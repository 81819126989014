import React from 'react';

const xss = require('xss');

import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

const quillDeltaConfig = {};
const converter = new QuillDeltaToHtmlConverter([], quillDeltaConfig);
const BlotsMap = {};

export const registerRender = (type, renderCusom) => {
  _.set(BlotsMap, type, renderCusom);
};

converter.renderCustomWith((customOp, contextOp) => {
  const renderer = _.get(BlotsMap, customOp.insert.type);
  if (renderer) {
    const content = renderer(customOp, contextOp);
    if (!_.isUndefined(content)) return content;
  }
  return '';
});

export const renderer = {
  renderHTML: () => (itemData) => {
    if (!itemData) return '';
    const cleanHTML = xss(itemData);
    return React.createElement('p', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
  renderDeltaToHTML: () => (itemData) => {
    if (!itemData) return '';
    const content_ops = _.get(itemData, 'ops');
    converter.rawDeltaOps = content_ops;
    const content_html = converter.convert();
    const cleanHTML = content_html;
    return React.createElement('div', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
};

export default renderer;
