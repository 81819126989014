import gstyles from '@vl/gstyles';
import React from 'react';
import _ from 'lodash';

import styled, { keyframes } from 'styled-components';
import useHoistedRender from '@vl/hooks/useHoistedRender';

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const Spinner = styled.div`
  .spinner {
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);

    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid black;
    background: transparent;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => (props.fullScreen ? `
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    position: fixed;
    background-color: ${gstyles.colors.white500};
    background-color: transparent;
  ` : `
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    position: absolute;
    background-color: transparent;
  `)}
  .spinner-container {
    .spinner-logo {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
`;

const LoadingScreen = (props) => {
  const [shouldRender] = useHoistedRender();
  const spinnerProps = {
    ...props,
    fullScreen: _.has(props, 'fullScreen') ? props.fullScreen : true,
  };

  if (!shouldRender) return null;
  return (
    <Spinner {...spinnerProps}>
      <div className="relative spinner-container">
        <div className="spinner" />
        <div className='spinner-logo'
        >
          {gstyles.icons({ name: 'unitz-logo', size: 24 })}
        </div>
      </div>
    </Spinner>
  );
};

export default LoadingScreen;
