import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';

export const View8 = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div
        className="absolute rounded-full shadow-sm cursor-pointer bottom-2/4 -left-6 -translate-y-3/4 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-black100">
          {gstyles.icons({ name: 'arrow-left', size: 25, fill: gstyles.colors.white500 })}
        </div>
      </div>
    </DIV>
  );
};
export default displayName(View8);
