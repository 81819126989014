import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { Link } from '@uz/unitz-components-web/Link';
import * as Actions from '@uz/unitz-ctf-theme/actions';

export const LoginButton = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      const action = Actions.resolveAction(itemData);
      const onClick = _.isFunction(action) && action(ctx);

      return (
        <li>
          <Link
            className={cx(
              ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
              _.get(itemProps, 'className')
            )}
            to={onClick ? '#login' : ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref', ''))}
            onClick={onClick}
          >
            {ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', '']))}
          </Link>
        </li>
      );
    })}
  </DIV>
);

export default LoginButton;
