import React from 'react';
import _ from 'lodash';
import DataContext from '../DataContext';
import Context from '../Context';
import { resolveOption } from '../hook';

const rule = ({ children, options, className }) => {
  let display = _.get(options, 'display');

  // eslint-disable-next-line
  const ctx = React.useContext(DataContext);

  display = resolveOption(display, ctx);

  // eslint-disable-next-line
  return () => {
    if (display) {
      return children({ className }, ctx || new Context({}));
    }
    return null;
  };
};

export default rule;
