import React from 'react';
import _ from 'lodash';

const xss = require('xss');

export const formatter = {
  displayName: () => (itemData) => {
    return _.get(itemData, 'displayName', '');
  },
  id: () => (itemData) => {
    return _.get(itemData, 'sys.id', '');
  },
  name: () => (itemData) => {
    return _.get(itemData, 'sys.id', '');
  },
  photoUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);
    return _.get(itemData, 'avatarUrl.url', `https://ui-avatars.com/api/?name=${displayName}`);
  },
  findImage: (ctx) => (itemData) => {
    let imgUrl = _.get(itemData, 'image.fixed.src');
    if (imgUrl) {
      const { width, height } = itemData;
      if (width) {
        imgUrl = imgUrl.replace('w=1600', `w=${width}`);
      }
      if (height) {
        imgUrl = imgUrl.replace('h=1600', `h=${height}`);
      }
    }
    return imgUrl ? `https:${imgUrl}` : itemData;
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);
    return _.get(itemData, 'avatarUrl.fixed.src', `https://ui-avatars.com/api/?name=${displayName}`);
  },

  renderHTMLText: () => (itemData) => {
    const detail = _.get(itemData, 'longText.longText', '');
    if (!detail) return '';
    const cleanHTML = xss(detail);
    return React.createElement('p', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
  icon: () => (itemData) => {
    return _.get(itemData, 'icon', '');
  },
  longText: () => (itemData) => {
    const detail = _.get(itemData, 'longText', '');
    if (!detail) return '';
    const cleanHTML = xss(detail);

    return cleanHTML;
  },
  isFinalLevel: () => (itemData) => {
    return !_.get(itemData, 'chidlrenCollection.items.length', false);
  },
  buildCategoryHierarchy: (ctx) => (categories) => {
    const categoryMapById = _.keyBy(categories, (item) => _.get(item, 'sys.id'));
    // build direct parent
    categories.map((item) => {
      _.map(_.get(item, 'chidlrenCollection.items', []), (childItem) => {
        const childId = _.get(childItem, 'sys.id');
        _.set(categoryMapById, `${childId}.parent`, item);
      });
    });
    // build parents path
    categories.map((item) => {
      const parents = [];
      let curNode = item;
      const maxLevel = 10;
      do {
        const parent = _.get(curNode, 'parent');
        if (parent) {
          parents.unshift(parent);
          curNode = categoryMapById[_.get(parent, 'sys.id')];
        } else {
          curNode = null;
        }
      } while (curNode && parents.length <= maxLevel);
      _.set(item, 'parents', parents);
    });
    // filter cat fulltime

    return _.filter(categories, (item) => _.get(item, 'sys.id') !== '2JDELht3aty4I18HvWctIC');
  },

  findCategoryByIds: (ctx) => (ids) => {
    const categories = ctx.apply('ctf.buildCategoryHierarchy', {
      lang: ctx.get('currentUserModel.activeLanguage'),
    });
    return _.compact(_.map(ids, (item) => _.find(categories, (cat) => _.get(cat, 'sys.id') === _.get(item, 'sys.id'))));
  },

  getChildById: (ctx) => (categoryId) => {
    const parent = formatter.findCategoryByIds(ctx)([{ sys: { id: categoryId } }]);
    return formatter.findCategoryByIds(ctx)(_.get(parent, '0.chidlrenCollection.items'));
  },

  findRootParent: (ctx) => (categoryId) => {
    const itemData = formatter.findCategoryByIds(ctx)([{ sys: { id: categoryId } }]);
    const parent = _.get(itemData, '0.parent', {});
    _.assign(parent, { isConsult: _.get(parent, 'sys.id') === '1WamMEyXzLe6x1iI1v0bgJ' });
    return parent;
  },

  formatterCategory: (ctx) => (cat) => {
    return {
      image: _.get(cat, 'image'),
      icon: formatter.icon(ctx)(cat),
      id: formatter.id(ctx)(cat),
      cat_id: formatter.id(ctx)(cat),
      name: formatter.name(ctx)(cat),
      longText: formatter.longText(ctx)(cat),
      isFinalLevel: formatter.isFinalLevel(ctx)(cat),
      props: {
        displayName: formatter.displayName(ctx)(cat),
        photoUrl: formatter.photoUrl(ctx)(cat),
      },
    };
  },
};

export default formatter;
