import React from 'react';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Subtitle, Description, Title, Titlespan2 } from '../../components/title/index';
import Image from '../../components/image/index';
// import './portfolio.scss';

function Portfolio() {
  return (
    <section className="portfolio-wrapper" id="portfolio">
      <DIV>
        <div className="main-title-wrapper">
          <Subtitle
            Class="site-subtitle"
            Name={ctx.apply('ctf.findEntry', { name: 'portfolioItem' }, 'shortText', '')}
          />
          <Titlespan2
            Class="sitemain-subtitle"
            Name={ctx.apply('ctf.findEntry', { name: 'portfolioItem' }, 'longText.longText', '')}
          />
          <Description
            Class="site-dec"
            Name={ctx.apply('ctf.renderRichText', { name: 'portfolioItem' }, 'richText', null)}
          />
        </div>
        <Row>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <Col lg={4} sm={6} key={i} className={`portfolio-${i - 1}`}>
              <div className="portfolio-content">
                <div className="portfolio-first-block">
                  <Image
                    Path={ctx.apply('ctf.findImage', { name: `portfolioItem${i}` }, 'image', '', { width: 450 })}
                  />
                  <div className="portfolio-hover">
                    <Title
                      Class="portfolio-hover-title"
                      Name={ctx.apply('ctf.findEntry', { name: `portfolioItem${i}` }, 'shortText', '')}
                    />
                    <Description
                      Class="portfolio-hover-dec"
                      Name={ctx.apply('ctf.findEntry', { name: `portfolioItem${i}` }, 'longText.longText', '')}
                    />
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </DIV>
    </section>
  );
}

export default Portfolio;
