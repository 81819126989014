import { bindings, hook } from '@vl/redata';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import CourseModel from '@uz/unitz-models/CourseModel';

import _ from 'lodash';

const bindData = bindings({
  widget: {
    rules: [
      [
        'data',
        {
          data: {
            coursePending: hook((ctx) => {
              const advisor_id = ctx.apply('authModel.getUserId');
              const courses = usePromiseSource(async () => {
                try {
                  const courses = await CourseModel.find(
                    `where: {
                      status: {_eq: pending_approval},
                      advisor_id: {_eq: "${advisor_id}"}
                    }`,
                      `
                        id
                        status
                      `
                  );
                  return courses.toObject();
                } catch (err) {}
              });
              return _.first(courses);
            }),
            courses: hook((ctx) => {
              return [];
            }),
            courseGroups: hook((ctx) => {
              const courses = ctx.get('coursePagingModel.items');
              const groupMapping = [
                {
                  id: '1',
                  title: ctx.apply('i18n.t', 'AdvisorProfileGeneral.NotPublished'),
                  filter: ({ status }) => status === 'draft',
                  detailUrl: ctx.apply('routeStore.toUrl', 'course', { status: 'draft' }),
                },
                {
                  id: '2',
                  title: ctx.apply('i18n.t', 'AdvisorProfileGeneral.GoingOn'),
                  filter: ({ status }) => status === 'onprogress',
                  detailUrl: ctx.apply('routeStore.toUrl', 'course', { status: 'onprogress' }),
                },
                {
                  id: '3',
                  title: ctx.apply('i18n.t', 'AdvisorProfileGeneral.ComingSoon'),
                  filter: ({ status }) => status === 'published',
                  detailUrl: ctx.apply('routeStore.toUrl', 'course', { status: 'published' }),
                },
              ].map((group) => {
                return {
                  ...group,
                  count: _.get(_.filter(courses, group.filter), 'length'),
                };
              });
              return groupMapping;
            }),
          },
        },
      ],
    ],
  },
  allowButtonCreateCourse: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !!_.isEmpty(ctx.get('coursePending.id'))),
        },
      ],
    ],
  },
});
export default bindData;
