import { bindings, hook } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            lang: hook(() => {
              const route = useRoute().getPageContext();
              const lang = _.get(route, 'lang');
              return lang;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
