import React from 'react';
import DataContext from '../DataContext';

const rule = ({ children, className }) => {
  const RuleWrapper = () => (
    <DataContext.Provider value={{}} className={className}>
      {children}
    </DataContext.Provider>
  );
  return RuleWrapper;
};

export default rule;
