import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import UnitzBizInformationForm from '@uz/unitz-components-web/UnitzBizInformationForm';

const UnitzBizInformationFormLayout = () => {
  return (
    <DIV className="content">
      <div className="lg:bg-background1">
        <div className="wrapper app-row" id={ctx.get('sectionData.className')}>
          <Row justify="center" align="middle">
            <Col sm={24} lg={12}>
              {_.map(ctx.get('sectionData.sections'), (item, index) => {
                return <React.Fragment key={index}>{ctx.apply('ctf.renderItem', { name: item.name })}</React.Fragment>;
              })}
            </Col>
            <Col className="w-full" sm={24} lg={12}>
              <UnitzBizInformationForm />
            </Col>
          </Row>
        </div>
      </div>
    </DIV>
  );
};

export default UnitzBizInformationFormLayout;
