import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Avatar } from '@uz/unitz-components-web/Avatar';

export const View8 = (props) => {
  const { itemData } = props;
  return (
    <DIV className="carouselItem">
      <div className="mx-5 py-3 lg:py-2 text-center">
        <div className="flex items-center justify-center mb-5">
          <Avatar
            size={{
              xs: 106,
              sm: 106,
              md: 106,
              lg: 106,
              xl: 168,
              xxl: 168,
            }}
            alt={_.get(itemData, 'name', '')}
            src={_.get(itemData, 'image.fixed.src', '')}
          />
        </div>
        <div className="flex flex-col px-0 md:px-12 lg:px-0 justify-center items-center">
          <h5 className="text-xl text-main mb-0 lg:mb-1 text-center font-semibold">
            {_.get(itemData, 'shortText', '')}
          </h5>

          {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'longText.longText', {
            className: 'text-base px-4 font-normal mb-0 lg:mb-4 text-center text-sub',
          })}

          <div className="w-20 h-1 mb-0 md:mb-4 rounded-lg bg-brandb500"></div>
          <div className="text-xs px-4 lg:px-12 lg:text-base font-normal text-center text-main">
            {ctx.apply('ctf.renderRichText', { name: itemData.name }, 'richText', null)}
          </div>
        </div>
      </div>
    </DIV>
  );
};
export default displayName(View8);
