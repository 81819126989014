import React from 'react';
import { Button as AntdButton, Tooltip } from 'antd';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import _ from 'lodash';

/**
 * Button sẽ thay đổi gì?
 * 1. Background color.
 * 2. Border color.
 * 3. Size.
 * 4. Text size.
 * 5. Text color. (5.1 text color hover)
 * 6. Icon
 * 7. Trạng thái hover.
 * 8. Trạng thái active.
 * 9. Trạng thái focus.
 * 10. Trạng thái disable.
 */

const normalizeColor = (color) => {
  return {
    darker: color,
    dark: color,
    DEFAULT: color,
    light: color,
    lighter: color,
  };
};
/**
 * All color of button
 */
const colors = {
  // Main Color Palette
  primary: gstyles.colors.brandb,
  secondary: gstyles.colors.branda,
  // Accent Color Palette
  red: gstyles.colors.red,
  yellow: gstyles.colors.yellow,
  blue: gstyles.colors.blue,
  // Ink is custom for button style guide
  ink: {
    dark: gstyles.colors.main,
    DEFAULT: gstyles.colors.sub,
    light: gstyles.colors.border,
  },
  inkBg2: {
    dark: gstyles.colors.main,
    DEFAULT: gstyles.colors.background2,
    light: gstyles.colors.border,
    lighter: gstyles.colors.border,
  },
  inkPlaceHolder: {
    dark: gstyles.colors.main,
    DEFAULT: gstyles.colors.placeHolder,
    light: gstyles.colors.border,
    lighter: gstyles.colors.border,
  },
  text_inkBg2: normalizeColor(gstyles.colors.sub),
  text_inkPlaceHolder: normalizeColor(gstyles.colors.white),
  // Other
  text_primary: normalizeColor(gstyles.colors.white),
  // white: normalizeColor(gstyles.colors.white),
  main: normalizeColor(gstyles.colors.main),
  transparent: normalizeColor('transparent'),
  overlay: {
    dark: gstyles.colors.main,
    DEFAULT: gstyles.colors.black300,
    light: gstyles.colors.black300,
  },
};

const customType = ({ type, mainColor, color }) => {
  // type of antd: primary, default, dashed, text, link;
  const options = {
    default: {
      color: mainColor,
      bgColor: color,
      borderColor: mainColor,
    },
    primary: {
      color,
      bgColor: mainColor,
      borderColor: mainColor,
    },
    dashed: {
      color: mainColor,
      bgColor: color,
      borderColor: mainColor,
    },
    text: {
      color: _.get(colors, 'main'),
      bgColor: _.get(colors, 'transparent'),
      borderColor: _.get(colors, 'transparent'),
    },
    label: {
      color: mainColor,
      bgColor: _.get(colors, 'transparent'),
      borderColor: _.get(colors, 'transparent'),
    },
    link: {
      color: mainColor,
      bgColor: _.get(colors, 'transparent'),
      borderColor: _.get(colors, 'transparent'),
    },
  };
  return _.get(options, `${type}`) || _.get(options, 'default');
};

const buttonState = ({ bgColor, borderColor, color, reverse }) => {
  // states of button.
  return {
    default: {
      display: reverse ? 'flex' : 'inline-block',
      flexDirection: reverse ? 'row-reverse' : 'row',
      color: _.get(color, 'DEFAULT'),
      bgColor: _.get(bgColor, 'DEFAULT'),
      borderColor: _.get(borderColor, 'DEFAULT'),
    },
    hover: {
      color: _.get(color, 'light'),
      bgColor: _.get(bgColor, 'light'),
      borderColor: _.get(borderColor, 'light'),
    },
    focus: {
      color: _.get(color, 'light'),
      bgColor: _.get(bgColor, 'light'),
      borderColor: _.get(borderColor, 'light'),
    },
    active: {
      color: _.get(color, 'dark'),
      bgColor: _.get(bgColor, 'dark'),
      borderColor: _.get(borderColor, 'dark'),
    },
    disable: {
      color: _.get(color, 'lighter'),
      bgColor: _.get(bgColor, 'lighter'),
      borderColor: _.get(borderColor, 'lighter'),
    },
  };
};

const styledProps = (props) => {
  // overwrite button
  const { name, type, reverse } = props;
  const mainColor = _.get(colors, `${name}`, colors['primary']);
  const color = _.get(colors, `text_${name}`, colors['text_primary']);
  return buttonState({ ...customType({ type, mainColor, color }), reverse });
};

const StyledButton = styled(AntdButton)`
  ${(props) => {
    const customButtonProps = styledProps(props);
    return `
      &.ant-btn {
        display: ${_.get(customButtonProps, 'default.display')};
        flex-direction: ${_.get(customButtonProps, 'default.flexDirection')};
        color: ${_.get(customButtonProps, 'default.color')};
        background-color: ${_.get(customButtonProps, 'default.bgColor')};
        border-color: ${_.get(customButtonProps, 'default.borderColor')};
        svg {
          filter: ${_.get(gstyles.getFilterCss(_.get(customButtonProps, 'default.color')), 'filter')} !important;
        }
        &:hover {
          color: ${_.get(customButtonProps, 'hover.color')};
          background: ${_.get(customButtonProps, 'hover.bgColor')};
          border-color: ${_.get(customButtonProps, 'hover.borderColor')};
          svg {
            filter: ${_.get(gstyles.getFilterCss(_.get(customButtonProps, 'hover.color')), 'filter')} !important;
          }
        }
        &:focus {
          color: ${_.get(customButtonProps, 'focus.color')};
          background: ${_.get(customButtonProps, 'focus.bgColor')};
          border-color: ${_.get(customButtonProps, 'focus.borderColor')};
          svg {
            filter: ${_.get(gstyles.getFilterCss(_.get(customButtonProps, 'focus.color')), 'filter')} !important;
          }
        }
        &:active {
          color: ${_.get(customButtonProps, 'active.color')};
          background: ${_.get(customButtonProps, 'active.bgColor')};
          border-color: ${_.get(customButtonProps, 'active.borderColor')};
          svg {
            filter: ${_.get(gstyles.getFilterCss(_.get(customButtonProps, 'active.color')), 'filter')} !important;
          }
        }
      }
      &.ant-btn[disabled] {
        color: ${_.get(customButtonProps, 'disable.color')};
        background: ${_.get(customButtonProps, 'disable.bgColor')};
        border-color: ${_.get(customButtonProps, 'disable.borderColor')};
        svg {
          filter: ${_.get(gstyles.getFilterCss(_.get(customButtonProps, 'disable.color')), 'filter')} !important;
        }
        &:hover,
        &:focus,
        &:active {
          color: ${_.get(customButtonProps, 'disable.color')};
          background: ${_.get(customButtonProps, 'disable.bgColor')};
          border-color: ${_.get(customButtonProps, 'disable.borderColor')};
          svg {
            filter: ${_.get(gstyles.getFilterCss(_.get(customButtonProps, 'disable.color')), 'filter')} !important;
          }
        }
      }
    `;
  }}
`;
// props: type, size, name, icon, block, ghost
export const Button = (allProps) => {
  const {
    tooltip,
    tooltipProps,
    ...props
  } = allProps;
  const [loading, $loading] = React.useState(props.loading);

  const computedProps = (() => {
    return {
      ...props,
      ...(props.onClick ? {
        onClick: (...args) => {
          const res = props.onClick(args);
          if (_.get(res, 'then')) {
            $loading(true);
            res.then((data) => {
              $loading(false);
              return data;
            }).catch(() => {
              $loading(false);
            });
          }
          return res;
        },
        loading
      } : {}),
      ...(_.has(props, 'loading') ? { loading: props.loading } : {}),
    };
  })();
  const ele = <StyledButton {...computedProps} />;
  if (tooltip) {
    return (
      <Tooltip {...{
        title: tooltip,
        ...tooltipProps,
      }}>
        {ele}
      </Tooltip>
    );
  }
  return ele;
};

export default Button;
