import _ from 'lodash';
import { formatVND } from '@vl/mod-utils/currencyFormatter';
import { formatDateDisplay, formatFullDateDisplay } from '@vl/mod-utils/dateFormatter';
import { applyDiscount } from '@vl/mod-utils/paymentHelper';
import { removeDirectivesFromDocument } from '@apollo/client/utilities';
import moment from 'moment';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import {
  END_TIME_HOUR_MINUTE_FORMAT,
  TIME_HOUR_MINUTE_FORMAT,
  TIMESTAMPTZ_FORMAT,
  START_TIME_FORMAT_TIME_ZONE,
  DATE_FORMAT,
  START_DATE_YEAR_ML_FORMAT,
  TIME_HOUR_MINUTE_ML_FORMAT,
} from '@vl/mod-utils/datetime';
import { later } from '@vl/mod-utils/later';
import i18n from 'i18n-js';
import helpers from '@vl/mod-utils/flattenGet';

// var later = require('later');

export const formatter = {
  displayName: (ctx) => (itemData) => {
    return _.get(itemData, 'name', '');
  },
  description: (ctx) => (itemData) => {
    return _.get(itemData, 'description', '--');
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = `${formatter.displayName(ctx)(itemData) || ''}`;
    const photoUrl = _.get(itemData, 'photo_url') || '';
    if (!photoUrl) {
      const matches = encodeURIComponent(displayName || 'Unitz');
      return `https://ui-avatars.com/api/?name=${matches}&font-size=0.2&length=5&background=E6EBF0`;
    }
    return photoUrl;
  },
  duration: (ctx) => (itemData) => {
    const session_duration = _.get(itemData, 'session_duration', '');
    if (!session_duration) return 0;
    return ctx.apply('i18n.t', 'Course.Info.minute_per_session', {
      minute: Math.round(session_duration / 60),
    });
  },

  durationLesson: (ctx) => (itemData) => {
    const session_duration = _.get(itemData, 'session_duration', '');
    if (!session_duration) return 0;
    return ctx.apply('i18n.t', 'ZoomToolCourse.minute_per_lesson', {
      minute: Math.round(session_duration / 60),
    });
  },

  startAt: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    if (!startAt) return '';
    return formatDateDisplay(startAt);
  },

  startAtWithTime: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    if (!startAt) return '';
    return formatFullDateDisplay(startAt);
  },

  startAtTime: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    if (!startAt) return '';
    return `${moment(startAt)
      .locale(i18n.locale)
      .format(TIME_HOUR_MINUTE_FORMAT)}`;
  },

  lessonTime: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    const endAt = _.get(itemData, 'end_at');
    return `${moment(startAt)
      .locale(i18n.locale)
      .format('HH:mm')} - ${moment(endAt)
      .locale(i18n.locale)
      .format('HH:mm')} ${moment(startAt)
      .locale(i18n.locale)
      .format('YYYY/MM/DD')}`;
  },

  lessonTimeML: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    const endAt = _.get(itemData, 'end_at');
    return `${moment(startAt)
      .locale(i18n.locale)
      .format(_.get(TIME_HOUR_MINUTE_ML_FORMAT, moment.locale(i18n.locale), 'de'))} - ${moment(endAt)
      .locale(i18n.locale)
      .format(_.get(TIME_HOUR_MINUTE_ML_FORMAT, moment.locale(i18n.locale), 'de'))} ${moment(startAt)
      .locale(i18n.locale)
      .format(_.get(START_DATE_YEAR_ML_FORMAT, moment.locale(i18n.locale), 'de'))}`;
  },

  startTimeWithDayOfWeek: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    if (!startAt) return '';
    const format_by_locale = _.get(START_TIME_FORMAT_TIME_ZONE, moment.locale(i18n.locale), 'vi');
    return `${moment(startAt)
      .locale(i18n.locale)
      .format(format_by_locale)}`;
  },

  startTimeWithDate: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    if (!startAt) return '';
    if (!itemData) return '';
    const format_by_locale = _.get(DATE_FORMAT, moment.locale(i18n.locale), 'vi');
    const date = formatter.replaceThg()(
      moment(startAt)
        .locale(i18n.locale)
        .format(format_by_locale)
    );
    return date;
  },

  replaceSACH: () => (time) => {
    return _.replace(_.replace(time, 'SA', 'Sáng'), 'CH', 'Chiều');
  },

  replaceThg: () => (day) => {
    return _.replace(day, 'Thg', 'Tháng');
  },

  endAtWithTime: (ctx) => (itemData) => {
    const endAt = _.get(itemData, 'end_at');
    if (!endAt) return '';
    return formatFullDateDisplay(endAt);
  },

  sessionTimeHour: (ctx) => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    const endAt = _.get(itemData, 'end_at');
    if (!startAt || !endAt) return '';
    return `${formatter.replaceSACH()(moment(startAt).format(TIME_HOUR_MINUTE_FORMAT))} - ${formatter.replaceSACH()(
      moment(endAt).format(END_TIME_HOUR_MINUTE_FORMAT)
    )}`;
  },

  sessions: (ctx) => (itemData) => {
    const _sessions = _.get(itemData, 'sessions', []);
    const format_by_locale = _.get(DATE_FORMAT, moment.locale(i18n.locale), 'vi');
    const sessions = _.orderBy(_.take(_sessions, 3), (session) => _.get(session, 'type'), ['asc']);
    const arr = _.filter(
      _.map(sessions, (session) => {
        const type = _.get(session, 'type');
        const handler = {
          single: (session) => {
            return {
              type,
              data: [
                formatter.replaceThg()(
                  moment(_.get(session, 'start_at'))
                    .locale(i18n.locale)
                    .format(format_by_locale)
                ),
              ],
            };
          },
          repeat: (session) => {
            const repeat_pattern = _.get(session, 'repeat_pattern');
            if (!repeat_pattern) {
              const date = formatter.replaceThg()(
                moment(_.get(session, 'start_at'))
                  .locale(i18n.locale)
                  .format(format_by_locale)
              );
              return date;
            }
            const start_at = _.get(session, 'start_at');
            const session_duration = _.get(itemData, 'session_duration');
            let time = '';
            if (session_duration) {
              time = `${CourseFormatter.replaceSACH()(
                moment(start_at).format('hh:mm A')
              )} - ${CourseFormatter.replaceSACH()(
                moment(start_at)
                  .clone()
                  .add(session_duration, 'second')
                  .format('hh:mm A')
              )}`;
            }
            const repeatPattern = _.compact(`${repeat_pattern}`.replace('* * * * ', '').split(','));
            const arrayTransfering = _.castArray(repeatPattern).sort();
            const dateOrdering = _.sortBy(arrayTransfering, (item) => {
              return item === '0' ? _.get(arrayTransfering, 'length', 1) : 0;
            });
            let dayRepeat = _.map(dateOrdering, (item) =>
              moment()
                .day(item)
                .format('dddd')
            );
            const result = {
              type,
              data: [`${ctx.apply('i18n.t', 'Course.DurationSetting.every_week')} ${dayRepeat.join(', ')}: ${time}`],
            };
            return result;
          },
        };
        return handler[type](session);
      }),
      (item) => !!item
    );
    return arr;
  },

  sessionsCourse: (ctx) => (_sessions) => {
    const sessions = _.orderBy(_.take(_sessions, 3), (session) => _.get(session, 'type'), ['asc']);
    return _.map(sessions, (session) => formatter.sessionItem(ctx)(session));
  },

  sessionItem: (ctx) => (itemData) => {
    const start_at = _.get(itemData, 'start_at');
    const type = _.get(itemData, 'type');
    const repeat_pattern = _.get(itemData, 'repeat_pattern');
    const time = `${moment(start_at).format('hh:mm A')}`;

    if (!repeat_pattern) {
      return {
        type: 'single',
        data: [`${formatDateDisplay(start_at)}, ${time}`],
      };
    }

    const schedule = later.parse.cron(repeat_pattern);
    const dateOrdering = _.get(schedule, 'schedules.0.d');
    let dayRepeat = _.map(dateOrdering, (item) =>
      moment()
        .locale(i18n.locale)
        .day(item - 1)
        .format('dd')
    );
    const result = {
      type,
      data: [`${ctx.apply('i18n.t', 'Course.DurationSetting.every_week')} ${dayRepeat.join(', ')}: ${time}`],
    };

    return result;
  },

  priceAmount: (ctx) => (itemData) => {
    const amount = _.get(itemData, 'price_amount') || 0;
    return `${formatVND(amount)}`;
  },
  priceAmountTxt: (ctx) => (itemData) => {
    const amount = _.get(itemData, 'price_amount') || 0;
    const type =
      _.get(itemData, 'pricing_type', 0) === 'per_session' ? ` / ${ctx.apply('i18n.t', 'Course.per_session')}` : ``;
    return `${formatVND(amount)}${type}`;
  },
  priceAmountText: (ctx) => (itemData) => {
    return `${_.get(itemData, 'price_amount', 0)} ${_.get(itemData, 'price_currency', 'vnd')}`;
  },
  priceAmountObj: (ctx) => (itemData) => {
    const amount = _.get(itemData, 'price_amount') || 0;
    const type =
      _.get(itemData, 'pricing_type', 0) === 'per_session' ? ` / ${ctx.apply('i18n.t', 'Course.per_session')}` : ``;
    return {
      amount: formatVND(amount),
      type,
    };
  },

  pricingTypeTxt: (ctx) => (itemData) => {
    const type = _.get(itemData, 'pricing_type', '');
    const session_per_purchase = _.get(itemData, 'session_per_purchase', 0);
    if (type === 'per_course') {
      return ctx.apply('i18n.t', 'Course.Info.tuition_by_course');
    }
    return ctx.apply('i18n.t', 'Course.Info.frequency_of_tuition', {
      count: session_per_purchase,
    });
  },

  priceUnitText: (ctx) => (itemData) => {
    const pricing_type = _.get(itemData, 'pricing_type', '');
    if (pricing_type === 'per_session') {
      return ctx.apply('i18n.t', 'Course.per_session');
    } else if (pricing_type === 'per_course') {
      return ctx.apply('i18n.t', 'Course.per_course');
    }
  },

  purchaseNote: (ctx) => (itemData) => {
    const purchases = _.get(itemData, 'purchases');
    const courses = helpers.flattenGet(purchases, 'purchase.courses');
    const total_session = _.sumBy(courses, 'course.per_amount');
    const session_count = _.get(itemData, 'session_occurence', 0);
    const course_rooms = helpers.flattenGet(purchases, 'purchase.course_rooms');
    const transaction_purchases = helpers.flattenGet(purchases, 'purchase.transaction_purchases');
    const learned_session = _.get(transaction_purchases, 'length', 0);
    const session_occurence = _.sumBy(courses, 'course.session_occurence');
    const end_room = _.last(_.orderBy(course_rooms, 'room.start_at'));

    if (total_session - learned_session <= 0) return '';

    if (parseInt(session_count) === 100000) {
      return ctx.apply('i18n.t', 'CourseDetail.purchase.unlimited', {
        purchase_session: '',
        no_learn_session: total_session - learned_session,
        end_time: `<strong>${_.capitalize(
          moment(_.get(end_room, 'room.start_at'))
            .locale(i18n.locale)
            .format('dddd, DD/MM/YYYY')
        )}</strong>`,
      });
    }

    return ctx.apply('i18n.t', 'CourseDetail.purchase.limited', {
      purchase_session: `${_.get(course_rooms, 'length')}/${session_occurence}`,
      no_learn_session: total_session - learned_session,
      end_time: `<strong>${_.capitalize(
        moment(_.get(end_room, 'room.start_at'))
          .locale(i18n.locale)
          .format('dddd, DD/MM/YYYY')
      )}</strong>`,
    });
  },

  purchaseObj: (ctx) => (itemData) => {
    const purchases = _.get(itemData, 'purchases');
    const courses = helpers.flattenGet(purchases, 'purchase.courses');
    const total_session = _.sumBy(courses, 'course.per_amount');
    const session_count = _.get(itemData, 'session_occurence', 0);
    const course_rooms = helpers.flattenGet(purchases, 'purchase.course_rooms');
    const transaction_purchases = helpers.flattenGet(purchases, 'purchase.transaction_purchases');
    const learned_session = _.get(transaction_purchases, 'length', 0);
    const session_occurence = _.sumBy(courses, 'course.session_occurence');

    if (parseInt(session_count) === 100000) {
      return {
        type: 'unlimited',
        no_learn_session: total_session - learned_session,
        session: total_session,
        session_occurence,
      };
    }

    return {
      type: 'limited',
      purchase_session: `${total_session}/${_.get(course_rooms, 'length')}`,
      no_learn_session: total_session - learned_session,
      session: total_session,
      session_occurence,
    };
  },

  statusText: (ctx) => (itemData) => {
    return `${_.get(itemData, 'status', '')}`;
  },
  sessionPerPurchase: (ctx) => (itemData) => {
    return _.get(itemData, 'session_per_purchase', 0);
  },
  sessionOccurence: (ctx) => (itemData) => {
    return _.get(itemData, 'session_occurence', 0);
  },
  amountPayment: (ctx) => (itemData) => {
    const discount = _.get(itemData, 'discounts.0', null);
    const pricing_type = _.get(itemData, 'pricing_type');
    const price_amount = _.get(itemData, 'price_amount');

    const totalCalculate = {
      per_session: () => {
        return price_amount * _.get(itemData, 'numberOfSession');
      },
      per_course: () => {
        return price_amount;
      },
    };
    const total = typeof totalCalculate[pricing_type] === 'function' ? totalCalculate[pricing_type]() : price_amount;
    if (!total) return {};

    const values = applyDiscount(discount)(total);
    return values;
  },
  createdAtWithTime: (ctx) => (itemData) => {
    return formatFullDateDisplay(_.get(itemData, 'created_at') || '');
  },
  purchaseUnit: (ctx) => (itemData) => {
    const pricing_type = _.get(itemData, 'pricing_type');
    const mapper = {
      per_session: 'session',
      per_course: 'course',
    };
    return mapper[pricing_type] || '';
  },
  pricingType: (ctx) => (itemData) => {
    const pricing_type = _.get(itemData, 'pricing_type');
    if (!pricing_type) return '';
    return pricing_type;
  },
  totalRate: () => (itemData) => {
    const count = _.get(itemData, 'reviews_aggregate.aggregate.count', 0);
    if (!count) return 0;
    return count;
  },

  isUnlimitedSession: () => (itemData) => {
    const session_occurence = parseInt(_.get(itemData, 'session_occurence'));
    return _.isNumber(session_occurence) && session_occurence >= 10000;
  },

  isPerCoursePurchase: () => (itemData) => {
    const pricing_type = _.get(itemData, 'pricing_type');
    return pricing_type === 'per_course';
  },

  canPurchase: () => (itemData) => {
    const status = _.get(itemData, 'status');
    return ['published', 'onprogress'].includes(status);
  },

  reviewScore: () => (itemData) => {
    const review = _.get(itemData, 'review_agg.avg_score');

    if (!review) return 0;
    return _.round(parseFloat(review) / 2, 1).toFixed(1);
  },

  fileName: () => (itemData) => {
    if (!itemData) return '';
    const regex = itemData.match(/([a-zA-Z\-\_0-9]+\.[a-zA-Z]{2,4})[\?\$]/);

    return _.get(regex, '1', '');
  },
  categories: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'categories') || [];
    return _.compact(
      arr.map((item, index) => {
        const _item = _.get(item, 'category') || item;
        const catId = _.get(_item, 'id');
        return {
          displayName: _.get(_item, 'display_name') || '',
          icon: _.get(_item, 'icon') || '',
          id: catId || index,
        };
      })
    );
  },
  queryCoursePublishedOnprogress: (ctx) => (itemData) => {
    return `where: { status: {_in: [published, onprogress]}},`;
  },
  queryCourseViewing: (ctx) => (itemData) => {
    const toDate = moment()
      .utc()
      .format(TIMESTAMPTZ_FORMAT);
    const fromDate = moment()
      .subtract(2, 'weeks')
      .utc()
      .format(TIMESTAMPTZ_FORMAT);
    return `where: { 
      status: {_in: [published, onprogress]},
      views: {_not: {id: {_is_null: true}}}
      _and: [{updated_at: {_lte: "${toDate}"}}, {updated_at: {_gte: "${fromDate}"}}]
    },
    order_by:{ views_aggregate: {count: desc_nulls_last}},`;
  },

  getQueryView: (ctx) => (itemData) => {
    const toDate = moment()
      .utc()
      .format(TIMESTAMPTZ_FORMAT);
    const fromDate = moment()
      .subtract(2, 'weeks')
      .utc()
      .format(TIMESTAMPTZ_FORMAT);
    return `args: {}, where: { 
      status: {_in: [published, onprogress]},
      views: {_not: {id: {_is_null: true}}}
      _and: [{updated_at: {_lte: "${toDate}"}}, {updated_at: {_gte: "${fromDate}"}}]
    },
    order_by:{ views_aggregate: {count: desc_nulls_last}},`;
  },

  getQueryFeature: (ctx) => (itemData) => {
    const arraySort = ['scoring_int: desc_nulls_last'];
    const dataSort = _.filter(arraySort, (item) => item).join(',');
    return `args: {category: "4bhJ4jhBJP3cilGFfNoekv"}, where: { status: { _in: [published, onprogress] } } ,order_by: {${dataSort}}`;
  },

  getQueryLatest: (ctx) => (itemData) => {
    const arraySort = ['scoring_int: desc_nulls_last'];
    const dataSort = _.filter(arraySort, (item) => item).join(',');
    return `args: {}, where: { status: { _in: [published, onprogress] } } ,order_by: { updated_at: desc_nulls_last, scoring_int: desc_nulls_last }`;
  },
};

export default formatter;
