import React, { useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

// import './menu.scss';

function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState();
  const toggle = () => setIsOpen(!isOpen);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const activeLink = (link) => {
    toggle();
    setActive(link);
    return 60;
  };
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <nav className="navbar navbar-wrapper menu-wrapper">
            <div className="navbar-link-wrapper">
              <ul className="navbar-nav flex flex-row space-x-6" id="menu-navbar">
                {_.map(ctx.get('sectionData.sections', []), (section, index) => {
                  const menuItem = ctx.apply('ctf.findSectionChild', section);

                  if (!menuItem) return null;
                  return ctx.apply(
                    'ctf.renderEnhancers',
                    menuItem,
                    <li key={index} className={`nav-item-box ${active === menuItem.linkHref ? 'active' : ''}`}>
                      <AnchorLink offset={() => activeLink(menuItem.linkHref)} href={menuItem.linkHref}>
                        <span className="text-white">{menuItem.shortText}</span>
                      </AnchorLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        );
      })}
    </DIV>
  );
}

export default Menu;
