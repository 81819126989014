import { bindings, hook } from '@vl/redata';

import AdvisorProfileModel from '@uz/unitz-models/AdvisorProfileModel';
import useObservableSource from '@vl/hooks/useObservableSource';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            advisorObs: hook((ctx) => {
              const advisors = useObservableSource(() => AdvisorProfileModel.find('limit: 10', 'advisor_id ref_ctf_eid avatar_url display_name'));
              return advisors;
            })
          }
        }
      ]
    ]
  }
});

export default bindData;
