import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';

const CustomParapraph = styled.div`
  p {
    color: ${gstyles.colors.sub};
    margin-bottom: 0;
  }
`;

export const BizCustomerSupportItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <div style={{ boxShadow: '0px 2px 12px rgba(38, 51, 64, 0.24)' }} className="customerItem p-6 pb-16 rounded-lg">
          <div className="flex justify-start items-start">
            <div>
              <div className="w-16">
                <Image
                  className="object-cover"
                  size="cover"
                  src={ctx.apply('ctf.findImage', itemData, 'image', '')}
                  alt={_.get(itemData, 'shortText')}
                  preview={false}
                />
              </div>
            </div>
            <div className="ml-4">
              <div className="font-semibold text-2xl text-main mb-2">{_.get(itemData, 'shortText')}</div>
              <CustomParapraph clas>
                {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'longText.longText', null)}
              </CustomParapraph>
            </div>
          </div>
        </div>
      );
    })}
  </DIV>
);

export default BizCustomerSupportItem;
