import moment from 'moment';
import i18n from 'i18n-js';
import _ from 'lodash';

export const formatPaymentHistoryTime = (ctx) => (time) => {
  const dateFormat = moment(time);
  const currentDate = moment();
  if (dateFormat.month() === currentDate.month() && dateFormat.year() === currentDate.year()) {
    return `${ctx.apply('i18n.t', 'time.currentMonth')}`;
  }

  if (dateFormat.month() !== currentDate.month() && dateFormat.year() === currentDate.year()) {
    return `${ctx.apply('i18n.t', 'time.month')} ${dateFormat.month()}`;
  }

  return `${ctx.apply('i18n.t', 'time.month')} ${dateFormat.month()} ${ctx.apply(
    'i18n.t',
    'time.year'
  )} ${dateFormat.year()}`;
};

export const normalizeDate = (date) => moment(date).format('YYYY-MM-DD');

export const vnDate = (date) => moment(date).format('DD/MM/YYYY');

export const getDaysInWeek = () => {
  const startOfWeek = moment().startOf('week').toDate();

  const daysArr = [normalizeDate(startOfWeek)];
  let current = daysArr[0];
  for (let day = 1; day <= 7; day++) {
    const nextDay = moment(current).add(1, 'days');
    current = normalizeDate(nextDay);
    daysArr.push(current);
  }

  return daysArr;
};

export const getDaysInMonth = () => {
  const startOfMonth = moment().startOf('month').toDate();
  const endOfMonth = moment().endOf('month').toDate();

  return [normalizeDate(startOfMonth), normalizeDate(endOfMonth)];
};
// TODO: need to return date as format as current locale
export const getTodayDate = () => formatDateDisplay(moment());

export const getToday = () => [normalizeDate(moment()), normalizeDate(moment().add(1, 'days'))];

export const getYesterday = () => [normalizeDate(moment().add(-1, 'days')), normalizeDate(moment())];

export const nextDay = (day) => normalizeDate(moment(day).add(1, 'days'));

export const prevDay = (day) => normalizeDate(moment(day).add(-1, 'days'));

export const nextWeek = (week) => {
  if (!week || week.length === 0) return [];
  return week.map((day) => normalizeDate(moment(day).add(7, 'days')));
};

export const prevWeek = (week) => {
  if (!week || week.length === 0) return [];
  return week.map((day) => normalizeDate(moment(day).add(-7, 'days')));
};

export const formatDateDisplay = (date) => {
  return moment(date).locale(i18n.locale).format(i18n.t('DateFormat.Default'));
};

export const formatFullDateDisplayWithLocale = (date) => {
  if (moment.locale(i18n.locale) === 'en') {
    return moment(date).format('ll');
  } else if (moment.locale(i18n.locale) === 'vi') {
    return moment(date).format('DD/MM/YYYY');
  }
};

export const formatFullDateDisplay = (date) => {
  return moment(date).format(i18n.t('DateFormat.FullDisplay'));
};

export const dateDiff = (date, otherDate) => {
  const duration = moment(otherDate).diff(moment(date), 'days');
  return duration;
};

export const getCurrentDate = () => normalizeDate(moment());
export const getCurrentDateUtc = () => moment().format('YYYY-MM-DDTHH:mm:ss.SSS+00:00');
export const formatUtc = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS+00:00');

export const isDateEqual = (a, b) => {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
};

export const getListDaysInMonth = (date) =>
  Array.from({ length: moment(date).daysInMonth() }, (x, i) => moment(date).startOf('month').add(i, 'days'));

export const formatPayDay = (date) => {
  return _.capitalize(moment(date).locale(i18n.locale).format('dddd, MMMM Do, YYYY'));
};
