import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import SearchHeaderNavbarTransparent from '@uz/unitz-components-web/SearchHeaderNavbarTransparent';

export const SearchTransparent = () => {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const SearchComponent = () => (
          <li className="flex justify-center items-center">
            <div className="flex items-center justify-center">
              <SearchHeaderNavbarTransparent />
            </div>
          </li>
        );

        return (
          <DIV>
            <ResponsiveProps lg={{ renderer: () => <div /> }} xl={{ renderer: () => <SearchComponent /> }}>
              {(resProps) => !!resProps && resProps.renderer()}
            </ResponsiveProps>
          </DIV>
        );
      })}
    </DIV>
  );
};

export default SearchTransparent;
