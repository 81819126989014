import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';
import cx from 'classnames';

export const Hint = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <div className="flex space-x-2 items-center">
          <div>{gstyles.icons({ name: 'info', size: 24, fill: gstyles.colors.blue500 })}</div>
          <Link
            to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref'))}
            aria-label={_.get(itemData, 'shortText')}
            target="_blank"
            style={{ color: '#00A3F5' }}
            className={cx('text-sm md:text-base underline font-normal', _.get(itemData, 'className', ''))}
          >
            {ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', '']))}
          </Link>
        </div>
      );
    })}
  </DIV>
);

export default Hint;
