import React from 'react';
import _ from 'lodash';

const privateData = {};

class Streamable {
  emit(event, ...params) {
    return _.get(privateData, [event], []).map((listener) => listener.call(this, ...params));
  }

  on(event, listener) {
    _.update(privateData, [event], (val) => (val ? val.concat(listener) : [listener]));
    return () => {
      _.update(privateData, [event], (val) => _.filter(val || [], (item) => item !== listener));
    };
  }

  once(event, listener) {
    const handlers = {
      listener: (...args) => {
        // self disposing
        if (handlers.disposer) {
          handlers.disposer();
          handlers.disposer = null;
          handlers.listener = null;
        }
        return listener.call(...args);
      },
      disposer: null,
    };
    handlers.disposer = this.on(event, handlers.listener);
    return handlers.disposer;
  }
}

const eventStream = new Streamable();

export const useLocalStorage = (key, defVal) => {
  const ref = React.useRef({});
  const [val, $val] = React.useState(() => {
    let itemVal = defVal;
    try {
      const str = localStorage.getItem(key);
      try {
        const parsed = JSON.parse(str);
        itemVal = parsed === null ? defVal : parsed;
        itemVal = _.isFunction(itemVal) ? itemVal() : itemVal;
      } catch (err) {
        itemVal = str;
      }
    } catch (err) {}
    return itemVal;
  });
  const [loading, $loading] = React.useState(false);

  Object.assign(ref.current, {
    val,
    $val,
    loading,
    $loading,
  });

  const setVal = (newVal) => {
    try {
      localStorage.setItem(key, JSON.stringify(newVal));
      ref.current.$val && ref.current.$val(newVal);
      eventStream.emit(key, newVal);
    } catch (err) {}
  };

  const applyVal = (newVal) => {
    try {
      localStorage.setItem(key, JSON.stringify(newVal));
      ref.current.$val && ref.current.$val(newVal);
      // emit anyone, accept me
      eventStream.emit(key, newVal);
    } catch (err) {}
  };

  const reload = () => {
    let itemVal = defVal;
    try {
      const str = localStorage.getItem(key);
      const parsed = JSON.parse(str);
      itemVal = parsed === null ? defVal : parsed;
      itemVal = _.isFunction(itemVal) ? itemVal() : itemVal;
    } catch (err) {}
    eventStream.emit(key, itemVal);
  };

  React.useEffect(() => {
    ref.current.disposer = eventStream.on(key, (newVal) => {
      setTimeout(() => {
        if (!_.isEqual(newVal, ref.current.val)) {
          ref.current.$val && ref.current.$val(newVal);
        }
      });
    });
    return () => {
      ref.current.disposer && ref.current.disposer();
      ref.current = {};
    };
  }, []);
  return [val, setVal, loading, applyVal, reload];
};

export const usePartialLocalStorage = (key, defVal, dataPath) => {
  const [store, $store, loading] = useLocalStorage(key, defVal);
  const ref = React.useRef({});
  const val = _.get(store, dataPath);
  _.assign(ref.current, { store, $store, val });

  const setVal = (newVal) => {
    if (ref.current.$store) {
      const newStore = _.set(_.cloneDeep(ref.current.store), dataPath, newVal);
      if (!_.isEqual(newStore, ref.current.store)) {
        ref.current.$store(newStore);
      }
    }
  };
  React.useEffect(() => {
    return () => {
      ref.current = {};
    };
  }, []);

  return [val, setVal, loading];
};

useLocalStorage.partial = usePartialLocalStorage;

export default useLocalStorage;
