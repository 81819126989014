import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Slider from 'react-slick';
import { Description, Title } from '../../components/title/index';
import Image from '../../components/image/index';
import img1 from '../../assets/images/testimonial/1.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './testimonial.scss';

function CarouselSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="testimonial-slider ">
      <DIV>
        <div className="testimonial-outer-box">
          <Slider {...settings}>
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <div className="testimonial-item" key={i}>
                <div className="testimonial-content">
                  <div className="animated-bg">
                    <i></i>
                    <i></i>
                    <i></i>
                  </div>
                  <Description
                    Class="testimonial-dec"
                    Name={ctx.apply('ctf.renderRichText', { name: `testimonialItem${i}` }, 'richText', null)}
                  />
                  <Title
                    Class="testimonial-title"
                    Name={ctx.apply('ctf.findEntry', { name: `testimonialItem${i}` }, 'shortText', '')}
                  />
                  <Title
                    Class="testimonial-subtitle"
                    Name={ctx.apply('ctf.findEntry', { name: `testimonialItem${i}` }, 'longText.longText', '')}
                  />
                  <div className="testimonial-images">
                    <Image Path={img1} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </DIV>
    </div>
  );
}

export default CarouselSlider;
