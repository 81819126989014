import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import RESABLE from '@vl/redata/RESABLE.macro';
import { resable } from '@vl/redata/RESABLE';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { Image } from '@uz/unitz-components-web/Image';
import { Button } from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import CourseRoomComingSoonInfo from '@uz/unitz-components-web/CourseRoomComingSoonInfo';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CourseSessionFormatter from '@uz/unitz-models/CourseSessionModel/formatter';
import CoursePurchaseButton from '@uz/unitz-components-web/CoursePurchaseButton';
import CourseDetailButton from '@uz/unitz-components-web/CourseDetailButton';
import i18n from 'i18n-js';
import moment from 'moment';
import _ from 'lodash';

const CustomList = styled.div`
  ul {
    li {
      list-style: none;
      margin-right: 0;
      &:first-child {
        &:before {
          content: '';
        }
      }
      &:before {
        content: '• ';
      }
    }
  }
`;

export const View8 = () => {
  moment.locale(i18n.locale);
  return (
    <DIV className="listItem">
      <Link
        {...ctx.apply('tracking.dataSet', {
          action: 'click',
          category: `${ctx.get('dataPage')}_card_course`,
          value: ctx.get('@item.courseInfo.id'),
          label: ctx.get('@item.courseInfo.displayName'),
        })}
        className="course-card-item"
        onMouseMove={() => ctx.apply('carouselModel.$dragging', true)}
        onMouseDown={() => ctx.apply('carouselModel.$dragging', false)}
        onClick={(event) => {
          if (ctx.get('carouselModel.dragging')) {
            event.preventDefault();
          }
        }}
        to={ctx.apply('routeStore.toUrl', 'courseDetail', ctx.get('@item'))}
        external
      >
        <div
          style={{ boxShadow: '0px 2px 6px rgba(38, 51, 64, 0.18)' }}
          className="flex flex-col mx-2 mb-4 rounded-lg bg-white500"
        >
          <div className="relative rounded-tl-lg rounded-tr-lg bg-background2">
            <RESABLE>
              {!!resable.for('avatarUrl')(ctx.get('@item.courseInfo.avatarUrl')) && (
                <ResponsiveProps
                  xs={{ style: { width: '100%', height: 154, objectFit: 'cover' }, height: 154 }}
                  md={{ style: { width: '100%', height: 178, objectFit: 'cover' }, height: 178 }}
                >
                  {(resProps) => (
                    <Image
                      src={ctx.get('@item.courseInfo.avatarUrl', '')}
                      className="overflow-hidden rounded-tl-lg rounded-tr-lg"
                      alt={ctx.get('@item.courseInfo.displayName')}
                      {...resProps}
                    />
                  )}
                </ResponsiveProps>
              )}
            </RESABLE>
            <div className="absolute px-2 py-1 border rounded-lg shadow-sm border-divider bg-white500 left-3 top-3">
              <div className="flex flex-row items-center justify-center space-x-1">
                {gstyles.icons({ name: 'star', size: 16, fill: gstyles.colors.yellow500 })}
                <span className="text-xs font-normal text-main">
                  5.0
                  {/* <RESABLE>{resable(ctx.get('@item.rating.ratingTxt'), '')}</RESABLE> */}
                </span>
              </div>
            </div>
            <div className="absolute px-1 py-1 border rounded-lg shadow-sm cursor-pointer border-divider bg-white500 right-3 top-3">
              <div>{gstyles.icons({ name: 'heart', size: 16, fill: gstyles.colors.sub })}</div>
            </div>
            <div className="absolute bottom-0 left-0 w-full px-4 py-2 min-md:py-3 bg-black300">
              <h3 className="mb-2 text-base font-semibold text-left text-ellipsis-1 text-white500">
                {ctx.get('@item.courseInfo.displayName')}
              </h3>
              <div className="flex flex-row items-center justify-start space-x-2">
                <RESABLE>
                  {!!resable.for('avatarUrl')(ctx.get('@item.advisor.avatarUrl')) && (
                    <Avatar
                      className="object-cover rounded-full"
                      size={20}
                      alt={ctx.get('@item.advisor.displayName')}
                      src={resable.for('avatarUrl')(ctx.get('@item.advisor.avatarUrl'), '')}
                    />
                  )}
                </RESABLE>
                <div className="mb-0 text-sm font-normal text-left text-white400">
                  {ctx.get('@item.advisor.displayName')}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start justify-center w-full p-4 space-y-3">
            <div className="h-36">
              <div className="flex flex-row items-center justify-start mb-3 space-x-2">
                {gstyles.icons({ name: 'clock', size: 20, fill: gstyles.colors.sub })}
                <span className="text-sm text-main">{ctx.get('@item.session_duration')}</span>
              </div>
              {/* <div className="flex flex-row items-center justify-start mb-3 space-x-2">
                {gstyles.icons({ name: 'calendar', size: 20, fill: gstyles.colors.sub })}
                <span className="text-sm text-main">
                  {ctx.apply('i18n.t', 'Course.Info.startAtTxt')} {ctx.get('@item.startAt')}
                </span>
              </div> */}
              <div className="mb-3">
                <CourseRoomComingSoonInfo className="ml-2 text-sm text-main" />
              </div>
              <div className="flex flex-row items-center justify-start mb-3 space-x-2">
                <div className="self-start">
                  {gstyles.icons({ name: 'repeat', size: 20, fill: gstyles.colors.sub })}
                </div>
                <CustomList>
                  <ul className="flex flex-wrap justify-between space-x-2">
                    {_.map(_.take(ctx.get('@item.sessions'), 3), (item) => (
                      <li className="text-sm font-normal text-main" key={_.get(item, 'id')}>
                        {CourseSessionFormatter.formatSessionTimeText(ctx)(item)}{' '}
                        {CourseFormatter.replaceSACH()(moment(_.get(item, 'start_at')).format('hh:mm A'))}
                      </li>
                    ))}
                  </ul>
                </CustomList>
              </div>
            </div>
            <div className="w-full h-10">
              {/* <CoursePurchaseButton showPrice showInfo={false} /> */}
              <CourseDetailButton showPrice showInfo={false} />
              {/* <Link to={ctx.apply('routeStore.toUrl', 'courseDetail', ctx.get('@item'))}>
                <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                  {(resProps) => (
                    <Button
                      focus="true"
                      type="primary"
                      icon={gstyles.icons({
                        name: 'cart-add',
                        size: 24,
                        fill: gstyles.colors.white500,
                        className: 'inline-block mr-2',
                      })}
                      block
                      {...resProps}
                    >
                      <span className="text-base font-semibold">{ctx.get('@item.price.amountText')}</span>
                    </Button>
                  )}
                </ResponsiveProps>
              </Link> */}
            </div>
          </div>
        </div>
      </Link>
    </DIV>
  );
};
export default displayName(View8);
