import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
// import { useRenderProps } from '@vl/hooks/useRenderProps';
import Slider from 'react-slick';
import AdvisorCardItemX from '@uz/unitz-components-web/AdvisorCardItemX';
import FlatList from '@uz/unitz-components-web/FlatList';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import Link from '@uz/unitz-components-web/Link';
import useRoute from '@vl/hooks/useGbRoute';

const CustomSlider = styled.div`
  .slick-slider {
    .slick-list {
      margin-bottom: 24px;
      padding: 0px !important;
      .slick-track {
        margin-top: 16px;
      }
    }
    .slick-dots {
      bottom: -30px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: ${gstyles.colors.background2};

          &:before {
            content: '';
          }
        }
        &.slick-active {
          button {
            background-color: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div
        className="absolute z-50 w-10 h-10 transform rounded-full cursor-pointer filter drop-shadow-md -right-10 min-md:right-4 min-md:translate-x-3/4 min-md:top-1/2 -translate-x-3/4 bottom-1/3 sm:-bottom-10 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-right', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div
        className="absolute z-50 w-10 h-10 transform rounded-full cursor-pointer filter drop-shadow-md -left-10 min-md:left-4 min-md:-translate-x-3/4 min-md:top-1/2 translate-x-3/4 bottom-1/3 sm:-bottom-10 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-left', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};

const View8 = () => {
  const route = useRoute();
  return (
    <DIV className="component">
      <CustomSlider style={{ minHeight: '405px' }}>
        <Slider
          className="relative"
          {...ctx.get('carouselModel.options')}
          {...{
            nextArrow: <NextButton onClick />,
            prevArrow: <PrevButton onClick />,
          }}
        >
          {_.map(ctx.get('@items', []), (item, index) => (
            <React.Fragment key={`advisor_card_${index}`}>
              <DIV forceCtx>
                {ctx.debug(() => {
                  ctx.set('@item', item);
                  ctx.set('@index', index);
                })}
                <AdvisorCardItemX />
              </DIV>
            </React.Fragment>
          ))}
        </Slider>
      </CustomSlider>
      {(() => {
        const selectedCategory = ctx.apply('form.getSelectedCategory');
        if (selectedCategory) {
          return (
            <div className="flex items-center justify-center pt-12 md:pt-8">
              <Link className="flex flex-row space-x-2" to={route.toLocale(_.get(selectedCategory, 'highlightSlug'))}>
                <span className="mb-0 text-base font-semibold text-brandb500">
                  {ctx.apply('i18n.t', 'Advisor.discoverMore')}
                </span>
                <span>{gstyles.icons({ name: 'arrow-right2', size: 24, fill: gstyles.colors.brandb500 })}</span>
              </Link>
            </div>
          );
        }
        return null;
      })()}
    </DIV>
  );
};

export default displayName(View8);
