import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import { Menu, Dropdown, Space } from 'antd';
import Link from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import { getLocaleConfig, getCurrentLocaleConfig } from '@uz/mod-translations/utils';
import useRoute from '@vl/hooks/useGbRoute';

const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
`;

const DesktopLangSwitch = () => {
  const route = useRoute();
  const localeConfigs = getLocaleConfig(false);
  const currentLocale = getCurrentLocaleConfig(route);
  return (
    <DIV>
      <div className="px-2">
        <Dropdown
          className="hidden lg:flex"
          overlay={
            <CustomDropDownContent className="shadow-xl">
              <Menu selectedKeys={[currentLocale.get('lang')]}>
                {localeConfigs.map((localeConfig) => {
                  return (
                    <Menu.Item className="py-1 px-3 hover:bg-brandb-lightest" key={localeConfig.get('lang')}>
                      <Link
                        className="text-main hover:text-main"
                        to={localeConfig.withLangPrefix(
                          `${_.get(route, 'location.pathname')}${_.get(route, 'location.search')}`
                        )}
                        onClick={() => localeConfig.setCurrent()}
                      >
                        <span className="text-main text-base font-normal">{_.toUpper(localeConfig.get('lang'))}</span>
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </CustomDropDownContent>
          }
          placement="bottomRight"
        >
          <div className="mb-4 lg:mb-0 text-main cursor-pointer border border-main px-3 rounded">
            <Space size="small">
              <span className="text-main text-base">{_.toUpper(currentLocale.get('lang'))}</span>
            </Space>
          </div>
        </Dropdown>
      </div>
    </DIV>
  );
};

export default DesktopLangSwitch;
