import React from 'react';
import _ from 'lodash';
import DataContext from '../DataContext';

const rule = ({ children, options, className }) => {
  const data = _.get(options, 'data');
  const RuleWrapper = () => (
    <DataContext.Provider value={data} className={className}>
      {children}
    </DataContext.Provider>
  );
  return RuleWrapper;
};

export default rule;
