import React from 'react';
import glightbox from '@uz/unitz-components-web/Glightbox';
import GLightBoxLoader from '@uz/unitz-components-web/Glightbox/loader';
import renderVideo from '@uz/unitz-components-web/Glightbox/renders/video';
import styled from 'styled-components';

const StyledLink = styled.a`
  .gvideo-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  position: relative;
`;

export const LinkPlayer = (props) => {
  const { to, children, ...rest } = props;
  React.useEffect(() => {
    setTimeout(() => {
      glightbox.apply();
    }, 500);
  }, []);
  const playerRef = React.useRef();

  React.useEffect(() => {
    if (to && playerRef.current) {
      const node = playerRef.current.querySelector('.gslide');
      if (!node) {
        playerRef.current.appendChild(
          renderVideo(
            {
              href: to,
              sizes: '',
              srcset: '',
              title: '',
              type: '',
              description: '',
              alt: '',
              descPosition: 'bottom',
              effect: '',
              width: '480',
              height: '270',
              content: false,
              zoomable: true,
              draggable: true,
            },
            0
          )
        );
      }
    }
  }, [to]);

  return (
    <>
      <GLightBoxLoader />
      <StyledLink className="glightbox cursor-pointer block" href={to} {...rest}>
        {children || <div className="mx-auto mt-2 w-96 h-52 relative" ref={playerRef}></div>}
      </StyledLink>
    </>
  );
};

export default LinkPlayer;
