import React from 'react';
import _ from 'lodash';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Avatar from '@uz/unitz-components-web/Avatar';
import UserModel from '@uz/unitz-models/UserModel';
import UserProfileModelFormatter from '@uz/unitz-models/UserProfileModel/formatter';
import { LinkToWrapper } from '@uz/unitz-components-web/Link';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import useMountedState from '@vl/hooks/useMountedState';
import { DataLoader } from '@vl/mod-utils/DataLoader';
import cx from 'classnames';

const Index = ({ user_id, query_str, showName, avatarClassName, textClassName, to, ...rest }) => {
  if (query_str) {
    const urlParams = new URLSearchParams(query_str);
    user_id = urlParams.get('user_id') || user_id;
    user_id = urlParams.get('advisor_id') || user_id;
  }

  const [fetching, $fetching] = useMountedState(false);
  const [user, $user] = useMountedState(() => {
    if (_.has(rest, 'item')) {
      return rest.item;
    }
    return null;
  });
  const ref = React.useRef({});
  const forceUpdate = useForceUpdate();
  _.assign(ref.current, { user_id, fetching, $fetching, user, $user });

  React.useEffect(() => {
    const user = ref.current.user || rest.item;
    if (user) {
      ref.current.$user(user);
    }
  }, [rest.item]);

  const fetchUserById = async () => {
    if (ref.current.user && _.get(ref.current.user, 'id') === ref.current.user_id) {
      return ref.current.user;
    }
    ref.current.$fetching && ref.current.$fetching(true);
    let user;
    try {
      user = await DataLoader(UserModel, async (user_id) => {
        const queryRes = await UserModel.find(
          `where: {id: { _eq: "${user_id}" }}`,
          `
          id 
          profile {
            display_name
            avatar_url
          }
        `
        );
        const data = _.get(queryRes.toObject(), 0);
        return data;
      }).load(ref.current.user_id);

      ref.current.$user && ref.current.$user(user);
    } catch (error) {
      console.log('error', error);
    } finally {
      ref.current.$fetching && ref.current.$fetching(false);
    }
    return user;
  };

  React.useEffect(() => {
    user_id && fetchUserById().then(() => forceUpdate());
    return () => {};
  }, [user_id]);

  if (!ref.current.user) return null;

  return (
    <DIV forceCtx>
      <LinkToWrapper to={to} {...rest}>
        <Avatar
          shape="circle"
          className={`object-cover rounded-md ${avatarClassName}`}
          size={{
            xs: 68,
            sm: 120,
            md: 136,
            lg: 168,
            xl: 144,
            xxl: 144,
          }}
          {...rest}
          src={UserProfileModelFormatter.avatarUrl(ctx)(ref.current.user)}
        />
        {showName && (
          <span className={cx(textClassName, 'mx-1')}>
            {(() => {
              if (_.isFunction(showName)) {
                return showName(ref.current.user);
              }
              return UserProfileModelFormatter.displayName(ctx)(ref.current.user);
            })()}
          </span>
        )}
      </LinkToWrapper>
    </DIV>
  );
};

export default displayName(Index);
