import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const Wrapper = styled.div`
  background-color: ${gstyles.colors.white500};
  @media only screen and (min-width: 801px) {
    background-color: ${gstyles.colors.brandb.lighter};
  }
`;

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const CustomCarousel = styled.div`
  .slick-slider {
    .slick-list {
      margin-bottom: 24px;
      .slick-track {
        padding: 20px 0;
        .slick-slide {
          transform: scale(0.9);
          &.slick-current {
            transform: scale(1);
            z-index: 9000;
            box-shadow: 0px 1px 6px rgba(38, 51, 64, 0.18);
            @media only screen and (max-width: 800px) {
              box-shadow: none;
            }
          }
        }
      }
    }
    .slick-dots {
      bottom: -65px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.background2};
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div className="absolute cursor-pointer bottom-2/4 -right-10 -translate-y-3/4 hover:opacity-90" onClick={onClick}>
        {gstyles.icons({ name: 'arrow-right', size: 40, fill: gstyles.colors.sub })}
      </div>
    </DIV>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div className="absolute cursor-pointer bottom-2/4 -left-10 -translate-y-3/4 hover:opacity-90" onClick={onClick}>
        {gstyles.icons({ name: 'arrow-left', size: 40, fill: gstyles.colors.sub })}
      </div>
    </DIV>
  );
};

const settings = {
  dots: false,
  centerMode: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        swipeToSlide: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
        swipeToSlide: true,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
      },
    },
  ],
};

const AdvisorRegistratorCommunity = () => {
  return (
    <DIV className="AdvisorRegistratorCommunity">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <Wrapper>
            <CustomCarousel>
              <div className="py-8 lg:py-14">
                <CustomParagraph className="text-center">
                  {ctx.apply(
                    'ctf.renderHTMLText',
                    { name: ctx.get('sectionData.name', '') },
                    'longText.longText',
                    null
                  )}
                </CustomParagraph>
                <div className="lg:wrapper mb-6 app-row px-0 py-8">
                  <Slider
                    {...settings}
                    {...{
                      nextArrow: <NextButton onClick />,
                      prevArrow: <PrevButton onClick />,
                    }}
                    afterChange={(current) => {
                      ctx.apply('VideoModel.setItem', current);
                    }}
                  >
                    {_.map(
                      _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []),
                      (item, index) => {
                        const itemData = ctx.apply('ctf.findNode', { name: _.get(item, 'name') });

                        return (
                          <React.Fragment key={index}>
                            {_.map(_.take(_.get(itemData, 'sections', []), 1), (itemSub) => {
                              return ctx.apply('ctf.renderItem', { name: _.get(itemSub, 'name', '') }) || null;
                            })}
                          </React.Fragment>
                        );
                      }
                    )}
                  </Slider>
                  {/* <div className="mx-4 lg:mx-0">
                    {ctx.apply('ctf.renderItem', { name: _.get(ctx.get('VideoModel.videoItem'), 'name', '') }) || null}
                  </div> */}
                </div>
              </div>
            </CustomCarousel>
          </Wrapper>
        );
      })}
    </DIV>
  );
};

export default AdvisorRegistratorCommunity;
