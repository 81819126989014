import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useRoute from '@vl/hooks/useGbRouteDe';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            ctfData: hook((ctx) => {
              const item = ctx.apply('ctf.findEntry', { name: 'UnitzBrand' });
              return item;
            }),

            '@items': hook(() => {
              const data = usePromiseSource(
                async () => {
                  try {
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetAccounts {
                          b2b_account(order_by: { created_at: desc_nulls_last }) {
                            id
                            slug
                            account_profile {
                              id
                              display_name
                            }
                            owner {
                              id
                              profile {
                                avatar_url
                                display_name
                              }
                            }
                          }
                        }
                      `
                    );
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                []
              );

              const activeAccountId = _.get(useRoute().getPageContextParams(), 'accountId');

              return {
                menuData: _.get(data, 'b2b_account', []),
                activeAccount: [activeAccountId],
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
