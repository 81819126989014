/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

function SEO({
  description, lang, meta, title
}) {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const siteMetadata = ctx.apply('ctf.findEntry', { name: 'siteMetadataItem' });

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={siteMetadata.shortText}
            titleTemplate={`%s | ${siteMetadata.shortText}`}
            meta={[
              {
                name: 'description',
                content: siteMetadata.shortText
              },
              {
                property: 'og:title',
                content: siteMetadata.shortText
              },
              {
                property: 'og:description',
                content: _.get(siteMetadata, 'longText.longText')
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: siteMetadata.shortText
              },
              {
                name: 'twitter:title',
                content: siteMetadata.shortText
              },
              {
                name: 'twitter:description',
                content: _.get(siteMetadata, 'longText.longText')
              }
            ]}
          />
        );
      })}
    </DIV>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
