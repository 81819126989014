const defaultCompare = (a, b) => {
  return a - b;
};

function mergeRanges(ranges, compare = defaultCompare, overlapDistance = 0) {
  if (!(ranges && ranges.length)) {
    return [];
  }

  // Stack of final ranges
  const stack = [];

  // Sort according to start value
  ranges.sort((a, b) => {
    return compare(a[0], b[0]);
  });

  // Add first range to stack
  stack.push(ranges[0]);

  ranges.slice(1).forEach((range) => {
    const top = stack[stack.length - 1];
    if (compare(top[1], range[0]) < overlapDistance) {
      // No overlap, push range onto stack
      stack.push(range);
    } else if (compare(top[1], range[1]) < overlapDistance) {
      // Update previous range
      top[1] = range[1];
    }
  });

  return stack;
}

module.exports = mergeRanges;
