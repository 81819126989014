import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    color: ${gstyles.colors.main};
  }
`;
const CustomCollapse = styled.div`
  .ant-collapse {
    border: none;
    background-color: ${gstyles.colors.white500};
    .ant-collapse-item {
      border-radius: 8px;
      padding: 0 16px;
      @media only screen and (min-width: 1200px) {
        padding: 0 24px;
      }
      border: 1px solid ${gstyles.colors.border};
      margin-bottom: 16px;
      &:last-child {
        border-radius: 8px;
        border: 1px solid ${gstyles.colors.border};
        margin-bottom: 16px;
      }
      .ant-collapse-header {
        padding: 16px 0;
        @media only screen and (min-width: 1200px) {
          padding: 24px 0;
        }
        .ant-collapse-arrow {
          right: 0px;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          border-radius: 8px;
          padding-left: 0px;
          padding-right: 0;
        }
      }
    }
  }
`;

const AdvisorRegisterFAQLayout = () => {
  return (
    <DIV className="AdvisorRegisterFAQContent">
      {ctx.apply('ctf.renderProps', () => {
        // Fix error: Cannot read property 'offsetHeight' of null
        // const handleResize = () => {
        //   let highestSlide = 0;
        //   _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).forEach((ref) => {
        //     if (ref.current && highestSlide < ref.current.offsetHeight) {
        //       highestSlide = ref.current.offsetHeight;
        //     }
        //   });
        // };
        // React.useLayoutEffect(() => {
        //   handleResize();
        // }, []);

        return (
          <div className="sm:app-row sm:wrapper" id={ctx.get('tabData')}>
            <CustomCollapse>
              <Collapse
                // defaultActiveKey={[0]}
                destroyInactivePanel
                ghost
                accordion
                expandIcon={(panelProps) => {
                  if (!panelProps.isActive) {
                    return <span>{gstyles.icons({ name: 'arrow-down', size: 24, fill: gstyles.colors.sub })}</span>;
                  }
                  return <span>{gstyles.icons({ name: 'arrow-up', size: 24, fill: gstyles.colors.sub })}</span>;
                }}
                expandIconPosition="right"
              >
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (item, index) => {
                    return (
                      <Panel
                        header={
                          <h5 className="mb-0 text-sm lg:text-base text-main font-semibold w-5/6">
                            {_.get(item, 'shortText', '')}
                          </h5>
                        }
                        key={index}
                      >
                        <CustomParagraph>
                          {ctx.apply('ctf.renderHTMLText', { name: _.get(item, 'name', '') }, 'longText.longText', {
                            as: 'p',
                          })}
                        </CustomParagraph>
                      </Panel>
                    );
                  }
                )}
              </Collapse>
            </CustomCollapse>
          </div>
        );
      })}
    </DIV>
  );
};

export default AdvisorRegisterFAQLayout;
