import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import RESABLE from '@vl/redata/RESABLE.macro';
import { resable } from '@vl/redata/RESABLE';
import { Row, Col } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import { Image } from '@uz/unitz-components-web/Image';
import { Button } from '@uz/unitz-components-web/Button';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';
import gstyles from '@vl/gstyles';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import styled from 'styled-components';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CourseSessionFormatter from '@uz/unitz-models/CourseSessionModel/formatter';
import i18n from 'i18n-js';
import moment from 'moment';
import _ from 'lodash';
import CourseRoomComingSoonInfo from '@uz/unitz-components-web/CourseRoomComingSoonInfo';
import CoursePurchaseButton from '@uz/unitz-components-web/CoursePurchaseButton';
import CourseDetailButton from '@uz/unitz-components-web/CourseDetailButton';
import cx from 'classnames';

const CustomList = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  ul {
    li:first-child {
      list-style-type: none;
      margin-left: 0;
    }
  }
`;

export const View8 = ({ noneBorder = false }) => {
  moment.locale(i18n.locale);
  return (
    <DIV className="listItem">
      <div
        className={cx('course-item sm:hover:bg-brandb-lightest bg-white500 ', {
          'border-b': !noneBorder,
        })}
      >
        <div className="py-6">
          <Row
            style={{ marginLeft: 0, marginRight: 0 }}
            gutter={[
              { xs: 18, md: 18, lg: 18 },
              { xs: 18, md: 18, lg: 18 },
            ]}
          >
            <Col xs={24} md={23} xl={24}>
              <Link
                {...ctx.apply('tracking.dataSet', {
                  action: 'click',
                  category: `${ctx.get('dataPage')}_card_course`,
                  value: ctx.get('@item.courseInfo.id'),
                  label: ctx.get('@item.courseInfo.displayName'),
                })}
                to={ctx.apply('routeStore.toUrl', 'courseDetail', ctx.get('@item'))}
                external
              >
                <Row gutter={{ xs: 20, md: 2, lg: 2, xl: 20 }}>
                  <Col
                    style={{ paddingLeft: 0, paddingRight: 10 }}
                    className="mb-3 sm:mb-0"
                    xs={6}
                    md={8}
                    lg={8}
                    xl={6}
                  >
                    <ResponsiveProps
                      xs={{ style: { width: '100%', objectFit: 'cover' } }}
                      md={{
                        style: { width: '200px', objectFit: 'cover' },
                        width: '200px',
                        height: '200px',
                      }}
                    >
                      {(resProps) => (
                        <div
                          className="bg-background2 rounded-xl"
                          style={{ width: resProps.width, height: resProps.height }}
                        >
                          <Image
                            className="h-full overflow-hidden rounded-xl"
                            src={ctx.get('@item.courseInfo.avatarUrl')}
                            alt={ctx.get('@item.courseInfo.displayName')}
                            ratio={1}
                            {...resProps}
                          />
                        </div>
                      )}
                    </ResponsiveProps>
                  </Col>
                  <Col style={{ paddingLeft: 0 }} xs={18} md={16} lg={16} xl={18}>
                    <div className="flex flex-col mb-4 space-y-3">
                      <div className="text-base font-semibold text-ellipsis-2 text-main">
                        {ctx.get('@item.courseInfo.displayName', '')}
                      </div>

                      <div className="flex flex-row items-center justify-start space-x-2">
                        <RESABLE>
                          {!!resable.for('avatarUrl')(ctx.get('@item.advisor.avatarUrl')) && (
                            <Avatar
                              className="object-cover rounded-full"
                              alt={ctx.get('@item.advisor.displayName')}
                              size={20}
                              src={resable.for('avatarUrl')(ctx.get('@item.advisor.avatarUrl'), '')}
                            />
                          )}
                        </RESABLE>
                        <div className="mb-0 text-sm font-normal text-left text-sub">
                          {ctx.get('@item.advisor.displayName')}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-start space-x-2">
                        <div>{gstyles.icons({ name: 'clock', size: 20, fill: gstyles.colors.sub })}</div>
                        <span className="text-sm text-main">{ctx.get('@item.session_duration')}</span>
                      </div>
                      {/* <div className="flex flex-row items-center justify-start space-x-2">
                        <div>{gstyles.icons({ name: 'calendar', size: 20, fill: gstyles.colors.sub })}</div>
                        <span className="text-sm text-main">
                          {ctx.apply('i18n.t', 'Course.Info.startAtTxt')} {ctx.get('@item.startAt')}
                        </span>
                      </div> */}
                      <div className="mb-3">
                        <CourseRoomComingSoonInfo className="ml-2 text-sm text-main" />
                      </div>
                      <div className="flex flex-row items-center justify-start space-x-2">
                        <div>{gstyles.icons({ name: 'repeat', size: 20, fill: gstyles.colors.sub })}</div>
                        <CustomList>
                          <ul className="flex flex-row flex-wrap m-0 mr-4">
                            {_.map(_.take(ctx.get('@item.sessions'), 3), (item) => (
                              <li className="ml-3 mr-2 text-sm font-normal list-disc text-main" key={_.get(item, 'id')}>
                                {CourseSessionFormatter.formatSessionTimeText(ctx)(item)}{' '}
                                {CourseFormatter.replaceSACH()(moment(_.get(item, 'start_at')).format('hh:mm A'))}
                              </li>
                            ))}
                          </ul>
                        </CustomList>
                      </div>
                    </div>
                    <div className="flex flex-wrap lg:space-x-2">
                      {/* <CoursePurchaseButton showPrice showInfo fullWidth /> */}
                      <CourseDetailButton showPrice showInfo fullWidth />
                    </div>
                  </Col>
                </Row>
              </Link>
            </Col>
            {/* <Col xs={24} md={24} lg={24} xl={6}> */}
            {/* <div className="items-center h-full xl:flex">
                <Row gutter={10} className="xl:w-full xl:space-y-3">
                  <Col xs={12} md={12} lg={12} xl={24}>
                    <Link to={ctx.apply('routeStore.toUrl', 'coursePurchase', ctx.get('@item'))}>
                      <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                        {(resProps) => (
                          <Button
                            type="primary"
                            icon={gstyles.icons({
                              name: 'cart-add',
                              size: 20,
                              fill: gstyles.colors.white500,
                              className: 'inline-block mr-2',
                            })}
                            block
                            {...resProps}
                          >
                            <span className="text-sm font-semibold md:text-base">
                              {ctx.get('@item.price.amountText')} / {ctx.apply('i18n.t', 'CourseFilter.sessionUnit')}
                            </span>
                          </Button>
                        )}
                      </ResponsiveProps>
                    </Link>
                  </Col>
                  {!!ctx.get('@item.video_url') && (
                    <Col xs={12} md={12} lg={12} xl={24}>
                      <LinkPlayer to={ctx.get('@item.video_url')}>
                        <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                          {(resProps) => (
                            <Button
                              icon={gstyles.icons({
                                name: 'video-library',
                                size: 20,
                                fill: gstyles.colors.white500,
                                className: 'inline-block mr-2',
                              })}
                              to={'#'}
                              name="ink"
                              block
                              {...resProps}
                            >
                              <span className="text-sm font-semibold md:text-base">
                                {ctx.apply('i18n.t', 'CourseFilter.videoDemo')}
                              </span>
                            </Button>
                          )}
                        </ResponsiveProps>
                      </LinkPlayer>
                    </Col>
                  )}
                </Row>
              </div> */}
            {/* </Col> */}
          </Row>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
