import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const LandingVideoLayout = () => {
  return (
    <DIV className="LandingVideoLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <div className="wrapper app-row">
            <div className="flex flex-col mb-6 justify-center items-center">
              <h2 className="mb-6 lg:mb-16 text-2xl lg:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
            </div>
            <Row
              align="middle"
              gutter={[
                { xs: 16, lg: 24 },
                { xs: 18, md: 18, lg: 24 },
              ]}
            >
              {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                return (
                  <Col xs={24} lg={12} key={index}>
                    <React.Fragment>{ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}</React.Fragment>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default LandingVideoLayout;
