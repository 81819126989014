import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';

const View8 = ({ className }) => {
  return (
    <DIV className="CourseRoomComingSoonInfo">
      <div>
        {!!ctx.get('@item.rooms.length') ? (
          <div className="flex items-center">
            <div>{gstyles.icons({ name: 'coming-soon', size: 20, fill: gstyles.colors.sub })}</div>
            <span className={className}>
              <span>{ctx.apply('i18n.t', 'Course.coming_room')}</span>{' '}
              {`${ctx.get('@item.room.startAt')} | ${ctx.get('@item.room.startAtTime')}`}
            </span>
          </div>
        ) : (
          <div className="flex items-center mb-3">
            <div>{gstyles.icons({ name: 'calendar2', size: 20, fill: gstyles.colors.sub })}</div>
            <span className={className}>
              {ctx.apply('i18n.t', 'Course.openingDay', { startAt: ctx.get('@item.startAt') })}
            </span>
          </div>
        )}
      </div>
    </DIV>
  );
};

export default displayName(View8);
