import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';

const stylingFn = (props) => {
  const { ctx } = props;
  const url = ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image');
  if (!url) return '';
  // return `
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     left: 0;
  //     bottom: 0;
  //     background-size: cover;
  //   }
  // `;
};

const components = {};

const getStyledAs = (name) => {
  if (!components[name]) {
    let styledCom;
    if (styled[name]) {
      styledCom = styled[name]`
        ${stylingFn}
      `;
    } else {
      styledCom = styled(name)`
        ${stylingFn}
      `;
    }
    components[name] = styledCom;
  }
  return components[name];
};

export const SectionWrapper = (props) => {
  let { as, ...rest } = props;
  as = as || 'div';
  const Com = getStyledAs(as);
  return (
    <DIV>
      <Com {...rest} ctx={ctx} />
    </DIV>
  );
};

export default SectionWrapper;
