import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { Link } from '@uz/unitz-components-web/Link';

export const TextExternalLink = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <Link
          to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref'))}
          aria-label={_.get(itemData, 'shortText')}
          target="_blank"
        >
          <span
            className={cx(
              ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
              _.get(itemProps, 'className')
            )}
          >
            {ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', '']))}
          </span>
        </Link>
      );
    })}
  </DIV>
);

export default TextExternalLink;
