import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const CustomBackground = styled.div`
  background: ${gstyles.colors.brandb500};
  z-index: -2;
  position: relative;
  width: 100%;
`;

// const CustomShape = styled.div`
//   background: ${gstyles.colors.brandb.darker};
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   z-index: -1;
// `;

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 24px;
    @media only screen and (max-width: 576px) {
      font-size: 14px;
    }
    margin: 0;
    color: ${gstyles.colors.white500};
  }
`;

const AboutUsBannerLayout = () => {
  return (
    <DIV className="AboutUsBannerLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <CustomBackground>
            {/* <ResponsiveProps
              xs={{
                renderer: () => (
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                      d="M-47.68,75.50 C333.23,58.72 233.92,144.58 552.76,128.80 L532.44,174.19 L0.00,150.00 Z"
                      style={{ stroke: 'none', fill: '#2EB2AA' }}
                    ></path>
                  </svg>
                ),
              }}
              md={{
                renderer: () => (
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path
                      d="M-70.26,187.02 C102.42,78.47 307.84,38.00 573.64,186.03 L490.12,179.13 L-7.05,174.19 Z"
                      style={{ stroke: 'none', fill: '#2EB2AA' }}
                    ></path>
                  </svg>
                ),
              }}
            >
              {(resProps) => (
                <CustomShape>
                  <div style={{ height: '120px', overflow: 'hidden' }}>{!!resProps && resProps.renderer()}</div>
                </CustomShape>
              )}
            </ResponsiveProps> */}

            <div className="wrapper app-row">
              <div className="flex flex-col mb-6 justify-center items-center">
                <h2 className="mb-2 text-white500 text-2xl lg:text-5xl font-semibold text-center">
                  {ctx.get('sectionData.shortText')}
                </h2>
                <CustomParagraph>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                    className: 'text-center',
                  })}
                </CustomParagraph>
              </div>
            </div>
          </CustomBackground>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsBannerLayout;
