import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Image from '@uz/unitz-components-web/Image';
import gstyles from '@vl/gstyles';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';

export const VideoLinkItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <div className={_.get(itemData, 'className', '')}>
          <LinkPlayer to={_.get(itemData, 'linkHref', '')}>
            <div className="relative">
              <Image
                size="cover"
                srcParams={{ w: 600 }}
                src={ctx.apply('ctf.findImage', itemData, 'image', '')}
                alt={_.get(itemData, 'id')}
              />
            </div>
          </LinkPlayer>

          <div className="text-main text-xl font-semibold my-3">{_.get(itemData, 'shortText', '')}</div>
        </div>
      );
    })}
  </DIV>
);

export default VideoLinkItem;
