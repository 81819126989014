import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Alert } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Form, FormItem, DatePicker, Checkbox } from 'formik-antd';
import moment from 'moment';
import CustomInput from '../CustomInput';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SESSION_START_TIME_FORMAT = 'HH:mm';

export default function CourseRoomRescheduleModal() {
  return (
    <DIV className="courseRoomRescheduleModal">
      <Modal
        title={ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.title')}
        visible={ctx.get('form.isModalVisible')}
        onOk={ctx.get('form.handleSubmit')}
        okText={ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.okText')}
        onCancel={ctx.get('form.handleCancel')}
        cancelText={ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.cancelText')}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          id: 'submit',
          disabled: !ctx.get('form.canSubmit'),
          loading: ctx.get('form.isSubmitting'),
          className: 'px-8',
        }}
        destroyOnClose
        afterClose={() => ctx.apply('modalModel.hide')}
      >
        <>
          {ctx.get('form.isModalVisible') &&
            (() => {
              const dateFormatter = (value) => {
                const TIME_FORMAT = 'HH:mm';
                const DATE_FORMAT = 'YYYY/MM/DD';
                const session_duration = ctx.get('form.values.session_duration');
                const endValue = value.clone().add(session_duration, 'second');
                return `${value.format(`${DATE_FORMAT} ${TIME_FORMAT}`)} - ${endValue.format(TIME_FORMAT)}`;
              };
              const disabledDate = (date) => {
                return !date || date.isBefore(moment(), 'days');
              };
              const disabledTime = (date) => {
                return !date || date.isBefore(moment(), 'hours');
              };

              return (
                <FormProvider form={ctx.get('form')}>
                  <Form {...layout}>
                    {ctx.get('form.status.error') && (
                      <div className="py-2">
                        <Alert message={ctx.get('form.status.error.message')} type="error" />
                      </div>
                    )}
                    {!!ctx.get('form.errors.sessions') &&
                      (_.isString(ctx.get('form.errors.sessions')) ||
                        React.isValidElement(ctx.get('form.errors.sessions'))) && (
                        <div className="py-2">
                          <Alert message={ctx.get('form.errors.sessions')} type="error" showIcon />
                        </div>
                      )}

                    {/* <Alert message={ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.infoMessage')} type="info" /> */}
                    <CustomInput>
                      <FormItem
                        name={'old_start_at'}
                        label={
                          <span className="text-sm font-nornal text-sub">
                            {ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.currentTimeFrameLabel')}
                          </span>
                        }
                      >
                        <DatePicker
                          // allowClear={false}
                          size="large"
                          showTime={{
                            hourStep: 1,
                            minuteStep: 15,
                            format: SESSION_START_TIME_FORMAT,
                          }}
                          suffixIcon={gstyles.icons({
                            name: 'calendar',
                            size: 20,
                            fill: gstyles.colors.sub,
                          })}
                          format={dateFormatter}
                          name={'old_start_at'}
                          className="w-full"
                          disabled
                        />
                      </FormItem>
                    </CustomInput>
                    <CustomInput>
                      <FormItem
                        name={'start_at'}
                        label={
                          <span className="text-sm font-nornal text-sub">
                            {ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.newTimeFrameLabel')}
                          </span>
                        }
                      >
                        <DatePicker
                          allowClear={false}
                          size="large"
                          showTime={{
                            hourStep: 1,
                            minuteStep: 15,
                            format: SESSION_START_TIME_FORMAT,
                          }}
                          suffixIcon={gstyles.icons({
                            name: 'calendar',
                            size: 20,
                            fill: gstyles.colors.sub,
                          })}
                          format={dateFormatter}
                          disabledDate={disabledDate}
                          disabledTime={disabledTime}
                          name={'start_at'}
                          className="w-full"
                        />
                      </FormItem>
                    </CustomInput>
                    {/* <CustomInput>
                      <FormItem name="update_same_rooms">
                        <Checkbox name="update_same_rooms">
                          {ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.updateSameRoomsLabel')}
                        </Checkbox>
                      </FormItem>
                    </CustomInput> */}
                  </Form>
                </FormProvider>
              );
            })()}
        </>
      </Modal>
    </DIV>
  );
}
