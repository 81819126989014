import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import { Image } from '@uz/unitz-components-web/Image';
import _ from 'lodash';

const StepOneLayout = () => {
  return (
    <DIV className="StepOneContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="py-4">
            <Row>
              <Col xs={24} md={24} lg={12}>
                <div className="flex mb-8 lg:mb-0 justify-center items-center">
                  <Image
                    size="cover"
                    src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                    alt="phone"
                  />
                </div>
              </Col>
              <Col xs={24} md={24} lg={12}>
                <h4 className="text-brandb500 text-center lg:text-left mb-2 text-xl font-semibold">
                  {ctx.get('sectionData.shortText')}
                </h4>

                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                  className: 'mb-8 text-base font-semibold text-left',
                  as: 'div',
                })}

                <div className="w-full px-4 lg:px-0 lg:w-3/4 mb-3 grid grid-rows-2 grid-flow-col">
                  {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                    (item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                        </React.Fragment>
                      );
                    }
                  )}
                </div>

                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'detailText.detailText', {
                  as: 'div',
                  className: 'px-4 lg:px-0',
                })}
              </Col>
            </Row>
          </div>
        );
      })}
    </DIV>
  );
};

export default StepOneLayout;
