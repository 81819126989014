import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParagraph = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const WhyUsLayout = () => {
  return (
    <DIV className="WhyUsSectionContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div>
            <div className="wrapper app-row">
              <div>
                <h2 className="mb-6 text-4xl font-semibold text-center text-main">
                  {ctx.get('sectionData.shortText')}
                </h2>
                <CustomParagraph>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                    className: 'mt-5 text-center',
                    as: 'p',
                  })}
                </CustomParagraph>
                <CustomParagraph>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'detailText.detailText', {
                    className: 'py-2 text-center mb-0',
                    as: 'div',
                  })}
                </CustomParagraph>
              </div>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default WhyUsLayout;
