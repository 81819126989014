import graphme from '@unitz/graphme';

import CFDefinition from '../CFDefinition';

class CFAdvisorProfileModel extends graphme.BaseModel {
  static DEFINITION = CFDefinition.create({
    name: 'CFAdvisorProfileModel',
    schema: {
      // id: String,
      displayName: String,
      advisor_id: String,
    },
    key: 'id',
    nodes: [['sys.id', String, { usePlanSync: true }]],

    baseQuery: graphme.GqlBuilder.loadDocument('query { advisorProfile { sys { id } displayName } }'),
    GQL_ACTIONS: {
      GET: 'advisorProfile',
    },
  });

  static async getProfile(profileId) {
    const advisorProfile = CFAdvisorProfileModel.fromData({}).setArgs(`id: "${profileId}"`).setSelections(`
        sys { id firstPublishedAt }
        displayName
        avatarUrl { url }
        yearsExperience
        categoriesCollection {
          items {
            displayName
            icon
          }
        }
        calendar
        appraisalType
        skillsCollection {
          items {
            displayName
            sys {
              id
            }
          }
        }
        services: servicesCollection {
          items {
            sys {
              id
            }
            name
            nameTxt
            icon
          }
        }
      `);
    await advisorProfile.sync();
    return advisorProfile;
  }

  static async getProfileFull(profileId) {
    const advisorProfile = CFAdvisorProfileModel.fromData({}).setArgs(`id: "${profileId}"`).setSelections(`
        sys { id firstPublishedAt }
        displayName
        avatarUrl { url }
        categoriesCollection {
          items {
            displayName
            icon
          }
        }
        calendar
        appraisalType
        skillsCollection {
          items {
            displayName
            sys {
              id
            }
          }
        }
        degrees
        services: servicesCollection {
          items {
            sys {
              id
            }
            name
            nameTxt
            icon
          }
        }
        displayName
        avatarUrl {
          url
        }
        overview
        intro {
          json
        }
      `);
    await advisorProfile.sync();
    return advisorProfile;
  }
}

graphme.model({ CFAdvisorProfileModel });

export default CFAdvisorProfileModel;
