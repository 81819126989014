import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
  }
`;

export const AdvisorBenefitItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemProps = ctx.get('itemProps');
      const listIndex = ctx.get('index') + 1;
      return (
        <Row className="py-4 lg:py-8">
          <Col lg={4} xl={3}>
            <div className="hidden lg:block mx-auto w-24 h-24">
              <Image
                className="object-cover"
                size="cover"
                src={ctx.apply(
                  'ctf.findImage',
                  ctx.get('itemData'),
                  _.get(itemProps, 'fieldNames.image', 'image'),
                  ...[].concat(_.get(itemProps, 'args') || [])
                )}
                alt={ctx.get('itemData.shortText')}
              />
            </div>
          </Col>
          <Col lg={20} xl={21}>
            <h4 className="font-semibold text-base lg:text-xl mb-1">{`${listIndex}. ${_.get(
              ctx.get('itemData'),
              'shortText',
              ''
            )}`}</h4>
            <CustomParagraph>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                as: 'p',
              })}
            </CustomParagraph>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default AdvisorBenefitItem;
