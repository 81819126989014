import _ from 'lodash';
import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetTransaction from 'unitz-gql/transaction/GetTransaction.gql';

graphme.GqlBuilder.loadDocument({
  GetTransaction,
});

class TransactionModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Transaction',
    schema: {
      id: String,
      advisor_id: String,
      user_id: String,
      session_id: String,
    },
    nodes: [
      ['advisor', 'AdvisorModel', { column_mapping: { advisor_id: 'id' } }],
      ['user', 'UserModel', { column_mapping: { user_id: 'id' } }],
    ],
    key: 'id',

    getForeignKeys: ['advisor_id', 'user_id'],

    foreignKeysMapping: {
      advisor_id: ['Advisor', 'id'],
      user_id: ['user', 'id'],
    },

    baseQuery: 'GetTransaction',

    GQL_ACTIONS: _.memoize(() => {
      return {
        FIND: 'transaction',
        GET: 'transaction_by_pk',
        INSERT: 'insert_transaction_one',
        INSERT_MANY: 'insert_transaction',
        UPDATE: 'update_transaction_by_pk',
        DELETE: 'delete_transaction_by_pk',
        DELETE_MANY: 'delete_transaction',
      };
    })(),

    getClient: hasuraClient.getClient,
  });
  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
  /**
   *
   * @param {*} param0
   */
  static async create({ user_id, advisor_id, session_id }) {
    const query = hasuraClient.gql`
      mutation createTransaction($user_id: String!, $advisor_id: String!, $session_id: String!) {
        transaction: insert_transaction(objects: {user_id: $user_id, advisor_id: $advisor_id, session_id: $session_id}) {
          returning {
            id
            user_id
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        advisor_id,
        session_id,
      });
      const data = _.get(rtn, 'transaction.returning');
      if (data) {
        return this.fromData(data);
      }
    } catch (err) {
      console.log(err);
    }

    throw Error('Create user error');
  }
}

graphme.model({ TransactionModel });

export default TransactionModel;
