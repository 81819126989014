import React from 'react';
import { bindings, ctx, hook } from '@vl/redata';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const bindData = bindings({
  LandingHowWeWorkLayout: {
    rules: [
      [
        'data',
        {
          data: {
            sections: hook((ctx) => {
              const parentSection = ctx.apply('ctf.renderProps', () => {
                return ctx.get('sectionData.sections');
              });

              const sectionName = [
                { ctfName: _.get(parentSection, '0.name', ''), displayName: _.get(parentSection, '0.shortText', '') },
                // { ctfName: _.get(parentSection, '1.name', ''), displayName: _.get(parentSection, '1.shortText', '') },
              ];
              return sectionName;
            }),

            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  section: ctx.get('sections.0.ctfName'),
                },
                validationSchema: Yup.object().shape({}),
              });
              _.assign(form, {
                getSelectedCategory: () => {
                  const section = _.get(form, 'values.section');
                  const selected = _.find(ctx.get('sections'), { ctfName: section });
                  return selected;
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
