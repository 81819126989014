import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import LangSwitch from '@uz/unitz-ctf-theme/layouts/LangSwitchUS';
import Link from '@uz/unitz-components-web/Link';
// const hasScrollY = () => {
//   // eslint-disable-next-line
//   const [hasScroll, $hasScroll] = React.useState(false);
//   // eslint-disable-next-line
//   React.useEffect(() => {
//     if (typeof window !== 'undefined') {
//       window.addEventListener('scroll', () => {
//         let scroll = null;
//         if (window.scrollY === 0) {
//           scroll = false;
//         } else {
//           scroll = true;
//         }
//         $hasScroll(scroll);
//       });
//     }
//   }, []);
//   return hasScroll;
// };

// const StickyNav = styled(({ children, className }) => {
//   const hasScroll = hasScrollY();
//   const { observe, height } = useDimensions({
//     onResize: _.throttle(() => {}, 1000),
//   });
//   const ref = React.useRef({});
//   const placeHolderHeight = React.useMemo(() => {
//     if (height > 0) {
//       ref.current.height = height;
//     }
//     return ref.current.height;
//   }, [height]);

//   return (
//     <div className={className}>
//       <div className="header-Placeholder" style={{ height: placeHolderHeight }} />
//       <header className={cx(`header-sticky ${hasScroll ? 'sticky' : ''}`)} ref={observe}>
//         {children}
//       </header>
//     </div>
//   );
// })`
//   > .sticky {
//     position: fixed;
//   }
// `;

const CustomLang = styled.div`
  > div {
    padding: 0;
    margin-left: 16px;
  }
`;

const DesktopNavbar = () => {
  return (
    <DIV>
      <SectionWrapper
        className={`relative h-20 ${
          !!ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }, 'className', '')
            ? ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }, 'className', '')
            : 'bg-brandb500'
        }`}
        as="div"
      >
        <div className="lg:px-4 xl:px-0 flex items-center flex-row h-20 space-x-2 app-row">
          <div className="flex items-center justify-center">
            <Link
              to={ctx.apply(
                'routeStore.toLocale',
                ctx.apply('ctf.findEntry', { name: ctx.apply('ctf.findSectionChild', 0, 'name') }, 'linkHref', '')
              )}
            >
              <div className="mr-2 flex space-x-2 items-center">
                <Image
                  size={40}
                  src={ctx.apply('ctf.findImage', { name: ctx.apply('ctf.findSectionChild', 0, 'name') }, 'image', '')}
                  alt="banner"
                />
                <span className="text-2xl text-main font-semibold">Unitz</span>
              </div>
            </Link>
          </div>
          <div className="flex items-center flex-row items-center flex-1">{ctx.apply('ctf.renderSectionChild', 1)}</div>
          <div className="flex flex-row items-center">
            <div className="search-menu-account-wrapper">{ctx.apply('ctf.renderSectionChild', 2)}</div>
            <CustomLang>
              <LangSwitch />
            </CustomLang>
          </div>
        </div>
      </SectionWrapper>
    </DIV>
  );
};

export default DesktopNavbar;
