import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Button } from '@uz/unitz-components-web/Button';
import { Row, Col } from 'antd';
import { Input, Form, FormItem, Checkbox } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import ModalProvider from '@uz/unitz-providers/ModalProvider';

const CustomInput = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
    label {
      span {
        color: ${gstyles.colors.sub} !important;
      }
    }
  }
  textarea,
  input {
    border-radius: 8px;
    border-color: ${gstyles.colors.divider};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const View8 = () => {
  return (
    <DIV className="component">
      <ModalProvider>
        {ctx.debug(() => {
          ctx.set('modalModel', ctx.get('modalModel'));
        })}
      </ModalProvider>
      <ResponsiveProps
        xs={{ style: { boxShadow: 'none' } }}
        lg={{ style: { boxShadow: '0px 2px 12px rgba(38, 51, 64, 0.24)' } }}
      >
        {(resProps) => (
          <div className="bg-white500 rounded-lg md:p-6" {...resProps}>
            <DIV className="applyForm">
              <FormProvider form={ctx.get('form')}>
                <Form {...layout} className="survey-form">
                  <CustomInput>
                    <FormItem
                      name="fullName"
                      label={
                        <span className="font-nornal text-sm text-sub">{ctx.apply('i18n.t', 'Signup.fullname')}</span>
                      }
                      required
                    >
                      <Input name="fullName" size="medium" placeholder={ctx.apply('i18n.t', 'Signup.fullname')} />
                    </FormItem>
                  </CustomInput>

                  <CustomInput>
                    <FormItem
                      name="email"
                      label={
                        <span className="font-nornal text-sm text-sub">{ctx.apply('i18n.t', 'Signup.email')}</span>
                      }
                      required
                    >
                      <Input name="email" size="medium" placeholder={ctx.apply('i18n.t', 'Signup.email')} />
                    </FormItem>
                  </CustomInput>

                  <CustomInput>
                    <FormItem
                      name="phone"
                      label={
                        <span className="font-nornal text-sm text-sub">{ctx.apply('i18n.t', 'Signup.phone')}</span>
                      }
                      required
                    >
                      <Input name="phone" size="medium" placeholder={ctx.apply('i18n.t', 'Signup.phone')} />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem
                      name="message"
                      label={
                        <span className="font-nornal text-sm text-sub">{ctx.apply('i18n.t', 'Signup.message')}</span>
                      }
                      required
                    >
                      <Input.TextArea name="message" rows={4} placeholder={ctx.apply('i18n.t', 'Signup.message')} />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem name="check" required>
                      <Checkbox name="check">
                        <div className="mb-0 text-sm text-main font-normal">
                          {ctx.apply('i18n.t', 'Signup.check_get_noti')}
                        </div>
                      </Checkbox>
                    </FormItem>
                  </CustomInput>
                  <Row>
                    <Col span={24}>
                      <div className="flex justify-center">
                        <ResponsiveProps xs={{ size: 'large' }} md={{ size: 'large' }} lg={{ size: 'large' }}>
                          {(resProps) => (
                            <Button
                              type="primary"
                              htmlType="submit"
                              disabled={!ctx.get('form.canSubmit')}
                              loading={ctx.get('form.isSubmitting')}
                              block
                              {...resProps}
                            >
                              <span style={{ color: gstyles.colors.white500 }}>
                                {ctx.apply('i18n.t', 'ProfileAdvisor.send')}
                              </span>
                            </Button>
                          )}
                        </ResponsiveProps>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </FormProvider>
            </DIV>
          </div>
        )}
      </ResponsiveProps>
    </DIV>
  );
};

export default displayName(View8);
