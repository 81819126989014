import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import { Button } from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import cx from 'classnames';
import _ from 'lodash';
import { Row, Col } from 'antd';

const CustomImage = styled(Image)`
  .ant-image {
    position: relative;
    padding-top: ${(props) => props.ratio * 100 || 0}%;
    overflow: hidden;
    .ant-image-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`;
export const View8 = () => {
  return (
    <DIV className="listItem">
      <Link
        {...ctx.apply('tracking.dataSet', {
          value: ctx.get('@item.id'),
          label: ctx.get('@item.profile.displayName'),
          category: 'supplier',
        })}
        className="supplier-card-item"
        onMouseMove={() => ctx.apply('carouselModel.$dragging', true)}
        onMouseDown={() => ctx.apply('carouselModel.$dragging', false)}
        onClick={(event) => {
          if (ctx.get('carouselModel.dragging')) {
            event.preventDefault();
          }
        }}
        to={ctx.apply('routeStore.toUrl', 'advisor', ctx.get('@item'))}
      >
        <section className="p-0 m-2 overflow-hidden rounded-lg shadow-md">
          <CustomImage
            srcParams={{ fit: 'fill', f: 'face' }}
            optimize={{ size: 400 }}
            ratio={0.75}
            src={ctx.get('@item.profile.avatarUrl', '')}
            alt="avt"
          />
          <div className="flex flex-col items-center px-4">
            <div className="mt-4 text-lg font-semibold text-center text-main text-ellipsis-1">
              {ctx.get('@item.profile.displayName')}
            </div>
            <div className="mt-2 text-sm text-center text-main text-ellipsis-1">
              {ctx.get('@item.profile.categoriesText')}
            </div>
            {!ctx.get('@item.isTeacher') && (
              <div className="flex items-center p-0 m-0 mt-2 text-sm text-main">
                {gstyles.icons({ name: 'coin-filled', size: 20, fill: gstyles.colors.brandb500 })}
                <span className="ml-1">{ctx.get('@item.minService.price.pricePerMinTxt')} </span>{' '}
                <span className="ml-1 lowercase">{ctx.get('@item.minService.price.pricePerMinTitle')}</span>
              </div>
            )}
            {!!ctx.get('@item.isTeacher') && (
              <div className="mt-2 text-sm text-center text-main text-ellipsis-1">
                {ctx.apply('i18n.t', 'Course.course_is_teaching', {
                  count: ctx.get('@item.totalCourse'),
                })}
              </div>
            )}
          </div>
          {!ctx.get('@item.isTeacher') && (
            <Row
              gutter={{ xs: 12, md: 16, lg: 6 }}
              align="center"
              className={cx('px-2 py-4')}
              style={{ minHeight: '72px' }}
            >
              {_.map(ctx.get('@item.btnServices'), (service) => {
                return (
                  <Col span={12}>
                    {service === 'VoiceService' && (
                      <Link
                        to={ctx.apply('routeStore.toUrl', 'voicecall', ctx.get('@item'))}
                        className="mx-auto"
                        external
                      >
                        <Button
                          type="primary"
                          block
                          size="small"
                          icon={gstyles.icons({ name: 'phone-call', size: 18, className: 'inline-block' })}
                          disabled={ctx.get('@item.status.isOffline') || ctx.get('@item.status.isBusy')}
                        >
                          <span className="ml-1 text-sm font-semibold">
                            {ctx.apply('i18n.t', 'ProfileAdvisor.callText')}
                          </span>
                        </Button>
                      </Link>
                    )}
                    {service === 'VideoService' && (
                      <Link
                        to={ctx.apply('routeStore.toUrl', 'videocall', ctx.get('@item'))}
                        className="mx-auto"
                        external
                      >
                        <Button
                          type="primary"
                          size="small"
                          block
                          icon={gstyles.icons({ name: 'videocam', size: 18, className: 'inline-block' })}
                          disabled={ctx.get('@item.status.isOffline') || ctx.get('@item.status.isBusy')}
                          // name="secondary"
                        >
                          <span className="ml-1 text-sm font-semibold">
                            {ctx.apply('i18n.t', 'ProfileAdvisor.callVideoText')}
                          </span>
                        </Button>
                      </Link>
                    )}
                  </Col>
                );
              })}
            </Row>
          )}
          {!!ctx.get('@item.isTeacher') && (
            <div className={cx('flex p-4 justify-between')} style={{ minHeight: '72px' }}>
              <Link to={ctx.apply('routeStore.toUrl', 'advisor', ctx.get('@item'))} className="mx-auto">
                <Button
                  type="primary"
                  size="small"
                  // block
                  icon={gstyles.icons({ name: 'course', size: 18, className: 'inline-block' })}
                  // name="secondary"
                >
                  <span className="ml-1 text-sm font-semibold">{ctx.apply('i18n.t', 'Course.viewCourse')}</span>
                </Button>
              </Link>
            </div>
          )}
        </section>
      </Link>
    </DIV>
  );
};
export default displayName(View8);
