import moment from 'moment';
import _ from 'lodash';
import currencyFormatter, { formatVND, normalizeNumber, normalizeCurrencyTemp } from '@vl/mod-utils/currencyFormatter';
import { getDaysInWeek } from '../../mod-utils/dateFormatter';
import { applyDiscount } from '../../mod-utils/paymentHelper';

export const formatter = {
  createdAtTxt: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'sys.firstPublishedAt', '');
    if (!createdAt) return '';
    return ctx.apply('i18n.t', 'Advisor.joinedSince', { dateStr: moment(createdAt).format('Do MMM, YYYY') });
  },

  createAtByMonthSlashYear: () => (itemData) => {
    const createdAt = _.get(itemData, 'sys.firstPublishedAt', '');
    if (!createdAt) return '';
    return moment(createdAt).format('MM/YYYY');
  },

  pricePerMinTitle: (ctx) => (itemData) => {
    const currency = _.get(itemData, 'price.price_currency');
    const unit = _.get(itemData, 'price.per_unit');
    if (!currency || !unit) return '';
    return ctx.apply('i18n.t', 'Advisor.pricePerMin', {
      currency: normalizeCurrencyTemp(ctx)(currency),
      unit: ctx.apply('i18n.t', 'time.minute'),
    });
  },

  reviewScore: () => (itemData) => {
    const review = _.get(itemData, 'review_agg.avg_score');

    if (!review) return 0;
    return _.round(parseFloat(review) / 2, 1).toFixed(1);
  },

  pricePerMin: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount');
    const per_amount = _.get(itemData, 'price.per_amount');
    if (!price_amount || !per_amount) return 0;
    return currencyFormatter(ctx)((parseInt(price_amount) / per_amount) * 60);
  },

  priceFullMin: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount');
    if (!price_amount) return 0;
    return formatVND(price_amount);
  },

  discountPercentage: () => (itemData) => {
    const discount = _.get(itemData, 'discounts.0.percentage', 0);

    if (!discount) return 0;

    return `${discount} %`;
  },

  amountPayment: (ctx) => (itemData) => {
    const discount = _.get(itemData, 'discounts.0', null);
    const price_amount = _.get(itemData, 'price.price_amount');

    if (!price_amount) return {};

    const values = applyDiscount(discount)(price_amount);

    return values;
  },

  amountPaymentFormat: (ctx) => (itemData) => {
    const discount = _.get(itemData, 'discounts.0', null);
    const price_amount = _.get(itemData, 'price.price_amount');

    if (!price_amount) return {};

    const values = applyDiscount(discount)(price_amount);

    return formatVND(values);
  },

  timeFull: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'price.per_amount');

    if (!per_amount) return 0;
    return normalizeNumber(per_amount) / 60;
  },

  pricePerMinTitleTxt: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount');
    const per_amount = _.get(itemData, 'price.per_amount');
    if (!price_amount || !per_amount) return 0;
    return `"${ctx.apply(
      'i18n.t',
      `AdvisorVideoCall.call${_.capitalize(_.get(itemData, 'service.kind'))}`
    )} ${per_amount / 60} ${ctx.apply('i18n.t', 'time.minute')}"`;
  },

  displayName: () => (itemData) => {
    return _.get(itemData, 'displayName', '') || _.get(itemData, 'display_name', '');
  },

  yearsExperience: () => (itemData) => {
    if (_.isNull(_.get(itemData, 'yearsExperience'))) return 0;
    return _.get(itemData, 'yearsExperience');
  },

  profileId: () => (itemData) => {
    return _.get(itemData, 'sys.id', '');
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);
    return (
      _.get(itemData, 'avatarUrl.url') ||
      _.get(itemData, 'avatar_url', `https://ui-avatars.com/api/?name=${displayName}`)
    );
  },
  categories: () => (itemData) => {
    const arr = _.get(itemData, 'categoriesCollection.items', []);

    return arr.map((item, index) => ({
      displayName: _.get(item, 'displayName'),
      id: _.get(item, 'sys.id', index),
      icon: _.get(item, 'icon', ''),
    }));
  },

  appraisalType: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'appraisalType') || _.get(itemData, 'appraisal_type', {});
    if (!arr) return [];

    return Object.keys(arr).map((item, index) => ({
      displayName: ctx.apply('i18n.t', `AdvisorProfile.${item}.name`),
      value: arr[item],
      minText: ctx.apply('i18n.t', `AdvisorProfile.${item}.min`),
      maxText: ctx.apply('i18n.t', `AdvisorProfile.${item}.max`),
    }));
  },
  teacherAppraisalType: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'appraisalType') || _.get(itemData, 'appraisal_type', {});
    if (!arr) return [];

    return Object.keys(arr).map((item, index) => ({
      displayName: ctx.apply('i18n.t', `Teacher.${item}.name`),
      value: arr[item],
      minText: ctx.apply('i18n.t', `Teacher.${item}.min`),
      maxText: ctx.apply('i18n.t', `Teacher.${item}.max`),
    }));
  },
  calendar: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'calendar', {});
    let dayOfWeek = _.slice(getDaysInWeek(), 1, 8);

    if (!arr) return [];
    const weeks = ctx.apply('i18n.t', 'Weeks');

    const weeksKey = Object.keys(weeks);

    return Object.keys(arr)
      .map((item, index) => {
        if (weeksKey[item] && arr[item] && arr[item].length) {
          return {
            // displayName: `${ctx.apply('i18n.t', `Weeks.${weeksKey[item]}`)}, ${dayOfWeek[item]}`,
            displayName: `${ctx.apply('i18n.t', `Weeks.${weeksKey[item]}`)}`,
            value: arr[item],
          };
        }
      })
      .filter((item) => item);
  },

  skills: () => (itemData) => {
    const arr = _.get(itemData, 'skillsCollection.items', []);
    return arr.map((item, index) => ({
      displayName: _.get(item, 'displayName'),
      id: _.get(item, 'sys.id', index),
    }));
  },

  reviews: (ctx) => (itemData) => {
    return _.map(itemData, (item) => {
      return {
        id: _.get(item, 'id'),
        reviewer: {
          displayName: _.get(item, 'author.profile.display_name') || 'Ẩn Danh',
          avatarUrl: _.get(item, 'author.profile.avatar_url'),
          seenAtText: moment(_.get(item, 'updated_at'), 'YYYYMMDD').fromNow(),
        },
        reviewText: _.get(item, 'review_text'),
        rating: {
          ratingTxt: formatter.reviewScore(ctx)({ review_agg: { avg_score: _.get(item, 'score', 0) } }),
          ratingScore: formatter.reviewScore(ctx)({ review_agg: { avg_score: _.get(item, 'score', 0) } }),
        },
      };
    });
  },

  count: (ctx) => (itemData) => {
    return _.get(itemData, 'aggregate.count', '0');
  },

  services: (ctx) => (itemData) => {
    const groupServices = _.orderBy(
      _.toArray(_.keyBy(itemData, 'service.kind')),
      ({ service }) => _.get(service, 'kind'),
      ['desc']
    );
    return _.map(groupServices, (item) => {
      return {
        kind: _.get(item, 'service.kind'),
        id: _.get(item, 'service.id'),
        service_id: _.get(item, 'id'),
        name: `${_.upperFirst(_.get(item, 'service.kind'))}Service`,
        nameTxt: ctx.apply('i18n.t', `AdvisorProfile.call${_.capitalize(_.get(item, 'service.kind'))}`),
        icon: _.get(item, 'service.kind') === 'video' ? 'camera' : _.get(item, 'service.kind'),
        price: {
          ..._.get(item, 'price'),
          pricePerMinTxt: formatter.pricePerMin(ctx)(item),
          pricePerMinTitle: formatter.pricePerMinTitle(ctx)(item),
        },
        status: _.get(item, 'service.status') || false,
        status_kind: _.get(item, 'service.is_active') || false,
      };
    });
  },

  service: (ctx) => (itemData) => {
    const prefix = _.get(itemData, 'service.id', '').split('_')[0];
    return {
      kind: _.get(itemData, 'service.kind'),
      name: `${_.upperFirst(prefix)}Service`,
      id: _.get(itemData, 'service.id'),
      service_id: _.get(itemData, 'id'),
      nameTxt: _.get(itemData, 'service.name'),
      icon: _.get(itemData, 'icon'),
      price: {
        ..._.get(itemData, 'price'),
        pricePerMinTxt: formatter.pricePerMin(ctx)(itemData),
        pricePerMinTitle: formatter.pricePerMinTitle(ctx)(itemData),
      },
      status: _.get(itemData, 'service.status') || false,
      status_kind: _.get(itemData, 'service.is_active') || false,
    };
  },

  profileName: () => (itemData) => {
    return _.get(itemData, 'display_name', '');
  },

  profileAvatar: (ctx) => (itemData) => {
    const displayName = formatter.profileName(ctx)(itemData);
    return (
      _.get(itemData, 'avatar_url', `https://ui-avatars.com/api/?name=${displayName}`) ||
      `https://ui-avatars.com/api/?name=${displayName}`
    );
  },
  profilePhoneNumber: () => (itemData) => {
    return _.get(itemData, 'phone_number', '');
  },
  profileEmail: () => (itemData) => {
    return _.get(itemData, 'email', '');
  },
  profileIdentifyNumber: () => (itemData) => {
    return _.get(itemData, 'identify_number', '');
  },
  profilePassportNumber: () => (itemData) => {
    return _.get(itemData, 'passport_number', '');
  },
  profileTaxNumber: () => (itemData) => {
    return _.get(itemData, 'tax_number', '');
  },
  socialLink: () => (itemData) => {
    return _.get(itemData, 'social_link', '');
  },
  introTxt: () => (itemData) => {
    return _.get(itemData, 'profile.intro_text', '');
  },

  onlineTxt: (ctx) => (itemData) => {
    const status = _.get(itemData, 'presence.status', '');
    if (status === 'online') {
      return ctx.apply('i18n.t', 'StatusAdvisor.Online');
    }

    return ctx.apply('i18n.t', 'StatusAdvisor.Offline');
  },

  onlineValue: () => (itemData) => {
    const status = _.get(itemData, 'presence.status', '');
    return status === 'online';
  },

  totalRate: () => (itemData) => {
    const count = _.get(itemData, 'reviews_aggregate.aggregate.count', 0);
    if (!count) return 0;
    return count;
  },
};

export default formatter;
