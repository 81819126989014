import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';

export const AdvisorHeaderNotification = () => (
  <DIV className="forUserOnly">
    <DIV className="component">
      {ctx.apply('ctf.renderProps', () => {
        const itemData = ctx.get('itemData');
        const itemProps = ctx.get('itemProps');
        return (
          <li
            className={cx(
              'group',
              ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
              _.get(itemProps, 'className')
            )}
          >
            <Link
              className="text-white500"
              to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref') || '/')}
              target="_blank"
            >
              <div className="relative group-hover:text-white500">
                {gstyles.icons({
                  name: ctx.apply(
                    'ctf.findEntry',
                    itemData,
                    ...[].concat(_.get(itemProps, 'args') || ['shortText', ''])
                  ),
                  fill: !_.isNull(_.get(itemData, 'action')) ? gstyles.colors.sub : gstyles.colors.white,
                  size: 24,
                })}
                <DIV forceCtx>
                  {ctx.get('notificationCount') > 0 && (
                    <span className="group-hover:text-white500 absolute w-5 h-5 text-center font-semibold flex justify-center items-center -top-3 -right-3 text-xs bg-red500 text-white500 rounded-full">
                      {ctx.get('notificationCount') > 9 ? '9+' : ctx.get('notificationCount')}
                    </span>
                  )}
                </DIV>
              </div>
            </Link>
          </li>
        );
      })}
    </DIV>
  </DIV>
);

export default AdvisorHeaderNotification;
