import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import _ from 'lodash';

const CustomParapraph = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;
export const AdvisorRegistratorConvenientItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <div className="flex flex-col mx-3">
          <div className="bg-background1">
            <Image
              src={ctx.apply(
                'ctf.findImage',
                itemData,
                _.get(itemProps, 'fieldNames.image', 'image'),
                ...[].concat(_.get(itemProps, 'args') || [])
              )}
              alt={_.get(itemData, 'shortText', '')}
              size="cover"
              srcParams={{ fit: 'pad' }}
            />
          </div>

          <div className="py-4 flex flex-col justify-start items-start">
            <h3 className="mb-2 text-xl text-left text-ellipsis-1 font-semibold text-main">
              {_.get(itemData, 'shortText', '')}
            </h3>
            <CustomParapraph>
              {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {
                className: 'text-base mb-4',
                as: 'p',
              })}
            </CustomParapraph>
          </div>
        </div>
      );
    })}
  </DIV>
);

export default AdvisorRegistratorConvenientItem;
