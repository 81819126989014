import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const GreenDisc1 = styled.div`
  position: absolute;
  top: 140%;
  left: 20%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 1190px;
  height: 1190px;
  background-color: ${gstyles.colors.brandb500};
  overflow: hidden;
  opacity: 0.15;
`;

const GreenDisc2 = styled.div`
  position: absolute;
  top: 100%;
  right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 1079px;
  height: 1079px;
  background-color: ${gstyles.colors.brandb.light};
  overflow: hidden;
  opacity: 0.15;
`;

const GridLayout = styled.div`
  .row-span-2 {
    .overlay {
      @media only screen and (max-width: 1024px) {
        height: 100% !important;
      }
      height: auto;
    }
    div {
      height: 100%;
      @media only screen and (max-width: 1024px) {
        height: auto;
      }
      img {
        height: 100%;
        @media only screen and (max-width: 1024px) {
          height: auto;
        }
      }
    }
  }
`;

const ForSchoolTourPlatformLayout = () => {
  return (
    <DIV className="ForSchoolTourPlatformLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className={`py-12 relative overflow-hidden ${_.get(ctx.get('sectionData'), 'className', '')}`}>
            <GreenDisc1 className="hidden lg:block" />
            <GreenDisc2 className="hidden lg:block" />

            <div className="wrapper app-row">
              {!_.isNull(ctx.get('sectionData.shortText')) && (
                <h1 className="text-center text-xl lg:text-3xl mb-4 lg:mb-12 text-brandb-darker font-semibold">
                  {ctx.get('sectionData.shortText')}
                </h1>
              )}
              <GridLayout className="grid lg:grid-cols-3 gap-4">
                {_.map(ctx.get('sectionData.sections'), (item, index) => (
                  <React.Fragment key={index}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                  </React.Fragment>
                ))}
              </GridLayout>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForSchoolTourPlatformLayout;
