import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';
import CFAdvisorListX from '@uz/unitz-components-web/CFAdvisorListX';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const CategoryFilterItem = () => {
  return (
    <DIV>
      <div
        onClick={() => {
          ctx.apply('form.setFieldValue', 'category', ctx.get('@item.id'));
        }}
        {...ctx.apply('tracking.dataSet', {
          value: ctx.get('@item.id'),
          label: ctx.get('@item.displayName'),
          action: 'click',
          category: 'category',
        })}
        className={cx(
          'tag-button-category flex-grow flex-1 cursor-pointer flex justify-center items-center text-center p-2 min-md:p-5 rounded-lg mx-2 min-md:mx-2 text-sm lg:text-base font-semibold',
          {
            'text-white500 bg-brandb': ctx.get('@item.isSelected'),
            'text-main bg-white500 border border-border': !ctx.get('@item.isSelected'),
          }
        )}
      >
        {ctx.get('@item.displayName')}
      </div>
    </DIV>
  );
};

const LandingCourseListLayout = () => {
  return (
    <DIV className="LandingCourseListLayout">
      <FormProvider form={ctx.get('form')}>
        <Form>
          <section className="mb-0 wrapper app-row advisor-list-section">
            <div className="flex flex-col items-center justify-center mb-6">
              <div className="text-sm font-semibold text-center uppercase text-sub">
                {ctx.get('sectionData.shortText')}
              </div>
              <CustomParagraph className="text-center">
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
              </CustomParagraph>
            </div>
            <div className="flex mb-8 jusify-between">
              {_.map(ctx.get('categories.data'), (item, index) => {
                return (
                  <React.Fragment key={`cat_${index}`}>
                    <DIV forceCtx>
                      {ctx.debug(() => {
                        ctx.set('@item', {
                          ...item,
                          isSelected: ctx.get('form.values.category') === _.get(item, 'id'),
                        });
                        ctx.set('@index', index);
                      })}
                      <CategoryFilterItem />
                    </DIV>
                  </React.Fragment>
                );
              })}
            </div>

            <DIV forceCtx>
              {ctx.debug(() => {
                ctx.set('@items', ctx.get('advisorFilterModel.items'));
              })}
              <CFAdvisorListX />
            </DIV>
          </section>
        </Form>
      </FormProvider>
    </DIV>
  );
};

export default LandingCourseListLayout;
