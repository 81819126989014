import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import DesktopLangSwitch from './components/Desktop';
// import TabletLangSwitch from './components/DesktopLangSwitch';
import MobileLangSwitch from './components/Mobile';

function LangSwitchTransparent() {
  return (
    <DIV>
      <ResponsiveProps
        // md={{ renderer: RenderNavbarMediumSize }}
        xs={{ renderer: () => <MobileLangSwitch /> }}
        md={{ renderer: () => <MobileLangSwitch /> }}
        lg={{ renderer: () => <MobileLangSwitch /> }}
        xl={{ renderer: () => <DesktopLangSwitch /> }}
      >
        {(resProps) => !!resProps && resProps.renderer()}
      </ResponsiveProps>
    </DIV>
  );
}

export default LangSwitchTransparent;
