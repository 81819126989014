import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

function SocialAppFooterLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <section className="social-app-group flex flex-col-reverse lg:flex-col py-0" id="social-app-group">
            <DIV>
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                (col, index) => (
                  <React.Fragment key={`${col.name}_${index}`}>
                    {ctx.apply('ctf.renderEntry', { name: col.name }) || null}
                  </React.Fragment>
                )
              )}
            </DIV>
          </section>
        );
      })}
    </DIV>
  );
}

export default SocialAppFooterLayout;
