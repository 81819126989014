import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import '../modal.css';
import { Modal, Alert, Spin, Result, Row, Col, Divider } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import Button from '@uz/unitz-components-web/Button';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import { Form, FormItem, DatePicker, TimePicker, Select, Input, Checkbox, InputNumber, Radio } from 'formik-antd';
import moment from 'moment';
import InputText from '@uz/unitz-components-web/InputText';
import { DATE_FORMAT } from '@vl/mod-utils/datetime';
import FormLoginModal from '@uz/unitz-components-web/FormLoginModal';
import i18n from 'i18n-js';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import styled from 'styled-components';

import CustomInput from '@uz/unitz-components-web/CourseRAT/Components/CustomInput';
const CustomFormItemCheckbox = styled(FormItem)`
  margin-bottom: 0px !important;
`;
const CustomFormItemLabel = styled(FormItem)`
  .ant-form-item-label {
    width: 200%;
    overflow: initial;
    label {
      width: 200%;
    }
  }
`;
const CustomLabelRequired = styled.label`
  :before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;
const CustomNumberOfStudent = styled.div`
  .ant-input-number {
    border-radius: 8px;
    overflow: hidden;
  }
`;
const CustomAge = styled.div`
  label {
    line-height: 1.5715;
  }
  label::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .form-item {
    margin-bottom: 0;
  }
`;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};
const SESSION_START_TIME_FORMAT = 'HH:mm';

export const CourseRoomRescheduleModal = FormLoginModal.LoginEnhancer(() => {
  return (
    <DIV className="modalComponent">
      <Modal
        title={
          <div className="flex items-center justify-start py-2">
            <div className="text-2xl font-semibold text-main center">
              {ctx.apply('i18n.t', 'Course.RCM.modal.title')}
            </div>
          </div>
        }
        centered
        wrapClassName={'my-modal'}
        className="overflow-hidden rounded-lg"
        visible={ctx.get('form.isModalVisible')}
        onOk={ctx.get('form.handleSubmit')}
        okText={ctx.apply('i18n.t', 'Course.RAT.modal.okButton')}
        onCancel={ctx.get('form.handleCancel')}
        cancelText={ctx.apply('i18n.t', 'Course.RAT.modal.cancelButton')}
        // okButtonProps={{
        //   type: 'primary',
        //   htmlType: 'submit',
        //   id: 'submit',
        //   disabled: !ctx.get('form.canSubmit'),
        //   loading: ctx.get('form.isSubmitting'),
        //   className: 'px-8',
        // }}
        closeIcon={<div className="mt-4">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>}
        bodyStyle={{ padding: 0 }}
        destroyOnClose
        afterClose={() => ctx.apply('modalModel.hide')}
        footer={false}
        width={760}
      >
        <>
          {ctx.get('form.isModalVisible') &&
            (() => {
              const getTime = (value) => {
                const label = moment(value);

                return `${label.format(_.get(DATE_FORMAT, moment.locale(i18n.locale), 'vi'))}`;
              };

              return (
                <Row className="py-2" justify="center">
                  <Col span={23}>
                    <FormProvider form={ctx.get('form')}>
                      <Form {...layout}>
                        {ctx.get('form.status.error') && (
                          <div className="py-2">
                            <Alert message={ctx.get('form.status.error.message')} type="error" />
                          </div>
                        )}
                        {!!ctx.get('form.errors.sessions') &&
                          (_.isString(ctx.get('form.errors.sessions')) ||
                            React.isValidElement(ctx.get('form.errors.sessions'))) && (
                            <div className="py-2">
                              <Alert message={ctx.get('form.errors.sessions')} type="error" showIcon />
                            </div>
                          )}
                        {ctx.apply('form.renderStatus', {
                          success: () => (
                            <Result
                              status="success"
                              icon={
                                <div className="flex items-center justify-center">
                                  <div className="p-4 rounded-full bg-brandb-lighter">
                                    {gstyles.icons({ name: 'check', size: 42, fill: gstyles.colors.brandb500 })}
                                  </div>
                                </div>
                              }
                              style={{ padding: 0 }}
                              title={
                                <div className="text-xl font-semibold text-main">
                                  {ctx.apply('i18n.t', 'Course.RCM.modal.results.success.title')}
                                </div>
                              }
                              subTitle={
                                <div className="py-3 text-base font-normal text-main">
                                  {ctx.apply('i18n.t', 'Course.RCM.modal.results.success.subTitle')}
                                </div>
                              }
                            />
                          ),
                          error: () => (
                            <Result
                              status="error"
                              title={ctx.apply('i18n.t', 'Course.RCM.modal.results.error.title')}
                              subTitle={ctx.apply('i18n.t', 'Course.RCM.modal.results.error.subTitle')}
                            />
                          ),
                          default: () => (
                            <div className="py-2">
                              <Alert
                                style={{
                                  borderRadius: 8,
                                  backgroundColor: 'transparent',
                                  padding: 0,
                                  border: 'none',
                                }}
                                message={
                                  <div className="py-2 text-base text-sub">
                                    {ctx.apply('i18n.t', 'Course.RCM.messageInfo')}
                                  </div>
                                }
                                type="info"
                              />
                            </div>
                          ),
                        })}
                        {!!_.isUndefined(ctx.get('form.status')) && (
                          <>
                            <CustomInput>
                              <FormItem
                                name="name"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Admin.Account.Table.displayName')}
                                  </span>
                                }
                                required
                              >
                                <InputText name="name" />
                              </FormItem>
                            </CustomInput>
                            <CustomInput>
                              <FormItem
                                name="phone"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Profile.phoneLabel')}
                                  </span>
                                }
                                required
                              >
                                <InputText name="phone" />
                              </FormItem>
                            </CustomInput>
                            <CustomInput>
                              <FormItem
                                name="email"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Profile.emailLabel')}
                                  </span>
                                }
                                required
                              >
                                <InputText name="email" />
                              </FormItem>
                            </CustomInput>
                            {/*  */}
                            <CustomInput>
                              <FormItem
                                name="topic"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Course.RCM.modal.topicLabel')}
                                  </span>
                                }
                                required
                              >
                                <InputText name="topic" />
                              </FormItem>
                            </CustomInput>
                            <CustomInput>
                              <FormItem
                                name="literacy"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Course.RCM.modal.literacyLabel')}
                                  </span>
                                }
                                required
                              >
                                <Select
                                  size="middle"
                                  name="literacy"
                                  suffixIcon={gstyles.icons({
                                    name: 'arrow-down3',
                                    size: 24,
                                    fill: gstyles.colors.sub,
                                    className: '-mt-2 -ml-2',
                                  })}
                                  required
                                  placeholder={ctx.apply('i18n.t', 'Course.RCM.modal.literacyLabel')}
                                >
                                  {_.map(ctx.get('form.selectOptions.literacy'), (item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </CustomInput>
                            <CustomInput>
                              <FormItem
                                name="advisor_name"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Teacher.title')}
                                  </span>
                                }
                              >
                                <InputText name="advisor_name" />
                              </FormItem>
                            </CustomInput>
                            {/* <CustomInput>
                              <FormItem
                                name="session_duration"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Course.RAT.modal.sessionDurationLabel')}
                                  </span>
                                }
                              >
                                <InputText
                                  name="session_duration"
                                  value={ctx.apply('i18n.t', 'Course.Info.minute', {
                                    count: ctx.get('form.values.session_duration') / 60,
                                  })}
                                  disabled
                                />
                              </FormItem>
                            </CustomInput> */}
                            <Row gutter={16}>
                              <Col span={12}>
                                <CustomInput>
                                  <FormItem
                                    name="start_at"
                                    label={
                                      <span className="text-sm font-semibold text-sub">
                                        {ctx.apply('i18n.t', 'Course.Info.start_at_input_label')}
                                      </span>
                                    }
                                    required
                                  >
                                    <DatePicker
                                      allowClear={false}
                                      size="middle"
                                      className="w-full"
                                      suffixIcon={gstyles.icons({
                                        name: 'calendar',
                                        size: 20,
                                        fill: gstyles.colors.sub,
                                      })}
                                      name="start_at"
                                      disabledDate={(current) => {
                                        return current && current.startOf('day') < moment().startOf('day');
                                      }}
                                    />
                                  </FormItem>
                                </CustomInput>
                              </Col>
                              <Col span={12}>
                                <CustomInput>
                                  <FormItem
                                    name="target_audience"
                                    label={
                                      <span className="text-sm font-semibold text-sub">
                                        {ctx.apply('i18n.t', 'Course.Info.object_teaching_input_label')}
                                      </span>
                                    }
                                    required
                                  >
                                    <Radio.Group
                                      onChange={ctx.get('form.onChangeTargetAudience')}
                                      options={ctx.get('form.selectOptions.target_audience')}
                                      name="target_audience"
                                      optionType="button"
                                      buttonStyle="solid"
                                      style={{ width: '100%', height: '40px' }}
                                      size="medium"
                                    />
                                  </FormItem>
                                </CustomInput>
                              </Col>
                            </Row>

                            <Row gutter={8} align="bottom">
                              <Col span={6}>
                                <CustomNumberOfStudent>
                                  <CustomInput>
                                    <FormItem
                                      className="flex-1 form-item"
                                      name="number_of_students"
                                      label={
                                        <span className="text-sm font-semibold text-sub">
                                          {ctx.apply('i18n.t', 'Course.RAT.modal.numberOfStudentsLabel')}
                                        </span>
                                      }
                                      required
                                    >
                                      <InputNumber
                                        className="number_of_students"
                                        name="number_of_students"
                                        min={1}
                                        max={100}
                                        style={{ width: '100%', height: '40px' }}
                                      />
                                    </FormItem>
                                  </CustomInput>
                                </CustomNumberOfStudent>
                              </Col>
                              <Col span={2}>
                                <span className="flex mb-3 text-sm text-sub">
                                  {ctx.apply('i18n.t', 'Course.RCM.modal.people')}
                                </span>
                              </Col>
                              <Col span={6}>
                                <CustomAge>
                                  <CustomInput>
                                    <CustomFormItemLabel
                                      name="age"
                                      label={
                                        <div className="flex items-center">
                                          <span className="mr-1 text-sm font-semibold text-sub">
                                            {ctx.apply('i18n.t', 'Course.Info.age_input_label')}
                                          </span>
                                          <span className="text-sub" style={{ fontSize: '10px' }}>
                                            {ctx.apply('i18n.t', 'Course.Info.age_note_input_label')}
                                          </span>
                                        </div>
                                      }
                                    >
                                      <InputNumber
                                        className="min_age"
                                        name="age"
                                        min={3}
                                        max={ctx.get('form.values.age_max') <= 18 ? ctx.get('form.values.age_max') : 18}
                                        style={{ width: '100%', height: '40px' }}
                                      />
                                    </CustomFormItemLabel>
                                  </CustomInput>
                                </CustomAge>
                              </Col>
                            </Row>
                            <Row className="py-4 border-b border-divider" gutter={gutter}>
                              <Col span={24}>
                                <CustomLabelRequired className="font-semibold text-sub">
                                  {ctx.apply('i18n.t', 'Course.RAT.modal.selectDateMainLabel')}
                                </CustomLabelRequired>
                              </Col>
                              {/* <Col span={2}>
                                <div className="text-sm font-normal text-sub">#</div>
                              </Col>
                              <Col span={10}>
                                <div className="text-sm font-normal text-sub">
                                  {ctx.apply('i18n.t', 'Course.DurationSetting.hour_frame')}
                                </div>
                              </Col>
                              <Col span={10}>
                                <div className="text-sm font-normal text-sub">
                                  {ctx.apply('i18n.t', 'Course.DurationSetting.repeat')}
                                </div>
                              </Col>
                              <Col span={2}></Col> */}
                            </Row>
                            {_.map(ctx.get('form.values.sessions'), (session, index) => {
                              const isRepeat = ctx.get(`form.values.sessions.${index}.repeat_pattern.length`);
                              const dateFormatter = (value) => {
                                const TIME_FORMAT = 'HH:mm';
                                const DATE_FORMAT = 'YYYY/MM/DD';
                                const session_duration = ctx.get('form.values.session_duration') || 60 * 60;
                                const endValue = value.clone().add(session_duration, 'second');
                                if (isRepeat) {
                                  return `${value.format(TIME_FORMAT)} - ${endValue.format(TIME_FORMAT)}`;
                                }
                                return `${value.format(`${DATE_FORMAT} ${TIME_FORMAT}`)} - ${endValue.format(
                                  TIME_FORMAT
                                )}`;
                              };
                              return (
                                <>
                                  <Row gutter={gutter} align="middle" className="py-2">
                                    <Col span={4}>
                                      {/* <CustomFormItemCheckbox name={`sessions[${index}].select`}>
                                        <Checkbox
                                          name={`sessions[${index}].select`}
                                          onChange={(e) =>
                                            ctx.apply(
                                              'form.handleSelectAllSessions',
                                              `sessions[${index}].repeat_pattern`,
                                              e.target.checked
                                            )
                                          }
                                        >
                                          {ctx.apply('i18n.t', 'Course.RAT.DurationSetting.repeat')}
                                        </Checkbox>
                                      </CustomFormItemCheckbox> */}
                                    </Col>

                                    <Col span={20}>
                                      <div className="flex justify-between">
                                        {_.map(ctx.get('form.selectOptions.repeat_pattern'), (item) => (
                                          <div
                                            className={cx(
                                              'text-sub p-2 rounded border cursor-pointer text-xs',
                                              {
                                                'border-brandb bg-white': _.includes(
                                                  ctx.get(`form.values.sessions[${index}].repeat_pattern`, []),
                                                  item.value
                                                ),
                                              },
                                              {
                                                'border-background2 bg-background2': !_.includes(
                                                  ctx.get(`form.values.sessions[${index}].repeat_pattern`, []),
                                                  item.value
                                                ),
                                              }
                                            )}
                                            onClick={() =>
                                              ctx.apply(
                                                'form.handleSelectSessions',
                                                `sessions[${index}].repeat_pattern`,
                                                item.value,
                                                index
                                              )
                                            }
                                          >
                                            {item.label}
                                          </div>
                                        ))}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2 border-b border-divider" gutter={gutter} key={index}>
                                    <Col span={4}>
                                      <div className="flex items-center justify-center h-full">
                                        <div className="text-sm font-normal text-main text-sub">
                                          {ctx.apply('i18n.t', 'Course.RAT.modal.selectDateLabel')}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col span={18}>
                                      <CustomInput>
                                        <FormItem name={`sessions[${index}].start_at`}>
                                          {isRepeat ? (
                                            <TimePicker
                                              allowClear={false}
                                              size="middle"
                                              showTime={{
                                                hourStep: 1,
                                                minuteStep: 15,
                                                format: SESSION_START_TIME_FORMAT,
                                              }}
                                              suffixIcon={gstyles.icons({
                                                name: 'calendar',
                                                size: 20,
                                                fill: gstyles.colors.sub,
                                              })}
                                              format={dateFormatter}
                                              // disabledDate={disabledDate}
                                              // disabledTime={disabledTime}
                                              name={`sessions[${index}].start_at`}
                                              className="w-full"
                                            />
                                          ) : (
                                            <DatePicker
                                              allowClear={false}
                                              size="middle"
                                              showTime={{
                                                hourStep: 1,
                                                minuteStep: 15,
                                                format: SESSION_START_TIME_FORMAT,
                                              }}
                                              suffixIcon={gstyles.icons({
                                                name: 'calendar',
                                                size: 20,
                                                fill: gstyles.colors.sub,
                                              })}
                                              format={dateFormatter}
                                              // disabledDate={disabledDate}
                                              // disabledTime={disabledTime}
                                              // disabledDate={(current) => {
                                              //   const start_at = ctx.get('form.values.start_at');
                                              //   return current.startOf('day') < moment(start_at).startOf('day');
                                              // }}
                                              name={`sessions[${index}].start_at`}
                                              className="w-full"
                                            />
                                          )}
                                        </FormItem>
                                      </CustomInput>
                                    </Col>
                                    {/* <Col span={9}>
                                      <CustomInput>
                                        <FormItem name={`sessions[${index}].repeat_pattern`}>
                                          <Select
                                            showSearch
                                            size="small"
                                            mode="multiple"
                                            suffixIcon={gstyles.icons({
                                              name: 'arrow-down3',
                                              size: 24,
                                              fill: gstyles.colors.sub,
                                              className: '-mt-2 -ml-2',
                                            })}
                                            defaultValue={_.compact(session.repeat_pattern)}
                                            name={`sessions[${index}].repeat_pattern`}
                                            placeholder={ctx.apply(
                                              'i18n.t',
                                              'Course.DurationSetting.repeat_pattern_input_placeholder'
                                            )}
                                            onChange={(event) => {
                                              console.log({ event });
                                            }}
                                          >
                                            {_.map(ctx.get('form.selectOptions.repeat_pattern'), (item) => (
                                              <Select.Option label={item.label} value={item.value} key={item.value}>
                                                {item.label}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </FormItem>
                                      </CustomInput>
                                    </Col> */}
                                    <Col span={2}>
                                      <Button
                                        type="text"
                                        className="w-full"
                                        size="small"
                                        onClick={() => ctx.apply('form.array.remove', 'sessions', index)}
                                        icon={gstyles.icons({
                                          name: 'remove-circle',
                                          fill: gstyles.colors.red500,
                                          size: 24,
                                          className: 'inline-block mx-2',
                                        })}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                            <Row className="mt-4" align="middle" gutter={gutter}>
                              <Col className="mx-auto" span={10}>
                                <Button
                                  type="outline"
                                  className="mx-auto"
                                  size="small"
                                  block
                                  onClick={() => ctx.apply('form.array.add', 'sessions')}
                                  icon={gstyles.icons({
                                    name: 'add-circle',
                                    size: 20,
                                    fill: gstyles.colors.brandb500,
                                    className: 'inline-block mx-2',
                                  })}
                                >
                                  {ctx.apply('i18n.t', 'Course.DurationSetting.Action.add_hour_frame')}
                                </Button>
                              </Col>
                            </Row>
                            <div className="my-2">
                              <Alert
                                style={{ borderRadius: '8px' }}
                                description={ctx.apply('i18n.t', 'Course.RAT.DurationSetting.helperMessage')}
                                type="info"
                                showIcon
                                closable
                              />
                            </div>
                            <CustomInput>
                              <FormItem
                                name="notes"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'Course.RAT.modal.notesLabel')}
                                  </span>
                                }
                              >
                                <Input.TextArea
                                  size="middle"
                                  rows={4}
                                  className="w-full"
                                  name="notes"
                                  placeholder={ctx.apply('i18n.t', 'Course.RAT.modal.notesPlaceholder')}
                                />
                              </FormItem>
                            </CustomInput>
                          </>
                        )}
                        {!_.isUndefined(ctx.get('form.status')) && (
                          <>
                            {/* <Divider />
                            <div className="flex flex-col py-1 space-y-3">
                              <div className="flex items-center justify-between">
                                <span className="text-base font-normal text-sub">
                                  {ctx.apply('i18n.t', 'Course.Info.name_input_label')}
                                </span>
                                <div className="text-sm font-normal text-right text-main">
                                  {ctx.get('form.values.name')}
                                </div>
                              </div>
                              <div className="flex items-center justify-between">
                                <span className="text-base font-normal text-sub">
                                  {ctx.apply('i18n.t', 'Teacher.title')}
                                </span>
                                <div className="text-sm font-normal text-main">
                                  {ctx.get('form.values.advisor_name')}
                                </div>
                              </div>
                              <div className="flex items-center justify-between">
                                <span className="text-base font-normal text-sub">
                                  {ctx.apply('i18n.t', 'Course.Info.start_at_input_label')}
                                </span>
                                <div className="text-sm font-normal text-main">
                                  {getTime(ctx.get('form.values.start_at'))}
                                </div>
                              </div>
                              <div className="flex items-center justify-between">
                                <span className="text-base font-normal text-sub">
                                  {ctx.apply('i18n.t', 'Course.DurationSetting.hour_frame')}
                                </span>
                                <div className="flex flex-col text-sm font-normal text-main">
                                  {_.map(
                                    CourseFormatter.sessions(ctx)({
                                      sessions: _.map(_.get(ctx.get('form.values'), 'sessions') || [], (session) => {
                                        return {
                                          ...session,
                                          ...(_.get(session, 'repeat_pattern.length')
                                            ? {
                                                type: 'repeat',
                                                repeat_pattern: `* * * * ${session.repeat_pattern.join(',')}`,
                                              }
                                            : {
                                                type: 'single',
                                                repeat_pattern: '',
                                              }),
                                        };
                                      }),
                                    }),
                                    (session, index) => {
                                      return (
                                        <div className="flex mt-1" key={index}>
                                          {renderPropsComposer(
                                            {
                                              matcher: (props) => !_.get(props, 'type'),
                                              render: () => (
                                                <div className="flex items-center">
                                                  <div className="text-base text-main">•</div>
                                                  <div className="ml-2 text-base text-main">{session}</div>
                                                </div>
                                              ),
                                            },
                                            {
                                              matcher: (props) => _.get(props, 'type') === 'single',
                                              render: () => (
                                                <div className="flex items-center">
                                                  <div className="text-base text-main">•</div>
                                                  <div className="ml-2 text-base text-main">
                                                    {_.get(session, 'data', []).join(' • ')}
                                                  </div>
                                                </div>
                                              ),
                                            },
                                            {
                                              matcher: (props) => _.get(props, 'type') === 'repeat',
                                              render: () => (
                                                <div className="flex items-center mt-1">
                                                  <div className="text-base text-main">•</div>
                                                  <div className="ml-2 text-base text-main">
                                                    {_.get(session, 'data', []).join(' • ')}
                                                  </div>
                                                </div>
                                              ),
                                            },
                                            () => null
                                          )(session)}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                              <Divider />
                            </div> */}
                          </>
                        )}
                      </Form>
                    </FormProvider>
                    <div className="py-4">
                      {ctx.get('getLoginEnhancerButton')({
                        title: ctx.apply('i18n.t', 'Course.RAT.modal.okButton'),
                        onClick: () => {
                          ctx.apply('form.onSaveForm');
                        },
                      })}
                      {!!ctx.apply('authModel.isAuthenticated') &&
                        _.compact([
                          ctx.apply('form.renderStatus', {
                            default: (
                              <Button
                                key="submit"
                                type="primary"
                                block
                                onClick={ctx.get('form.handleSubmit')}
                                disabled={!ctx.get('form.canSubmit')}
                                loading={ctx.get('form.isSubmitting')}
                              >
                                {ctx.apply('i18n.t', 'Course.RAT.modal.okButton')}
                              </Button>
                            ),
                            success: (
                              <Button key="done" type="primary" block onClick={ctx.get('form.handleCancel')}>
                                {ctx.apply('i18n.t', 'Course.RAT.modal.doneButton')}
                              </Button>
                            ),
                          }),
                        ])}
                    </div>
                  </Col>
                </Row>
              );
            })()}
        </>
      </Modal>
    </DIV>
  );
});

export default CourseRoomRescheduleModal;
