import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { Rate } from 'antd';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const CustomStar = styled.div`
  .ant-rate {
    font-size: 16px;
    margin-bottom: 2px;
    color: ${gstyles.colors.yellow.DEFAULT};
  }
`;

const CustomText = styled.div`
  div > p {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const View8 = (props) => {
  const { itemData } = props;
  return (
    <DIV className="carouselItem">
      <div className="border-b md:border-b-0 lg:border-b-0 mx-5 py-3 lg:py-2 text-center grid grid-flow-col grid-cols-2 gap-4 lg:block">
        <div className="col-span-6 block lg:flex lg:items-center lg:justify-center mb-5">
          <Avatar
            size={{
              xs: 60,
              sm: 60,
              md: 60,
              lg: 64,
              xl: 60,
              xxl: 100,
            }}
            src={ctx.apply('ctf.findImage', itemData, 'image', '')}
            alt={_.get(itemData, 'name', '')}
          />
        </div>
        <div>
          <h5 className="text-base text-main text-left mb-0 lg:mb-1 lg:text-center font-semibold">
            {_.get(itemData, 'shortText', '')}
          </h5>
          <CustomText>
            {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'detailText.detailText', {
              className: 'text-sm font-normal text-left lg:text-center text-sub',
            })}
          </CustomText>
          <div className="pt-1 pb-2">
            <CustomStar>
              <Rate
                allowHalf
                disabled
                value={
                  !!_.get(ctx.apply('ctf.findNode', { name: _.get(itemData, 'name', '') }), 'className')
                    ? _.get(ctx.apply('ctf.findNode', { name: _.get(itemData, 'name', '') }), 'className')
                    : 0
                }
              />
            </CustomStar>
          </div>

          <div>
            {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'longText.longText', {
              className: 'text-sm font-normal mb-1 lg:mb-7 text-left lg:text-center text-sub',
              as: 'p',
            })}
          </div>
          {/* <div className="text-xs lg:text-base font-normal text-left lg:text-center text-main">
            {ctx.apply('ctf.renderRichText', { name: itemData.name }, 'richText', null)}
          </div> */}
        </div>
      </div>
    </DIV>
  );
};
export default displayName(View8);
