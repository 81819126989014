import _ from 'lodash';
import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetAdvisorProfile from 'unitz-gql/advisor_profile/GetAdvisorProfile.gql';

graphme.GqlBuilder.loadDocument({
  GetAdvisorProfile,
});

class AdvisorProfileModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'AdvisorProfile',
    schema: {
      id: String,
      display_name: String,
      ref_ctf_eid: String,
      advisor_id: String,
    },
    foreignKeys: ['advisor_id'],
    key: 'id',

    baseQuery: 'GetAdvisorProfile',
    GQL_ACTIONS: {
      GET: 'advisor_profile_by_pk',
      FIND: 'advisor_profile',
      INSERT: 'insert_advisor_profile_one',
      UPDATE: 'update_advisor_profile_by_pk',
      DELETE: 'delete_advisor_profile_by_pk',
    },
    getClient: hasuraClient.getClient,
  });

  static async updateContract({
    user_id,
    email,
    phone_number,
    full_name,
    identify_number,
    identify_release_date,
    identify_release_place,
    permanent_address,
    signed_date,
  }) {
    const query = hasuraClient.gql`
      mutation updateProfile(
          $user_id: String!,
          $email: String!, 
          $phone_number:  String!, 
          $full_name: String!, 
          $identify_number:  String!, 
          $identify_release_date:  timestamptz!, 
          $identify_release_place:  String!, 
          $permanent_address: String!, 
          $signed_date:  timestamptz!, 
        ) {
        profile: update_advisor_profile(
          where: {advisor_id: {_eq: $user_id}},
          _set: {email: $email, phone_number: $phone_number, full_name: $full_name, identify_number: $identify_number, identify_release_date: $identify_release_date, identify_release_place: $identify_release_place, permanent_address: $permanent_address, signed_date: $signed_date}
          ) {
          returning {
            advisor_id
          }
        }
      }
    `;
    console.log({ signed_date });
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        email,
        phone_number,
        full_name,
        identify_number,
        identify_release_date,
        identify_release_place,
        permanent_address,
        signed_date,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async updateProfile({
    user_id,
    display_name,
    social_link,
    phone_number,
    tax_number,
    identify,
    passport,
    scoring_int,
    appraisal_type,
    note,
    contact,
    passed_date,
  }) {
    const query = hasuraClient.gql`
      mutation updateProfile($user_id: String!, $display_name: String!, $social_link:  String!, $phone_number: String!, $tax_number:  String!, $identify:  String!, $passport:  String!, $note:  String!, $contact:  String!, $passed_date:  timestamptz!, $scoring_int: bigint!, $appraisal_type: jsonb!) {
        profile: update_advisor_profile(
          where: {advisor_id: {_eq: $user_id}}, 
          _set: {display_name: $display_name, social_link: $social_link, identify_number: $identify, passport_number: $passport,note: $note,contact: $contact, phone_number: $phone_number, passed_date: $passed_date, tax_number: $tax_number, scoring_int: $scoring_int, appraisal_type: $appraisal_type}
          ) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        display_name,
        social_link,
        identify,
        passport,
        phone_number,
        tax_number,
        scoring_int,
        appraisal_type,
        note,
        contact,
        passed_date,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateProfileInfo({ user_id, object }) {
    const query = hasuraClient.gql`
      mutation updateProfile($user_id: String!, $object: advisor_profile_set_input = {}) {
        profile: update_advisor_profile(
          where: {advisor_id: {_eq: $user_id}}, 
          _set: $object
          ) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        object,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateDescription({ user_id, object }) {
    const query = hasuraClient.gql`
      mutation updateProfile($user_id: String!, $object: advisor_profile_set_input!) {
        profile: update_advisor_profile(where: {advisor_id: {_eq: $user_id}}, _set: $object) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        object,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async updateCategories({ advisor_profile_id, categories }) {
    const query = hasuraClient.gql`
      mutation ($advisor_profile_id: uuid!, $objects: [advisor_profile_category_insert_input!]!) {
        delete_advisor_profile_category(where: {advisor_profile_id: {_eq: $advisor_profile_id}}) {
          returning {
            category_id
          }
        }
        profile: insert_advisor_profile_category(
          objects: $objects,
          on_conflict: {constraint: advisor_profile_category_advisor_profile_id_category_id_key, update_columns: category_id}
          ){
          returning {
            category_id
            advisor_profile_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        advisor_profile_id,
        objects: categories,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async updateDetailHtml({ user_id, detail_html }) {
    const query = hasuraClient.gql`
      mutation updateProfile($user_id: String!, $detail_html: String!) {
        profile: update_advisor_profile(where: {advisor_id: {_eq: $user_id}}, _set: {detail_html: $detail_html}) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        detail_html,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateAvatar({ user_id, avatar_url }) {
    const query = hasuraClient.gql`
      mutation updateAvatar($user_id: String!, $avatar_url: String!) {
        profile: update_advisor_profile(where: {advisor_id: {_eq: $user_id}}, _set: {avatar_url: $avatar_url}) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        avatar_url,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateVideoUrl({ user_id, video_url }) {
    const query = hasuraClient.gql`
      mutation updateVideoUrl($user_id: String!, $video_url: String!) {
        profile: update_advisor_profile(where: {advisor_id: {_eq: $user_id}}, _set: {video_url: $video_url}) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        video_url,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateWorkingTime({ user_id, object }) {
    const query = hasuraClient.gql`
      mutation updateProfile($user_id: String!, $object: advisor_profile_set_input!) {
        profile: update_advisor_profile(where: {advisor_id: {_eq: $user_id}}, _set: $object) {
          returning {
            calendar
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        object,
      });
      const data = _.get(rtn, 'profile.returning.0');
      const calendar = _.get(data, 'calendar', null);
      return calendar;
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ AdvisorProfileModel });

export default AdvisorProfileModel;
