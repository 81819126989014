import _ from 'lodash';
import importAll from 'import-all.macro';

import DIContainer from '@vl/mod-utils/DIContainer';

const actionContainer = new DIContainer();

actionContainer.loadDir([importAll.sync('./**/index.js')]);

// eslint-disable-next-line
const ver = 6;

const resolveItemAction = (item) => {
  const actionName = _.get(item, 'action');
  if (actionContainer.has(actionName)) {
    return actionContainer.get(actionName);
  }
  return null;
};

export const resolve = (item) => {
  const action = resolveItemAction(item);
  return action;
};

export const resolveAction = resolve;
