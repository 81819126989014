import React from 'react';
import _ from 'lodash';
import cx from 'classnames';

import { BindingContext } from './bindings';
import DataContext from './DataContext';
import Context from './Context';
import * as rules from './rules';

const mergeRuleSets = (ruleSets = []) => {
  return _.castArray(ruleSets || []).reduce(
    (acc, { value }) => {
      acc.rules.push(..._.castArray(value.rules || []));
      return acc;
    },
    { rules: [] }
  );
};

// shorthand ruleSet
const shorthandRuleSets = {
  as: (input) => {
    const { as, props } = input;
    return [{ path: 'inline', value: { rules: [{ type: 'as', options: { as, props } }] } }];
  }
};
const resolveShorthandRuleSets = (props) => {
  // as shorthand rule
  const { as } = props;
  if (as) {
    return shorthandRuleSets.as(props);
  }
  return [];
};

const interpolateBindings = (props, ctx) => {
  // eslint-disable-next-line
  const bindingContainer = React.useContext(BindingContext);
  let {
    bindings, data, forceCtx, ...rest
  } = props;
  const { className } = props;

  let ruleSetsFromContext;
  // bindings ruleSets from bindings context
  if (className) {
    ruleSetsFromContext = bindingContainer && bindingContainer.get({ className, ctx });
    // merge bindings;
  }

  // bindings ruleSets from props
  const ruleSetsFromProps = data ? [{ path: 'inline', value: data }] : [];
  if (forceCtx) {
    ruleSetsFromProps.push({ path: 'inline', value: { rules: [{ type: 'force', options: {} }] } });
  }

  // combine ruleSets
  const ruleSets = [
    ...ruleSetsFromProps,
    ...resolveShorthandRuleSets(props),
    ..._.castArray(ruleSetsFromContext || [])
  ];

  bindings = mergeRuleSets(ruleSets);
  return {
    ...rest,
    bindings: bindings ? { ...bindings } : null,
    className
  };
};

const normalizeRuleOptions = (type, options) => {
  if (_.isString(options)) {
    return { [type]: options };
  }
  return options;
};

const normalizeRuleConfig = (rule) => {
  let type;
  let options;
  if (_.isArray(rule)) {
    type = rule[0];
    options = normalizeRuleOptions(type, rule[1]);
  } else {
    type = rule.type;
    options = normalizeRuleOptions(type, rule.options);
  }
  return { type, options };
};

const DIV = (props) => {
  const ctx = React.useContext(DataContext) || new Context({});
  const { bindings, ...rest } = interpolateBindings(props, ctx);
  if (!bindings) {
    // no bindings found, apply renderProps
    const { children, ...otherDefault } = rest;
    return children(otherDefault, ctx);
  }

  const { children, className, ...otherProps } = rest;
  const render = _.castArray(_.get(bindings, 'rules') || []).reduceRight((acc, rule) => {
    const { type, options } = normalizeRuleConfig(rule);
    const ruleMapper = rules.resolveRule(type);
    if (ruleMapper) {
      return ruleMapper({
        children: acc,
        options,
        className: cx(className, type),
        props: otherProps
      });
    }
    // no rule mapper found, display warning
    console.warn(`Data rule "${type}" is not found`);
    return children;
  }, children);
  return render(otherProps, ctx);
};

export default DIV;
