import React from 'react';
import _ from 'lodash';

export const usePromiseSource = (promise, def, deps) => {
  const [val, $val] = React.useState(def);
  const ref = React.useRef({});
  _.assign(ref.current, { val, $val, promise });

  const setVal = (newVal) => {
    if (!_.isEqual(newVal, ref.current.val)) {
      // update local val
      if (ref.current.$val) {
        ref.current.$val(newVal);
      }
    }
  };
  React.useEffect(() => {
    (async () => {
      let val = promise;
      if (_.isFunction(val)) {
        val = val();
      }
      val = await val;
      setVal(val);
    })();
    return () => {
      ref.current = {};
    };
    // eslint-disable-next-line
  }, [ref, ..._.castArray(deps || [])]);

  return ref.current.val;
};

export default usePromiseSource;
