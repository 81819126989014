import React from 'react';
import _ from 'lodash';
import importAll from 'import-all.macro';

import DIContainer from '@vl/mod-utils/DIContainer';

const enhancerContainer = new DIContainer();

enhancerContainer.loadDir([importAll.sync('./**/index.js')]);

// eslint-disable-next-line
const ver = 3;

const resolveItemEnhancers = (item) => {
  const enhancers = _.compact(
    (_.get(item, 'enhancers') || []).map((enhancer) => {
      const enhancerName = _.get(enhancer, 'name');

      if (enhancerContainer.has(enhancerName)) {
        return enhancerContainer.get(enhancerName);
      }
      return undefined;
    })
  );
  return enhancers;
};

const EnhancerComponent = ({ children, item }) => {
  const enhancers = resolveItemEnhancers(item);
  return enhancers.reduceRight((acc, Component) => {
    return React.createElement(Component, null, acc);
  }, children);
};

export const resolve = (item, info) => {
  const enhancers = resolveItemEnhancers(item);
  if (enhancers.length) {
    return EnhancerComponent;
  }
};

export const resolveEnhancer = resolve;
