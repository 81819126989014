import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import cx from 'classnames';

const StyledImage = styled.div``;

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: ${gstyles.colors.white500}
    white-space: break-spaces;
  }
`;
export const ImageMobileWithTextOverlay = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <StyledImage
          className={cx(
            `relative ${ctx.apply(
              'ctf.findEntry',
              itemData,
              _.get(itemProps, 'fieldNames.className', 'className'),
              ''
            )}`,
            _.get(itemProps, 'className')
          )}
        >
          <Image
            src={ctx.apply(
              'ctf.findImage',
              itemData,
              _.get(itemProps, 'fieldNames.image', 'image'),
              ...[].concat(_.get(itemProps, 'args') || [])
            )}
            size="cover"
            title={ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.shortText', 'shortText'), '')}
            alt={_.get(itemData, 'shortText', '')}
            preview={false}
          />
          <div className="overlay block lg:hidden bg-black300 absolute top-0 left-0 w-full"></div>
          <div className="block lg:hidden absolute top-0 left-0 p-3 w-full h-full">
            <div className="font-semibold text-2xl mb-2 text-white500">
              {_.get(ctx.get('itemData'), 'shortText', '')}
            </div>
            <CustomParagraph>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name', '') }, 'longText.longText', null)}
            </CustomParagraph>
          </div>
        </StyledImage>
      );
    })}
  </DIV>
);

export default ImageMobileWithTextOverlay;
