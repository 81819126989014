import React from 'react';
import { bindings, hook } from '@vl/redata';

import _ from 'lodash';
import CourseRoomFormatter from '@uz/unitz-models/CourseRoomModel/formatter';
import moment from 'moment';
import { TIMESTAMPTZ_FORMAT } from '@vl/mod-utils/datetime';
import UserModel from '@uz/unitz-models/UserModel';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const bindData = bindings({
  componentContainer: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return !!ctx.apply('authModel.getUserId');
          }),
        },
      ],
    ],
  },
  advisorMenus: {
    rules: [
      [
        'data',
        {
          data: {
            courseRooms: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { ctx });
              const userId = ctx.apply('authModel.getUserId');
              const subject = useObservableSource(() =>
                UserModel.find(
                  `where: {id: {_eq: "${userId}"}}`,
                  `course_rooms(
                      order_by: {start_at: asc_nulls_last},
                      where: {
                        start_at: {_gte: "${moment()
                          .startOf('day')
                          .utc()
                          .format(TIMESTAMPTZ_FORMAT)}"
                        }
                        end_at: {_lte: "${moment()
                          .endOf('day')
                          .utc()
                          .format(TIMESTAMPTZ_FORMAT)}"
                        }
                      }
                    ) {
                    id
                    start_at
                    end_at
                    type_id
                    duration
                    course {
                      id
                      slug
                      advisor_id
                      name
                      description
                    }
                    attendees {
                      id
                      user_id
                      display_name
                      avatar_url
                    }
                    purchase_info {
                      purchase_id
                    }
                  }`
                )
              );
              const users = useSubjectSource(subject);
              const items = _.get(users, '0.course_rooms', []);
              ref.current.courseRooms = CourseRoomFormatter.formatRoomEvents(ctx)(items);

              return {
                items: ref.current.courseRooms || [],
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
