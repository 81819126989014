import React from 'react';
import _ from 'lodash';
import { hook, bindings } from '@vl/redata';
import moment from 'moment';

export default bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            courseModel: hook((ctx) => {
              const item = ctx.get('@item');
              const userId = ctx.apply('authModel.getUserId');
              const model = {
                isAttendee: () => {
                  return !!_.find(_.get(item, 'attendees', []), { user_id: userId });
                },
                isAdvisor: () => {
                  const advisor_id = _.get(item, 'advisor_id') || _.get(item, 'advisor.profile.advisor_id');
                  return advisor_id === userId;
                },
                isPurchased: () => {
                  return !!_.find(_.get(item, 'purchases', []), { user_id: userId });
                },
                hasPurchase: () => {
                  return !!_.get(item, 'purchases.length', []);
                },
                can: (action) => {
                  if (action === 'dismiss') {
                    // only for future room and attendees
                    if (model.isPassedRoom() || !model.isAdvisor() || model.hasPurchase()) {
                      return false;
                    }
                  } else if (action === 'edit') {
                    if (!model.isAdvisor()) {
                      return false;
                    }
                  }
                  return true;
                },
              };
              return model;
            }),
          },
        },
      ],
    ],
  },
});
