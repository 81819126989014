import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import Button from '@uz/unitz-components-web/Button';
import { Row, Col } from 'antd';
import { Input, Form, FormItem, Checkbox, Select } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import _ from 'lodash';

const CustomInput = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
  }
  textarea,
  input {
    border-radius: 8px;
    border-color: ${gstyles.colors.divider};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.divider};
      }
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const View8 = () => {
  return (
    <DIV className="AdvisorRegistratorForm">
      <ModalProvider>
        {ctx.debug(() => {
          ctx.set('modalModel', ctx.get('modalModel'));
        })}
      </ModalProvider>
      <section className="bg-white500 lg:rounded-lg lg:shadow-lg lg:px-4 py-8">
        <DIV className="applyForm">
          <Row justify="center">
            <Col xs={22} md={22} lg={24}>
              <FormProvider form={ctx.get('form')}>
                <Form {...layout} className="registrator-form">
                  <CustomInput>
                    <FormItem
                      name="first_name"
                      label={
                        <span className="font-normal text-sm text-sub">
                          {ctx.apply('i18n.t', 'Biz.Form.first_name')}
                        </span>
                      }
                      required
                    >
                      <Input name="first_name" size="medium" />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem
                      name="last_name"
                      label={
                        <span className="font-normal text-sm text-sub">
                          {ctx.apply('i18n.t', 'Biz.Form.last_name')}
                        </span>
                      }
                      required
                    >
                      <Input name="last_name" size="medium" />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem
                      name="email"
                      label={
                        <span className="font-normal text-sm text-sub">{ctx.apply('i18n.t', 'Biz.Form.email')}</span>
                      }
                      required
                    >
                      <Input name="email" size="medium" />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem
                      name="title"
                      label={
                        <span className="font-normal text-sm text-sub">{ctx.apply('i18n.t', 'Biz.Form.title')}</span>
                      }
                      required
                    >
                      <Input name="title" size="medium" />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem
                      name="phone"
                      label={
                        <span className="font-normal text-sm text-sub">
                          {ctx.apply('i18n.t', 'Biz.Form.phone_label')}
                        </span>
                      }
                      required
                    >
                      <Input
                        name="phone"
                        size="medium"
                        placeholder={ctx.apply('i18n.t', 'Biz.Form.phone_placeholder')}
                      />
                    </FormItem>
                  </CustomInput>
                  <CustomInput>
                    <FormItem
                      name="organization"
                      label={
                        <span className="font-normal text-sm text-sub">
                          {ctx.apply('i18n.t', 'Biz.Form.organization_name')}
                        </span>
                      }
                      required
                    >
                      <Input name="organization" size="medium" />
                    </FormItem>
                  </CustomInput>

                  <Row>
                    <Col span={12}>
                      <CustomInput>
                        <FormItem
                          name="country"
                          label={
                            <span className="font-normal text-sm text-sub">
                              {ctx.apply('i18n.t', 'Biz.Form.country_label')}
                            </span>
                          }
                          required
                        >
                          <Select
                            name="country"
                            size="medium"
                            showSearch
                            suffixIcon={gstyles.icons({
                              name: 'arrow-down',
                              size: 24,
                              fill: gstyles.colors.sub,
                              className: '-mt-2 -ml-2',
                            })}
                            placeholder={ctx.apply('i18n.t', 'Biz.Form.country_placeholder')}
                          >
                            {_.map(ctx.get('form.selectCountry'), (item, index) => (
                              <Select.Option key={index} value={item.name}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      </CustomInput>
                    </Col>
                  </Row>

                  <CustomInput>
                    <FormItem
                      name="message"
                      label={
                        <span className="font-normal text-sm text-sub">{ctx.apply('i18n.t', 'Biz.Form.message')}</span>
                      }
                    >
                      <Input.TextArea
                        rows="4"
                        name="message"
                        size="medium"
                        // placeholder={ctx.apply('i18n.t', 'Biz.Form.message_placeholder')}
                      />
                    </FormItem>
                  </CustomInput>
                  {/* <CustomInput>
                    <FormItem name="check" required>
                      <Checkbox name="check">
                        <div className="mb-0 text-sm text-main font-normal">
                          {ctx.apply('i18n.t', 'Biz.Form.check_text')}
                        </div>
                      </Checkbox>
                    </FormItem>
                  </CustomInput> */}
                  <Row justify="center">
                    <Col xs={24}>
                      <div className="flex justify-center">
                        <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'medium' }}>
                          {(resProps) => (
                            <Button
                              type="primary"
                              htmlType="submit"
                              disabled={!ctx.get('form.canSubmit')}
                              loading={ctx.get('form.isSubmitting')}
                              block
                              {...resProps}
                            >
                              <span>{ctx.apply('i18n.t', 'Biz.Form.register')}</span>
                            </Button>
                          )}
                        </ResponsiveProps>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </FormProvider>
            </Col>
          </Row>
        </DIV>
      </section>
    </DIV>
  );
};

export default displayName(View8);
