import React from 'react';
import _ from 'lodash';

const createWrapper = (target, { key, ref, deps }) => {
  // function wrapper
  if (_.isFunction(target)) {
    if (!ref.current.cache[key] || !_.isEqual(deps, ref.current.depsCache[key])) {
      ref.current.cache[key] = (...args) => {
        const handler = ref.current.wrapperCache[key] || target;
        return handler(...args);
      };
    }
    return ref.current.cache[key];
  }
  // plain object wrapper;
  return target;
};

const globalRef = {
  current: {
    cache: {},
    wrapperCache: {},
    depsCache: {},
    memo: (key, target, deps) => {
      globalRef.current.wrapperCache[key] = target;
      globalRef.current.depsCache[key] = deps;
      const rtn = createWrapper(target, { key, ref: globalRef, deps });
      return rtn;
    }
  }
};

export const useCtxMemo = () => {
  try {
    const ref = React.useRef({
      cache: {},
      wrapperCache: {},
      depsCache: {},
      memo: (key, target, deps) => {
        ref.current.wrapperCache[key] = target;
        ref.current.depsCache[key] = deps;
        const rtn = createWrapper(target, { key, ref, deps });
        return rtn;
      }
    });
    return ref.current.memo;
  } catch (error) {
    return globalRef.current.memo;
  }
};

export default useCtxMemo;
