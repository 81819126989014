import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Menu, Space } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
  .ant-sub-menu {
    border-radius: 8px;
  }
`;

function DesktopView() {
  return (
    <DIV className="component">
      <DIV forceCtx>
        <CustomDropDownContent className="shadow-none xl:shadow-lg">
          <Menu
            forceSubMenuRender={true}
            expandIcon={<span>{gstyles.icons({ name: 'arrow-right', size: 24, fill: gstyles.colors.sub })}</span>}
          >
            {ctx.get('categories.children', []).map((cat, index) => {
              const itemData = ctx.apply('ctf.findNode', { id: _.get(cat, 'id', '') });
              let catId = { category: _.get(itemData, 'id', '') };
              if (_.isNull(_.get(itemData, 'children'))) {
                return (
                  <Menu.Item
                    key={`${_.get(itemData, 'id', '')}_${index}`}
                    className="bg-white500 hover:bg-brandb-lightest"
                  >
                    <Link
                      className="text-lg leading-10"
                      // target="_blank"
                      external
                      to={ctx.apply('routeStore.toUrl', 'filterCourse', catId)}
                    >
                      <Space size="small">
                        {gstyles.icons({
                          name: _.get(itemData, 'icon', 'replace'),
                          size: 24,
                          fill: gstyles.colors.brandb500,
                        })}
                        <span className="text-base font-normal text-main">{_.get(itemData, 'displayName', '')}</span>
                      </Space>
                    </Link>
                  </Menu.Item>
                );
              }
              return (
                <Menu.SubMenu
                  onTitleMouseLeave={() => {}}
                  popupOffset={[1, -10]}
                  className="bg-white500 hover:bg-brandb-lightest"
                  key={_.get(itemData, 'id')}
                  popupClassName="rounded-lg shadow-lg"
                  title={
                    <Link
                      className="text-lg leading-10"
                      // target="_blank"
                      external
                      to={ctx.apply('routeStore.toUrl', 'filterCourse', catId)}
                    >
                      <Space size="small">
                        {gstyles.icons({
                          name: _.get(itemData, 'icon', 'replace'),
                          size: 24,
                          fill: gstyles.colors.brandb500,
                        })}
                        <span className="text-base font-normal text-main">{_.get(itemData, 'displayName', '')}</span>
                      </Space>
                    </Link>
                  }
                >
                  {_.map(_.get(itemData, 'children', []), (item) => {
                    const subData = ctx.apply('ctf.findNode', { id: _.get(item, 'id', '') });
                    let catId = { category: _.get(itemData, 'id', '') };
                    if (!subData) return null;
                    return (
                      <Menu.Item
                        key={`${_.get(subData, 'id', '')}_${index}`}
                        className="bg-white500 hover:bg-brandb-lightest"
                      >
                        <Link
                          className="text-lg leading-10"
                          // target="_blank"
                          external
                          to={ctx.apply('routeStore.toUrl', 'filterCourse', catId)}
                        >
                          <Space size="small">
                            {gstyles.icons({
                              name: _.get(subData, 'icon', 'replace'),
                              size: 24,
                              fill: gstyles.colors.brandb500,
                            })}
                            <span className="text-base font-normal text-main">{_.get(subData, 'displayName', '')}</span>
                          </Space>
                        </Link>
                      </Menu.Item>

                      // <Menu.SubMenu
                      //   onTitleMouseLeave={() => {}}
                      //   popupOffset={[-1, -4]}
                      //   popupClassName="rounded-lg bg-red shadow-lg"
                      //   className="bg-white500 hover:bg-brandb-lightest"
                      //   title={
                      //     <Space size="small">
                      //       {gstyles.icons({
                      //         name: _.get(itemData, 'icon', 'replace'),
                      //         size: 24,
                      //         fill: gstyles.colors.brandb500,
                      //       })}
                      //       <span className="text-base font-normal text-main">
                      //         {_.get(itemData, 'displayName', '')}
                      //       </span>
                      //     </Space>
                      //   }
                      // >
                      //   {_.map(_.get(itemData, 'children', []), (subItem) => {
                      //     const subItemData = ctx.apply('ctf.findNode', { id: _.get(subItem, 'id', '') });
                      //     if (!subItemData) return null;
                      //     return (
                      //       <Menu.Item
                      //         className="px-3 bg-white500 hover:bg-brandb-lightest"
                      //         key={_.get(subItem, 'id')}
                      //         onItemHover={() => {}}
                      //       >
                      //         <Link
                      //           className="leading-10"
                      //           target="_blank"
                      //           to={ctx.apply('routeStore.toUrl', 'category', subItemData)}
                      //         >
                      //           <span className="text-base font-normal text-main">
                      //             {_.get(subItemData, 'displayName', '')}
                      //           </span>
                      //         </Link>
                      //       </Menu.Item>
                      //     );
                      //   })}
                      // </Menu.SubMenu>
                    );
                  })}
                </Menu.SubMenu>
              );
            })}
          </Menu>
        </CustomDropDownContent>
      </DIV>
    </DIV>
  );
}

export default DesktopView;
