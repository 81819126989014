import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import UserCourseCarousel from '@uz/unitz-components-web/UserCourseCarousel';
import Link from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const LandingMyCourseLayout = () => {
  return (
    <DIV className="LandingMyCourseLayout">
      {!!ctx.get('myCourses.length') && (
        <section className="wrapper app-row course-list-section">
          <div className="flex flex-row items-center justify-between">
            <div className="flex text-base font-semibold">
              {gstyles.icons({ name: 'menuBook', size: 22, fill: gstyles.colors.sub })}
              <span className="ml-2">{ctx.apply('i18n.t', 'Profile.course')}</span>
            </div>
            <Link to={ctx.apply('routeStore.toUrl', 'userProfileMenu', '/course')} className="text-base font-semibold">
              {ctx.apply('i18n.t', 'AdvisorProfileGeneral.Detail')}
            </Link>
          </div>
          <DIV forceCtx>
            {ctx.debug(() => {
              ctx.set('@items', ctx.get('myCourses.items'));
            })}
            <UserCourseCarousel />
          </DIV>
        </section>
      )}
    </DIV>
  );
};

export default LandingMyCourseLayout;
