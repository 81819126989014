import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const CustomTab = styled.div`
  .ant-tabs {
    .ant-tabs-nav {
      flex: 1 1 30%;
      background-color: ${gstyles.colors.background2};
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            margin: 0 !important;
            padding: 8px 24px;
            &.ant-tabs-tab-active {
              background-color: ${gstyles.colors.white500};
              margin: 0;
            }
          }
          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      flex: 1 1 70%;
      box-shadow: 0px 1px 6px rgba(38, 51, 64, 0.18);
      .ant-tabs-content {
        .ant-tabs-tabpane {
          padding-left: 0;
        }
      }
    }
  }
`;

function QuickSettingLayout() {
  return (
    <DIV className="component">
      <div className="col-span-1">
        <div className="py-0 sm:wrapper app-row">
          {ctx.apply('ctf.renderProps', () => {
            return (
              <div className="rounded-lg shadow-md overflow-hidden bg-white500">
                <div className="section-header px-4 py-6">
                  {_.map(ctx.get('sectionData.nodes'), (section, index) => (
                    <div key={index}>{ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}</div>
                  ))}
                </div>
                {ctx.get(`sectionState.sectionState.${ctx.get('parentSectionName')}`) !== 'hide' && (
                  <div className="section-body border-t-2">
                    <CustomTab>
                      <Tabs className="block" defaultActiveKey="0" tabPosition="left" size="large">
                        {_.map(ctx.get('sectionData.sections'), (section, index) => {
                          const sectionData = ctx.apply('ctf.findNode', { id: _.get(section, 'id') });
                          return (
                            <Tabs.TabPane
                              key={index}
                              tab={
                                <div className="flex flex-col md:flex-row md:items-center md:space-x-2 py-2">
                                  <div>
                                    {gstyles.icons({
                                      name: !!ctx.get(`advisorState.state.${_.get(sectionData, 'slug', '')}`)
                                        ? 'check-circle'
                                        : _.get(sectionData, 'className', ''),
                                      size: 24,
                                      fill: !!ctx.get(`advisorState.state.${_.get(sectionData, 'slug', '')}`)
                                        ? gstyles.colors.brandb500
                                        : gstyles.colors.sub,
                                    })}
                                  </div>
                                  <ResponsiveProps
                                    xs={{
                                      className:
                                        'text-left mt-2 text-ellipsis-2 whitespace-normal font-normal text-base text-main',
                                    }}
                                    md={{ className: 'text-left whitespace-normal font-normal text-base text-main' }}
                                  >
                                    {(resProps) => (
                                      <div className="" {...resProps}>
                                        {_.get(sectionData, 'shortText', '')}
                                      </div>
                                    )}
                                  </ResponsiveProps>
                                </div>
                              }
                            >
                              <div className="p-8">
                                {_.map(_.get(sectionData, 'sections', []), (item, index) => (
                                  <React.Fragment key={index}>
                                    {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                                  </React.Fragment>
                                ))}
                              </div>
                            </Tabs.TabPane>
                          );
                        })}
                      </Tabs>
                    </CustomTab>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </DIV>
  );
}

export default QuickSettingLayout;
