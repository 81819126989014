import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import cx from 'classnames';

const AboutUsGoalLayout = () => {
  return (
    <DIV className="AboutUsGoalLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <div className="wrapper app-row py-0 md:py-16">
            <div className="py-8">
              <div className="text-xl md:text-3xl mb-8 text-main text-center font-semibold">
                {ctx.get('sectionData.shortText')}
              </div>
              <Row gutter={[{ xs: 0, sm: 12, md: 48 }, 24]}>
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col
                      key={index}
                      xs={24}
                      sm={12}
                      lg={8}
                      className={cx(ctx.get('sectionData.className'))}
                      key={index}
                    >
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsGoalLayout;
