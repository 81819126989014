import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomCarousel = styled.div`
  .slick-slider {
    .slick-dots {
      bottom: -0px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.background2};
          border-radius: 50%;
          width: 8px;
          height: 8px;

          &:before {
            content: '';
          }
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div className="absolute cursor-pointer bottom-2/4 -right-4 -translate-y-3/4 hover:opacity-90" onClick={onClick}>
        {gstyles.icons({ name: 'arrow-right', size: 40, fill: gstyles.colors.sub })}
      </div>
    </DIV>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div className="z-20 absolute cursor-pointer bottom-2/4 -left-4 -translate-y-3/4 hover:opacity-90" onClick={onClick}>
        {gstyles.icons({ name: 'arrow-left', size: 40, fill: gstyles.colors.sub })}
      </div>
    </DIV>
  );
};

function AdvisorRegistratorTestimonialLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const sectionItems = _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []);
        const settings = {
          dots: _.includes(ctx.get('sectionData.className'), 'non-dot') ? false : true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        };
        return (
          <div className={ctx.get('sectionData.className')}>
            <div className="app-row wrapper">
              <div className="flex flex-col mb-6 justify-center items-center">
                {!!ctx.get('sectionData.shortText') && (
                  <h5 className="text-sm text-sub text-center font-semibold">{ctx.get('sectionData.shortText')}</h5>
                )}
                <CustomParagraph className="text-center">
                  {ctx.apply(
                    'ctf.renderHTMLText',
                    { name: ctx.get('sectionData.name', '') },
                    'longText.longText',
                    null
                  )}
                </CustomParagraph>
              </div>
              <CustomCarousel>
                <div className="px-0 min-xl:px-4">
                  <Slider
                    className="relative"
                    {...{
                      nextArrow: <NextButton onClick />,
                      prevArrow: <PrevButton onClick />,
                    }}
                    {...settings}
                  >
                    {sectionItems.map((item, index) => (
                      <div className="app-row mb-6 min-md:mb-12 mt-4 min-md:mt-0 px-0 min-sm:px-12" key={index}>
                        <DIV forceCtx>
                          {ctx.debug(() => {
                            ctx.set('index', index);
                            ctx.set('item', item);
                          })}
                          {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                        </DIV>
                      </div>
                    ))}
                  </Slider>
                </div>
              </CustomCarousel>
            </div>
          </div>
        );
      })}
    </DIV>
  );
}

export default AdvisorRegistratorTestimonialLayout;
