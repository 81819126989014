import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const CustomRow = styled(Row)`
  min-width: 300px;
`;

function AuthSectionLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <CustomRow align="middle" gutter={12} className="px-0 lg:px-3 py-3 lg:py-0 auth-group py-0" id="auth-group">
            <DIV>
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                (col, index) => (
                  <Col xs={12} md={12} lg={12} xl={12} key={`${col.name}_${index}`}>
                    {ctx.apply('ctf.renderEntry', { name: col.name }) || null}
                  </Col>
                )
              )}
            </DIV>
          </CustomRow>
        );
      })}
    </DIV>
  );
}

export default AuthSectionLayout;
