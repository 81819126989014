import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { Link } from '@uz/unitz-components-web/Link';
import useRoute from '@vl/hooks/useGbRoute';

export const DynamicFormDirectionLink = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      const pageData = useRoute().getPageContext();
      const specialSlugs = ['/for-school', '/for-center', '/for-enterprise'];

      return (
        <Link
          to={ctx.apply(
            'routeStore.toLocale',
            `${_.includes(specialSlugs, _.get(pageData, 'slug')) ? _.get(pageData, 'slug', '') : ''}${_.get(
              itemData,
              'linkHref'
            )}`
          )}
          aria-label={_.get(itemData, 'shortText')}
          external
        >
          <div>
            <span
              className={cx(
                ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
                _.get(itemProps, 'className')
              )}
            >
              {ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', '']))}
            </span>
          </div>
        </Link>
      );
    })}
  </DIV>
);

export default DynamicFormDirectionLink;
