import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';

function SocialLinkButtonGroupLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const sectionData = ctx.get('sectionData');
        return (
          <Row gutter={[32, 24]} justify="center" align="middle" className="socialGroup mb-0 sm:mb-8" id="socialGroup">
            {_.map(_.get(sectionData, 'sections', []), (col, index) => {
              return (
                <Col
                  className="flex justify-center items-center"
                  xs={2}
                  sm={2}
                  md={2}
                  lg={8}
                  key={`${col.name}_${index}`}
                >
                  {ctx.apply('ctf.renderEntry', { name: col.name }) || null}
                </Col>
              );
            })}
          </Row>
        );
      })}
    </DIV>
  );
}

export default SocialLinkButtonGroupLayout;
