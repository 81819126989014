import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import styled from 'styled-components';

function VideoIntroductionItemLayout() {
  return (
    <DIV className="component">
      <div className="py-0 sm:wrapper app-row">
        {ctx.apply('ctf.renderProps', () => {
          return (
            <div className="overflow-hidden rounded-lg shadow-lg bg-white500">
              <div className="section-body">
                {_.map(ctx.get('sectionData.sections'), (section, index) => {
                  <React.Fragment key={`content_${index}`}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                  </React.Fragment>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </DIV>
  );
}

export default VideoIntroductionItemLayout;
