import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import elementResizeDetectorMaker from 'element-resize-detector';

class Dispatcher {
  listeners = new Map();

  static ensure(container, dataKey) {
    _.update(container, dataKey, (val) => (val instanceof Dispatcher ? val : new Dispatcher()));
    return _.get(container, dataKey);
  }

  subscribe(listener) {
    this.listeners.set(listener, () => {
      this.listeners.delete(listener);
    });
    return this.listeners.get(listener);
  }

  dispatch(data) {
    const it = this.listeners.entries();
    for (const item of it) {
      const [listener] = item;
      _.isFunction(listener) && listener(data);
    }
  }
}

const normalizeDataKey = (dataKey) => {
  if (_.isPlainObject(dataKey)) {
    const keys = _.keys(dataKey);
    return [dataKey[keys[0]], keys[0]];
  }
  if (_.isString(dataKey)) {
    return [dataKey, 'layout'];
  }
  if (_.isArray(dataKey)) {
    return dataKey;
  }
  return dataKey;
};

const bindData = bindings({
  layoutProvider: {
    rules: [
      [
        'data',
        {
          data: {
            LAYOUT: hook((ctx) => {
              const ref = React.useRef({ data: {}, listeners: {} });

              const getData = (dataKey) => {
                return _.get(ref.current.data, dataKey);
              };

              const RendererComponent = React.useMemo(
                () => ({ dataKey, render, ctxKey }) => {
                  const [v, $v] = React.useState(0);
                  const cRef = React.useRef({});

                  Object.assign(cRef.current, { v, $v });

                  React.useEffect(() => {
                    const dis = Dispatcher.ensure(ref.current.listeners, dataKey).subscribe(() => {
                      cRef.current.$v(cRef.current.v + 1);
                    });
                    return () => {
                      dis();
                    };
                  }, [dataKey, cRef]);

                  if (_.isFunction(render)) {
                    const layout = getData(dataKey);
                    if (ctxKey) {
                      return render({ [ctxKey]: layout });
                    }
                    return render({ layout });
                  }
                  return null;
                },
                []
              );

              const onLayout = (dataKey) => {
                if (!ref.current.erd) {
                  ref.current.erd = elementResizeDetectorMaker({});
                }
                return (eleRef) => {
                  if (eleRef) {
                    ref.current.erd.listenTo(eleRef, (element) => {
                      const width = element.offsetWidth;
                      const height = element.offsetHeight;
                      _.set(ref.current.data, dataKey, { width, height });
                      // emit all listener on the key if layout changed
                      Dispatcher.ensure(ref.current.listeners, dataKey).dispatch();
                    });
                  }
                };
              };

              const useLayout = (key, render) => {
                const [dataKey, ctxKey] = normalizeDataKey(key);
                if (_.isFunction(render)) {
                  return <RendererComponent render={render} dataKey={dataKey} ctxKey={ctxKey} />;
                }
                return null;
              };

              const getLayout = (dataKey) => {
                return _.get(ref.current.data, dataKey);
              };

              React.useEffect(() => {
                return () => {
                  if (ref.current) {
                    // ref.current.erd.removeAllListeners();
                    ref.current = {};
                  }
                };
              }, []);

              // const matchBreakpoint = withBreakpoints({
              //   sm: 300,
              //   md: 700,
              // });

              // const makeBreakpointResolver = (fn) => {
              //   _.set(fn, 'isBreakpointResolver', true);
              //   return fn;
              // };

              return {
                onLayout,
                useLayout,
                getLayout,
                // Dimensions: Dimensions.get('window'),
                // insets,
                // matchBreakpoint,
                // makeBreakpointResolver,
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
