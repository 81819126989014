import React from 'react';
import _ from 'lodash';
import DataContext from './DataContext';

export const ctx = {
  get: (key) => {
    // eslint-disable-next-line
    const foundContext = React.useContext(DataContext);
    if (foundContext) {
      return foundContext.get(key);
    }
    return key;
  },
  debug: (fn) => {
    if (_.isFunction(fn)) {
      fn();
    }
    return null;
  },
  memo(fn, deps) {
    return fn;
  },
  apply: (path, ...args) => {
    const fn = this.get(path);
    if (_.isFunction(fn)) {
      return fn(...args);
    }
  }
};

export const isSelector = (prop) => {
  return _.isFunction(prop) && _.get(prop, 'isSelector');
};

export default ctx;
