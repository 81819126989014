const _ = require('lodash');

const LOADERS_MAP = new WeakMap();

const ensureID = (id) => {
  if (_.isString(id)) {
    return Symbol.for(id);
  }
  return id;
};

const DataLoader = (ID, loader) => {
  ID = ensureID(ID);
  if (LOADERS_MAP.has(ID)) {
    return LOADERS_MAP.get(ID);
  }

  const caches = {};
  const rtn = {
    load: async (id) => {
      let val;
      if (!_.has(caches, id)) {
        val = await loader(id);
        _.set(caches, id, val);
      }
      return _.get(caches, id);
    },
  };
  LOADERS_MAP.set(ID, rtn);
  return rtn;
};

exports.DataLoader = DataLoader;
