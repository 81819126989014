import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    scrollbar-width: none;
    display: none;
    -ms-overflow-style: none;
  }
`;
const AdvisorRegistratorWorkFlowLayout = () => {
  return (
    <DIV className="AdvisorRegistratorWorkFlow">
      {ctx.apply('ctf.renderProps', () => (
        <div className="wrapper app-row">
          <h2 className="mt-4 min-md:mt-0 mb-10 text-2xl min-md:text-3xl min-lg:text-4xl font-semibold text-center text-main">
            {ctx.get('sectionData.shortText')}
          </h2>
          <ScrollX className="relative flex pb-3 overflow-x-scroll lg:overflow-x-hidden">
            <ResponsiveProps
              xs={{ wrap: false, align: 'start' }}
              md={{ wrap: false, align: 'start' }}
              lg={{ wrap: true, align: 'center' }}
              xl={{ wrap: true, align: 'center' }}
            >
              {(resProps) => (
                <Row
                  gutter={{
                    xs: 16,
                    sm: 16,
                    md: 24,
                    lg: 16,
                    xl: 32,
                  }}
                  {...resProps}
                >
                  {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                    (col, index) => (
                      <Col
                        className="mb-3 gutter-row sm:mb-0"
                        lg={6}
                        xs={12}
                        sm={24}
                        md={10}
                        key={`${_.get(col, 'name', '')}_${index}`}
                      >
                        {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                      </Col>
                    )
                  )}
                </Row>
              )}
            </ResponsiveProps>
          </ScrollX>
        </div>
      ))}
    </DIV>
  );
};

export default AdvisorRegistratorWorkFlowLayout;
