import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';

const CustomItem = styled.div`
  .ant-row {
    &:last-child,
    &:first-child {
      flex-direction: row;
      .content {
        text-align: left;
      }
    }
    &:not(&:last-child, &:first-child) {
      flex-direction: row-reverse;
      .content {
        text-align: left;
      }
    }
  }
`;

const ConsultingStepLayout = () => {
  return (
    <DIV className="content">
      <div className="wrapper app-row">
        <CustomItem>
          {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
            (item, index) => (
              <React.Fragment key={index}>
                {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
              </React.Fragment>
            )
          )}
        </CustomItem>
      </div>
    </DIV>
  );
};

export default ConsultingStepLayout;
