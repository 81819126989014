import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomStepName = styled.div`
  p > p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
  }
`;

const CustomParagraph = styled.div`
  p > p {
    font-weight: 400;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
  }
`;

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    scrollbar-width: none;
    display: none;
    -ms-overflow-style: none;
  }
`;

const CustomTitle = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 36px;
    }
    color: ${gstyles.colors.main};
  }
`;

const View10 = () => {
  return (
    <DIV className="guideline">
      <div className="wrapper app-row sm:px-4 xl:px-0 sm:w-full md:py-6 xl:py-16">
        <div className="flex flex-col mb-6 justify-center items-center">
          {/* <h5 className="text-sm text-sub text-center font-semibold">{ctx.get('sectionData.section.shortText')}</h5> */}
          <CustomTitle className="text-center">
            {ctx.apply(
              'ctf.renderHTMLText',
              { name: ctx.get('sectionData.section.name', '') },
              'longText.longText',
              null
            )}
          </CustomTitle>
        </div>
        <ScrollX className="relative flex pb-3 overflow-x-scroll lg:overflow-x-hidden">
          <Row
            gutter={{
              xs: 16,
              sm: 16,
              md: 24,
              lg: 30,
              xl: 41,
            }}
            wrap={false}
          >
            {ctx.get('sectionData.children', []).map((step) => {
              return (
                <Col key={step.id} className="mb-3 gutter-row sm:mb-0" lg={8} xs={12} md={8}>
                  <div className="h-full px-4 py-6 text-center border border-divider rounded-xl">
                    <h4 className="mb-4 text-xl font-semibold text-center text-brandb500">
                      {_.get(step, 'shortText')}
                    </h4>
                    <div className="mb-4">
                      <Avatar
                        shape="square"
                        size={{
                          xs: 160,
                          sm: 160,
                          md: 80,
                          lg: 64,
                          xl: 104,
                          xxl: 120,
                        }}
                        src={ctx.apply('ctf.findImage', step, 'image', '', { width: 620 })}
                        alt={_.get(step, 'name', '')}
                      />
                    </div>
                    <CustomStepName>
                      {ctx.apply('ctf.renderHTMLText', { name: step.name }, 'longText.longText', {
                        className: 'font-semibold text-base mb-2',
                        as: 'p',
                      })}
                    </CustomStepName>

                    <CustomParagraph>
                      {ctx.apply('ctf.renderHTMLText', { name: step.name }, 'detailText.detailText', {
                        className: 'text-center font-normal text-sm text-ellipsis-4',
                        as: 'p',
                      })}
                    </CustomParagraph>
                  </div>
                </Col>
              );
            })}
          </Row>
        </ScrollX>
        <div className="hidden md:block mx-8 pt-3 lg:hidden border-b border-divider"></div>
      </div>
    </DIV>
  );
};

export default View10;
