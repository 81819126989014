import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import { Spin } from 'antd';
import { InputText } from '@uz/unitz-components-web/InputText';
import styled from 'styled-components';
import { Form } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import AdvisorSearchItem from '@uz/unitz-components-web/AdvisorSearchItem';
import FlatList from '@uz/unitz-components-web/FlatList';

const CustomInput = styled.div`
  .ant-input-affix-wrapper {
    border: 1px solid ${gstyles.colors.black100};
    border-radius: 8px;
    background: transparent;
    .ant-input-suffix {
      margin-left: 10px;
    }
    .ant-input {
      background: transparent;
      height: auto;
      border-right: 1px solid ${gstyles.colors.black100};
      &::-webkit-input-placeholder {
        color: ${gstyles.colors.sub};
      }

      &:-ms-input-placeholder {
        color: ${gstyles.colors.sub};
      }

      &::placeholder {
        color: ${gstyles.colors.sub};
      }
      &:hover {
        border-color: none;
      }
      &:focus {
        border-color: none;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
`;
const CustomList = styled.div`
  .advisor-list {
    .advisor-item {
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
`;

const renderItem = ({ item, index }) => {
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('@item', item);
        ctx.set('@index', index);
      })}
      <div className="advisor-item hover:bg-brandb-lightest rounded-lg border-b border-divider">
        <AdvisorSearchItem />
      </div>
    </DIV>
  );
};

const View8 = () => {
  return (
    <DIV className="SearchButton">
      <div className="relative">
        <FormProvider form={ctx.get('searchForm')}>
          <Form>
            <div className="flex justify-center items-center">
              <CustomInput>
                <InputText
                  name="textSearch"
                  suffix={gstyles.icons({ name: 'search', size: 20, fill: gstyles.colors.sub })}
                  autoComplete="off"
                  onChange={ctx.get('searchForm.handleChange')}
                  placeholder={ctx.apply('i18n.t', 'Selection.titleSearchBar')}
                />
              </CustomInput>
            </div>
          </Form>
        </FormProvider>

        {ctx.get('listSuggestion.hasData') && (
          <CustomList>
            <div
              style={{ zIndex: 500 }}
              className="advisor-list absolute rounded-lg animation-opacity shadow-lg bg-white500 w-full transform translate-y-1 l-0"
            >
              <FlatList
                data={ctx.get('listSuggestion.items', [])}
                renderItem={renderItem}
                keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
                horizontal
              />
            </div>
          </CustomList>
        )}

        {!ctx.get('listSuggestion.hasData') && ctx.get('searchForm.values.textSearch') !== '' && (
          <div
            style={{ zIndex: 500 }}
            className="flex justify-center py-4 items-center absolute rounded-lg animation-opacity shadow-lg bg-white500 w-full transform translate-y-1 l-0"
          >
            <Spin />
          </div>
        )}
      </div>
    </DIV>
  );
};

export default displayName(View8);
