import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';
import gstyles from '@vl/gstyles';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';

const View8 = () => {
  return (
    <DIV className="component 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">
      <div className="mx-4 sm:mx-20 lg:mx-0">
        <div className={ctx.get('sectionData.section.className')}>
          {_.map(ctx.get('sectionData.children', []), (item, index) => (
            <React.Fragment key={index}>
              <div className={_.get(item, 'className', '')}>
                <LinkPlayer to={_.get(item, 'linkHref', '')}>
                  <div className="relative">
                    <Image
                      size="cover"
                      srcParams={{ w: 600 }}
                      src={ctx.apply('ctf.findImage', item, 'image', '')}
                      alt={_.get(item, 'id')}
                    />
                    <div className="absolute flex justify-center items-center bg-black300 hover:opacity-90 h-12 w-12 rounded-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      {gstyles.icons({ name: 'play', size: 24, fill: gstyles.colors.white500 })}
                    </div>
                  </div>
                </LinkPlayer>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </DIV>
  );
};
export default displayName(View8);
