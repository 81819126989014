import React from 'react';
import _ from 'lodash';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col, Divider } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import CourseRCMRequestModal from '@uz/unitz-components-web/CourseRCM/Components/CourseRCMRequestModal';
import gstyles from '@vl/gstyles';

const CourseRCMButton = () => {
  return (
    <DIV className="component">
      <ModalProvider>
        <DIV>
          <div className="flex flex-col items-center max-lg:hidden">
            {ctx.debug(() => {
              ctx.apply('REF.setRef', 'RCMModalModel', ctx.get('modalModel'));
            })}
            <div className="flex flex-col items-center">
              <Row align="middle">
                <Button
                  name="primary"
                  type="primary"
                  block
                  size="large"
                  onClick={() => {
                    ctx.apply('modalModel.show', () => {
                      return <CourseRCMRequestModal />;
                    });
                  }}
                >
                  {ctx.apply('i18n.t', 'Course.RCM.buttonTitle')}
                </Button>
              </Row>
            </div>
          </div>
          <div className="flex flex-col items-center min-lg:hidden ">
            <div className="flex flex-col items-center ">
              <Row align="middle">
                <Button
                  name="primary"
                  type="primary"
                  block
                  // icon={gstyles.icons({ name: 'menu-book', size: 20, fill: gstyles.colors.sub, className: 'inline-block' })}
                  size="large"
                  onClick={() => {
                    ctx.apply('modalModel.show', () => {
                      return <CourseRCMRequestModal />;
                    });
                  }}
                >
                  <span className="mx-1">{ctx.apply('i18n.t', 'Course.RCM.buttonTitleShort')}</span>
                </Button>
              </Row>
            </div>
          </div>
        </DIV>
      </ModalProvider>
    </DIV>
  );
};

export default CourseRCMButton;
