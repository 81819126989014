import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const CustomText = styled.div`
  p,
  span {
    color: ${gstyles.colors.white500};
    font-size: 32px;
    font-weight: 600;
    @media only screen and (max-width: 991px) {
      font-size: 24px;
      font-weight: 400;
    }
    @media only screen and (max-width: 576px) {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const UnitzBizMessageLayout = () => {
  return (
    <DIV className="UnitzBizMessageLayout">
      {ctx.apply('ctf.renderProps', () => (
        <ResponsiveProps
          xs={{
            renderer: () => (
              <div className="wrapper app-row text-center ">
                <div className={`${ctx.get('sectionData.className')} rounded-lg px-3`}>
                  <div className="flex flex-col">
                    {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                      return (
                        <CustomText key={index}>
                          {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                        </CustomText>
                      );
                    })}
                  </div>
                </div>
              </div>
            ),
          }}
          lg={{
            renderer: () => (
              <div className={ctx.get('sectionData.className')}>
                <div className="wrapper app-row text-center">
                  <div className="flex flex-col">
                    {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                      return (
                        <CustomText key={index}>
                          {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                        </CustomText>
                      );
                    })}
                  </div>
                </div>
              </div>
            ),
          }}
        >
          {(resProps) => !!resProps && resProps.renderer()}
        </ResponsiveProps>
      ))}
    </DIV>
  );
};

export default UnitzBizMessageLayout;
