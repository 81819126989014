import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';

const VideoGridLayout = () => {
  return (
    <DIV className="VideoGridLayout">
      <div className="mx-4 sm:mx-20 lg:mx-0">
        {ctx.apply('ctf.renderProps', () => {
          return (
            <div className={ctx.get('sectionData.className', '')}>
              {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                return (
                  <React.Fragment key={index}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    </DIV>
  );
};

export default VideoGridLayout;
