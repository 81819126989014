import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import { Overlay } from '@uz/unitz-components-web/Overlay';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const CustomText = styled.div`
  p > span {
    margin: 0;
  }
`;

const HeroAdvisorSupportCenterLayout = () => {
  return (
    <DIV className="HeroAdvisorSupportCenterContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="relative content">
            <Overlay opacity={30} />
            <div className="absolute z-50 w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="wrapper app-row flex justify-center items-center">
                <div className="text-center">
                  <h1 className="text-2xl lg:text-5xl font-bold mb-0 lg:mb-2 tracking-wider text-white500">
                    {ctx.get('sectionData.shortText')}
                  </h1>
                  <div className="mb-0 sm:mb-8">
                    <CustomText>
                      <div className="text-base lg:text-xl font-normal mb-0 tracking-wider text-white500">
                        {ctx.apply('ctf.renderRichText', { name: ctx.get('sectionData.name') }, 'richText', null)}
                      </div>
                    </CustomText>
                  </div>
                </div>
              </div>
            </div>
            <ResponsiveProps
              md={{ ratio: 0.5, width: 768, height: 768 * 0.5 }}
              xs={{ ratio: 0.6, width: 420, height: 420 * 0.6 }}
              lg={() => ({ ratio: 0.4, width: 1200, height: 1200 * 0.4 })}
            >
              {(resProps) => (
                <Image
                  size="cover"
                  src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                  className="w-full hero-image"
                  alt="banner"
                  // background
                  {...resProps}
                />
              )}
            </ResponsiveProps>
          </div>
        );
      })}
    </DIV>
  );
};

export default HeroAdvisorSupportCenterLayout;
