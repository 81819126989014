const { GraphQLClient } = require('graphql-request');
const _ = require('lodash');
require('@vl/mod-config');

const options = {
  // endpoint: modConfig.get('CONTENTFUL_GRAPHQL_ENDPOINT'),
  endpoint: 'https://graphql.contentful.com/content/v1/spaces/49vqjgy9zjzd/environments/master',
  // adminSecret: modConfig.get('CONTENTFUL_GRAPHQL_ADMIN_SECRET'),
  adminSecret: 'wpUQdLVz_AaZnZJqUf4Khft63PL0P08aDkSSEn3xquM',
  debug: false,
};

exports.getClient = _.memoize((endpoint = options.endpoint, opts = {}) => {
  const adminSecret = _.get(options, 'adminSecret');

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Bearer ${adminSecret}`,
    },
  });
  // check for debug mode
  if (_.get(options, 'debug', true)) {
    return new Proxy(client, {
      get(obj, prop) {
        if (prop === 'request') {
          return function (...args) {
            console.log('request with args:', ...args);
            return obj.request(...args);
          };
        }
        // if(prop === 'subscribe') {
        //   return function(...args) {
        //     const [query] = args;
        //     console.log('susbcribe with query:', query);
        //     return hasuraSubs.getClient().request({query});
        //   }
        // }
      },
    });
  }
  return client;
});
