import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomCarousel = styled.div`
  .slick-slider {
    .slick-slide {
      padding: 0 15px;
    }
    .slick-list {
      margin-bottom: 24px;
    }
    .slick-dots {
      bottom: -65px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.background2};
          border-radius: 50%;
          width: 8px;
          height: 8px;
          &:before {
            content: '';
          }
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div
        className="absolute rounded-full filter drop-shadow-md z-50 cursor-pointer ml-20 left-2/4 transform translate-x-2/4 -bottom-20 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-right', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};

const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div
        className="absolute bg-white500 rounded-full flex items-center -ml-24 justify-center w-10 h-10 filter drop-shadow-md z-50 cursor-pointer left-2/4 transform -translate-x-full -bottom-20 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-left', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};

const NewsSliderSectionLayout = () => {
  return (
    <DIV className="NewsSliderSectionLayout">
      {ctx.apply('ctf.renderProps', () => {
        const settings = ctx.get('carouselOptions');
        return (
          <div className={cx('relative content', ctx.get('sectionData.className'))}>
            <div className="wrapper app-row">
              {ctx.get('sectionData.sections.length') > 3 ? (
                <CustomCarousel>
                  <h2 className="mb-0 px-4 text-2xl min-md:text-3xl min-lg:text-4xl font-semibold text-center text-main">
                    {ctx.get('sectionData.shortText')}
                  </h2>
                  <div className="py-8 lg:py-14">
                    <div className="lg:wrapper mb-6 app-row px-0 py-8">
                      <Slider
                        {...settings}
                        {...{
                          nextArrow: <NextButton onClick />,
                          prevArrow: <PrevButton onClick />,
                        }}
                      >
                        {_.map(
                          _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []),
                          (item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                              </React.Fragment>
                            );
                          }
                        )}
                      </Slider>
                    </div>
                  </div>
                </CustomCarousel>
              ) : (
                <>
                  <h2 className="mb-4 px-4 text-2xl min-md:text-3xl min-lg:text-4xl font-semibold text-center text-main">
                    {ctx.get('sectionData.shortText')}
                  </h2>
                  <Row gutter={[{ xs: 0, md: 24, lg: 48 }, 24]}>
                    {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                      return (
                        <Col key={`item_${index}`} xs={24} sm={12} lg={8}>
                          {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default NewsSliderSectionLayout;
