import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            menuData: hook((ctx) => {
              return _.compact([
                {
                  linkHref: '/dashboard',
                  id: 'dashboard',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.Home'),
                  icon: 'home',
                  show: true,
                },
                {
                  linkHref: '/course-widget',
                  id: 'home',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.MyCourse'),
                  icon: 'category',
                  show: true,
                },
                {
                  linkHref: '/profile',
                  id: 'profile',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.Account'),
                  icon: 'account',
                  show: true,
                },
                {
                  linkHref: '/message',
                  id: 'message',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.Message'),
                  icon: 'message',
                  show: true,
                },
                {
                  linkHref: '/calendar',
                  id: 'calendar',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.TeachingSchedule'),
                  icon: 'calendar',
                  show: true,
                },
                // {
                //   linkHref: '/working-time',
                //   id: 'working-time',
                //   shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.SettingWorkingTime'),
                //   icon: 'dashboard',
                //   show: true,
                // },
                {
                  linkHref: '/course',
                  id: 'course',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.CourseManagement'),
                  icon: 'menuBook',
                  show: true,
                },
                !ctx.apply('routeStore.isUnitzVN')
                  ? {
                      linkHref: '/wallet',
                      id: 'wallet',
                      shortText: ctx.apply('i18n.t', 'AdvisorWallet.myWallet'),
                      icon: 'wallet',
                      show: true,
                    }
                  : null,
                {
                  linkHref: '/bank',
                  id: 'bank',
                  shortText: ctx.apply('i18n.t', 'Bank.bankListTxt'),
                  icon: 'bank-cards',
                  show: true,
                },
                {
                  linkHref: '/income',
                  id: 'income',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.MyIncome'),
                  icon: 'monetizationOn',
                  show: true,
                },
                {
                  linkHref: '/setting',
                  id: 'setting',
                  shortText: ctx.apply('i18n.t', 'AdvisorProfileMenu.Setting'),
                  icon: 'setting',
                  show: true,
                },
              ]);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
