import React from 'react';
import { Helmet } from 'react-helmet';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';

export const DataContext = React.createContext();

export const provideData = (dataKey, value) => {
  const dataContext = React.useContext(DataContext);
  _.set(dataContext, dataKey, value);
};

export const consumeData = (dataKey) => {
  const dataContext = React.useContext(DataContext);
  return _.get(dataContext, dataKey);
};

const getPageContext = () => {
  let pageContext;
  if (typeof window !== 'undefined') {
    pageContext = _.get(window, 'pageContext');
  }
  pageContext = pageContext || {};
  return pageContext;
};

const withPageContext = (Comp) => {
  return (props) => {
    const pageContext = _.get(props, 'pageContext');
    _.assign(pageContext, getPageContext());
    // eslint-disable-next-line
    const route = useRoute();
    route.setPageContext(pageContext);
    return (
      <DataContext.Provider value={{}}>
        <DIV>
          {ctx.debug(() => {
            ctx.set('pageContext', pageContext);
          })}
          <Helmet>
            <link rel="stylesheet" type="text/css" href="/seo.css" />
          </Helmet>
          <Comp {...props} />
        </DIV>
      </DataContext.Provider>
    );
  };
};

export default withPageContext;
