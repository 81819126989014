import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

const bindData = bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              // eslint-disable-next-line
              const itemData = React.useMemo(() => ctx.get('@item'), []);
              return {
                ...itemData,
                profile: {
                  id: _.get(itemData, 'id'),
                  displayName: CourseFormatter.displayName(ctx)(itemData) || '',
                  avatarUrl: CourseFormatter.avatarUrl(ctx)(itemData),
                },
                startAt: CourseFormatter.startAt(ctx)(itemData),
                sessionsTitle: ctx.apply('i18n.t', 'ProfileUser.studySchedule'),
                session_duration: CourseFormatter.duration(ctx)(itemData),
                price: {
                  amountText: CourseFormatter.priceAmount(ctx)(itemData),
                  unitText: CourseFormatter.priceUnitText(ctx)(itemData),
                },
                statusText: CourseFormatter.statusText(ctx)(itemData),
                advisorProfile: _.get(itemData, 'advisor.profile'),
                nextCourseRoom: _.first(_.get(itemData, 'rooms')),
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
