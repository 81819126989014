import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import { Input, Spin } from 'antd';
import styled from 'styled-components';
import { Form } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import AdvisorSearchItem from '@uz/unitz-components-web/AdvisorSearchItem';
import FlatList from '@uz/unitz-components-web/FlatList';

const { Search } = Input;
const CustomInput = styled.div`
  .ant-input-group-wrapper {
    .ant-input-wrapper {
      .ant-input-suffix {
        order: 1;
      }
      .ant-input-affix-wrapper {
        padding: 0 10px;
        &:not(:last-child) {
          border: none;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:not(.ant-input-affix-wrapper-disabled):hover {
          border: none;
        }
        &.ant-input-affix-wrapper-focused {
          border: none !important;
          outline: none;
        }
        &:focus {
          border: none;
          border-right-width: 0;
          outline: none;
          box-shadow: none;
        }
        .ant-input {
          min-height: 40px;
          @media only screen and (min-width: 320px) {
            min-width: 220px;
          }
          @media only screen and (min-width: 768px) {
            min-width: 600px;
          }
          @media only screen and (min-width: 992px) {
            min-width: 200px;
          }
          order: 2;
          padding: 0 10px;
          &:hover {
            border: 0 !important;
          }
          &:focus {
            border: 0 !important;
            box-shadow: none;
          }
          &::-webkit-input-placeholder {
            color: ${gstyles.colors.sub};
          }
          &:-ms-input-placeholder {
            color: ${gstyles.colors.sub};
          }
          &::placeholder {
            color: ${gstyles.colors.sub};
          }
        }
      }
      .ant-input-group-addon {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        .ant-btn {
          box-shadow: none;
          outline: none;
          height: 32px;
          border-right: none;
          border-top: none;
          border-bottom: none;
          border-left: 1px solid #d9e1e8;
          width: 40px;
          margin-left: 1px;
          span {
            font-size: 14px;
            line-height: 30px;
            color: ${gstyles.colors.sub};
          }
        }
      }
    }
  }
`;
const CustomList = styled.div`
  .advisor-list {
    .advisor-item {
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
`;

const renderItem = ({ item, index }) => {
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('@item', item);
        ctx.set('@index', index);
      })}
      <div className="advisor-item hover:bg-brandb-lightest rounded-lg border-b border-divider">
        <AdvisorSearchItem />
      </div>
    </DIV>
  );
};

const View8 = ({ handleToggle }) => {
  return (
    <DIV className="SearchButton">
      <div className="relative">
        <FormProvider form={ctx.get('searchForm')}>
          <Form>
            <CustomInput>
              <Search
                name="textSearch"
                autoFocus
                autoComplete="off"
                onChange={ctx.get('searchForm.handleChange')}
                suffix={
                  <span className="cursor-pointer" onClick={handleToggle}>
                    {gstyles.icons({ name: 'close', size: 20, fill: gstyles.colors.sub })}
                  </span>
                }
                onSearch={ctx.get('searchForm.handleSubmit')}
                placeholder={ctx.apply('i18n.t', 'Selection.titleSearchBar')}
              />
            </CustomInput>
          </Form>
        </FormProvider>

        {!!(ctx.get('listSuggestion.hasData') && ctx.get('listSuggestion.show')) && (
          <CustomList>
            <div
              style={{ zIndex: 500 }}
              className="advisor-list absolute rounded-lg animation-opacity shadow-lg bg-white500 w-full transform translate-y-1 l-0"
            >
              <FlatList
                data={ctx.get('listSuggestion.items', [])}
                renderItem={renderItem}
                keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
                horizontal
              />
            </div>
          </CustomList>
        )}

        {!!(
          !ctx.get('listSuggestion.hasData') &&
          ctx.get('listSuggestion.show') &&
          ctx.get('searchForm.values.textSearch') !== ''
        ) && (
          <div
            style={{ zIndex: 500 }}
            className="flex justify-center py-4 items-center absolute rounded-lg animation-opacity shadow-lg bg-white500 w-full transform translate-y-1 l-0"
          >
            <Spin />
          </div>
        )}
      </div>
    </DIV>
  );
};

export default displayName(View8);
