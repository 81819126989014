import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import CategoryNewListX from '@uz/unitz-components-web/CategoryNewListX';

function BusinessCategories() {
  return (
    <DIV className="component">
      <div className="wrapper app-row sm:px-4 xl:mx-auto sm:w-full py-6 lg:py-16">
        {ctx.apply('ctf.renderProps', () => (
          <h2 className="font-semibold text-main text-xl sm:text-2xl lg:text-4xl text-center mb-8 sm:mb-10">
            {ctx.get('sectionData.shortText')}
          </h2>
        ))}
        {ctx.debug(() => {
          const categories = ctx.get('categories', []);
          ctx.set('ctfCategories', categories);
        })}

        <CategoryNewListX />
      </div>
    </DIV>
  );
}

export default BusinessCategories;
