import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Row, Col, Space, Rate } from 'antd';
import RESABLE from '@vl/redata/RESABLE.macro';
import { resable } from '@vl/redata/RESABLE';
import { Link } from '@uz/unitz-components-web/Link';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { Button } from '@uz/unitz-components-web/Button';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import cx from 'classnames';

const CustomStar = styled.div`
  .ant-rate {
    font-size: 16px;
    margin-bottom: 2px;
    color: ${gstyles.colors.yellow.DEFAULT};
  }
`;

const Mask = styled.div`
  mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0));
  -webkit-mask-image: linear-gradient(#fff, #fff, rgba(255, 255, 255, 0));
`;

export const View8 = () => {
  return (
    <DIV className="listItem">
      <div className="group sm:hover:bg-brandb-lightest">
        <div className="border-t-0 wrapper sm:px-4 xl:px-0 sm:w-full sm:py-0 xl:border-t">
          <Row className="py-0 sm:py-3">
            <Col xs={24} lg={24} xl={20}>
              <Link
                {...ctx.apply('tracking.dataSet', {
                  action: 'click',
                  category: `${ctx.get('dataPage')}_card_advisor`,
                  value: ctx.get('@item.id'),
                  label: ctx.get('@item.profile.displayName'),
                })}
                to={ctx.apply('routeStore.toUrl', 'advisor', ctx.get('@item'))}
                external
              >
                <Row gutter={{ xs: 24, lg: 18 }}>
                  <Col xs={6} lg={6} xl={5}>
                    <div className="relative px-0 py-3 sm:flex md:justify-center md:py-3 sm:px-3 md:items-center">
                      <RESABLE>
                        {!!resable.for('avatarUrl')(ctx.get('@item.profile.avatarUrl')) && (
                          <Avatar
                            className="object-cover rounded-full"
                            size={{
                              xs: 68,
                              sm: 120,
                              md: 136,
                              lg: 168,
                              xl: 144,
                              xxl: 144,
                            }}
                            alt={ctx.get('@item.profile.displayName')}
                            src={resable.for('avatarUrl')(ctx.get('@item.profile.avatarUrl'), '')}
                          />
                        )}
                      </RESABLE>
                      <div
                        className={cx(
                          'absolute w-4 sm:w-6 lg:w-8 xl:w-6 h-4 sm:h-6 lg:h-8 xl:h-6 bottom-4 sm:bottom-4 lg:bottom-6 xl:bottom-5 right-0 sm:right-8 md:right-9 lg:right-10 xl:right-8 border-2 sm:border-4 border-white500 rounded-full',
                          ctx.get('status.dotStyle')
                        )}
                      ></div>
                    </div>
                  </Col>
                  <Col xs={18} lg={18} xl={14}>
                    <div className="py-3">
                      <div className="flex flex-wrap items-center justify-between sm:justify-start sm:flex-nowrap">
                        <h3 className="mb-1 mr-0 text-xl font-semibold sm:mr-4 sm:text-2xl text-main">
                          {ctx.get('@item.profile.displayName')}
                        </h3>
                        <div className={cx('text-left rounded-sm mb-1 px-2', ctx.get('status.bgStyle'))}>
                          <div className={cx('mb-0 text-sm', ctx.get('status.textStyle'))}>
                            {ctx.get('status.name')}
                          </div>
                        </div>
                      </div>

                      <ResponsiveProps
                        xs={{ className: 'font-normal text-main text-sm text-ellipsis-2' }}
                        md={{ className: 'font-semibold text-main text-base text-ellipsis-1' }}
                        lg={{ className: 'font-semibold text-main text-base text-ellipsis-1' }}
                      >
                        {(resProps) => (
                          <h5 className="mb-2" {...resProps}>
                            {ctx.get('@item.profile.introText')}
                          </h5>
                        )}
                      </ResponsiveProps>
                      <div className="hidden sm:block">
                        <Mask>
                          <ResponsiveProps
                            lg={{ className: 'text-base font-semibold text-ellipsis-3 text-main' }}
                            md={{
                              className: 'font-semibold text-main text-base text-ellipsis-2',
                            }}
                          >
                            {(resProps) => (
                              <div className="mb-0" {...resProps}>
                                {ctx.get('@item.profile.description')}
                              </div>
                            )}
                          </ResponsiveProps>
                        </Mask>
                      </div>
                      <Row className="mt-3 sm:flex xl:hidden" gutter={[10, 14]}>
                        <Col xs={0} md={12} lg={12} xl={0}>
                          <Space className="mb-2" size="small">
                            <div className="mb-0 text-main text-base font-semibold xl:font-normal">
                              <RESABLE>{resable(ctx.get('@item.rating.ratingTxt'), '')}</RESABLE>
                            </div>
                            <CustomStar>
                              <Rate allowHalf value={ctx.get('@item.rating.ratingScore')} disabled />
                            </CustomStar>
                          </Space>
                          <div className="mb-0 font-semibold tracking-widest uppercase text-xs text-sub">
                            <RESABLE>{resable(ctx.get('@item.reviewAggregate'), '')}</RESABLE>{' '}
                            <span className="font-semibold">{ctx.apply('i18n.t', 'Advisor.voteCount')}</span>
                          </div>
                        </Col>
                        <Col xs={0} md={12} lg={12} xl={0}>
                          <div>
                            <div className="relative w-2/3 px-4 py-1 bg-branda triangle arrow-right arrow-in arrow-w-xs arrow-h-xs arrow-color-white500">
                              <div className="mb-0 text-xs text-white">
                                {ctx.apply('i18n.t', 'Advisor.listY_highlightAdvisors')}
                              </div>
                            </div>
                            <Space className="mt-1 mb-3" size="small">
                              <h4 className="mb-0 text-base font-normal sm:text-xl sm:font-semibold text-main">
                                {ctx.get('@item.price.pricePerMinTxt')}
                              </h4>
                              <span className="text-xs tracking-wider text-main">
                                {ctx.get('@item.price.pricePerMinTitle')}
                              </span>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                      <Row className="sm:flex xl:hidden" gutter={10}>
                        <Col className="mb-2" xs={0} md={12} lg={12} xl={0}>
                          <Link
                            {...ctx.apply('tracking.dataSet', {
                              action: 'click',
                              category: `${ctx.get('dataPage')}_video-button_advisor`,
                              value: ctx.get('@item.id'),
                              label: ctx.get('@item.profile.displayName'),
                            })}
                            to={ctx.apply('routeStore.toUrl', 'videocall', ctx.get('@item'))}
                            external
                          >
                            <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }}>
                              {(resProps) => (
                                <Button
                                  type="primary"
                                  block
                                  icon={gstyles.icons({
                                    name: 'videocam',
                                    size: 24,
                                    fill: gstyles.colors.white,
                                    className: 'inline-block mr-2',
                                  })}
                                  disabled={ctx.get('@item.status.isOffline') || ctx.get('@item.status.isBusy')}
                                  onClick={ctx.get('form.onSubmit')}
                                  {...resProps}
                                >
                                  {ctx.apply('i18n.t', 'Advisor.callVideoText')}
                                </Button>
                              )}
                            </ResponsiveProps>
                          </Link>
                        </Col>

                        <Col className="mb-2" xs={0} md={12} lg={12} xl={0}>
                          <Link
                            {...ctx.apply('tracking.dataSet', {
                              action: 'click',
                              category: `${ctx.get('dataPage')}_call-button_advisor`,
                              value: ctx.get('@item.id'),
                              label: ctx.get('@item.profile.displayName'),
                            })}
                            to={ctx.apply('routeStore.toUrl', 'voicecall', ctx.get('@item'))}
                            external
                          >
                            <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }}>
                              {(resProps) => (
                                <Button
                                  focus="true"
                                  icon={gstyles.icons({
                                    name: 'phone-call',
                                    size: 24,
                                    fill: gstyles.colors.brandb500,
                                    className: 'inline-block mr-2',
                                  })}
                                  disabled={ctx.get('@item.status.isOffline') || ctx.get('@item.status.isBusy')}
                                  block
                                  onClick={ctx.get('form.onSubmit')}
                                  {...resProps}
                                >
                                  {ctx.apply('i18n.t', 'Advisor.callText')}
                                </Button>
                              )}
                            </ResponsiveProps>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} md={0} lg={0} xl={5}>
                    <Row className="flex sm:hidden md:hidden xl:flex" gutter={[10, 14]}>
                      <Col xs={12} xl={24}>
                        <Space size="small">
                          <RESABLE>
                            <div className="mb-0 text-base text-main font-semibold">
                              {resable(ctx.get('@item.rating.ratingTxt'), '')}
                            </div>
                          </RESABLE>
                          <CustomStar>
                            <Rate allowHalf value={ctx.get('@item.rating.ratingScore')} disabled />
                          </CustomStar>
                        </Space>
                        <div className="mb-0 tracking-widest font-normal md:font-semibold uppercase text-xxs text-sub">
                          <RESABLE>{resable(ctx.get('@item.reviewAggregate'), '')}</RESABLE>{' '}
                          {ctx.apply('i18n.t', 'Advisor.voteCount')}
                        </div>
                      </Col>
                      <Col xs={0} xl={24}>
                        <div className="hidden lg:block">
                          <div className="mb-0 text-main text-base font-semibold">
                            <RESABLE>{resable(ctx.get('@item.profile.transactionAggregate'), '')}</RESABLE>
                          </div>
                          <div className="mb-0 text-main tracking-widest font-semibold uppercase text-xxs text-sub">
                            {ctx.apply('i18n.t', 'Advisor.adviseCount')}
                          </div>
                        </div>
                      </Col>
                      <Col xs={0} xl={24}>
                        <div className="hidden pr-1 lg:block">
                          <div className="mb-0 text-main text-base font-semibold">
                            <RESABLE>{resable(ctx.get('@item.profile.yearsExperience'))}</RESABLE>
                          </div>
                          <div className="mb-0 font-semibold tracking-widest uppercase text-xxs text-sub">
                            {ctx.apply('i18n.t', 'Advisor.yearsExperience')}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} xl={24}>
                        <div className="block lg:hidden">
                          <div className="relative px-2 py-1 bg-branda triangle arrow-right arrow-in arrow-w-xs arrow-h-xs arrow-color-white500">
                            <div className="mb-0 text-xs text-white">
                              {ctx.apply('i18n.t', 'Advisor.listY_highlightAdvisors')}
                            </div>
                          </div>
                          <Space className="mt-1 mb-3" size="small">
                            <h4 className="mb-0 text-base font-semibold sm:text-xl text-main">
                              {ctx.get('@item.price.pricePerMinTxt')}
                            </h4>
                            <span className="text-xs sm:font-semibold tracking-wider text-main">
                              {ctx.get('@item.price.pricePerMinTitle')}
                            </span>
                          </Space>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Link>
            </Col>
            <Col xs={24} md={0} lg={0} xl={4}>
              <div className="hidden lg:block">
                <div className="relative px-2 py-1 bg-branda triangle arrow-right arrow-in arrow-w-xs arrow-h-xs arrow-color-white500">
                  <div className="mb-0 text-xs text-white">
                    {ctx.apply('i18n.t', 'Advisor.listY_highlightAdvisors')}
                  </div>
                </div>
                <Space className="mt-1 mb-3" size="small">
                  <h4 className="mb-0 text-xl font-semibold text-main">{ctx.get('@item.price.pricePerMinTxt')}</h4>
                  <span className="text-xs font-semibold tracking-wider text-main">
                    {ctx.get('@item.price.pricePerMinTitle')}
                  </span>
                </Space>
              </div>
              <Row gutter={10}>
                <Col className="mb-2" xs={12} lg={24}>
                  <Link
                    {...ctx.apply('tracking.dataSet', {
                      action: 'click',
                      category: `${ctx.get('dataPage')}_video-button_advisor`,
                      value: ctx.get('@item.id'),
                      label: ctx.get('@item.profile.displayName'),
                    })}
                    to={ctx.apply('routeStore.toUrl', 'videocall', ctx.get('@item'))}
                    external
                  >
                    <ResponsiveProps xs={{ size: 'small' }} lg={{ size: 'medium' }}>
                      {(resProps) => (
                        <Button
                          type="primary"
                          block
                          icon={gstyles.icons({
                            name: 'videocam',
                            size: 24,
                            fill: gstyles.colors.white,
                            className: 'inline-block mr-2',
                          })}
                          disabled={ctx.get('@item.status.isOffline') || ctx.get('@item.status.isBusy')}
                          onClick={ctx.get('form.onSubmit')}
                          {...resProps}
                        >
                          {ctx.apply('i18n.t', 'Advisor.callVideoText')}
                        </Button>
                      )}
                    </ResponsiveProps>
                  </Link>
                </Col>

                <Col className="mb-2" xs={12} lg={24}>
                  <Link
                    {...ctx.apply('tracking.dataSet', {
                      action: 'click',
                      category: `${ctx.get('dataPage')}_call-button_advisor`,
                      value: ctx.get('@item.id'),
                      label: ctx.get('@item.profile.displayName'),
                    })}
                    to={ctx.apply('routeStore.toUrl', 'voicecall', ctx.get('@item'))}
                    external
                  >
                    <ResponsiveProps xs={{ size: 'small' }} lg={{ size: 'medium' }}>
                      {(resProps) => (
                        <Button
                          focus="true"
                          icon={gstyles.icons({
                            name: 'phone-call',
                            size: 24,
                            fill: gstyles.colors.brandb500,
                            className: 'inline-block mr-2',
                          })}
                          // disabled={ctx.get('@item.status.isOffline') || ctx.get('@item.status.isBusy')}
                          disabled={true}
                          block
                          onClick={ctx.get('form.onSubmit')}
                          {...resProps}
                        >
                          {ctx.apply('i18n.t', 'Advisor.callText')}
                        </Button>
                      )}
                    </ResponsiveProps>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="block border-b md:mx-8 xl:hidden border-divider"></div>
    </DIV>
  );
};

export default displayName(View8);
