import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const ForSchoolBenefitsLayout = () => {
  return (
    <DIV className="ForSchoolBenefitsLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <section className="py-12 wrapper app-row benefit-section">
            <div className="flex flex-col mb-12 justify-center items-center">
              <div className="text-3xl text-main text-center font-semibold">{ctx.get('sectionData.shortText')}</div>
            </div>

            <Row justify="center" align="middle" gutter={[24, 24]}>
              {_.map(
                _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []),
                (col, index) => (
                  <Col xs={24} md={12} key={`${_.get(col, 'name', '')}_${index}`}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                  </Col>
                )
              )}
            </Row>
          </section>
        );
      })}
    </DIV>
  );
};

export default ForSchoolBenefitsLayout;
