import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const UnitzBizTestimonialsLayout = () => {
  return (
    <DIV className="UnitzBizTestimonialsLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="wrapper app-row">
            {!_.isNull(ctx.get('sectionData.shortText')) && (
              <h1 className="text-center text-2xl lg:text-3xl mb-4 lg:mb-12 text-main font-semibold">
                {ctx.get('sectionData.shortText')}
              </h1>
            )}
            <Row justify="center" align="middle">
              <Col xs={24} md={18}>
                <Row justify="center" align="middle">
                  {_.map(ctx.get('sectionData.sections'), (item, index) => (
                    <Col key={index} span={24}>
                      {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizTestimonialsLayout;
