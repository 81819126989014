import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CFAdvisorProfileModel from '@uz/unitz-models/CFAdvisorProfileModel';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';
import usePromiseSource from '@vl/hooks/usePromiseSource';

const bindData = bindings({
  CourseSearchItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const itemData = ctx.get('@item');
              return itemData;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
