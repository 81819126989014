import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';


const AdvisorRegisterMenuLayout = () => {
  return (
    <DIV className="AdvisorRegisterMenuContent">
      {ctx.apply('ctf.renderProps', () => {
        // const sectionData = ctx.get('sectionData');
        // console.log(sectionData);
        return <div className="py-4 border-b-8"></div>;
      })}
    </DIV>
  );
};

export default AdvisorRegisterMenuLayout;
