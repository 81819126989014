const colors = {
  // branda500: '#EB4781',
  // branda: {
  //   darkest: '#752340',
  //   darker: '#A4325A',
  //   dark: '#C83C6E',
  //   DEFAULT: '#EB4781',
  //   light: '#F391B3',
  //   lighter: '#F9C8D9',
  //   lightest: '#FDEDF2',
  // },
  // brandb500: '#2EB2AA',
  // brandb: {
  //   darkest: '#175955',
  //   darker: '#207D77',
  //   dark: '#279790',
  //   DEFAULT: '#2EB2AA',
  //   light: '#6DD9D2',
  //   lighter: '#BEEEEB',
  //   lightest: '#EFFBFA',
  // },
  // brandb_lighter: '#BEEEEB',
  branda500: '#00C8FF',
  branda: {
    darkest: '#2E778B',
    darker: '#419AB3',
    dark: '#3FB2D1',
    DEFAULT: '#00C8FF',
    light: '#51DAFF',
    lighter: '#7FE3FF',
    lightest: '#C0F1FF',
  },
  brandb500: '#0064FA',
  brandb: {
    darkest: '#305C9D',
    darker: '#3B6EBA',
    dark: '#4080E0',
    DEFAULT: '#0064FA',
    light: '#3B87F9',
    lighter: '#77ADFF',
    lightest: '#CFE2FF',
  },
  brandb_lighter: '#77ADFF',
  red500: '#E11447',
  red: {
    darkest: '#700A23',
    darker: '#9E0E32',
    dark: '#BF113C',
    DEFAULT: '#E11447',
    light: '#F47C9A',
    lighter: '#F8B4C5',
    lightest: '#FDECF1',
  },
  blue500: '#00A3F5',
  blue: {
    darkest: '#00517B',
    darker: '#0072AC',
    dark: '#008BD0',
    DEFAULT: '#00A3F5',
    light: '#70CFFF',
    lighter: '#C2EBFF',
    lightest: '#EBF8FF',
  },
  yellow500: '#F9C74F',
  yellow: {
    darkest: '#7D6328',
    darker: '#AE8B37',
    dark: '#D4A943',
    DEFAULT: '#F9C74F',
    light: '#FBDF9D',
    lighter: '#FDECC4',
    lightest: '#FEF9EB',
  },
  black500: '#000000',
  black400: 'rgba(0, 0, 0, 0.7)',
  black300: 'rgba(0, 0, 0, 0.5)',
  black200: 'rgba(0, 0, 0, 0.3)',
  black100: 'rgba(0, 0, 0, 0.1)',
  black50: 'rgba(0, 0, 0, 0.05)',
  white500: '#ffffff',
  white400: 'rgba(255, 255, 255, 0.7)',
  white300: 'rgba(255, 255, 255, 0.5)',
  white200: 'rgba(255, 255, 255, 0.3)',
  white100: 'rgba(255, 255, 255, 0.1)',
  white50: 'rgba(255, 255, 255, 0.05)',
  background1: '#F2F5F7',
  background2: '#E6EBF0',
  divider: '#D9E1E8',
  border: '#BFCDD9',
  placeHolder: '#99AFC2',
  main: '#263340',
  sub: '#577590',
  success: '#275a53',
  danger: '#e3492b',
  danger2: 'rgb(227, 73, 43)',
  warning: '#ffcc3e',
  primary: '#030047',
  primary2: 'rgb(3, 0, 71)',
  secondary: '#77869e',
  secondary2: 'rgb(214, 216, 221)',
  secondary3: 'rgb(119, 134, 158)',
  bg1: '#faf8f4',
  bg2: '#ecf0f6',
  info: '#28b7ff',
  bg3: '#fffbf3',
  white: '#ffffff',
  black: '#000000',
  green: '#2EB2AA',
  brownGrey: '#9b9b9b',
  powerPeony: '#EB4781',
};

module.exports = colors;
