import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import Image from '@uz/unitz-components-web/Image';
import useGbRoute from '@vl/hooks/useGbRoute';

const interpolateContent = (content, { pageData, route }) => {
  try {
    return _.template(content, {
      interpolate: /{{([\s\S]+?)}}/g,
    })({
      siteTitle: _.get(pageData, 'seoTitle'),
      siteUrl: _.get(route, 'location.href'),
      siteDescription: _.get(pageData, 'seoMetaDescription.seoMetaDescription'),
      siteImage: _.get(
        Image.createImageUrl({
          src: _.get(pageData, 'socialImage.resize.src') || _.get(pageData, 'siteImage'),
          width: 200,
          height: 200,
        }),
        'src'
      ),
      siteType: 'article',
    });
  } catch (err) {
    return content;
  }
};

export const SEO = ({ pageData }) => {
  const metaItems = [
    {
      name: 'title',
      content: _.get(pageData, 'seoTitle'),
    },
    {
      name: 'description',
      content: _.get(pageData, 'seoMetaDescription.seoMetaDescription'),
    },
    /* <!-- Schema.org markup for Google+ --> */
    {
      itemprop: 'name',
      content: '{{siteTitle}}',
    },
    {
      itemprop: 'description',
      content: '{{siteDescription}}',
    },
    {
      itemprop: 'image',
      content: '{{siteImage}}',
    },
    // <!-- Twitter Card data -->
    {
      itemprop: 'twitter:card',
      content: '{{siteType}}',
    },
    {
      itemprop: 'twitter:site',
      content: '',
    },
    {
      itemprop: 'twitter:title',
      content: '{{siteTitle}}',
    },
    {
      itemprop: 'twitter:description',
      content: '{{siteDescription}}',
    },
    {
      itemprop: 'twitter:creator',
      content: '',
    },
    {
      itemprop: 'twitter:image',
      content: '{{siteImage}}',
    },
    {
      itemprop: 'twitter:data1',
      content: '',
    },
    {
      itemprop: 'twitter:label1',
      content: '',
    },
    // <!-- Open Graph data -->
    {
      property: 'og:title',
      content: '{{siteTitle}}',
    },
    {
      property: 'og:type',
      content: '{{siteType}}',
    },
    {
      property: 'og:url',
      content: '{{siteUrl}}',
    },
    {
      property: 'og:image',
      content: '{{siteImage}}',
    },
    {
      property: 'og:description',
      content: '{{siteDescription}}',
    },
    {
      property: 'og:site_name',
      content: '',
    },
    {
      property: 'og:price:amount',
      content: '',
    },
    {
      property: 'og:price:currency',
      content: '',
    },
    // <!-- Facebook Card data -->
    {
      property: 'fb:app_id',
      content: '466026994412612',
    },
  ];
  const route = useGbRoute();
  return (
    <Helmet>
      {!_.isNull(_.get(pageData, 'seoTitle')) && <title>{_.get(pageData, 'seoTitle')}</title>}
      {metaItems.map((item, index) => {
        let { content, ...res } = item;
        content = interpolateContent(content, { pageData, route });
        if (!content) return null;
        return <meta key={`${index}`} {...res} content={content} />;
      })}
    </Helmet>
  );
};

export default SEO;
