import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchPage: true }))),
            pageData: hook((ctx) => {
              const route = useRoute();
              const pageParams = _.get(route, 'params');
              const pageData = ctx.apply('ctf.findPage', { name: _.get(pageParams, 'name', '') });
              _.debounce(() => {
                ctx.apply('loadingModel.clearLoading', 'fetchPage');
              }, 1000)();
              return pageData;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
