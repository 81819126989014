import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Row, Col } from 'antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const CustomParagraph = styled.div`
  div > p,
  span {
    font-weight: 400;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
    margin-bottom: 0;
    text-align: center;
    @media only screen and (max-width: 768px) {
      text-align: left;
    }
    color: ${gstyles.colors.main};
  }
`;

export const GoalItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <Row gutter={[24, 24]} justify="center">
          <Col xs={6} sm={6} md={24}>
            <ResponsiveProps xs={{ size: 52 }} sm={{ size: 70 }} md={{ size: 72 }}>
              {(resProps) =>
                gstyles.icons({
                  name: ctx.get('itemData.className') === null ? 'open-in-new' : `${ctx.get('itemData.className')}`,
                  className: 'mx-0 sm:mx-auto',
                  ...resProps,
                })
              }
            </ResponsiveProps>
          </Col>
          <Col xs={18} sm={18} md={24}>
            <h5 className="text-left md:text-center text-brandb500 text-base md:text-2xl font-semibold text-base">
              {ctx.get('itemData.shortText')}
            </h5>
            <CustomParagraph>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name', '') }, 'longText.longText', null)}
            </CustomParagraph>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default GoalItem;
