import { numWords } from './numToWord';
import i18n from 'i18n-js';

const _ = require('lodash');

const ONE_THOUSAND = Math.pow(10, 3);
const ONE_MILLION = Math.pow(10, 6);
const ONE_BILLION = Math.pow(10, 9);
const ONE_HUNDRED_MILLION = Math.pow(10, 7);
export const TWENTY_MILLION = 20 * Math.pow(10, 6);

const wordDigit = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];

const WordMoney = ['triệu', 'nghìn', ''];

const WordCurrency = {
  hundred: 'trăm',
  old: 'lẻ',
  ten: 'mười',
  tenPrefix: 'mươi',
  onePostfix: 'mốt',
  fivePostfix: 'lăm',
};

const readMoneyToWord = (ctx) => (number) => {
  let digit3st = parseInt(number / 100);
  let digit2st = parseInt((number % 100) / 10);
  let digit1st = number % 10;
  let result = [];

  if (digit3st === 0 && digit2st === 0 && digit1st === 0) return '';
  if (digit3st !== 0) {
    result.push(`${wordDigit[digit3st]} ${WordCurrency.hundred}`);
    if (digit2st === 0 && digit1st !== 0) result.push(WordCurrency.old);
  }
  if (digit2st !== 0 && digit2st !== 1) {
    result.push(`${wordDigit[digit2st]} ${WordCurrency.tenPrefix}`);
    if (digit2st === 0 && digit1st !== 0) result.push(WordCurrency.old);
  }
  if (digit2st === 1) result.push(WordCurrency.ten);
  switch (digit1st) {
    case 1:
      if (digit2st !== 0 && digit2st !== 1) {
        result.push(WordCurrency.onePostfix);
      } else {
        result.push(wordDigit[digit1st]);
      }
      break;
    case 5:
      if (digit2st === 0) {
        result.push(wordDigit[digit1st]);
      } else {
        result.push(WordCurrency.fivePostfix);
      }
      break;
    default:
      if (digit1st !== 0) {
        result.push(wordDigit[digit1st]);
      }
      break;
  }
  return result.join(' ');
};

export const showCurrency = (ctx) => (itemData) => {
  const money = normalizeNumber(itemData);

  if (!money) return '';

  const lang = ctx.get('currentUserModel.activeLanguage', 'en');

  if (lang === 'en') return `${_.capitalize(`${numWords(money)}`)} ${ctx.apply('i18n.t', 'Currency.vnd')}`;

  const listCurrency = [1, ONE_THOUSAND, ONE_MILLION];

  let valueOrigin = money;

  const listBaseCurrency = [];

  for (const item of listCurrency.reverse()) {
    const temp = normalizeNumber(Math.floor(valueOrigin / item));
    listBaseCurrency.push(temp);
    valueOrigin -= parseFloat(temp) * item;
  }

  const words = listBaseCurrency.reduce((words, value, index) => {
    if (value) {
      return `${words ? `${words} ` : ''}${readMoneyToWord(ctx)(value)} ${WordMoney[index]}`;
    }
    return `${words ? `${words} ` : ''}${readMoneyToWord(ctx)(value)}`;
  }, '');

  return _.capitalize(`${words}${ctx.apply('i18n.t', 'Currency.vnd')}`);
};

const currencyFormatter = (ctx) => (num) => {
  if (num >= ONE_BILLION) {
    return `${_.round(num / ONE_BILLION, 1)}${ctx.apply('i18n.t', 'currency.billion')}`;
  }
  if (num >= ONE_MILLION) {
    return `${_.round(num / ONE_MILLION, 1)}${ctx.apply('i18n.t', 'currency.million')}`;
  }
  if (num >= ONE_THOUSAND) {
    return `${_.round(num / ONE_THOUSAND, 1)}${ctx.apply('i18n.t', 'currency.thousand')}`;
  }
  return localeFormat(num);
};

export function toCommas(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function secondToMinute(value) {
  return value / 60;
}

export const localeFormat = (value) => {
  return _.round(normalizeNumber(value), 1).toLocaleString();
};

export const roundCurrency = (num) => {
  if (num >= ONE_BILLION) {
    return { value: _.round(num / ONE_BILLION, 1), pow: ONE_BILLION };
  }
  if (num >= ONE_MILLION) {
    return { value: _.round(num / ONE_MILLION, 1), pow: ONE_MILLION };
  }
  if (num >= ONE_THOUSAND) {
    return { value: _.round(num / ONE_THOUSAND, 1), pow: ONE_THOUSAND };
  }
  return { value: _.round(num, 1), pow: 1 };
};

export const padCurrency = (ctx) => (num) => {
  if (num >= ONE_BILLION) {
    return ctx.apply('i18n.t', 'currency.billion');
  }
  if (num >= ONE_MILLION) {
    return ctx.apply('i18n.t', 'currency.million');
  }
  if (num >= ONE_THOUSAND) {
    return ctx.apply('i18n.t', 'currency.thousand');
  }
  return '';
};

export default currencyFormatter;

const ONE_DAY = 86400000;
const ONE_HOUR = 3600000;
const ONE_MINUTES = 60000;
const ONE_SECONDS = 1000;

export const parseMilliseconds = (milliseconds) => {
  return {
    days: Math.trunc(milliseconds / ONE_DAY),
    hours: Math.trunc(milliseconds / ONE_HOUR) % 24,
    minutes: Math.trunc(milliseconds / ONE_MINUTES) % 60,
    seconds: Math.trunc(milliseconds / ONE_SECONDS) % 60,
    milliseconds: Math.trunc(milliseconds) % 1000,
  };
};
const addZero = (value, digits = 2) => {
  return _.padStart(value, digits, '0');
};
export const durationFormatter = (ms, digit = 3) => {
  let timer = normalizeNumber(ms);
  if (timer < 0) timer = 0;
  const time = parseMilliseconds(timer);

  const items = [
    ms > ONE_DAY && addZero(time.days),
    ms > ONE_HOUR && addZero(time.hours),
    addZero(time.minutes),
    digit >= 3 && addZero(time.seconds),
  ];
  return _.compact(items).join(':');
};

const padText = (value, text) => {
  return `${normalizeNumber(value)} ${text}`;
};

export const durationFormatterText = (ctx) => (ms) => {
  if (!ctx) return durationFormatter(ms);

  let timer = normalizeNumber(ms);
  if (timer < 0) timer = 0;
  const time = parseMilliseconds(timer);

  const items = [
    ms > ONE_DAY && padText(time.days, ctx.apply('i18n.t', 'time.day')),
    ms > ONE_HOUR && padText(time.hours, ctx.apply('i18n.t', 'time.hour')),
    padText(time.minutes, ctx.apply('i18n.t', 'time.minute')),
    time.seconds > 0 && padText(time.seconds, ctx.apply('i18n.t', 'time.second')),
  ];
  return _.compact(items).join(' ');
};

const normalizeParams = (currency) => {
  if (!currency) currency = 'vnd';
  if (currency === 'đ') currency = 'vnd';
  return i18n.t(`CurrencyFormat.${currency}`);
};

export const formatVND = (num, currency = 'vnd') => {
  if (_.isObject(num)) {
    const obj = _.clone(num);

    const listKey = _.keys(num);
    for (const key of listKey) {
      _.assign(obj, { [key]: formatVND(obj[key]) });
    }

    return obj;
  }

  return `${normalizeNumber(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${normalizeParams(currency)}`;
};

export const formatComas = (num) => {
  return `${normalizeNumber(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const suggestPrice = (num) => {
  const currNum = normalizeNumber(num);
  if (!currNum || currNum < 0) return [5000, 10000, 20000];

  return _.times(3, (item) => currNum * Math.pow(10, item + 1)).filter((item) => item < TWENTY_MILLION);
};

export const normalizeNumber = (num) => {
  if (!num) return 0;
  let currNum = 0;
  try {
    currNum = _.toNumber(num.toString().replace(/,/g, ''));
  } catch (error) {}

  if (!currNum) return 0;

  return _.round(currNum, 1);
};

export const normalizeCurrency = (currency) => {
  return normalizeParams(currency);
};

export const normalizeCurrencyTemp = (ctx) => (currency) => {
  // if (!currency || currency === 'vnd') return ctx.apply('i18n.t', 'TopUpPayment.currency', 'd');

  // return currency;
  return normalizeParams(currency);
};
