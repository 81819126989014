import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

const View8 = ({ children }) => {
  const ref = React.useRef({});

  // fix 100vh on browser
  React.useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    ref.current.vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${ref.current.vh}px`);

    window.addEventListener(
      'resize',
      _.debounce(() => {
        // We execute the same script as before
        const vh = window.innerHeight * 0.01;
        if (ref.current.vh !== vh) {
          ref.current.vh = vh;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
      }, 500)
    );
  }, []);
  return <DIV className="layoutProvider">{children}</DIV>;
};

export default displayName(View8);
