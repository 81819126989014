import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import cx from 'classnames';

const FlexRowResponsiveLayout = () => {
  return (
    <DIV className="FlexRowResponsiveLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className="mt-2 flex flex-col space-y-2 min-md:space-y-0 min-md:flex-row min-md:items-center space-x-2">
          {_.map(ctx.get('sectionData.sections', []), (item, index) => {
            return (
              <div className={cx(ctx.get('sectionData.className'))} key={index}>
                {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
              </div>
            );
          })}
        </div>
      ))}
    </DIV>
  );
};

export default FlexRowResponsiveLayout;
