import React from 'react';

export const useAsyncLoading = (initState) => {
  const [loading, $loading] = React.useState(initState);
  const loadingWrapper = (fn) => {
    return async () => {
      $loading(true);
      try {
        await fn();
      } catch (err) {
        throw err;
      } finally {
        $loading(false);
      }
    };
  };

  return [loading, $loading, loadingWrapper];
};

export default useAsyncLoading;
