import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const BenefitListWithBorderLayout = () => {
  return (
    <DIV className="BenefitListWithBorderLayout">
      {ctx.apply('ctf.renderProps', () => {
        console.log(ctx.get('sectionData'));
        return (
          <div className="md:px-4 xl:px-0">
            <div className="px-3 py-4 border-0 sm:border-2 rounded-xl">
              {_.map(ctx.get('sectionData.sections'), (item, index) => (
                <React.Fragment key={`item__${index}`}>
                  {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default BenefitListWithBorderLayout;
