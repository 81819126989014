import React from 'react';
import _ from 'lodash';
import DataContext from '../DataContext';
import Context from '../Context';
import { resolveOption, resolveObjectOption } from '../hook';

const findChildEle = (childrenEle, match) => {
  const children = _.castArray(_.get(childrenEle, 'props.children'));
  // match by array index
  let rtn = null;
  if (_.isNumber(match)) {
    rtn = children[match];
  }

  if (_.isString(match)) {
    rtn = _.find(children, (child) => _.split(_.get(child, 'props.className')).includes(match));
  }
  return rtn;
};

const unsetChildEle = (childrenEle, ele) => {
  const children = _.castArray(_.get(childrenEle, 'props.children'));
  let newChildren = _.filter(children, (item) => item !== ele);
  newChildren = newChildren.length ? newChildren : null;
  return React.cloneElement(childrenEle, {}, newChildren);
};

const conformRenderPropsFn = ([val, prop], ctx, childrenEle) => {
  let rtn = resolveOption(val, ctx, childrenEle);
  if (_.isPlainObject(rtn)) {
    const valObj = rtn;
    const { match, mapRenderArgsToContextName = prop, unset } = valObj;
    const ele = findChildEle(childrenEle, match);
    // eslint-disable-next-line
    rtn = (renderPropsArgs) => {
      const renderArgsContextName = mapRenderArgsToContextName;
      const ctxValue = {
        [renderArgsContextName]: renderPropsArgs
      };
      return <DataContext.Provider value={ctxValue}>{ele}</DataContext.Provider>;
    };

    if (unset && ele) {
      const newChildrenEle = unsetChildEle(childrenEle, ele);
      return [rtn, newChildrenEle];
    }
  }

  return rtn;
};

const rule = ({
  children, options, className, props
}) => {
  const as = _.get(options, 'as');
  let ctxValue = {};
  // get additional props from options
  if (options.props) {
    ctxValue = { props: options.props };
  }
  // eslint-disable-next-line
  return () => {
    return (
      <DataContext.Provider value={ctxValue} className={className}>
        <DataContext.Consumer>
          {(ctx) => {
            let childrenEle = children({ className }, ctx || new Context({}));
            // renderProps support
            let renderProps = {};
            if (options.renderProps) {
              ({ renderProps } = options);
              renderProps = { ...resolveOption(renderProps, ctx) };
              _.map(renderProps, (val, prop) => {
                const configVal = resolveOption(val, ctx, childrenEle);
                const conformedRenderProps = _.castArray(conformRenderPropsFn([configVal, prop], ctx, childrenEle));
                const [renderPropsFn] = conformedRenderProps;
                if (_.has(conformedRenderProps, '1')) {
                  childrenEle = conformedRenderProps[1];
                }

                renderProps[prop] = renderPropsFn;
              });
              // update children if needed
              childrenEle = _.get(renderProps, 'children', childrenEle);
            }
            // dataProps
            const dataProps = resolveObjectOption(ctx.get('props'), ctx);
            return React.createElement(
              as,
              {
                className,
                ...props,
                ...dataProps,
                ...renderProps
              },
              childrenEle
            );
          }}
        </DataContext.Consumer>
      </DataContext.Provider>
    );
  };
};

export default rule;
