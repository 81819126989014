import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import styled from 'styled-components';
import { Menu, Dropdown, Modal } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';
import _ from 'lodash';
import { getLocaleConfig, getCurrentLocaleConfig } from '@uz/mod-translations/utils';
import useRoute from '@vl/hooks/useGbRoute';
import gstyles from '@vl/gstyles';

const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
`;

const DesktopLangSwitch = () => {
  const route = useRoute();
  const localeConfigs = getLocaleConfig(false);
  const currentLocale = getCurrentLocaleConfig(route);

  return (
    <DIV className="component">
      <FormProvider form={ctx.get('form')}>
        <Form className="language-form">
          <Dropdown
            className="hidden lg:flex"
            overlay={
              <CustomDropDownContent className="shadow-xl">
                <Menu selectedKeys={[currentLocale.get('lang')]}>
                  {localeConfigs.map((localeConfig) => {
                    return (
                      <Menu.Item className="py-1 px-3 hover:bg-brandb-lightest" key={localeConfig.get('lang')}>
                        <span
                          className="text-main hover:text-main"
                          // to={localeConfig.withLangPrefix(
                          //   `${_.get(route, 'location.pathname')}${_.get(route, 'location.search')}`
                          // )}
                          // onClick={() => localeConfig.setCurrent()}
                          onClick={() => ctx.apply('submitForm.handleSubmit', localeConfig.get('lang'))}
                        >
                          <span className="text-main text-base font-normal">{_.toUpper(localeConfig.get('lang'))}</span>
                        </span>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </CustomDropDownContent>
            }
            placement="bottomRight"
          >
            <div className="mb-4 lg:mb-0 text-white cursor-pointer border px-2 py-1 rounded-lg">
              <div className="flex items-center space-x-1">
                <span className="text-main text-base font-semibold lg:text-white500">
                  {_.toUpper(currentLocale.get('lang'))}
                </span>
                <div>{gstyles.icons({ name: 'arrow-down', size: 20, fill: gstyles.colors.white500 })}</div>
              </div>
            </div>
          </Dropdown>
        </Form>
      </FormProvider>
    </DIV>
  );
};

export default DesktopLangSwitch;
