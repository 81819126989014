import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const LandingBenefitLayout = () => {
  return (
    <DIV className="LandingBenefitLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <section className="wrapper app-row benefit-section">
            <div className="flex flex-col mb-6 justify-center items-center">
              <div className="text-sm text-sub text-center font-semibold">{ctx.get('sectionData.shortText')}</div>
              <CustomParagraph className="text-center">
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
              </CustomParagraph>
            </div>

            <Row gutter={[24, 24]}>
              {_.map(
                _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []),
                (col, index) => (
                  <Col xs={24} md={12} lg={8} key={`${_.get(col, 'name', '')}_${index}`}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                  </Col>
                )
              )}
            </Row>
          </section>
        );
      })}
    </DIV>
  );
};

export default LandingBenefitLayout;
