module.exports = {
  welcome: 'Welcome',
  takeTheTour: 'Take the tour',
  Next: 'Next',
  Skip: 'Skip',
  Cancel: 'Quit',
  Show: 'Show',
  Hide: 'Hide',
  SeeMore: 'See more',
  noData: 'No data',
  Partner: {
    title: 'Become a Unitz partner today',
    description: 'Make effective use of your spare time and earn up to hundreds of millions VND/month! Register to get a competitive advantage now!',
  },
  WordDigit: ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'],
  WordMoney: ['million', 'thousand'],
  WordCurrency: {
    hundred: 'hundred',
    old: '',
    ten: 'ten',
    tenPrefix: 'ten',
    onePostfix: 'one',
    fivePostfix: 'five',
  },
  Currency: {
    vnd: 'Vietnamese Dong',
  },
  Form: {
    required: 'Required field',
    BackBtnTxt: 'Back',
    CancelBtnTxt: 'Cancel',
    SaveBtnTxt: 'Save',
    DeleteBtnTxt: 'Delete',
    EditBtnTxt: 'Edit',
  },
  Weeks: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  Welcome: {
    welcomeTitle1: 'Welcome to Unitz',
    welcomeDesc1: 'The best place to find the certified advisor for you in any field',
    welcomeTitle2: 'Transparent pricing',
    welcomeDesc2: 'With fixed consultation prices, you can learn worry free on Unitz',
    welcomeTitle3: 'A trust based community',
    welcomeDesc3: 'Check advisor reviews from fellow users before making your decision',
    or: 'or',
    exploreNow: 'Discover now',
    signupLabel: ' Create a new account',
    choiceArea: 'Choose the field that interests you',
    choiceAreaDesc: 'You can change it in the Settings.',
    areaType: {
      education: 'Education',
      consultant: 'Consulting',
    },
    function: 'Choose the service',
    educationDesc: 'Select "Education" if you need to find online courses, learn face-to-face with teachers.',
    consultantDesc: 'Select "Consulting" if you need advice from experienced experts in many fields.',
  },
  GDPR: {
    consent: {
      noticeMessageTxt: `By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.`,
      acceptButtonText: 'Accept All Cookies',
      declineButtonText: 'Reject All',
    },
  },
  Setting: {
    Language: {
      yourLanguage: 'Your Language',
      note: 'When you log in to Unitz, this will be the language you see. This will not affect the language the client sees on the platform.',
      language: 'Language',
      language_placeholder: 'Select language',
      save: 'Save',
      Notification: {
        success: 'Changed successfully',
        fail: 'Changed failure',
      },
    },
    DeactiveAccount: {
      title: 'Deactivate account / Delete account',
      description: 'Temporarily deactivate or delete your account at Unitz',
      deleteConfirm: 'Confirm to delete your account',
      deactiveConfirm: 'Confirm to deactivate your account',
      deleteNote: 'Your account will be deleted and you can not log in Unitz platform.',
      deactiveNote: 'Your account will be deactivated and you can not log in Unitz platform.',
      Action: {
        activate: 'Activate account',
        deactivate: 'Temporarily deactivate account',
        delete: 'Delete account',
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      Notification: {
        deleteSuccess: 'Your account is deleted',
        activeSuccess: 'Your account is activated',
        deactiveSuccess: 'Your account is deactivated',
        error: 'something went wrong...',
      },
    },
  },
  Selection: {
    titleSearchBar: 'Find advisors',
    titleCourseSearchBar: 'Search courses here...',
    call: 'Call',
    video: 'Video',
    message: 'Message',
    search: 'Search',
    noApply: 'Do not apply',
    placeHolder: 'Examples: English, Business',
    vnd: 'đ',
    filterDetail: 'Detail filters',
    vote: 'Rate',
    priceSlashMinute: 'Price/minute',
    bestFit: 'Most suitable',
    result: 'Advisors found',
    nextPage: 'Next page',
    prevPage: 'Back',
  },
  Login: {
    welcomeTitle1: 'Welcome to Unitz!',
    loginOptionsTitle1: 'Sign in using',
    brandDesc: 'Unitz connects you with advisors you can trust in the fields that interest you, with a fixed pricing model.',
    email: 'Email',
    guestName: 'Enter your name (Guest mode)',
    password: 'Password',
    forgotPassword: 'Lost your password?',
    saveAccount: 'Save your account',
    or: 'or',
    guestLogin: 'Guest access',
    login: 'Sign in',
    logout: 'Sign out',
    signupQuestion: "Don't have an account yet? ",
    signupRedirect: 'Sign up now',
    loginLabel: 'Sign in with',
    signupLabel: 'Create a new account',
    modal: {
      title: 'Login to continue',
    },
    ChoosingAdvisorTitle: 'Advisors you have chosen',
    facebookLogin: 'Facebook',
    googleLogin: 'Google',
    appleLogin: 'Apple',
    emailLogin: 'Email',
    method: 'Please choose how you want to sign in',
  },

  Auth: {
    login: 'Log in',
    register: 'Register',
    error: {
      email: 'Invalid email',
      emailIsUsed: 'The email address is already in use by another account',
      matchingPassword: 'Passwords must match',
      passwordMinCharactors: 'Password should be at least 6 characters',
    },
  },

  Signup: {
    createAccount: 'Create an account',
    signupOptionsTitle1: 'Sign up using',
    loginLabel: 'Sign in with',
    email: 'Email',
    fullname: 'Full name',
    phone: 'Phone',
    password: 'Password',
    passwordConfirm: 'Repeat password',
    forgotPassword: 'Forget your password?',
    or: 'or',
    message: 'Your messages',
    nextStep: 'Continue',
    signup: 'Sign up',
    loginQuestion: 'Already have an account? ',
    loginRedirect: 'Sign in now',
    loginToRegisterCourse: 'Sign in to register the course now',
    verifyEmailTitle: 'Verify your email',
    verifyEmailSuccessStatus: 'Your email has been verified. You can now sign in with your new account',
    verifyEmailSuccessContinue: 'Continue',
    signupLabel: 'Sign up with',
    agreeLabel1: 'By signing up, you confirm that you are at least 13 years old, ',
    agreeLabel1_1: 'By signing up, you confirm that you are ',
    agreeLabel1_2: 'above 13 years old',
    agreeLabel1_3: ', agree with ',
    agreeLabel2: 'and ',
    agreeLabel3: '',
    termOfServices: 'Terms and conditions',
    privacy: 'Privacy policy ',
    description: 'Create an account now to start connecting with our advisors and teachers',
    descriptionAdvisor: 'Create an account now to start connecting with our users',
    signupWithFb: 'Continue with Facebook',
    signupWithGoogle: 'Continue with Google',
    signupWithEmail: 'Sign up with Email',
    signupWithApple: 'Continue with Apple ID',
    termsAndCondition: 'Terms and conditions',
    signupNotification: 'Your account registered successfully',
    labelTermsContract: 'Below is the cooperation contract for experts at Unitz. Please read the contract carefully and confirm by entering your name below.',
    labelConfirm: 'I confirm that I have provided accurate information and I will be responsible for the information provided.',
    readConfirm: 'Please confirm that you have read and agreed with the terms and conditions',
    requireFill: '* To sign a contract, please fill in the information by B side.',
    check_get_noti: 'I agree to receive product updates and marketing communications from Unitz.',
    saveAccount: 'Lưu tài khoản',
  },
  Home: {
    welcomeBack: 'Welcome back ',
    recommendedAdvisors: 'Featured Advisors',
    interestedAdvisors: '',
    more: 'Show more',
    recommendedForYou: 'Recommended Advisors',
    featuredCourses: 'Featured Courses',
    reactionAdvisor: 'In your history',
    research: 'Search',
    categoryInterested: 'Favourite Categories',
    edit: 'Settings',
    listNoAnswer: 'You currently have %{num} unfulfilled calls',
    callNow: 'Call Now',
    exitApp: 'Tap again to exit the app',
    consultant: 'Consultant',
    academic: 'Academic',
  },
  HomeEducation: {
    recommendedAdvisors: 'Featured Teachers',
    requestLesson: 'REQUEST A FREE TRIAL LESSON',
    accountNotFinish: 'Tài khoản của bạn chưa hoàn tất thiết lập, vui lòng hoàn tất để xác thực.',
    settingAccount: 'Set up now!',
    listCategory: 'Categories',
    seeAll: 'View all',
  },
  Chat: {
    image: 'Image',
    status: {
      Online: 'Online',
      Offline: 'Offline',
    },
    seen: 'Seen',
  },
  Admin: {
    Dashboard: {
      teacher: 'Teachers',
      advisor: 'Advisors',
      user: 'Users',
      courseOnProgress: 'Courses on progress',
      IncomeTeacherInYear: 'Income of the teachers in month',
      IncomeTeacherPopup: 'This percentage ilustrates the comparison of the teacher income between 2021 and 2022',
      IncomeSystemInYear: 'Income of the platform in month',
      IncomeSystemPopup: 'This percentage ilustrates the comparison of the platform income between 2021 and 2022',
      courseInDay: 'Courses of today',
      detail: 'Detail',
      category: 'Categories',
      course: 'Courses',
      student: 'Students',
    },
    Account: {
      listTxt: 'Advisor List',
      Status: {
        active: 'Active',
        inActive: 'Inactive',
        activate: 'Activate',
        deActivate: 'Deactivate',
        deleted: 'Deleted',
        notDeletedYet: 'None',
        deactive: 'Vô hiệu hóa',
      },
      Table: {
        displayName: 'Name',
        email: 'Email',
        createdAt: 'Created at',
        contact: 'Contact',
        note: 'Note',
        phoneNumber: 'Phone number',
        passedDate: 'Passed date',
        startAt: 'Start at',
        endAt: 'End at',
        status: 'Status',
        courseName: 'Course name',
        roomName: 'Room name',
        studentList: 'Student',
        joinTime: 'Join date',
        urlList: 'Url list',
        room: 'Room',
        type: 'Type',
        link: 'Link',
        course: 'Course',
        profile: 'Profile',
        advisor: 'Advisor',
        scoring_int: 'Scores',
        contract: 'Contract',
        referral: 'Referral',
        topic: 'Topic',
        literacy: 'Level',
        targetAudience: 'Target',
        numberOfStudents: 'Number of students',
        numberOfStudent: 'Số lượng',
      },
      Action: {
        getUrl: 'Get url',
        copy: 'Copy',
        openNewTab: 'Open new tab',
        downloadCSV: 'Download profile',
        delete: 'Delete',
        restore: 'Restore',
      },
    },
    Room: {
      detailRoom: 'Detail room',
    },
    User: {
      Status: {
        active: 'Enable test state',
        deactive: 'Disable test state',
      },
    },
    Voucher: {
      Info: {
        adminOnly: 'This page is for admins only.',
        titleGenerateVoucher: 'Generate new code',
      },
      Action: {
        createNewVoucher: 'Generate new voucher',
      },
    },
    Assist: {
      assistor: 'Assistor',
      assist: 'Assist',
      nonAssist: 'Non-assistance',
    },
    Messages: {
      title: 'For',
      subject: 'Subject',
      targetType: {
        advisor: 'Advisor',
        user: 'User',
      },
      Action: {
        edit: 'Edit',
      },
    },
  },
  Question: {
    questionTitle: 'FAQs',
    more: 'Show more',
    moreCommonQuestion: 'Show more FAQs',
    allQuestion: 'All questions',
  },
  Guideline: {
    guidelineTitle: '4 easy steps to get you started',
  },
  CustomerCarousel: {
    customerTitle: 'Testimonials',
  },
  Handbook: {
    handBookTitle: 'UNITZ handbook',
    detail: 'Handbook details',
  },
  Whyus: {
    whyUsTitle: 'Why UNITZ',
  },
  Category: {
    categoryTxt: 'Categories',
    recommendedAdvisors: 'Featured advisors',
    interestedAdvisors: 'You may also like',
    recommendedTeachers: 'Featured teachers',
    more: 'Show more',
    discoverMore: 'Discover more',
    detail: 'Details',
    searchRecent: 'Recent search categories',
    categoryTitle: 'Advise by methods',
    allCategory: 'All categories',
    highlightCategory: 'Top categories',
    moreCategories: 'More categories',
    suggestionCategoriesTitle: 'Discover advisors in other categories',
    learnMore: 'Learn more',
  },
  CategoryDetail: {
    categoryDetailTitle: 'Category Details',
    searchTextPlaceholder: 'Search',
    moreAdvisor: 'See %{total} more advisors',
    expandFilters: 'Expand Filters',
    collapseFilters: 'Collapse Filters',
    recommendedCategories: 'Featured categories',
  },
  JobPost: {
    title: 'Recruitment',
    Filter: {
      title: 'Filter by categories:',
      categories: 'categories',
      all: 'all categories',
    },
    Detail: {
      applyThisJob: 'Apply for job',
      apply: 'Apply',
      submit: 'Submit Application',
    },
    Form: {
      title: 'Submit your application',
      fullName: 'Full name',
      email: 'Email',
      phone: 'Phone',
      cv: 'Your CV',
      successfullNotification: 'Submitted successfully',
      upload: 'Upload',
      errorNotification: 'Something went wrong. Please try again',
    },
    Validation: {
      required: 'Please fill out this field',
      fileSize: 'Your file is large than 5MB',
      phone: 'Invalid phone number',
    },
    Action: {
      startNow: 'Start now',
      contactUs: 'Contact us',
    },
  },
  Advisor: {
    highlightAdvisorTitle: 'Featured advisors',
    highestAdvisorTitle: 'Highest ranked advisors',
    pricePerMin: '(%{currency})/%{unit}',
    priceUnit: '/minute',
    rating: 'Review',
    joinedSince: 'Joined since %{dateStr}',
    voteCount: 'number of votes',
    timeJoined: 'experienced from',
    yearsExperience: 'years of experience',
    adviseCount: 'number of calls',
    courseCount: 'number of courses',
    call: 'Call now',
    callText: 'Audio call',
    callVideoText: 'Video call',
    watchMore: 'Show more',
    fitWithYou: 'Your match',
    listY_newAdvisors: 'New',
    listY_highlightAdvisors: 'Featured advisors',
    listY_onlineAdvisors: 'Online',
    listY_interestedAdvisors: 'You may also like',
    online: 'Online',
    offline: 'Offline',
    busy: 'Busy',
    more: 'Show more',
    less: 'Collapse',
    aboutMe: 'About me',
    onCourse: {
      zero: '0 courses',
      one: '1 course',
      other: '%{count} courses',
    },
    discoverMore: 'Discover more',
  },
  AdvisorRegister: {
    PlaceHolder: {
      description: 'Describe Your Expertise',
    },
    step: 'Step',
  },
  AdvisorVideoStart: {
    callInfoTxt: 'You have a total %{duration} minutes to contact this advisor',
    callPolicyTx: 'Your call is safe and secure. Unitz does not store records of your call with your advisor',
    videoCallTxt: 'Video Call',
  },
  AdvisorVideoCall: {
    title: 'Call ended',
    callStatus: {
      completedLabel: 'The call has ended',
      declinedLabel: 'The call was declined',
      missedLabel: 'The call was not answered',
      earlyEndLabel: 'The call was ended early',
    },
    callVideo: 'Video call package',
    callVoice: 'Voice call package',
    userReaction: 'What are your thoughts on the previous call?',
    rejectCallText: 'Call was interrupted?',
    missCallText: 'Missed call',
    earlyEndCallText: 'Is your call ending early?',
    reCall: 'Call back',
    ringingStatusTxt: 'Connecting to your advisor, please wait ',
    incommingStatusTxt: 'Incoming video call',
    initingStatusTxt: 'Establishing connection, hold on ... the first 3 minutes are free!',
    callReportTxt1: 'You have ended a call ',
    callReportTxt2: ' with the advisor:',
    callReviewLabel: 'Are you satisfied with the previous call? Let us know!',
    callReviewBadLabel: 'Unsatisfied',
    callReviewGoodLabel: 'Very good',
    callReviewSubmitBtn: 'Send',
    reviewTextPlaceholder: 'How can we improve your experience?',
    finishTxt: 'Finish',
    finishDesc: 'Are you satisfied with the previous call? Let us know!',
    ratingGood: 'Very good',
    ratingBad: 'Unsatisfied',
    finishNote: 'How can we improve your experience?',
    finishSend: 'Send',
    finishReview: 'Review',
    finishBuyMore: 'Buy more packages',
    ratingText: 'How was your call?',
    exit: 'Skip',
    noAnswer: 'No Answer',
    back: 'Back',
    connectionDisconnect: 'Lost connection due to network error',
    reportCall: 'Report an issue to Unitz',
    close: 'Exit',
    endCall: 'End Call',
    timeLeft: 'Time remaining: ',
    continueCall: 'Are you sure you want to end the call ?',
    goBack: 'Back',
    endCallAction: 'End call',
    outRoom: 'Leave Room',
    outRoomConfirm: 'Do you still want to leave the room ?',
  },
  AdvisorChatStart: {
    chatInfoTxt: 'You have a total %{duration} minutes to chat',
    chatPolicyTx: 'Your session is safe and secure. Unitz does not store your chat records with your advisor.',
    chatTxt: 'Chat now',
    comingSoon: 'Coming soon',
  },
  AdvisorChat: {
    callReportTxt1: 'You have finished your chat ',
    callReportTxt2: ' with the advisor:',
    callReviewLabel: 'Are you satisfied with the previous chat session? Let us know!',
    callReviewBadLabel: 'Unsatisfied',
    callReviewGoodLabel: 'Very good',
    callReviewSubmitBtn: 'Send',
    chatTextPlaceholder: 'How can we improve your experience?',
  },
  Profile: {
    titleText: 'Account',
    yourAccount: 'Your account',
    editProfileLabel: 'Update public profile',
    changePasswordLabel: 'Change password',
    nameLabel: 'Full name',
    emailLabel: 'Email',
    phoneLabel: 'Phone number',
    oldPasswordLabel: 'Old password',
    inputPassword: 'Please enter your current password',
    newPasswordLabel: 'Enter new password',
    confirmPasswordLabel: 'Repeat new password',
    message: 'Messages',
    balance: 'Your balance',
    paymentHistory: 'Payment history',
    paymentSetup: 'Payment setup',
    subPaymentSetup: 'List of linked bank cards',
    changePassword: 'Change password',
    helpCenter: 'Help center',
    subHelpCenter: 'Get help for your problems and questions',
    review: 'Rate Unitz on the AppStore or Google Playstore!',
    overview: 'About Unitz',
    service: 'Service',
    price: 'Price',
    time: 'Minutes',
    totalMoney: 'Total amount',
    connected: 'connected',
    updatePass: 'Update password',
    updatePassSuccess: 'Password updated successfully',
    updatePassFail: 'Password update failed',
    currentPassword: 'Please make sure you enter the correct password',
    matchPassword: 'Your new password must not be the same as your old password',
    confirmPass: 'Passwords do not match',
    forgotPassword: 'Forgot your password',
    resetPassword: 'Update password',
    getNewPassWord: 'An email will be sent to your inbox with instructions on how to change your password.',
    sendEmail: 'Email sent',
    sendEmailSuccess: 'An Email has been sent. Please check your inbox',
    enterEmail: 'Please enter your registered email address. You will receive instructions on how to reset your password',
    send: 'Send',
    transactionHistory: 'My transactions',
    subTransactionHistory: 'Purchased calls/packages',
    notification: 'Notifications',
    subNotification: 'Transaction information, refunds, missed calls',
    myWallet: 'My wallet',
    subMyWallet: 'Wallet details, recent transactions, top-ups',
    gift: 'Gifts',
    subGift: 'Special gifts from Unitz',
    schedule: 'My schedule',
    subSchedule: 'List of scheduled calls',
    course: 'My courses',
    subCourse: 'List of courses',
    request: 'Requests',
    activities: 'Activities',
    subRequest: 'List of requests you have sent to the system',
  },
  ProfileAdvisor: {
    socialLink: 'Social link',
    social_link: 'Social network',
    socialLinkPlaceholder: 'Enter your social network link, e.g: https://www.linkedin.com/in/...',
    notification: 'Notification',
    review: 'Reviews & Ratings',
    globalMessage: 'Global message',
    seeSample: 'See a sample video',
    learnMore: 'Learn more',
    watchVideo: 'Watch video',
    message: 'Messages',
    message_vi: 'Messages (vi)',
    message_en: 'Messages (en)',
    setting: 'Update account info',
    bonusMoney: 'Rewards',
    course: 'Courses',
    helpAdvisor: 'Help Center',
    videoIntroduce: 'Introductory video',
    infoProfile: 'Account info',
    avatar: 'Profile picture',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    email: 'Email',
    identify: 'Identity number',
    identifyReleaseDate: 'Identify release date',
    identifyReleasePlace: 'Identify release place',
    permanentAddress: 'Permanent address',
    specialityDesc: 'Description',
    passport: 'Passport',
    taxNumber: 'Tax number',
    scoringInt: 'Scores',
    editProfile: 'Update account info',
    edit: 'Edit',
    save: 'Save',
    personalPage: 'Your profile page',
    intro_text: 'Intro',
    update: 'Update',
    example: 'Example: I have over 10 years of experience giving relationship advice to my clients.',
    success: 'Information updated successfuly ',
    fail: 'Update failed, we are notified about this issue ',
    detail_text: 'Detailed description',
    category: 'Categories',
    degree: 'Certifications',
    enterEmail: 'Please enter your registered email address. You will receive instructions on how to reset your password',
    enterNewPassword: 'Please enter your new password',
    newPassword: 'new password',
    newPasswordConfirm: 'confirm your new password',
    send: 'Send',
    invalidEmail: 'Invalid email',
    required: 'Required',
    passwordMustMatch: 'passwords do not match',
    yinYang: 'Yin Yang',
    heart: 'Love - Marriage',
    coin: 'Money - Career',
    family: 'Family and children',
    casino: 'Properties - Real estate',
    photo: 'Photos',
    video: 'Videos',
    usingSteps: '4 easy steps to get started',
    commentAndReview: 'Ratings and reviews',
    consultingSteps: '4 easy steps to begin receiving advice',
    consultingStyle: 'Methods',
    workingTime: 'Availability',
    callPackages: 'Call packages',
    contact: 'Contact Advisor',
    reviews: 'reviews',
    more: 'Read more',
    collapse: 'Read less',
    callText: 'Audio call',
    callVideoText: 'Video call',
    smsText: 'Chat',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    page: 'Page',
    styleSlider: {
      title: 'Consulting Style',
      friendly: 'Friendly',
      polite: 'Polite',
    },
    speakSlider: {
      title: 'Speaking speed',
      slow: 'Calm',
      fast: 'Fast',
    },
    teachSlider: {
      title: 'Feedback Style',
      flexible: 'Soft',
      direct: 'Direct',
    },
    tabs: {
      profileTabLabel: 'About me',
      courseTabLabel: 'Courses',
      reviewTabLabel: 'Reviews',
    },
    editAvatar: 'Update profile picture',
    editPhoto: 'Update photo',
    choiceImage: 'Choose photo',
    editAvatarSuccess: 'Profile picture updated',
    editAvatarFail: 'Update failed',
    editPhotoSuccess: 'Upload successful',
    editPhotoFail: 'Upload failed',
    acceptPermission: 'Please grant access to camera and recording in order to continue using the app',
    goProfile: 'View public profile page',
    education: 'Education',
    certificates: 'Certificates',
    experience: 'Experience',
    schedule: 'My Schedule',
    contractNote: 'We have prepared for you a new contract with updated information. Please review and sign the contract to continue using our services.',
    contract: 'Sign contract',
    contractMessage: 'Success',
    contractDescription: 'You have just submitted the contract information successfully.',
    takeSurvey: 'Take the survey',
    activities: 'Activities',
  },
  FirebaseMessage: {
    'auth/user-not-found': {
      title: 'Invalid email',
      message: 'There is no account linked to this email.',
    },
    'auth/user-disabled': {
      title: 'Invalid account',
      message: 'The account corresponding to the given email has been disabled.',
    },
    'auth/invalid-email': {
      title: 'Invalid email',
      message: 'The email address is not valid.',
    },
    'auth/invalid-action-code': {
      title: 'Invalid path error',
      message: 'Invalid path. Please contact Unitz support to resolve the issue.',
    },
    'auth/email-already-in-use': {
      title: 'Invalid email',
      message: 'The email address is already in use.',
    },
    'auth/wrong-password': {
      title: 'Invalid password',
      message: 'The password is invalid.',
    },
  },
  Search: {
    searchResult: 'Search results for ',
    searchResultTotalHead: 'Abount ',
    searchResultTotal: '%{total} results ',
    searchResultTotalTail: 'for the keyword searched.',
    suggestedAdvisors: 'You may be interested in',
    screenTitleTxt: 'Search',
    searchAdvanced: 'Advanced Search',
    delete: 'Delete',
    confirm: 'Confirm',
    searchCategory: 'Search by category',
    searchSkill: 'Skills',
    category: 'Categories',
    skill: 'Choose skill',
    review: 'Rate',
    star_5: '%{star} star',
    star_4: 'From %{star} stars',
    result: '%{result} matched results',
    allCategory: 'All categories',
  },
  tts: {
    videoCall: {
      timeoutAlert: 'Your call will end after %{duration} minutes.',
    },
  },
  currency: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
  },
  time: {
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    second: 'second',
    month: 'month',
    year: 'year',
    currentMonth: 'Current month',
    minutes: 'minutes',
  },
  AdvisorProfileMenu: {
    Dashboard: 'Dashboard',
    Home: 'Home',
    Overview: 'Overview',
    Message: 'Messages',
    MyCourse: 'My courses',
    TeachingSchedule: 'Teaching schedule',
    Notification: 'Notifications',
    CourseManagement: 'Course management',
    ProfileManagement: 'Profile management',
    SettingWorkingTime: 'Availability',
    MyIncome: 'My income',
    CallHistory: 'Activity histories',
    Account: 'Account',
    Setting: 'Settings',
    Request: 'Requests',
  },
  AdvisorProfileGeneral: {
    Courses: 'Courses',
    CourseLower: 'course',
    CoursesLower: 'courses',
    NoCourseAddMore: 'You have no active courses. Please add one here!',
    NotPublished: 'Not published',
    GoingOn: 'Ongoing',
    ComingSoon: 'Coming soon',
    AddCourse: 'Add course',
    Detail: 'Detail',
    TodaySchedule: "Today's schedule",
    NoStudentYet: 'No students yet',
    Finished: 'Finished',
    JoinNowClass: 'Join now',
    OnlineAdvise: 'On-Demand Call Settings',
    NoAnyConsultationSession: 'No on-demand calls yet',
  },
  AdvisorProfile: {
    Overview: 'Overview',
    Skill: 'Skills',
    Rating: 'Ratings',
    Title: 'Advisor profile',
    aboutMe: 'About me',
    photos: 'Photos',
    category: 'Categories',
    advisorStyle: 'Methods',
    howToSpeak: {
      name: 'Consulting Style',
      min: 'Friendly',
      max: 'Polite',
    },
    speakingSpeed: {
      name: 'Speaking speed',
      min: 'Calm',
      max: 'Fast',
    },
    howToConveyTheResult: {
      name: 'Feedback Style',
      min: 'Soft',
      max: 'Direct',
    },
    timeWork: 'Availability',
    more: 'Show more',
    collapse: 'Show less',
    noName: 'Anonymous',
    callVoice: 'Audio call',
    callVideo: 'Video call',
    callBusy: 'Busy',
    addImage: 'Add image',
    SetSchedule: 'Book an appointment',
    Timezone: 'Timezone',
    restartMessageTimezone: 'The app will automatically restart to update Timezone.',
    status: {
      available: 'Available Now',
      takingAppointment: 'Taking Appointments',
      notAvailable: 'Not Available',
    },
  },
  Biz: {
    Form: {
      organization_name: 'Organization Name',
      country_label: 'Country',
      country_placeholder: 'Please select',
      register: 'Contact us',
      success_notification: '',
      success_description: 'We have received your contact request. Our team member will contact you shortly to arrange a meeting.',
      title: 'Job Title',
      email: 'Work Email Address',
      last_name: 'Last name',
      first_name: 'First name',
      phone_label: 'Phone number',
      phone_placeholder: '+49 150 123 55555',
      message: 'Your message',
      message_placeholder: 'Anything you want to let us know',
      check_text: 'By signing up, you agree to our terms and privacy policy. You agree that we can contact you about Unitz and use data from third parties to personalize your experience.',
      errorNotification: 'Something went wrong. Please try again later.',
    },
    Blog: {
      all_articles: 'All Articles',
      author: 'Author',
      date: 'Date',
      read_article: 'Read article',
      other_articles: 'Other Articles',
      no_blog: 'The page you requested does not exist',
      page: 'Page',
      of: 'of',
      next: 'Next',
      prev: 'Prev',
      goTo: 'Go to',
    },
    read_more: 'Read more',
  },
  Course: {
    aboutCourse: 'About',
    about: 'About',
    createCourseTitle: 'Create New Course',
    courseLabel: 'Course',
    detail_course: 'Course details',
    schedule: 'Schedule',
    course: '%{total} courses',
    classNotes: 'Class notes',
    teaching_schedule: 'Course session',
    coming_room: 'Starting on',
    course_of_lecture: 'More courses',
    files: 'Files',
    supports: 'Supports',
    reviews: 'Reviews',
    per_session: 'session',
    per_course: 'course',
    openingDay: 'Opening on %{startAt}',
    enrollNow: 'Enroll now',
    edit_course: 'Edit Course',
    viewCourse: 'View courses',
    myCourse: 'My courses',
    subMyCourse: 'List of courses you are enrolled in',
    paidAlready: 'Already paid',
    general: 'General',
    overview: 'Overview',
    activity: 'Activities',
    shareTitle: 'Share course',
    share: 'Share',
    allowTrialAccess: 'Allow free trial lesson',
    courseBenefits: 'Benefits of online learning via Unitz',
    benefits: {
      benefit1: 'Join live classes online with instructor',
      benefit2: 'Learn 1-1 class or group class',
      benefit3: 'Learn by a laptop, or a tablet via a browser',
      benefit4: 'No need to install software',
      benefit5: 'Low cost',
      benefit6: 'Performance Optimization',
      benefit7: 'Save time and avoid traffic jams',
      benefit8: 'Review lessons and documents after an online class session ends',
      benefit9: 'Flexible time anytime, anywhere',
      benefit10: 'Flexible Zeiteinteileung - egal wo, egal wann.',
    },
    demo: {
      messageInfo: 'You can register for a free trial lesson before purchasing this course. Select time for the trial lesson below and submit registration. Teacher will check and approve the registration.',
      buttonTitle: 'Register trial lesson',
      buttonTitleShort: 'Trial lesson',
      title: 'Trial lesson',
      messageHint: '(You can register a free trial lesson)',
      messageNoTrial: '(The class does not allow trial lesson)',
      messageRequested: '(You registered a free trial lesson)',
      modal: {
        title: 'Register trial lesson',
        okButton: 'Register',
        cancelButton: 'Close',
        doneButton: 'Done',
        sessionDurationLabel: 'Duration',
        startDateLabel: 'Select date',
        startTimeLabel: 'Session',
        startAtLabel: 'Time',
        results: {
          success: {
            title: 'Successfully registered trial lesson!',
            subTitle: 'Please wait for confirmation from teacher and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
      modalConfirm: {
        title: 'Response to trial lesson request',
        messageInfo: 'Students can submit a request for a trial lesson only once. Students can only enter the trial class when the teacher accepts the request for a trial lesson.',
        okButton: 'Accept',
        cancelButton: 'Close',
        doneButton: 'Done',
        confirmButton: 'Accept',
        rejectButton: 'Decline',
        sessionDurationLabel: 'Duration',
        startDateLabel: 'Select date',
        startTimeLabel: 'Select time',
        studentLabel: 'Student',
        notesLabel: 'Notes',
        results: {
          success: {
            title: 'Successfully registered trial lesson!',
            subTitle: 'Please wait for confirmation from teacher and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
    },
    shareContent: "Let's study with me!",
    course_count: {
      zero: '0 courses',
      one: '1 course',
      other: '%{count} courses',
    },
    course_is_teaching: {
      zero: 'currently teaching 0 courses',
      one: 'currently teaching 1 course',
      other: 'currently teaching %{count} courses',
    },
    contact: 'Enroll in course',
    emptyCourse: 'Course list is empty',
    seeAll: 'See all',
    rooms: 'Rooms',
    Info: {
      helperMessage: 'Tuition fee to be collected from students, please add on top of your desired rate an additional platform fee of 20% to your pricing. An example, if you charge 200k per hour lesson, then set the price per hour for this course at 240.000 vnd. Hence you will earn the full amount of 200k for your one hour of teaching. Note that, this is the fee that each of your students will pay. If there are 3 students in your class then you will earn 3x200k = 600k per one hour.',
      new_course: 'New course',
      step_title: 'Course Information',
      scoring_int_input_label: 'Scoring',
      scoring_int_input_placeholder: 'Example: 100',
      name_input_label: 'Title',
      name_input_placeholder: 'Add course title',
      description_input_label: 'Description',
      description_input_defaultValue: '<p>Course Introduction:&nbsp;</p><p>Target audience:</p><p>Detailed Course Content:</p><p>What Students will learn:</p><p>Textbooks:</p><p>Schedule:</p><p>Commitment:</p>',
      description_input_placeholder: 'Briefly describe your course',
      photo_url_input_label: 'Course cover photo',
      video_url_input_label: 'Course video',
      video_url_input_placeholder: 'Course video',
      price_amount_per_session: 'Price/session',
      price_amount_per_course: 'Price/course',
      no_photo: 'No cover photo',
      no_video: 'No video',
      session_duration_input_label: 'Duration/session',
      session_duration_input_placeholder: 'Select duration',
      start_at_input_label: 'Start date',
      pricing_type_input_label: 'Tuition fees by',
      object_teaching_input_label: 'Target audience',
      age_input_label: 'Age ',
      children_age_input_label: '(enter at least 3 years old and up to 18 years old)',
      to_age_label: 'to',
      years_old_label: 'years old',
      minimum_sessions_per_time: '%{number} sessions',
      frequency_of_tuition: {
        zero: 'pay tuition fees for 0 sessions/ time',
        one: 'pay tuition fees for 1 session/ time',
        other: 'pay tuition fees for %{count} sessions/ time',
      },
      tuition_by_course: 'Pay only once for the entire course',
      minute_per_session: '%{minute} minutes / session',
      benefit_input_label: 'What you will learn',
      benefit_input_hint: '(Enter at least 2 benefits that students get when taking classes with teachers.)',
      benefit_input_placeholder: 'EX: Communicating, using english in everyday conversation',
      category_input_label: 'Select category',
      category_input_placeholder: 'Select the category related to the course',
      minute: {
        zero: '0 minutes',
        one: '1 minute',
        other: '%{count} minutes',
      },
      student: {
        zero: '0 students',
        one: '1 student',
        other: '%{count} students',
      },
      session: {
        zero: '0 sessions',
        one: '1 session',
        other: '%{count} sessions',
      },
      object_teaching_for_adult: 'Adult',
      object_teaching_for_children: 'Children',
      session_type: 'Session',
      fullCourse_type: 'Full Course',
      session_occurence_input_label: 'Number of Sessions',
      unlimitedSession: 'Unlimited sessions',
      unlimited: 'Unlimited',
      price_amount_input_label: 'Tuition',
      price_amount_input_label_by_types: {
        per_course: 'Tuition (full course)',
        per_session: 'Tuition (per session)',
      },
      price_amount_input_placeholder: 'Enter price amount',
      enroll_min_input_label: 'Minimum students for course to start',
      enroll_min_input_placeholder: 'Enter number of students',
      enroll_max_input_label: 'Maximum number of students in class',
      enroll_max_input_placeholder: 'Enter number of students',
      attachments_input_label: 'Files',
      startAtTxt: 'Start on',
      Validation: {
        required: 'Please fill in the required fields',
        sessionMin: 'Please add at least one course session',
        conflictSession: 'Session #%{index} conflicts with another session',
        session_occurence_min: 'Not enough sessions %{session_count}/%{session_occurence}',
        session_occurence_max: 'Too many sessions %{session_count}/%{session_occurence}',
        conflictsessionCourse: 'Conflicting time with another course ',
        moreThanMoney: 'The amount of tuition must be greater than %{val}',
        lessThanMoney: 'The amount of tuition must be less than %{val}',
        limit_benefit_content_character: 'Maximum is 200 characters',
        min_benefit_entered: 'Please enter at least 2 benefits',
        category_input_min: 'Please select category',
        courseLinkMin: "Please add at least one course's link",
        conflictSessionCourse: 'Trùng giờ với khoá học',
        max_age: 'Tuổi tối đa chưa đúng',
        min_age: 'Tuổi tối thiểu chưa đúng',
      },
      Action: {
        add_photo: 'Add photo',
        select_files: 'Add files',
        drop_files_here: 'Drop files here...',
        add_benefit: 'Add benefit',
      },
      age_note_input_label: '(If there are many students, priority is given to the oldest student)',
      pending_message: 'You have a course waiting for approval, we have received your course on the system and will review it within 3 working days. We’ll send you notifications via our web and in-app notifications as well as by email: supplier@unitz.app, save this email address to your Contacts to always receive emails, or check spam mailbox once the course is approved.',
      category_input_min: 'Vui lòng chọn danh mục cho khóa học',
    },
    DurationSetting: {
      step_title: 'Duration settings',
      detail_study_time: 'Detail study time',
      time_interval: 'Time interval',
      number: 'No.',
      every_week: 'Every week on',
      hour_frame: 'Start time',
      repeat: 'Repeat',
      repeat_pattern_input_placeholder: 'Choose repeating date',
      Action: {
        add_hour_frame: 'Add another starting time',
        add_course_link: "Add another course's link",
      },
      helperMessage: 'The starting times specified here are the actual start and end times of the lessons in this course. As students will join the time slots specified here, please ensure that the course starting time(s) do not conflict with your other courses. If there is a conflict, the system will notify you of the duplication. If you would like to offer an alternative time slot, for example, Tue-Thu-Sat instead of Mon-Wed-Fri, please create a new course instead.',
      start_at: 'Start at',
    },
    Payment: {
      step_title: 'Payment',
      type_of_product: 'Type of product',
      course: 'Course',
      session_per_purchase_input_label: 'Type of purchases',
      session_per_purchase_input_placeholder: 'Choose type of purchases',
      should_publish_input_label: 'Tuition will be refunded if students are not satisfied with the course during the trial lesson',
      should_publish_input_placeholder: 'Publish this course',
      full_package: 'Full price',
      every_session: 'Every session',
      session: {
        zero: '0 sessions',
        one: '1 session',
        other: '%{count} sessions',
      },
      paidSessionsPerTotalLabel: {
        zero: 'Paid %{count}/%{session_occurence} sessions',
        one: 'Paid %{count}/%{session_occurence} session',
        other: 'Paid %{count}/%{session_occurence} sessions',
      },
      paidSessionsRemainLabel: {
        zero: 'Buy more',
        one: '%{count} session remains',
        other: '%{count} sessions remains',
      },
      paymentRoomListLabel: 'Payment for sessions',
      paidLabel: 'PAID',
      unPaidLabel: 'UNPAID',
    },
    Action: {
      add_course: 'Add course',
      clone_course: 'Clone course',
      save: 'Save',
      cancel: 'Cancel',
      next_step: 'Next step',
      prev_step: 'Previous step',
      preview: 'Preview',
      copy_url: 'Copy URL',
    },
    Status: {
      draft: {
        title: 'Draft',
        action: 'Draft',
      },
      published: {
        title: 'Published',
        action: 'Publish',
      },
      requested: {
        title: 'Demo request',
        label: 'Demo request',
        action: 'Confirm',
      },
      confirmed: {
        title: 'Demo confirmed',
        action: 'Confirm demo',
      },
      rejected: {
        title: 'Demo rejected',
        action: 'Reject demo',
      },
      completed: {
        title: 'Completed',
        action: 'Complete',
      },
      refund: {
        title: 'Refund',
        action: 'Refund',
      },
      canceled: {
        title: 'Canceled',
        action: 'Cancel',
      },
      closed: {
        title: 'Closed',
        action: 'Close',
      },
      onprogress: {
        title: 'On-going',
        action: 'On progress',
      },
      viewAsUser: {
        title: 'View as user',
        action: 'View as user',
      },
      clone: {
        title: 'Clone course',
        action: 'Clone course',
      },
      pending_approval: {
        title: 'Pending',
        action: 'Approve',
      },
    },
    RAT: {
      buttonSeeScheduleTitle: 'See course schedule',
      messageInfo: 'You can register for a free trial lesson before purchasing this course. Select time for the trial lesson below and submit registration. Teacher will check and approve the registration.',
      messageButton: 'If there is no suitable class for you. You can send requests to the teacher for another class time.',
      buttonTitle: 'Request Another Time',
      buttonTitleShort: 'Request Another Time',
      title: 'Request Another Time',
      messageHint: '(You can register a free trial lesson)',
      messageNoTrial: '(The class does not allow trial lesson)',
      messageRequested: '(You registered a free trial lesson)',
      requestMessage: 'Request another lesson of {{courseLink}} Course of teacher {{advisorLink}} has been sent.',
      confirmMessage: 'You have just accepted a Course Request from %{user}',
      rejectMessage: 'You just declined Request another lesson from %{user}',
      pendingMessage: 'Your request is awaiting approval from the teacher',
      go_RATCourse: 'Go to course',
      DurationSetting: {
        step_title: 'Duration settings',
        detail_study_time: 'Detail study time',
        time_interval: 'Time interval',
        number: 'No.',
        every_week: 'Every week on',
        hour_frame: 'Start time',
        repeat: 'Repeat',
        repeat_pattern_input_placeholder: 'Choose repeating date',
        Action: {
          add_hour_frame: 'Add another starting time',
        },
        helperMessage: 'The starting times specified here are the actual start and end times of the lessons in this course. As students will join the time slots specified here, please ensure that the course starting time(s) do not conflict with your other courses. If there is a conflict, the system will notify you of the duplication.',
      },
      modal: {
        title: 'Register trial lesson',
        okButton: 'Register',
        cancelButton: 'Close',
        doneButton: 'Done',
        sessionDurationLabel: 'Duration',
        numberOfStudentsLabel: 'Number of students',
        startDateLabel: 'Select date',
        selectDateMainLabel: 'Select date and time',
        selectDateLabel: 'Select date',
        startTimeLabel: 'Session',
        startAtLabel: 'Time',
        notesLabel: 'Notes',
        notesPlaceholder: 'Write your notes here',
        requested_note1: 'You received 01 request from user',
        requested_note2: 'Please respond as soon as possible!',
        requested_note3: 'If you agree with the above requirements, the system will automatically create a course with similar content',
        results: {
          success: {
            title: 'Successfully registered trial lesson!',
            subTitle: 'Please wait for confirmation from teacher and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
      registration: {
        label1: 'Your request has been sent successfully',
        label2: 'You can track requests in the Requests section of your account',
        info: 'Information required',
        seeRequire: 'View request now',
      },
      modalConfirm: {
        title: 'Response to trial lesson request',
        messageInfo: 'Students can submit a request for a trial lesson only once. Students can only enter the trial class when the teacher accepts the request for a trial lesson.',
        okButton: 'Accept',
        cancelButton: 'Close',
        doneButton: 'Done',
        confirmButton: 'Accept',
        rejectButton: 'Decline',
        sessionDurationLabel: 'Duration',
        startDateLabel: 'Select date',
        startTimeLabel: 'Select time',
        studentLabel: 'Student',
        notesLabel: 'Notes',
        results: {
          success: {
            title: 'Successfully registered trial lesson!',
            subTitle: 'Please wait for confirmation from teacher and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
    },
    RCM: {
      confirmed: 'Confirmed the course request',
      rejected: 'Rejected the course request',
      responsor: 'Responsor',
      buttonResponseTitle: 'Response',
      buttonRejectTitle: 'Reject',
      buttonTitle: 'Request course matching',
      buttonTitleShort: 'Request course matching',
      requestMessage: 'Request your another course has been sent.',
      confirmMessage: 'You have just accepted a course request from %{user}',
      rejectMessage: 'You just declined a course request from %{user}',
      rejectedMessage: 'You have been declined a course request from admin',
      pendingMessage: 'Your request is awaiting response from the teacher',
      noteLabel: 'Note',
      linkCourse: 'Courses matching',
      messageInfo: 'We will find a course matching or recommend a teacher to create a course if approved. So please let us know the details of your request.',
      Status: {
        requested: 'requested',
        rejected: 'rejected',
        confirmed: 'confirmed',
      },
      modal: {
        title: 'Request another course',
        topicLabel: 'Fill in the topic you want to study',
        literacyLabel: 'Level',
        teacherLabel: 'Instructor (If you have researched about the instructor before)',
        people: 'people',
        courseLink: "Course's link",
        responseButton: 'Submit',
        rejectButton: 'Reject',
        literacies: {
          beginner: 'Beginner',
          elementary: 'Elementary',
          intermediate: 'Intermediate',
          advanced: 'Advanced',
        },
        results: {
          success: {
            title: 'Successfully requested course!',
            subTitle: 'Please wait for confirmation from Unitz and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
      modalConfirm: {
        title: 'Response to trial lesson request',
      },
    },
  },
  CourseRoom: {
    purchase: {
      transaction_per_purchase_tooltip: 'Transaction/Purchase',
    },
    validation: {
      invalid_room_attendee: 'You are not enrolled for this lesson.',
      invalid_room_purchase: 'Purchase is required to enter this room.',
      invalid_room_time: 'The room is not open.',
      invalid_room_completed: 'The lesson is completed.',
      invalid_room_host: 'You are not allowed to host this room.',
      invalid_room: "The room doesn't exist",
    },
    status: {
      ready_to_join: 'Ready to join',
      not_scheduled: 'The class is not opening right now',
    },
    message: {
      invalid_room: 'Double check the classroom information.',
      check_camera_micro: 'Double-check your camera, micro and speaker settings!',
      not_scheduled: 'Your camera will not be shown when you join the class outside its scheduled time. But you can save the setting for later!',
    },
    label: {
      camera: 'Camera',
      micro: 'Micro',
      speaker: 'Speaker',
      view_class: 'View class',
    },
    placeholder: {
      camera: 'Select camera',
      micro: 'Select micro',
      speaker: 'Select speaker',
    },
    menus: {
      message: {
        title: 'Send message',
      },
      reschedule: {
        title: 'Reschedule room',
      },
      refund: {
        title: 'Refund room',
      },
      dismiss: {
        title: 'Dismiss rooms',
      },
      unfollow: {
        title: 'Unfollow',
      },
      cancel: {
        title: 'Cancel room',
      },
    },
    modals: {
      message: {
        title: 'Send message',
        infoMessage: 'Send message',
        okText: 'Send',
        cancelText: 'Close',
      },
      reschedule: {
        title: 'Reschedule',
        infoMessage: 'reschedule',
        okText: 'Reschedule',
        cancelText: 'Close',
        currentTimeFrameLabel: 'Current time',
        newTimeFrameLabel: 'New time',
        updateSameRoomsLabel: 'Apply new time for similiar rooms',
      },
      refund: {
        title: 'Refund',
        infoMessage: 'Refund',
        confirmMessage: 'Please enter the course name to the input below to continue proceed refund.',
        okText: 'Refund',
        cancelText: 'Close',
      },
      dismiss: {
        title: 'Dismiss',
        infoMessage: 'Dismiss',
        confirmMessage: 'Please enter the course name to the input below to continue proceed.',
        okText: 'Dismiss',
        cancelText: 'Close',
      },
      unfollow: {
        title: 'Unfollow',
        infoMessage: 'Unfollow',
        confirmMessage: 'Please enter the course name to the input below to continue proceed.',
        okText: 'Unfollow',
        cancelText: 'Close',
      },
      cancel: {
        title: 'Cancel room',
        infoMessage: 'Do you want to cancel this room? You will not recieve tuition fee for this room and Unitz will refund the fee to user.',
        confirmMessage: 'Please enter the course name to the input below to continue proceed cancel request.',
        okText: 'Ok',
        cancelText: 'Close',
      },
    },
    info: {
      Status: 'Status',
      NumberOfStudent: 'Number of student',
      Attendee: {
        zero: 'No one has joined yet',
        one: '1 participant',
        other: '%{count} participants',
      },
    },
  },
  CourseReview: {
    title: 'The lesson today is over!',
    description: 'Tell us a little bit more!',
    question1: 'How was your experience with the lesson?',
    question2: 'Would you like to rate your teacher again?',
    question3: 'Do you have any comments to the teacher? Please feel free to let us know what you think.',
    place_holder: 'Would you like to leave a comment for us? It will be useful for other students.',
    completeTitle: 'Thank you for rating!',
    completeDescription: 'We will use your feedback to improve the product',
    Action: {
      goHome: 'Go to homepage',
      confirm: 'Confirm',
      rateNow: 'Rate now',
      ok: 'OK',
    },
  },
  HomeSearchCourse: {
    per_session: 'Session',
    per_course: 'Course',
    Label: {
      field: 'Field',
      categories: 'Categories',
      session_duration: 'Session duration',
      tuition: 'Tuition',
    },
    Placeholder: {
      field: 'Select fields that interest you',
      categories: 'Select categories you want to learn',
      session_duration: 'Select lesson duration',
      tuition: 'Tuition by',
    },
    Action: {
      search: 'Search course',
    },
    course_more: 'See more courses on the same topic here.',
  },
  InstructorFilter: {
    title: 'Instructor Listing',
    filterTools: 'Filters',
    show: 'Show',
    quantity: 'Quantity',
    emptyFilterTxt1: 'There arent any registered experts in %{category} yet.',
    emptyFilterTxt2: 'We will notify you when a new expert is available.',
    emptyFilterTxt3: 'In the meantime, try searching for someone else!',
    apply: 'Apply',
    ratings: 'over %{count}',
    menu: {
      category: 'Category',
      priceRange: 'Price range',
      duration: 'Duration',
      ratings: 'Ratings',
    },
    sortBy: {
      placeHolder: 'Sort by',
      newest: 'Newest',
      outstanding: 'Outstanding',
      highRating: 'Rating: High to Low',
    },
    result: {
      zero: '0 results',
      one: '1 result',
      other: '%{count} results',
    },
  },
  CourseFilter: {
    title: 'Course Listing',
    filterTools: 'Filters',
    show: 'Show',
    sessionUnit: 'session',
    videoDemo: 'Demo video',
    quantity: 'Quantity',
    emptyFilterTxt1: 'There are not any courses in this category yet, but there will be soon!',
    emptyFilterTxt2: 'We will notify you when that happens.',
    emptyFilterTxt3: 'In the meantime, try searching for other courses!',
    apply: 'Apply',
    ratings: 'over %{count}',
    menu: {
      category: 'Category',
      priceRange: 'Price range',
      duration: 'Duration',
      ratings: 'Ratings',
    },
    sortBy: {
      placeHolder: 'Sort by',
      newest: 'Newest',
      outstanding: 'Outstanding',
      highRating: 'Rating: High to Low',
      viewing: 'Viewing',
    },
    result: {
      zero: '0 results',
      one: '1 result',
      other: '%{count} results',
    },
    recommendedCourses: 'Recommended courses',
  },
  Teacher: {
    lecturer: 'Instructor',
    more: 'Profile',
    teachingExpertise: 'Teaching expertise',
    myCourse: 'My courses',
    teachingStyle: 'Methods',
    howToSpeak: {
      name: 'Consulting Style',
      min: 'Friendly',
      max: 'Polite',
    },
    speakingSpeed: {
      name: 'Speaking speed',
      min: 'Calm',
      max: 'Fast',
    },
    howToConveyTheResult: {
      name: 'Feedback Style',
      min: 'Soft',
      max: 'Direct',
    },
    titleHeader: 'Teacher profile',
    title: 'Teacher',
    featured: 'Featured teachers',
  },
  Payment: {
    StepOneTitle: '1. Choose call package',
    StepTwoTitle: '2. Pay using Unitz wallet',
    StepTwoTitleVN: '2. Choose another payment method',
    StepThreeTitleVN: '3. Offer',
    StepThreeTitle: '3. Choose another payment method',
    StepFourTitle: '4. Offer',
    Bill: {
      package: 'Package',
      callVideo: 'Video call',
      tempCost: 'Price',
      discountCost: 'Discount',
      totalCost: 'Total amount',
      unitzCoin: 'Unitz wallet',
      promotion: 'Promotion',
      priceUnit: 'Minute unit price',
      pay: 'Pay now',
    },
    Wallet: {
      equivalent: 'equivalent',
    },
    Profile: {
      reviews: 'reviews',
      advisor: 'Advisor',
    },
    Method: {
      myCard: 'My card',
      domesticDebitCard: 'Local ATM bank card / internet banking',
      visaMasterCard: 'Visa / Master Card',
      newCard: 'Add card',
      selectCard: 'Select card',
      selectBank: 'Select bank',
    },
    Discount: {
      selectDiscountPlaceHolder: 'Enter offer code',
      optionLabelDiscountPercentage: 'Discount %{percentage}%',
      optionLabelDiscountAmount: 'Discount %{amount}',
      optionLabelDiscountPercentageMaxAmount: 'Discount %{percentage}% up to %{amount}',
    },
    Error: {
      service: 'You need to choose a service',
      credit: 'Do you want to pay with Unitz wallet?',
      bank: 'Please choose your bank card.',
      token: 'Please choose your card.',
    },
    Action: {
      payNow: 'Pay now',
      joinClass: 'Go to class',
    },
  },
  Wallet: {
    walletTxt: 'My wallet',
    walletSurplus: 'Wallet surplus:',
    Action: {
      addVoucher: 'Enter voucher',
      recharge: 'Add money',
      transfer: 'Transfer',
    },
    Table: {
      transactionDate: 'Transaction date',
      detailTransaction: 'Details',
      status: 'Status',
      Amount: 'Price',
    },
  },
  Bank: {
    bankListTxt: 'Bank List',
    internationalPayment: 'Debit or Credit Card',
    paypalPayment: 'PayPal',
    domesticPayment: 'Bank account',
    emptyCardList: 'You do not have a card',
    default: 'Default',
    addInternationalCard: 'Add credit card / debit card',
    addPaypal: 'Add PayPal account',
    addDomesticCard: 'Add bank account',
    numberCard: 'Number of bank account',
    numberCard_placeholder: 'Ex: 1234 5678 9000 1234',
    paypalId: 'PayPal account',
    paypalId_placeholder: 'Name, @username, email, or phone number',
    nameCard: 'Full name as displayed on card',
    nameCard_placeholder: 'Ex: David Stamp',
    bankSelection: 'Select a bank',
    bankSelection_placeholder: 'Please select a bank',
    expDate: 'Valid thru',
    expDate_placeholder: 'MM/YY',
    cvv: 'CVV',
    cvv_placeholder: 'Ex: 123',
    Validation: {
      equiredCardType: 'Invalid card number',
      requiredCardLen: 'Card number must be 16 characters long',
      requiredCardExpDate: 'Invalid expiration date',
      requiredCardCVC: 'Invalid CVV number',
      required: 'Please fill in the required fields',
    },
    Action: {
      addCard: 'Add Card',
      addAccount: 'Add Account',
      delete: 'Delete',
      saveCard: 'Save',
      back: 'Back',
    },
  },
  History: {
    Filter: {
      category: 'Category',
      status: 'Status',
      startDate: 'Start date',
      endDate: 'End date',
      filterTxt: 'Filter by',
    },
    Status: {
      reject: 'Missed call',
      pending: 'Waiting for confirmation',
      unknown: 'Unknown',
      completed: 'Finished',
      canceled: 'Cancelled',
    },
    Table: {
      createdAt: 'Date & time',
      displayName: 'Teacher',
      type: 'Type',
      during: '\tTime',
      status: 'Status',
      priceAmount: 'Price',
      courseRoomDate: 'Room time',
    },
    Detail: {
      detailTxt: 'Activity detail',
      paymentInfo: 'Activity Info',
      createdAt: 'Date & time',
      status: 'Call status',
      package: 'Call package',
      callTxt: 'Call',
      courseTxt: 'Course',
      during: 'Duration',
      typeOfService: 'Type of service',
      advisor: 'Advisor',
      teacher: 'Teacher',
      cash: 'Price',
      numberOfSession: 'Number of sessions registered',
      statusOfCourse: 'Status of course',
    },
    Rating: {
      ratingTxt: 'Rate & Comment',
      yourRating: 'Your Rating',
      aboutAdvisor: 'Comments about advisor',
    },
  },
  Transaction: {
    Filter: {
      category: 'Categories',
      status: 'Status',
      time: 'Date',
      startDate: 'From',
      endDate: 'To',
      filterTxt: 'Filter by',
    },
    Status: {
      reject: 'Missed call',
      pending: 'Awaiting confirmation',
      unknown: 'Unknown',
      incomplete: 'Unconfirmed',
      completed: 'Confirmed',
      refund: 'Refund',
      expired: 'Expired',
      canceled: 'Canceled',
      declined: 'Declined',
      processing: 'Processing',
    },
    Table: {
      createdAt: 'Date & time',
      displayName: 'Advisor',
      coin: 'Unitz Coin',
      status: 'Status',
      priceAmount: 'Total amount',
      package: 'Package',
      detailTransaction: 'Detail transaction',
    },
    Detail: {
      transactionInfo: 'Payment info',
      paymentInfo: 'Payment info',
      paySuccess: 'Payment success',
      paymentDetail: 'Payment details',
      call: 'Call now',
      createdAt: 'Time of transaction',
      transactionId: 'Transaction ID',
      buyPackage: 'Buy call package',
      buyBooking: 'Buy booking service',
      buyCourse: 'Purchase course',
      status: 'Status',
      package: 'Advisory package',
      displayName: 'Advisor',
      typeOfTransaction: 'Transaction type',
      typeOfService: 'Service type',
      serviceDetail: 'Details',
      customer: 'Customer',
      paymentResource: 'Payment Resource',
      cash: 'Payment amount',
      totalCash: 'Total amount',
      promotion: 'Promotion',
    },
    payments: {
      labelsByType: {
        card: 'Visa/master card',
        wallet: 'Unitz wallet',
        payment: 'ATM card',
      },
    },
  },
  Booking: {
    advisorTitle: 'Advisor',
    namePackage: 'Price for %{time} minutes package',
    selectPackage: 'Select a package',
    freeFiveMinutes: 'The first three minutes are free!',
    checkout: 'Pay',
    checkoutMethod: 'Payment methods',
    methodPayment: {
      checkout: 'Pay',
      checkoutMethod: 'Payment methods',
      visaOrMaster: 'Visa/Master Cards',
      bankCard: 'Bank Card',
      eWallet: 'e-Wallet',
      momo: 'Momo',
    },
    PaymentEditCard: {
      headerTitle: 'Update card info',
      cardName: 'Full name as displayed on card',
      cardExpDate: 'Expiration date',
      setDefault: 'Set as main card',
      newCard: 'Add new card',
      newCardName: 'Name on card',
      newCardNumber: 'Card number',
      newCardExp: 'MM/YY',
      newCardCVV: 'CVV',
      confrim: 'Confirm',
      requiredCardType: 'Invalid card number',
      requiredCardLen: 'Card number must be 16 characters long',
      requiredCardExpDate: 'Invalid expiration date',
      requiredCardCVC: 'Invalid CVV number',
      required: 'Please fill in the required fields',
    },
    checkoutStatus: {
      title: 'Transaction results',
      code: 'Transaction code',
      timeTransaction: 'Transaction time',
      call: 'Call now',
      detail: 'Details',
      success: 'Payment success',
      fail: 'Payment failed',
      pending: 'Pending confirmation',
      congratulations: 'Congratulations, you have successfully paid for the package',
      waitingForSupport: 'The process of verifying payment may take some time, please wait',
      or: 'or',
      backHome: 'Return to home page',
      canceled: 'Transaction was cancelled',
      processing: 'We are processing your transaction, please wait.',
      completed: 'Transaction successfully processed',
      declined: 'Transaction was declined. Please double check your payment information',
    },
    AmountInvalid: 'The amount to be paid via your bank card is below the minimum transaction limit, please pay the full amount',
  },
  AdvisorApp: {
    Onboarding: {
      Skip: 'Skip',
    },
    Login: {
      Login: 'Sign in',
      loginWith: 'Sign in with',
      fillInfo: 'Fill in your account information and password',
      userName: 'Username',
    },
  },
  Tabbar: {
    Home: 'Home',
    New: 'Discover',
    Wallet: 'Wallet',
    Monetization: 'Income',
    Account: 'Account',
    Calender: 'My calendar',
  },
  HomeAdvisor: {
    welcomeBack: 'Hi, ',
    readyAdvise: 'Ready to receive calls',
    withdraw: 'Withdraw',
    Monetization: 'Income',
    Rate: 'Ratings',
    timeAvg: 'mins / this week',
    On: 'Ready',
    Off: 'Currently off',
    setPrice: 'Set price for packages',
    setPriceTxt: 'Set price',
    namePackage: '%{time} minutes package',
    createPackage: 'Add new price package',
    newPackage: 'New price package',
    editPackage: 'Update price package',
    perPrice: 'Unit price',
    totalPrice: 'Total amount',
    numTime: 'Number of minutes',
    defaultMoney: '0 vnd',
    date: {
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun',
    },
    control: 'Control',
    edit: 'Edit price',
    delete: 'Delete price package',
    selectioPackage: 'Package',
    acceptVideoCall: "You are ready to receive %{name}'s call",
    rejectVideoCall: "You are NOT taking %{name}'s call",
    acceptAdvise: 'You are ready to receive calls',
    rejectAdvise: 'You are NOT taking calls',
    kindCall: '%{name} call',
    kindCallVideo: 'Video call',
    kindCallVoice: 'Audio call',
    kindCallNotificationVideo: 'video call',
    kindCallNotificationVoice: 'audio call',
    newPackagePrice: 'Price for package',
    notAllowAccount: 'The account has not been approved',
    noServiceYet: 'Your account is currently unavailable',
    sendAuthentication: 'Send account verification',
    AuthenticationDescription: 'Have you finished setting up all information in your %{account} and %{info}?',
    Account: 'Account',
    Info: 'Profile',
    ConfirmAndSend: 'Confirm and send',
    Back: 'Back',
    SendApprovalSuccess: 'Submitted request for account confirmation successfully!',
    SendApproval: 'Request Approval',
  },
  AdvisorVideoReceiver: {
    reject: 'Reject',
    accept: 'Answer',
    speaker: 'Loud speaker',
    microOn: 'Mic On',
    microOff: 'Mic Off',
    rotateCamera: 'Flip camera',
    endCall: 'End call',
    videoOn: 'Video On',
    videoOff: 'Video Off',
    reportCall: 'Call Report',
    callSuccess: 'Call completed',
    callFail: 'Call interrupted',
    callInfo: 'Call information',
    callPackage: 'Call package:',
    callPackageTime: 'Call duration:',
    callPackagePrice: 'Price per minute:',
    callPackageTotal: 'Price:',
    pricePerMin: '%{currency}/minute',
    goBack: 'Return to home',
    separate: 'Split screen',
    collapse: 'Minimize',
    reconnect: 'Connection of %{user} is not stable. Please wait for a few moments!',
    disconnect: 'Call was disconnected due to internet connectivity. Please contact customer support for further assistance!',
  },
  AdvisorWallet: {
    myWallet: 'My wallet',
    walletBalance: 'Current balance',
    walletAvailabeBalance: 'Available balance',
    linkBankDesc: 'Link your bank account in order to withdraw money directly from the application.',
    linkBankNow: 'Link your bank account now  ᐳ',
    linkBankTitle: 'Linked accounts',
    labelBankName: 'Account holder',
    bankName: 'Enter your full name',
    labelBankNumber: 'Account number',
    bankNumber: 'Enter your bank account number',
    bankCode: 'Bank',
    addBank: 'Add account',
    listAccount: 'Account list',
    verify: 'Verifying',
    transferToAccount: 'Withdraw money',
    historyTransaction: 'View transaction history',
    history: 'My transactions',
    transfer: 'Transfer',
    typeMoney: 'Enter amount in (vnd)',
    accountReceiver: 'Receiving account',
    continue: 'Continue',
    transferSuccess: 'Transfer success',
    listBank: 'List of banks',
    searchBank: 'Search for bank',
    addCardSuccess: 'Add card info success',
    addCardFail: 'Add card info failed',
    payout: 'Withdraw',
    balance: 'Balance: %{balance}',
    required: 'Please enter the required fields',
    moreThanMoney: 'The amount of money must be greater than %{val}',
    notValidAmount: 'The amount to transfer must not exceed your current available balance',
    bankEmptyList: 'No banks linked yet',
    historyTransactionEmpty: 'Transaction history is empty',
    incomeEmpty: 'No income history',
    requiredPrice: 'Please enter the desired package',
    minPrice: 'Minimum package price %{amount}',
    maxPrice: 'Maximum package price %{amount}',
    transferError: 'Transfer error',
    bankNamePlaceholder: 'Enter your full name as appearing on bank statements',
    bankNumberPlaceholder: 'Enter your bank account number',
    infoText: 'Transaction info',
    typeText: 'Transaction type',
    code: 'Transaction code',
    timeTxt: 'Transaction time',
    statusTxt: 'Status',
    infoTransaction: 'Payment Info',
    total: 'Withdraw amount',
    fee: 'Fee',
    amountAfter: 'Total amount',
    balanceAvailable: 'Your balance after transaction',
    describeDeposit: 'Please add money to Unitz wallet to complete the transaction',
    walletName: 'Unitz wallet',
    depositAdd: 'Add money',
    exchangePayment: 'Change payment method',
    method: 'Payment method',
    type: 'Withdraw to bank account',
    minValue: 'Minimum amount is %{num}',
    maxValue: 'Maximum amount is %{num}',
    account: 'Account Receive',
    reason: 'Reason',
    status: {
      processing: 'Pending confirmation',
      completed: 'Confirmed',
      canceled: 'Cancelled',
      declined: 'Declined',
    },
    detail: 'Transaction details',
    checkoutStatus: {
      canceled: 'Transaction was cancelled',
      processing: 'We are processing your transaction, please wait.',
      completed: 'Transaction successfully processed',
      declined: 'Transaction was declined. Please double check payment information',
    },
    withdrawError: 'Withdraw request failed',
    withdrawSuccess: "We have successfully received your withdrawal request. Payments will be processed from the 5th to 10th of the next calendar month for your previous month's earnings",
    support: 'Contact help',
    walletStatus: 'Status',
    linking: 'Linking a bank card',
    linked: 'Bank card linked',
    unlink: 'Unlink a bank card',
    setCardDefault: 'Set card as default',
    setCardDefaultSuccess: 'Default card set successfully !',
    setCardDefaultFail: 'Failed to set default card !',
    unlinkSuccess: 'Unlinked bank card successfully !',
    unlinkFail: 'Failed to unlink bank card !',
  },
  AdvisorInCome: {
    titleText: 'Income',
    advisorNum: '%{num} calls',
    inComeToday: 'Today',
    inComeYesterday: 'Yesterday',
    inComeInWeek: 'This week',
    inComeInMonth: 'This month',
    inComeDetail: 'Detailed income',
    advisorNumDetail: '%{num} number of calls made',
    inComeTextPackage: '%{num} minutes package',
    inComeTextTime: '%{num} minutes',
    titleTextTotal: 'Total income',
    statistical: 'Statistics',
    summary: 'Summary',
    chart: {
      today: 'Today',
      yesterday: 'Yesterday',
      week: 'This week',
      month: 'This month',
    },
    totalFrom: 'Total income from %{start} - %{end}',
    perMount: 'Average: %{num} vnd/call',
    perTime: 'Average: %{num} mins/call',
    collapse: 'Collapse',
    more: 'See more',
    callVideo: 'Video call',
    callVoice: 'Voice call',
    callRoom: 'Course',
    fromDate: 'From %{start} to %{end}',
    chartView: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
    },
    totalDay: 'Total day earnings',
    numAdvisorTxt: 'Number of calls taken',
    numAdvisor: '%{num} calls',
    numCourse: '%{num} lessons',
    fromDay: 'From %{start} to %{end}',
    rangeWeek: {
      0: 'Sun',
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
    },
  },
  Calendar: {
    Today: 'Today',
    Yesterday: 'Yesterday',
    Tomorrow: 'Tomorrow',
    Week: 'This week',
    Month: 'This month',
    Day: 'Day',
    _Week: 'Week',
    _Month: 'Month',
    Agenda: 'Agenda',
    Date: 'Date',
    Time: 'Time',
    Event: 'Event',
    Allday: 'All day',
    noEventsInRange: 'No classes in this range',
    FilterByCourses: 'Filter by courses',
  },
  Validation: {
    accountName: 'Account name must be at least 6 characters long',
    accountNumber: 'Account number is invalid',
    fullName: 'Full name must be at least 6 characters long and must not contain numbers, special characters',
    phoneNumber: 'Invalid phone number',
    identify: 'Identity number is not valid',
    passport: 'Passport is not valid',
    taxNumber: 'Tax number is not valid',
    email: 'Invalid email address',
    httpLink: 'Invalid URL address',
  },
  Alert: {
    accept: 'Accept',
    reject: 'Cancel',
  },
  AdvisorNotification: {
    headerTitle: 'Notifications',
    emptyList: 'No system notifications yet, come back later',
    All: 'All',
    UnSeen: 'Unseen',
    Seen: 'Seen',
    detail: 'Detail',
  },
  AdvisorReview: {
    headerTitle: 'Ratings and reviews',
    userReview: 'User reviews',
    numReview: '%{num} reviews',
    collapse: 'Read less',
    more: 'Read more',
    emptyList: 'No ratings or reviews',
    question: 'Would you like to leave a comment for teacher?',
    place_holder: 'Write your thoughts here!',
    rating_success: 'Rating successfully',
    Action: {
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
  },
  AdvisorBonusMoney: {
    point: 'Rewards',
    transfer: 'Convert and withdraw to my wallet',
    task: 'Tasks',
    emptyList: 'No new tasks, check back later',
  },
  AdvisorVoiceStart: {
    callInfoTxt: 'You have %{duration} minutes of connection with your advisor',
    callPolicyTx: 'Your call with your advisor is safe and secure. Unitz does not store call data without your consent',
    videoCallTxt: 'Audio call',
  },
  AdvisorVoiceCall: {
    title: 'Call was ended',
    userReaction: 'What are your thoughts on the previous call?',
    rejectCallText: 'Call was interrupted?',
    reCall: 'Call back',
    ringingStatusTxt: 'Trying to contact your advisor, please wait. We will not charge you until you are connected with your advisor.',
    incommingStatusTxt: 'Incoming voice call',
    initingStatusTxt: 'Establishing connection ...',
    callReportTxt1: 'You have just ended a ',
    callReportTxt2: ' call with an advisor:',
    callReviewLabel: 'Please let us know your thoughts on the previous call',
    callReviewBadLabel: 'Unsatisfied',
    callReviewGoodLabel: 'Very good',
    callReviewSubmitBtn: 'Send',
    reviewTextPlaceholder: 'How can we improve your experience?',
    finishTxt: 'Complete',
    finishDesc: 'Please let us know your thoughts on the previous call',
    ratingGood: 'Very good',
    ratingBad: 'Unsatisfied',
    finishNote: 'How can we improve your experience?',
    finishSend: 'Send',
    finishBuyMore: 'Buy more packages',
    exit: 'Exit',
    noAnswer: 'No Answer',
    back: 'Back',
    connectionDisconnect: 'Lost connection due to network error',
    reportCall: 'Report an issue to Unitz',
    close: 'Exit',
    callStatus: {
      completedLabel: 'Cuộc gọi kết thúc',
      declinedLabel: 'Cuộc gọi bị từ chối',
      missedLabel: 'Cuộc gọi bị nhỡ',
      earlyEndLabel: 'Cuộc gọi kết thúc sớm',
    },
    finishReview: 'Nhận xét',
    callVideo: 'Gói video',
    callVoice: 'Gói thoại',
  },
  AdvisorVoiceReceiver: {
    reject: 'Reject',
    accept: 'Answer',
    speaker: 'Loud speaker',
    microOn: 'Mic On',
    microOff: 'Mic Off',
    rotateCamera: 'Flip camera',
    endCall: 'End call',
    videoOn: 'Video On',
    videoOff: 'Video Off',
    reportCall: 'Call Report',
    callSuccess: 'Call completed',
    callFail: 'Call interrupted',
    callInfo: 'Call summary',
    callPackage: 'Call package:',
    callPackageTime: 'Call duration:',
    callPackagePrice: 'Price per minute:',
    callPackageTotal: 'Total amount:',
    pricePerMin: '%{currency}/minute',
    goBack: 'To homepage',
    separate: 'Split screen',
    collapse: 'Minimize',
    reconnect: 'Connection of %{user} is not stable. Please wait for a few moments!',
    disconnect: 'Call was interrupted due to network error',
  },
  UserTabbar: {
    Home: 'Home',
    Category: 'Categories',
    Like: 'Favorites',
    Account: 'Account',
    Calendar: 'Calendar',
  },
  StatusAdvisor: {
    Online: 'Online',
    Offline: 'Offline',
  },
  SearchUser: {
    placeHolder: 'Find an advisor',
    suggest: 'Suggestion',
    done: 'Done',
    result: '%{num} advisors found for you',
    emptyList: 'Find your advisor',
    notResult: 'No advisors found',
  },
  VideoCallPage: {
    SectionAction: {
      split: 'Split screen',
      collapse: 'Minimize',
      note: 'Note',
      record: 'Record',
      like: 'Like',
      setting: 'Settings',
      participants: 'Participants',
      message: 'Message',
    },
    Initing: {
      calling: 'Calling',
    },
  },
  UserPayment: {
    advisorTitle: 'Advisor',
    confirmText: 'Confirm payment',
    payment: 'Pay now',
    paymentCourse: 'Pay for',
    of: 'from',
    payFor: 'for %{time} minutes call package to',
    walletName: 'Unitz Wallet',
    walletIconLabel: 'Unitz wallet',
    walletIcon: 'Unitz wallet: %{amount}',
    addPaymentMethod: 'Add new payment method',
    transactionDetail: 'Transaction details',
    amountBefore: 'Price',
    discount: 'Discount',
    amountAfter: 'Total amount',
    serviceType: '%{kind} call',
    packageName: '%{time} minutes package',
    titleTopup: 'When you top up your wallet from %{amount}',
    topUpDesc: '',
    depositNow: 'Add money now',
    topUpTxt: 'Top up',
    amount: 'Amount',
    topUpUseful: 'Top up wallet to extend calls without interruption',
    discountTxt: 'The first three minutes are free!',
    emptyTransactionHistory: 'No transactions yet',
    paymentMyWallet: 'Pay from my Unitz Wallet',
    linkBank: 'Link your bank card for your next purchase.',
    learnMore: 'Learn more',
    typePackage: 'Package type',
    typeService: 'Type of call',
    duration: 'Time',
    time: 'Date',
    enterVoucherCode: 'Please enter voucher code',
    invalidVoucher: 'Invalid voucher, please try again!',
  },
  UserPaymentMethod: {
    titleTxt: 'Payment methods',
    listCard: 'My bank cards',
    listCardOther: 'Other payment methods',
    ATM: 'Local ATM bank cards / Internet banking',
    VisaCard: 'Debit or Credit Card',
    momoWallet: 'MoMo Wallet',
    zaloPay: 'Zalopay',
    methodSaved: 'Saved payment methods',
    addCard: 'Add new card',
    selected: 'Select',
    exchangeCard: 'Update',
    emptyPaymentMethod: 'No payment methods linked yet.',
    paypal: 'Paypal',
  },
  PurchaseDetail: {
    title: 'Transaction details',
    waitForConfirm: 'Waiting for confirmation from payment provider',
    completed: 'Transaction confirmed',
    waitCompleted: 'Pending transaction',
    sourceMoney: 'Source',
    infoTransaction: 'Transaction information',
    total: 'Price',
    totalPerSession: 'Price (by session)',
    discount: 'Discount',
    amountAfter: 'Total amount',
    status: {
      processing: 'Pending confirmation',
      completed: 'Confirmed',
      refund: 'Refunded',
      canceled: 'Cancelled',
      declined: 'Declined',
    },
  },
  TopUpPayment: {
    amountBalance: 'Account balance',
    descDiscount: 'Get a %{percent}% bonus for top-ups of %{amount} and more!',
    source: 'Source',
    deposit: 'Add money',
    exchange: 'Exchange',
    currency: 'vnd',
    infoText: 'Transaction info',
    typeText: 'Transaction type',
    code: 'Transaction code',
    timeTxt: 'Transaction time',
    statusTxt: 'Status',
    infoTransaction: 'Payment Info',
    total: 'Payment amount',
    discount: 'Discount',
    amountAfter: 'Total amount',
    balanceAvailable: 'Your balance after transaction',
    notValidAmount: 'Insufficient balance',
    describeDeposit: 'Please top-up your Unitz wallet to complete the transaction',
    walletName: 'Unitz wallet',
    depositAdd: 'Add money',
    exchangePayment: 'Change payment method',
    method: 'Payment method',
    type: 'Add money to Unitz Wallet',
    minValue: 'Minimum amount is %{num}',
    maxValue: 'Maximum amount is %{num}',
  },
  ServiceCallPackage: {
    buyPackage: 'Buy Package',
    desc: '5 minutes left, would you like to buy another package?',
    cancel: 'Later',
    accept: 'Buy more packages',
    payment: 'Payment',
    continue: 'continue',
    confirmTxt: 'Payment Confirmation',
    amountBefore: 'Price',
    discount: 'Discount',
    total: 'Total',
    status: {
      processing: 'Pending confirmation',
      completed: 'Confirmed',
      canceled: 'Cancelled',
      declined: 'Declined',
    },
  },
  ProfileUser: {
    infoProfile: 'Account info',
    message: 'Messages',
    fullName: 'Full name',
    userInformation: 'User Information',
    phoneNumber: 'Phone number',
    email: 'Email',
    birthday: 'Birthday',
    personalIdentification: 'Personal Identification',
    typeOfIdentity: 'Type of Identity',
    identifierNumber: 'Identifier Number',
    required: 'Please enter the required fields',
    success: 'Update successful ',
    fail: 'Update failed, we are notified about this issue ',
    callHistory: 'Activity histories',
    transactionHistory: 'My transactions',
    detailTransaction: 'Transaction details',
    subCallHistory: 'Calls you have been on',
    emptyCallHistory: 'No calls yet',
    listCard: 'Your cards',
    setting: 'Settings',
    subSetting: 'Set language, favorites, sign out',
    changePassword: 'Change Password',
    changeCategory: 'Set Favorites',
    language: 'Language',
    currentPasswordLabel: 'Current Password',
    oldPasswordLabel: 'Current Password',
    newPasswordLabel: 'Enter a new password',
    confirmPasswordLabel: 'Confirm Password',
    passwordHelper: 'Password for security and account login',
    passwordDescription1: 'Password must have at least 8 characters',
    passwordDescription2: 'Password must include numbers, lowercase and uppercase characters',
    next: 'Next',
    done: 'Done',
    update: 'Update',
    voucherLabel: "Don't miss the attractive offers from Unitz",
    share: 'Share App',
    subShare: 'Recommend Unitz to friends',
    inviteMessage: 'Download the app and learn together with me!',
    subjectEmail: 'Invitation to join Unitz app',
    studySchedule: 'Study schedule',
    yourStudySchedule: 'Your schedule',
    cardDetail: 'Card Details',
    unlink: 'Unlink a card',
    labelSelectUser: 'Select a reviewer',
    changeAvatar: 'Change avatar',
  },
  CallHistory: {
    detailTxt: 'Call details',
    timeCall: 'Time',
    advisor: 'Advisor',
    course: 'Course',
    category: 'Category',
    typeCall: 'Type of call',
    packageName: 'Package call',
    totalAmount: 'Price',
    timeDuration: 'Duration',
    review: 'Rate and comment',
    roomRegister: 'Registered sessions',
    status: {
      processing: 'Processing',
      completed: 'Completed',
      canceled: 'Canceled',
      declined: 'Declined',
    },
  },
  UserWallet: {
    deposit: 'Add money',
    voucher: 'Enter voucher',
    giftLabel: '10% bonus for top-ups of 1,000,000 vnd and more!',
    recentTransaction: 'Recent Transactions',
    payment: 'Payment',
    amountBalance: 'Your balance',
    seeAll: 'See all',
    walletHistory: 'Wallet History',
    walletWithdrawalHistory: 'Wallet withdrawal history',
    emptyWalletHistory: 'The wallet history is empty',
    status: {
      processing: 'Pending confirmation',
      completed: 'Confirmed',
      canceled: 'Cancelled',
      declined: 'Declined',
    },
    packageName: '%{time} minutes package',
    topUp: 'Add coin from',
    accountCardInternational: 'Debit or Credit Card',
    addNew: 'Add New card',
    linkBankNow: 'Link bank account ',
  },
  SettingLanguage: {
    vn: 'Tiếng Việt',
    en: 'English',
    vi: 'Tiếng Việt',
    de: 'German',
    restartMessage: 'Unitz app will automatically restart to update the language.',
    restart: 'Restart',
    hintChangeLanguage: 'Would you like to change the default language?',
    title: 'Confirm',
    yes: 'Yes',
    no: 'No',
  },
  FilterAdvanced: {
    category: 'Category',
    price: 'Price',
    rate: 'Rate',
    service: 'Type of call',
    favorite: 'Favorites',
    reset: 'Reset',
    done: 'Done',
    filter: 'Filter',
    liked: 'Liked',
    notLiked: 'Not liked yet',
    all: 'All',
    serviceType: {
      video: 'Video call',
      voice: 'Voice call',
      phone: 'Phone call',
      chat: 'Chat',
      all: 'All',
    },
    statusTxt: 'Status',
    status: {
      online: 'Online',
      offline: 'Offline',
      all: 'All',
    },
    sessionDuration: 'Session duration',
    sessionDurationTxt: '%{time} minutes',
    pricingType: {
      per_session: 'Session',
      per_course: 'Course',
    },
    sessionOccurence: {
      unlimited: 'Unlimited sessions',
      limited: 'Limited sessions',
    },
    numberOfSession: 'Number Of Session',
    tuition: 'Tuition',
  },
  HelpCenter: {
    titleTxt: 'Help Center',
    recentQuestion: 'Frequently Asked Questions',
    seeAll: 'See all',
    problemWith: 'I have a problem with',
    account: 'Account',
    paymentService: 'Paying for calls',
    wallet: 'Unitz wallet',
    advisor: 'Finding the right advisor',
    promotion: 'Promotion',
    otherProblem: 'Other problems',
    helpCustomer: 'Customer Care',
    infoDesc: 'Is this information helpful for you?',
    send: 'Send',
    request: 'Request help',
    sendRequest: 'Send request',
    requestTitle: 'Please help me with',
    requestDesc: 'Please write your question',
    imageOption: 'Image (optional)',
    suggest: 'Make a suggestion to Unitz',
    whatProblem: 'What is your issue?',
    sendSuccess: 'Review submitted successfully',
    sendFail: 'Review submission failed',
    sendRequestSuccess: 'Successfully submitted help request',
    sendRequestFail: 'Failed to send request',
    note: 'Read our handbook that is carefully crafted for you!',
    learnMore: 'Learn more',
    homePage: 'Home Page',
    search: 'Search',
    searchingResult: 'Searching result...',
  },
  CategoryFavorite: {
    title: 'Favorites',
    otherCategory: 'Other categories',
    Save: 'Save',
    success: 'Favorites changed successfully',
    fail: 'Failed to change favorites',
  },
  New: {
    describe: 'Check here for updates on useful information, attractive promotions and new features',
  },
  RatingSuggestion: {
    label1: 'Very bad!',
    label2: 'Bad!',
    label3: 'Average!',
    label4: 'Good!',
    label5: 'Awesome!',
    title1: "Unitz regrets the experience you've had. Please share your concerns with us so we can address it. Thank you very much!",
    title2: 'What needs to be changed to improve your call experience?',
    title3: 'What needs to be changed to improve your call experience?',
    title4: 'What needs to be changed to improve your call experience?',
    title5: '',
    noise: ' Noise',
    reverb: 'Reverb',
    lossOfSound: 'Loss of sound',
    echo: 'Audio is repeating',
    distortion: 'The sound is distorted',
    interrupt: 'Audio interrupted',
    frozen: 'Video freezes',
    blur: 'Blurry video',
    poorColor: 'Poor video color',
    underexposed: 'Video is dark',
    lag: 'Video is laggy',
    notShowing: 'Video is not showing',
  },
  Register: {
    Register: 'Register',
    emailPlaceholder: 'example@domain.com',
    Step1: {
      title: 'Import email address',
      desc: 'Use "Account Name" to login to Unitz Advisor app',
    },
    Authentication: 'Authentication',
    AuthenEmailInfo: 'Open mailbox to verify email address',
    GuestMode: 'Discover',
    GuestMessage: 'Sign in as guest: you will be able to access the app but some features will be limited.',
    GuestLimitMessage: 'This feature is limited for guest users. You need to register a real account to use it.',
  },
  Favorite: {
    emptyList: 'No favorites yet',
    teacherEmptyList: 'No favorites yet',
  },
  AdvisorCourse: {
    emptyList: 'No new courses yet',
    roomActions: {
      startIn: 'Starts in %{count}',
      inProgress: 'In progress',
      endIn: 'Ends in %{count}',
      leave: 'Leave',
      enter: 'Enter',
      close: 'Close',
      autoJoin: 'Auto join room',
    },
    streamActions: {
      pin: 'Pin',
      unpin: 'Unpin',
      record: 'Record',
    },
    roomStatus: {
      completed: 'Completed',
    },
  },
  ReviewCall: {
    title: 'About your call',
    description: 'Please rate our app!',
    suggest: 'What could be better?',
    advisorReview: 'About your advisor',
    advisorReviewDesc: 'Please rate our advisor!',
    report: 'Report',
    textReview: 'Please share your thoughts',
    reportTxt: ' Tell us about your problems with your advisor. Unitz regrets this unexpected experience. Your report will be kept confidential. We hope you will continue to support Unitz!!',
    send: 'Send',
    reivew: 'Review',
  },
  UserNotification: {
    All: 'All ',
    UnSeen: 'Unseen',
    Seen: 'Seen',
    Delete: 'Delete',
    Types: {
      completed_call_user: 'Your %{packageName} call with %{userLink} is completed.',
    },
    notificationEmpty: 'Notifications empty',
    voice: 'voice',
    video: 'video',
    call: 'package',
  },
  LinkCard: {
    title: 'Link bank card',
    continue: 'Continue',
    success: 'Added a card successfully',
  },
  Voucher: {
    title: 'Gift voucher worth %{money}',
    expired: 'Expires %{date}',
    use: 'Use it now',
    voucherLabel: 'Enter voucher code',
    voucherContentLabel: 'Please enter voucher code',
    codePlaceholder: 'UZ-XXXXX',
    redeem: 'Exchange now',
    RedeemStatus: {
      titleSuccess: 'Successfully redeemed coupon',
      titleFail: 'Coupon redemption failed',
      success: 'The gift voucher %{money} has been successfully added to the wallet.',
      fail: 'Coupon redemption failed.',
    },
    goBack: 'Back',
    tryAgain: 'Try again',
    Status: {
      available: 'Available',
      unavailable: 'Unavailable',
    },
  },
  Message: {
    repliesCountLabel: {
      one: '%{count} reply',
      other: '%{count} replies',
      zero: 'No replies',
    },
    repliesCollapseLabel: 'Collapse',
    replyActionLabel: 'Reply',
    replyingIndicatorLabel: ' replying...',
    messageStatus: {
      edited: '(edited)',
      removed: '(removed)',
    },
    tabPaneLabels: {
      rooms: 'Chat box',
      threads: 'Threads',
      mentions: 'mentions',
    },
    messageActions: {
      edit: 'Edit message',
      remove: 'Remove message',
      reply: 'Reply message',
      pin: 'Pin message',
      setAsTopic: 'Set as topic',
      setAsQuestion: 'Set as question',
    },
    newMessageAlertLabel: {
      one: 'You have one new message',
      other: 'You have %{count} new messages',
    },
    hints: {
      EnterToSend: 'Press Enter to send and Shift + Enter to add a new line',
    },
  },
  Conversation: {
    allConversation: 'All conversations',
    advice: 'Please advise me about this course.',
  },
  ReportIssue: {
    success: 'Call report sent to Unitz',
    fail: 'Failed to send call report to Unitz, please try again !',
  },
  ErrorMsg: {
    Default: 'An error occurred, please try again !',
  },
  AppCalendar: {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    today: 'Today',
  },
  LearnSchedule: {
    goToClass: 'Enter Room',
    offClass: 'Leave Room',
    title: 'Schedule',
    detail: 'Lesson details',
    upcoming: 'Upcoming courses',
    doneCourse: 'Finished courses',
    emptyList: 'Course list is empty',
  },
  RoomChat: {
    Title: 'Chat room',
    Actions: {
      Edit: 'Edit message',
      Mark: 'Mark message',
      Reply: 'Reply message',
      Copy: 'Copy message',
      Delete: 'Delete message',
    },
    ReplyToUser: 'Answering %{userName}',
    SeeComment: 'View reply',
    Rooms: 'Rooms',
    Threads: 'Threads',
    Mentions: 'Mentions',
    DayOf: 'Lesson %{day}',
    ListLesson: 'List of lessons',
    Leave: 'Leave room',
    Participant: 'Participant',
    Advisor: 'Advisors (%{num})',
    Student: 'Students (%{num})',
    Hosts: 'Hosts (%{num})',
    Participants: 'Participants (%{num})',
  },
  SearchCourse: {
    placeHolder: 'Find your course',
    result: '%{num} courses found for you',
    emptyList: 'Find your course',
    notResult: 'No suitable courses',
  },
  DateFormat: {
    Default: 'll',
    FullDisplay: 'LT LL',
  },
  CurrencyFormat: {
    vnd: 'đ',
  },
  AdvisorScheduleBooking: {
    type: 'Package',
    selectPackage: 'Select package',
    next: 'Next',
    SetSchedule: 'Set Schedule',
    timeLine: 'Time',
    schedulePresent: 'Upcoming schedule',
    list: 'List schedule',
    detail: 'Schedule details',
    callWith: 'Package %{service} with',
    time: '%{time} minutes',
    timeNote: 'The consultation room will be opened 5 minutes before the hour. Be sure to be there on time!',
    startCall: 'Start call',
    service: 'Package List',
    serviceEmpty: 'Advisor does not provide this service package, please choose another package',
    scheduleEmpty: 'Schedule empty',
    timeEmpty: 'Advisor has not made this booking time available, please choose another date!',
    cancelSchedule: 'Cancellation of schedule',
    cancelDescUser: 'You can only cancel an appointment 1 minute before the start time. After canceling the appointment, the money will be refunded to your Unitz wallet.',
    confirm: 'Confirm',
    back: 'Back',
    cancelScheduleSuccess: 'Successfully canceled appointment !',
    cancelScheduleFail: 'Schedule cancellation failed, please try again!',
    cancelDescAdvisor: 'Are you sure you want to cancel this appointment? Tuition fees will be refunded to your Unitz wallet.',
    status: {
      confirmed: 'Confirmed booking',
      completed: 'Completed booking',
      missed: 'Missed booking',
      canceled: 'Canceled booking',
    },
    upcoming: 'Upcoming',
    booked: 'Saved',
    add: 'Add',
    reCall: 'Call Advisor',
    reCalling: 'Calling to Advisor',
    cancelScheduleInvalid: 'You cannot cancel an appointment once the call has started.',
  },
  Whiteboard: {
    toolbar: {
      markerTooltip: 'Marker',
      shapeTooltip: 'Shape',
      stickyNoteTooltip: 'Sticky note',
      pictureTooltip: 'Image',
      moreTooltip: 'More',
      moveTooltip: 'Move tool',
      undoTooltip: 'Undo',
      redoTooltip: 'Redo',
      dragTooltip: 'Hand tool',
      hideTooltip: 'Hide blackboard',
      showTooltip: 'Show blackboard',
      eraseTooltip: 'Erase',
      eraserTooltip: 'Eraser',
      zoomoutTooltip: 'Zoom out',
      zoominTooltip: 'Zoom in',
      textTooltip: 'Text',
      authorTooltip: 'Show/hide author',
      textPlaceholder: 'Add text',
    },
  },
  CourseDetail: {
    summary: 'Summary',
    detail: 'Detail',
    schedule: 'Schedule',
    note: 'Note',
    file: 'File',
    support: 'Support',
    review: 'Review',
    timeNote: 'Class will be open for entry 5 minutes before class time. Be sure to show up on time!',
    coursePresent: 'Courses in progress',
    download: 'Download',
    downloadSuccess: 'File %{file} downloaded successfully!',
    payment: 'Payment',
    refund: 'Refund',
    statusLesson: 'Lesson status',
    viewcourse: 'View courses',
    benefits: 'What students will learn',
    purchase: {
      limited: 'Bạn đã mua {{purchase_session}} buổi. Hiện đang còn {{no_learn_session}} buổi chưa học và sẽ kết thúc vào {{end_time}}.',
      unlimited: 'Bạn đang còn {{no_learn_session}} buổi chưa học và sẽ kết thúc vào {{end_time}}.',
    },
  },
  Audio: {
    connect: 'Connecting audio ...',
  },
  AdvisorCalendar: {
    Tab: {
      booking: 'My booking',
      course: 'My course',
    },
    courseEmpty: 'List course empty',
    form: {
      fullTime: 'Full day',
      beginTime: 'Begin time',
      endTime: 'End time',
      delete: 'Delete range time',
      message: 'This range time has been overlapped, please check again !',
      timeValid: 'End time must be greater than start time, please check again!',
    },
  },
  SEO: {
    Actions: {
      add: 'Add SEO Link',
      submit: 'Submit',
    },
    Form: {
      newSEOLink: 'New SEO Link',
      originalPath: 'Original Path',
      SEOPath: 'SEO Path',
      siteTitle: 'Site Title',
      siteDescription: 'Site Description',
      siteImage: 'Image for the page',
      SEOType: 'SEO Type',
    },
    Table: {
      originPath: 'Origin link',
      SEOPath: 'SEO Link',
      title: 'Title',
      description: 'Description',
      image: 'Avatar',
      type: 'Type',
    },
    manageLinks: 'Manage Links',
    linkDetail: 'SEO Link Detail',
    linkEdit: 'SEO Link Edit',
  },
  UserCalendar: {
    Tab: {
      booking: 'My booking',
      course: 'My courses',
    },
  },
  RemoteConfig: {
    Booking: {
      Package: 'Package %{package} - %{time}',
      AdvisorBookingPaid: {
        title: 'You got %{amount} for the %{kind} call with %{user}.',
      },
      AdvisorBookingCancel: {
        title: 'The appointment with %{user} has been cancelled.',
      },
      AdvisorBookingCompleted: {
        title: 'You have completed your appointment with %{user}.',
      },
      AdvisorBookingConfirmed: {
        title: 'You have a new appointment with %{user}.',
      },
      AdvisorBookingReminder: {
        title: 'Appointments with %{user} will start in %{diffMin} minutes.',
      },
      UserBookingCancel: {
        title: 'The appointment with %{advisor} has been cancelled.',
      },
      UserBookingCompleted: {
        title: 'You have completed your appointment with %{advisor}.',
      },
      UserBookingReminder: {
        title: 'Appointments with %{advisor} will start in %{diffMin} minutes.',
      },
    },
    Course: {
      AdvisorCoursePaid: {
        title: 'Course %{course} completed.',
        body: 'You get %{amount}.',
      },
      AdvisorCoursePurchase: {
        title: '%{user} bought the course %{course}.',
      },
      AdvisorCourseRefund: {
        title: 'Notice of cancellation of the course',
        body: '%{user} unsubscribe from the course %{course}.',
      },
      Purchase: {
        session: 'Pay %{amount} for %{per_amount} sessions',
        full_session: 'Full package: %{amount}',
        per_session: '%{session} session',
        full_session_txt: 'Full package',
        sessionOccurence: {
          unlimited: 'Unlimited sessions',
          limited: 'Limited sessions',
        },
        sessionCount: {
          zero: '0 sessions',
          one: '1 session',
          other: '%{count} sessions',
        },
      },
      UserCoursePurchase: {
        title: 'You have purchased the course %{course}.',
      },
      UserCourseRefund: {
        title: 'Course refund %{course}.',
        body: 'Unitz has refunded you %{amount} to your wallet.',
      },
      AdvisorCoursePostpone: {
        title: 'Announcement to postpone the opening date',
        body: 'Your course %{course} has been rescheduled to %{start_at}',
      },
      UserCoursePostpone: {
        title: 'Announcement to postpone the opening date',
        body: 'Your course %{course} has been rescheduled to %{start_at}',
      },
    },
    Room: {
      UserRoomRefund: {
        title: 'The class %{course} is not taking place.',
        body: 'Unitz has refunded you %{amount} to your wallet.',
      },
      UserRoomConnect: {
        title: 'Class %{course}',
        body: 'Class Started - Duration %{duration}.',
      },
      UserRoomReminder: {
        title: 'Class %{course}',
        body: 'Class will start in %{diffMin} minutes.',
      },
      UserRoomReschedule: {
        title: 'Reschedule class time %{course}',
        body: 'Class will start at %{time}',
      },
      AdvisorRoomConnect: {
        title: 'Class %{course}',
        body: 'Class Started - Duration %{duration}.',
      },
      AdvisorRoomPaid: {
        title: 'The class %{course} is complete',
        body: 'You get %{amount}',
      },
      AdvisorRoomRefund: {
        title: 'The class %{course} is not taking place.',
        body: 'You do not receive tuition from the class.',
      },
      AdvisorRoomReminder: {
        title: 'Class %{course}',
        body: 'Class will start in %{diffMin} minutes.',
      },
      AdvisorRoomReschedule: {
        title: 'Reschedule class time %{course}',
        body: 'Class will start at %{time}',
      },
      student: {
        zero: '0 students',
        one: '1 student',
        other: '%{count} students',
      },
    },
    DemoCourse: {
      AdvisorDemoCourseRequest: {
        title: 'Request a trial lesson',
        body: 'You have received a request for a trial lesson from %{user}',
      },
      UserDemoCourseConfirm: {
        title: 'Request for a trial lesson is approved',
        body: 'Your request to try the course %{course} has been approved.',
      },
      UserDemoCourseReject: {
        title: 'Request for a trial lesson has been denied',
        body: 'Your request for a trial course %{course} has been denied.',
      },
    },
    TrialCourse: {
      AdvisorTrialCourseRequest: {
        title: 'Request a trial lesson',
        body: 'You have received a request for a trial lesson from %{user}',
      },
      UserTrialCourseConfirm: {
        title: 'Request for a trial lesson is approved',
        body: 'Your request to try the course %{course} has been approved.',
      },
      UserTrialCourseReject: {
        title: 'Request for a trial lesson has been denied',
        body: 'Your request for a trial course %{course} has been denied.',
      },
    },
    Purchase: {
      UserPurchaseRemind: {
        title: 'Lesson payment reminder',
        body: 'It’s about the billing date of your %{course} course. Pay now for your consecutive course!',
      },
    },
    Chat: {
      UserChatReminder: {
        title: 'You have a new message from teacher',
        body: 'Please open the web browser on your computer or tablet to view the message. We are working hard to bring Chat support to app.',
      },
      AdvisorChatReminder: {
        title: 'You have a new message from student',
        body: 'Please open the web browser on your computer or tablet to view the message. We are working hard to bring Chat support to app.',
      },
    },
    Email: {
      user_contacts: [
        {
          title: 'Unitz community',
          name: 'UNITZ COMMUNITY',
          url: 'https://www.facebook.com/groups/unitz.community',
        },
        {
          title: 'Fanpage',
          name: 'UNITZ APP',
          url: 'https://www.facebook.com/UnitzApp',
        },
        {
          title: 'Zalo',
          name: 'UNITZ Co., Ltd',
          url: 'https://zalo.me/2510053737522180594',
        },
        {
          title: 'Email',
          name: 'support@unitz.app',
          url: 'mailto:support@unitz.app',
        },
        {
          title: 'Website',
          name: 'https://unitz.app',
          url: 'https://unitz.app',
        },
        {
          title: 'Phone number',
          name: '0856 507 095 (Ms. Trang)',
        },
      ],
      advisor_contacts: [
        {
          title: 'Unitz community',
          name: 'UNITZ COMMUNITY',
          url: 'https://www.facebook.com/groups/unitz.community',
        },
        {
          title: 'Fanpage',
          name: 'UNITZ APP',
          url: 'https://www.facebook.com/UnitzApp',
        },
        {
          title: 'Zalo',
          name: 'UNITZ Co., Ltd',
          url: 'https://zalo.me/2510053737522180594',
        },
        {
          title: 'Email',
          name: 'supplier@unitz.app',
          url: 'mailto:supplier@unitz.app',
        },
        {
          title: 'Website',
          name: 'https://advisor.unitz.app',
          url: 'https://advisor.unitz.app',
        },
        {
          title: 'Phone number',
          name: '0856 507 095 (Ms. Trang)',
        },
      ],
      advertisementForm: '- %{courseName} taught by %{teacher}. Starts on %{startAt} at %{startTime} GMT+7 (Hanoi time)',
    },
    Call: {
      Package: 'Package %{package}',
      AdvisorCallPaid: {
        title: 'You got %{amount} for the %{kind} call with %{user}.',
      },
      AdvisorCallReminder: {
        title: 'You have received a payment for a call with %{user}, please turn on the app and be ready for the call',
      },
    },
    RATCourse: {
      AdvisorRATCourseRequest: {
        title: 'Request another time',
        body: 'You have received a request for another time from %{user}',
      },
      UserRATCourseRequest: {
        title: 'Request another time',
        body: 'Your request for another time for this course %{course} has been sent.',
      },
      UserRATCourseConfirm: {
        title: 'Request for another time for this course has been approved',
        body: 'Your request for another time for this course %{course} has been approved.',
      },
      UserRATCourseReject: {
        title: 'Request for another time for this course has been denied',
        body: 'Your request for another time for this course %{course} has been denied.',
      },
    },
  },
  DemoCourse: {
    Accept: 'Accept',
    Reject: 'Reject',
  },
  RequestFreeTrialLesson: {
    categoryListing: 'Choose a field you want to master',
    targetUser: 'Who is the course aimed at?',
    learningLevel: 'What is your current level of study?',
    requestTime: 'Choose a time that works for you',
    nextBtn: 'Next',
    registrationForm: 'How can the instructor contact you?',
    registration: {
      studentInfo: 'Student data',
      studentFirstName: 'Last name',
      studentLastName: 'First name',
      studentBirthday: 'Date of birth',
      studentNote: 'Note',
      accountInfo: 'Thông tin tài khoản',
      accountName: 'Full name',
      accountEmail: 'Email',
      accountPhone: 'Phone number',
      accountPassword: 'Password',
      accountRePassword: 'Repeat Password',
      agreeWith: 'I agree to the ',
      termAndCondition: 'terms and conditions',
      and: 'and',
      requestBtn: 'Request a free trial lesson',
      privacy: ' privacy policy',
    },
    validation: {
      studentFirstName: 'This field is required',
      studentLastName: 'This field is required',
      studentBirthday: 'This field is required',
      studentNote: 'This field is required',
      accountName: 'This field is required',
      accountEmail: 'This field is required',
      accountPhone: 'This field is required',
      accountPassword: 'This field is required',
      accountRePassword: 'This field is required',
    },
    verifyEmail: 'Verify your email address',
    verify: {
      title: 'The verification code has been sent to your\nemail address.',
      actionTxt: 'Verification code has been sent',
      description: "If you haven't received the e-mail, please check your spam folder.\n" + "      If you are sure you haven't received an e-mail, use the form below to get the e-mail sent again.",
    },
  },
  EducationTabbar: {
    Home: 'Home',
    Category: 'Discover',
    Course: 'Course',
    Account: 'Account',
    Calendar: 'Calendar',
  },
  Explorer: {
    courseEmpty: 'There are no courses yet',
    filter: 'Filter',
    reset: 'Clear Filters',
    rating: 'Ratings',
    ratingDesc: 'Mức đánh giá',
    tuition: 'Price',
    applyFilter: 'Apply',
    listCourse: 'List of courses',
  },
  EducationCourse: {
    myCourse: 'My Courses',
    featureCourse: 'Featured courses',
    viewCourse: 'Students are viewing',
    latestCourse: 'Newest courses',
    favorite: 'Wishlist',
    Sort: 'Sort',
    SortBy: {
      All: 'All',
      Upcomming: 'Upcoming',
      OnProgress: 'In Progress',
      Finished: 'Finished',
    },
  },
  TeacherProfile: {
    Category: 'Teaching categories',
    Chat: 'Chat now!',
  },
  CourseInfo: {
    All: 'All',
    studySchedule: 'My calendar',
    registerTrialCourse: 'Register for a trial lesson',
    detail: 'See details',
    enrollNow: 'Enroll',
    retrialCourseSuccess: 'Request for a trial lesson has been sent successfully',
    retrialCourseSuccessMessage: 'Your trial request has been sent successfully. The teacher will respond as quickly as possible.',
    retrialCourseError: 'Your trial request failed',
    retrialCourseErrorMessage: 'Your trial request failed, please try again',
    goHome: 'Home',
    myCalendar: 'My calendar',
  },
  ProfileStudent: {
    calendar: 'Schedule',
    subCalendar: 'Time for upcoming lesson',
    message: 'Message',
    subMessage: 'Messages with teachers',
    notes: 'Notifications from the system',
  },
  MessageChat: {
    actions: {
      title: 'Action',
      Edit: 'Edit message',
      Remove: 'Remove message',
    },
  },
  Subscriber: {
    Modal: {
      title: 'Find your ideal course with ease!',
      sub_title: "Leave information below and we'll assist you in finding your suitable course",
      your_message: 'Your messages',
    },
    Button: {
      title: 'Submit',
    },
    Notification: {
      success: 'You have successfully registered.',
    },
  },
  AboutUs: {
    message_success: 'Thank you for contating us! We will get back to you within 3 working days.',
  },
  RoomCall: {
    endCall: 'End of class',
    timeLeft: 'Your class time is still left',
    continueCall: 'Still want to finish the lesson ?',
    chatDelay: 'The functionality works best in the browser, we will be rolling out the Chat feature in the app soon.',
    okText: 'OK',
    chatWithTeacher: 'Chat with teachers now',
    waitingAdvisorNote: 'You are waiting to connect with consultant %{advisor}. This is a %{package}.\n' + '\n' + "    Now you have the first 3 minutes free, after 3 minutes the call will start to be charged. If the Expert doesn't come in, you will get a refund for this package.",
    waitingUserNote: 'You are waiting to connect with your customer. This is a %{package}.\n' + '\n' + '    Users are free for the first three minutes, after 3 minutes the call will start to be charged. You need to join the call more than three minutes and longer than the user, you will get money for this package.',
  },
  TimezoneSetting: {
    notfound: 'No search results found.',
    textSearch: 'Type to search...',
  },
  RequestPermission: {
    Allow: 'Allow',
    Later: 'Later',
    Notification: {
      header: 'Unitz would like to send you notifications to:',
      content: {
        require_1: 'Make sure that you don’t miss any calls',
        require_2: 'Get notifications from your experts instantly',
        require_3: 'Quickly get payment information and refunds',
      },
      message: 'Notifications may include alerts, sounds and icon badges. This can be configured in Settings',
    },
    Camera: {
      header: 'Please allow apps to access your Camera to:',
      content: {
        require_1: 'Meet your expert in video call ',
        require_2: 'Ensure conversation with better experience',
        require_3: 'Make sure your Expert can see you to give you the most accurate advice if you are in a beauty consulting service',
      },
    },
    Voice: {
      header: 'Please allow apps to access your Microphone to:',
      content: {
        require_1: 'Make sure that your Expert can hear you clearly ',
        require_2: 'Make the conversation flow more smoothly',
        require_3: 'Ensure you are having the best experience',
      },
    },
  },
  RequestApproval: {
    note: 'Please wait a moment, we will set up a consulting account for you...',
    approvalSuccess: 'The consulting account has been set up!',
  },
  AdvisorOnboarding: {
    category: 'Category',
    basicInfo: 'Fill in basic information',
    basicInfo_2: 'Update personal information',
    infoDescription: 'The information below is public in nature, will be visible to your customers.',
    infoDescription_2: 'Complete your profile by updating information regarding experience, qualifications, and certifications',
    intro_text_example: 'Example: Over 10 years of experience in the field of meditation, transmission of life energy.',
    detail_text_example: 'Example: I have over 10 years of experience in meditation and methods for better mental health. If you want to share tips...',
    experience_example: 'Example: Over 10 years of experience in counseling psychology.',
    certificates_example: "Example: Graduated with a master's degree in psychology from Ho Chi Minh City University of Education",
    education_example: "Example: Graduated with a master's degree in psychology from Ho Chi Minh City University of Education",
    setupPackage: 'Set up consulting call',
    setupPackageDesc: 'Setting price information,\ncall time per package.',
    setupPrice: 'Price package for call %{package}',
    createAccount: 'Create an account now and start connecting with thousands of customers waiting for your consultation. ',
  },
  ZoomToolMenu: {
    Home: 'Home',
    ProgramsTemplates: 'Create program',
    Programs: 'All programs',
    CourseTemplates: 'Create course',
    Courses: 'All courses',
    Requests: 'Requests',
    Admins: 'Admins',
    Teachers: 'Teachers',
    Students: 'Students',
    Calendar: 'Calendar',
    MyAccount: 'My Account',
    Notifications: 'Notifications',
    Settings: 'Settings',
  },

  ZoomToolOrganization: {
    organization: 'Organization',
    create: 'Create organization',
    settings: 'Organization settings and subscriptions',
  },

  ZoomToolDashboard: {
    ongoing: 'Ongoing',
    upcoming: 'Upcoming',
    finished: 'Finished',
    details: 'Details',
    number_of_students: 'Total number of students',
    number_of_teachers: 'Total number of teachers',
    number_of_courses: 'Total number of courses',
    alert: {
      message1: 'Welcome to Unitz',
      message2: 'This free demo of Unitz expires in 13 days. To continue, get your membership today!',
    },
    course: {
      zero: '0 courses',
      one: '1 course',
      other: '%{count} courses',
    },
    student: {
      zero: '0 students',
      one: '1 student',
      other: '%{count} students',
    },
    attendance: 'Attendance',
    student_performance: 'Student Performance',
    requests: 'Requests',
    empty_request: 'No requests yet',
    empty_calendar: 'There are no lessons taking place today',
    total: 'Total',
  },
  ZoomToolProgram: {
    title: 'PROGRAM LIST',
    empty_text1: 'Let’s begin here! A program is made up of multiple courses.',
    empty_text2: 'Start by adding a new program.',
    empty_text3: 'Don’t want to group multiple courses into programs? You can skip this concept and go to Settings to hide program.',
    add_form_title: 'CREATE A NEW PROGRAM',
    edit_form_title: 'EDIT PROGRAM',
    success_title: 'Your program has been created successfully',
    success_sub_title: '',
    course_templates: 'Programs',
    course: 'Course',
    Detail: {
      about_title: 'About this program',
      courses: 'Courses',
    },
    Form: {
      name: 'Program name',
      name_input_placeholder: 'Enter Programe name (1 - 100 chars)',
      courses: 'Courses',
      description: 'Description',
      description_input_placeholder: 'Enter the description',
      add_course: 'Add Course',
      course: {
        zero: '0 courses',
        one: '1 course',
        other: '%{count} courses',
      },
      selected_course_template: 'Selected course',
      search_label1: 'Search existing course',
      search_label2: '',
      search_placeholder: '',
    },
    Action: {
      add_program: 'Create a new Program',
      add_program_from_template: 'Create new program from existing ones',
      add_course_template: 'Add a course',
      start: 'Start',
      use: 'Use',
      remove: 'Remove',
      edit: 'Edit',
      start_program_now: 'Start this program now',
    },
    selected_course_template: 'Selected course',
    create_course_state_title: 'Start by adding a new course',
    empty_course_state_title: 'No course templates yet.',
  },
  ZoomToolProgramTemplate: {
    title: 'PROGRAM LIST',
    empty_text1: 'Let’s begin here! A program is made up of multiple courses.',
    empty_text2: 'Start by adding a new program.',
    empty_text3: 'Don’t want to group multiple courses into programs? You can skip this concept and go to Settings to hide program and program templates.',
    add_form_title: 'CREATE A NEW PROGRAM',
    edit_form_title: 'EDIT PROGRAM',
    success_title: 'The program has been created successfully',
    success_sub_title: 'You can view, edit and use this program in your',
    course_templates: 'Program Templates',
    course: 'Course',
    Detail: {
      about_title: 'About this program',
      courses: 'Courses',
      course_detail: 'Course detail',
    },
    Form: {
      name: 'Program name',
      courses: 'Courses',
      description: 'Description',
      add_course: 'Add Course',
      course: {
        zero: '0 courses',
        one: '1 course',
        other: '%{count} courses',
      },
      selected_course_template: 'Selected course',
      search_label1: 'Existing course',
      search_label2: 'Search',
      search_placeholder: '',
    },
    Action: {
      add_course_template: 'Create a new Course',
      add_program_template: 'Create a new Program',
      start: 'Start',
      use: 'Use',
      remove: 'Remove',
      edit: 'Edit',
      start_program_now: 'Start this program now',
    },
    selected_course_template: 'Selected course',
    create_course_state_title: 'Start by adding a new course',
    empty_course_state_title: 'No course templates yet.',
  },
  ZoomToolTeacher: {
    empty_text: 'Start by adding a teacher',
    report_title: 'Teacher report',
    send_mail_title: 'Send Teacher Report',
    edit_teacher_title: 'Edit teacher',
    edit_teachers_title: 'Edit teachers',
    Table: {
      title: 'TEACHERS',
      teacher: 'Teacher',
      assistant: 'Teaching assistant',
      type: 'Employment Type',
      course_in_progress: 'Courses in progress',
      lesson_in_progress: 'Lesson in progress',
      attendance: 'Absences/Tardies/Early Dismissal',
      status: 'Status',
      add_to_course: 'Add to course',
      active: 'Active',
      inactive: 'Inactive',
    },
    Attendance: {
      present: 'Present',
      absent: 'Absent',
      late: 'Late',
      early_dismissed: 'Early Dismissed',
    },
    Action: {
      view_report: 'View Report',
    },
  },
  ZoomToolStudent: {
    empty_text: 'Start by adding a student',
    add_student_title: 'Add Student',
    report_title: 'Student report',
    send_mail_title: 'Send Student Report',
    edit_student_title: 'Edit student',
    edit_students_title: 'Edit students',
    Table: {
      title: 'STUDENTS',
      student: 'Full name',
    },
    Action: {
      add_student: 'Add a student',
    },
  },
  ZoomToolAccount: {
    title: 'ACCOUNTS',
    create_organization: 'CREATE NEW ORGANIZATION',
    edit_organization: 'ORGANIZATION INFORMATION',
    Form: {
      organization_id: 'Organization ID',
      organization_name: 'Organization name',
      logo: 'Logo',
      add_logo: 'Add logo here',
      holiday_title: 'Holidays',
      holiday_description: 'Pick your holiday calendar. This will automatically apply to all timetables generated on the platform.',
      holiday_label: 'Holiday calendar',
      country: 'Country',
      city: 'City',
      state: 'State',
    },
    ListTable: {
      name: 'Name',
      actions: 'Actions',
    },
    Action: {
      save: 'Save',
      new_account: 'New account',
      create_organization: 'CREATE ORGANIZATION',
    },
  },
  ZoomToolCourse: {
    title: 'COURSE LIST',
    course_report: 'Course report',
    course: 'Course',
    add_form_title: 'CREATE YOUR COURSE',
    edit_form_title: 'EDIT THE COURSE',
    empty_text1: 'Start by adding a new course.',
    empty_text2: `You don't have a course yet. Please click the "Add course" to create more course.`,
    success_title: 'You have successfully started the course:',
    success_sub_title: '',
    course_type: 'Online or Offline',
    minute_per_lesson: '%{minute} mins / a session',
    name: 'Name',
    type: 'Type',
    edit_lesson_title: 'Edit Lesson',
    number_of_hours: 'Number of hours',
    number_of_students: 'Number of students',
    number_of_lessons: 'Number of lessons',
    UseTemplate: {
      title: 'START THE COURSE',
      choose_template_title: 'CHOOSE FROM A COURSE',
    },
    teacher: 'TEACHERS',
    Form: {
      search_placeholder: 'Search the course name',
      name: 'Course name',
      teacher: 'Teachers',
      student: 'Students',
      duration: 'Duration',
      course_type: 'Online or Offline',
      start_date_placeholder: 'Please select',
      time: 'Enter course schedule',
      lesson_time: 'Lesson time',
      online: 'Online',
      offline: 'Offline',
      minutes: 'mins',
      session_unit: '/ a session',
      select_time: 'Select time',
      add_lesson_time_manually: 'Add lesson time manually',
      add_photo: 'Add photo',
      drop_photo: 'Drop a photo here',
      select_date: 'Select date',
      select_time: 'Select time',
      holiday_checked: 'Holiday calendar for %{region} is applied.',
      holiday_unchecked: 'Tick here to apply holiday calendar for %{region}',
      skip_note: 'Skip lesson on this date',
      skipped_note: 'The following lesson is skipped: %{old_date}. The new lesson is on %{new_date}',
    },
    Table: {
      name: 'Course Name',
      teacher: 'Teachers',
      student: 'Students',
      student_number: 'Student number',
      email: 'Email',
      type: 'Type',
      startDate: 'Start date',
      lesson_progress: 'Lesson Progress',
      actions: 'Actions',
    },
    Action: {
      add_course_from_template: 'Create new course from existing ones',
      add_course: 'Create a new course',
      add_more_schedule: 'Add more schedule',
      add_teacher: 'Add teacher',
      add_assistant: 'Add teaching assistant',
      add_student: 'Add students',
      add_a_teacher: 'Add a teacher',
      add_a_student: 'Add new student',
      next: 'Add Schedule',
      create_course_from_scratch: 'Create a new course from scratch',
      use_this_template: 'Use this course',
      back: 'back',
      chat_room: 'Chat room',
      view_course: 'View course',
      edit_course: 'Edit course',
      view_report: 'View report',
      edit_student: 'Edit Students',
      edit_attendance: 'Edit Attendance',
      edit_teachers: 'Edit Teachers',
      done: 'Done',
      enroll_student: 'Enroll students to course',
      manage_all_courses: 'Manage all courses',
      join_room: 'Go to class',
      auto_update_lesson_time: 'Update remaining lessons',
    },
    Notification: {
      edit_lesson_success: 'Edit lesson information successfully',
    },
    Validation: {
      lesson_time: 'The selected time is in the past . Please choose another time',
    },
    Status: {
      all: 'All',
      upcoming: 'Upcoming',
      draft: 'Upcoming',
      ongoing: 'Ongoing',
      completed: 'Finished',
    },
    Report: {
      hour: {
        zero: '0 hours',
        one: '1 hour',
        other: '%{count} hours',
      },
      minute: {
        zero: '0 minutes',
        one: '1 minute',
        other: '%{count} minutes',
      },
      second: {
        zero: '0 seconds',
        one: '1 second',
        other: '%{count} seconds',
      },
      instantly: 'Instantly',
    },
  },
  ZoomToolCourseTemplate: {
    title: 'COURSE LIST',
    add_form_title: 'CREATE A NEW COURSE',
    edit_form_title: 'EDIT COURSE',
    empty_text1: 'Start by adding a new course.',
    empty_text2: `You don't have a course yet. Please click the "Add course" to create more course templates.`,
    success_title: 'The course has been created successfully',
    success_sub_title: '',
    course_templates: '',
    lesson: 'Lesson',
    confirm_edit_title: 'You have unsaved changes',
    confirm_edit_description: 'Do you want to save this course as a draft?',
    confirm_delete_title: 'Delete the course',
    confirm_delete_description: 'Do you want to delete this course? Your changes will be discarded.',
    Detail: {
      about_title: 'About this course',
      materials: 'Materials',
      lessons: 'Lessons',
      see_more: 'See more',
      see_less: 'See less',
    },
    Status: {
      hasCourse: 'The course has already been started at the following dates:',
      emptyCourse: 'The course has not been started yet',
    },
    Action: {
      add_template: 'Create a new Course',
      start_course: 'Start course',
      view: 'View',
      save: 'Save',
      delete: 'Delete',
      edit: 'Edit',
      back: 'Back',
      yes: 'Yes',
      cancel: 'Cancel',
      save_as_draft: 'Save as Draft',
      manage_all_templates: 'Manage all',
      start_this_course_now: 'Start this course now',
      duplicate: 'Duplicate',
    },
    Form: {
      name: 'Course name',
      lessons: 'Lessons',
      lesson_title: 'Lesson title',
      cover: 'Cover',
      cover_placeholder: 'Add a photo (16x9)',
      cover_hint: 'Only JPG/ PNG/ GIF/ BMP is supported',
      description: 'Description',
      material: 'Materials',
      material_hint: 'Only the following types are supported: *.doc, *.docx, *.pdf, *.pptx, *.mp3, *.jpeg, *.png, *.jpg',
      url_placeholder: 'Add YouTube/URL Link',
      add_link: 'Add URL link',
      done: 'Done',
      cancel: 'Cancel',
      add_lesson: 'Add Lesson',
      add_multi_lesson: 'Add Multiple Lessons',
      lesson: {
        zero: '0 lessons',
        one: '1 lesson',
        other: '%{count} lessons',
      },
    },
  },
  ZoomToolMember: {
    title: 'USERS AND ACCESS',
    add_user_button: 'Add a new User',
    start_date: 'Start date',
    empty_course_state_title: 'No course yet',
    empty_admin: 'Start by adding a admin.',
    Role: {
      account_holder: 'Account Holder',
      admin: 'Admin',
      teacher: 'Teacher',
      student: 'Student',
      student_manager: 'Student Manager',
      counselling: 'Counselling',
      quality: 'Quality',
    },
    Form: {
      search_label: 'Search',
      search_placeholder: '',
      role_label: 'Role',
      role_placeholder: '',
      status_label: 'Status',
      status_placeholder: '',
      clear_all_button: 'Clear all',
      send_to_label: 'Send to',
      send_to_me: 'Send to me',
      undefined: 'Undefined',
    },
    Add_Form: {
      form_name: 'Add User',
      first_name_label: 'First name',
      first_name_placeholder: 'Enter user first name',
      last_name_label: 'Last name',
      last_name_placeholder: 'Enter user last name',
      date_of_birth_label: 'Date of birth',
      date_of_birth_placeholder: 'YYYY-MM-DD',
      email_label: 'Email',
      email_placeholder: '',
      phone_label: 'Phone number',
      phone_placeholder: '+44 6525 4555289',
      photo_label: 'Photo',
      note_label: 'Note',
      note_placeholder: '',
      roles_label: 'Roles',
      Action: {
        add_user: 'Add User',
        cancel: 'Cancel',
        see_permissions: 'See Permissions',
        hide_permissions: 'Hide Permissions',
      },
      Notification: {
        add_success: 'Add member successfully',
        add_error: 'Somethings went wrong. Please check again your information!',
      },
      place_of_birth_label: 'Place of birth',
      place_of_birth_placeholder: 'Los Angeles, USA',
    },
    View_Form: {
      form_name: 'View User',
    },
    Edit_Form: {
      form_name: 'Edit User',
      Action: {
        edit_user: 'Edit User',
        cancel: 'Cancel',
      },
      Notification: {
        edit_success: 'Edit member successfully',
      },
    },
    Table: {
      number_order: 'No. ',
      full_name: 'Full name',
      phone_number: 'Phone',
      email: 'Email',
      date_of_birth: 'Date of birth',
      place_of_birth: 'Place of birth',
      role: 'Roles',
      status: {
        col_name: 'Status',
        type: {
          active: 'Active',
          disabled: 'Disabled',
        },
      },
      action: 'Actions',
      Action: {
        edit: 'Edit',
      },
    },
    Action: {
      export_to_pdf: 'Export to pdf',
      import_users: 'Import users from Excel',
      import_teachers: 'Import teachers from Excel',
      import_students: 'Import students from Excel',
      send_email: 'Send email',
      back: 'Back',
      add_email: 'Add email',
      cancel: 'Cancel',
      send: 'Send',
    },
    Excel: {
      import_user: 'Import users',
      import_teacher: 'Import teachers',
      import_student: 'Import students',
      description_1: 'Step 1: Download a sample Excel file to add in user’s data:: ',
      sample_file: 'sample.xls',
      description_2: 'Step 2: Fill in the information in the Excel file, save the Excel file',
      description_3: 'Step 3: Upload the Excel file into our system. Select the file for upload:',
      support: 'Only the following types are supported: .xls, .xlsx',
      Notifications: {
        member_success: 'Excel data imported successfully',
      },
    },
    Program: {
      ongoing: 'Program Ongoing',
      upcoming: 'Program Upcoming',
      finished: 'Program Finished',
      program: {
        zero: '0 programs',
        one: '1 program',
        other: '%{count} programs',
      },
    },
    Course: {
      ongoing: 'Course Ongoing',
      upcoming: 'Course Upcoming',
      finished: 'Course Finished',
      course: {
        zero: '0 courses',
        one: '1 course',
        other: '%{count} courses',
      },
    },
  },
  ZoomToolNotification: {
    title: 'NOTIFICATIONS',
    empty_text: 'There are no notifications to display yet',
  },
  ZoomToolCalendar: {
    empty_text: 'There are no notifications to display yet',
    course: 'Course',
    lesson: 'Lesson',
    today: `Today's calendar`,
    prepare_lesson: 'Prepare the lesson',
    material_reminder: `You haven't prepared the lesson's materials for this lesson.`,
    student_material_reminder: 'No materials for this lesson just yet.',
    Form: {
      viewBy: 'View by',
      search: 'Search',
      status: 'Status',
      program: 'Program',
      all: 'All',
      inprogress: 'In Progress',
      student: 'Student',
      teacher: 'Teacher',
    },
    roomActions: {
      modals: {
        cancelTitle: 'Leave the class',
        cancelText: 'Are you sure you want to leave?',
        form: {
          lessonName: 'Lesson name',
          courseName: 'Course name',
          dateTime: 'Datetime',
          currentTime: 'Current time',
          newTime: 'New time',
          applyFor: 'Apply for',
          checkOptions: {
            option1: 'This lesson',
            option2: 'This and following lessons: %{count}',
            option3: 'All lessons',
          },
          manageTeachers: 'Manage teachers',
          reason: 'Reason',
          effectedOptions: {
            option1: 'This lesson',
            option2: 'This and following lessons',
            option3: 'All lessons',
          },
        },
      },
      buttons: {
        confirmCancel: 'Confirm cancelling lesson',
        confirmReschedule: 'Confirm rescheduling lesson',
        confirmSubstitutes: 'Confirm substitutes',
      },
      cancel: 'Cancel lesson',
      reschedule: 'Reschedule lesson',
      manageSubstitutes: 'Manage substitutes',
    },
    Action: {
      reset: 'Reset',
      save: 'Save Presets',
    },
  },
  ZoomToolMyAccount: {
    title: 'My Account',
    edit_information: 'Edit your information',
    dob_label: 'Date of birth',
    first_name_label: 'First name',
    last_name_label: 'Last name',
    change_password: 'Change password',
    dob_placeholder: 'YYYY/MM/DD',
    timezone: 'Timezone',
    region: 'Region',
    calendar: 'Calendar',
    font_size: 'Font size',
    calendar_label: 'Change calendar',
    timezone_label: 'Set your timezone',
    font_size_label: 'Change font size',
    language_note: 'Would you like to change the language of the text you see on Unitz?',
    timezone_note: 'When you log in to Unitz, this will be the timezone you see. This will not affect the timezone other people sees on the platform.',
    region_note: 'This will be the region you see. This will not affect the region other people sees on the platform.',
    calendar_note: 'When you log in to Unitz, this will be the calendar you see. This will not affect the calendar other people sees on the platform.',
    font_size_note: 'When you log in to Unitz, this will be the font size you see. This will not affect the font size other people sees on the platform.',
    font_size_unit: 'pt (points)',
    region_hint: 'This will be the region you see. This will not affect the region other people sees on the platform.',
    time_zone_placeholder: 'Select your timezone',
    region_placeholder: 'Select your region',
    calendar_placeholder: 'Select your calendar',
  },
  ZoomToolSettings: {
    title: 'SETTINGS',
    organization_name: 'Organization name',
    company_logo: 'Company logo',
    Notification: {
      success: 'Update information successfully',
    },
    class_time_rules: {
      label: 'Class time rules',
      tardy: 'It will be counted as tardy when the following class time has passed:',
      early: 'It will be counted as early leave when leaving the class before:',
      minute: 'Minute',
      minutes: 'Minutes',
    },
    supported_materials: 'Supported materials',
    lessons_materials: {
      label: 'Allow access to lessons materials',
      option1: 'Up to current lesson only',
      option1_1: 'Allow teacher to manually publish lessons',
      option2: 'Only 1 lesson in advance',
      option2_2: 'Allow teacher to manually publish lessons',
      option3: 'All lessons',
    },
    program: {
      label: 'Skip program',
      enabled: 'Enabled',
      disabled: 'Disabled',
      note: 'Program is made up of multiple courses. I want to use Program in our system to wrap Courses into Program',
    },
    approval: {
      label: 'Allow automatic approval',
      option1: 'Automatically approve class cancellation lesson requests',
      option2: 'Automatically approve reschedule lesson requests',
    },
    evaluation: {
      label: 'Student Evaluation Per Single Lesson',
      option1: 'Student Concentration during the Lesson',
      option2: 'Student Activeness during the Lesson',
      option3: 'Student Classification',
    },
    grading_system: {
      title: 'Student Evaluation Per Course',
      label: 'Evaluation Per Course',
      note: 'Would you like to change the grading system?',
    },
  },
  ZoomToolFilter: {
    Status: {
      all: 'All',
      active: 'Active',
      disabled: 'Disabled',
    },
  },
  ZoomToolRoomManipulation: {
    selectCamera: 'Select a camera',
    selectSpeaker: 'Select a speaker',
    selectMicrophone: 'Select a microphone',
    mirrorVideo: 'Mirror my video',
    videoStatistic: 'Video statistic',
    audioStatistic: 'Audio statistic',
    leaveVideo: 'Leave video',
    hangUp: 'Hangup',
    startAudio: 'Start audio',
    mute: 'Mute',
    unmute: 'Unmute',
    startCam: 'Start camera',
    showVideo: 'Show videos',
    hideVideo: 'Hide videos',
    startShareScreen: 'Start share screen',
    stopShareScreen: 'Stop share screen',
    showThumbnail: 'Show thumbnails',
  },

  ZoomToolError: {
    default: 'Something went wrong!',
    existOrg: 'Organization name already exists',
    missingTeacher: 'Please add at least a teacher',
    missingStudent: 'Please add at least a student',
    CourseTemplate: {
      create: {
        fail: 'The course creation failed. Please check the information again',
      },
      edit: {
        fail: 'Course editing failed. Please check the information again',
      },
    },
    Member: {
      import: {
        intro: 'Errors encountered while processing the Excel file. Please check the errors below:',
        errors: ['- Field is not valid', '- Missing required field'],
      },
    },
  },

  Content404: {
    message: 'Hoppla!',
    description: `We're sorry, the page you requested could not be found.`,
    requirement: 'Please go back to the Homepage',
    goHome: 'Go to Homepage',
  },

  Blackboard: {
    import: 'Import',
    export: 'Export',
    FontSize: {
      16: 'Normal',
      22: 'Subheading',
      28: 'Heading',
      32: 'Title',
    },
  },

  ZoomToolVerifyEmail: {
    title: 'Verify your email',
    activation_notification: 'Your account has not been activated yet.',
    email: `We’ve sent a verification email to {{email}}`,
    check_text: 'Please check your inbox and verify your account.',
    description: `You can also request to`,
    resend: 'resend the verification email',
    solutions: {
      title: `Didn’t receive the verification email?`,
      content_1: '1. Check your spam, promotions or updates folders.',
      content_2: '2. Is {{email}} your correct email? If there’s a typo, go ahead and create a new account with the same organisation code we give you.',
      content_3: '3. Add hi@unitz.biz to our contacts and resend the verification email.',
    },
    contact: 'If you’re still having issues, please contact hi@unitz.biz for support.',
    thanks: 'Thank you,',
    sender: 'Unitz team',
  },
};
