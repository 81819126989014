import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { Row, Col } from 'antd';
import CourseRCMButton from '@uz/unitz-components-web/CourseRCM/Components/Button';

// import AdvisorRegistratorForm from '@uz/unitz-components-web/AdvisorRegistratorForm';
// import Link from '@uz/unitz-components-web/Link';
import cx from 'classnames';
import _ from 'lodash';
import * as Actions from '@uz/unitz-ctf-theme/actions';
import gstyles from '@vl/gstyles';

const CustomText = styled.div`
  span {
    margin: 0;
    color: ${gstyles.colors.white500};
  }
`;

const HeroLandingPage = () => {
  return (
    <DIV className="HeroLandingPage">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="relative content">
            <div className="absolute inset-0 z-10 w-full h-full opacity-50 bg-black500"></div>
            <div className="absolute z-20 w-full transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
              <div className={cx('wrapper app-row', ctx.get('sectionData.className'))}>
                <div className="w-full min-lg:w-2/3">
                  <Row className={ctx.get('sectionData.className')} align="left">
                    <Col>
                      {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                        const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });
                        return renderPropsComposer(
                          {
                            matcher: (props) => props.name === 'Logo',
                            render: (props) => {
                              // console.log({ src: _.get(props, 'image.fixed.src') });
                              return (
                                <ResponsiveProps
                                  key={`key_${index}`}
                                  xs={{ width: Math.round(100 / 1.47), height: 100 }}
                                  md={{ width: Math.round(120 / 1.47), height: 120 }}
                                  lg={{ width: Math.round(144 / 1.47), height: 144 }}
                                >
                                  {(resProps) => {
                                    // console.log({ resProps });
                                    return (
                                      <Image
                                        {...resProps}
                                        // height={144}
                                        // width={100}
                                        src={_.get(props, 'image.fixed.src')}
                                      />
                                    );
                                  }}
                                </ResponsiveProps>
                              );
                            },
                          },
                          {
                            matcher: (props) => props.name === 'RCMContent',
                            render: (props) => {
                              return (
                                <React.Fragment key={`key_${index}`}>
                                  <h1 className="mt-6 text-2xl tracking-wider font-demibold lg:text-4xl lg:mb-6 text-white500">
                                    {_.get(props, 'shortText')}
                                  </h1>
                                  <div className="mb-4 sm:mb-8">
                                    <CustomText>
                                      <div className="mb-0 text-base font-normal tracking-wider text-white500">
                                        {ctx.apply(
                                          'ctf.renderHTMLText',
                                          { name: _.get(props, 'name') },
                                          'longText.longText',
                                          null
                                        )}
                                      </div>
                                    </CustomText>
                                  </div>
                                </React.Fragment>
                              );
                            },
                          },
                          {
                            matcher: (props) => props.name === 'RCMButton',
                            render: () => {
                              return (
                                <div className="flex" key={`key_${index}`}>
                                  <CourseRCMButton />
                                </div>
                              );
                            },
                          }
                        )(itemData);
                        //   return (
                        //     <Col key={`item_${index}`} xs={18} sm={12}>
                        //       <Link to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref'))}>
                        //         <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                        //           {(resProps) => (
                        //             <Button className="w-full text-center" type="primary" {...resProps}>
                        //               {_.get(itemData, 'shortText', '')}
                        //             </Button>
                        //           )}
                        //         </ResponsiveProps>
                        //       </Link>
                        //     </Col>
                        //   );
                      })}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <ResponsiveProps
              xs={{ style: { height: 400 }, background: true }}
              md={{ style: { height: 500 }, background: true }}
              lg={{ style: { height: 600 }, background: true }}
              xl={{ style: { height: 700 }, background: true }}
              //   md={{ ratio: 0.5, width: 768, height: 500 }}
              //   lg={{ ratio: 0.4, width: 1000, height: 600 }}
              //   xl={{ ratio: 0.4, width: 1200, height: 700 }}
            >
              {(resProps) => (
                <Image
                  size="cover"
                  src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                  className="w-full hero-image"
                  alt="banner"
                  {...resProps}
                />
              )}
            </ResponsiveProps>
          </div>
        );
      })}
    </DIV>
  );
};

export default HeroLandingPage;
