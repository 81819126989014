import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import CourseModel from '@uz/unitz-models/CourseModel';
import moment from 'moment';
import { TIMESTAMPTZ_FORMAT } from '@vl/mod-utils/datetime';

const bindData = bindings({
  LandingMyCourseLayout: {
    rules: [
      [
        'data',
        {
          data: {
            myCourses: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { ctx });
              const userId = ctx.apply('authModel.getUserId');

              const pagingModel = React.useMemo(() => {
                const condition = `
                where: {
                  status: {_in: [published, onprogress]},
                  attendees: {user_id: {_eq: "${userId}"}},
                }
                  order_by: { updated_at: desc_nulls_last }
                `;
                const dataQuery = `
                  course(${condition}) {
                    id
                    status
                    start_at
                    name
                    description
                    price_amount
                    photo_url
                    video_url
                    price_currency
                    pricing_type
                    session_duration
                    session_occurence
                    enrolls {
                      id
                      user {
                        id
                        profile {
                          avatar_url
                        }
                      }
                    }
                    sessions {
                      id
                      type
                      start_at
                      repeat_pattern
                    }
                    advisor {
                      profile {
                        advisor_id
                        avatar_url
                        display_name
                      }
                    }
                    rooms(limit: 2, where: {start_at: {_gt: "${moment()
                      .utc()
                      .format(TIMESTAMPTZ_FORMAT)}"}}, order_by: {start_at: asc_nulls_last}) {
                      id
                      start_at
                      end_at
                    }
                    purchases(where: {purchase: {user_id: {_eq: "${userId}"}, latest_status: {_eq: "completed"}}}) {
                      id
                      per_amount
                      per_unit
                      purchase_id
                      purchase {
                        user_id
                        remain_rooms: course_rooms(where: {room: {start_at: {_gte: "${moment()
                          .startOf('day')
                          .utc()
                          .format(TIMESTAMPTZ_FORMAT)}"}}}) {
                          id
                        }
                        course_rooms {
                          id
                        }
                      }
                    }
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  Model: CourseModel,
                  dataQuery,
                  aggQuery: `
                    course_aggregate(${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 120,
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const courseListPagingModel = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                isLoadMore: pagingModel.useState('isLoadMore'),
                isLoading: pagingModel.useState('isLoading'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                onLoadMore: () => pagingModel.onLoadMore(),
                onRefresh: () => pagingModel.onRefresh(),
                onPreviousPage: () => pagingModel.onPreviousPageHandler(),
                onNextPage: () => pagingModel.onNextPageHandler(),
                onLoadPage: (page) => pagingModel.onLoadPageHandler(page),
              };
              return courseListPagingModel;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
