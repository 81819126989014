import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';
import Link from '@uz/unitz-components-web/Link';

export const NewsItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <Row className="text-center items-center" gutter={[24, 16]}>
          <Col xs={24}>
            <div className="h-full w-full flex flex-col">
              <div className="mb-4">
                <Image
                  className="w-full"
                  height={200}
                  src={ctx.apply('ctf.findImage', ctx.get('itemData'), 'image', '')}
                  alt={ctx.get('itemData.name')}
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <div className="text-left">
                {_.isNull(ctx.get('itemData.linkHref')) ? (
                  <div className="font-semibold text-xl my-2 text-main">
                    {_.get(ctx.get('itemData'), 'shortText', '')}
                  </div>
                ) : (
                  <Link to={ctx.get('itemData.linkHref')}>
                    <div className="font-semibold text-xl my-2 text-main">
                      {_.get(ctx.get('itemData'), 'shortText', '')}
                    </div>
                  </Link>
                )}

                <Link to={ctx.get('itemData.linkHref')}>
                  <div className="flex items-center">
                    <div className="font-semibold text-sm my-2 text-brandb500">
                      {ctx.apply('i18n.t', 'Biz.read_more')}{' '}
                    </div>
                    <span>{gstyles.icons({ name: 'arrow-right', size: 16, fill: gstyles.colors.brandb500 })}</span>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default NewsItem;
