import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import DesktopTab from './components/DesktopTab';
import MobileTab from './components/MobileTab';
import TabletTab from './components/TabletTab';

const AdvisorRegisterGuideLayout = () => {
  return (
    <DIV className="AdvisorRegisterGuideContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <ResponsiveProps
            xs={{
              renderer: () => (
                <div className="guideline-content" id={ctx.get('tabData')}>
                  <MobileTab sectionData={ctx.get('sectionData')} />
                </div>
              ),
            }}
            md={{
              renderer: () => (
                <div className="guideline-content" id={ctx.get('tabData')}>
                  <TabletTab sectionData={ctx.get('sectionData')} />
                </div>
              ),
            }}
            lg={{
              renderer: () => (
                <div className="guideline-content" id={ctx.get('tabData')}>
                  <DesktopTab sectionData={ctx.get('sectionData')} />
                </div>
              ),
            }}
          >
            {(resProps) => !!resProps && resProps.renderer()}
          </ResponsiveProps>
        );
      })}
    </DIV>
  );
};

export default AdvisorRegisterGuideLayout;
