import { bindings, hook } from '@vl/redata';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';
import AdvisorModel from '@uz/unitz-models/AdvisorModel';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            advisorState: hook((ctx) => {
              const advisor_id = ctx.apply('authModel.getUserId');
              const state = useObservableSource(() =>
                AdvisorModel.find(
                  `where: {profile: {advisor_id: {_eq: "${advisor_id}"}}}`,
                  ` 
                    state {
                      advisor_id
                      signed
                      update_category
                      update_course
                      update_information
                      update_profile
                      update_service
                      update_working_time
                    }
                  `
                )
              );
              const res = useSubjectSource(state);
              return _.get(res, '0', {});
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
