import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Link } from '@uz/unitz-components-web/Link';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

export const View8 = () => {
  return (
    <DIV className="categoryItem">
      <div className="relative rounded-lg overflow-hidden">
        <Link to={ctx.apply('routeStore.toUrl', 'category', ctx.get('@item'))}>
          <ResponsiveProps xs={{ height: 100 }} md={{ height: 200 }} lg={{ height: 280 }}>
            {(resProps) => (
              <Image
                style={{ objectFit: 'cover' }}
                alt={`avt_${ctx.get('@items.id')}`}
                className="object-fill"
                src={ctx.get('@item.imageData')}
                {...resProps}
              />
            )}
          </ResponsiveProps>
          <div className="absolute bottom-0 left-0 px-3 md:p-6 bg-brandb500 w-full">
            <div className="text-white500 font-semibold text-center text-sm sm:text-base md:text-xl">{ctx.get('@item.displayName')}</div>
          </div>
        </Link>
      </div>
    </DIV>
  );
};
export default displayName(View8);
