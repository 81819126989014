import React from 'react';
import { Button, Row, Col, Alert, Divider, Space, Checkbox, Modal } from 'antd';

import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Input, Form, FormItem } from 'formik-antd';
import styled from 'styled-components';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const CustomButton = styled.div`
  .ant-btn {
    border: 1px solid ${gstyles.colors.divider};
  }
`;

export default () => (
  <DIV className="formContainer">
    <Modal
      title={ctx.apply('i18n.t', 'Login.modal.title')}
      visible={ctx.get('form.isModalVisible')}
      onOk={ctx.get('form.handleSubmit')}
      onCancel={ctx.get('form.handleCancel')}
      okButtonProps={{
        type: 'primary',
        htmlType: 'submit',
        id: 'submit',
        disabled: !ctx.get('form.canSubmit'),
        loading: ctx.get('form.isSubmitting'),
        className: 'px-8',
      }}
      destroyOnClose
      afterClose={() => ctx.apply('modalModel.hide')}
      footer={null}
    >
      <>
        {ctx.get('form.isModalVisible') &&
          (() => {
            return (
              <DIV forceCtx>
                <div className="bg-white500 p-4 lg:p-6">
                  <h3 className="mb-6 text-2xl font-semibold text-center text-brandb500">
                    {ctx.apply('i18n.t', 'Login.loginLabel')}
                  </h3>
                  <Row>
                    {/* <Col className="mb-4 formStatus" span={24}>
                      <CustomButton>
                        <Button
                          className="relative"
                          block
                          onClick={() => ctx.get('authModel.facebookLogin')(ctx.get('handlers.onLoginRedirect'))}
                          loading={ctx.get('authModel.signInFacebook_')}
                          disabled={ctx.get('authModel.signInFacebook_')}
                        >
                          <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                            {gstyles.icons({ name: 'facebook2', size: 24 })}
                          </div>
                          <h5 className="mb-0 text-base font-semibold">{ctx.apply('i18n.t', 'Login.facebookLogin')}</h5>
                        </Button>
                      </CustomButton>
                    </Col> */}
                    <Col className="mb-4 formStatus" span={24}>
                      <CustomButton>
                        <Button
                          className="relative"
                          type="secondary"
                          onClick={() => ctx.get('authModel.googleLogin')(ctx.get('handlers.onLoginRedirect'))}
                          loading={ctx.get('authModel.signInGoogle_')}
                          disabled={ctx.get('authModel.signInGoogle_')}
                          block
                        >
                          <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                            {gstyles.icons({ name: 'google2', size: 24 })}
                          </div>
                          <h5 className="mb-0 inline-block text-base font-semibold">
                            {ctx.apply('i18n.t', 'Login.googleLogin')}
                          </h5>
                        </Button>
                      </CustomButton>
                    </Col>
                    {/* <Col className="mb-4 formStatus" span={24}>
                      <CustomButton>
                        <Button
                          className="relative"
                          block
                          onClick={() => ctx.get('authModel.appleLogin')(ctx.get('handlers.onPrevScreen'))}
                          loading={ctx.get('authModel.signInApple_')}
                          disabled={ctx.get('authModel.signInApple_')}
                        >
                          <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                            {gstyles.icons({ name: 'apple2', size: 24 })}
                          </div>
                          <h5 className="mb-0 text-base font-semibold">{ctx.apply('i18n.t', 'Login.appleLogin')}</h5>
                        </Button>
                      </CustomButton>
                    </Col> */}
                  </Row>
                  <FormProvider form={ctx.get('form')}>
                    <Form {...layout} className="login-form" id="login-form">
                      <Row>
                        <Col className="formStatus" span={24}>
                          {!!ctx.get('form.status.error') && (
                            <div className="py-2">
                              <Alert message={ctx.get('form.status.error.message')} type="error" />
                            </div>
                          )}
                          {!!ctx.get('form.status.success') && (
                            <div className="py-2">
                              <Alert message={ctx.get('form.status.success.message')} type="success" />
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Divider plain>
                        <div className="mb-0 text-sm font-normal uppercase">{ctx.apply('i18n.t', 'Login.or')}</div>
                      </Divider>
                      <FormItem name="username">
                        <Input
                          className="rounded-lg"
                          name="username"
                          placeholder={ctx.apply('i18n.t', 'Login.email')}
                          size="large"
                        />
                      </FormItem>
                      <FormItem name="password">
                        <Input.Password
                          className="rounded-lg"
                          name="password"
                          placeholder={ctx.apply('i18n.t', 'Login.password')}
                          size="large"
                        />
                      </FormItem>
                      <Row className="mb-3">
                        <Col xs={12} lg={16}>
                          <Checkbox>
                            <div className="mb-0 text-base text-main font-normal">
                              {ctx.apply('i18n.t', 'Login.saveAccount')}
                            </div>
                          </Checkbox>
                        </Col>
                        <Col xs={12} lg={8} className="text-right">
                          <Link to={'/forgot'}>
                            <h5 className="mb-0 font-medium text-base text-brandb500">
                              {ctx.apply('i18n.t', 'Login.forgotPassword')}
                            </h5>
                          </Link>
                        </Col>
                      </Row>
                      <Row className="mt-6 mb-8">
                        <Col span={24}>
                          <Button
                            className="shadow-sm"
                            size="large"
                            type="primary"
                            htmlType="submit"
                            id="submit"
                            disabled={!ctx.get('canSubmit')}
                            loading={ctx.get('form.isSubmitting')}
                            block
                          >
                            <span id="button-text">{ctx.apply('i18n.t', 'Login.login')}</span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>

                    <div className="mt-3 text-center">
                      <Space>
                        <div className="font-normal text-main mb-0 text-base">
                          {ctx.apply('i18n.t', 'Login.signupQuestion')}
                        </div>
                        <Link to={'/signup'}>
                          <h5 className="mb-0 font-medium text-base text-brandb500">
                            {ctx.apply('i18n.t', 'Login.signupRedirect')}
                          </h5>
                        </Link>
                      </Space>
                    </div>
                  </FormProvider>
                </div>
              </DIV>
            );
          })()}
      </>
    </Modal>
  </DIV>
);
