import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    scrollbar-width: none;
    display: none;
    -ms-overflow-style: none;
  }
`;

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;
const LandingGuideStepLayout = () => {
  return (
    <DIV className="LandingGuideStepLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className="wrapper app-row">
          <div className="flex flex-col mb-6 justify-center items-center">
            <div className="text-sm text-sub text-center font-semibold">{ctx.get('sectionData.shortText')}</div>
            <CustomParagraph className="text-center">
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
            </CustomParagraph>
          </div>
          <ScrollX className="relative flex pb-3 overflow-x-scroll lg:overflow-x-hidden">
            <ResponsiveProps
              xs={{ wrap: false, align: 'start' }}
              md={{ wrap: false, align: 'start' }}
              lg={{ wrap: true, align: 'center' }}
              xl={{ wrap: true, align: 'center' }}
            >
              {(resProps) => (
                <Row
                  gutter={{
                    xs: 16,
                    sm: 16,
                    md: 24,
                    lg: 16,
                    xl: 32,
                  }}
                  {...resProps}
                >
                  {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                    (col, index) => (
                      <Col
                        className="mb-3 gutter-row sm:mb-0"
                        lg={8}
                        xs={12}
                        sm={24}
                        md={10}
                        key={`${_.get(col, 'name', '')}_${index}`}
                      >
                        {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                      </Col>
                    )
                  )}
                </Row>
              )}
            </ResponsiveProps>
          </ScrollX>
        </div>
      ))}
    </DIV>
  );
};

export default LandingGuideStepLayout;
