import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
  }
`;

export const PaymentTerm = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <div>
          <h4 className="font-semibold text-sm mb-1">{`${_.get(
            ctx.get('itemData'),
            'shortText',
            ''
          )}`}</h4>
          <CustomParagraph>
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
              as: 'p',
            })}
          </CustomParagraph>
        </div>
      );
    })}
  </DIV>
);

export default PaymentTerm;
