import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

const DisplayForAuthOnly = ({ children }) => {
  return (
    <DIV forceCtx>
      {ctx.apply('ctf.renderProps', () => {
        if (!ctx.apply('currentUserModel.getUserId')) return null;
        return children;
      })}
    </DIV>
  );
};

export default DisplayForAuthOnly;
