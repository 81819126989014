import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  LandingCategoriesLayout: {
    rules: [
      [
        'data',
        {
          data: {
            categories: hook((ctx) => {
              const categories = ctx.apply('ctf.buildCategoryHierarchy');
              const optimizeFilter = React.useMemo(
                () =>
                  _.filter(categories, (item) => {
                    return !!_.get(item, 'featured');
                  }),
                [categories]
              );
              return optimizeFilter;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
