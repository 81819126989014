import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Image } from '@uz/unitz-components-web/Image';

const GreenDisc = styled.div`
  position: absolute;
  top: 120%;
  left: 10%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 974px;
  height: 745px;
  background-color: ${gstyles.colors.brandb500};
  overflow: hidden;
  opacity: 0.15;
`;

const ForCenterBenefitLayout = () => {
  return (
    <DIV className="ForCenterBenefitLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="bg-white500 relative content overflow-hidden">
            <GreenDisc className="hidden lg:block" />

            <div className={cx('wrapper app-row', ctx.get('sectionData.className'))}>
              <div className="relative flex justify-center items-center mb-0 md:mb-4">
                <Image
                  size="cover"
                  height={240}
                  src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                  className="w-full hero-image md:rounded-lg overflow-hidden"
                  alt="banner"
                />

                <h1 className="absolute text-center text-2xl lg:text-4xl tracking-wider text-white500 font-semibold">
                  {ctx.get('sectionData.shortText')}
                </h1>
              </div>

              <Row
                gutter={[
                  { xs: 0, md: 24 },
                  { xs: 0, md: 24 },
                ]}
                justify="center"
                align="middle"
                className={ctx.get('sectionData.className')}
              >
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col key={`item_${index}`} xs={24} lg={6}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForCenterBenefitLayout;
