import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';

import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
// import LangSwitch from '@uz/unitz-ctf-theme/layouts/LangSwitch';

// const hasScrollY = () => {
//   // eslint-disable-next-line
//   const [hasScroll, $hasScroll] = React.useState(false);
//   // eslint-disable-next-line
//   React.useEffect(() => {
//     if (typeof window !== 'undefined') {
//       window.addEventListener('scroll', () => {
//         let scroll = null;
//         if (window.scrollY === 0) {
//           scroll = false;
//         } else {
//           scroll = true;
//         }
//         $hasScroll(scroll);
//       });
//     }
//   }, []);
//   return hasScroll;
// };

// const StickyNav = styled(({ children, className }) => {
//   const hasScroll = hasScrollY();
//   const { observe, height } = useDimensions({
//     onResize: _.throttle(() => {}, 1000),
//   });
//   const ref = React.useRef({});
//   const placeHolderHeight = React.useMemo(() => {
//     if (height > 0) {
//       ref.current.height = height;
//     }
//     return ref.current.height;
//   }, [height]);

//   return (
//     <div className={className}>
//       <div className="header-Placeholder" style={{ height: placeHolderHeight }} />
//       <header className={cx(`header-sticky ${hasScroll ? 'sticky' : ''}`)} ref={observe}>
//         {children}
//       </header>
//     </div>
//   );
// })`
//   > .sticky {
//     position: fixed;
//   }
// `;

const DesktopNavbar = () => {
  return (
    <DIV>
      <SectionWrapper className="relative h-20 bg-brandb" as="div">
        <div className="flex flex-row h-20 px-4 space-x-6 app-row">
          <div className="flex items-center justify-center">
            <div className="m-2">
              <a
                href={ctx.apply(
                  'ctf.findEntry',
                  { name: ctx.apply('ctf.findSectionChild', 0, 'name') },
                  'linkHref',
                  ''
                )}
              >
                <Image
                  size={40}
                  src={ctx.apply('ctf.findImage', { name: ctx.apply('ctf.findSectionChild', 0, 'name') }, 'image', '')}
                  alt="banner"
                />
              </a>
            </div>
          </div>
          <div className="flex flex-row items-center flex-1 ml-2">{ctx.apply('ctf.renderSectionChild', 1)}</div>
          <div className="flex flex-row items-center">
            <div className="search-menu-account-wrapper">{ctx.apply('ctf.renderSectionChild', 2)}</div>
            {/* <LangSwitch /> */}
          </div>
        </div>
      </SectionWrapper>
    </DIV>
  );
};

export default DesktopNavbar;
