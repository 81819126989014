import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
// import './menu.scss';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

function MenuV() {
  const [, setIsOpen] = React.useState(false);
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <DIV>
      <ResponsiveProps
        xs={{ renderer: () => <MobileMenu /> }}
        md={{ renderer: () => <MobileMenu /> }}
        lg={{ renderer: () => <DesktopMenu /> }}
        xl={{ renderer: () => <DesktopMenu /> }}
      >
        {(resProps) => !!resProps && resProps.renderer()}
      </ResponsiveProps>
    </DIV>
  );
}

export default MenuV;
