import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import AdvisorSearchModel from '@uz/unitz-models/AdvisorSearchModel';
import SearchCourseModel from '@uz/unitz-models/SearchCourseModel';
import React from 'react';

const bindData = bindings({
  SearchButton: {
    rules: [
      [
        'data',
        {
          data: {
            searchForm: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  textSearch: '',
                },

                onSubmit: async () => {
                  try {
                    // console.log(values);
                  } catch (error) {
                    console.log({ error });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              return form;
            }),

            listSuggestion: hook((ctx) => {
              const [show, $show] = React.useState(false);
              const term = ctx.get('searchForm.values.textSearch');
              const [items, $items] = React.useState([]);

              const searchTerm = React.useMemo(
                () =>
                  _.debounce(async (term) => {
                    try {
                      const querySearch = [term ? `search: "${term}"` : `search: ""`];
                      const result = await SearchCourseModel.find(
                        `args: {${_.compact(querySearch).join(
                          ','
                        )}}, where: {scoring_int: {_gte: "100"}, status: {_in: [published, onprogress]}}, limit: 10`,
                        'id name photo_url'
                      );

                      $items(result.toObject() || []);
                    } catch (err) {
                      console.log(err);
                    }
                  }, 500),
                []
              );

              React.useEffect(() => {
                if (term) {
                  searchTerm(term);
                  if (!show) {
                    $show(true);
                  }
                }
              }, [term]);
              return {
                hasData: items.length > 0,
                items,
                show,
                hideSuggestion: () => {
                  $show(false);
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
