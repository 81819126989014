import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

import Layout from '@uz/unitz-layout-web/LayoutWidget';
import Link from '@uz/unitz-components-web/Link';

import { Button } from '@uz/unitz-components-web/Button';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import AdvisorSchedule from '@uz/unitz-components-web/AdvisorSchedule';
import styled from 'styled-components';

const CustomButton = styled(Button)`
  padding: 0 !important;
`;

const View8 = () => (
  <DIV className="widget">
    <Layout>
      <Layout.POS name="widget-header-title">
        <h3 className="flex items-center text-base font-semibold">
          <span>{gstyles.icons({ name: 'menuBook', size: 24, fill: gstyles.colors.sub })}</span>
          <span className="ml-2">{ctx.apply('i18n.t', 'AdvisorProfileGeneral.Courses')} </span>
        </h3>
      </Layout.POS>
      {!!ctx.get('courseGroups.length') && (
        <Layout.POS name="widget-header-action">
          <DIV className="allowButtonCreateCourse">
            <Link to={ctx.apply('routeStore.toUrl', 'courseAdd')} className="block mx-auto">
              <Button
                type="primary"
                icon={gstyles.icons({ name: 'add-circle', size: 20, className: 'inline-block mr-1' })}
                disabled={false}
              >
                {ctx.apply('i18n.t', 'AdvisorProfileGeneral.AddCourse')}
              </Button>
            </Link>
          </DIV>
        </Layout.POS>
      )}
      <Layout.POS name="widget-body">
        {ctx.get('courseGroups.length') ? (
          <div className="space-y-8">
            <Row gutter={[16, 16]}>
              {_.map(ctx.get('courseGroups'), (item, index) => (
                <Col xs={12} lg={8} xl={8} key={`${index}`}>
                  <div className="px-5 py-4 border rounded-lg border-border">
                    <div className="mb-1 text-sm font-normal text-sub">{_.get(item, 'title')}</div>
                    <div className="text-xl font-semibold">
                      {ctx.apply('i18n.t', 'Course.course_count', { count: _.get(item, 'count') })}
                    </div>
                    <CustomButton type="link">
                      <Link
                        to={ctx.apply('routeStore.toLocale', _.get(item, 'detailUrl'))}
                        className="block mx-auto mt-4"
                      >
                        <div className="flex items-center justify-center">
                          <span className="mr-2 text-base font-semibold">
                            {ctx.apply('i18n.t', 'AdvisorProfileGeneral.Detail')}
                          </span>
                          <div>{gstyles.icons({ name: 'arrow-right2', size: 20, className: 'inline-block' })}</div>
                        </div>
                      </Link>
                    </CustomButton>
                  </div>
                </Col>
              ))}
            </Row>
            <AdvisorSchedule />
          </div>
        ) : (
          <div className="mb-4">
            <div className="flex flex-col items-center justify-center mt-12">
              {gstyles.icons({ name: 'courseEmpty', size: 120 })}
              <div className="text-base font-normal text-justify">
                {ctx.apply('i18n.t', 'AdvisorProfileGeneral.NoCourseAddMore')}
              </div>
            </div>
            <div className="flex justify-center">
              <Link to={ctx.apply('routeStore.toUrl', 'courseAdd')} className="block mx-auto">
                <Button
                  className="px-12 mt-6"
                  type="primary"
                  icon={gstyles.icons({ name: 'add-circle', size: 20, className: 'inline-block mr-1' })}
                  disabled={false}
                >
                  {ctx.apply('i18n.t', 'AdvisorProfileGeneral.AddCourse')}
                </Button>
              </Link>
            </div>
          </div>
        )}
      </Layout.POS>
    </Layout>
  </DIV>
);

export default displayName(View8);
