import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            carouselModel: hook((ctx) => {
              const [dragging, $dragging] = React.useState(false);
              const NUMBER_OF_ITEMS = ctx.get('@items.length') || 0;

              const options = {
                dots: true,
                infinite: !!(NUMBER_OF_ITEMS > 3),
                infinite: false,
                // centerMode: !(NUMBER_OF_ITEMS > 3),
                arrows: true,
                slidesToShow: 4,
                slidesToScroll: 3,
                speed: 500,
                // rows: NUMBER_OF_ITEMS > 3 ? 2 : 1,
                rows: 1,
                responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      // infinite: !!(NUMBER_OF_ITEMS > 3),
                      infinite: false,
                      centerMode: !(NUMBER_OF_ITEMS > 3),
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      rows: 1,
                      dots: true,
                      arrows: true,
                      swipeToSlide: true,
                    },
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      // infinite: !!(NUMBER_OF_ITEMS > 2),
                      infinite: false,
                      centerMode: !(NUMBER_OF_ITEMS > 2),
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      rows: NUMBER_OF_ITEMS > 3 ? 2 : 1,
                      initialSlide: 1,
                      swipeToSlide: true,
                      dots: true,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      infinite: false,
                      centerMode: true,
                      slidesToShow: 1.2,
                      slidesToScroll: 1,
                      rows: NUMBER_OF_ITEMS > 3 ? 2 : 1,
                      swipeToSlide: true,
                      arrows: true,
                      dots: false,
                    },
                  },
                  {
                    breakpoint: 576,
                    settings: {
                      // className: 'slider variable-width',
                      infinite: false,
                      centerMode: true,
                      slidesToShow: 1.01,
                      slidesToScroll: 1,
                      rows: NUMBER_OF_ITEMS > 3 ? 2 : 1,
                      // rows: 1,
                      swipeToSlide: true,
                      arrows: true,
                      dots: false,
                      // variableWidth: true,
                    },
                  },
                ],
              };
              return {
                dragging,
                $dragging,
                options,
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
