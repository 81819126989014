import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import WidgetCourse from '@uz/unitz-components-web/WidgetCourse';
import _ from 'lodash';
import { AdvisorOnlyRedirect } from '@uz/unitz-components-web/AppRedirect';
export const AdvisorRegistratorScheduleLayout = () => {
  return (
    <DIV className="AdvisorRegistratorSchedule">
      <AdvisorOnlyRedirect>
        {ctx.apply('ctf.renderProps', () => {
          return (
            <div className="px-0 py-12 w-fill-screen min-md:px-4 bg-background1">
              <div className="h-full p-0 space-y-4 bg-white rounded-lg shadow min-md:p-6 app-row">
                <WidgetCourse />
              </div>
            </div>
          );
        })}
      </AdvisorOnlyRedirect>
    </DIV>
  );
};
export default AdvisorRegistratorScheduleLayout;
