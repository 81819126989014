import React from 'react';
import createLayout from '@vl/mod-utils/createLayout';

export const Layout = createLayout(({ children }) => {
  return (
    <div className="h-full flex flex-col" style={{ backgroundColor: '#fff' }}>
      <div id="layoutcontent">{children}</div>
      <div className="w-full">
        <Layout.RenderPOS name="nav-lang" />
      </div>
      <div className="px-4 w-full border-b">
        <Layout.RenderPOS name="nav-profile" />
      </div>
      <div className="w-full overflow-x-hidden overflow-y-auto">
        <div className="px-4 w-full">
          <Layout.RenderPOS name="nav-ctf-menu" />
        </div>
        <div className="px-4 w-full">
          <Layout.RenderPOS name="nav-menu" />
        </div>
      </div>
    </div>
  );
});

export default Layout;
