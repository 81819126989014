import { bindings, hook } from '@vl/redata';
import UserModel from '@uz/unitz-models/UserModel';
import _ from 'lodash';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const bindData = bindings({
  forUserOnly: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return !!ctx.apply('authModel.getUserId');
          }),
        },
      ],
    ],
  },

  component: {
    rules: [
      [
        'data',
        {
          data: {
            notificationCount: hook((ctx) => {
              const user_id = ctx.apply('authModel.getUserId');
              const user_notification = useObservableSource(() =>
                UserModel.find(
                  `where: {id: {_eq: "${user_id}"}}`,
                  `notifications_aggregate(where: {type_id: {_like: "%advisor%"}, seen: {_eq: false}}) {
                      aggregate{
                        count
                      }
                    }
                    `
                )
              );

              const data_noti = useSubjectSource(user_notification);

              return _.get(data_noti, '0.notifications_aggregate.aggregate.count', 0);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
