import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Alert } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem } from 'formik-antd';
import InputText from '@uz/unitz-components-web/InputText';

import CustomInput from '../CustomInput';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function CourseRoomCancelModal() {
  return (
    <DIV className="courseRoomCancelModal">
      <Modal
        title={ctx.apply('i18n.t', 'CourseRoom.modals.cancel.title')}
        visible={ctx.get('form.isModalVisible')}
        onOk={ctx.get('form.handleSubmit')}
        okText={ctx.apply('i18n.t', 'CourseRoom.modals.cancel.okText')}
        onCancel={ctx.get('form.handleCancel')}
        cancelText={ctx.apply('i18n.t', 'CourseRoom.modals.cancel.cancelText')}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          id: 'submit',
          disabled: !ctx.get('form.canSubmit'),
          loading: ctx.get('form.isSubmitting'),
          className: 'px-8',
        }}
        destroyOnClose
        afterClose={() => ctx.apply('modalModel.hide')}
      >
        {ctx.get('form.isModalVisible') && (
          <FormProvider form={ctx.get('form')}>
            <Form {...layout}>
              {ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              <div className="my-2">
                <Alert message={ctx.apply('i18n.t', 'CourseRoom.modals.cancel.infoMessage')} type="info" />
              </div>
              <CustomInput>
                <FormItem
                  name="name"
                  label={
                    <span className="font-nornal text-sm text-sub">
                      {ctx.apply('i18n.t', 'Course.Info.name_input_label')}
                    </span>
                  }
                >
                  <InputText name="name" disabled />
                </FormItem>
              </CustomInput>
              <div className="my-1">{ctx.apply('i18n.t', 'CourseRoom.modals.cancel.confirmMessage')}</div>
              <CustomInput>
                <FormItem
                  name="confirm_text"
                  label={
                    <span className="font-nornal text-sm text-sub">
                      {ctx.apply('i18n.t', 'AdvisorScheduleBooking.confirm')}
                    </span>
                  }
                >
                  <InputText name="confirm_text" />
                </FormItem>
              </CustomInput>
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
}
