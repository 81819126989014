import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import Button from '@uz/unitz-components-web/Button';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import CourseRoomRefundModal from '@uz/unitz-components-web/CourseRoom/Components/CourseRoomRefundModal';
import CourseRoomDismissModal from '@uz/unitz-components-web/CourseRoom/Components/CourseRoomDismissModal';
import CourseRoomUnfollowModal from '@uz/unitz-components-web/CourseRoom/Components/CourseRoomUnfollowModal';
import CourseRoomMessageModal from '@uz/unitz-components-web/CourseRoom/Components/CourseRoomMessageModal';
import CourseRoomRescheduleModal from '@uz/unitz-components-web/CourseRoom/Components/CourseRoomRescheduleModal';
import CourseRoomCancelModal from '@uz/unitz-components-web/CourseRoom/Components/CourseRoomCancelModal';

import { Dropdown, Menu } from 'antd';

export const CourseRoomActionMenu = () => {
  return (
    <DIV className="listItem">
      <ModalProvider>
        <Dropdown
          destroyPopupOnHide
          overlay={() => {
            return (
              <Menu>
                {ctx.apply('courseRoomModel.can', 'reschedule') && (
                  <Menu.Item
                    key="reschedule"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomRescheduleModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.reschedule.title')}
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'refund') && (
                  <Menu.Item
                    key="refund"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomRefundModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.refund.title')}
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'message') && (
                  <Menu.Item key="message">
                    <a href={ctx.apply('courseRoomModel.linkTo')} target="_blank">
                      {ctx.apply('i18n.t', 'CourseRoom.menus.message.title')}
                    </a>
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'dismiss') && (
                  <Menu.Item
                    key="dismiss"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomDismissModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.dismiss.title')}
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'unfollow') && (
                  <Menu.Item
                    key="unfollow"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomUnfollowModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.unfollow.title')}
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'cancel') && (
                  <Menu.Item
                    key="cancel"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomCancelModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.cancel.title')}
                  </Menu.Item>
                )}
              </Menu>
            );
          }}
        >
          <Button
            type="link"
            name="ink"
            icon={gstyles.icons({ name: 'more', size: 24, fill: gstyles.colors.sub })}
            area-label="action"
            disabled={false}
          />
        </Dropdown>
      </ModalProvider>
    </DIV>
  );
};

export default CourseRoomActionMenu;
