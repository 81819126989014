import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import React from 'react';
import useObservableSource from '@vl/hooks/useObservableSource';
import AdminSupplierSearchModel from '@uz/unitz-models/AdminSupplierSearchModel';
import UserModel from '@uz/unitz-models/UserModel';
import CourseModel from '@uz/unitz-models/CourseModel';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const ACADEMIC_ID = '4bhJ4jhBJP3cilGFfNoekv';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            categoryAggregate: hook((ctx) => {
              const categories = ctx.apply('ctf.buildCategoryHierarchy');

              const getLanguage = React.useMemo(
                () =>
                  _.filter(categories, (item) => {
                    return _.get(item, 'id') === ACADEMIC_ID;
                  }),
                [categories]
              );
              return _.get(getLanguage, '0.children.length', 0);
            }),

            teacherAggregate: hook(() => {
              const teachers = useObservableSource(() =>
                AdminSupplierSearchModel.find(
                  'args:{}, where:{ profile: {is_active: {_eq: true}, is_published: {_eq: true}, scoring_int: {_gte: "100"}}}',
                  `id
                `
                )
              );
              const res = useSubjectSource(teachers);
              return _.get(res, 'length', 0);
            }),

            userAggregate: hook(() => {
              const users = useObservableSource(() => UserModel.find('', `id`));
              const res = useSubjectSource(users);

              return _.get(res, 'length', 0);
            }),

            courseAggregate: hook(() => {
              const courses = useObservableSource(() =>
                CourseModel.find(
                  'where: {status: {_eq: published}, scoring_int: {_gte: "1000"}, is_active: {_eq: true}}',
                  `id
                `
                )
              );
              const res = useSubjectSource(courses);

              return _.get(res, 'length', 0);
            }),

            statistics: hook((ctx) => {
              return [
                { name: ctx.apply('i18n.t', 'Admin.Dashboard.category'), total: ctx.get('categoryAggregate') },
                { name: ctx.apply('i18n.t', 'Admin.Dashboard.teacher'), total: ctx.get('teacherAggregate') },
                { name: ctx.apply('i18n.t', 'Admin.Dashboard.student'), total: ctx.get('userAggregate') },
                { name: ctx.apply('i18n.t', 'Admin.Dashboard.course'), total: ctx.get('courseAggregate') },
              ];
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
