import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

export const View8 = () => {
  return (
    <DIV className="listItem">
      <div
        style={{ height: 405, boxShadow: '0px 2px 6px rgba(38, 51, 64, 0.18)' }}
        className="flex flex-col mx-2 mb-4 justify-center items-center rounded-lg bg-white500"
      >
        <div>{gstyles.icons({ name: 'courseEmpty', size: 156 })}</div>
        <div style={{ color: gstyles.colors.placeHolder }} className="px-4 text-sm text-center font-normal py-2">
          {ctx.apply('i18n.t', 'HomeSearchCourse.course_more')}
        </div>
        <Link to={ctx.get('linkHref')}>
          <div className="flex items-center">
            <div className="text-brandb500 text-base font-semibold">{ctx.apply('i18n.t', 'Advisor.discoverMore')}</div>
          </div>
        </Link>
      </div>
    </DIV>
  );
};
export default displayName(View8);
