import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Image } from '@uz/unitz-components-web/Image';
import Button from '@uz/unitz-components-web/Button';

const LogoImage = styled.div`
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 0.1;
`;

const ForSchoolFormCollectionLayout = () => {
  return (
    <DIV className="ForSchoolFormCollectionLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className={`relative overflow-hidden ${_.get(ctx.get('sectionData'), 'className', '')}`}>
            <div className="wrapper app-row">
              <LogoImage className="hidden lg:block">
                <ResponsiveProps
                  md={{ ratio: 0.5, width: 768, height: 768 * 0.5 }}
                  xs={{ style: { height: 400 }, background: true }}
                  lg={() => ({ ratio: 0.4, width: 1200, height: 1200 * 0.4 })}
                >
                  {(resProps) => (
                    <Image
                      size="cover"
                      src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                      className="w-full hero-image"
                      alt="banner"
                      {...resProps}
                    />
                  )}
                </ResponsiveProps>
              </LogoImage>

              <ResponsiveProps
                xs={{ style: { flexDirection: 'column-reverse', textAlign: 'center' } }}
                md={{ style: { flexDirection: 'row', textAlign: 'left' } }}
              >
                {(resProps) => (
                  <Row gutter={[32, 24]} justify="center" align="middle" {...resProps}>
                    <Col xs={24} md={12}>
                      {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.0.name') })}
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="mx-12">
                        <div className="mb-3">
                          <span className="text-2xl lg:text-3xl text-main font-semibold uppercase">
                            {_.get(
                              ctx.apply('ctf.findNode', { name: ctx.get('sectionData.sections.1.name') }),
                              'sections.0.shortText'
                            )}
                          </span>{' '}
                          <span className="text-2xl lg:text-3xl text-brandb-darker font-semibold uppercase">
                            {_.get(
                              ctx.apply('ctf.findNode', { name: ctx.get('sectionData.sections.1.name') }),
                              'sections.1.shortText'
                            )}
                          </span>
                        </div>
                        <div className="hidden md:block">
                          <Row>
                            <Col span={12}>
                              <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                                {(resProps) => (
                                  <Button className="w-full text-main" type="primary" {...resProps}>
                                    {_.get(
                                      ctx.apply('ctf.findNode', { name: ctx.get('sectionData.sections.1.name') }),
                                      'sections.2.shortText'
                                    )}
                                  </Button>
                                )}
                              </ResponsiveProps>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </ResponsiveProps>
              <div className="md:hidden block mt-4">
                <Row>
                  <Col span={24}>
                    <Button className="w-full text-main" size="large" type="primary">
                      {_.get(
                        ctx.apply('ctf.findNode', { name: ctx.get('sectionData.sections.1.name') }),
                        'sections.2.shortText'
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForSchoolFormCollectionLayout;
