import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

export const LineSpacingText = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return <div className={`border border-b w-full border-divider ${ctx.get('itemData.className')}`}></div>;
    })}
  </DIV>
);

export default LineSpacingText;
