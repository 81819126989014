import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';

export const ConvenienceItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemProps = ctx.get('itemProps');
      return (
        <div className="py-0 mb-3 px-0 md:px-2 lg:mb-2 lg:py-2 lg:px-3">
          <Row gutter={2}>
            <Col xs={3} md={1} lg={1}>
              <div
                className={cx(
                  ctx.apply(
                    'ctf.findEntry',
                    ctx.get('itemData'),
                    _.get(itemProps, 'fieldNames.className', 'className'),
                    ''
                  ),
                  _.get(itemProps, 'className')
                )}
              >
                {gstyles.icons({ name: 'check', size: 20, fill: gstyles.colors.branda500 })}
              </div>
            </Col>
            <Col xs={21} md={23} lg={23}>
              <div className="m-0">
                {ctx.apply(
                  'ctf.findEntry',
                  ctx.get('itemData'),
                  ...[].concat(_.get(itemProps, 'args') || ['shortText', ''])
                )}
              </div>
            </Col>
          </Row>
        </div>
      );
    })}
  </DIV>
);

export default ConvenienceItem;
