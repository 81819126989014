import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';
import _ from 'lodash';
import { getLocaleConfig, getCurrentLocaleConfig } from '@uz/mod-translations/utils-biz';
import useRoute from '@vl/hooks/useGbRouteDe';
import gstyles from '@vl/gstyles';

const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
`;

const DesktopLangSwitch = () => {
  const route = useRoute();
  const currentLocale = getCurrentLocaleConfig(route);

  return (
    <DIV className="component">
      <FormProvider form={ctx.get('form')}>
        <Form className="language-form">
          <Dropdown
            className="hidden lg:flex"
            overlay={
              <CustomDropDownContent className="shadow-xl">
                <Menu selectedKeys={[ctx.get('currentLanguage')]}>
                  {getLocaleConfig(false).map((localeConfig) => {
                    return (
                      <Menu.Item className="py-1 px-3 hover:bg-brandb-lightest" key={localeConfig.get('lang')}>
                        <span
                          className="text-main hover:text-main"
                          onClick={() => ctx.apply('submitForm.handleSubmit', localeConfig.get('lang'))}
                        >
                          <div className="flex h-full items-center space-x-2">
                            <span>
                              {gstyles.icons({
                                name: localeConfig.get('flag'),
                                size: 24,
                              })}
                            </span>
                            <span className="text-main text-base font-normal">
                              {ctx.apply('i18n.t', `SettingLanguage.${localeConfig.get('lang')}`)}
                            </span>
                          </div>
                        </span>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </CustomDropDownContent>
            }
            placement="bottomRight"
          >
            <div
              style={{ borderColor: gstyles.colors.main }}
              className="mb-4 lg:mb-0 cursor-pointer border px-2 py-2 rounded-lg"
            >
              <div className="flex items-center space-x-1">
                <div className="flex items-center space-x-1">
                  <span>
                    {gstyles.icons({
                      name: currentLocale.get('flag'),
                      size: 24,
                    })}
                  </span>
                  <span className="text-sub text-base font-semibold">
                    {ctx.apply('i18n.t', `SettingLanguage.${currentLocale.get('lang')}`)}
                  </span>
                </div>

                <div>{gstyles.icons({ name: 'arrow-down', size: 20, fill: gstyles.colors.sub })}</div>
              </div>
            </div>
          </Dropdown>
        </Form>
      </FormProvider>
    </DIV>
  );
};

export default DesktopLangSwitch;
