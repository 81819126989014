import React from 'react';

import { Link } from '@uz/unitz-components-web/Link';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import _ from 'lodash';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import * as Actions from '@uz/unitz-ctf-theme/actions';
import cx from 'classnames';

const renderers = [
  {
    name: 'anchorLink',
    test: ({ item }) => {},
    renderer: ({ item }) => {
      const activeLink = (link) => {
        return 60;
      };
      return (
        <AnchorLink offset={() => activeLink(item.linkHref)} href={item.linkHref}>
          <span className="text-white">{item.shortText}</span>
        </AnchorLink>
      );
    },
  },
  {
    name: 'actionLink',
    test: ({ item }) => {
      return !!Actions.resolveAction(item);
    },
    renderer: ({ item, submenu }) => {
      return (
        <DIV>
          {/* eslint-disable-next-line */}
          <div className="cursor-pointer" onClick={Actions.resolveAction(item)(ctx)}>
            <span
              className={cx({
                'text-white': !submenu,
              })}
            >
              {item.shortText}
            </span>
          </div>
        </DIV>
      );
    },
  },
  {
    name: 'submenuLink',
    test: ({ submenu }) => !!submenu,
    renderer: ({ item }) => {
      return <span className="">{item.shortText}</span>;
    },
  },
  {
    name: 'internalLink',
    test: ({ item }) => {
      return _.has(item, 'linkHref') && _.get(item, 'linkHref');
    },
    renderer: ({ item }) => {
      return (
        <DIV>
          <Link to={ctx.apply('routeStore.toLocale', _.get(item, 'linkHref', ''))}>
            <span className="text-white">{item.shortText}</span>
          </Link>
        </DIV>
      );
    },
  },
  {
    name: 'menuLink',
    test: ({ item }) => {
      return _.has(item, 'sections') && _.get(item, 'sections.length');
    },
    renderer: ({ item }) => {
      return (
        <DIV forceCtx>
          {ctx.apply('ctf.renderProps', () => {
            return ctx.apply('ctf.renderSection', item);
          })}
        </DIV>
      );
    },
  },
  {
    name: 'defaultLink',
    test: () => true,
    renderer: ({ item }) => {
      return <span className="text-white">{item.shortText}</span>;
    },
  },
];

const matchRender = (props) => {
  for (const item of renderers) {
    if (_.isFunction(item.test) && item.test(props)) {
      return item.renderer(props);
    }
  }
};

export const LinkWrapper = (props) => {
  return matchRender(props);
};

export default LinkWrapper;
