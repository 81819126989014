import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import UserStudySchedule from '@uz/unitz-components-web/UserStudySchedule';
import UserMyCourse from '@uz/unitz-components-web/UserMyCourse';
import _ from 'lodash';

const MyScheduleLayout = () => {
  return (
    <DIV className="MyScheduleLayout">
      {ctx.apply('ctf.renderProps', () => {
        const userId = ctx.apply('authModel.getUserId');
        if (userId) {
          return (
            <div className="bg-background1">
              {!!ctx.get('courseRoomsToday.items.length') ? <UserStudySchedule /> : <UserMyCourse />}
            </div>
          );
        }
        return null;
      })}
    </DIV>
  );
};

export default MyScheduleLayout;
