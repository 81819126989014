import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParagraph = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const PrivacySecurityPolicyContentLayout = () => {
  return (
    <DIV className="PrivacySecurityPolicyContent">
      {ctx.apply('ctf.renderProps', () => (
        <div className="py-4">
          <div className="wrapper app-row">
            <div>
              <h2 className="mb-6 text-xl sm:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              <CustomParagraph>
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'detailText.detailText', {
                  className: 'py-4 mb-0 text-main',
                  as: 'p',
                })}
              </CustomParagraph>
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default PrivacySecurityPolicyContentLayout;
