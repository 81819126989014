const _ = require('lodash');
const i18n = require('i18n-js');

// import 'moment/locale/es';

// Set the key-value pairs for the different languages you want to support.

i18n.translations = {
  en: require('./lang/en.js'),
  vi: require('./lang/vi.js'),
  de: require('./lang/de.js'),
};

// Set the locale once at the beginning of your app.
const isBrowser = () => typeof window !== 'undefined';

(async () => {
  if (isBrowser()) {
    // const origin = _.get(window, 'location.origin');
    const defaultLocaleFull = process.env.GATSBY_DEFAULT_LOCALE_FULL || 'vi-VN';
    const locale = process.env.GATSBY_DEFAULT_LOCALE || 'vi';
    console.log({ defaultLocaleFull, locale });
    i18n.defaultLocale = defaultLocaleFull;
    i18n.locale = locale;
    i18n.fallbacks = true;
  }
})();

module.exports = i18n;
