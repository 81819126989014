import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import DesktopView from './components/DesktopView';
import MobileView from './components/MobileView';

function CategoriesMenuItemLayout() {
  return (
    <DIV className="component">
      <DIV forceCtx>
        <ResponsiveProps
          xs={{ renderer: () => <MobileView /> }}
          md={{ renderer: () => <MobileView /> }}
          lg={{ renderer: () => <MobileView /> }}
          xl={{ renderer: () => <DesktopView /> }}
        >
          {(resProps) => !!resProps && resProps.renderer()}
        </ResponsiveProps>
      </DIV>
    </DIV>
  );
}

export default CategoriesMenuItemLayout;
