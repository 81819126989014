import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const HelpCenterContentLayout = () => {
  return (
    <DIV className="HelpCenterContentsContent">
      {ctx.apply('ctf.renderProps', () => (
        <div>
          <div className="wrapper app-row">
            <div>
              <h2 className="mb-6 text-xl sm:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              {/* <CustomParagraph>
                  {ctx.apply('ctf.renderHTMLText', { name: sectionData.name }, 'longText.longText', {
                    className: 'mt-5',
                    as: 'p',
                  })}
                </CustomParagraph> */}
              {/* <CustomParagraph>
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'detailText.detailText', {
                  as: 'div',
                })}
              </CustomParagraph> */}

              {_.map(
                _.get(ctx.apply('ctf.findNode', { name: ctx.get('sectionData.name') }), 'sections', []),
                (item, index) => (
                  <React.Fragment key={index}>{ctx.apply('ctf.renderItem', { name: item.name })}</React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default HelpCenterContentLayout;
