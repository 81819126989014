import React, { useEffect } from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import LayoutNavMenu from '@uz/unitz-layout-web/LayoutNavMenu';
import SearchHeaderNavbar from '@uz/unitz-components-web/SearchHeaderNavbar';

const hasScrollY = () => {
  // eslint-disable-next-line
  const [hasScroll, $hasScroll] = React.useState(false);
  // eslint-disable-next-line
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        let scroll = null;
        if (window.scrollY === 0) {
          scroll = false;
        } else {
          scroll = true;
        }
        $hasScroll(scroll);
      });
    }
  }, []);
  return hasScroll;
};

const StickyNav = styled(({ children, className }) => {
  const hasScroll = hasScrollY();
  const { observe, height } = useDimensions({
    onResize: _.throttle(() => {}, 1000),
  });
  const ref = React.useRef({});
  const placeHolderHeight = React.useMemo(() => {
    if (height > 0) {
      ref.current.height = height;
    }
    return ref.current.height;
  }, [height]);

  return (
    <div className={className}>
      <div className="header-Placeholder" style={{ height: placeHolderHeight }} />
      <header className={cx(`header-sticky ${hasScroll ? 'sticky' : ''}`)} ref={observe}>
        {children}
      </header>
    </div>
  );
})`
  > .sticky {
    position: fixed;
    box-shadow: 0px 2px 6px rgba(38, 51, 64, 0.1);
  }
`;

const MobileNavbar = () => {
  const [toggle, $toggle] = React.useState(false);
  const [displaySidebar, $displaySidebar] = React.useState(false);
  const handleToggle = () => {
    $toggle(!toggle);
    document.body.style.overflow = toggle ? 'auto' : 'hidden';
  };

  const handleDisplaySidebar = () => {
    $displaySidebar(!displaySidebar);
    document.body.style.overflow = displaySidebar ? 'auto' : 'hidden';
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <DIV>
      <div
        style={{ zIndex: 222 }}
        className={cx(
          'fixed transform duration-200 ease-in-out h-screen h-fill-screen w-screen',
          {
            '-translate-x-full': !displaySidebar,
          },
          {
            '-translate-x-0': displaySidebar,
          }
        )}
      >
        <LayoutNavMenu>
          <LayoutNavMenu.POS name="nav-profile">
            <div className="w-full flex items-start justify-between">
              <div className="flex w-full search-menu-account-wrapper">{ctx.apply('ctf.renderSectionChild', 2)}</div>
              <button className="focus:outline-none cursor-pointer mt-6 ml-6" onClick={handleDisplaySidebar}>
                {gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.brandb500 })}
              </button>
            </div>
          </LayoutNavMenu.POS>
          <LayoutNavMenu.POS name="nav-ctf-menu">
            <div className="flex flex-col items-start flex-1">{ctx.apply('ctf.renderSectionChild', 1)}</div>
          </LayoutNavMenu.POS>
        </LayoutNavMenu>
      </div>
      <div
        style={{ zIndex: 65 }}
        className={cx('fixed transform duration-100 ease-in-out bg-black300 w-full h-screen', {
          'top-0 block': displaySidebar,
          'block top-16': toggle,
          ' hidden': !toggle && !displaySidebar,
        })}
      ></div>
      <StickyNav>
        <SectionWrapper
          style={{
            background:
              'linear-gradient(180deg, #EFFBFA 100%, rgba(239, 251, 250, 0.4) 89.87%, rgba(239, 251, 250, 0) 100%)',
          }}
          className="h-16 bg-brandb relative"
          as="div"
        >
          <div className="flex flex-row h-16 px-4 app-row">
            <div className="flex justify-start items-center flex-1">
              <button className="focus:outline-none cursor-pointer" onClick={handleDisplaySidebar}>
                {gstyles.icons({ name: 'menu', size: 24, fill: gstyles.colors.brandb500 })}
              </button>
            </div>

            <div className="m-2 flex justify-center items-center flex-1">
              {!toggle ? (
                <a
                  href={ctx.apply(
                    'ctf.findEntry',
                    { name: ctx.apply('ctf.findSectionChild', 0, 'name') },
                    'linkHref',
                    ''
                  )}
                >
                  <Image
                    size={40}
                    src={ctx.apply(
                      'ctf.findImage',
                      { name: ctx.apply('ctf.findSectionChild', 0, 'name') },
                      'image',
                      ''
                    )}
                    alt="banner"
                  />
                </a>
              ) : // eslint-disable-next-line
              null}
            </div>
            <div className="flex justify-end items-center flex-1">
              <div className="flex items-center justify-center">
                {toggle ? (
                  <SearchHeaderNavbar handleToggle={handleToggle} />
                ) : (
                  // eslint-disable-next-line
                  <div
                    onClick={handleToggle}
                    className="shadow-sm bg-brandb500 rounded-full cursor-pointer flex justify-center items-center w-8 h-8"
                  >
                    {gstyles.icons({
                      name: 'search',
                      size: 20,
                      fill: gstyles.colors.white500,
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </SectionWrapper>
      </StickyNav>
    </DIV>
  );
};

export default MobileNavbar;
