import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import UserCourseItemX from '@uz/unitz-components-web/UserCourseItemX';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Row, Col } from 'antd';

const CustomCarousel = styled.div`
  .slick-slider {
    .slick-list {
      padding-bottom: 24px !important;
      .slick-track {
        margin-top: 16px;
      }
    }
    .slick-dots {
      bottom: -25px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.brandb.lighter};
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }
        &:before {
          content: '';
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div
        className="absolute rounded-full filter drop-shadow-md z-50 cursor-pointer ml-20 left-2/4 transform translate-x-2/4 -bottom-8 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-right', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div
        className="absolute bg-white500 rounded-full flex items-center -ml-24 justify-center w-10 h-10 filter drop-shadow-md z-50 cursor-pointer left-2/4 transform -translate-x-full -bottom-8 hover:opacity-90"
        onClick={onClick}
      >
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-left', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    scrollbar-width: none;
    display: none;
    -ms-overflow-style: none;
  }
`;

export const View8 = () => {
  return (
    <DIV className="carouselContainer">
      <React.Fragment>
        <ResponsiveProps
          xs={{
            renderer: () => (
              <ScrollX className="relative flex pb-3 overflow-x-scroll lg:overflow-x-hidden">
                <Row className="pt-3" wrap={false}>
                  {_.map(_.take(ctx.get('@items', []), 8), (item, index) => {
                    return (
                      <DIV>
                        {ctx.debug(() => {
                          ctx.set('@item', item);
                          ctx.set('@index', index);
                        })}
                        <Col span={ctx.get('@items.length') >= 3 ? 6 : 24}>
                          <UserCourseItemX />
                        </Col>
                      </DIV>
                    );
                  })}
                </Row>
              </ScrollX>
            ),
          }}
          md={{
            renderer: () => (
              <React.Fragment>
                {ctx.get('@items.length') <= 2 ? (
                  <Row className="pt-3">
                    {_.map(_.take(ctx.get('@items', []), 6), (item, index) => {
                      return (
                        <DIV>
                          {ctx.debug(() => {
                            ctx.set('@item', item);
                            ctx.set('@index', index);
                          })}
                          <Col span={8}>
                            <UserCourseItemX />
                          </Col>
                        </DIV>
                      );
                    })}
                  </Row>
                ) : (
                  <CustomCarousel>
                    <div className="pt-3 pb-6">
                      <Slider
                        className="relative"
                        {...ctx.get('carouselModel.options')}
                        {...{
                          nextArrow: <NextButton onClick />,
                          prevArrow: <PrevButton onClick />,
                        }}
                      >
                        {_.map(_.take(ctx.get('@items', []), 8), (item, index) => (
                          <React.Fragment key={`item_${index}`}>
                            <DIV forceCtx>
                              {ctx.debug(() => {
                                ctx.set('@item', item);
                                ctx.set('@index', index);
                              })}
                              <UserCourseItemX />
                            </DIV>
                          </React.Fragment>
                        ))}
                      </Slider>
                    </div>
                  </CustomCarousel>
                )}
              </React.Fragment>
            ),
          }}
        >
          {(resProps) => !!resProps && resProps.renderer()}
        </ResponsiveProps>
      </React.Fragment>
    </DIV>
  );
};
export default displayName(View8);
