import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';

const UnitzProductListLayout = () => {
  return (
    <DIV className="UnitzProductListLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <>
            <div className="text-xl lg:text-3xl font-semibold text-center lg:text-left mb-3">
              {ctx.get('sectionData.shortText')}
            </div>
            <Row gutter={[0, 16]}>
              {_.map(ctx.get('sectionData.sections'), (item, index) => (
                <Col key={index} xs={24} lg={20}>
                  {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                </Col>
              ))}
            </Row>
          </>
        );
      })}
    </DIV>
  );
};

export default UnitzProductListLayout;
