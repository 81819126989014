import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';

const bindData = bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              // eslint-disable-next-line
              const itemData = ctx.get('@item');
              const advisorProfile = _.get(itemData, 'advisor.profile', '');
              return {
                ...itemData,
                advisor: {
                  displayName: AdvisorProfileFormatter.displayName(ctx)(advisorProfile),
                  avatarUrl: AdvisorProfileFormatter.avatarUrl(ctx)(advisorProfile),
                },
                courseInfo: {
                  id: _.get(itemData, 'id'),
                  displayName: CourseFormatter.displayName(ctx)(itemData),
                  avatarUrl: CourseFormatter.avatarUrl(ctx)(itemData),
                },
                startAt: CourseFormatter.startAt(ctx)(itemData),
                session_duration: CourseFormatter.duration(ctx)(itemData),

                price: {
                  amountText: CourseFormatter.priceAmount(ctx)(itemData),
                  unitText: CourseFormatter.priceUnitText(ctx)(itemData),
                },
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
