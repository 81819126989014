import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Space } from 'antd';
import _ from 'lodash';

export const FAQItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <div id={itemData.slug}>
          <div>
            <Space className="mb-2">
              <p className="font-semibold">Q:</p>
              <p >{_.get(itemData, 'shortText', '')}</p>
              {/* {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'longText.longText', {
                className: 'text-sm font-medium',
                as: 'p',
              })} */}
            </Space>
          </div>
          <div>
            <Space align="start">
              <p>A:</p>
              {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'detailText.detailText', {
                className: 'mb-0',
                as: 'p',
              })}
            </Space>
          </div>
        </div>
      );
    })}
  </DIV>
);

export default FAQItem;
