import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Row, Col } from 'antd';
import { Image } from '@uz/unitz-components-web/Image';

const CustomParapraph = styled.div`
  p {
    font-weight: 600;
    margin-bottom: 16px;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const CustomDetailText = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;

export const WorkingStep = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <Row
          gutter={[
            { xs: 0, md: 16, lg: 60 },
            { xs: 24, xl: 32 },
          ]}
          className="mb-4 md:mb-6 lg:mb-12 justify-center items-center"
        >
          <Col className="content" lg={12} md={12} sm={24}>
            <ResponsiveProps
              xs={{
                renderer: () => (
                  <div className="flex flex-col justify-center items-center">
                    {_.isNull(_.get(itemData, 'className')) ? (
                      <div className="mb-4 flex justify-center items-center rounded-full w-14 h-14 bg-brandb-lighter text-3xl font-semibold text-brandb500">
                        {_.get(itemData, 'shortText', '')}
                      </div>
                    ) : (
                      <span className="mb-4 inline-block py-1 px-4 rounded-full bg-brandb-lighter text-2xl font-semibold text-brandb500">
                        {_.get(itemData, 'shortText', '')}
                      </span>
                    )}

                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {
                        className: 'text-center text-base mb-4',
                        as: 'p',
                      })}
                    </CustomParapraph>
                    <CustomDetailText>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'detailText.detailText', {
                        className: 'text-center text-base mb-4',
                        as: 'p',
                      })}
                    </CustomDetailText>
                  </div>
                ),
              }}
              md={{
                renderer: () => (
                  <div>
                    {_.isNull(_.get(itemData, 'className')) ? (
                      <div className="mb-4 flex justify-center items-center rounded-full w-14 h-14 bg-brandb-lighter text-3xl font-semibold text-brandb500">
                        {_.get(itemData, 'shortText', '')}
                      </div>
                    ) : (
                      <span className="mb-4 inline-block py-1 px-4 rounded-full bg-brandb-lighter text-2xl font-semibold text-brandb500">
                        {_.get(itemData, 'shortText', '')}
                      </span>
                    )}
                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {})}
                    </CustomParapraph>
                    <CustomDetailText>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'detailText.detailText', {
                        className: 'text-left text-base mb-4',
                        as: 'p',
                      })}
                    </CustomDetailText>
                  </div>
                ),
              }}
              lg={{
                renderer: () => (
                  <div>
                    {_.isNull(_.get(itemData, 'className')) ? (
                      <div className="mb-4 flex justify-center items-center rounded-full w-14 h-14 bg-brandb-lighter text-3xl font-semibold text-brandb500">
                        {_.get(itemData, 'shortText', '')}
                      </div>
                    ) : (
                      <span className="mb-4 inline-block py-1 px-4 rounded-full bg-brandb-lighter text-3xl font-semibold text-brandb500">
                        {_.get(itemData, 'shortText', '')}
                      </span>
                    )}

                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {})}
                    </CustomParapraph>
                    <CustomDetailText>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'detailText.detailText', {
                        className: 'text-left text-base mb-4',
                        as: 'p',
                      })}
                    </CustomDetailText>
                  </div>
                ),
              }}
            >
              {(resProps) => !!resProps && resProps.renderer()}
            </ResponsiveProps>
          </Col>
          <Col className="order-1" lg={12} md={12} sm={24}>
            <div className="mb-3 sm:mb-0">
              <ResponsiveProps
                md={() => ({ ratio: 0.5, width: 768, height: 768 * 0.5 })}
                xs={() => ({ ratio: 0.6, width: 420, height: 420 * 0.6 })}
                lg={() => ({ ratio: 0.8, width: 600, height: 600 * 0.8 })}
                xl={() => ({ ratio: 0.8, width: 600, height: 600 * 0.8 })}
              >
                {(resProps) => (
                  <Image
                    className="object-cover"
                    size="cover"
                    src={ctx.apply('ctf.findImage', itemData, 'image', '')}
                    alt={_.get(itemData, 'id')}
                    preview={false}
                    srcParams={{ fit: 'pad' }}
                    {...resProps}
                  />
                )}
              </ResponsiveProps>
            </div>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default WorkingStep;
