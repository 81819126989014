import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';

import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import LayoutNavMenu from '@uz/unitz-layout-web/LayoutNavMenu';
import SearchHeaderNavbar from '@uz/unitz-components-web/SearchHeaderNavbar';
import LangSwitchTransparent from '@uz/unitz-ctf-theme/layouts/LangSwitchTransparentUS';
import { getCurrentLocaleConfig } from '@uz/mod-translations/utils-biz';
import useRoute from '@vl/hooks/useGbRouteDe';
import { Drawer } from 'antd';

const TabletNavbar = () => {
  const [visible, setVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const route = useRoute();
  const currentLocale = getCurrentLocaleConfig(route);
  // const [toggle, $toggle] = React.useState(false);
  const [lang, $lang] = React.useState(false);

  // const handleToggle = () => {
  //   $toggle(!toggle);
  //   document.body.style.overflow = toggle ? 'auto' : 'hidden';
  // };

  const flags = {
    vi: 'vietnam-flag',
    en: 'uk-flag',
    de: 'german-flag',
  };

  return (
    <DIV>
      <Drawer
        placement="left"
        bodyStyle={{ padding: 0 }}
        closable={false}
        onClose={onClose}
        width={'70%'}
        visible={visible}
      >
        <LayoutNavMenu>
          <LayoutNavMenu.POS name="nav-lang">
            <div className="flex justify-between items-center p-4">
              <button className="focus:outline-none cursor-pointer" onClick={onClose}>
                {gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.main })}
              </button>
              <div className="flex items-center space-x-2" onClick={() => $lang(!lang)}>
                <span>
                  {gstyles.icons({
                    name: _.get(flags, currentLocale.get('lang'), ''),
                    size: 24,
                  })}
                </span>
                <span className="text-main">{ctx.apply('i18n.t', `SettingLanguage.${currentLocale.get('lang')}`)}</span>
                <span>
                  {gstyles.icons({
                    name: 'arrow-down3',
                    size: 24,
                  })}
                </span>
              </div>
            </div>
            {!!lang && <LangSwitchTransparent />}
          </LayoutNavMenu.POS>

          <LayoutNavMenu.POS name="nav-profile">
            <div className="flex w-full search-menu-account-wrapper">{ctx.apply('ctf.renderSectionChild', 2)}</div>
          </LayoutNavMenu.POS>

          <LayoutNavMenu.POS name="nav-ctf-menu">
            <div className="flex flex-col items-start flex-1">{ctx.apply('ctf.renderSectionChild', 1)}</div>
          </LayoutNavMenu.POS>
        </LayoutNavMenu>
      </Drawer>

      {/* <div
        style={{ zIndex: 99 }}
        className={cx('fixed transform duration-100 ease-in-out bg-black300 w-full h-screen h-fill-screen', {
          'block top-16': toggle,
          ' hidden': !toggle,
        })}
      ></div> */}

      <SectionWrapper className="h-16 bg-white500 relative shadow-md" as="div">
        <div className="flex flex-row h-16 px-4 app-row">
          <div className="flex justify-start items-center flex-1">
            <button className="focus:outline-none cursor-pointer" onClick={showDrawer}>
              {gstyles.icons({ name: 'menu', size: 24, fill: gstyles.colors.main })}
            </button>
          </div>

          <div className="m-2 flex justify-center items-center flex-1">
            {/* {!toggle ? (
              
            ) : // eslint-disable-next-line
            null} */}
            <a href="/">
              <Image
                size={40}
                src={ctx.apply('ctf.findImage', { name: ctx.apply('ctf.findSectionChild', 0, 'name') }, 'image', '')}
                alt="banner"
              />
            </a>
          </div>
          <div className="flex justify-end items-center flex-1">
            <div className="flex items-center justify-center">
              {/* {toggle ? null : ( // <SearchHeaderNavbar handleToggle={handleToggle} />
                // eslint-disable-next-line
                
              )} */}
              <div
                // onClick={handleToggle}
                className="shadow-sm bg-transparent cursor-pointer flex justify-center items-center w-8 h-8"
              >
                {gstyles.icons({
                  name: 'search',
                  size: 24,
                  fill: gstyles.colors.main,
                })}
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </DIV>
  );
};

export default TabletNavbar;
