import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import TestimonialItem from '@uz/unitz-components-web/TestimonialItem';
import CarouselNextButton from '@uz/unitz-components-web/CarouselNextButton';
import CarouselPrevButton from '@uz/unitz-components-web/CarouselPrevButton';
import _ from 'lodash';

const CustomCarousel = styled.div`
  .slick-slider {
    .slick-dots {
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.brandb.lighter};
          border-radius: 50%;
          width: 8px;
          height: 8px;
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

function BusinessTestimonial() {
  const carouselOptions = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          swipeToSlide: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <DIV className="carouselContainer">
      <div className="py-0 sm:wrapper app-row">
        {ctx.apply('ctf.renderProps', () => (
          <CustomCarousel>
            <div className="py-8 md:py-6 lg:py-14 md:mx-4 lg:mx-0 border-b-2 md:border-b-0 lg:border-b-0 rounded-none sm:rounded-xl bg-brandb-lightest">
              <h2 className="text-xl sm:text-2xl lg:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              <div className="lg:wrapper app-row px-0 py-4 md:px-8 lg:px-12">
                <Slider
                  className="relative"
                  {...carouselOptions}
                  {...{
                    nextArrow: <CarouselNextButton onClick />,
                    prevArrow: <CarouselPrevButton onClick />,
                  }}
                >
                  {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                    return <TestimonialItem key={index} itemData={item} />;
                  })}
                </Slider>
              </div>
            </div>
          </CustomCarousel>
        ))}
      </div>
    </DIV>
  );
}

export default BusinessTestimonial;
