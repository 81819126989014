import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { formatDateDisplay } from '@vl/mod-utils/dateFormatter';
import currencyFormatter, { formatVND, normalizeNumber, toCommas } from '@vl/mod-utils/currencyFormatter';

const xss = require('xss');

const DAY_MAP = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const formatter = {
  yearText: (ctx) => (itemData) => {
    const end_at = _.get(itemData, 'end_at', '');
    if (!end_at) return '';
    return moment(end_at).year();
  },
  createdAtTxt: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'created_at', '');
    if (!createdAt) return '';
    return ctx.apply('i18n.t', 'Advisor.joinedSince', { dateStr: moment(createdAt).format('Do MMM, YYYY') });
  },
  updatedAtTxt: (ctx) => (itemData) => {
    const updatedAt = _.get(itemData, 'updated_at', '');
    if (!updatedAt) return '';
    return moment(updatedAt).format('Do MMM, YYYY');
  },
  createAtByMonthSlashYear: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'sys.firstPublishedAt', '');
    if (!createdAt) return '';
    return moment(createdAt).format('MM/YYYY');
  },

  titleText: (ctx) => (itemData) => {
    const name = _.get(itemData, 'name', '');
    if (!name) return '';
    return name;
  },
  dayTitle: (ctx) => (dayIndex) => {
    return ctx.apply('i18n.t', `Weeks.${DAY_MAP[dayIndex]}`);
  },
  detailHtml: (ctx) => (itemData) => {
    const detail = _.get(itemData, 'detail_html', '');
    if (!detail) return '';
    return detail;
  },

  renderHTML: (ctx) => (itemData) => {
    const detail = _.get(itemData, 'detail_html', '');
    if (!detail) return '';
    const cleanHTML = xss(detail);
    return React.createElement('p', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },

  profileId: () => (itemData) => {
    return _.get(itemData, 'id', '');
  },
  priceService: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount', 0);
    if (!price_amount) return 0;
    const currency = _.get(itemData, 'price.price_currency', 0);

    return `${formatVND(price_amount, currency)}`;
  },
  pricePerMin: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount');
    const per_amount = _.get(itemData, 'price.per_amount');
    if (!price_amount || !per_amount) return 0;
    return currencyFormatter(ctx)((parseInt(price_amount) / per_amount) * 60);
  },
  pricePerMinTitle: (ctx) => (itemData) => {
    const currency = _.get(itemData, 'price.price_currency');
    const unit = _.get(itemData, 'price.per_unit');
    if (!currency || !unit) return '';
    return ctx.apply('i18n.t', 'Advisor.pricePerMin', { currency, unit: 'MINUTE' });
  },
  pricePerMinText: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'price.price_amount');
    const per_amount = _.get(itemData, 'price.per_amount');
    if (!price_amount || !per_amount) return 0;
    return toCommas(_.round((parseInt(price_amount) / per_amount) * 60));
  },
  currentPerUnit: (ctx) => (itemData) => {
    const currency = _.get(itemData, 'price.price_currency');
    const unit = _.get(itemData, 'price.per_unit');
    if (!currency || !unit) return '';
    return ctx.apply('i18n.t', 'Advisor.pricePerMin', { currency: currency === 'vnd' ? 'đ' : currency, unit: 'PHÚT' });
  },
  displayName: () => (itemData) => {
    return _.get(itemData, 'display_name', '');
  },
  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.displayName(ctx)(itemData);
    return _.get(itemData, 'avatar_url') || `https://ui-avatars.com/api/?name=${displayName}`;
  },
  videoUrl: () => (itemData) => {
    return _.get(itemData, 'video_url', '');
  },
  categories: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'categories') || [];
    const allContentfulCategoryIds = _.map(ctx.apply('ctf.getNodes', 'allContentfulCategory.nodes', []), 'id');

    return _.compact(
      arr.map((item, index) => {
        const _item = _.get(item, 'category') || item;
        const catId = _.get(_item, 'id');
        if (allContentfulCategoryIds.length && !allContentfulCategoryIds.includes(catId)) {
          return null;
        }
        return {
          displayName: formatter.displayName()(_item),
          icon: _.get(_item, 'icon') || '',
          id: catId || index,
        };
      })
    );
  },
  categoriesText: (ctx) => (itemData) => {
    const arr = _.get(itemData, 'categories') || [];
    const allContentfulCategoryIds = _.map(ctx.apply('ctf.getNodes', 'allContentfulCategory.nodes', []), 'id');

    return _.filter(
      _.compact(
        arr.map((item, index) => {
          const _item = _.get(item, 'category') || item;
          const catId = _.get(_item, 'id');
          if (allContentfulCategoryIds.length && !allContentfulCategoryIds.includes(catId)) {
            return null;
          }
          return formatter.displayName()(_item);
        })
      ),
      (item) => item
    ).join(', ');
  },

  detailText: () => (itemData) => {
    return _.get(itemData, 'detail_text') || '--';
  },
  introText: () => (itemData) => {
    return (
      _.get(itemData, 'intro_text') ||
      _.get(itemData, 'intro_text_en_US') ||
      _.get(itemData, 'intro_text_vi_VN') ||
      '--'
    );
  },

  introTextVN: () => (itemData) => {
    return _.get(itemData, 'intro_text_vi_VN') || '--';
  },

  introTextUS: () => (itemData) => {
    return _.get(itemData, 'intro_text_en_US') || '--';
  },

  status: (ctx) => (itemData) => {
    const processData = _.get(itemData, '0', itemData);
    const statusData = {
      isReady: !!_.get(processData, 'readiness.is_accept_service') && !!_.get(processData, 'readiness.is_ready'),
      isOnline: !!_.get(processData, 'readiness.is_accept_service'),
      isOffline: !_.get(processData, 'readiness.is_accept_service'),
      isBusy: !!_.get(processData, 'readiness.is_accept_service') && !_.get(processData, 'readiness.is_ready'),
    };
    return statusData;
  },

  skills: () => (itemData) => {
    const skills = _.get(itemData, 'skills', []);
    if (!skills) return;
    return _.map(
      skills,
      (skill, index) => `${_.get(skill, 'skill.display_name', '')}${index < skills.length - 1 ? ', ' : ''}`
    );
  },

  packageName: (ctx) => (itemData) => {
    const per_amount = _.get(itemData, 'price.per_amount');
    if (!per_amount) return '';
    return ctx.apply('i18n.t', 'AdvisorInCome.inComeTextPackage', { num: _.round(per_amount / 60) });
  },
  photos: (ctx) => (itemData) => {
    const photos = _.get(itemData, 'photos', null);
    if (!photos) return null;
    return _.map(photos, (photo) => {
      const src = _.get(photo, 'src', '');
      return src.slice(0, 2) === '//' ? { src: `https:${src}` } : { src };
    });
  },
  services: (ctx) => (itemData = []) => {
    const services = _.map(itemData, (item) => ({
      id: _.get(item, 'service.id'),
      service_id: _.get(item, 'id'),
      kind: _.get(item, 'service.kind', ''),
      name: `${_.upperFirst(_.get(item, 'service.kind'))}Service`,
      nameTxt: _.get(item, 'service.name'),
      packageName: formatter.packageName(ctx)(item),
      priceTxt: formatter.priceService()(item),
      icon: _.get(item, 'service.kind') === 'video' ? 'camera' : _.get(item, 'icon'),
      price: {
        ..._.get(item, 'price'),
        pricePerMinTxt: formatter.pricePerMinText(ctx)(item),
        pricePerMinTitle: formatter.currentPerUnit(ctx)(item),
      },
      discounts: _.get(item, 'discounts', []),
      discount: {
        ..._.get(item, 'discounts.0', {}),
      },
      status: _.get(item, 'service.status'),
    }));
    const groupServices = _.groupBy(services, (ser) => ser.name);
    return groupServices;
  },
  percentageText: (ctx) => (itemData) => {
    const percentage = _.get(itemData, 'discounts.0.percentage', 0);
    if (!percentage) return 0;
    return `${percentage} %`;
  },
  amountText: (ctx) => (itemData) => {
    const amount = _.get(itemData, 'discounts.0.amount', 0);
    if (!amount) return 0;
    return formatVND(amount);
  },
  packages: (ctx) => (itemData = [], kind) => {
    const packs = _.map(
      _.filter(itemData, (ser) => _.get(ser, 'service.kind', '') === kind),
      (item) => ({
        id: _.get(item, 'service.id'),
        service_id: _.get(item, 'id'),
        kind: _.get(item, 'service.kind', ''),
        name: `${_.upperFirst(_.get(item, 'service.kind'))}Service`,
        packageName: formatter.packageName(ctx)(item),
        nameTxt: _.get(item, 'service.name'),
        priceTxt: formatter.priceService()(item),
        icon: _.get(item, 'service.kind') === 'video' ? 'camera' : _.get(item, 'icon'),
        price: {
          ..._.get(item, 'price'),
          pricePerMinTxt: formatter.pricePerMinText(ctx)(item),
          pricePerMinTitle: formatter.currentPerUnit(ctx)(item),
        },
        discounts: _.get(item, 'discounts', []),
        discount: {
          ..._.get(item, 'discounts.0', {}),
          percentageText: formatter.percentageText(ctx)(item),
          amountText: formatter.amountText(ctx)(item),
        },
        status: _.get(item, 'service.status'),
      })
    );
    return packs;
  },
  reviewText: (ctx) => (itemData) => {
    const review = _.get(itemData, 'review_agg.avg_score');
    const number = 0;
    if (!review) return number.toFixed(1);
    return _.round(parseFloat(review) / 2, 1).toFixed(1);
  },
  reviewScore: (ctx) => (itemData) => {
    const review = _.get(itemData, 'review_agg.avg_score');
    if (!review) return 0;
    return _.round(parseFloat(review) / 2, 1);
  },

  courseCount: () => (itemData) => {
    return _.get(itemData, 'aggregate.count') || '0';
  },
  reviewCount: (ctx) => (itemData) => {
    return _.get(itemData, 'count') || '0';
  },

  reviews: (ctx) => (itemData) => {
    return _.map(itemData, (item) => {
      return {
        id: _.get(item, 'id'),
        profile: {
          displayName: _.get(item, 'author.profile.display_name') || ctx.apply('i18n.t', 'AdvisorProfile.noName'),
          avatarUrl: _.get(item, 'author.profile.avatar_url'),
          seenAtText: moment(_.get(item, 'updated_at'), 'YYYYMMDD').fromNow(),
        },
        reviewText: _.get(item, 'review_text'),
        rating: {
          ratingTxt: formatter.reviewText(ctx)({ review_agg: { avg_score: _.get(item, 'score') || 0 } }),
          ratingScore: formatter.reviewScore(ctx)({ review_agg: { avg_score: _.get(item, 'score') || 0 } }),
        },
      };
    });
  },
  totalReview: (ctx) => (itemData) => {
    const score = _.get(itemData, 'avg.aggregate.count', 0);
    if (!score) return 0;
    return score;
  },
  avgScore: (ctx) => (itemData) => {
    const score = _.get(itemData, 'avg.aggregate.avg.score', 0);
    if (!score) return 0;
    return _.round(score / 2, 1);
  },
  totalReviewByNum: (ctx) => (itemData) => {
    const score = _.get(itemData, 'aggregate.count', 0);
    if (!score) return 0;
    return score;
  },
  maxReview: (ctx) => (itemData) => {
    const maxReview = _.max(_.times(5, (num) => formatter.totalReviewByNum(ctx)(_.get(itemData, `star${num + 1}`))));
    if (!maxReview) return 0;
    return maxReview;
  },
  percentByNum: (ctx) => (itemData, num) => {
    const maxReview = normalizeNumber(formatter.maxReview(ctx)(itemData));

    const totalReviewByNum = formatter.totalReviewByNum(ctx)(_.get(itemData, `star${num}`));
    return maxReview > 0 ? Math.round((normalizeNumber(totalReviewByNum) / maxReview) * 100) : 0;
  },

  urlUpload: (ctx) => (itemData) => {
    const userId = _.get(itemData, 'user_id', '');
    let urlName = _.get(itemData, 'photoName', '');
    const subFolder = _.get(itemData, 'subFolder', 'Photo');
    const ext = _.get(_.split(urlName, '.'), '1');
    urlName = `${new Date().getTime()}.${ext || 'jpg'}`;
    return `${userId}/${subFolder}/${urlName}`;
  },
  appraisalType: (ctx) => (itemData) => {
    const type = _.get(itemData, 'appraisal_type', '--');
    return {
      howToSpeak: _.get(type, 'howToSpeak') || 0,
      speakingSpeed: _.get(type, 'speakingSpeed') || 0,
      howToConveyTheResult: _.get(type, 'howToConveyTheResult') || 0,
    };
  },
  calendar: (ctx) => (itemData) => {
    const calendar = _.get(itemData, 'calendar') || [];
    const newCalendar = _.map(calendar, (cal, index) => {
      if (index === 'NaN') return;
      return {
        day: formatter.dayTitle(ctx)(index),
        data: _.map(cal, (slot) => {
          const startTime = moment(slot[0], 'HH:mm').format('HH:mm');
          const endTime = moment(slot[1], 'HH:mm').format('HH:mm');
          return {
            text: `${startTime} - ${endTime}`,
          };
        }),
      };
    });
    return newCalendar.filter((item) => !!(item && item.data.length));
  },
  totalCourse: (ctx) => (itemData) => {
    const total = _.get(itemData, 'courses_aggregate.aggregate.count', 0);
    if (!total) return 0;
    return total;
  },

  isPublished: (ctx) => (itemData) => {
    return _.get(itemData, 'is_published', '');
  },
  yearsExperience: (ctx) => (itemData) => {
    if (_.isNull(_.get(itemData, 'years_experience'))) return 0;
    return _.get(itemData, 'years_experience');
  },
};

export default formatter;
