import React from 'react';
import { bindings, hook } from '@vl/redata';
import { message } from 'antd';

const bindData = bindings({
  messageModel: {
    rules: [
      [
        'data',
        {
          data: {
            message: hook(() => {
              return message;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
