import React from 'react';
import { Avatar as AntdAvatar } from 'antd';
import _ from 'lodash';
import { createImageUrl } from '@vl/mod-utils/file';

const omitProps = ['srcParams', 'ratio'];

export const Avatar = (props) => {
  // eslint-disable-next-line
  const imageProps = React.useMemo(() => createImageUrl(props), [props.src]);
  return <AntdAvatar {..._.omit({ ...props, ...imageProps }, omitProps)} />;
};

Avatar.createImageUrl = createImageUrl;

export default Avatar;
