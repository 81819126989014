import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import Slider from 'react-slick';
import EducationTestimonialItem from '@uz/unitz-components-web/EducationTestimonialItem';
import EducationTestimonialNextButton from '@uz/unitz-components-web/EducationTestimonialNextButton';
import EducationTestimonialPrevButton from '@uz/unitz-components-web/EducationTestimonialPrevButton';

export const View8 = () => {
  return (
    <DIV className="carouselContainer">
      <Slider
        className="relative"
        {...ctx.get('carouselOptions')}
        {...{
          nextArrow: <EducationTestimonialNextButton onClick />,
          prevArrow: <EducationTestimonialPrevButton onClick />,
        }}
      >
        {ctx.get('sectionData', []).map((item, index) => {
          return <EducationTestimonialItem key={index} itemData={item} />;
        })}
      </Slider>
    </DIV>
  );
};
export default displayName(View8);
