import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';
import Link from '@uz/unitz-components-web/Link';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
    white-space: break-spaces;
  }
`;

export const ProductReferralItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <Link to={ctx.get('itemData.linkHref')}>
          <div className="flex items-center border border-border rounded-lg bg-white500 p-4 h-full">
            <div className="table-cell align-top">
              <div className="h-full w-full flex items-center justify-center flex-col">
                <Image
                  width={60}
                  height={60}
                  src={ctx.apply('ctf.findImage', ctx.get('itemData'), 'image', '')}
                  alt={ctx.get('itemData.name')}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
            <div className="table-cell align-top pl-5">
              <div className="h-full w-full flex flex-col">
                <div className="text-left">
                  <div className="font-semibold text-base lg:text-xl mb-2 text-main">
                    {_.get(ctx.get('itemData'), 'shortText', '')}
                  </div>
                  <CustomParagraph>
                    {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name', '') }, 'longText.longText', null)}
                  </CustomParagraph>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    })}
  </DIV>
);

export default ProductReferralItem;
