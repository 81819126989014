import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

import { Row, Col, Space } from 'antd';
// import { Image } from '@uz/unitz-components-web/Image';

import gstyles from '@vl/gstyles';
// import { Link } from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import styled from 'styled-components';

const CustomParapraph = styled.div`
  p > p {
    margin-bottom: 16px;
    color: ${gstyles.colors.main};
  }
`;

function BusinessHandBook() {
  return (
    <DIV className="component">
      <div className="py-0 sm:wrapper app-row">
        {/* <div className="border-b-2" /> */}
        {ctx.apply('ctf.renderProps', () => {
          return (
            <Row className="wrapper app-row sm:px-4 xl:px-0 sm:w-full md:py-6 xl:py-16">
              <Col lg={12} md={12} sm={24}>
                <div className="w-full md:w-11/12">
                  {_.map(ctx.get('sectionData.sections'), (section, index) => (
                    <React.Fragment key={index}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                    </React.Fragment>
                  ))}
                </div>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <div className="flex h-full w-full justify-center items-center">
                  <div>
                    <h2 className="my-4 md:my-0 sm:mb-2 lg:mb-8 text-xl text-center lg:text-left md:text-2xl lg:text-4xl font-semibold text-main">
                      {ctx.get('sectionData.shortText')}
                    </h2>
                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                        className: 'text-sm sm:text-base font-normal',
                        as: 'p',
                      })}
                    </CustomParapraph>
                    {/* <Link to={ctx.apply('routeStore.toLocale', '/beginner-guide')}>
                      <Space>
                        <h5 className="mb-0 text-base font-semibold text-brandb500">
                          {ctx.apply('i18n.t', 'Handbook.detail')}
                        </h5>
                        {gstyles.icons({ name: 'arrow-right2', size: 20, fill: gstyles.colors.brandb500 })}
                      </Space>
                    </Link> */}
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
        {/* <div className="border-b-2" /> */}
      </div>
    </DIV>
  );
}

export default BusinessHandBook;
