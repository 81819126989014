import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import Link from '@uz/unitz-ctf-theme/components/Link';
import cx from 'classnames';
import _ from 'lodash';

const MobileMenu = () => {
  const [active] = React.useState();
  return (
    <DIV>
      <nav className="navbar w-full navbar-wrapper menu-wrapper">
        {_.map(ctx.get('sectionData.sections', []), (section, index) => {
          const menuItem = ctx.apply('ctf.findEntry', { name: section.name });
          if (!menuItem) return null;

          return (
            <React.Fragment key={index}>
              <DIV>
                {ctx.apply('ctf.renderEntry', menuItem) || (
                  <li className={cx('nav-item-box block', { ' active': active === menuItem.linkHref })}>
                    <Link item={menuItem} />
                  </li>
                )}
              </DIV>
            </React.Fragment>
          );
        })}
      </nav>
    </DIV>
  );
};

export default MobileMenu;
