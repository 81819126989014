import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';
import cx from 'classnames';
import Link from '@uz/unitz-components-web/Link';

const CustomLongText = styled.div`
  p > p {
    color: ${gstyles.colors.main};
    @media only screen and (min-width: 320px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }
`;

export const ImageAndTextYItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <Row className="text-center items-center" gutter={[24, 16]}>
          <Col xs={24}>
            <div className="h-full w-full flex flex-col">
              <div className={cx('mb-4 w-full', { 'mx-auto': ctx.get('itemData.className') === 'text-center' })}>
                <Image
                  style={{ width: '100%' }}
                  height={200}
                  src={ctx.apply('ctf.findImage', ctx.get('itemData'), 'image', '')}
                  alt={ctx.get('itemData.shortText')}
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <div className={ctx.get('itemData.className')}>
                {_.isNull(ctx.get('itemData.linkHref')) ? (
                  <div className="font-semibold text-xl md:text-3xl my-2 text-main">
                    {_.get(ctx.get('itemData'), 'shortText', '')}
                  </div>
                ) : (
                  <Link to={ctx.get('itemData.linkHref')}>
                    <div className="font-semibold text-xl md:text-3xl my-2 text-main hover:text-white500">
                      {_.get(ctx.get('itemData'), 'shortText', '')}
                    </div>
                  </Link>
                )}

                <div>
                  <CustomLongText>
                    {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                      className: 'text-base',
                      as: 'p',
                    })}
                  </CustomLongText>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default ImageAndTextYItem;
