import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import moment from 'moment';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

import RenderError from './RenderError';

const bindData = bindings({
  modalComponent: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const [localItem, $localItem] = useLocalStorage('@UZ::RCMFormValues');
              const [reOpen, $reOpen] = useLocalStorage('@UZ::RCMReOpen', false);
              const Yup = ctx.get('ValidateHandler.Yup');
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const item = ctx.get('@item') || localItem || {};

              const sessionValidator = async (values) => {
                const errors = {};
                const params = {
                  ...values,
                  sessions: _.map(_.get(values, 'sessions') || [], ({ select, ...session }) => {
                    return {
                      ...session,
                      ...(_.get(session, 'repeat_pattern.length')
                        ? {
                            type: 'repeat',
                            repeat_pattern: `* * * * ${session.repeat_pattern.join(',')}`,
                          }
                        : {
                            type: 'single',
                            repeat_pattern: '',
                          }),
                    };
                  }),
                  // session_duration: ctx.get('form.values.session_duration'),
                  start_at: ctx.get('form.values.start_at'),
                };
                try {
                  const data = await fbFnsClient.getClient().post('course-validateRCMCourse', params);

                  if (_.get(data, 'errors')) {
                    errors.sessions = RenderError(data.errors);
                    return false;
                  }
                } catch (err) {
                  console.log(err);
                  errors.sessions = `${err}`;
                }
                return errors;
              };

              const onSubmit = async (values, actions) => {
                const errors = {};
                const params = {
                  ..._.pick(values, [
                    'user_id',
                    'name',
                    'phone',
                    'email',
                    'topic',
                    'literacy',
                    'target_audience',
                    'advisor_name',
                    'start_at',
                    'notes',
                    'age',
                    'number_of_students',
                  ]),
                  sessions: _.map(_.get(values, 'sessions') || [], (session) => {
                    return {
                      ...session,
                      ...(_.get(session, 'repeat_pattern.length')
                        ? {
                            type: 'repeat',
                            repeat_pattern: `* * * * ${session.repeat_pattern.join(',')}`,
                          }
                        : {
                            type: 'single',
                            repeat_pattern: '',
                          }),
                    };
                  }),
                  // session_duration: ctx.get('form.values.session_duration'),
                  start_at: ctx.get('form.values.start_at'),
                };
                try {
                  const data = await fbFnsClient.getClient().post('course-RCMRequest', params);
                  console.log({ data });
                  if (_.get(data, 'errors')) {
                    errors.sessions = RenderError(data.errors);
                    actions.setStatus({
                      status: 'error',
                      error: errors,
                    });
                    return form.setErrors(errors);
                  }
                  if (_.get(data, 'error')) {
                    return actions.setStatus({
                      status: 'error',
                      error: data,
                    });
                  }
                  actions.setStatus({
                    status: 'success',
                  });
                  $localItem({});
                } catch (error) {
                  actions.setStatus({
                    status: 'error',
                    error,
                  });
                }
              };

              const validationSchema = React.useMemo(() => {
                return Yup.object({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  phone: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  email: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  topic: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  number_of_students: Yup.number()
                    .typeError(ctx.apply('i18n.t', 'Course.Info.Validation.required'))
                    .min(1, ctx.apply('i18n.t', 'Course.Info.Validation.required'))
                    .required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  age: Yup.number()
                    .typeError(ctx.apply('i18n.t', 'Course.Info.Validation.required'))
                    .min(3, ctx.apply('i18n.t', 'Course.Info.Validation.min_age'))
                    .required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  user_id: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  sessions: Yup.array()
                    .of(
                      Yup.object().shape({
                        start_at: Yup.date().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                      })
                    )
                    .min(1, ctx.apply('i18n.t', 'Course.Info.Validation.sessionMin'))
                    .required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                });
              }, []);
              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  select_all_repeat: _.get(item, 'select_all_repeat') || [{ select: false }],
                  user_id: _.get(item, 'user_id') || userId,
                  // course_id: _.get(item, 'id'),
                  name: _.get(item, 'name') || '',
                  phone: _.get(item, 'phone') || '',
                  email: _.get(item, 'email') || '',
                  topic: _.get(item, 'topic') || '',
                  literacy: _.get(item, 'literacy') || 'beginner',
                  target_audience: _.get(item, 'target_audience') || 'for_adult',
                  advisor_name: _.get(item, 'advisor.profile.display_name') || _.get(item, 'advisor_name') || '',
                  // session_duration: _.get(item, 'session_duration'),
                  start_at: moment().startOf('day'),
                  number_of_students: _.get(item, 'number_of_students') || 1,
                  age: _.get(item, 'age') || 3,
                  sessions: _.get(item, 'sessions') || [
                    {
                      select: false,
                      type: 'single',
                      start_at: '',
                      repeat_pattern: '',
                    },
                  ],
                  notes: _.get(item, 'notes') || '',
                },
                validateOnChange: true,
                validateOnBlur: false,
                onSubmit,
                validationSchema,
                validate: sessionValidator,
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
                renderStatus: (config) => {
                  const status = _.get(form, 'status');
                  const statusCode = _.get(status, 'status');
                  const defaultRender = () => {
                    let render = _.get(config, 'default');
                    if (_.isFunction(render)) {
                      render = render(status);
                    }
                    return render || null;
                  };
                  if (statusCode) {
                    let render = _.get(config, statusCode);
                    if (_.isFunction(render)) {
                      render = render(status);
                    }
                    return render || null;
                  }
                  return defaultRender();
                },
                selectOptions: {
                  repeat_pattern: [
                    ..._.range(0, 7, 1).map((count) => ({
                      value: count,
                      label: `${moment(count, 'e')
                        .startOf('week')
                        .isoWeekday(count)
                        .format('dddd')}`,
                    })),
                  ],
                  target_audience: [
                    { value: 'for_adult', label: ctx.apply('i18n.t', 'Course.Info.object_teaching_for_adult') },
                    { value: 'for_children', label: ctx.apply('i18n.t', 'Course.Info.object_teaching_for_children') },
                  ],
                  literacy: [
                    { value: 'beginner', label: ctx.apply('i18n.t', 'Course.RCM.modal.literacies.beginner') },
                    { value: 'elementary', label: ctx.apply('i18n.t', 'Course.RCM.modal.literacies.elementary') },
                    { value: 'intermediate', label: ctx.apply('i18n.t', 'Course.RCM.modal.literacies.intermediate') },
                    { value: 'advanced', label: ctx.apply('i18n.t', 'Course.RCM.modal.literacies.advanced') },
                  ],
                },
                handleSelectAllSessions: (name, value) => {
                  if (value) {
                    form.setFieldValue(name, _.range(0, 7, 1));
                  } else {
                    form.setFieldValue(name, []);
                  }
                },
                handleSelectSessions: (name, value, index) => {
                  const currentValue = _.get(form, `values.${name}`, []);
                  let newValue = [];
                  if (_.isArray(value)) {
                    newValue = _.xor(currentValue, value);
                  } else {
                    newValue = _.xor(currentValue, [value]);
                  }
                  form.setFieldValue(name, newValue);
                  if (_.isEqual(newValue.sort(), _.range(0, 7, 1).sort())) {
                    form.setFieldValue(`sessions[${index}].select`, true);
                  }
                  if (_.isEmpty(newValue)) {
                    form.setFieldValue(`sessions[${index}].select`, false);
                  }
                },
                onChangeTargetAudience: (e) => {
                  const value = _.get(e, 'target.value');
                  // const handler = {
                  //   for_adult: () => {
                  //     form.setFieldValue('age_min', 18);
                  //     form.setFieldValue('age_max', 100);
                  //   },
                  //   for_children: () => {
                  //     form.setFieldValue('age_min', 3);
                  //     form.setFieldValue('age_max', 18);
                  //   },
                  // };
                  // if (!!handler[value]) handler[value]();
                },
                onSaveForm: () => {
                  $localItem(form.values);
                  $reOpen(true);
                },
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };
                    const newItemVal = cleanItem(valueToInsert);
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
