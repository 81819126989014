import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';

export const View8 = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div className="z-30 absolute rounded-full shadow-sm cursor-pointer bottom-2/4 left-2 sm:left-0" onClick={onClick}>
        <div className="flex items-center justify-center rounded-full">
          {gstyles.icons({ name: 'arrow-left', size: 32, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};
export default displayName(View8);
