import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import cx from 'classnames';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const CustomText = styled.div`
  p > span {
    margin: 0;
  }
`;

const AboutUsInvitation = () => {
  return (
    <DIV className="AboutUsInvitation">
      {ctx.apply('ctf.renderProps', () => (
        <div className="relative content">
          <div className="absolute z-10 bg-black500 opacity-50 w-full h-full inset-0"></div>
          <div className="absolute z-20 w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className={cx('wrapper app-row', ctx.get('sectionData.className'))}>
              <div className="w-full md:w-2/3 lg:w-full xl:w-2/3">
                <h1 className="text-xl md:text-2xl font-semibold mb-12 lg:mb-8 tracking-wider text-white500">
                  {ctx.get('sectionData.shortText')}
                </h1>
                <Row
                  gutter={[0, ctx.get('sectionData.sections.length') >= 2 ? 24 : 0]}
                  align="middle"
                  justify="center"
                  wrap
                  className={ctx.get('sectionData.className')}
                >
                  <Col className="text-center" xs={24} sm={8}>
                    {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.0.name', '') })}
                  </Col>

                  <Col className="text-center" xs={24}>
                    {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.1.name', '') })}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <ResponsiveProps
            md={{ ratio: 0.5, width: 768, height: 768 * 0.5 }}
            xs={{ style: { height: 400 }, background: true }}
            lg={() => ({ ratio: 0.4, width: 1200, height: 1200 * 0.4 })}
          >
            {(resProps) => (
              <Image
                size="cover"
                src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                className="w-full hero-image"
                alt="banner"
                {...resProps}
              />
            )}
          </ResponsiveProps>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsInvitation;
