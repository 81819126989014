import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import _ from 'lodash';
import cx from 'classnames';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: ${(props) =>
      _.includes(_.get(props, 'itemData.className'), 'bg-brandb500')
        ? gstyles.colors.white500
        : gstyles.colors.brandb.darker}
      
    white-space: break-spaces;
  }
`;

export const ForSchoolReasonTextBlock = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const isTextWhite = _.includes(ctx.get('itemData.className'), 'bg-brandb500');
      return (
        <div className={`${ctx.get('itemData.className')} p-4`}>
          <div>
            <div className="h-full w-full flex flex-col">
              <div className="text-left mb-2">
                <div
                  className={cx('font-semibold text-2xl mb-2', {
                    'text-white500': !!isTextWhite,
                    'text-brandb-darker': !isTextWhite,
                  })}
                >
                  {_.get(ctx.get('itemData'), 'shortText', '')}
                </div>
                <CustomParagraph itemData={itemData}>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name', '') }, 'longText.longText', null)}
                </CustomParagraph>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </DIV>
);

export default ForSchoolReasonTextBlock;
