import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

function FooterLinkLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <section className="link-group py-0" id="linkGroup">
            <DIV>
              <div className="inline-flex justify-center flex-row space-x-3 sm:space-x-0 space-y-0 mb-4 sm:mb-0 sm:space-y-3 sm:flex-col flex-wrap">
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (col, index) => (
                    <React.Fragment key={`${_.get(col, 'name', '')}_${index}`}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                    </React.Fragment>
                  )
                )}
              </div>
            </DIV>
          </section>
        );
      })}
    </DIV>
  );
}

export default FooterLinkLayout;
