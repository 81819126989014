import React from 'react';
import { Image as AntdImage } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { createImageUrl } from '@vl/mod-utils/file';

const convertSizeProp = (size) => {
  return size ? { width: size, height: size } : {};
};

const BackgroundImage = styled.div`
  background: url("${(props) => props.src}");
  padding-bottom: ${(props) => props.ratio * 100 || 0}%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const omitProps = ['srcParams', 'ratio'];

export const Image = styled(({ className, background, optimize, ...props }) => {
  // eslint-disable-next-line
  const optSize = _.get(optimize, 'size');
  const imageProps = React.useMemo(
    () =>
      createImageUrl({
        ...props,
        size: optSize || props.size,
      }),
    [props.src]
  );
  if (background) {
    return (
      <div className={className}>
        <BackgroundImage {..._.omit({ ...props, ...convertSizeProp(props.size), ...imageProps }, omitProps)} />
      </div>
    );
  }
  return (
    <div className={className}>
      <AntdImage preview={false} {..._.omit({ ...props, ...convertSizeProp(props.size), ...imageProps }, omitProps)} />
    </div>
  );
})`
  & > .ant-image {
    width: 100%;
    display: block;
  }
`;

Image.createImageUrl = createImageUrl;

export default Image;
