import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParapraph = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;

function AdvisorRegistratorIntroduceLayout() {
  return (
    <DIV>
      <div className="bg-white500">
        <div className="wrapper app-row">
          <Row gutter={[24, 16]} className="py-4 min-md:py-4 min-xl:py-12 items-center">
            <Col sm={24} md={12} className="text-2xl min-md:text-3xl min-lg:text-4xl font-semibold text-main">
              {ctx.apply('ctf.findEntry', { name: 'AdvisorRegistratorIntro' }, 'shortText', '')}
            </Col>
            <Col sm={24} md={12} className="text-main">
              <CustomParapraph>
                {ctx.apply('ctf.renderHTMLText', { name: 'AdvisorRegistratorIntro' }, 'longText.longText', {
                  className: 'text-left text-sm min-md:text-base mb-4',
                  as: 'p',
                })}
              </CustomParapraph>
            </Col>
          </Row>
        </div>
      </div>
    </DIV>
  );
}

export default AdvisorRegistratorIntroduceLayout;
