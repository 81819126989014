import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Modal } from 'antd';
import gstyles from '@vl/gstyles';

const View8 = () => {
  return (
    <DIV className="component">
      <Modal
        bodyStyle={{ padding: 0 }}
        width={600}
        footer={null}
        className="rounded-lg overflow-hidden"
        maskStyle={{ background: gstyles.colors.black300 }}
        visible={ctx.get('form.isModalVisible')}
        centered
        wrapClassName={'my-modal'}
        onCancel={() => {
          ctx.apply('modalModel.hide');
        }}
        closable={false}
      >
        <section className="flex justify-center items-center">
          <div className="flex flex-col items-center space-y-4 p-6">
            <div>{gstyles.icons({ name: 'heart-success', size: 80 })}</div>
            <div className="py-4 text-brandb500 text-center font-semibold text-3xl">
              {ctx.apply('i18n.t', 'Biz.Form.success_notification')}
            </div>
            <div className="px-8 text-sub text-center font-semibold text-base">
              {ctx.apply('i18n.t', 'Biz.Form.success_description')}
            </div>
          </div>
        </section>
      </Modal>
    </DIV>
  );
};

export default displayName(View8);
