import React from 'react';
import _ from 'lodash';

const _useAsyncCallState = (stateName, fn) => {
  if (_.isString(stateName)) {
    const [loading, $loading] = React.useState(false);
    return {
      [stateName]: (...args) => {
        try {
          const rtn = fn(...args);
          if (_.isFunction(_.get(rtn, 'then'))) {
            $loading(true);
            rtn.then((val) => {
              $loading(false);
              return val;
            });
            return rtn;
          }
          return rtn;
        } catch (err) {
          $loading(false);
          throw err;
        }
      },
      [`${stateName}_`]: loading
    };
  }
  throw Error('stateName must be a string');
};

export const useAsyncCallState = (stateName, fn) => {
  if (!_.isString(stateName)) {
    const rtn = {};
    // TODO: ensure props name is not changed
    for (const item of _.entries(stateName)) {
      _.assign(rtn, _useAsyncCallState(...item));
    }
    return rtn;
  }
  return _useAsyncCallState(stateName, fn);
};

export default useAsyncCallState;
