import React from 'react';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

export const CustomInput = styled.div`
  .ant-form-item{
    margin-bottom: 8px;
  }
  textarea,
  input,
  .ant-picker,
  .ant-radio-group {
    border-radius: 8px;
    border-color: ${gstyles.colors.divider};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.divider};
      }
    }
  }
  .ant-radio-group {
    overflow: hidden;
    .ant-radio-button-wrapper {
      width: 50%;
      border-color: ${gstyles.colors.divider};
      text-align: center;
      color: ${gstyles.colors.main};
      font-weight: 400;
      &.ant-radio-button-wrapper-checked {
        color: ${gstyles.colors.white500};
        background-color: ${gstyles.colors.brandb500}
        font-weight: bold;
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export default CustomInput;
