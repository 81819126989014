import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';

const bindData = bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              // eslint-disable-next-line
              const itemData = ctx.get('@item');
              const profile = _.get(itemData, 'profile', '');
              const allServices = AdvisorProfileFormatter.services(ctx)(_.get(itemData, 'services'));
              const video = _.get(allServices, 'VideoService');
              const voice = _.get(allServices, 'VoiceService');
              const services = _.concat(voice, video);
              const minService = _.min(services, (service) => service.price.per_amount);
              const totalCourse = AdvisorProfileFormatter.totalCourse(ctx)(itemData);
              const category = _.find(
                ctx.get('categories'),
                (cat) => cat.id === ctx.get('advisorFilterModel.categoryActive')
              );
              return {
                ...itemData,
                slug: _.get(profile, 'slug'),
                isTeacher: totalCourse,
                profile: {
                  displayName: AdvisorProfileFormatter.displayName(ctx)(profile),
                  avatarUrl: AdvisorProfileFormatter.avatarUrl(ctx)(profile),
                  categoriesText: AdvisorProfileFormatter.categoriesText(ctx)(profile),
                },
                totalCourse,
                btnServices: _.keys(allServices) || [],
                minService,
                status: AdvisorProfileFormatter.status(ctx)(itemData),
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
