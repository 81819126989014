import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetBusinessDataCollection from 'unitz-gql/business_data_collection/GetBusinessDataCollection.gql';

graphme.GqlBuilder.loadDocument({
  GetBusinessDataCollection,
});

class BusinessDataCollectionModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'BusinessDataCollection',
    schema: {
      id: String,
    },

    key: 'id',

    baseQuery: 'GetBusinessDataCollection',

    GQL_ACTIONS: {
      FIND: 'business_data_collection',
      GET: 'business_data_collection_by_pk',
      INSERT: 'insert_business_data_collection_one',
      UPDATE: 'update_business_data_collection_by_pk',
      DELETE: 'delete_business_data_collection_by_pk',
    },

    getClient: hasuraClient.getClient,
  });
}

graphme.model({ BusinessDataCollectionModel });

export default BusinessDataCollectionModel;
