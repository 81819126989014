import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useRoute from '@vl/hooks/useGbRoute';
import RenderError from './RenderError';

const bindData = bindings({
  courseRoomDismissModal: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');

              const route = useRoute();
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const item = ctx.get('@item');
              const room = _.get(item, 'room');
              const validationSchema = React.useMemo(() => {
                return Yup.object({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  confirm_text: Yup.string()
                    .required(ctx.apply('i18n.t', 'Course.Info.Validation.required'))
                    .oneOf([Yup.ref('name'), null], ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                });
              }, []);

              const form = useFormik({
                initialValues: {
                  user_id: userId,
                  room_id: _.get(room, 'id'),
                  name: _.get(item, 'course.name'),
                  confirm_text: '',
                  sessions: Date.now(),
                },
                onSubmit: async (values, actions) => {
                  const errors = {};
                  const params = {
                    ..._.omit(values, ['confirm_text', 'sessions', 'name']),
                  };
                  try {
                    const data = await fbFnsClient.getClient().post('course-dismissRoom', params);

                    if (_.get(data, 'errors')) {
                      errors.sessions = RenderError(data.errors);
                      form.setErrors({ sessions: RenderError(data.errors) });
                    }
                    if (_.get(data, 'error')) {
                      actions.setStatus({
                        error: data,
                      });
                    }
                    form.handleCancel();
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema,
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid') && _.get(form, 'dirty'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
