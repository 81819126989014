import React from 'react';

const LoadingOverlay = ({ children }) => (
  <>
    <div
      id="loadOverlay"
      style={{
        background: '#FFFFFF',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2000,
      }}
    />
    {children}
  </>
);

export default LoadingOverlay;
