import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const CustomTab = styled.div`
  .ant-tabs {
    .ant-tabs-nav {
      flex: 1 1 34%;
      .ant-tabs-nav-wrap {
        padding-right: 24px;
        border-right: 1px solid ${gstyles.colors.divider};
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            &.ant-tabs-tab-active {
              background-color: ${gstyles.colors.brandb.lightest};
              border-radius: 8px;
            }
          }
          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
    }
    .ant-tabs-content-holder {
      flex: 1 1 66%;
    }
  }
`;

const DesktopTab = (props) => {
  const { sectionData } = props;
  return (
    <DIV>
      <CustomTab>
        <Tabs className="block" defaultActiveKey="0" tabPosition="left" size="large">
          {_.get(ctx.apply('ctf.findEntry', { name: sectionData.name }), 'sections', []).map((item, index) => {
            const listIndex = index + 1;
            return (
              <TabPane
                tab={
                  <div className="py-3">
                    <div className="mb-1 text-left font-normal text-sub text-sm">{`${ctx.apply(
                      'i18n.t',
                      'AdvisorRegister.step'
                    )} ${listIndex}`}</div>
                    <h6 className="text-left whitespace-normal font-semibold text-sm text-main">
                      {_.get(item, 'shortText', '')}
                    </h6>
                  </div>
                }
                key={index}
              >
                {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
              </TabPane>
            );
          })}
        </Tabs>
      </CustomTab>
    </DIV>
  );
};

export default DesktopTab;
