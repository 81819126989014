import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParapraph = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const CustomLayout = styled.div`
  .biz-client {
    div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const UnitzBizFeatureForStudentLayout = () => {
  return (
    <DIV className="content">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="wrapper app-row">
            {!_.isNull(ctx.get('sectionData.shortText')) && (
              <h1 className="text-center text-2xl lg:text-3xl mb-4 lg:mb-10 text-main font-semibold">
                {ctx.get('sectionData.shortText')}
              </h1>
            )}

            {!!ctx.get('sectionData.longText') && (
              <CustomParapraph>
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                  className: 'text-main',
                  as: 'p',
                })}
              </CustomParapraph>
            )}
            <CustomLayout>
              {_.map(ctx.get('sectionData.sections'), (item, index) => (
                <div className="mb-24" key={index}>
                  {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                </div>
              ))}
            </CustomLayout>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizFeatureForStudentLayout;
