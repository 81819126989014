const { GraphQLClient, gql } = require('graphql-request');
const _ = require('lodash');
const modConfig = require('@vl/mod-config');
// const hasuraSubs = require('@vl/mod-clients/hasuraSubs');

const getOptions = () => {
  const options = {
    endpoint: modConfig.get('HASURA_GRAPHQL_ENDPOINT'),
    adminSecret: modConfig.get('HASURA_GRAPHQL_ADMIN_SECRET'),
    jwtToken: modConfig.get('HASURA_GRAPHQL_JWT_TOKEN'),
    debug: true,
  };
  return options;
};

const getHeaders = (options) => {
  const headers = {};
  if (options.jwtToken) {
    _.assign(headers, {
      Authorization: `Bearer ${options.jwtToken}`,
    });
  } else if (options.adminSecret) {
    _.assign(headers, {
      'x-hasura-admin-secret': options.adminSecret,
    });
  }
  return headers;
};

exports.getClient = (endpoint, opts = {}) => {
  const options = getOptions();

  endpoint = endpoint || options.endpoint;
  // console.log('endpoint', endpoint);

  const client = new GraphQLClient(endpoint, {
    headers: getHeaders(options),
  });

  // check for debug mode
  if (_.get(options, 'debug', true)) {
    return new Proxy(client, {
      get(obj, prop) {
        if (prop === 'options') {
          return options;
        }
        if (prop === 'request') {
          return function(...args) {
            // console.log('request with args:', ...args);
            return obj.request(...args);
          };
        }
        // if(prop === 'subscribe') {
        //   return function(...args) {
        //     const [query] = args;
        //     console.log('susbcribe with query:', query);
        //     return hasuraSubs.getClient().request({query});
        //   }
        // }
      },
    });
  }
  return client;
};

exports.gql = gql;
