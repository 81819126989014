import React from 'react';
import _ from 'lodash';

import cx from 'classnames';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { message } from 'antd';

import Button from '@uz/unitz-components-web/Button';
import useAsyncLoading from '@vl/hooks/useAsyncLoading';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useRoute from '@vl/hooks/useGbRoute';

const dispatchApiResponse = (data) => {
  if (_.get(data, 'message')) {
    message.error(data.message);
  }
  return data;
};

const CourseStatusIndicator = (props) => {
  const { className } = props;
  const ref = React.useRef({});
  const route = useRoute();
  const [loading, $loading, loadingWrapper] = useAsyncLoading(false);
  ref.current.props = props;

  return (
    <DIV className="listItem">
      {ctx.debug(() => {
        const menuItemsMap = {
          published: {
            styleName: 'primary',
            title: ctx.apply('i18n.t', 'Course.Status.published.title'),
            label: ctx.apply('i18n.t', 'Course.Status.published.action'),
            value: 'published',
          },
          requested: {
            styleName: 'yellow',
            title: ctx.apply('courseModel.can', 'edit')
              ? ctx.apply('i18n.t', 'Course.Status.requested.action')
              : ctx.apply('i18n.t', 'Course.Status.requested.label'),
            label: ctx.apply('courseModel.can', 'edit')
              ? ctx.apply('i18n.t', 'Course.Status.requested.action')
              : ctx.apply('i18n.t', 'Course.Status.requested.label'),
            value: 'requested',
            handler:
              ctx.apply('courseModel.can', 'edit') &&
              loadingWrapper(async () => {
                try {
                  const data = dispatchApiResponse(
                    await fbFnsClient.getClient().post('course-updateCourseStatus', {
                      user_id: ctx.apply('authModel.getUserId'),
                      course_id: ctx.get('@item.id'),
                      status: 'confirmed',
                    })
                  );
                  route.reload();
                } catch (err) {
                  console.log('err', err);
                }
              }),
          },
          rejected: {
            styleName: 'red',
            title: ctx.apply('i18n.t', 'Course.Status.rejected.action'),
            label: ctx.apply('i18n.t', 'Course.Status.rejected.action'),
            value: 'rejected',
            handler: loadingWrapper(async () => {
              try {
                const data = dispatchApiResponse(
                  await fbFnsClient.getClient().post('course-updateCourseStatus', {
                    user_id: ctx.apply('authModel.getUserId'),
                    course_id: ctx.get('@item.id'),
                    status: 'rejected',
                  })
                );
                route.reload();
              } catch (err) {
                console.log('err', err);
              }
            }),
          },
          canceled: {
            styleName: 'red',
            title: ctx.apply('i18n.t', 'Course.Status.canceled.title'),
            label: ctx.apply('i18n.t', 'Course.Status.canceled.action'),
            value: 'canceled',
          },
          closed: {
            styleName: 'red',
            title: ctx.apply('i18n.t', 'Course.Status.closed.title'),
            label: ctx.apply('i18n.t', 'Course.Status.closed.action'),
            value: 'closed',
          },
          draft: {
            styleName: 'yellow',
            title: ctx.apply('i18n.t', 'Course.Status.draft.title'),
            label: ctx.apply('i18n.t', 'Course.Status.draft.action'),
            value: 'draft',
          },
          pending_approval: {
            styleName: 'yellow',
            title: ctx.apply('i18n.t', 'Course.Status.pending_approval.title'),
            label: ctx.apply('i18n.t', 'Course.Status.pending_approval.action'),
            value: 'pending_approval',
          },
          completed: {
            styleName: 'ink',
            title: ctx.apply('i18n.t', 'Course.Status.completed.title'),
            label: ctx.apply('i18n.t', 'Course.Status.completed.action'),
            value: 'completed',
          },
          onprogress: {
            styleName: 'blue',
            title: ctx.apply('i18n.t', 'Course.Status.onprogress.title'),
            label: ctx.apply('i18n.t', 'Course.Status.onprogress.action'),
          },
        };

        // calculate menu item by current status
        const status = ctx.get('@item.status');
        let menuItems = [];
        const getMenuItems = _.get(menuItemsMap, `${status}.getMenuItems`);
        if (_.isFunction(getMenuItems)) {
          menuItems = getMenuItems();
        }
        ctx.set('menuItems', menuItems);
        ctx.set('menuItemsMap', menuItemsMap);
        ctx.set('menuHandler', ({ key }) => {
          const handler = _.get(menuItemsMap, `${key}.handler`);
          if (_.isFunction(handler)) {
            return handler();
          }
        });
      })}
      <Button
        type="primary"
        name={ctx.get(`menuItemsMap.${ctx.get('@item.status')}.styleName`, 'yellow')}
        className={cx(className, { 'w-32': !className })}
        loading={loading}
        {...ref.current.props}
        onClick={() => {
          const menuHandler = ctx.get('menuHandler');
          menuHandler && menuHandler({ key: ctx.get('@item.status') });
        }}
        size={ref.current.props.size || 'small'}
      >
        {ctx.get(`menuItemsMap.${ctx.get('@item.status')}.title`, '')}
      </Button>
    </DIV>
  );
};

export default CourseStatusIndicator;
