import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

import { useRenderProps } from '@vl/hooks/useRenderProps';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import CourseRoomAttendees from '@uz/unitz-components-web/CourseRoomAttendees';
import CourseRoomAttendeePaymentStatus from '@uz/unitz-components-web/CourseRoomAttendeePaymentStatus';
import CourseRoomActionMenu from '@uz/unitz-components-web/CourseRoomActionMenu';
import CourseRoomDateTimeCountdown from '@uz/unitz-components-web/CourseRoomDateTimeCountdown';
// import CourseRoomQuickActionAdvisor from '@uz/unitz-components-web/CourseRoomQuickActionAdvisor';
import CourseRoomQuickActionNew from '@uz/unitz-components-web/CourseRoomQuickActionNew';
import moment from 'moment';
import { TIME_HOUR_MINUTE_FORMAT, DATE_FORMAT } from '@vl/mod-utils/datetime';
import { Link } from '@uz/unitz-components-web/Link';
import i18n from 'i18n-js';

const CustomButton = styled.div`
  > a {
    @media (max-width: 575px) {
      margin: 0;
    }
  }
`;

const renderCoureRoomItem = useRenderProps(
  ({ item, index }) => {
    return (
      <DIV forceCtx>
        {ctx.debug(() => {
          ctx.set('@item', item);
          ctx.set('@index', index);
        })}
        <Row gutter={[16, 8]} key={item.id} className="py-3 border-b min-md:pl-2">
          <div className="min-md:hidden">
            <CourseRoomAttendeePaymentStatus />
          </div>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 5, offset: 0 }}>
            <div className="flex items-center justify-between w-full h-full min-md:justify-start">
              <DIV>
                <div className="flex flex-col space-y-1">
                  <div className="text-xs md:text-bases">
                    {[
                      CourseFormatter.replaceSACH()(moment(ctx.get('@item.start_at')).format(TIME_HOUR_MINUTE_FORMAT)),
                      CourseFormatter.replaceThg()(
                        CourseFormatter.replaceSACH()(
                          moment(ctx.get('@item.start_at')).format(_.get(DATE_FORMAT, moment.locale(i18n.locale), 'vi'))
                        )
                      ),
                    ].join(' - ')}
                  </div>
                  <CourseRoomDateTimeCountdown />
                </div>
              </DIV>
            </div>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 8, offset: 0 }}>
            <div className="flex items-center justify-start w-full h-full">
              <Link to={ctx.apply('routeStore.toUrl', 'courseDetail', _.get(item, 'course'))} external>
                {_.get(item, 'course.name', '')}
              </Link>
            </div>
          </Col>
          <Col xs={{ span: 0, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 0 }}>
            <div className="items-center justify-start hidden w-full h-full min-md:flex">
              <CourseRoomAttendeePaymentStatus />
            </div>
          </Col>
          <Col xs={{ span: 0, offset: 0 }} lg={{ span: 2, offset: 0 }}>
            <div className="items-center justify-start hidden w-full h-full min-lg:flex">
              <CourseRoomAttendees />
            </div>
          </Col>

          <Col xs={{ span: 24, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} className="">
            <div className="flex items-center justify-between w-full h-full space-x-2">
              {/* <CourseRoomQuickActionAdvisor /> */}
              <CustomButton>
                <CourseRoomQuickActionNew />
              </CustomButton>
              <CourseRoomActionMenu />
            </div>
          </Col>
        </Row>
      </DIV>
    );
  },
  ({ item, index }) => [index, _.get(item, 'id')]
);

export default renderCoureRoomItem;
