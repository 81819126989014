import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Row, Col } from 'antd';

import cx from 'classnames';
import _ from 'lodash';

const CustomText = styled.div`
  p > span {
    margin: 0;
  }
`;

const UnitzBizEndBannerLayout = () => {
  return (
    <DIV className="UnitzBizEndBannerLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="relative content">
            <div className="absolute z-10 bg-black500 opacity-50 w-full h-full inset-0"></div>
            <div className="absolute z-20 w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className={cx('wrapper app-row', ctx.get('sectionData.className'))}>
                <div className="w-full">
                  <h1 className="text-base lg:text-4xl font-semibold mb-6 lg:mb-2 tracking-wider text-white500">
                    {ctx.get('sectionData.shortText')}
                  </h1>
                  <div className="mb-4 sm:mb-8">
                    <CustomText>
                      <div className="text-base lg:text-xl font-normal mb-0 tracking-wider text-white500">
                        {ctx.apply('ctf.renderRichText', { name: ctx.get('sectionData.name') }, 'richText', null)}
                      </div>
                    </CustomText>
                  </div>
                  <Row
                    gutter={[
                      ctx.get('sectionData.sections.length') >= 2 ? 12 : 0,
                      ctx.get('sectionData.sections.length') >= 2 ? 12 : 0,
                    ]}
                    align="middle"
                    className={ctx.get('sectionData.className')}
                  >
                    <Col className="text-center sm:text-left" xs={18} sm={8} md={10} lg={9} xl={8}>
                      {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.0.name', '') })}
                    </Col>
                    {!!ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.1.name', '') }) && (
                      <Col className="text-center sm:text-left" xs={18} sm={8} md={12} lg={12} xl={12}>
                        {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.1.name', '') })}
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
            <ResponsiveProps
              md={{ ratio: 0.5, width: 768, height: 768 * 0.5 }}
              xs={{ style: { height: 400 }, background: true }}
              lg={() => ({ ratio: 0.4, width: 1200, height: 1200 * 0.4 })}
            >
              {(resProps) => (
                <Image
                  size="cover"
                  src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                  className="w-full hero-image"
                  alt="banner"
                  {...resProps}
                />
              )}
            </ResponsiveProps>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizEndBannerLayout;
