import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import _ from 'lodash';

const DesktopMenu = () => {
  const [active] = React.useState();
  return (
    <DIV>
      <nav className="navbar navbar-wrapper menu-wrapper">
        <div className="navbar-link-wrapper">
          <ul className="flex flex-row justify-center items-center mb-0 space-x-6 navbar-nav" id="menu-navbar">
            {_.map(ctx.get('sectionData.sections', []), (section, index) => {
              const menuItem = ctx.apply('ctf.findEntry', { name: section.name });
              if (!menuItem) return null;
              return (
                <React.Fragment key={index}>
                  <DIV>
                    {ctx.apply('ctf.renderEntry', menuItem) || (
                      <li
                        className={
                          (cx('flex justify-center items-center', { ' active': active === menuItem.linkHref }),
                          'nav-item-box')
                        }
                      >
                        <span className={_.get(menuItem, 'className', '')} />
                      </li>
                    )}
                  </DIV>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </nav>
    </DIV>
  );
};

export default DesktopMenu;
