import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col } from 'antd';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const Paragraph = styled.div`
  p {
    color: ${gstyles.colors.sub};
    font-size: 16px;
  }
`;

export const BizClientItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemProps = ctx.get('itemProps');
      return (
        <div style={{ boxShadow: '0px 2px 12px rgba(38, 51, 64, 0.24)' }} className="h-full p-6 text-center rounded-xl">
          <div className="flex justify-center items-center mb-8">
            {gstyles.icons({
              name: ctx.get('itemData.className'),
              size: 115,
              fill: gstyles.colors.sub,
            })}
          </div>
          <h4 className="mb-2 text-xl font-semibold text-center text-main">
            {ctx.apply(
              'ctf.findEntry',
              ctx.get('itemData'),
              ...[].concat(_.get(itemProps, 'args') || ['shortText', ''])
            )}
          </h4>

          <Paragraph>
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {})}
          </Paragraph>
        </div>
      );
    })}
  </DIV>
);

export default BizClientItem;
