import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const ContentHelpCenterLayout = () => {
  return (
    <DIV className="ContentHelpCenterLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div>
            {_.map(_.get(ctx.apply('ctf.findNode', { name: ctx.get('sectionData.name') }), 'nodes', []), (itemNode) => {
              return _.map(_.get(itemNode, 'sections', []), (item, index) => {
                return <React.Fragment key={index}>{ctx.apply('ctf.renderEntry', { name: item.name })}</React.Fragment>;
              });
            })}
          </div>
        );
      })}
    </DIV>
  );
};

export default ContentHelpCenterLayout;
