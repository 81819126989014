const _ = require('lodash');

const getStreamable = () => {
  const privateData = {};
  class Streamable {
    emit(event, ...params) {
      return _.get(privateData, [event], []).map((listener) => listener.call(this, ...params));
    }

    on(event, listener) {
      _.update(privateData, [event], (val) => (val ? val.concat(listener) : [listener]));
      return () => {
        _.update(privateData, [event], (val) => _.filter(val || [], (item) => item !== listener));
      };
    }

    once(event, listener) {
      const handlers = {
        listener: (...args) => {
          // self disposing
          if (handlers.disposer) {
            handlers.disposer();
            handlers.disposer = null;
            handlers.listener = null;
          }
          return listener.call(...args);
        },
        disposer: null,
      };
      handlers.disposer = this.on(event, handlers.listener);
      return handlers.disposer;
    }
  }
  return new Streamable();
};

exports.getStreamable = getStreamable;
