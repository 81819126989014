import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import CourseCardItemX from '@uz/unitz-components-web/CourseCardItemX';
import CourseCardItemY from '@uz/unitz-components-web/CourseCardItemY';
import CourseCardItemEmptyX from '@uz/unitz-components-web/CourseCardItemEmptyX';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
const isBrowser = () => typeof window !== 'undefined';
const CustomCarousel = styled.div`
  .slick-slider {
    .slick-list {
      margin-bottom: 24px;
      padding: 0px !important;
      .slick-track {
        margin-top: 16px;
      }
    }
    .slick-disabled {
      display: none;
    }
    .slick-dots {
      bottom: -30px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.background2};
          border-radius: 50%;
          width: 8px;
          height: 8px;
          &:before {
            content: '';
          }
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;
const CustomSlider = styled(Slider)`
  .slick-slide {
    width: ${(props) => (props.itemWidth ? props.itemWidth : '')} !important;
  }
`;
const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div
        className="absolute z-50 transform rounded-full cursor-pointer filter drop-shadow-md -right-10 min-md:right-4 min-lg:right-4 min-md:translate-x-3/4 top-1/2 right-6 -translate-x-3/4 -bottom-10 hover:opacity-90"
        onClick={onClick}
        style={{ display: onClick === null ? 'none' : 'block' }}
      >
        <div className="flex items-center justify-center w-10 h-10 transform -translate-y-1/2 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-right', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div
        className="absolute z-50 w-10 h-10 transform rounded-full cursor-pointer filter drop-shadow-md -left-10 min-md:left-4 min-lg:left-4 min-md:-translate-x-3/4 top-1/2 left-6 translate-x-3/4 -bottom-10 hover:opacity-90"
        onClick={onClick}
        style={{ display: onClick === null ? 'none' : 'block' }}
      >
        <div className="flex items-center justify-center w-10 h-10 transform -translate-y-1/2 rounded-full bg-white500">
          {gstyles.icons({ name: 'arrow-left', size: 24, fill: gstyles.colors.sub })}
        </div>
      </div>
    </DIV>
  );
};

export const View8 = () => {
  return (
    <DIV className="component">
      <CustomCarousel className="relative">
        <ResponsiveProps
          xs={{
            renderer: () => {
              return (
                // <Slider
                //   {...ctx.get('carouselModel.options')}
                //   {...{
                //     nextArrow: <NextButton onClick />,
                //     prevArrow: <PrevButton onClick />,
                //   }}
                // >
                //   {_.map(_.chunk(ctx.get('@items', []), 2), (item, index) => (
                //     <div
                //       className="flex flex-col mb-2 mr-2"
                //       key={`group_${index}`}
                //       style={{ width: `${_.round(window.innerWidth * 0.8, 0)}px` }}
                //     >
                //       {!!_.isArray(item) &&
                //         _.map(item, (item) => (
                //           <React.Fragment key={`course_${_.get(item, 'slug', index)}`}>
                //             <DIV>
                //               {ctx.debug(() => {
                //                 ctx.set('@item', item);
                //                 ctx.set('@index', _.get(item, 'id', index));
                //               })}

                //               <CourseCardItemY noneBorder={true} />
                //             </DIV>
                //           </React.Fragment>
                //         ))}
                //     </div>
                //   ))}
                // </Slider>
                <CustomSlider
                  // itemWidth={isBrowser() ? `${_.round(window.innerWidth * 0.6, 0)}px` : ''}
                  {...ctx.get('carouselModel.options')}
                  {...{
                    nextArrow: <NextButton onClick />,
                    prevArrow: <PrevButton onClick />,
                  }}
                >
                  {_.map(ctx.get('@items', []), (item, index) => (
                    <React.Fragment key={`course_${_.get(item, 'slug', index)}`}>
                      <DIV forceCtx>
                        {ctx.debug(() => {
                          ctx.set('@item', item);
                          ctx.set('@index', _.get(item, 'id', index));
                        })}
                        <CourseCardItemY noneBorder={true} />
                      </DIV>
                    </React.Fragment>
                  ))}
                </CustomSlider>
              );
            },
          }}
          md={{
            renderer: () => {
              return (
                <CustomSlider
                  {...ctx.get('carouselModel.options')}
                  {...{
                    nextArrow: <NextButton onClick />,
                    prevArrow: <PrevButton onClick />,
                  }}
                >
                  {_.map(ctx.get('@items', []), (item, index) => (
                    <React.Fragment key={`course_${_.get(item, 'slug', index)}`}>
                      <DIV forceCtx>
                        {ctx.debug(() => {
                          ctx.set('@item', item);
                          ctx.set('@index', _.get(item, 'id', index));
                        })}

                        <CourseCardItemX />
                      </DIV>
                    </React.Fragment>
                  ))}
                  {!!ctx.get('linkHref') && <CourseCardItemEmptyX />}
                </CustomSlider>
              );
            },
          }}
        >
          {(resProps) => {
            return !!resProps && !!_.isFunction(resProps.renderer) && resProps.renderer();
          }}
        </ResponsiveProps>
      </CustomCarousel>
    </DIV>
  );
};
export default displayName(View8);
