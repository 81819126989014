import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const CustomText = styled.div`
  p {
    font-size: 16px;
    color: ${gstyles.colors.main};
  }
`;

const LandingAdvisorDiscoveryLayout = () => {
  return (
    <DIV className="LandingAdvisorDiscoveryLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="flex py-16 justify-center items-center">
            <div className="wrapper app-row relative mx-auto">
              <div className="grid grid-cols-1 min-md:grid-cols-2">
                <div className="flex order-1 justify-start items-start">
                  <div className="flex flex-col justify-center min-md:justify-start items-center min-md:items-start">
                    <div className="text-sm text-sub text-center min-md:text-left font-semibold">
                      {ctx.get('sectionData.shortText')}
                    </div>
                    <CustomParagraph className="text-center min-md:text-left">
                      {ctx.apply(
                        'ctf.renderHTMLText',
                        { name: ctx.get('sectionData.name', '') },
                        'longText.longText',
                        null
                      )}
                    </CustomParagraph>
                    <CustomText className="text-center min-md:text-left">
                      {ctx.apply(
                        'ctf.renderHTMLText',
                        { name: ctx.get('sectionData.name', '') },
                        'detailText.detailText',
                        null
                      )}
                    </CustomText>
                  </div>
                </div>
              </div>
              <div className="static min-md:absolute bottom-0 right-0 w-full min-md:w-1/2 h-full flex justify-center items-center">
                <ResponsiveProps>
                  {(resProps) => (
                    <Image
                      size="cover"
                      src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                      className="w-full hero-image"
                      alt="discovery"
                      {...resProps}
                    />
                  )}
                </ResponsiveProps>
              </div>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default LandingAdvisorDiscoveryLayout;
