import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import cx from 'classnames';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const UnitzBizIntroductionLayout = () => {
  return (
    <DIV className="UnitzBizIntroductionLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <div className="wrapper app-row text-center">
            <div className={`flex flex-col`}>
              {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                return <div key={index}>{ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}</div>;
              })}
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default UnitzBizIntroductionLayout;
