import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import SearchHeaderNavbar from '@uz/unitz-components-web/SearchHeaderNavbar';

export const SearchButtonMenu = () => {
  const [toggle, $toggle] = React.useState(false);
  const handleToggle = () => {
    $toggle(!toggle);
  };
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const itemData = ctx.get('itemData');
        const itemProps = ctx.get('itemProps');
        return (
          <DIV>
            <ResponsiveProps
              // lg={{ renderer: () => <div /> }}
              xs={{ renderer: () => <div /> }}
              lg={{
                renderer: () => (
                  <li className="flex justify-center align-center">
                    <div className="flex items-center justify-center">
                      <SearchHeaderNavbar handleToggle={handleToggle} />
                    </div>
                  </li>
                ),
              }}
            >
              {(resProps) => !!resProps && resProps.renderer()}
            </ResponsiveProps>
          </DIV>
        );
      })}
    </DIV>
  );
};

export default SearchButtonMenu;
