import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  advisorSchedule: {
    rules: [
      [
        'data',
        {
          data: {
            courseRooms: hook((ctx) => {
              const items = ctx.get('calendarModel.events', []);
              return items;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
