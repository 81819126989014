import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
// import CourseModel from '@uz/unitz-models/CourseModel';
import SuggestCourseModel from '@uz/unitz-models/SuggestCourseModel';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import useObservableSource from '@vl/hooks/useObservableSource';
import CourseRoomModelFormatter from '@uz/unitz-models/CourseRoomModel/formatter';
// import SupplierSuggestModel from '@uz/unitz-models/SupplierSuggestModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const bindData = bindings({
  LandingCourseListLayout: {
    rules: [
      [
        'data',
        {
          data: {
            categories: hook((ctx) => {
              const categories = ctx.apply('ctf.buildCategoryHierarchy');
              const languages = React.useMemo(() => {
                const categoryLanguage = _.find(categories, (item) => _.get(item, 'id') === '4bhJ4jhBJP3cilGFfNoekv');
                const children = _.get(categoryLanguage, 'children', []);
                const data = _.map(children, (child) => {
                  return _.find(categories, (item) => _.get(item, 'id') === child.id);
                });
                return {
                  catId: _.get(data, '0.id', ''),
                  data: data,
                };
              }, [categories]);
              return languages;
            }),
            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  category: ctx.get('categories.catId'),
                },
                validationSchema: Yup.object().shape({}),
              });
              _.assign(form, {
                getSelectedCategory: () => {
                  const category = _.get(form, 'values.category');
                  const selected = _.find(ctx.get('categories.data'), { id: category });
                  return selected;
                },
              });
              return form;
            }),
            getFilterArgs: hook((ctx) => {
              return () => ({
                category: ctx.get('form.values.category'),
              });
            }),
            courseFilterModelMap: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModels = React.useMemo(() => {
                return _.map(ctx.get('categories.data'), (item) => {
                  const dataQuery = `
                  suggest_course (args: $args, where: $course_bool_exp,  order_by: {scoring_int: desc_nulls_last}) {
                    id
                    slug
                    name
                    advisor {
                      profile {
                        avatar_url
                        display_name
                      }
                    }
                    session_duration
                    start_at
                    sessions {
                      repeat_pattern
                      type
                      start_at
                    }
                    price_amount
                    price_currency
                    pricing_type
                    photo_url
                    video_url
                    status
                    rooms (order_by: { end_at: asc_nulls_last}, limit: 3, where: {${CourseRoomModelFormatter.getExactlyUpcomeRoomCondition()()}}){
                      id
                      start_at
                      end_at
                    }
                  }
                `;
                  try {
                    const pagingModel = PaginationModel.fromConfig({
                      Model: SuggestCourseModel,
                      subscription: true,
                      dataQuery,
                      aggQuery: `
                       suggest_course_aggregate(args: $args, where: $course_bool_exp) {
                          aggregate {
                            count
                          }
                        }
                    `,
                      pageSize: 18,
                      varsList: `$args: suggest_course_args!, $course_bool_exp: course_bool_exp,`,
                      getVars: () => {
                        return {
                          args: {
                            allow_all_category_vn: !ctx.apply('routeStore.isUnitzVN'),
                            allow_all_category_app: !ctx.apply('routeStore.isUnitzApp'),
                            category: item.id,
                          },
                          course_bool_exp: {
                            status: { _in: ['published', 'onprogress'] },
                          },
                        };
                      },
                    });
                    return pagingModel;
                  } catch (err) {
                    console.log({ err });
                  }
                });
              }, [ref]);
              _.map(pagingModels, (pagingModel) => pagingModel.hookState());

              React.useEffect(() => {
                _.map(pagingModels, (pagingModel) => pagingModel.onLoadPageHandler(0));
              }, [pagingModels]);
              return pagingModels;
            }),
            courseFilterModel: hook((ctx) => {
              const catId = ctx.get('form.values.category');
              const categories = ctx.get('categories.data');
              const catIndex = _.findIndex(categories, { id: catId });
              const pagingModels = ctx.get('courseFilterModelMap');
              const pagingModel = _.get(pagingModels, catIndex);

              const pagination = {
                items: (pagingModel && pagingModel.useState('items')) || [],
              };
              return pagination;
            }),
            highlightCourses: hook((ctx) => {
              // 'where: {is_active: { _eq: true }} limit: 20'
              const items = useObservableSource(() =>
                SuggestCourseModel.find(
                  ` args: {
                    allow_all_category_vn: ${!ctx.apply('routeStore.isUnitzVN')},
                    allow_all_category_app: ${!ctx.apply('routeStore.isUnitzApp')},
                  },
                  ${CourseFormatter.queryCoursePublishedOnprogress()()}
                  order_by: { scoring_int: desc_nulls_last },
                  limit: 18 `,
                  `
                  id
                  slug
                  name
                  advisor {
                    profile {
                      avatar_url
                      display_name
                    }
                  }
                  session_duration
                  start_at
                  sessions {
                    repeat_pattern
                    type
                    start_at
                  }
                  price_amount
                  price_currency
                  pricing_type
                  photo_url
                  video_url
                  status
                  rooms (order_by: { end_at: asc_nulls_last}, limit: 3, where: {${CourseRoomModelFormatter.getExactlyUpcomeRoomCondition()()}}){
                    id
                    start_at
                    end_at
                  }
                `
                )
              );
              return items;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
