import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { resable } from '@vl/redata/RESABLE';
import { ctx } from '@vl/redata';
import RESABLE from '@vl/redata/RESABLE.macro';
import AdvisorListX from '@uz/unitz-components-web/AdvisorListX';
import _ from 'lodash';

function BusinessHighlightAdvisor() {
  return (
    <DIV className="component">
      <div className="py-0 sm:wrapper app-row">
        {/* <div className="border-b-2" /> */}
        <div className="py-6 sm:w-full lg:py-16">
          {ctx.apply('ctf.renderProps', () => (
            <h2 className="mb-6 text-xl font-semibold text-center lg:mb-10 sm:text-2xl lg:text-4xl text-main">
              {ctx.get('sectionData.shortText')}
            </h2>
          ))}
          <RESABLE>
            <DIV>
              {ctx.debug(() => {
                const advisorsList = resable.for('advisorPagingModel')(ctx.get('advisorPagingModel'), []);
                ctx.set('paginationModel', advisorsList);
              })}
              <AdvisorListX />
            </DIV>
          </RESABLE>
        </div>
      </div>
    </DIV>
  );
}

export default BusinessHighlightAdvisor;
