import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';
import FlatList from '@uz/unitz-components-web/FlatList';
import renderCoureRoomItem from '@uz/unitz-components-web/UserCourseRoomItem';
import _ from 'lodash';

const View8 = () => {
  return (
    <DIV className="componentContainer">
      <DIV className="advisorMenus">
        {!!ctx.get('courseRooms.items.length', []) && (
          <div className="w-fill-screen px-4 py-12 bg-background1">
            <div className="h-full p-6 space-y-4 bg-white rounded-lg shadow app-row">
              <div className="flex justify-between">
                <div className="mb-6">
                  <div className="flex text-base font-semibold">
                    {gstyles.icons({ name: 'calendar', size: 22, fill: gstyles.colors.sub })}
                    <span className="ml-2">{ctx.apply('i18n.t', 'ProfileUser.yourStudySchedule')}</span>
                  </div>
                </div>
                <div>
                  <Link
                    to={ctx.apply('routeStore.toUrl', 'userProfileMenu', '/calendar')}
                    className="block mx-auto text-base font-semibold"
                  >
                    {ctx.apply('i18n.t', 'AdvisorProfileGeneral.Detail')}
                  </Link>
                </div>
              </div>
              <div>
                <FlatList
                  data={ctx.get('courseRooms.items', [])}
                  renderItem={renderCoureRoomItem}
                  keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
                />
              </div>
            </div>
          </div>
        )}
      </DIV>
    </DIV>
  );
};

export default displayName(View8);
