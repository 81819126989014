import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';

function FooterAppsLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="appGroup py-0 order-1 lg:order-2" id="appGroup">
            <DIV>
              <Row gutter={{ xs: 12, sm: 16, md: 16 }}>
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (col, index) => (
                    <Col xs={12} lg={24} key={`${_.get(col, 'name', '')}_${index}`}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                    </Col>
                  )
                )}
              </Row>
            </DIV>
          </div>
        );
      })}
    </DIV>
  );
}

export default FooterAppsLayout;
