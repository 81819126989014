import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Modal } from 'antd';
import { getLocaleConfig, getCurrentLocaleConfig } from '@uz/mod-translations/utils';
import useRoute from '@vl/hooks/useGbRoute';
import Button from '@uz/unitz-components-web/Button';
import cx from 'classnames';
import gstyles from '@vl/gstyles';

const MobileLangSwitch = () => {
  const route = useRoute();
  const localeConfigs = getLocaleConfig(false);
  const currentLocale = getCurrentLocaleConfig(route);

  const flags = {
    vi: 'vietnam-flag',
    en: 'uk-flag',
  };
  return (
    <DIV className="component">
      <div className="px-4 py-3 bg-background1">
        {localeConfigs.map((localeConfig) => {
          return (
            <div
              key={`${localeConfig.get('lang')}`}
              className="text-main"
              // to={localeConfig.withLangPrefix(`${_.get(route, 'location.pathname')}${_.get(route, 'location.search')}`)}
              onClick={() => ctx.apply('submitForm.handleSubmit', localeConfig.get('lang'))}
            >
              <div
                className={cx('flex relative justify-between rounded-lg items-center p-3', {
                  'bg-brandb-lighter': localeConfig.get('lang') === currentLocale.get('lang'),
                })}
                key={localeConfig.get('lang')}
              >
                <div className="flex justify-between w-full items-center">
                  <div className="flex space-x-2 items-center">
                    <span>
                      {gstyles.icons({
                        name: _.get(flags, localeConfig.get('lang'), ''),
                        size: 24,
                      })}
                    </span>
                    <span className="font-semibold text-sm text-main">
                      {ctx.apply('i18n.t', `SettingLanguage.${localeConfig.get('lang')}`)}
                    </span>
                    {localeConfig.get('lang') === currentLocale.get('lang') && (
                      <span className="absolute top-1/2 transform -translate-y-1/2 right-3">
                        {gstyles.icons({
                          name: 'check',
                          size: 24,
                          fill: gstyles.colors.brandb500,
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DIV>
  );
};

export default MobileLangSwitch;
