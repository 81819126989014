import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

function HeaderMenuLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const sectionData = ctx.get('sectionData');
        return (
          <div className="flex items-center justify-center">
            {_.get(ctx.apply('ctf.findEntry', { name: sectionData.name }), 'sections', []).map((item, index) => {
              return (
                <React.Fragment key={`${item.name}_${index}`}>
                  <div className="text-base text-white mb-0 mx-2">
                    {ctx.apply('ctf.renderItem', { name: item.name }) || null}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </DIV>
  );
}

export default HeaderMenuLayout;
