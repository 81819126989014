import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
// import './navbar.scss';
import MobileNavbar from './components/MobileNavbar';
import DesktopNavbar from './components/DesktopNavbar';
import TabletNavbar from './components/TabletNavbar';

function NewNavbar() {
  return (
    <div className="App-header">
      <DIV>
        <ResponsiveProps
          // md={{ renderer: RenderNavbarMediumSize }}
          xs={{ renderer: () => <MobileNavbar /> }}
          md={{ renderer: () => <TabletNavbar /> }}
          lg={{ renderer: () => <DesktopNavbar /> }}
          xl={{ renderer: () => <DesktopNavbar /> }}
        >
          {(resProps) => !!resProps && resProps.renderer()}
        </ResponsiveProps>
      </DIV>
    </div>
  );
}

export default NewNavbar;
