import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  guideline: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'LandingGuideStep' });
              const children = _.get(section, 'sections', []).map((section) => {
                return ctx.apply('ctf.findEntry', section);
              });
              return {
                section,
                children,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
