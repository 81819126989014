import { bindings } from '@vl/redata';

const bindData = bindings({
  carouselPrev: {
    rules: [
      [
        'data',
        {
          data: {}
        }
      ]
    ]
  }
});

export default bindData;
