import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col, Rate, Divider } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Avatar } from '@uz/unitz-components-web/Avatar';

const CustomParapraph = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;
const CustomLongText = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;

const CustomStar = styled.div`
  .ant-rate {
    font-size: 20px;
    margin-bottom: 2px;
    color: ${gstyles.colors.yellow.DEFAULT};
  }
`;

export const TestimonialItemX = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      // const item = ctx.get('item');
      return (
        <div className="py-6 bg-white500 xl:shadow-lg xl:rounded-xl">
          <Row className="text-center items-center" gutter={[24, 16]}>
            <Col xs={24}>
              <div className="h-full w-full flex flex-col">
                <div>
                  <Avatar
                    srcParams={{ fit: 'fill', fm: 'webp' }}
                    size={168}
                    src={ctx.apply('ctf.findImage', ctx.get('itemData'), 'image', '')}
                    alt="avatar"
                  />
                </div>
                <div className="font-semibold text-2xl min-lg:text-xl my-2 text-brandb500">
                  {_.get(ctx.get('itemData'), 'shortText', '')}
                </div>
                <div>
                  <CustomLongText>
                    {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                      className: 'text-base',
                      as: 'p',
                    })}
                  </CustomLongText>
                </div>
              </div>
              <div>
                <CustomStar>
                  <Rate allowHalf value={_.parseInt(ctx.get('itemData.className'))} disabled />
                </CustomStar>
              </div>
            </Col>
            <div className="mx-auto w-1/3">
              <Divider style={{ borderColor: gstyles.colors.blue500 }} />
            </div>
            <Col xs={24}>
              <div className="px-4 min-md:px-0 min-lg:px-6">
                <CustomParapraph>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'detailText.detailText', {
                    className: 'text-main',
                    as: 'p',
                  })}
                </CustomParapraph>
              </div>
            </Col>
          </Row>
        </div>
      );
    })}
  </DIV>
);

export default TestimonialItemX;
