import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import Data from './Data';
import View from './View';
import Button from '@uz/unitz-components-web/Button';
const Component = Data(View);

const AuthEnhancer = (Base) => () => {
  return <DIV forceCtx>{!ctx.apply('authModel.getUserId') ? <Component /> : <Base />}</DIV>;
};

Component.AuthEnhancer = AuthEnhancer;
/**
 * LoginEnhancer: Cho hiển thị bình thường, nếu component Base có sử dụng getLoginEnhancerButton, thì sẽ hiển thị form login sau khi ấn vào button này.
 * */
const LoginEnhancer = (Base) => () => {
  const [showBase, $showBase] = React.useState(true);
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('getLoginEnhancerButton', ({ message = '', title = '', onClick = () => {} }) => {
          return ctx.apply('authModel.getUserId') ? null : (
            <Button
              type="primary"
              block
              onClick={() => {
                onClick();
                $showBase(false);
              }}
            >
              {title ? title : ctx.apply('i18n.t', 'Login.login')}
            </Button>
          );
        });
      })}
      {showBase && <Base />}
      {!showBase && <Component />}
    </DIV>
  );
};
Component.LoginEnhancer = LoginEnhancer;

export default Component;
