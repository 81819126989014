import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParagraph = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const TextContentLayout = () => {
  return (
    <DIV className="TextContentLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div>
          <h2 className="mb-6 text-xl sm:text-4xl font-semibold text-center min-lg:text-left text-main">
            {ctx.get('sectionData.shortText')}
          </h2>
          <CustomParagraph className="text-center min-lg:text-left">
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
              className: 'mt-5',
              as: 'p',
            })}
          </CustomParagraph>
          <CustomParagraph>
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'detailText.detailText', {
              className: 'py-2 text-justify mb-0',
              as: 'div',
            })}
          </CustomParagraph>
        </div>
      ))}
    </DIV>
  );
};

export default TextContentLayout;
