import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import gstyles from '@vl/gstyles';
import UnitzBizInformationForm from '@uz/unitz-components-web/UnitzBizInformationForm';

const GreenDisc1 = styled.div`
  position: absolute;
  top: 140%;
  left: 20%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 1190px;
  height: 1190px;
  background-color: ${gstyles.colors.brandb500};
  overflow: hidden;
  opacity: 0.15;
`;

const GreenDisc2 = styled.div`
  position: absolute;
  top: 0;
  right: -60%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 1079px;
  height: 1079px;
  background-color: ${gstyles.colors.brandb.light};
  overflow: hidden;
  opacity: 0.15;
`;

const GreenDisc3 = styled.div`
  position: absolute;
  top: 100%;
  right: -40%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 978px;
  height: 978px;
  background-color: ${gstyles.colors.brandb.light};
  overflow: hidden;
  opacity: 0.15;
`;

const ForSchoolTourPlatformLayout = () => {
  return (
    <DIV className="ForSchoolTourPlatformLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div id="form" className={`relative overflow-hidden ${_.get(ctx.get('sectionData'), 'className', '')}`}>
            <GreenDisc1 className="hidden lg:block" />
            <GreenDisc2 className="hidden lg:block" />
            <GreenDisc3 className="hidden lg:block" />
            <div className="wrapper app-row">
              <ResponsiveProps xs={{ style: { textAlign: 'center' } }} md={{ style: { textAlign: 'left' } }}>
                {(resProps) => (
                  <div className="lg:flex h-full" {...resProps}>
                    <div className="block w-full lg:w-1/2">
                      <div className="flex flex-col justify-around h-full">
                        <div>
                          <div className="text-main font-semibold text-xl lg:text-4xl mb-2">
                            {ctx.get('sectionData.shortText')}
                          </div>
                          <div className="pr-4">
                            {ctx.apply(
                              'ctf.renderHTMLText',
                              { name: ctx.get('sectionData.name', '') },
                              'longText.longText',
                              null
                            )}
                          </div>
                        </div>

                        <div className="hidden lg:block">
                          {_.map(ctx.get('sectionData.sections'), (item, index) => (
                            <React.Fragment key={index}>
                              {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="w-full lg:w-1/2">
                      <div>
                        <UnitzBizInformationForm />
                        <div className="lg:hidden block mt-4">
                          {_.map(ctx.get('sectionData.sections'), (item, index) => (
                            <React.Fragment key={index}>
                              {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </ResponsiveProps>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForSchoolTourPlatformLayout;
