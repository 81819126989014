import React from 'react';
import _ from 'lodash';

class Stream {
  listeners = {};

  emit(event, ...params) {
    _.get(this.listeners, [event], []).map((listener) => listener.call(this, ...params));
  }

  on(event, listener) {
    _.update(this.listeners, [event], (val) => (val ? val.concat(listener) : [listener]));
    return () => {
      _.update(this.listeners, [event], (val) => _.reject(val || [], listener));
    };
  }

  proxy(stream, events) {
    const dispoers = [].concat(events || _.keys(stream.listeners)).map((event) => {
      return stream.on(event, (...params) => {
        this.emit(event, ...params);
      });
    });
    return () => dispoers.map((dis) => dis());
  }
}

const resolveFunc = (fn, ...args) => {
  if (_.isFunction(fn)) {
    return fn.call(fn, ...args);
  }
  return fn;
};

const isObservable = (obj) => {
  return !!obj && typeof obj.subscribe === 'function';
};

const isPromise = (obj) => {
  return !!obj && typeof obj.then === 'function';
};

const isStream = (obj) => {
  return !!obj && typeof obj.on === 'function' && typeof obj.emit === 'function';
};

const isIterator = (obj) => {
  return !!obj && typeof obj.next === 'function';
};

export const RESABLE = ({ children, loading }) => {
  const [v, $v] = React.useState(0);

  const ref = React.useRef({
    loading: false,

    listPromise: {},

    listStream: {},
    listStreamSubscriber: {},

    listObservable: {},
    listSubscription: {},

    listData: {},
    listOpts: {},

    stream: new Stream(),
    disposers: []
  });

  _.assign(ref.current, { v, $v });

  React.useMemo(() => {
    ref.current.disposers
      && ref.current.disposers.push(
        ref.current.stream.on(
          'resolve',
          _.debounce(async () => {
            ref.current.loading = true;

            // for listPromise
            if (ref.current.listPromise) {
              for (const key of Object.keys(ref.current.listPromise)) {
                if (!_.has(ref.current.listData, key)) {
                  let item = ref.current.listPromise[key];
                  item = await resolveFunc(item);
                  _.set(ref.current.listData, key, item);
                }
              }
            }

            // for listObservable
            if (ref.current.listObservable) {
              for (const key of Object.keys(ref.current.listObservable)) {
                if (!_.has(ref.current.listSubscription, key)) {
                  const item = ref.current.listObservable[key];
                  const sub = item.subscribe((val) => {
                    _.set(ref.current.listData, key, val);
                    ref.current.stream && ref.current.stream.emit('changed');
                  });
                  _.set(ref.current.listSubscription, key, sub);
                }
              }
            }

            // for listStream
            if (ref.current.listStream) {
              for (const key of Object.keys(ref.current.listStream)) {
                if (!_.has(ref.current.listStreamSubscriber, key)) {
                  const item = ref.current.listStream[key];
                  const event = _.get(ref.current.listOpts, [key, 'event'], 'data');
                  const sub = item.on(event, (val) => {
                    _.set(ref.current.listData, key, val);
                    ref.current.stream && ref.current.stream.emit('changed');
                  });
                  _.set(ref.current.listStreamSubscriber, key, sub);
                }
              }
            }

            ref.current.loading = false;
            ref.current.stream && ref.current.stream.emit('changed');
          })
        )
      );

    ref.current.disposers
      && ref.current.disposers.push(
        ref.current.stream.on(
          'changed',
          _.debounce(async () => {
            if (!_.isEqual(ref.current.prevData, ref.current.listData)) {
              ref.current.prevData = _.cloneDeep(ref.current.listData);
              ref.current.$v && ref.current.$v(ref.current.v + 1);
            }
          })
        )
      );
  }, []);

  React.useEffect(() => {
    return () => {
      ref.current.disposers && ref.current.disposers.forEach((dis) => dis());

      // unsubscribe subscriptions
      ref.current.listSubscription && _.map(ref.current.listSubscription, (sub) => sub && sub.unsubscribe);

      // disposer stream listener
      ref.current.listStreamSubscriber
        && _.map(ref.current.listStreamSubscriber, (sub) => {
          return sub && sub();
        });

      ref.current = {};
    };
  }, []);

  const resolvePromiseGen = React.useCallback(() => {
    let index = 0;
    const getAutoKey = (prefix = 'auto') => {
      index++;
      return `${prefix}_${index}`;
    };
    const resolvePromise = (data, def, keyId, opts) => {
      const key = keyId || getAutoKey('promise');
      ref.current.listPromise && _.set(ref.current.listPromise, key, data);
      ref.current.listOpts && _.set(ref.current.listOpts, key, opts);
      ref.current.stream && ref.current.stream.emit('resolve', key);
      return _.get(ref.current.listData, key, def);
    };

    const resolveObservable = (data, def, keyId, opts) => {
      const key = keyId || getAutoKey('observable');
      ref.current.listObservable && _.set(ref.current.listObservable, key, data);
      ref.current.listOpts && _.set(ref.current.listOpts, key, opts);
      ref.current.stream && ref.current.stream.emit('resolve', key);
      return _.get(ref.current.listData, key, def);
    };

    const resolveStream = (data, def, keyId, opts) => {
      const key = keyId || getAutoKey('stream');
      ref.current.listStream && _.set(ref.current.listStream, key, data);
      ref.current.listOpts && _.set(ref.current.listOpts, key, opts);
      ref.current.stream && ref.current.stream.emit('resolve', key);
      return _.get(ref.current.listData, key, def);
    };

    const resolver = (data, def, keyId, opts) => {
      if (isPromise(data)) {
        // console.log('resolving promise')
        return resolvePromise(data, def, keyId, opts);
      } if (isObservable(data)) {
        // console.log('resolving observable', data);
        return resolveObservable(data, def, keyId, opts);
      } if (isStream(data)) {
        // console.log('resolving stream', data);
        return resolveStream(data, def, keyId, opts);
      } if (isIterator(data)) {
        // console.log('resolving iterator', data);
        return def;
      }
      // console.log('not resolveable data')
      return data;
      // return def;
    };

    resolver.for = (keyId, opts) => (data, def) => resolver(data, def, keyId, opts);

    return resolver;
  }, []);

  if (ref.current.loading) {
    return resolveFunc(loading) || null;
  }

  return resolveFunc(children, resolvePromiseGen());
};

export const resable = () => {};

export default RESABLE;
