import React from 'react';
import useForceUpdate from '@vl/hooks/useForceUpdate';
import _ from 'lodash';

const hoistedQueue = {};

const ref = {
  keys: [],
};

const getHoistedQueue = () => {
  const queue = {
    add: (id, data) => {
      _.set(hoistedQueue, id, data);
      ref.keys = _.keys(hoistedQueue).sort();
    },
    isHoisted: (id) => {
      return id === queue.getHoistedId();
    },
    remove: (id) => {
      const isHoisted = queue.isHoisted(id);
      _.unset(hoistedQueue, id);
      ref.keys = _.keys(hoistedQueue).sort();
      const hoistedId = queue.getHoistedId();
      if (hoistedId && isHoisted) {
        const forceUpdate = _.get(hoistedQueue, [hoistedId, 'forceUpdate']);
        if (forceUpdate) {
          forceUpdate();
        }
      }
    },
    getHoistedId: () => {
      return _.first(ref.keys);
    }
  };
  return queue;
};

export const useHoistedRender = (opts = {}) => {
  let { id } = opts;
  id = React.useMemo(() => {
    return id || _.uniqueId('hoistedId_');
  }, []);
  const forceUpdate = useForceUpdate();
  const queue = React.useMemo(() => {
    const queue = getHoistedQueue();
    queue.add(id, { forceUpdate });
    return queue;
  }, []);
  let shouldRender = queue.isHoisted(id);
  const unmountEffect = () => {
    queue.remove(id);
  };
  React.useEffect(() => unmountEffect, []);
  return [shouldRender, unmountEffect];
};

export default useHoistedRender;
