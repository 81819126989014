import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useRoute from '@vl/hooks/useGbRoute';

const bindData = bindings({
  courseRoomMessageModal: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const route = useRoute();
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const initialValues = {};

              const form = useFormik({
                initialValues,
                onSubmit: async (values, actions) => {
                  try {
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
