/**
 * GLightbox
 * Awesome pure javascript lightbox
 * made by https://www.biati.digital
 * Github: https://github.com/biati-digital/glightbox
 */

const defaults = {
  selector: '.glightbox',
  elements: null,
  skin: 'clean',
  theme: 'clean',
  closeButton: true,
  startAt: null,
  autoplayVideos: true,
  autofocusVideos: true,
  descPosition: 'bottom',
  width: '900px',
  height: '506px',
  videosWidth: '960px',
  beforeSlideChange: null,
  afterSlideChange: null,
  beforeSlideLoad: null,
  afterSlideLoad: null,
  slideInserted: null,
  slideRemoved: null,
  slideExtraAttributes: null,
  onOpen: null,
  onClose: null,
  loop: false,
  zoomable: false,
  draggable: false,
  dragAutoSnap: false,
  dragToleranceX: 40,
  dragToleranceY: 65,
  preload: true,
  oneSlidePerOpen: false,
  touchNavigation: false,
  touchFollowAxis: false,
  keyboardNavigation: true,
  closeOnOutsideClick: true,
  plugins: false,
  plyr: {
    css: 'https://cdn.plyr.io/3.6.8/plyr.css',
    js: 'https://cdn.plyr.io/3.6.8/plyr.js',
    config: {
      ratio: '16:9', // or '4:3'
      fullscreen: { enabled: true, iosNative: true },
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        autoplay: true,
        autoplayVideos: true,
        iv_load_policy: 3, // eslint-disable-line camelcase
      },
      vimeo: {
        byline: false,
        portrait: false,
        title: false,
        autoplay: true,
        autoplayVideos: true,
        transparent: false,
      },
    },
  },
  openEffect: 'zoom', // fade, zoom, none
  closeEffect: 'zoom', // fade, zoom, none
  slideEffect: 'slide', // fade, slide, zoom, none
  moreText: 'See more',
  moreLength: 60,
  cssEfects: {
    fade: { in: 'fadeIn', out: 'fadeOut' },
    zoom: { in: 'zoomIn', out: 'zoomOut' },
    slide: { in: 'slideInRight', out: 'slideOutLeft' },
    slideBack: { in: 'slideInLeft', out: 'slideOutRight' },
    none: { in: 'none', out: 'none' },
  },
  svg: {
    close:
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"/></g></g><g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"/></g></g></svg>',
    next:
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"/></g></svg>',
    prev:
      '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/></g></svg>',
  },
};

// You can pass your own slide structure
// just make sure that add the same classes so they are populated
// title class = gslide-title
// desc class = gslide-desc
// prev arrow class = gnext
// next arrow id = gprev
// close id = gclose
defaults.slideHTML = `<div class="gslide">
     <div class="gslide-inner-content" style="position: relative;">
         <div class="ginner-container">
             <div class="gslide-media">
             </div>
             <div class="gslide-description">
                 <div class="gdesc-inner">
                     <h4 class="gslide-title"></h4>
                     <div class="gslide-desc"></div>
                 </div>
             </div>
         </div>
     </div>
 </div>`;

defaults.lightboxHTML = `<div id="glightbox-body" class="glightbox-container" tabindex="-1" role="dialog" aria-hidden="false">
     <div class="gloader visible"></div>
     <div class="goverlay"></div>
     <div class="gcontainer">
     <div id="glightbox-slider" class="gslider"></div>
     <button class="gclose gbtn" style="left: 0px; top: 0px;" aria-label="Close" data-taborder="3">{closeSVG}</button>
     <button class="gprev gbtn" aria-label="Previous" data-taborder="2">{prevSVG}</button>
     <button class="gnext gbtn" aria-label="Next" data-taborder="1">{nextSVG}</button>
 </div>
 </div>`;

export default defaults;
