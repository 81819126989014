import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import cx from 'classnames';
import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import LangSwitchTransparent from '@uz/unitz-ctf-theme/layouts/LangSwitchTransparent';

const hasScrollY = () => {
  // eslint-disable-next-line
  const [hasScroll, $hasScroll] = React.useState(false);
  // eslint-disable-next-line
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        let scroll = null;
        if (window.scrollY === 0) {
          scroll = false;
        } else {
          scroll = true;
        }
        $hasScroll(scroll);
      });
    }
  }, []);
  return hasScroll;
};

const StickyNav = styled(({ children, className }) => {
  const hasScroll = hasScrollY();
  const { observe, height } = useDimensions({
    onResize: _.throttle(() => {}, 1000),
  });
  const ref = React.useRef({});
  const placeHolderHeight = React.useMemo(() => {
    if (height > 0) {
      ref.current.height = height;
    }
    return ref.current.height;
  }, [height]);

  return (
    <div className={className}>
      <div className="header-Placeholder" style={{ height: placeHolderHeight }} />
      <header className={cx(`header-sticky ${hasScroll ? 'sticky' : ''}`)} ref={observe}>
        {children}
      </header>
    </div>
  );
})`
  > .sticky {
    position: fixed;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  }
`;

const DesktopNewNavbar = () => {
  return (
    <DIV>
      <StickyNav>
        <SectionWrapper className="relative" as="div">
          <div className="flex flex-row h-16 px-4 space-x-6 app-row">
            <div className="flex items-center justify-center">
              <div className="m-2">
                <a
                  href={ctx.apply(
                    'ctf.findEntry',
                    { name: ctx.apply('ctf.findSectionChild', 0, 'name') },
                    'linkHref',
                    ''
                  )}
                >
                  <Image
                    size={40}
                    src={ctx.apply(
                      'ctf.findImage',
                      { name: ctx.apply('ctf.findSectionChild', 0, 'name') },
                      'image',
                      ''
                    )}
                    alt="banner"
                  />
                </a>
              </div>
            </div>
            <div className="flex flex-row items-center text-main flex-1 ml-2">
              {ctx.apply('ctf.renderSectionChild', 1)}
            </div>
            <div className="flex flex-row items-center">
              <div className="search-menu-account-wrapper">{ctx.apply('ctf.renderSectionChild', 2)}</div>
              <LangSwitchTransparent />
            </div>
          </div>
        </SectionWrapper>
      </StickyNav>
    </DIV>
  );
};

export default DesktopNewNavbar;
