import _ from 'lodash';
import importAll from 'import-all.macro';

import DIContainer from '@vl/mod-utils/DIContainer';

const componentContainer = new DIContainer();

componentContainer.loadDir([importAll.sync('./**/index.js')]);

export const resolve = (itemData, info) => {
  return resolveComponent(itemData, info);
};

export const resolveProps = (itemData, info) => {
  const options = _.get(info, 'options', {});
  return {
    ...options,
  };
};

// eslint-disable-next-line
const ver = 24;

export const resolveComponent = (itemData, info) => {
  const options = _.get(info, 'options', {});
  // component overrided from options
  const componentNameFromOptions = _.get(options, 'component');
  if (componentNameFromOptions) {
    if (componentContainer.has(componentNameFromOptions)) {
      return componentContainer.get(componentNameFromOptions);
    }
  }

  // resolve component from component.name field
  const componentName = _.get(itemData, 'component.name');
  if (componentContainer.has(componentName)) {
    return componentContainer.get(componentName);
  }
  return null;
};
