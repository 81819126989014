import React, { useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

// import './menu.scss';

function MenuH() {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState();
  const toggle = () => setIsOpen(!isOpen);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const activeLink = (link) => {
    toggle();
    setActive(link);
    return 60;
  };
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <nav className="navbar navbar-wrapper menu-wrapper">
            <div className="sidebar-toggle">
              <button className="menu-btn navbar-toggler" type="button" id="navbar-menu-btn" onClick={toggle}>
                <span className="btn-block"></span>
                <div className="title">Menu</div>
              </button>
            </div>
            <div className={isOpen ? 'open' : ''} id="sidebar">
              <div className="sidebar-overlay"></div>
              <div className="sidebar-wrapper">
                <div className="slidebar-title-wrapper">
                  <div className="slidebar-title">
                    <div id="slidebar-close-btn"></div>
                  </div>
                </div>
                <div className="navbar-link-wrapper">
                  <ul className="navbar-nav navbar-menu-frist" id="menu-navbar">
                    {_.map(ctx.get('sectionData.sections', []), (section, index) => {
                      const menuItem = ctx.apply('ctf.findSectionChild', section);
                      if (!menuItem) return null;
                      return ctx.apply(
                        'ctf.renderEnhancers',
                        menuItem,
                        <li key={index} className={`nav-item-box ${active === menuItem.linkHref ? 'active' : ''}`}>
                          <AnchorLink offset={() => activeLink(menuItem.linkHref)} href={menuItem.linkHref}>
                            <span>{menuItem.shortText}</span>
                          </AnchorLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        );
      })}
    </DIV>
  );
}

export default MenuH;
