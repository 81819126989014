import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { ctx } from '@vl/redata';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    color: ${gstyles.colors.main};
  }
`;

function AdvisorBenefitLayout() {
  return (
    <DIV className="AdvisorBenefitContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="sm:app-row sm:wrapper" id={ctx.get('tabData')}>
            <div className="mb-0 sm:mb-6">
              <h2 className="mb-0 lg:mb-4 font-semibold text-2xl lg:text-4xl text-main text-center">
                {ctx.get('sectionData.shortText')}
              </h2>
              <CustomParagraph>
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                  className: 'text-center text-ellipsis-4',
                  as: 'p',
                })}
              </CustomParagraph>
            </div>

            <div className="list-benefit">
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                (item, index) => (
                  <div className="mb-4 border-b-2" key={index}>
                    <DIV forceCtx>
                      {ctx.debug(() => {
                        ctx.set('index', index);
                      })}
                      {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                    </DIV>
                  </div>
                )
              )}
            </div>
          </div>
        );
      })}
    </DIV>
  );
}

export default AdvisorBenefitLayout;
