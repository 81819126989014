import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';

const AdvisorSupportCenterMenuLayout = () => {
  return (
    <DIV className="AdvisorSupportCenterMenuContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="app-row wrapper md:px-4 md:mx-0 md:w-full xl:mx-auto xl:px-0">
            <Row gutter={18} className="content">
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map((item) => {
                return (
                  <Col key={item.id} span={8}>
                    {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </DIV>
  );
};

export default AdvisorSupportCenterMenuLayout;
