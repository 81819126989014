import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

import GetCourse from 'unitz-gql/course/GetCourse.gql';

graphme.GqlBuilder.loadDocument({
  GetCourse,
});

class CourseModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Course',
    schema: {
      id: String,
      is_active: Boolean,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetCourse',

    GQL_ACTIONS: {
      FIND: 'course',
      GET: 'course_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }

  static async create(payload) {
    try {
      const res = await hasuraClient.getClient().request(
        hasuraClient.gql`
        mutation insert_course_one($payload: course_insert_input!) {
          insert_course_one(
            object: $payload
            on_conflict: {
              constraint: course_pkey,
              update_columns: [id],
            }
          ) {
            id
            name
            sessions {
              id
            }
            benefits {
              id
            }
          }
        }
        `,
        {
          payload,
        }
      );
      return _.get(res, 'insert_course_one');
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async update(payloads, opts) {
    const { categories, ...payload } = payloads;
    // console.log({ payloads, opts });
    const removed_session_ids = _.compact(_.castArray(_.get(opts, 'removedSessionIds') || []));
    const removed_benefit_ids = _.compact(_.castArray(_.get(opts, 'removedBenefitIds') || []));
    // return payload;
    try {
      const res = await hasuraClient.getClient().request(
        hasuraClient.gql`
        mutation ($course_id: uuid!, $payload: course_insert_input!, $on_conflict: course_on_conflict, $removed_session_ids: [uuid!], $removed_benefit_ids: [uuid!]){
          delete_course_category(where: {course_id: {_eq: $course_id}}) {
            returning {
              category_id
            }
          }
          insert_course_one( 
            object: $payload
            on_conflict: $on_conflict
          ) {
            id
            name
            sessions {
              id
            }
            benefits {
              id
            }
          }
          update_course_session(
            where: {
              id: {_in: $removed_session_ids}
            },
            _set: {
              deleted: 1
            }) {
            returning {
              id
              deleted
            }
          }
          update_course_benefit(
            where: {
              id: {_in: $removed_benefit_ids}
            },
            _set: {
              deleted: 1
            }) {
            returning {
              id
              deleted
            }
          }
        }
        `,
        {
          course_id: payload.id,
          payload: {
            ...payload,
            categories: {
              data: _.map(categories, (item) => {
                return {
                  category_id: item,
                };
              }),
              on_conflict: {
                constraint: 'course_category_course_id_category_id_key',
                update_columns: ['category_id'],
              },
            },
          },
          on_conflict: {
            constraint: 'course_pkey',
            update_columns: _.keys(_.omit(payload, ['sessions', 'benefits'])),
          },
          removed_session_ids,
          removed_benefit_ids,
        }
      );
      return _.get(res, 'insert_course_one');
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  static async updateFavorite({ created_by, target_id }) {
    const query = hasuraClient.gql`
      mutation updateFavorite($created_by: String!, $target_id: uuid!) {
        advisor: insert_favorite_course_one(object: {created_by: $created_by, target_id: $target_id},
          on_conflict: {constraint: favorite_course_target_id_created_by_key, update_columns: score}) {
            id
            score
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        created_by,
        target_id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async removeFavorite({ created_by, target_id }) {
    const query = hasuraClient.gql`
      mutation removeFavorite($created_by: String!, $target_id: uuid!) {
        advisor: delete_favorite_course(where: {target_id: {_eq: $target_id}, created_by: {_eq: $created_by}}) {
          returning {
            id
            score
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        created_by,
        target_id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ CourseModel });

export default CourseModel;
