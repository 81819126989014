import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';

const CustomParapraph = styled.div`
  ul,
  li,
  p {
    color: ${gstyles.colors.white500};
  }
`;

export const BizAdvantageItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <div className="advantageItem">
          <div className="flex mb-4 justify-start items-center">
            <div>
              <div className="w-12 lg:w-16">
                <Image
                  className="object-cover"
                  size="cover"
                  src={ctx.apply('ctf.findImage', itemData, 'image', '')}
                  alt={_.get(itemData, 'shortText')}
                  preview={false}
                />
              </div>
            </div>
            <div className="content font-semibold text-xl lg:text-3xl text-white500 ml-2">{_.get(itemData, 'shortText')}</div>
          </div>
          <CustomParapraph>
            {ctx.apply('ctf.renderRichText', { name: itemData.name }, 'richText', null)}
          </CustomParapraph>
        </div>
      );
    })}
  </DIV>
);

export default BizAdvantageItem;
