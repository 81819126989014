import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import Slider from 'react-slick';
import Image from '../../components/image/index';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './brands.scss';

function Brand() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <section className="brand-slider">
      <DIV>
        <Slider {...settings}>
          {[0, 1, 2, 3].map((i) => (
            <div className="brand-item" key={i}>
              <div className="brand-content">
                <Image Path={ctx.apply('ctf.findImage', { name: 'brandItems' }, `images.${i}`, '', { width: 258 })} />
              </div>
            </div>
          ))}
        </Slider>
      </DIV>
    </section>
  );
}

export default Brand;
