import React from 'react';
import _ from 'lodash';

export const BindingContext = React.createContext();

const shouldVisit = ({ node }) => {
  return _.has(node, 'children') && !!Object.keys(node.children).length;
};

const howVisit = ({ node, key, path }) => {
  return { node: node.children, path: [...path, key] };
};

const canVisit = (val) => _.isObject(val);

const visitFn = (visitor) => {
  const paths = [];
  const visit = ({ node, path = [] }) => {
    if (canVisit(node)) {
      _.map(node, (value, key) => {
        const args = {
          parent: node,
          node: value,
          key,
          path
        };
        if (shouldVisit(args)) {
          visitor(args);
          visit(howVisit(args));
        }
      });
    }
    return paths;
  };
  return visit;
};

const isMatchItem = (val1, val2) => {
  return val1 === val2 || (_.isArray(val2) && val2.includes(val1));
};

const matchSubList = (list1, list2) => {
  let p1 = 0;
  let p2 = 0;
  if (list2.length < list1.length) {
    return false;
  }

  // last set must match
  const lastFound = isMatchItem(list1[list1.length - 1], list2[list2.length - 1]);
  if (!lastFound) return lastFound;

  let found1 = true;

  while (p1 < list1.length - 1 && found1) {
    const val1 = list1[p1];
    let found2 = false;
    while (p2 < list2.length - 1 && !found2) {
      const val2 = list2[p2];
      if (isMatchItem(val1, val2)) {
        found2 = true;
      }
      p2++;
    }
    if (!found2) {
      found1 = false;
    }
    p1++;
  }
  return found1;
};

class BindingContainer {
  data = {};

  selectors = [];

  constructor(data) {
    this.data = data;
    this.buildSchema();
  }

  buildSchema() {
    visitFn(({ path, node, key }) => {
      Object.keys(node.children).map((child) => {
        const selectorPath = [...path, key, child];
        const def = node.children[child];
        this.selectors.push({ path: selectorPath, value: def });
        return child;
      });
    })({ node: this.data });
    _.map(this.data, (value, key) => {
      this.selectors.push({ path: [key], value });
    });
  }

  get({ className: path, ctx }) {
    const classNamePath = [path, ...ctx.getPath()].map((item) => `${item}`.split(' ')).reverse();
    const ruleSets = _.filter(this.selectors, ({ path }) => {
      return matchSubList(path, classNamePath);
    });
    // merge rules
    return ruleSets;
  }
}

const bindings = (spec) => (Base) => {
  const data = new BindingContainer(spec);
  const BindingWrapper = (props) => (
    <BindingContext.Provider value={data}>
      <Base {...props} />
    </BindingContext.Provider>
  );
  return BindingWrapper;
};

export default bindings;
