import React from 'react';
import _ from 'lodash';

const useForceReducer = () => {
  const [, $state] = React.useState({});
  const ref = React.useRef({});
  if (!ref.current.forceUpdate) {
    ref.current.forceUpdate = () => {
      ref.current.isMounted && ref.current.$state({});
    };
  }
  ref.current.$state = $state;
  React.useEffect(() => {
    ref.current.isMounted = true;
    return () => {
      ref.current = {};
    };
  }, []);
  return ref.current.forceUpdate;
};

export const useForceUpdateDebounce = (delay) => {
  const forceUpdate = useForceReducer();
  const ref = React.useRef({});
  _.assign(ref.current, { forceUpdate });
  return React.useMemo(() => {
    return _.debounce(() => ref.current.forceUpdate(), delay);
  }, [delay]);
};

export default function useForceUpdate(delay) {
  if (delay) return useForceUpdateDebounce(delay);
  const forceUpdate = useForceReducer();
  return forceUpdate;
}
