import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const UnitzBizHeroLayout = () => {
  return (
    <DIV className="UnitzBizHeroLayout">
      {ctx.apply('ctf.renderProps', () => {
        const dataChildrenEntry = ctx.apply('ctf.findNode', {
          name: ctx.get('sectionData.sections.0.name'),
        });
        return (
          <div className="relative overflow-hidden lg:py-16">
            <div className="wrapper app-row">
              <div className="block lg:hidden mb-4">
                {ctx.apply('ctf.renderEntry', {
                  name: _.get(dataChildrenEntry, 'sections.0.name'),
                })}
              </div>
              <ResponsiveProps
                xs={{ style: { flexDirection: 'column-reverse', textAlign: 'center' } }}
                md={{ style: { flexDirection: 'row', textAlign: 'left' } }}
              >
                {(resProps) => (
                  <Row justify="center" align="middle" {...resProps}>
                    <Col xs={24} md={8}>
                      <Row gutter={[0, 0]}>
                        <Col xs={24} lg={23}>
                          <div className="hidden lg:block">
                            {ctx.apply('ctf.renderEntry', {
                              name: _.get(dataChildrenEntry, 'sections.0.name'),
                            })}
                          </div>
                          <div className="py-2">
                            {ctx.apply('ctf.renderEntry', {
                              name: _.get(dataChildrenEntry, 'sections.1.name'),
                            })}
                          </div>
                          <div className="my-2 w-full md:w-2/3">
                            {ctx.apply('ctf.renderEntry', {
                              name: _.get(dataChildrenEntry, 'sections.2.name'),
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={16}>
                      {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.1.name') })}
                    </Col>
                  </Row>
                )}
              </ResponsiveProps>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizHeroLayout;
