import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import { Row, Col } from 'antd';
import Link from '@uz/unitz-components-web/Link';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;
function LandingMagazinesLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className={ctx.get('sectionData.className')}>
            <section className="mb-0 wrapper app-row benefit-section">
              <CustomParagraph className="text-center">
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
              </CustomParagraph>
              <Row align="middle" justify="center">
                {ctx.get('sectionData.sections.length') < 2 ? (
                  <Col xs={12} lg={6}>
                    {_.map(ctx.get('sectionData.sections', []), (item, index) => (
                      <div key={`item_${index}`} className="p-1 grid-span-1">
                        <div className="flex justify-center items-center h-full">
                          {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                        </div>
                      </div>
                    ))}
                  </Col>
                ) : (
                  <Col span={18}>
                    <div
                      className={cx(
                        'grid grid-col grid-cols-1 md:grid-cols-2 auto-cols-fr gap-4 justify-center mb-10',
                        {
                          'min-lg:grid-flow-col': ctx.get('sectionData.sections.length') <= 3,
                          'min-lg:grid-cols-4': ctx.get('sectionData.sections.length') > 3,
                        }
                      )}
                    >
                      {_.map(_.take(ctx.get('sectionData.sections', []), 8), (item, index) => (
                        <div
                          key={`item_${index}`}
                          className="p-1 grid-span-1"
                          // target="_blank"
                          // to={ctx.apply('routeStore.toLocale', _.get(item, 'linkHref', ''))}
                        >
                          <div className="flex justify-center items-center h-full">
                            {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                )}
              </Row>
            </section>
          </div>
        );
      })}
    </DIV>
  );
}

export default LandingMagazinesLayout;
