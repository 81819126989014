import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  AdvisorRegistratorCommunity: {
    rules: [
      [
        'data',
        {
          data: {
            VideoModel: hook((ctx) => {
              const [videoItem, $videoItem] = React.useState();

              const handler = {
                setItem: (index) => {
                  const data = _.get(
                    ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }),
                    `sections.${index}`,
                    []
                  );
                  const itemData = _.get(ctx.apply('ctf.findNode', { name: _.get(data, 'name') }), 'sections.1', []);
                  $videoItem(itemData);
                },
              };

              React.useEffect(() => {
                handler.setItem(0);
              }, []);
              return { videoItem, ...handler };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
