import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';

const CustomItem = styled.div`
  .ant-row {
    &:nth-child(odd) {
      flex-direction: row;
      .content {
        text-align: left;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .content {
        text-align: left;
      }
    }
  }
`;

const UnitzBizFeatureForSchoolLayout = () => {
  return (
    <DIV className="content">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="wrapper app-row">
            {!_.isNull(ctx.get('sectionData.shortText')) && (
              <h1 className="text-center text-2xl lg:text-3xl mb-4 lg:mb-12 text-main font-semibold">
                {ctx.get('sectionData.shortText')}
              </h1>
            )}

            {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
              (item, index) => {
                const dataChildrenEntry = ctx.apply('ctf.findNode', {
                  name: _.get(item, 'name', ''),
                });
                return (
                  <React.Fragment key={index}>
                    {!_.isNull(item, 'shortText') && (
                      <div className="text-3xl font-semibold mb-10">{_.get(item, 'shortText', '')}</div>
                    )}
                    {_.map(_.get(dataChildrenEntry, 'sections', []), (itemData) => (
                      <React.Fragment>
                        {ctx.apply('ctf.renderItem', { name: _.get(itemData, 'name', '') }) || null}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              }
            )}

            <CustomItem>
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                (item, index) => (
                  <React.Fragment key={index}>
                    {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                  </React.Fragment>
                )
              )}
            </CustomItem>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizFeatureForSchoolLayout;
