import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Image from '@uz/unitz-components-web/Image';
import gstyles from '@vl/gstyles';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';

export const VideoItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <div className={_.get(itemData, 'className', '')}>
          <LinkPlayer to={_.get(itemData, 'linkHref', '')}>
            <div className="relative">
              <Image
                size="cover"
                srcParams={{ w: 600 }}
                src={ctx.apply('ctf.findImage', itemData, 'image', '')}
                alt={_.get(itemData, 'id')}
              />
              <div className="absolute left-0 top-0 w-full h-full bg-black200"></div>
              <div className="absolute flex justify-center items-center border-4 border-white500 hover:opacity-90 h-12 w-12 rounded-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {gstyles.icons({ name: 'play', size: 24, fill: gstyles.colors.white500 })}
              </div>
            </div>
          </LinkPlayer>
        </div>
      );
    })}
  </DIV>
);

export default VideoItem;
