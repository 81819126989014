import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

export const YoutubeVideoIframe = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <div style={{ width: '100%' }} className={cx('mx-auto', _.get(itemData, 'className', ''))}>
          <ResponsiveProps
            xs={{ height: 220 }}
            sm={{ height: 350 }}
            md={{ height: 450 }}
            lg={{ height: 550 }}
            xl={{ height: 700 }}
          >
            {(resProps) => (
              <iframe
                style={{ margin: '0 auto', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', borderRadius: '6px' }}
                width="100%"
                src={_.get(itemData, 'linkHref', '')}
                title={_.get(itemData, 'shortText', '')}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
                {...resProps}
              />
            )}
          </ResponsiveProps>
        </div>
      );
    })}
  </DIV>
);

export default YoutubeVideoIframe;
