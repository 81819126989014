import React from 'react';
import _ from 'lodash';

export const useAsyncLoadingCall = (fn, initState) => {
  const ref = React.useRef({
    called: false,
  });
  const [loading, $loading] = React.useState(initState);

  ref.current.loadingWrapper = React.useMemo(
    () =>
      _.memoize(async (...args) => {
        _.set(ref.current.loadingWrapper, 'called', true);
        $loading(true);
        try {
          const res = await fn(...args);
          ref.current.result = res;
        } catch (err) {
          throw err;
        } finally {
          $loading(false);
        }
      }),
    []
  );
  return [ref.current.result, ref.current.loadingWrapper, loading];
};

export default useAsyncLoadingCall;
