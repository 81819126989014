import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Link as DirectLink } from '@uz/unitz-components-web/Link';

export const Link = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <DirectLink to={_.get(itemData, 'linkHref')}>
          <div className={_.get(itemData, 'className', '')}>{_.get(itemData, 'shortText', '')}</div>
        </DirectLink>
      );
    })}
  </DIV>
);

export default Link;
