import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Col } from 'antd';
import { arrowRightCircle } from 'react-icons-kit/feather/arrowRightCircle';
import _ from 'lodash';
import { Title } from '../../components/title';
import PreviewIcon from '../../components/icon/index';

function FooterLinks() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const sectionData = ctx.get('sectionData');
        return (
          <Col lg={2} sm={6}>
            <div className="footer-about-us">
              <div className="footer-about-outer">
                <Title Class="footer-title" Name={_.get(sectionData, 'shortText', '')} />
                <div className="footer-details-link">
                  <ul>
                    {_.get(sectionData, 'sections', []).map((item, index) => {
                      const linkItem = ctx.apply('ctf.findSectionChild', item);
                      if (!linkItem) return null;
                      return (
                        <li key={index}>
                          <a href={linkItem.linkHref || '/'}>
                            <PreviewIcon icon={arrowRightCircle} />
                            <Title Class="footer-link footer-arrow" Name={linkItem.shortText || ''} />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        );
      })}
    </DIV>
  );
}

export default FooterLinks;
