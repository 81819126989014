import _ from 'lodash';

import Streamable from './Streamable';
import * as utils from './utils';

const privateData = utils.privateDataWrapper({
  enhancers: () => [],
  instance: () => null,
  proxy: () => null,
});

export class BaseState extends Streamable {
  init(that) {
    _.assign(this, that.state);
    that.state = this;
    return this;
  }

  enhance(enhancer) {
    if (!enhancer) return;
    const proxy = this;
    const inst = privateData.get(proxy, 'instance');
    privateData.get(inst, 'enhancers').push(enhancer);

    if (_.isFunction(enhancer.onConnect)) {
      enhancer.onConnect(proxy);
    }
  }

  static create(fromState) {
    const inst = new BaseState();
    _.assign(inst, fromState);
    const enhancers = privateData.get(inst, 'enhancers');

    const handler = {
      get(target, prop, ...rest) {
        if (prop === 'toObject') {
          return () => {
            const rtn = utils.toObject(inst);
            for (let index = enhancers.length - 1; index >= 0; index--) {
              const enhancer = enhancers[index];
              const eRtn = utils.toObject(enhancer);
              _.assign(rtn, eRtn);
            }
            return rtn;
          };
        }
        // return first found prop from enhancer stack
        for (let index = enhancers.length - 1; index >= 0; index--) {
          const enhancer = enhancers[index];
          if (utils.hasProp(enhancer, prop)) {
            return Reflect.get(enhancer, prop, enhancer);
          }
        }
        return Reflect.get(target, prop, target);
      },
      set(target, prop, value) {
        // set through all enhancer
        for (let index = enhancers.length - 1; index >= 0; index--) {
          const enhancer = enhancers[index];
          if (utils.hasProp(enhancer, prop)) {
            Reflect.set(enhancer, prop, value, enhancer);
          }
        }
        return Reflect.set(target, prop, value, target);
      },
    };
    const proxy = new Proxy(inst, handler);

    privateData.set(proxy, 'instance', inst);
    privateData.set(inst, 'proxy', proxy);
    return proxy;
  }
}

export default BaseState;
