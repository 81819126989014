import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomText = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const UnitzBizHeroByObjectLayout = () => {
  return (
    <DIV className="UnitzBizHeroByObjectLayout">
      {ctx.apply('ctf.renderProps', () => {
        const dataChildrenEntry = ctx.apply('ctf.findNode', {
          name: ctx.get('sectionData.sections.1.name'),
        });
        return (
          <div className="relative overflow-hidden lg:py-8">
            <div className="wrapper app-row">
              <div className="block lg:hidden mb-4">
                {ctx.apply('ctf.renderEntry', {
                  name: _.get(dataChildrenEntry, 'sections.0.name'),
                })}
              </div>
              <ResponsiveProps
                xs={{ style: { flexDirection: 'column-reverse', textAlign: 'center' } }}
                md={{ style: { flexDirection: 'row', textAlign: 'left' } }}
              >
                {(resProps) => (
                  <Row
                    gutter={[
                      { xs: 0, md: 32 },
                      { xs: 0, md: 32 },
                    ]}
                    justify="center"
                    {...resProps}
                  >
                    <Col xs={24} md={12}>
                      {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.0.name') })}
                    </Col>
                    <Col xs={24} md={12}>
                      <Row gutter={[0, 0]}>
                        <Col xs={24} lg={20}>
                          <div className="hidden lg:block">
                            {ctx.apply('ctf.renderEntry', {
                              name: _.get(dataChildrenEntry, 'sections.0.name'),
                            })}
                          </div>
                          <CustomText>
                            <div className="py-2">
                              {ctx.apply('ctf.renderEntry', {
                                name: _.get(dataChildrenEntry, 'sections.1.name'),
                              })}
                            </div>
                          </CustomText>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </ResponsiveProps>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizHeroByObjectLayout;
