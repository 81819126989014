exports.LANG_MAP_FULL = {
  vi: 'vi_VN',
  en: 'en_US',
  de: 'de',
};

exports.LOCALE_CONFIG = [
  {
    name: 'en',
    locale: 'en-US',
    lang: 'en',
    country: 'US',
    flag: 'uk-flag',
  },
  {
    name: 'de',
    locale: 'de',
    lang: 'de',
    country: 'DE',
    flag: 'german-flag',
  },
];

exports.LANG_MAP_LESS = {
  en_US: 'en',
  vi_VN: 'vi',
  vi_US: 'vi',
  vi: 'vi',
  de: 'de',
};
