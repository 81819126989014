const createPromise = (options = {}) => {
  const { timeoutMs } = options;

  const rtn = {};
  const finallyCb = () => {
    if (rtn.timeoutId) {
      clearTimeout(rtn.timeoutId);
    }
    if (options.finallyCb) {
      options.finallyCb();
    }
  };

  rtn.promise = new Promise((resolve, reject) => {
    rtn.resolve = resolve;
    rtn.reject = reject;
  });

  if (timeoutMs) {
    rtn.timeoutId = setTimeout(() => {
      rtn.reject('Timeout');
    }, timeoutMs);
  }

  // config finally
  rtn.promise = rtn.promise.finally(finallyCb);
  return rtn;
};

exports.createPromise = createPromise;
