import React from 'react';

const xss = require('xss');

const htmlTextRender = ({ content, as, ...props }) => {
  if (!content) return null;
  const cleanHTML = xss(content);
  return React.createElement(as || 'div', { ...props, dangerouslySetInnerHTML: { __html: cleanHTML } });
};

export default htmlTextRender;
