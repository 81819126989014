import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col, Button } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import { Link } from '@uz/unitz-components-web/Link';
import useRoute from '@vl/hooks/useGbRoute';

const StyledLayout = styled.div`
  border-bottom: 2px solid #e4e7eb;
  &:last-child {
    border-bottom: none;
  }
`;

const FAQLayout = () => {
  const route = useRoute().getPageContext();
  return (
    <DIV className="FAQ">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div>
            <div className="wrapper app-row">
              <h2 className="mb-5 text-xl md:text-2xl lg:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              <div className="mb-0 lg:mb-12">
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (col, index) => {
                    return (
                      <StyledLayout className="py-4" key={`${_.get(col, 'name', '')}_${index}`}>
                        {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') })}
                      </StyledLayout>
                    );
                  }
                )}
              </div>
              <Row className="mb-0 lg:mb-12 justify-center" gutter={24}>
                <Col xs={24} lg={12}>
                  <Link to={ctx.apply('routeStore.toLocale', '/faqs')}>
                    <Button size="large" block>
                      {ctx.apply('i18n.t', 'Question.moreCommonQuestion')}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default FAQLayout;
