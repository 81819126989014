import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

import AdvisorItemX from '@uz/unitz-components-web/AdvisorItemX';
import FlatList from '@uz/unitz-components-web/FlatList';
import { Row, Col, Button } from 'antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const renderItem = ({ item, index }) => {
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('@item', item);
        ctx.set('@index', index);
      })}
      <div>
        <AdvisorItemX />
      </div>
    </DIV>
  );
};

const View8 = () => (
  <DIV className="component advisorListX 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">
    <div className="block py-0 mx-4 border-b sm:mx-0 xl:hidden"></div>
    <FlatList
      data={ctx.get('paginationModel.items', [])}
      renderItem={renderItem}
      keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
      horizontal
    />
    {ctx.get('paginationModel.hasNextPage') && (
      <div className="px-4 py-0 sm:app-row xl:px-0">
        <Row
          className="mt-6 xl:mt-14"
          justify="center"
          gutter={{
            xs: 10,
            sm: 16,
            md: 10,
            lg: 24,
          }}
        >
          <Col xs={12} md={12} lg={12} xl={6}>
            <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
              {(resProps) => (
                <Button
                  block
                  onClick={ctx.get('paginationModel.onLoadMore')}
                  loading={ctx.get('paginationModel.isLoading')}
                  {...resProps}
                >
                  {ctx.apply('i18n.t', 'Advisor.watchMore')}
                </Button>
              )}
            </ResponsiveProps>
          </Col>

          {/* <Col xs={12} md={12} lg={12} xl={ctx.get('paginationModel.hasNextPage') ? 6 : 12}>
           <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
             {(resProps) => (
               <Button block onClick={ctx.get('advisorSearchForm.submitForm')} {...resProps}>
                 {ctx.apply('i18n.t', 'Advisor.fitWithYou')}
               </Button>
             )}
           </ResponsiveProps>
         </Col> */}
        </Row>
      </div>
    )}
  </DIV>
);

export default displayName(View8);
