import React from 'react';
import { Row, Col } from 'antd';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import Button from '../../components/button';
import { Description } from '../../components/title/index';
import Image from '../../components/image/index';
import BannerImg1 from '../../assets/images/banner/bannerimg.png';
import Shape2 from '../../assets/images/banner/bg-wave.svg';
import Shape3 from '../../assets/images/banner/wave.png';
// import './banner.scss';

function Banner(props) {
  const ChangeClass = 'demo1';

  return (
    <section className={`banner-wrapper ${ChangeClass}`} id="home">
      <DIV>
        <div className="banner-outer">
          <Row>
            <Col md={6}>
              <div className="banner-content">
                <Description
                  Class="banner-dec"
                  Name={ctx.apply('ctf.renderRichText', { name: 'heroDescription' }, 'richText', null)}
                />
                <div className="banner-btn-wrapper">
                  <Button Class="button1 button3 btn" Name="LEARN MORE" />
                  <Button
                    Class="button1 button4 btn"
                    Name="GET STARTED"
                    Title="gradient-color"
                    BtnIcon="btn-icon gradient-color1"
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="banner-image">
                <Image
                  Path={ctx.apply('ctf.findImage', { name: 'heroImage' }, 'image', BannerImg1, { width: 690 })}
                  Class="banner-img rounded-full"
                />
              </div>
            </Col>
          </Row>

          {(props.ChangeClass === 'demo2' || props.ChangeClass === 'demo3') && (
            <div className="bottom-img">
              {props.ChangeClass === 'demo2' ? <Image Path={Shape2} /> : <Image Path={Shape3} />}
            </div>
          )}
        </div>
      </DIV>
    </section>
  );
}

export default Banner;
