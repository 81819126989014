import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import Link from '@uz/unitz-components-web/Link';
import useRoute from '@vl/hooks/useGbRoute';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    color: ${gstyles.colors.main};
  }
`;
const CustomCollapse = styled.div`
  .ant-collapse {
    border: none;
    background-color: ${gstyles.colors.white500};
    .ant-collapse-item {
      border-radius: 8px;
      padding: 0 16px;
      @media only screen and (min-width: 1200px) {
        padding: 0 24px;
      }
      border: 1px solid ${gstyles.colors.border};
      margin-bottom: 16px;
      &:last-child {
        border-radius: 8px;
        border: 1px solid ${gstyles.colors.border};
        margin-bottom: 16px;
      }
      .ant-collapse-header {
        padding: 16px 0;
        @media only screen and (min-width: 1200px) {
          padding: 24px 0;
        }
        .ant-collapse-arrow {
          right: 0px;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          border-radius: 8px;
          padding-left: 0px;
          padding-right: 0;
        }
      }
    }
  }
`;

function AdvisorRegistratorFAQsLayout() {
  const route = useRoute().getPageContext();

  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const className = _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'className') || '';
        const numberItems = _.trim(className.match(/list-\d+/gi), 'list-') || Number.MAX_SAFE_INTEGER;
        const sectionItems = _.take(
          _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []),
          numberItems
        );

        return (
          <div className="app-row bg-white wrapper px-0 min-md:px-4 min-lg:px-12 min-xl:px-32 py-8 min-md:py-12 min-xl:py-16">
            <div className="mb-4 min-md:mb-8 min-lg:mb-14 text-2xl min-md:text-3xl min-lg:text-4xl font-semibold text-main text-center">
              {ctx.get('sectionData.shortText')}
            </div>
            <CustomCollapse>
              <Collapse
                // defaultActiveKey={[0]}
                offsetHeight={0}
                expandIcon={(panelProps) => {
                  if (!panelProps.isActive) {
                    return <span>{gstyles.icons({ name: 'arrow-down', size: 24, fill: gstyles.colors.sub })}</span>;
                  }
                  return <span>{gstyles.icons({ name: 'arrow-up', size: 24, fill: gstyles.colors.sub })}</span>;
                }}
                expandIconPosition="right"
              >
                {_.map(sectionItems, (item, index) => {
                  const listIndex = index + 1;
                  return (
                    <Panel
                      header={
                        <div className="mb-0 text-base text-main font-semibold w-5/6">{`${listIndex}. ${_.get(
                          item,
                          'shortText',
                          ''
                        )}`}</div>
                      }
                      key={index}
                    >
                      <CustomParagraph>
                        {ctx.apply('ctf.renderHTMLText', { name: _.get(item, 'name', '') }, 'longText.longText', {
                          as: 'p',
                        })}
                      </CustomParagraph>
                    </Panel>
                  );
                })}
              </Collapse>
            </CustomCollapse>
            {numberItems < Number.MAX_SAFE_INTEGER && (
              <div className="my-0 min-lg:my-4 py-0 px-4 py-3 text-center text-base font-semibold">
                <Link to={ctx.apply('routeStore.toLocale', '/supplier-faqs')}>
                  {ctx.apply('i18n.t', 'Question.allQuestion')}
                </Link>
                <Link className="ml-6" to={ctx.apply('routeStore.toLocale', '/help-center/overview')}>
                  {ctx.apply('i18n.t', 'HelpCenter.learnMore')}
                </Link>
              </div>
            )}
          </div>
        );
      })}
    </DIV>
  );
}

export default AdvisorRegistratorFAQsLayout;
