import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import LoadingScreen from '@uz/unitz-components-web/LoadingScreen';
import gstyles from '@vl/gstyles';

const sectionRender = (section) => {
  return <div></div>;
};

const Index = () => {
  return (
    <DIV className="pageContainer">
      {_.map(ctx.get('pageData.sections'), (section, index) => {
        const content = (
          <React.Fragment key={index}>{ctx.apply('ctf.renderEntry', { name: section.name })}</React.Fragment>
        );
        if (!content) {
          return sectionRender(section);
        }
        return content;
      })}
      <DIV className="isLoading">
        <div
          style={{
            background: gstyles.colors.white500,
            zIndex: 1000,
            userSelect: 'none',
            height: '100%',
            width: '100%',
            position: 'fixed',
            top: 0,
          }}
        >
          <LoadingScreen />
        </div>
      </DIV>
    </DIV>
  );
};

export default displayName(Index);
