import { bindings } from '@vl/redata';

const bindData = bindings({
  carouselItem: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
});

export default bindData;
