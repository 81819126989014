import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';

const CustomItem = styled.div`
  .ant-row {
    &:nth-child(odd) {
      flex-direction: row;
      .content {
        text-align: left;
      }
    }
    &:nth-child(even) {
      flex-direction: ${(props) => props.layout};
      .content {
        text-align: left;
      }
    }
  }
`;

const LeftRightContentLayout = () => {
  return (
    <DIV className="content">
      {ctx.apply('ctf.renderProps', () => {
        const layoutBoard = {
          offset: 'row-reverse',
          unset: 'row',
        };
        return (
          <CustomItem layout={_.get(layoutBoard, ctx.get('sectionData.className'), '')}>
            {console.log(_.get(layoutBoard, ctx.get('sectionData.className'), ''))}
            {_.map(ctx.get('sectionData.sections'), (item, index) => (
              <React.Fragment key={index}>
                {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
              </React.Fragment>
            ))}
          </CustomItem>
        );
      })}
    </DIV>
  );
};

export default LeftRightContentLayout;
