import { bindings } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
});
export default bindData;
