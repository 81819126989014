import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

function TestimonialWithVideoLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <ResponsiveProps xs={{ style: { flexDirection: 'column-reverse' } }} lg={{ style: { flexDirection: 'row' } }}>
            {(resProps) => (
              <Row gutter={[50, 24]} {...resProps}>
                {_.map(ctx.get('sectionData.sections'), (item, index) => {
                  return (
                    <Col key={index} xs={24} lg={12}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                    </Col>
                  );
                })}
              </Row>
            )}
          </ResponsiveProps>
        );
      })}
    </DIV>
  );
}

export default TestimonialWithVideoLayout;
