import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasura';

class Definition {
  static create(config) {
    return graphme.Definition.create({
      ...config,
      getClient: hasuraClient.getClient
    });
  }
}

export default Definition;
