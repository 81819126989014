import { bindings, hook } from '@vl/redata';
import React from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import { PHONE_NUMBER } from '@vl/mod-utils/validateUtil';
import SendInformationSuccess from './SendInformationSuccess';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'AdvisorRegistratorForm' });
              return section;
            }),

            form: hook((ctx) => {
              // const phoneRegExp = /((09|03|07|08|05)+([0-9]{8})\b)/g;
              const userId = ctx.apply('authModel.getUserId');
              const [isModalVisible, $isModalVisible] = React.useState(true);
              const form = useFormik({
                initialValues: {
                  fullName: '',
                  email: '',
                  phone: '',
                  message: '',
                },

                onSubmit: async (values) => {
                  try {
                    const inputs = {
                      full_name: values.fullName,
                      email: values.email,
                      phone_number: values.phone,
                      message: values.message,
                      page: 'Unitz Biz - About us',
                    };

                    const res = await fbFnsClient.getClient().post('course-submitToolAboutUsSurvey', {
                      ...inputs,
                    });

                    if (res.status === 'success') {
                      ctx.apply('modalModel.show', () => <SendInformationSuccess />);
                      _.debounce(() => ctx.apply('modalModel.hide'), 3000)();
                      form.resetForm();
                    }
                  } catch (error) {
                    console.log({ error });
                  }
                },

                validationSchema: Yup.object().shape({
                  fullName: Yup.string().required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  email: Yup.string()
                    .email()
                    .required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  phone: Yup.string()
                    .matches(PHONE_NUMBER, ctx.apply('i18n.t', 'JobPost.Validation.phone'))
                    .required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  message: Yup.string().required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                }),
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid') && _.get(form, 'dirty'),
                showModal: () => {
                  $isModalVisible(true);
                },
                handleCancel: () => {
                  $isModalVisible(false);
                },
              });

              return form;
            }),
          },
        },
      ],
    ],
    children: {
      content: {
        rules: [
          [
            'display',
            {
              display: hook((ctx) => ctx.get('applyJob.isApply')),
            },
          ],
        ],
      },
      applyForm: {
        rules: [
          [
            'display',
            {
              display: hook((ctx) => !ctx.get('applyJob.isApply')),
            },
          ],
        ],
      },
    },
  },
});

export default bindData;
