import graphme from '@unitz/graphme';

import cfClient from '@vl/mod-clients/cfApp';

class Definition {
  static create(config) {
    return graphme.Definition.create({
      ...config,
      getClient: cfClient.getClient
    });
  }
}

export default Definition;
