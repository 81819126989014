module.exports = {
  welcome: 'Welcome!',
  takeTheTour: 'Take the tour',
  Next: 'Tiếp tục',
  Skip: 'Bỏ qua',
  Cancel: 'Thoát',
  Show: 'Hiển thị',
  Hide: 'Ẩn',
  Partner: {
    title: 'Trở thành đối tác của UNITZ',
    description: 'Kiếm tiền nhanh nhất và hiệu quả nhất theo thời gian của bạn. Thu nhập có thể lên đến hàng trăm triệu đồng! Đăng ký ngay để có những lợi thế cạnh tranh nhất!',
  },
  WordDigit: ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'],
  WordMoney: ['triệu', 'nghìn'],
  WordCurrency: {
    hundred: 'trăm',
    old: 'lẻ',
    ten: 'mười',
    tenPrefix: 'mươi',
    onePostfix: 'mốt',
    fivePostfix: 'lăm',
  },
  Currency: {
    vnd: 'đồng',
  },
  Form: {
    required: 'Thông tin bắt buộc',
    BackBtnTxt: 'Quay lại',
    CancelBtnTxt: 'Hủy',
    SaveBtnTxt: 'Lưu',
    DeleteBtnTxt: 'Xoá',
    EditBtnTxt: 'Chỉnh sửa',
  },
  Weeks: {
    sunday: 'Chủ nhật',
    monday: 'Thứ hai',
    tuesday: 'Thứ ba',
    wednesday: 'Thứ tư',
    thursday: 'Thứ năm',
    friday: 'Thứ sáu',
    saturday: 'Thứ bảy',
  },
  Welcome: {
    welcomeTitle1: 'Chào mừng tới Unitz',
    welcomeDesc1: 'Khám phá ứng dụng, Kết nối với các chuyên gia tư vấn với các lĩnh vực đa dạng',
    welcomeTitle2: 'Quan tâm tới các vấn đề của bạn',
    welcomeDesc2: 'Khám phá ứng dụng, Kết nối với các chuyên gia tư vấn với các lĩnh vực đa dạng',
    welcomeTitle3: 'Hỗ trợ đa dạng, chủ động thời gian',
    welcomeDesc3: 'Khám phá ứng dụng, Kết nối với các chuyên gia tư vấn với các lĩnh vực đa dạng',
    or: 'hoặc',
    exploreNow: 'Khám phá ngay',
    signupLabel: ' Tạo tài khoản mới?',
    choiceArea: 'Hãy chọn một dịch vụ mà bạn quan tâm!',
    choiceAreaDesc: 'Bạn có thể thay đổi lựa chọn này trong phần Cài đặt',
    areaType: {
      education: 'Giáo dục',
      consultant: 'Tư vấn',
    },
    function: 'Chọn dịch vụ bạn mong muốn',
    educationDesc: 'Chọn "Giáo dục" nếu như bạn cần tìm các khóa học online, học trực tuyến trực tiếp cùng các giáo viên',
    consultantDesc: 'Chọn “Tư vấn” nếu như bạn cần lời khuyên từ các chuyên gia dày dặn kinh nghiệm trên nhiều lĩnh vực',
  },
  GDPR: {
    consent: {
      noticeMessageTxt: `By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.`,
      acceptButtonText: 'Accept All Cookies',
      declineButtonText: 'Reject All',
    },
  },
  Setting: {
    Language: {
      yourLanguage: 'Ngôn ngữ của bạn',
      note: 'Khi bạn đăng nhập vào Unitz, đây sẽ là ngôn ngữ bạn thấy. Điều này sẽ không ảnh hưởng đến ngôn ngữ khách hàng thấy trên nền tảng.',
      language: 'Ngôn ngữ',
      language_placeholder: 'Chọn ngôn ngữ',
      save: 'Lưu',
      Notification: {
        success: 'Đổi ngôn ngữ thành công',
        fail: 'Đổi ngôn ngữ thất bại',
      },
    },
    DeactiveAccount: {
      title: 'Vô hiệu hóa tài khoản / Xóa tài khoản',
      description: 'Vô hiệu hóa tạm thời hoặc xóa tài khoản của bạn tại Unitz',
      deleteConfirm: 'Xác nhận xoá tài khoản',
      deactiveConfirm: 'Xác nhận vô hiệu hóa tài khoản',
      deleteNote: 'Tài khoản của bạn sẽ bị xóa và bạn không thể đăng nhập vào nền tảng Unitz.',
      deactiveNote: 'Tài khoản của bạn sẽ bị vô hiệu hóa và bạn không thể đăng nhập vào nền tảng Unitz.',
      Action: {
        activate: 'Kích hoạt tài khoản',
        deactivate: 'Vô hiệu hóa tạm thời',
        delete: 'Xóa tài khoản',
        confirm: 'Xác nhận',
        cancel: 'Hủy',
      },
      Notification: {
        deleteSuccess: 'Tài khoản của bạn đã được xóa',
        activeSuccess: 'Tài khoản của bạn đã được kích hoạt thành công',
        deactiveSuccess: 'Tài khoản của bạn đã được vô hiệu hóa tạm thời',
        error: 'Xảy ra lỗi...',
      },
    },
  },
  Selection: {
    titleSearchBar: 'Tìm người hướng dẫn',
    titleCourseSearchBar: 'Tìm kiếm khóa học',
    call: 'Gọi thoại',
    video: 'Gọi video',
    message: 'Nhắn tin',
    search: 'Tìm kiếm',
    noApply: 'Không áp dụng',
    placeHolder: 'Ví dụ: Tình yêu, Hôn nhân gia đình, ...',
    vnd: 'đ',
    filterDetail: 'Bộ lọc chi tiết',
    vote: 'Bình chọn',
    priceSlashMinute: 'Đơn giá/ phút',
    bestFit: 'Phù hợp nhất',
    result: 'chuyên gia được tìm thấy',
    nextPage: 'Trang kế',
    prevPage: 'Trang trước',
  },
  Login: {
    welcomeTitle1: 'Chào mừng tới với Unitz!',
    loginOptionsTitle1: 'Đăng nhập bằng',
    brandDesc: 'Unitz giúp bạn kết nối và chia sẻ vấn đề của mình đến với các chuyên gia tư vấn bạn tin tưởng.',
    email: 'Email',
    guestName: 'Tên của bạn (Guest mode)',
    password: 'Mật khẩu',
    forgotPassword: 'Quên mật khẩu?',
    saveAccount: 'Lưu tài khoản',
    or: 'HOẶC',
    guestLogin: 'Truy cập',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    signupQuestion: 'Chưa có tài khoản? ',
    signupRedirect: 'Đăng ký ngay!',
    loginLabel: 'Đăng nhập với',
    signupLabel: 'Tạo tài khoản',
    modal: {
      title: 'Đăng nhập để tiếp tục',
    },
    ChoosingAdvisorTitle: 'Chuyên gia tư vấn bạn đã chọn',
    facebookLogin: 'Kết nối bằng Facebook',
    googleLogin: 'Kết nối bằng Google',
    appleLogin: 'Đăng nhập bằng Apple',
    emailLogin: 'Đăng nhập bằng Email',
    method: 'Vui lòng chọn phương thức đăng nhập của bạn',
  },
  Signup: {
    createAccount: 'Tạo tài khoản',
    signupOptionsTitle1: 'Đăng ký bằng',
    loginLabel: 'Đăng nhập với',
    email: 'Email',
    fullname: 'Tên đầy đủ',
    phone: 'Số điện thoại',
    password: 'Mật khẩu',
    passwordConfirm: 'Nhập lại mật khẩu',
    forgotPassword: 'Quên mật khẩu?',
    or: 'Hoặc',
    message: 'Hãy gửi cho chúng tôi những thông điệp từ bạn',
    nextStep: 'Tiếp tục',
    signup: 'Đăng ký',
    loginQuestion: 'Bạn đã có tài khoản? ',
    loginRedirect: 'Đăng nhập ngay',
    loginToRegisterCourse: 'Đăng nhập ngay để đăng ký khóa học',
    verifyEmailTitle: 'Verify your email',
    verifyEmailSuccessStatus: 'Your email has been verified. You can now sign in with your new account',
    verifyEmailSuccessContinue: 'Tiếp tục',
    signupLabel: 'Đăng ký với',
    agreeLabel1: 'Bằng cách click vào đăng ký, bạn đã cam kết mình trên 13 tuổi, đồng ý với ',
    agreeLabel1_1: 'Bằng cách click vào đăng ký, bạn đã cam kết mình ',
    agreeLabel1_2: 'trên 13 tuổi',
    agreeLabel1_3: ', đồng ý với ',
    agreeLabel2: 'và ',
    agreeLabel3: 'của chúng tôi',
    termOfServices: 'Điều khoản sử dụng ',
    privacy: 'Chính sách bảo mật ',
    description: 'Tạo tài khoản ngay để bắt đầu tham gia và kết nối với các chuyên gia tư vấn của chúng tôi',
    descriptionAdvisor: 'Tạo tài khoản ngay để bắt đầu tham gia và kết nối với người dùng của chúng tôi',
    signupWithFb: 'Kết nối bằng Facebook',
    signupWithGoogle: 'Kết nối bằng Google',
    signupWithEmail: 'Đăng ký bằng Email',
    signupWithApple: 'Đăng ký bằng Apple',
    termsAndCondition: 'Điều Khoản Và Điều Kiện',
    signupNotification: 'Đăng ký tài khoản thành công',
    labelTermsContract: 'Bên dưới là hợp đồng hợp tác dành cho chuyên gia tại Unitz. Quý vị vui lòng đọc kỹ hợp đồng và xác nhận bằng cách điền tên của mình bên dưới.',
    labelConfirm: 'Tôi xác nhận đã cung cấp thông tin chính xác và tôi sẽ chịu trách nhiệm với những thông tin đã cung cấp.',
    readConfirm: 'Xác nhận đã đọc và đồng ý với điều khoản hợp đồng',
    requireFill: '* Để tiến hành ký hợp đồng, bạn vui lòng điền đầy đủ thông tin bên B.',
    check_get_noti: 'Tôi đồng ý nhận những thông báo về sản phẩm và các chương trình khuyến mãi của Unitz',
    saveAccount: 'Lưu tài khoản',
  },
  Home: {
    welcomeBack: 'Chào mừng trở lại ',
    recommendedAdvisors: 'Người hướng dẫn nổi bật',
    interestedAdvisors: 'Có thể bạn quan tâm',
    more: 'Xem thêm',
    recommendedForYou: 'Phù hợp với bạn',
    featuredCourses: 'Khóa học nổi bật',
    reactionAdvisor: 'Đã tương tác trước đó',
    research: 'Tìm lại',
    categoryInterested: 'Danh mục yêu thích',
    edit: 'Thiết lập',
    listNoAnswer: 'Bạn đang có %{num} cuộc tư vấn chưa thực hiện',
    callNow: 'Gọi ngay',
    exitApp: 'Nhấn lần nữa để thoát ứng dụng',
    consultant: 'Tư vấn',
    academic: 'Học thuật',
  },
  HomeEducation: {
    recommendedAdvisors: 'Giáo viên nổi bật',
    requestLesson: 'Đăng ký học thử miễn phí',
    accountNotFinish: 'Tài khoản của bạn chưa hoàn tất thiết lập, vui lòng hoàn tất để xác thực.',
    settingAccount: 'Thiết lập ngay',
    listCategory: 'Các danh mục',
    seeAll: 'Xem tất cả',
  },
  Chat: {
    image: 'Hình ảnh',
    status: {
      Online: 'Đang online',
      Offline: 'Đang offline',
    },
    seen: 'Đã xem',
  },
  Admin: {
    Dashboard: {
      teacher: 'Giáo viên',
      advisor: 'Chuyên gia',
      user: 'Người dùng',
      courseOnProgress: 'Khóa học đang diễn ra',
      IncomeTeacherInYear: 'Thu nhập của giáo viên trong tháng',
      IncomeTeacherPopup: 'Phần trăm này thể hiện sự chênh lệnh về thu nhập của giáo viên giữa năm 2021 và 2022',
      IncomeSystemInYear: 'Thu nhập của nền tảng trong năm',
      IncomeSystemPopup: 'Phần trăm này thể hiện sự chênh lệnh về thu nhập của nền tảng giữa năm 2021 và 2022',
      courseInDay: 'Những khoá học trong tháng',
      detail: 'Chi tiết',
      category: 'Lĩnh vực',
      course: 'Khoá học',
      student: 'Học viên',
    },
    Account: {
      listTxt: 'Danh sách nhà tư vấn',
      Status: {
        active: 'Hoạt động',
        inActive: 'Inactive',
        activate: 'Kích hoạt',
        deActivate: 'Vô hiệu hóa',
        deleted: 'Đã xóa',
        notDeletedYet: 'Chưa xóa',
        deactive: 'Vô hiệu hóa',
      },
      Table: {
        displayName: 'Họ và tên',
        email: 'Email',
        createdAt: 'Thời gian tạo',
        contact: 'Liên hệ',
        note: 'Ghi chú',
        phoneNumber: 'SĐT',
        passedDate: 'Passed date',
        startAt: 'Thời gian bắt đầu',
        endAt: 'Thời gian kết thúc',
        status: 'Trạng thái',
        courseName: 'Tên khóa học',
        roomName: 'Tên phòng',
        studentList: 'Học viên',
        joinTime: 'Ngày tham gia',
        urlList: 'Đường dẫn',
        room: 'Phòng học',
        type: 'Loại',
        link: 'Đường dẫn',
        course: 'Khóa học',
        profile: 'Trang cá nhân',
        advisor: 'Giáo viên',
        scoring_int: 'Điểm số',
        contract: 'Hợp đồng',
        referral: 'Giới thiệu',
        topic: 'Chủ đề',
        literacy: 'Trình độ',
        targetAudience: 'Đối tượng',
        numberOfStudents: 'Number of students',
        numberOfStudent: 'Số lượng',
      },
      Action: {
        getUrl: 'Lấy url',
        copy: 'Sao chép',
        openNewTab: 'Mở đường dẫn',
        downloadCSV: 'Tải hồ sơ',
        delete: 'Xóa',
        restore: 'Khôi phục',
      },
    },
    Room: {
      detailRoom: 'Chi tiết phòng học',
    },
    User: {
      Status: {
        active: 'Kích hoạt trạng thái test',
        deactive: 'Bỏ trạng thái test',
      },
    },
    Voucher: {
      Info: {
        adminOnly: 'Trang này chỉ dành cho người quản lý.',
        titleGenerateVoucher: 'Tạo mã code mới',
      },
      Action: {
        createNewVoucher: 'Tạo mã mới',
      },
    },
    Assist: {
      assistor: 'Người phụ trách',
      assist: 'Giúp đỡ họ',
      nonAssist: 'Bỏ giúp đỡ',
    },
    Messages: {
      title: 'Dành cho',
      subject: 'Đối tượng',
      targetType: {
        advisor: 'Chuyên gia',
        user: 'Người dùng',
      },
      Action: {
        edit: 'Sửa',
      },
    },
  },
  Question: {
    questionTitle: 'Các Câu Hỏi Thường Gặp',
    more: 'Xem thêm',
    moreCommonQuestion: 'Xem thêm các câu hỏi thường gặp',
    allQuestion: 'Tất cả câu hỏi',
  },
  Guideline: {
    guidelineTitle: 'Tạo Một Buổi Tư Vấn Dễ Dàng',
  },
  CustomerCarousel: {
    customerTitle: 'Cảm Nhận Của Khách Hàng',
  },
  Handbook: {
    handBookTitle: 'Cẩm Nang UNITZ',
    detail: 'Chi tiết cẩm nang',
  },
  Whyus: {
    whyUsTitle: 'Lý Do Chọn UNITZ',
  },
  Category: {
    categoryTxt: 'Danh mục',
    recommendedAdvisors: 'Tư vấn viên nổi bật',
    interestedAdvisors: 'Có thể bạn quan tâm',
    recommendedTeachers: 'Giáo viên nổi bật',
    more: 'Xem thêm',
    discoverMore: 'Khám phá',
    detail: 'Chi tiết',
    searchRecent: 'Chủ đề tìm kiếm gần đây',
    categoryTitle: 'Tư Vấn Theo Phương Pháp',
    allCategory: 'Tất cả danh mục',
    highlightCategory: 'Danh mục nổi bật',
    moreCategories: 'Danh mục khác',
    suggestionCategoriesTitle: 'XEM CÁC CHUYÊN GIA Ở LĨNH VỰC KHÁC',
    learnMore: 'Tìm hiểu thêm',
  },
  CategoryDetail: {
    categoryDetailTitle: 'Chi tiết danh mục',
    searchTextPlaceholder: 'Tìm kiếm',
    moreAdvisor: 'Xem thêm %{total} tư vấn viên khác',
    expandFilters: 'Bộ lọc',
    collapseFilters: 'Thu gọn bộ lọc',
    recommendedCategories: 'Danh mục nổi bật',
  },
  JobPost: {
    title: 'Tuyển dụng',
    Filter: {
      title: 'Lọc theo:',
      categories: 'Danh mục',
      all: 'Tất cả danh mục',
    },
    Detail: {
      applyThisJob: 'Nộp hồ sơ ứng tuyển',
      apply: 'Ứng tuyển',
      submit: 'Gửi ứng tuyển',
    },
    Form: {
      title: 'Thông tin của bạn',
      fullName: 'Tên đầy đủ',
      email: 'Email',
      phone: 'Số điện thoại',
      cv: 'CV của bạn',
      successfullNotification: 'Ứng tuyển thành công',
      upload: 'Đính kèm tập tin',
      errorNotification: 'Something went wrong. Please try again',
    },
    Validation: {
      required: 'Vui lòng không bỏ trống',
      fileSize: 'Tập tin của bạn lớn hơn 5MB',
      phone: 'Số điện thoại không hợp lệ',
    },
    Action: {
      startNow: 'Bắt đầu ngay',
      contactUs: 'Liên hệ',
    },
  },
  Advisor: {
    highlightAdvisorTitle: 'Chuyên Gia Tư Vấn Nổi Bật',
    highestAdvisorTitle: 'Chuyên Gia Tư Vấn Được Đánh Giá Cao Nhất',
    pricePerMin: '%{currency}/%{unit}',
    priceUnit: '/phút',
    rating: 'ĐÁNH GIÁ',
    joinedSince: 'Tham gia từ %{dateStr}',
    voteCount: 'lượt bình chọn',
    timeJoined: 'có kinh nghiệm từ',
    yearsExperience: 'số năm kinh nghiệm',
    adviseCount: 'lượt tư vấn',
    courseCount: 'số khóa học',
    call: 'Gọi ngay',
    callText: 'Gọi thoại',
    callVideoText: 'Gọi video',
    watchMore: 'Xem thêm',
    fitWithYou: 'Phù hợp với bạn',
    listY_newAdvisors: 'Mới',
    listY_highlightAdvisors: '3 phút đầu miễn phí',
    listY_onlineAdvisors: 'Đang trực tuyến',
    listY_interestedAdvisors: 'Có thể bạn quan tâm',
    online: 'Trực tuyến',
    offline: 'Ngoại tuyến',
    busy: 'Đang bận',
    more: 'Xem thêm',
    less: 'Thu gọn',
    aboutMe: 'Về tôi',
    onCourse: {
      zero: '0 khóa học đang dạy',
      one: '1 khóa học đang dạy',
      other: '%{count} khóa học đang dạy',
    },
    discoverMore: 'Khám phá thêm',
  },
  AdvisorRegister: {
    PlaceHolder: {
      description: 'Mô tả chuyên môn của bạn',
    },
    step: 'Bước',
  },
  AdvisorVideoStart: {
    callInfoTxt: 'Bạn có %{duration} phút liên hệ',
    callPolicyTx: 'Mọi thông tin trao đổi của bạn và nhà tư vấn đều được bảo mật nhằm bảo vệ quyền riêng tư cá nhân. Unitz không được quyền lưu trữ.',
    videoCallTxt: 'Gọi video',
  },
  AdvisorVideoCall: {
    title: 'Cuộc gọi kết thúc',
    callStatus: {
      completedLabel: 'Cuộc gọi kết thúc',
      declinedLabel: 'Cuộc gọi bị từ chối',
      missedLabel: 'Cuộc gọi bị nhỡ',
      earlyEndLabel: 'Cuộc gọi kết thúc sớm',
    },
    callVideo: 'Gói video',
    callVoice: 'Gói thoại',
    userReaction: 'Cảm nhận của bạn',
    rejectCallText: 'Lỗi gián đoạn cuộc gọi?',
    missCallText: 'Cuộc gọi bị nhỡ',
    earlyEndCallText: 'Cuộc gọi bạn kết thúc sớm?',
    reCall: 'Gọi lại',
    ringingStatusTxt: 'Đang kết nối với nhà tư vấn. Xin đợi trong giây lát.',
    incommingStatusTxt: 'Cuộc gọi Video đến',
    initingStatusTxt: 'Đang thiết lập kết nối ...',
    callReportTxt1: 'Bạn vừa kết thúc ',
    callReportTxt2: ' gọi với nhà tư vấn:',
    callReviewLabel: 'Hãy cho chúng tôi biết độ hài lòng của bạn',
    callReviewBadLabel: 'Tệ',
    callReviewGoodLabel: 'Rất tốt',
    callReviewSubmitBtn: 'Gửi',
    reviewTextPlaceholder: 'Viết một chút chia sẻ về trải nghiệm của bạn',
    finishTxt: 'Hoàn thành',
    finishDesc: 'Đánh giá mức hài lòng của bạn về chuyên gia?',
    ratingGood: 'Rất tốt',
    ratingBad: 'Tệ',
    finishNote: 'Đôi dòng cảm nhận về buổi tư vấn ...',
    finishSend: 'Hoàn tất',
    finishReview: 'Review',
    finishBuyMore: 'Mua thêm gói',
    ratingText: 'Trải nghiệm cuộc gọi của bạn như thế nào?',
    exit: 'Bỏ qua',
    noAnswer: 'Không trả lời',
    back: 'Quay về',
    connectionDisconnect: 'Cuộc gọi bị mất kết nối do lỗi mạng',
    reportCall: 'Báo cáo sự cố cho Unitz',
    close: 'Thoát',
    endCall: 'Kết thúc cuộc gọi',
    timeLeft: 'Thời gian cuộc gọi của bạn vẫn còn lại',
    continueCall: 'Bạn vẫn muốn kết thúc cuộc gọi?',
    goBack: 'Quay lại',
    endCallAction: 'Kết thúc',
    outRoom: 'Rời phòng tư vấn',
    outRoomConfirm: 'Bạn vẫn muốn rời phòng?',
  },
  AdvisorChatStart: {
    chatInfoTxt: 'Bạn có %{duration} liên hệ',
    chatPolicyTx: 'Mọi thông tin trao đổi của bạn và nhà tư vấn đều được bảo mật nhằm bảo vệ quyền riêng tư cá nhân. Unitz không được quyền lưu trữ.',
    chatTxt: 'Nhắn tin',
    comingSoon: 'Sắp có',
  },
  AdvisorChat: {
    callReportTxt1: 'Bạn vừa kết thúc ',
    callReportTxt2: ' gọi với nhà tư vấn:',
    callReviewLabel: 'Hãy cho chúng tôi biết độ hài lòng của bạn',
    callReviewBadLabel: 'Tệ',
    callReviewGoodLabel: 'Rất tốt',
    callReviewSubmitBtn: 'Gửi',
    chatTextPlaceholder: 'Nhập tin nhắn...',
  },
  Profile: {
    titleText: 'Tài khoản',
    yourAccount: 'Tài khoản của bạn',
    editProfileLabel: 'Chỉnh sửa thông tin',
    changePasswordLabel: 'Thay đổi mật khẩu',
    nameLabel: 'Tên',
    emailLabel: 'Email',
    phoneLabel: 'Số điện thoại',
    oldPasswordLabel: 'Mật khẩu cũ',
    inputPassword: 'Nhập mật khẩu hiện tại',
    newPasswordLabel: 'Nhập mật khẩu mới',
    confirmPasswordLabel: 'Nhập lại mật khẩu',
    message: 'Tin nhắn',
    balance: 'Số dư',
    paymentHistory: 'Lịch sử thanh toán',
    paymentSetup: 'Cài đặt thanh toán',
    subPaymentSetup: 'Danh sách thẻ ngân hàng đã liên kết của bạn',
    changePassword: 'Thay đổi mật khẩu',
    helpCenter: 'Trợ giúp',
    subHelpCenter: 'Trung tâm câu hỏi giải đáp các vấn đề của bạn',
    review: 'Đánh giá chúng tôi!',
    overview: 'Về Unitz',
    service: 'Dịch vụ',
    price: 'Giá',
    time: 'Thời lượng',
    totalMoney: 'Tổng số tiền',
    connected: 'Đã kết nối',
    updatePass: 'Cập nhật mật khẩu',
    updatePassSuccess: 'Cập nhật mật khẩu thành công',
    updatePassFail: 'Cập nhật mật khẩu thất bại',
    currentPassword: 'Mật khẩu hiện tại không đúng! Vui lòng nhập lại',
    matchPassword: 'Mật khẩu mới không được trùng với mật khẩu hiện tại',
    confirmPass: 'Mật khẩu xác nhận không đúng',
    forgotPassword: 'Quên mật khẩu',
    resetPassword: 'Cài lại mật khẩu',
    getNewPassWord: 'Bạn sẽ nhận được email tạo mới mật khẩu thông qua email bên dưới! Vui lòng kiểm tra trong hộp thư!',
    sendEmail: 'Đã gửi email',
    sendEmailSuccess: 'Email đã được gửi vui lòng kiểm tra!',
    enterEmail: 'Vui lòng nhập vào địa chỉ email của bạn. Bạn sẽ nhận được một liên kết để tạo một mật khẩu mới thông qua email',
    send: 'Gửi',
    transactionHistory: 'Giao dịch của tôi',
    subTransactionHistory: 'Các đơn hàng cuộc gọi bạn đã mua',
    notification: 'Thông báo',
    subNotification: 'Thông tin giao dịch, hoàn tiền, cuộc gọi nhỡ',
    myWallet: 'Ví của tôi',
    subMyWallet: 'Chi tiết ví, giao dịch gần đây, nạp tiền',
    gift: 'Quà tặng',
    subGift: 'Các quà tặng ưu đãi từ Unitz',
    schedule: 'Lịch hẹn của tôi',
    subSchedule: 'Danh sách cuộc gọi đã được đặt lịch trước',
    course: 'Khoá học của tôi',
    subCourse: 'Danh sách khoá học bạn đã đăng ký',
    request: 'Yêu cầu',
    activities: 'Hoạt động',
    subRequest: 'Danh sách những yêu cầu bạn đã gửi đến hệ thống',
  },
  ProfileAdvisor: {
    socialLink: 'Liên kết mạng xã hội',
    social_link: 'Mạng xã hội',
    socialLinkPlaceholder: 'Nhập đường dẫn liên kết mạng xã hội của bạn, ví dụ: https://www.linkedin.com/in/...',
    notification: 'Thông báo',
    review: 'Nhận xét & Đánh giá',
    globalMessage: 'Tin nhắn tổng',
    seeSample: 'Tham khảo video mẫu',
    learnMore: 'Tìm hiểu thêm',
    watchVideo: 'Xem video',
    message: 'Tin nhắn',
    message_vi: 'Tin nhắn (vi)',
    message_en: 'Tin nhắn (en)',
    setting: 'Cài đặt trang cá nhân',
    bonusMoney: 'Tiền thưởng',
    course: 'Khoá học',
    helpAdvisor: 'Trợ giúp',
    videoIntroduce: 'Video giới thiệu',
    infoProfile: 'Thông tin tài khoản',
    avatar: 'Ảnh đại diện',
    fullName: 'Họ & tên',
    phoneNumber: 'Số điện thoại',
    email: 'Email',
    identify: 'CMND/CCCD',
    identifyReleaseDate: 'Ngày cấp (CMND/CCCD)',
    identifyReleasePlace: 'Nơi cấp (CMND/CCCD)',
    permanentAddress: 'Địa chỉ thường trú',
    specialityDesc: 'Mô tả chuyên môn',
    passport: 'Hộ chiếu',
    taxNumber: 'Mã số thuế',
    scoringInt: 'Điểm số',
    editProfile: 'Sửa thông tin tài khoản',
    edit: 'Chỉnh sửa',
    save: 'Lưu',
    personalPage: 'Trang cá nhân',
    intro_text: 'Giới thiệu',
    update: 'Chỉnh sửa',
    example: 'Ví dụ: trên 10 năm kinh nghiệm trong lĩnh vực tư vấn phong thủy.',
    success: 'Chỉnh sửa thông tin thành công ',
    fail: 'Chỉnh sửa thông tin thất bại',
    detail_text: 'Mô tả chi tiết',
    category: 'Danh mục giảng dạy',
    degree: 'Bằng cấp',
    enterEmail: 'Vui lòng nhập vào địa chỉ email của bạn. Bạn sẽ nhận được một liên kết để tạo một mật khẩu mới thông qua email',
    enterNewPassword: 'Vui lòng cập nhật mật khẩu mới của bạn.',
    newPassword: 'mật khẩu mới',
    newPasswordConfirm: 'xác nhận mật khẩu mới',
    send: 'Gửi',
    invalidEmail: 'Email chưa đúng',
    required: 'bắt buộc',
    passwordMustMatch: 'mật khẩu chưa trùng khớp',
    yinYang: 'Phong thủy',
    heart: 'Tình yêu - hôn nhân',
    coin: 'Tiền tài - sự nghiệp',
    family: 'Gia đình - con cái',
    casino: 'Điền sản',
    photo: 'Hình ảnh',
    video: 'Video',
    usingSteps: 'Các bước sử dụng',
    commentAndReview: 'Nhận xét & đánh giá',
    consultingSteps: 'Các bước tạo buổi tư vấn',
    consultingStyle: 'Phong cách tư vấn',
    workingTime: 'Giờ làm việc',
    callPackages: 'Gói cuộc gọi',
    contact: 'Liên hệ với chuyên gia',
    reviews: 'lượt đánh giá',
    more: 'Xem thêm',
    collapse: 'Thu gọn',
    callText: 'Gọi thoại',
    callVideoText: 'Gọi video',
    smsText: 'Nhắn tin',
    nextPage: 'Trang kế',
    prevPage: 'Trang trước',
    page: 'Trang',
    styleSlider: {
      title: 'Cách tư vấn',
      friendly: 'Thân thiện',
      polite: 'Lịch sự',
    },
    speakSlider: {
      title: 'Tốc độ nói chuyện',
      slow: 'Chậm',
      fast: 'Nhanh',
    },
    teachSlider: {
      title: 'Cách truyền đạt kết quả',
      flexible: 'Uyển chuyển',
      direct: 'Trực tiếp',
    },
    tabs: {
      profileTabLabel: 'Về tôi',
      courseTabLabel: 'Danh sách khoá học',
      reviewTabLabel: 'Nhận xét & Đánh giá',
    },
    editAvatar: 'Cập nhật ảnh đại diện',
    editPhoto: 'Cập nhật hình ảnh',
    choiceImage: 'Chọn hình ảnh',
    editAvatarSuccess: 'Cập nhật ảnh đại diện thành công',
    editAvatarFail: 'Cập nhật ảnh đại diện thất bại',
    editPhotoSuccess: 'Thêm hình ảnh thành công',
    editPhotoFail: 'Thêm hình ảnh thất bại',
    acceptPermission: 'Bạn vui lòng cấp quyền camera và ghi âm để sử dụng ứng dụng của chúng tôi!',
    goProfile: 'Vào trang cá nhân',
    education: 'Học vấn',
    certificates: 'Bằng cấp',
    experience: 'Kinh nghiệm',
    schedule: 'Lịch hẹn tư vấn',
    contractNote: 'Chúng tôi đã cập nhật nội dung hợp đồng hợp tác của quý vị, xin vui lòng nhấn vào nút Ký hợp đồng để tiếp tục sử dụng hệ thống.',
    contract: 'Ký hợp đồng',
    contractMessage: 'Thành Công',
    contractDescription: 'Bạn vừa gửi thông tin hợp đồng thành công.',
    takeSurvey: 'Tham gia khảo sát',
    activities: 'Activities',
  },
  FirebaseMessage: {
    'auth/user-not-found': {
      title: 'Lỗi email',
      message: 'Không có người dùng nào tương ứng với email.',
    },
    'auth/user-disabled': {
      title: 'Lỗi tài khoản',
      message: 'Tài khoản tương ứng với email này đã bị vô hiệu hóa.',
    },
    'auth/invalid-email': {
      title: 'Lỗi email',
      message: 'Địa chỉ email không hợp lệ.',
    },
    'auth/invalid-action-code': {
      title: 'Lỗi đường dẫn',
      message: 'Đường dẫn không hợp lệ. Vui lòng thử lại hoặc liện hệ với Unitz để được hổ trợ.',
    },
    'auth/email-already-in-use': {
      title: 'Lỗi email',
      message: 'Email này đã được sử dụng cho một tài khoản khác.',
    },
    'auth/wrong-password': {
      title: 'Lỗi mật khẩu',
      message: 'Mật khẩu của bạn không đúng.',
    },
  },
  Search: {
    searchResult: 'Kết quả tìm kiếm cho ',
    searchResultTotalHead: 'Khoảng ',
    searchResultTotal: '%{total} kết quả ',
    searchResultTotalTail: 'cho từ khóa được tìm kiếm.',
    suggestedAdvisors: 'Gợi ý cho bạn',
    screenTitleTxt: 'Tìm kiếm',
    searchAdvanced: 'Tìm kiếm nâng cao',
    delete: 'Xóa',
    confirm: 'Áp dụng',
    searchCategory: 'Tìm kiếm trong',
    searchSkill: 'Kỹ năng',
    category: 'Danh mục',
    skill: 'Lựa chọn kỹ năng',
    review: 'Đánh giá',
    star_5: '%{star} sao',
    star_4: 'Từ %{star} sao trở lên',
    result: '%{result} kết quả phù hợp',
    allCategory: 'Tất cả danh mục',
  },
  tts: {
    videoCall: {
      timeoutAlert: 'Your call will end after %{duration} minutes.',
    },
  },
  currency: {
    thousand: 'K',
    million: 'Tr',
    billion: 'Tỷ',
  },
  time: {
    day: 'ngày',
    hour: 'giờ',
    minute: 'phút',
    second: 'giây',
    month: 'tháng',
    year: 'năm',
    currentMonth: 'Tháng này',
    minutes: 'phút',
  },
  AdvisorProfileMenu: {
    Dashboard: 'Trình thiết lập',
    Home: 'Trang chủ',
    Overview: 'Tổng quan',
    Message: 'Tin nhắn',
    MyCourse: 'Khoá học của tôi',
    TeachingSchedule: 'Lịch dạy',
    Notification: 'Thông báo',
    CourseManagement: 'Quản lý khoá học',
    ProfileManagement: 'Quản lý hồ sơ',
    SettingWorkingTime: 'Giờ làm việc',
    MyIncome: 'Thu nhập của tôi',
    CallHistory: 'Lịch sử hoạt động',
    Account: 'Tài khoản',
    Setting: 'Cài đặt',
    Request: 'Yêu cầu',
  },
  AdvisorProfileGeneral: {
    Courses: 'Các khoá học',
    CourseLower: 'khoá',
    CoursesLower: 'khoá',
    NoCourseAddMore: 'Bạn chưa có khoá học. Vui lòng tạo thêm!',
    NotPublished: 'Chưa công bố',
    GoingOn: 'Đang diễn ra',
    ComingSoon: 'Sắp diễn ra',
    AddCourse: 'Thêm khoá học',
    Detail: 'Chi tiết',
    TodaySchedule: 'Lịch hôm nay',
    NoStudentYet: 'Chưa có học viên',
    Finished: 'Hoàn tất',
    JoinNowClass: 'Vào lớp',
    OnlineAdvise: 'Tư vấn trực tuyến',
    NoAnyConsultationSession: 'Bạn chưa có buổi tư vấn nào',
  },
  AdvisorProfile: {
    Overview: 'Thông tin chung',
    Skill: 'Kỹ năng',
    Rating: 'Đánh giá',
    Title: 'Hồ sơ chuyên gia',
    aboutMe: 'Về tôi',
    photos: 'Hình ảnh',
    category: 'Danh mục tư vấn',
    advisorStyle: 'Phong cách tư vấn',
    howToSpeak: {
      name: 'Cách tư vấn?',
      min: 'Thân thiện',
      max: 'Lịch sự',
    },
    speakingSpeed: {
      name: 'Tốc độ nói chuyện?',
      min: 'Chậm',
      max: 'Nhanh',
    },
    howToConveyTheResult: {
      name: 'Cách truyền đạt kết quả',
      min: 'Uyển chuyển',
      max: 'Trực tiếp',
    },
    timeWork: 'Giờ làm việc',
    more: 'Xem thêm',
    collapse: 'Thu gọn',
    noName: 'Ẩn danh',
    callVoice: 'Gọi thoại',
    callVideo: 'Gọi video',
    callBusy: 'Bận',
    addImage: 'Thêm ảnh',
    SetSchedule: 'Đặt lịch hẹn',
    Timezone: 'Timezone',
    restartMessageTimezone: 'Ứng dụng sẽ tự động khởi động lại để cập nhật Timezone.',
    status: {
      available: 'Sẵn sàng',
      takingAppointment: 'Đang bận',
      notAvailable: 'Không sẵn sàng',
    },
  },
  Biz: {
    Form: {
      organization_name: 'Tên tổ chức',
      country_label: 'Quốc gia',
      country_placeholder: 'Vui lòng chọn',
      register: 'Nhận Demo ngay bây giờ',
      success_notification: 'Thành công',
      success_description: 'Chúng tôi đã nhận được yêu cầu Demo của bạn. Một thành viên trong nhóm hỗ trợ khách hàng sẽ liên hệ với bạn trong thời gian ngắn.',
      title: 'Chức danh',
      email: 'Email',
      last_name: 'Họ',
      first_name: 'Tên',
      phone_label: 'Số điện thoại',
      phone_placeholder: '+1 234 567 8910',
      message: 'Tin nhắn của bạn',
      message_placeholder: 'Bất cứ điều gì bạn muốn cho chúng tôi biết',
      check_text: 'Bằng cách đăng ký, bạn đồng ý với các điều khoản và chính sách bảo mật của chúng tôi. Bạn đồng ý rằng chúng tôi có thể liên hệ với bạn về Unitz và sử dụng dữ liệu từ các bên thứ ba để cá nhân hóa trải nghiệm của bạn.',
      errorNotification: 'Gửi không thành công. Vui lòng kiểm tra lại thông tin',
    },
    Blog: {
      all_articles: 'Tất cả bài viết',
      author: 'Tác giả',
      date: 'Ngày đăng',
      read_article: 'Đọc bài viết',
      other_articles: 'Bài viết khác',
      no_blog: 'Trang bạn yêu cầu không tồn tại',
      page: 'Trang',
      of: 'của',
      next: 'Trang kế',
      prev: 'Trang trước',
      goTo: 'Đến trang',
    },
    read_more: 'Đọc thêm',
  },
  Course: {
    aboutCourse: 'Về khóa học này',
    about: 'Thông tin',
    createCourseTitle: 'Tạo khóa học mới',
    courseLabel: 'Khóa học',
    detail_course: 'Chi tiết khóa học',
    schedule: 'Lịch học',
    course: '%{total} khóa học',
    classNotes: 'Ghi chú buổi học',
    teaching_schedule: 'Lịch dạy',
    coming_room: 'Ngày khai giảng',
    course_of_lecture: 'Khóa học khác',
    files: 'Tập tin',
    supports: 'Hỗ trợ',
    reviews: 'Đánh giá',
    per_session: 'buổi',
    per_course: 'khoá',
    openingDay: 'Khai giảng %{startAt}',
    enrollNow: 'Đăng ký ngay',
    edit_course: 'Chỉnh sửa khóa học',
    viewCourse: 'Xem khóa học',
    myCourse: 'Khóa học của tôi',
    subMyCourse: 'Danh sách khóa học mà bạn đã tham gia',
    paidAlready: 'Đã thanh toán',
    general: 'Tổng quan',
    overview: 'Thông tin',
    activity: 'Hoạt động',
    shareTitle: 'Chia sẻ khóa học',
    share: 'Chia sẻ',
    allowTrialAccess: 'Cho phép quyền học thử khóa học',
    courseBenefits: 'Lợi ích học online qua Unitz',
    benefits: {
      benefit1: 'Lớp học trực tuyến, trực tiếp với giáo viên',
      benefit2: 'Lớp học 1-1 hoặc học theo nhóm',
      benefit3: 'Học bằng máy tính hoặc tablet qua trình duyệt',
      benefit4: 'Không cần tải phần mềm',
      benefit5: 'Chi phí thấp',
      benefit6: 'Hiệu quả tối ưu',
      benefit7: 'Tiết kiệm thời gian đi lại, tránh kẹt xe',
      benefit8: 'Ôn lại bài học, xem lại tài liệu khi kết thúc buổi học',
      benefit9: 'Chủ động thời gian mọi lúc mọi nơi',
      benefit10: 'Flexible Zeiteinteileung - egal wo, egal wann.',
    },
    demo: {
      messageInfo: 'Bạn có thể đăng ký học thử 1 buổi học miễn phí trước khi quyết định mua khoá học. Hãy chọn thời gian học thử bên dưới và đăng ký. Giáo viên sẽ xem xét và phê duyệt buổi học thử nghiệm.',
      buttonTitle: 'Đăng ký học thử',
      buttonTitleShort: 'Học thử',
      title: 'Đăng ký học thử',
      messageHint: '(Bạn có thể đăng ký 1 buổi học miễn phí)',
      messageNoTrial: '(Lớp học chưa cho phép học thử)',
      messageRequested: '(Bạn đã đăng ký 1 buổi học miễn phí)',
      modal: {
        title: 'Đăng ký học thử',
        okButton: 'Đăng ký',
        cancelButton: 'Đóng',
        doneButton: 'Hoàn tất',
        sessionDurationLabel: 'Thời lượng',
        startDateLabel: 'Chọn ngày học thử',
        startTimeLabel: 'Buổi học',
        startAtLabel: 'Thời gian',
        results: {
          success: {
            title: 'Đăng ký thành công!',
            subTitle: 'Vui lòng chờ giáo viên xác nhận và kiểm tra lịch học thử trong thời khoá biểu của bạn.',
          },
          error: {
            title: 'Đăng ký thất bại!',
            subTitle: 'Vui lòng kiểm tra thông tin đăng ký bên dưới và đăng ký lại sau.',
          },
        },
      },
      modalConfirm: {
        title: 'Phê duyệt yêu cầu học thử',
        messageInfo: 'Học viên có thể gửi yêu cầu học thử 1 buổi học một lần duy nhất. Học viên chỉ có thể vào lớp học thử khi giáo viên đồng ý yêu cầu học thử.',
        okButton: 'Đồng ý',
        cancelButton: 'Đóng',
        doneButton: 'Hoàn tất',
        confirmButton: 'Đồng ý',
        rejectButton: 'Từ chối',
        sessionDurationLabel: 'Thời lượng',
        startDateLabel: 'Chọn ngày học thử',
        startTimeLabel: 'Chọn giờ học',
        studentLabel: 'Học viên',
        notesLabel: 'Ghi chú',
        results: {
          success: {
            title: 'Đăng ký thành công!',
            subTitle: 'Vui lòng chờ giáo viên xác nhận và kiểm tra lịch học thử trong thời khoá biểu của bạn.',
          },
          error: {
            title: 'Đăng ký thất bại!',
            subTitle: 'Vui lòng kiểm tra thông tin đăng ký bên dưới và đăng ký lại sau.',
          },
        },
      },
    },
    shareContent: 'Cùng học với mình nha!',
    course_count: {
      zero: '0 khoá học',
      one: '1 khoá học',
      other: '%{count} khoá học',
    },
    course_is_teaching: {
      zero: '0 khóa học đang dạy',
      one: '1 khóa học đang dạy',
      other: '%{count} khóa học đang dạy',
    },
    contact: 'Tham gia khoá học',
    emptyCourse: 'Chưa có khóa học nào',
    seeAll: 'Xem tất cả',
    rooms: 'Phòng học',
    Info: {
      helperMessage:
        'Mức học phí sẽ thu của học viên, lưu ý cộng thêm phí nền tảng 20% vào phần phí học viên sẽ trả cho buổi học. Ví dụ, giá quý vị muốn thu cho 1 giờ dạy là 200k, thì khi thêm 20% vào, mức giá liệt kê ra bên ngoài hệ thống cho học viên xem sẽ là 240k, như vậy, quý vị nhập vào đây con số 240.000 vnd để có thể có được thu nhập mong muốn là 200k cho 1 giờ dạy. Lưu ý, đây là mức thu cho 1 học viên, nếu lớp học có nhiều học viên thì sẽ nhân lên tương ứng, chẳng hạn, 3 học viên x 200k = 600k cho 1 giờ dạy mà quý vị có thể nhận được.',
      new_course: 'Khoá học mới',
      step_title: 'Thông tin khóa học',
      scoring_int_input_label: 'Điểm xếp hạng',
      scoring_int_input_placeholder: 'Ví dụ: 100',
      name_input_label: 'Tên khoá học',
      name_input_placeholder: 'Thêm tên khóa học',
      description_input_label: 'Giới thiệu khoá học',
      description_input_defaultValue: '<p>Giới thiệu khóa học:&nbsp;</p><p>Đối tượng:</p><p>Nội dung cụ thể:</p><p>Lợi ích khi tham gia khóa học:</p><p>Giáo trình giảng dạy:</p><p>Lịch trình giảng dạy cụ thể:</p><p>Cam kết sau khi kết thúc khoá học:</p>',
      description_input_placeholder: 'Đôi dòng giới thiệu về khóa học',
      photo_url_input_label: 'Ảnh đại diện khóa học',
      video_url_input_label: 'Video giới thiệu khóa học',
      video_url_input_placeholder: 'Video giới thiệu khóa học',
      price_amount_per_session: 'Học phí/buổi',
      price_amount_per_course: 'Học phí/khóa',
      no_photo: 'Chưa có ảnh đại diện',
      no_video: 'Chưa có video giới thiệu',
      session_duration_input_label: 'Thời lượng/ buổi',
      session_duration_input_placeholder: 'Chọn thời lượng',
      start_at_input_label: 'Ngày bắt đầu',
      pricing_type_input_label: 'Hình thức học phí',
      object_teaching_input_label: 'Đối tượng giảng dạy',
      age_input_label: 'Độ tuổi ',
      children_age_input_label: '(Nhập tối thiểu 3 tuổi và tối đa 18 tuổi)',
      to_age_label: 'đến',
      years_old_label: 'tuổi',
      minimum_sessions_per_time: 'Tối thiểu %{number} buổi/ lần',
      frequency_of_tuition: {
        zero: 'Học phí thu 0 buổi/ lần',
        one: 'Học phí thu 1 buổi/ lần',
        other: 'Học phí thu %{count} buổi/ lần',
      },
      tuition_by_course: 'Học phí thu 1 lần cho toàn bộ khóa học',
      minute_per_session: '%{minute} phút / buổi',
      benefit_input_label: 'Lợi ích từ khóa học',
      benefit_input_hint: '(Nhập tối thiểu 2 lợi ích mà học viên có được khi tham gia lớp học với thầy cô.)',
      benefit_input_placeholder: 'VD: Giao tiếp, đúng được câu cơ bản trong hội thoại hằng ngày',
      category_input_label: 'Chọn chủ đề giảng dạy',
      category_input_placeholder: 'Chọn chủ đề liên quan tới khoá học',
      minute: {
        zero: '0 phút',
        one: '1 phút',
        other: '%{count} phút',
      },
      student: {
        zero: '0 học viên',
        one: '1 học viên',
        other: '%{count} học viên',
      },
      session: {
        zero: '0 buổi',
        one: '1 buổi',
        other: '%{count} buổi',
      },
      object_teaching_for_adult: 'Người lớn',
      object_teaching_for_children: 'Trẻ em',
      session_type: 'Theo buổi',
      fullCourse_type: 'Theo gói',
      session_occurence_input_label: 'Số buổi',
      unlimitedSession: 'Khóa học không giới hạn số buổi',
      unlimited: 'Không giới hạn',
      price_amount_input_label: 'Học phí',
      price_amount_input_label_by_types: {
        per_course: 'Học phí (trọn khoá)',
        per_session: 'Học phí (theo buổi)',
      },
      price_amount_input_placeholder: 'Nhập số tiền',
      enroll_min_input_label: 'Số học viên tối thiểu',
      enroll_min_input_placeholder: 'Chọn',
      enroll_max_input_label: 'Số học viên tối đa',
      enroll_max_input_placeholder: 'Chọn',
      attachments_input_label: 'Tập tin',
      startAtTxt: 'Khai giảng ngày',
      Validation: {
        required: 'Thông tin bắt buộc',
        sessionMin: 'Tối thiểu 1 giờ học cho mỗi khoá học',
        conflictSession: 'Giờ học #%{index} bị trùng',
        session_occurence_min: 'Không đủ số giờ học %{session_count}/%{session_occurence}',
        session_occurence_max: 'Số giờ học vượt quá %{session_count}/%{session_occurence}',
        conflictsessionCourse: 'Conflicting time with another course ',
        moreThanMoney: 'Học phí phải từ %{val} trở lên',
        lessThanMoney: 'Học phí không được quá %{val}',
        limit_benefit_content_character: 'Tối đa 200 ký tự',
        min_benefit_entered: 'Hãy nhập ít nhất 2 lợi ích',
        category_input_min: 'Please select category',
        courseLinkMin: 'Tối thiểu 1 đường dẫn khóa học',
        conflictSessionCourse: 'Trùng giờ với khoá học',
        max_age: 'Tuổi tối đa chưa đúng',
        min_age: 'Tuổi tối thiểu chưa đúng',
      },
      Action: {
        add_photo: 'Thêm ảnh',
        select_files: 'Chọn tập tin',
        drop_files_here: 'Đính kèm tập tin ở đây',
        add_benefit: 'Thêm lợi ích',
      },
      age_note_input_label: '(Nếu có nhiều học viên, ưu tiên số tuổi lớn nhất)',
      pending_message: 'Bạn đang có khoá học chờ duyệt, chúng tôi đã nhận được khoá học của bạn trên hệ thống và sẽ xét duyệt sớm nhất trong 3 ngày làm việc. Chúng tôi sẽ gửi thông báo đến cho bạn qua hệ thống thông báo trong web và trong app cũng như qua email: supplier@unitz.app, hãy lưu địa chỉ email này vào Các địa chỉ tin cậy để luôn nhận được email, hoặc hãy kiểm tra hòm thư Spam.',
      category_input_min: 'Vui lòng chọn danh mục cho khóa học',
    },
    DurationSetting: {
      step_title: 'Thiết lập giờ học',
      detail_study_time: 'Chi tiết giờ học',
      time_interval: 'Giờ học áp dụng',
      number: 'STT',
      every_week: 'Mỗi tuần vào',
      hour_frame: 'Giờ học',
      repeat: 'Lặp lại',
      repeat_pattern_input_placeholder: 'Chọn ngày lặp lại',
      Action: {
        add_hour_frame: 'Thêm giờ học',
        add_course_link: 'Thêm đường dẫn khóa học',
      },
      helperMessage: 'Giờ học áp dụng là thời gian bắt đầu - kết thúc của mỗi buổi học trong khoá học này. Được lặp lại định kỳ hàng tuần với số ngày nhẩt định theo thiết lập của bạn. Bạn nên đảm bảo mỗi giờ học là duy nhất để tránh bị trùng lặp giờ với các khoá học khác. Nếu bạn muốn tạo thêm một bộ giờ khác, bạn hãy tạo khoá học mới với khung giờ bạn mong muốn.',
      start_at: 'Từ ngày',
    },
    Payment: {
      step_title: 'Thanh toán',
      type_of_product: 'Loại sản phẩm',
      course: 'Khóa học',
      session_per_purchase_input_label: 'Hình thức thu',
      session_per_purchase_input_placeholder: 'Chọn hình thức thu',
      should_publish_input_label: 'Chúng tôi sẽ tự động hoàn tiền nếu học viên không hài lòng sau buổi học thử trên',
      should_publish_input_placeholder: 'Công bố khóa học',
      full_package: 'Trọn gói',
      every_session: 'Từng buổi',
      session: {
        zero: '0 buổi',
        one: '1 buổi',
        other: '%{count} buổi',
      },
      paidSessionsPerTotalLabel: {
        zero: 'Đã mua %{count}/%{session_occurence} buổi',
        one: 'Đã mua %{count}/%{session_occurence} buổi',
        other: 'Đã mua %{count}/%{session_occurence} buổi',
      },
      paidSessionsRemainLabel: {
        zero: 'Mua thêm buổi học',
        one: 'Còn %{count} buổi',
        other: 'Còn %{count} buổi',
      },
      paymentRoomListLabel: 'Thanh toán cho các buổi học',
      paidLabel: 'Đã thanh toán',
      unPaidLabel: 'Chưa thanh toán',
    },
    Action: {
      add_course: 'Thêm khoá',
      clone_course: 'Nhân bản khóa học',
      save: 'Lưu',
      cancel: 'Hủy',
      next_step: 'Tiếp theo',
      prev_step: 'Quay lại',
      preview: 'Xem trước',
      copy_url: 'Sao chép URL',
    },
    Status: {
      draft: {
        title: 'Nháp',
        action: 'Nháp',
      },
      published: {
        title: 'Đã công bố',
        action: 'Công bố',
      },
      requested: {
        title: 'Học thử',
        label: 'Yêu cầu học thử',
        action: 'Đồng ý học thử',
      },
      confirmed: {
        title: 'Đồng ý',
        action: 'Đồng ý học thử',
      },
      rejected: {
        title: 'Từ chối',
        action: 'Từ chối học thử',
      },
      completed: {
        title: 'Đã hoàn tất',
        action: 'Hoàn tất',
      },
      refund: {
        title: 'Đã hoàn tiền',
        action: 'Hoàn tiền',
      },
      canceled: {
        title: 'Đã huỷ',
        action: 'Huỷ',
      },
      closed: {
        title: 'Đã khoá',
        action: 'Khoá',
      },
      onprogress: {
        title: 'Đang diễn ra',
        action: 'Đang diễn ra',
      },
      viewAsUser: {
        title: 'Xem như người dùng',
        action: 'Xem như người dùng',
      },
      clone: {
        title: 'Nhân bản khóa học',
        action: 'Nhân bản khóa học',
      },
      pending_approval: {
        title: 'Chờ duyệt',
        action: 'Duyệt',
      },
    },
    RAT: {
      buttonSeeScheduleTitle: 'Xem lịch học',
      messageInfo: 'Nếu giờ học của khoá học không phù hợp với bạn, bạn có thể gửi yêu cầu thay đổi giờ học. Giáo viên sẽ xem xét yêu cầu và phê duyệt.',
      messageButton: 'Nếu không có giờ học phù hợp bạn. Bạn có thể gửi yêu cầu đến giáo viên giờ học khác',
      buttonTitle: 'Yêu cầu khung giờ khác',
      buttonTitleShort: 'Khung giờ khác',
      title: 'Yêu cầu khung giờ khác',
      messageHint: '(Bạn có thể yêu cầu khung giờ khác)',
      messageNoTrial: '(Lớp học chưa cho phép yêu cầu khung giờ khác)',
      messageRequested: '(Bạn đã yêu cầu khung giờ học khác)',
      requestMessage: 'Yêu cầu một giờ học khác Khóa học {{courseLink}} của giảng viên {{advisorLink}} đã được gửi.',
      confirmMessage: 'Bạn vừa chấp nhận Yêu cầu một khóa học từ %{user}',
      rejectMessage: 'Bạn vừa từ chối Yêu cầu một giờ học khác từ %{user}',
      pendingMessage: 'Yêu cầu của bạn đang chờ xét duyệt từ giảng viên',
      go_RATCourse: 'Xem khoá học',
      DurationSetting: {
        step_title: 'Thiết lập giờ học',
        detail_study_time: 'Chi tiết giờ học',
        time_interval: 'Giờ học áp dụng',
        number: 'STT',
        every_week: 'Mỗi tuần vào',
        hour_frame: 'Giờ học',
        repeat: 'Lặp lại',
        repeat_pattern_input_placeholder: 'Chọn ngày lặp lại',
        Action: {
          add_hour_frame: 'Thêm giờ học',
        },
        helperMessage: 'Giờ học áp dụng là thời gian bắt đầu - kết thúc của mỗi buổi học trong khoá học này. Được lặp lại định kỳ hàng tuần với số ngày nhẩt định theo thiết lập của bạn. Bạn nên đảm bảo mỗi giờ học là duy nhất để tránh bị trùng lặp giờ với các khoá học khác.',
      },
      modal: {
        title: 'Yêu cầu khung giờ học khác',
        okButton: 'Gửi yêu cầu',
        cancelButton: 'Đóng',
        doneButton: 'Hoàn tất',
        sessionDurationLabel: 'Thời lượng',
        numberOfStudentsLabel: 'Số người đăng ký',
        startDateLabel: 'Chọn ngày học thử',
        selectDateMainLabel: 'Chọn ngày, giờ học mà bạn mong muốn',
        selectDateLabel: 'Chọn ngày giờ',
        startTimeLabel: 'Buổi học',
        startAtLabel: 'Thời gian',
        notesLabel: 'Ghi chú',
        notesPlaceholder: 'Ghi chú của bạn',
        requested_note1: 'Bạn nhân được 01 yêu cầu từ người dùng',
        requested_note2: 'Hãy phản hồi sớm nhất có thể nhé!',
        requested_note3: 'Nếu bạn đồng ý với yêu cầu trên, hệ thống sẽ tự động tạo khóa học với nội dung tương tự',
        results: {
          success: {
            title: 'Yêu cầu thành công!',
            subTitle: 'Vui lòng chờ giáo viên xác nhận và kiểm tra xác nhận gửi vào hộp thư của bạn.',
          },
          error: {
            title: 'Yêu cầu thất bại!',
            subTitle: 'Vui lòng kiểm tra thông tin đăng ký bên dưới và đăng ký lại sau.',
          },
        },
      },
      registration: {
        label1: 'Yêu cầu của bạn đã được gửi đi thành công',
        label2: 'Bạn có thể theo dõi yêu cầu tại mục Yêu cầu trong tài khoản',
        info: 'Thông tin yêu cầu',
        seeRequire: 'Xem yêu cầu ngay',
      },
      modalConfirm: {
        title: 'Phê duyệt yêu cầu học thử',
        messageInfo: 'Học viên có thể gửi yêu cầu học thử 1 buổi học một lần duy nhất. Học viên chỉ có thể vào lớp học thử khi giáo viên đồng ý yêu cầu học thử.',
        okButton: 'Đồng ý',
        cancelButton: 'Đóng',
        doneButton: 'Hoàn tất',
        confirmButton: 'Đồng ý',
        rejectButton: 'Từ chối',
        sessionDurationLabel: 'Thời lượng',
        startDateLabel: 'Chọn ngày học thử',
        startTimeLabel: 'Chọn giờ học',
        studentLabel: 'Học viên',
        notesLabel: 'Ghi chú',
        results: {
          success: {
            title: 'Đăng ký thành công!',
            subTitle: 'Vui lòng chờ giáo viên xác nhận và kiểm tra lịch học thử trong thời khoá biểu của bạn.',
          },
          error: {
            title: 'Đăng ký thất bại!',
            subTitle: 'Vui lòng kiểm tra thông tin đăng ký bên dưới và đăng ký lại sau.',
          },
        },
      },
    },
    RCM: {
      confirmed: 'Xác nhận yêu cầu khóa học',
      rejected: 'Từ chối yêu cầu khóa học',
      responsor: 'Người phản hồi',
      buttonResponseTitle: 'Phản hồi',
      buttonRejectTitle: 'Từ chối',
      buttonTitle: 'Yêu cầu khóa học phù hợp',
      buttonTitleShort: 'Yêu cầu khóa học',
      requestMessage: 'Yêu cầu một khóa học của bạn đã được gửi.',
      confirmMessage: 'Bạn vừa chấp nhận Yêu cầu một khóa học khác từ %{user}',
      rejectMessage: 'Bạn vừa từ chối yêu cầu khóa học khác từ %{user}',
      rejectedMessage: 'Bạn vừa bị từ chối yêu cầu khóa học khác từ Unitz',
      pendingMessage: 'Yêu cầu của bạn đang chờ phản hồi từ Unitz',
      noteLabel: 'Lời nhắn',
      linkCourse: 'Khóa học phù hợp',
      messageInfo: 'Chúng tôi sẽ tìm khóa học phù hợp hoặc đề xuất giáo viên tạo khóa học nếu được chấp thuận. Vậy nên, hãy cho chúng tôi biết chi tiết về yêu cầu của bạn.',
      Status: {
        requested: 'yêu cầu',
        rejected: 'từ chối',
        confirmed: 'xác nhận',
      },
      modal: {
        title: 'Yêu cầu một khóa học',
        topicLabel: 'Điền chủ đề mà bạn muốn học',
        literacyLabel: 'Trình độ',
        teacherLabel: 'Giảng viên (Nếu bạn đã tìm hiểu về giảng viên trước đó)',
        people: 'Người',
        courseLink: 'Đường dẫn khóa học',
        responseButton: 'Gửi',
        rejectButton: 'Từ chối',
        literacies: {
          beginner: 'Mới bắt đầu',
          elementary: 'Sơ cấp',
          intermediate: 'Trung cấp',
          advanced: 'Nâng cao',
        },
        results: {
          success: {
            title: 'Yêu cầu thành công!',
            subTitle: 'Vui lòng chờ Unitz kiểm tra xác nhận gửi vào hộp thư của bạn.',
          },
          error: {
            title: 'Yêu cầu thất bại!',
            subTitle: 'Vui lòng kiểm tra thông tin đăng ký bên dưới và đăng ký lại sau.',
          },
        },
      },
      modalConfirm: {
        title: 'Phê duyệt yêu cầu học thử',
      },
    },
  },
  CourseRoom: {
    purchase: {
      transaction_per_purchase_tooltip: 'Thanh toán/Lượt mua',
    },
    validation: {
      invalid_room_attendee: 'Bạn chưa đăng ký khoá học này.',
      invalid_room_purchase: 'Bạn chưa thanh toán cho lớp học này.',
      invalid_room_time: 'Lớp học chưa tới giờ mở.',
      invalid_room_completed: 'Lớp học đã kết thúc.',
      invalid_room_host: 'Bạn không phải là giáo viên của lớp học này.',
      invalid_room: 'Lớp học không tồn tại',
    },
    status: {
      ready_to_join: 'Sẵn sàng tham gia',
      not_scheduled: 'Lớp học hiện không mở',
    },
    message: {
      invalid_room: 'Kiểm tra lại thông tin lớp học.',
      check_camera_micro: 'Kiểm tra kỹ cài đặt máy ảnh, micro và loa của bạn!',
      not_scheduled: 'Máy ảnh của bạn sẽ không hiển thị khi bạn tham gia lớp học ngoài thời gian đã định. Nhưng bạn có thể lưu cài đặt này để sử dụng sau!',
    },
    label: {
      camera: 'Camera',
      micro: 'Micro',
      speaker: 'Loa ngoài',
      view_class: 'Xem lớp học',
    },
    placeholder: {
      camera: 'Chọn camera',
      micro: 'Chọn micro',
      speaker: 'Chọn loa',
    },
    menus: {
      message: {
        title: 'Nhắn tin',
      },
      reschedule: {
        title: 'Thay đổi giờ học',
      },
      refund: {
        title: 'Hoàn tiền',
      },
      dismiss: {
        title: 'Huỷ buổi học',
      },
      unfollow: {
        title: 'Huỷ theo dõi',
      },
      cancel: {
        title: 'Huỷ buổi học',
      },
    },
    modals: {
      message: {
        title: 'Nhắn tin',
        infoMessage: 'Nhắn tin',
        okText: 'Gửi',
        cancelText: 'Đóng',
      },
      reschedule: {
        title: 'Thay đổi giờ học',
        infoMessage: 'Thay đổi giờ học',
        okText: 'Đồng ý',
        cancelText: 'Đóng',
        currentTimeFrameLabel: 'Giờ học hiện tại',
        newTimeFrameLabel: 'Giờ học mới',
        updateSameRoomsLabel: 'Cập nhật cho các lớp học tương tự',
      },
      refund: {
        title: 'Hoàn tiền',
        infoMessage: 'Hoàn tiền',
        confirmMessage: 'Vui lòng nhập tên của khoá học vào ô bên dưới để tiếp tục thực hiện yên cầu hoàn tiền.',
        okText: 'Hoàn tiền',
        cancelText: 'Đóng',
      },
      dismiss: {
        title: 'Huỷ buổi học',
        infoMessage: 'Bạn có thể huỷ buổi học khoá học này và khoá học sẽ không hiển thị trong lịch học của bạn nữa.',
        confirmMessage: 'Vui lòng nhập tên của khoá học vào ô bên dưới để tiếp tục thực hiện yên cầu huỷ buổi học.',
        okText: 'Đồng ý',
        cancelText: 'Đóng',
      },
      unfollow: {
        title: 'Huỷ theo dõi',
        infoMessage: 'Lịch học của bạn có khoá học nay vì bạn đã từng tham gia khoá học này. Bạn có thể huỷ theo dõi khoá học này và khoá học sẽ không hiển thị trong lịch học của bạn nữa.',
        confirmMessage: 'Vui lòng nhập tên của khoá học vào ô bên dưới để tiếp tục thực hiện yên cầu huỷ theo dõi.',
        okText: 'Đồng ý',
        cancelText: 'Đóng',
      },
      cancel: {
        title: 'Huỷ buổi học',
        infoMessage: 'Bạn muốn huỷ buổi học này? Bạn sẽ không nhận được học phí cho buổi học bị huỷ và tiền học phí sẽ được hoàn trả lại cho học viên.',
        confirmMessage: 'Vui lòng nhập tên của khoá học vào ô bên dưới để tiếp tục thực hiện yên cầu huỷ buổi học.',
        okText: 'Đồng ý',
        cancelText: 'Đóng',
      },
    },
    info: {
      Status: 'Tình trạng',
      NumberOfStudent: 'Học viên',
      Attendee: {
        zero: 'Chưa có ai tham gia',
        one: '1 người tham gia',
        other: '%{count} người tham gia',
      },
    },
  },
  CourseReview: {
    title: 'Buổi học hôm nay kết thúc!',
    description: 'Hãy cho chúng tôi biết thêm một chút!',
    question1: 'Trải nghiệm của bạn với buổi học như thế nào?',
    question2: 'Bạn có muốn đánh giá lại cho giáo viên của mình không?',
    question3: 'Bạn có bất kì bình luận nào cho giáo viên? Xin vui lòng cho chúng tôi biết những gì bạn nghĩ.',
    place_holder: 'Bạn có muốn để lại bình luận cho chúng tôi không? Nó sẽ hữu ích cho các học viên khác.',
    completeTitle: 'Cảm ơn bạn đã đánh giá',
    completeDescription: 'Chúng tôi sẽ sử dụng đánh giá của bạn để cải thiện sản phẩm',
    Action: {
      goHome: 'Về trang chủ',
      confirm: 'Xác nhận',
      rateNow: 'Đánh giá ngay',
      ok: 'Ok',
    },
  },
  HomeSearchCourse: {
    per_session: 'Theo buổi',
    per_course: 'Theo khóa',
    Label: {
      field: 'Lĩnh vực',
      categories: 'Danh mục',
      session_duration: 'Thời lượng buổi học',
      tuition: 'Học phí',
    },
    Placeholder: {
      field: 'Chọn lĩnh vực mà bạn quan tâm',
      categories: 'Chọn danh mục mà bạn muốn học',
      session_duration: 'Chọn thời lượng bạn muốn học / buổi',
      tuition: 'Học phí theo',
    },
    Action: {
      search: 'Tìm khóa học',
    },
    course_more: 'Xem thêm các khóa học cùng chủ đề tại đây',
  },
  InstructorFilter: {
    title: 'Danh sách Giảng viên',
    filterTools: 'Bộ lọc',
    show: 'Hiển thị',
    quantity: 'Số lượng',
    emptyFilterTxt1: 'Hiện tại chưa có các giảng viên thuộc lĩnh vực %{category}.',
    emptyFilterTxt2: 'Chúng tôi sẽ thông báo đến bạn khi có giảng viên mới.',
    emptyFilterTxt3: 'Trong lúc này, bạn hãy tìm kiếm thử các giảng viên khác nhé!',
    apply: 'Áp dụng',
    ratings: '%{count} trở lên',
    menu: {
      category: 'Danh mục',
      priceRange: 'Khoảng giá',
      duration: 'Thời lượng',
      ratings: 'Đánh giá',
    },
    sortBy: {
      placeHolder: 'Sắp xếp theo',
      newest: 'Mới nhất',
      outstanding: 'Nổi bật nhất',
      highRating: 'Đánh giá: Cao đến Thấp',
    },
    result: {
      zero: '0 kết quả',
      one: '1 kết quả',
      other: '%{count} kết quả',
    },
  },
  CourseFilter: {
    title: 'Danh sách khóa học',
    filterTools: 'Bộ lọc',
    show: 'Hiển thị',
    sessionUnit: 'buổi',
    videoDemo: 'Video giới thiệu',
    quantity: 'Số lượng',
    emptyFilterTxt1: 'Hiện tại chưa có các khóa học này, các khoá học sẽ sớm diễn ra trong thời gian tới.',
    emptyFilterTxt2: 'Chúng tôi sẽ thông báo đến bạn khi có khóa học mới.',
    emptyFilterTxt3: 'Trong lúc này, bạn hãy tìm hiểu thử các khóa học đang có sẵn khác nhé!',
    apply: 'Áp dụng',
    ratings: '%{count} trở lên',
    menu: {
      category: 'Danh mục',
      priceRange: 'Khoảng giá',
      duration: 'Thời lượng',
      ratings: 'Đánh giá',
    },
    sortBy: {
      placeHolder: 'Sắp xếp theo',
      newest: 'Mới nhất',
      outstanding: 'Nổi bật nhất',
      highRating: 'Đánh giá: Cao đến Thấp',
      viewing: 'Đang xem',
    },
    result: {
      zero: '0 kết quả',
      one: '1 kết quả',
      other: '%{count} kết quả',
    },
    recommendedCourses: 'Khóa học được đề xuất',
  },
  Teacher: {
    lecturer: 'Giảng viên',
    more: 'Xem thông tin',
    teachingExpertise: 'Chuyên môn giảng dạy',
    myCourse: 'Khóa học của tôi',
    teachingStyle: 'Phong cách giảng dạy',
    howToSpeak: {
      name: 'Cách giảng?',
      min: 'Thân thiện',
      max: 'Lịch sự',
    },
    speakingSpeed: {
      name: 'Tốc độ nói chuyện?',
      min: 'Chậm',
      max: 'Nhanh',
    },
    howToConveyTheResult: {
      name: 'Cách truyền đạt kết quả',
      min: 'Uyển chuyển',
      max: 'Trực tiếp',
    },
    titleHeader: 'Thông tin giáo viên',
    title: 'Giáo viên',
    featured: 'Giáo viên nổi bật',
  },
  Payment: {
    StepOneTitle: '1. Chọn gói cuộc gọi',
    StepTwoTitle: '2. Thanh toán bằng ví Unitz',
    StepTwoTitleVN: '2. Phương thức thanh toán',
    StepThreeTitleVN: '3. Ưu đãi',
    StepThreeTitle: '3. Phương thức thanh toán',
    StepFourTitle: '4. Ưu đãi',
    Bill: {
      package: 'Gói',
      callVideo: 'Cuộc gọi video',
      tempCost: 'Tạm tính',
      discountCost: 'Ưu đãi',
      totalCost: 'Thành tiền',
      unitzCoin: 'Unitz wallet',
      promotion: 'Ưu đãi',
      priceUnit: 'Đơn giá phút',
      pay: 'Thanh toán',
    },
    Wallet: {
      equivalent: 'tương ứng',
    },
    Profile: {
      reviews: 'lượt bình chọn',
      advisor: 'Chuyên gia tư vấn',
    },
    Method: {
      myCard: 'Thẻ của tôi',
      domesticDebitCard: 'Thẻ thanh toán nội địa',
      visaMasterCard: 'Thẻ thanh toán quốc tế ',
      newCard: 'Thêm thẻ',
      selectCard: 'Chọn thẻ',
      selectBank: 'Chọn ngân hàng',
    },
    Discount: {
      selectDiscountPlaceHolder: 'Nhập mã ưu đãi',
      optionLabelDiscountPercentage: 'Giảm %{percentage}%',
      optionLabelDiscountAmount: 'Giảm %{amount}',
      optionLabelDiscountPercentageMaxAmount: 'Giảm %{percentage}% tối đa %{amount}',
    },
    Error: {
      service: 'Bạn cần chọn một dịch vụ.',
      credit: 'Bạn nhớ chọn thanh toán với ví Unitz nhé.',
      bank: 'Đừng quên chọn thẻ ngân hàng của bạn nhé.',
      token: 'Đừng quên chọn thẻ của bạn nhé.',
    },
    Action: {
      payNow: 'Thanh toán ngay',
      joinClass: 'Vào lớp',
    },
  },
  Wallet: {
    walletTxt: 'Ví của tôi',
    walletSurplus: 'Số dư ví:',
    Action: {
      addVoucher: 'Nạp voucher',
      recharge: 'Nạp tiền',
      transfer: 'Chuyển khoản',
    },
    Table: {
      transactionDate: 'Ngày GD',
      detailTransaction: 'Chi tiết giao dịch',
      status: 'Trạng thái',
      Amount: 'Số tiền',
    },
  },
  Bank: {
    bankListTxt: 'Danh sách thẻ',
    internationalPayment: 'Thẻ thanh toán quốc tế',
    paypalPayment: 'PayPal',
    domesticPayment: 'Tài khoản ngân hàng',
    emptyCardList: 'Bạn chưa có thẻ',
    default: 'Mặc định',
    addInternationalCard: 'Thêm thẻ tín dụng / thẻ ghi nợ',
    addPaypal: 'Thêm tài khoản Paypal',
    addDomesticCard: 'Thêm tài khoản ngân hàng',
    numberCard: 'Số thẻ ngân hàng',
    numberCard_placeholder: 'Vd: 1234 5678 9000 1234',
    paypalId: 'Tài khoản PayPal',
    paypalId_placeholder: 'Tên, @tên người dùng, email, hoặc số di động',
    nameCard: 'Tên chủ thẻ',
    nameCard_placeholder: 'Vd: NGUYEN MINH A',
    bankSelection: 'Chọn ngân hàng',
    bankSelection_placeholder: 'Vui lòng chọn ngân hàng',
    expDate: 'Ngày hết hạn',
    expDate_placeholder: 'MM/YY',
    cvv: 'Mã bảo mật',
    cvv_placeholder: 'Vd: 123',
    Validation: {
      equiredCardType: 'Số thẻ không hợp lệ',
      requiredCardLen: 'Số thẻ phải đủ 16 ký tự',
      requiredCardExpDate: 'Ngày sử dụng không hợp lệ',
      requiredCardCVC: 'Mã bảo mật không hợp lệ',
      required: 'Vui lòng điền ô này',
    },
    Action: {
      addCard: 'Thêm thẻ',
      addAccount: 'Thêm tài khoản',
      delete: 'Xóa',
      saveCard: 'Lưu',
      back: 'Trở lại',
    },
  },
  History: {
    Filter: {
      category: 'Danh mục',
      status: 'Trạng thái',
      startDate: 'Từ ngày',
      endDate: 'Đến ngày',
      filterTxt: 'Lọc theo',
    },
    Status: {
      reject: 'Cuộc gọi nhỡ',
      pending: 'Chờ xác nhận',
      unknown: 'Chưa xác định',
      completed: 'Hoàn thành',
      canceled: 'Bị hủy',
    },
    Table: {
      createdAt: 'Ngày thực hiện',
      displayName: 'Chuyên gia',
      type: 'Loại',
      during: 'Thời lượng',
      status: 'Trạng thái',
      priceAmount: 'Số tiền',
      courseRoomDate: 'Thời gian phòng học',
    },
    Detail: {
      detailTxt: 'Chi tiết hoạt động',
      paymentInfo: 'Thông tin hoạt động',
      createdAt: 'Ngày thực hiện',
      status: 'Trạng thái cuộc gọi',
      package: 'Gói cuộc gọi',
      callTxt: 'Cuộc gọi',
      courseTxt: 'Khoá học',
      during: 'Thời lượng',
      typeOfService: 'Loại dịch vụ',
      advisor: 'Chuyên gia',
      teacher: 'Giáo viên',
      cash: 'Số tiền',
      numberOfSession: 'Số buổi đã đăng ký',
      statusOfCourse: 'Trạng thái buổi học',
    },
    Rating: {
      ratingTxt: 'Đánh giá & Cảm nhận',
      yourRating: 'Đánh giá của bạn',
      aboutAdvisor: 'Cảm nhận về chuyên gia',
    },
  },
  Transaction: {
    Filter: {
      category: 'Danh mục',
      status: 'Trạng thái',
      time: 'Thời gian',
      startDate: 'Từ ngày',
      endDate: 'Đến ngày',
      filterTxt: 'Lọc theo',
    },
    Status: {
      reject: 'Cuộc gọi nhỡ',
      pending: 'Chờ xác nhận',
      unknown: 'Chưa xác định',
      incomplete: 'Unconfirmed',
      completed: 'Đã xác nhận',
      refund: 'Đã hoàn tiền',
      expired: 'Hết hạn',
      canceled: 'Đã hủy',
      declined: 'Đã từ chối',
      processing: 'Đang xử lý',
    },
    Table: {
      createdAt: 'Ngày GD',
      displayName: 'Chuyên gia',
      coin: 'Unitz Coin',
      status: 'Trạng thái',
      priceAmount: 'Số tiền',
      package: 'Gói tư vấn',
      detailTransaction: 'Chi tiết giao dịch',
    },
    Detail: {
      transactionInfo: 'Thông tin thanh toán',
      paymentInfo: 'Thông tin thanh toán',
      paySuccess: 'Thanh toán thành công',
      paymentDetail: 'Chi tiết thanh toán',
      call: 'Gọi ngay',
      createdAt: 'Thời gian giao dịch',
      transactionId: 'Mã giao dịch',
      buyPackage: 'Mua gói tư vấn',
      buyBooking: 'Mua gói tư vấn',
      buyCourse: 'Mua khoá học',
      status: 'Trạng thái',
      package: 'Gói tư vấn',
      displayName: 'Chuyên gia tư vấn',
      typeOfTransaction: 'Loại giao dịch',
      typeOfService: 'Loại dịch vụ',
      serviceDetail: 'Chi tiết dịch vụ',
      customer: 'Khách hàng',
      paymentResource: 'Nguồn tiền',
      cash: 'Số tiền thanh toán',
      totalCash: 'Tổng tiền',
      promotion: 'Khuyến mãi',
    },
    payments: {
      labelsByType: {
        card: 'Thanh toán bằng thẻ visa/master',
        wallet: 'Thanh toán bằng tài khoản Unitz',
        payment: 'Thanh toán thẻ ngân hàng nội địa',
      },
    },
  },
  Booking: {
    advisorTitle: 'Chuyên gia tư vấn',
    namePackage: 'Giá cho gói %{time} phút',
    selectPackage: 'Chọn gói',
    freeFiveMinutes: 'Miễn phí: 5 phút đầu tiên ',
    checkout: 'Thanh toán',
    checkoutMethod: 'Hình thức thanh toán',
    methodPayment: {
      checkout: 'Thanh toán',
      checkoutMethod: 'Hình thức thanh toán',
      visaOrMaster: 'Thẻ Visa/Master',
      bankCard: 'Bank Card',
      eWallet: 'e-Wallet',
      momo: 'Momo',
    },
    PaymentEditCard: {
      headerTitle: 'Thay đổi thông tin thẻ',
      cardName: 'Full name as displayed on card',
      cardExpDate: 'Expiration date',
      setDefault: 'Set as main card',
      newCard: 'Add new card',
      newCardName: 'Name on card',
      newCardNumber: 'Card number',
      newCardExp: 'MM/YY',
      newCardCVV: 'CVV',
      confrim: 'Confirm',
      requiredCardType: 'Invalid card number',
      requiredCardLen: 'Card number must be 16 characters long',
      requiredCardExpDate: 'Invalid expiration date',
      requiredCardCVC: 'Invalid CVV number',
      required: 'Please fill in the required fields',
    },
    checkoutStatus: {
      title: 'Kết quả giao dịch',
      code: 'Mã giao dịch',
      timeTransaction: 'Thời gian giao dịch',
      call: 'Gọi ngay',
      detail: 'Chi tiết giao dịch',
      success: 'Thanh toán thành công',
      fail: 'Thanh toán thất bại',
      pending: 'Đang chờ thanh toán',
      congratulations: 'Chúc mừng bạn đã thanh toán thành công gói',
      waitingForSupport: '(chờ xác nhận thanh toán)',
      or: 'hoặc',
      backHome: 'Quay về trang chủ',
      canceled: 'Giao dịch đã bị huỷ',
      processing: 'Giao dịch đang chờ xác nhận',
      completed: 'Giao dịch thành công',
      declined: 'Giao dịch đã bị từ chối',
    },
    AmountInvalid: 'Số tiền cần phải thanh toán qua thẻ của quý khách dưới hạn mức thanh toán tối thiểu của thẻ ngân hàng, vui lòng thanh toán toàn bộ gói cước',
  },
  AdvisorApp: {
    Onboarding: {
      Skip: 'Bỏ qua',
    },
    Login: {
      Login: 'Đăng nhập',
      loginWith: 'Đăng nhập bằng',
      fillInfo: 'Điền thông tin tài khoản và mật khẩu tại đây',
      userName: 'Tên người dùng',
    },
  },
  Tabbar: {
    Home: 'Trang chủ',
    New: 'Tin tức',
    Wallet: 'Ví',
    Monetization: 'Thu nhập',
    Account: 'Tài khoản',
    Calender: 'Lịch',
  },
  HomeAdvisor: {
    welcomeBack: 'Xin chào, ',
    readyAdvise: 'Sẵn sàng tư vấn',
    withdraw: 'Rút tiền',
    Monetization: 'Thu nhập',
    Rate: 'Đánh giá',
    timeAvg: 'phút / tuần này',
    On: 'Đang mở',
    Off: 'Đang tắt',
    setPrice: 'Thiết lập giá',
    setPriceTxt: 'Thiết lập giá',
    namePackage: 'Gói %{time} phút',
    createPackage: 'Thêm gói',
    newPackage: 'Gói mới',
    editPackage: 'Cập nhật gói',
    perPrice: 'Đơn giá',
    totalPrice: 'Tổng tiền',
    numTime: 'Số phút',
    defaultMoney: '0 đ',
    date: {
      Monday: 'T2',
      Tuesday: 'T3',
      Wednesday: 'T4',
      Thursday: 'T5',
      Friday: 'T6',
      Saturday: 'T7',
      Sunday: 'CN',
    },
    control: 'Thao tác',
    edit: 'Chỉnh sửa giá',
    delete: 'Xóa gói',
    selectioPackage: 'Nhấp vào để chọn',
    acceptVideoCall: 'Bạn đã sẵn sàng nhận cuộc gọi %{name}',
    rejectVideoCall: 'Bạn đang không nhận cuộc gọi %{name}',
    acceptAdvise: 'Bạn đã sẵn sàng tư vấn',
    rejectAdvise: 'Bạn đang không nhận tư vấn',
    kindCall: 'Cuộc gọi %{name}',
    kindCallVideo: 'Cuộc gọi hình ảnh',
    kindCallVoice: 'Cuộc gọi âm thanh',
    kindCallNotificationVideo: 'cuộc gọi hình ảnh',
    kindCallNotificationVoice: 'cuộc gọi âm thanh',
    newPackagePrice: 'Giá cho gói',
    notAllowAccount: 'Tài khoản chưa được xét duyệt',
    noServiceYet: 'Tài khoản của bạn hiện chưa có dịch vụ.',
    sendAuthentication: 'Gửi xác thực tài khoản',
    AuthenticationDescription: 'Bạn đã hoàn tất thiết lập đầy đủ các thông tin trong phần %{account} và %{info}?',
    Account: 'Tài khoản',
    Info: 'Trang cá nhân',
    ConfirmAndSend: 'Xác nhận & Gửi',
    Back: 'Quay lại',
    SendApprovalSuccess: 'Gửi yêu cầu xác nhận tài khoản thành công!',
    SendApproval: 'Gửi xác thực',
  },
  AdvisorVideoReceiver: {
    reject: 'Từ chối',
    accept: 'Trả lời',
    speaker: 'Loa ngoài',
    microOn: 'Mở micro',
    microOff: 'Tắt micro',
    rotateCamera: 'Xoay camera',
    endCall: 'Kết thúc',
    videoOn: 'Mở video',
    videoOff: 'Tắt video',
    reportCall: 'Báo cáo cuộc gọi',
    callSuccess: 'Cuộc gọi hoàn thành',
    callFail: 'Cuộc gọi gián đoạn',
    callInfo: 'Thông tin cuộc gọi',
    callPackage: 'Gói cuộc gọi:',
    callPackageTime: 'Thời gian gọi:',
    callPackagePrice: 'Đơn giá phút:',
    callPackageTotal: 'Tổng tiền:',
    pricePerMin: '%{currency}/phút',
    goBack: 'Về trang chủ',
    separate: 'chia đôi',
    collapse: 'Thu gọn',
    reconnect: 'Kết nối của %{user} không ổn định. Vui lòng chờ trong giây lát.',
    disconnect: 'Cuộc gọi bị mất kết nối do lỗi mạng',
  },
  AdvisorWallet: {
    myWallet: 'Ví của tôi',
    walletBalance: 'Số dư ví',
    walletAvailabeBalance: 'Số dư có thể rút',
    linkBankDesc: 'Liên kết tài khoản ngân hàng để nhận các khoản thu nhập nhanh chóng.',
    linkBankNow: 'Liên kết ngay  ᐳ',
    linkBankTitle: 'Thông tin liên kết',
    labelBankName: 'Tên chủ tài khoản',
    bankName: 'Nhập tên đầy đủ của tài khoản thẻ của bạn',
    labelBankNumber: 'Số tài khoản',
    bankNumber: 'Nhập số tài khoản ngân hàng của bạn',
    bankCode: 'Ngân hàng',
    addBank: 'Thêm tài khoản',
    listAccount: 'Danh sách tài khoản',
    verify: 'Đang xác thực',
    transferToAccount: 'Chuyển khoản đến tài khoản',
    historyTransaction: 'Kiểm tra lịch sử giao dịch',
    history: 'Giao dịch của tôi',
    transfer: 'Chuyển khoản',
    typeMoney: 'Nhập số tiền (vnđ)',
    accountReceiver: 'Tài khoản nhận',
    continue: 'Tiếp tục',
    transferSuccess: 'Chuyển khoản thành công',
    listBank: 'Danh sách ngân hàng',
    searchBank: 'Tìm kiếm ngân hàng',
    addCardSuccess: 'Thêm thông tin thẻ thành công',
    addCardFail: 'Thêm thông tin thẻ thất bại',
    payout: 'Rút tiền',
    balance: 'Số dư: %{balance}',
    required: 'Vui lòng nhập thông tin',
    moreThanMoney: 'Số tiền chuyển phải tối thiểu %{val}',
    notValidAmount: 'Số tiền chuyển của bạn không được vượt quá số dư có thể rút',
    bankEmptyList: 'Chưa có ngân hàng nào được liên kết',
    historyTransactionEmpty: 'Chưa có lịch sử giao dịch',
    incomeEmpty: 'Chưa có lịch sử thu nhập',
    requiredPrice: 'Vui lòng nhập gói cước mong muốn',
    minPrice: 'Giá gói cưới tối thiểu %{amount}',
    maxPrice: 'Giá gói cưới tối đa %{amount}',
    transferError: 'Chuyển khoản thất bại',
    bankNamePlaceholder: 'Tên chủ tài khoản',
    bankNumberPlaceholder: 'Số tài khoản',
    infoText: 'Thông tin giao dịch',
    typeText: 'Loại giao dịch',
    code: 'Mã giao dịch',
    timeTxt: 'Thời gian giao dịch',
    statusTxt: 'Tình trạng',
    infoTransaction: 'Thông tin thanh toán',
    total: 'Số tiền rút',
    fee: 'Phí',
    amountAfter: 'Tổng tiền',
    balanceAvailable: 'Số dư ví sau giao dịch',
    describeDeposit: 'Vui lòng nạp thêm vào ví Unitz để hoàn tất giao dịch',
    walletName: 'Ví Unitz',
    depositAdd: 'Nạp thêm',
    exchangePayment: 'Đổi phương thức thanh toán',
    method: 'Phương thức thanh toán',
    type: 'Rút tiền',
    minValue: 'Số tiền tối thiểu là %{num}',
    maxValue: 'Số tiền tối đa là %{num}',
    account: 'Tài khoản nhận',
    reason: 'Lý do',
    status: {
      processing: 'Đang xử lý',
      completed: 'Đã hoàn thành',
      canceled: 'Thất bại',
      declined: 'Bị từ chối',
    },
    detail: 'Chi tiết giao dịch',
    checkoutStatus: {
      canceled: 'Giao dịch đã bị huỷ',
      processing: 'Giao dịch đang chờ xác nhận',
      completed: 'Giao dịch thành công',
      declined: 'Giao dịch đã bị từ chối',
    },
    withdrawError: 'Yêu cầu chuyển khoản khoản không thành công',
    withdrawSuccess: 'Yêu cầu chuyển khoản thành công',
    support: 'Liên hệ hỗ trợ',
    walletStatus: 'Trạng thái',
    linking: 'Đang xác thực',
    linked: 'Đã xác thực',
    unlink: 'Huỷ liên kết',
    setCardDefault: 'Đặt làm mặc định',
    setCardDefaultSuccess: 'Đặt làm mặc định thẻ thành công !',
    setCardDefaultFail: 'Đặt làm mặc định thẻ thất bại !',
    unlinkSuccess: 'Huỷ liên kết thẻ thành công !',
    unlinkFail: 'Huỷ liên kết thẻ thất bại !',
  },
  AdvisorInCome: {
    titleText: 'Thu nhập',
    advisorNum: '%{num} lượt tư vấn',
    inComeToday: 'Thu nhập hôm nay',
    inComeYesterday: 'Thu nhập hôm qua',
    inComeInWeek: 'Thu nhập tuần này',
    inComeInMonth: 'Thu nhập tháng này',
    inComeDetail: 'Chi tiết thu nhập',
    advisorNumDetail: '%{num} lượt tư vấn đã thực hiện',
    inComeTextPackage: 'Gói %{num} phút',
    inComeTextTime: '%{num} phút',
    titleTextTotal: 'Tổng thu nhập',
    statistical: 'Thống kê',
    summary: 'Tổng quát',
    chart: {
      today: 'Hôm nay',
      yesterday: 'Hôm qua',
      week: 'Tuần này',
      month: 'Tháng này',
    },
    totalFrom: 'Tổng thu nhập từ %{start} - %{end}',
    perMount: 'T.bình %{num} đ / lượt',
    perTime: 'Trung bình %{num} phút / lượt',
    collapse: 'Thu gọn',
    more: 'Xem thêm',
    callVideo: 'Cuộc gọi video',
    callVoice: 'Cuộc gọi thoại',
    callRoom: 'Khoá học',
    fromDate: 'Từ %{start} - %{end}',
    chartView: {
      day: 'Ngày',
      week: 'Tuần',
      month: 'Tháng',
    },
    totalDay: 'Tổng thu nhập ngày',
    numAdvisorTxt: 'Số lượt tư vấn đã thực hiện',
    numAdvisor: '%{num} lượt',
    numCourse: '%{num} buổi học',
    fromDay: 'Từ ngày %{start} - ngày %{end}',
    rangeWeek: {
      0: 'CN',
      1: 'T2',
      2: 'T3',
      3: 'T4',
      4: 'T5',
      5: 'T6',
      6: 'T7',
    },
  },
  Calendar: {
    Today: 'Hôm nay',
    Yesterday: 'Hôm qua',
    Week: 'Tuần này',
    Month: 'Tháng này',
    Day: 'Ngày',
    _Week: 'Tuần',
    _Month: 'Tháng',
    Agenda: 'Lịch',
    Date: 'Ngày',
    Time: 'Giờ',
    Event: 'Buổi học',
    Allday: 'Cả ngày',
    noEventsInRange: 'Không có buổi học nào trong khoảng thời gian này',
    FilterByCourses: 'Lọc theo khoá học',
  },
  Validation: {
    accountName: 'Tên chủ tài khoản bao từ 6 đến 40 ký tự, chỉ bao gồm chữ cái',
    accountNumber: 'Số tài khoản không hợp lệ',
    fullName: 'Họ & tên bao từ 6 đến 40 ký tự, chỉ bao gồm chữ cái',
    phoneNumber: 'Số điện thoại không hợp lệ',
    identify: 'CMND/CCCD không hợp lệ',
    passport: 'Hộ chiếu không hợp lệ',
    taxNumber: 'Mã số thuế không hợp lệ',
    email: 'Địa chỉ email không hợp lệ',
    httpLink: 'Đường dẫn không hợp lệ',
  },
  Alert: {
    accept: 'Đồng ý',
    reject: 'Huỷ',
  },
  AdvisorNotification: {
    headerTitle: 'Thông báo',
    emptyList: 'Chưa có thông báo hệ thống mới',
    All: 'Tất cả ',
    UnSeen: 'Chưa đọc',
    Seen: 'Đã đọc',
    detail: 'Chi tiết',
  },
  AdvisorReview: {
    headerTitle: 'Nhận xét & Đánh giá',
    userReview: 'Khách hàng nhận xét',
    numReview: '%{num} nhận xét',
    collapse: 'Thu gọn',
    more: 'Xem thêm',
    emptyList: 'Chưa có nhận xét & đánh giá',
    question: 'Bạn có muốn để lại bình luận cho giáo viên?',
    place_holder: 'Viết cảm nghĩ của bạn ở đây!',
    rating_success: 'Đánh giá thành công',
    Action: {
      confirm: 'Xác nhận',
      cancel: 'Hủy bỏ',
    },
  },
  AdvisorBonusMoney: {
    point: 'Điểm thưởng',
    transfer: 'Quy đổi và rút về ví',
    task: 'Nhiệm vụ',
    emptyList: 'Chưa có nhiệm vụ mới',
  },
  AdvisorVoiceStart: {
    callInfoTxt: 'Bạn có %{duration} phút liên hệ',
    callPolicyTx: 'Mọi thông tin trao đổi của bạn và nhà tư vấn đều được bảo mật nhằm bảo vệ quyền riêng tư cá nhân. Unitz không được quyền lưu trữ.',
    videoCallTxt: 'Gọi voice',
  },
  AdvisorVoiceCall: {
    title: 'Cuộc gọi kết thúc',
    userReaction: 'Cảm nhận của bạn',
    rejectCallText: 'Lỗi gián đoạn cuộc gọi?',
    reCall: 'Gọi lại',
    ringingStatusTxt: 'Đang kết nối với nhà tư vấn. Xin đợi trong giây lát.',
    incommingStatusTxt: 'Cuộc gọi Voice đến',
    initingStatusTxt: 'Đang thiết lập kết nối ...',
    callReportTxt1: 'Bạn vừa kết thúc ',
    callReportTxt2: ' gọi với nhà tư vấn:',
    callReviewLabel: 'Hãy cho chúng tôi biết độ hài lòng của bạn',
    callReviewBadLabel: 'Tệ',
    callReviewGoodLabel: 'Rất tốt',
    callReviewSubmitBtn: 'Gửi',
    reviewTextPlaceholder: 'Viết một chút chia sẻ về trải nghiệm của bạn',
    finishTxt: 'Hoàn thành',
    finishDesc: 'Đánh giá mức hài lòng của bạn về chuyên gia?',
    ratingGood: 'Rất tốt',
    ratingBad: 'Tệ',
    finishNote: 'Đôi dòng cảm nhận về buổi tư vấn ...',
    finishSend: 'Hoàn tất',
    finishBuyMore: 'Mua thêm gói',
    exit: 'Bỏ qua',
    noAnswer: 'Không trả lời',
    back: 'Quay về',
    connectionDisconnect: 'Cuộc gọi bị mất kết nối do lỗi mạng',
    reportCall: 'Báo cáo sự cố cho Unitz',
    close: 'Thoát',
    callStatus: {
      completedLabel: 'Cuộc gọi kết thúc',
      declinedLabel: 'Cuộc gọi bị từ chối',
      missedLabel: 'Cuộc gọi bị nhỡ',
      earlyEndLabel: 'Cuộc gọi kết thúc sớm',
    },
    finishReview: 'Nhận xét',
    callVideo: 'Gói video',
    callVoice: 'Gói thoại',
  },
  AdvisorVoiceReceiver: {
    reject: 'Từ chối',
    accept: 'Trả lời',
    speaker: 'Loa ngoài',
    microOn: 'Mở micro',
    microOff: 'Tắt micro',
    rotateCamera: 'Xoay camera',
    endCall: 'Kết thúc',
    videoOn: 'Mở video',
    videoOff: 'Tắt video',
    reportCall: 'Báo cáo cuộc gọi',
    callSuccess: 'Cuộc gọi hoàn thành',
    callFail: 'Cuộc gọi gián đoạn',
    callInfo: 'Thông tin cuộc gọi',
    callPackage: 'Gói cuộc gọi:',
    callPackageTime: 'Thời gian gọi:',
    callPackagePrice: 'Đơn giá phút:',
    callPackageTotal: 'Tổng tiền:',
    pricePerMin: '%{currency}/ phút',
    goBack: 'Về trang chủ',
    separate: 'chia đôi',
    collapse: 'Thu gọn',
    reconnect: 'Kết nối của %{user} không ổn định. Vui lòng chờ trong giây lát.',
    disconnect: 'Cuộc gọi bị mất kết nối do lỗi mạng',
  },
  UserTabbar: {
    Home: 'Trang chủ',
    Category: 'Danh mục',
    Like: 'Yêu thích',
    Account: 'Tài khoản',
    Calendar: 'Lịch',
  },
  StatusAdvisor: {
    Online: 'Trực tuyến',
    Offline: 'Ngoại tuyến',
  },
  SearchUser: {
    placeHolder: 'Tìm nhà tư vấn',
    suggest: 'Gợi ý',
    done: 'Xong',
    result: '%{num} chuyên gia tìm được cho',
    emptyList: 'Tìm nhà tư vấn dành cho bạn',
    notResult: 'Không có nhà tư vấn nào phù hợp',
  },
  VideoCallPage: {
    SectionAction: {
      split: 'Chia đôi',
      collapse: 'Thu gọn',
      note: 'Ghi chú',
      record: 'Record',
      like: 'Thích',
      setting: 'Thiết lập',
      participants: 'Thành viên',
      message: 'Message',
    },
    Initing: {
      calling: 'Đang gọi',
    },
  },
  UserPayment: {
    advisorTitle: 'Chuyên gia tư vấn',
    confirmText: 'Xác nhận thanh toán',
    payment: 'Thanh toán',
    paymentCourse: 'Thanh toán khóa học',
    of: 'của',
    payFor: 'cho gói cuộc gọi %{time} phút',
    walletName: 'Ví Unitz',
    walletIconLabel: 'Unitz wallet',
    walletIcon: 'Unitz wallet: %{amount}',
    addPaymentMethod: 'Thêm phương thức thanh toán',
    transactionDetail: 'Chi tiết giao dịch',
    amountBefore: 'Tạm tính',
    discount: 'Khuyến mãi',
    amountAfter: 'Thành tiền',
    serviceType: 'Cuộc gọi %{kind}',
    packageName: 'Gói %{time} phút',
    titleTopup: 'Khi nạp vào ví từ %{amount}',
    topUpDesc: 'Nạp trước vào ví để thoải mái gia hạn cuộc gọi tư vấn mà không lo ngắt quãng giữa cuộc gọi.',
    depositNow: 'Nạp tiền ngay',
    topUpTxt: 'Nạp tiền',
    amount: 'Số tiền',
    topUpUseful: 'Nạp thêm tiền vào ví để linh hoạt gia hạn cuộc gọi mà không sợ bị gián đoạn.',
    discountTxt: 'Miễn phí %{num} phút gọi đầu tiên',
    emptyTransactionHistory: 'Chưa có giao dịch nào',
    paymentMyWallet: 'Thanh toán từ ví của tôi',
    linkBank: 'Liên kết thẻ ngân hàng cho lần thanh toán sau.',
    learnMore: 'Tìm hiểu thêm',
    typePackage: 'Kiểu gói',
    typeService: 'Kiểu cuộc gọi',
    duration: 'Thời lượng',
    time: 'Thời gian',
    enterVoucherCode: 'Vui lòng nhập mã voucher',
    invalidVoucher: 'Mã voucher không hợp lệ, vui lòng thử lại!',
  },
  UserPaymentMethod: {
    titleTxt: 'Phương thức thanh toán',
    listCard: 'Danh sách thẻ của tôi',
    listCardOther: 'Phương thức thanh toán khác',
    ATM: 'Thẻ ATM nội địa / Internet banking',
    VisaCard: 'Thẻ quốc tế Visa, Master card, JCB',
    momoWallet: 'Ví MoMo',
    zaloPay: 'Ví Zalopay',
    methodSaved: 'Phương thức đã lưu',
    addCard: 'Thêm thẻ',
    selected: 'Chọn',
    exchangeCard: 'Thay đổi',
    emptyPaymentMethod: 'Chưa có ngân hàng nào được liên kết',
    paypal: 'Paypal',
  },
  PurchaseDetail: {
    title: 'Chi tiết giao dịch',
    waitForConfirm: 'Chờ Unitz xác nhận thanh toán',
    completed: 'Đã xác nhận',
    waitCompleted: 'Chờ xác nhận',
    sourceMoney: 'Nguồn tiền',
    infoTransaction: 'Thông tin giao dịch',
    total: 'Số tiền',
    totalPerSession: 'Số tiền (theo buổi)',
    discount: 'Khuyến mãi',
    amountAfter: 'Tổng thanh toán',
    status: {
      processing: 'Chờ xác nhận',
      completed: 'Đã xác nhận',
      refund: 'Đã hoàn tiền',
      canceled: 'Đã huỷ',
      declined: 'Đã thất bại',
    },
  },
  TopUpPayment: {
    amountBalance: 'Số dư ví:',
    descDiscount: '+ %{percent} % khi nạp từ %{amount} trở lên',
    source: 'Nguồn tiền',
    deposit: 'Nạp tiền',
    exchange: 'Thay đổi',
    currency: 'đ',
    infoText: 'Thông tin giao dịch',
    typeText: 'Loại giao dịch',
    code: 'Mã giao dịch',
    timeTxt: 'Thời gian giao dịch',
    statusTxt: 'Tình trạng',
    infoTransaction: 'Thông tin thanh toán',
    total: 'Số tiền thanh toán',
    discount: 'Khuyến mãi',
    amountAfter: 'Tổng tiền',
    balanceAvailable: 'Số dư ví sau giao dịch',
    notValidAmount: 'Bạn không có đủ số dư',
    describeDeposit: 'Vui lòng nạp thêm vào ví Unitz để hoàn tất giao dịch',
    walletName: 'Ví Unitz',
    depositAdd: 'Nạp thêm',
    exchangePayment: 'Đổi phương thức thanh toán',
    method: 'Phương thức thanh toán',
    type: 'Nạp tiền ví Unitz',
    minValue: 'Số tiền tối thiểu là %{num}',
    maxValue: 'Số tiền tối đa là %{num}',
  },
  ServiceCallPackage: {
    buyPackage: 'Mua gói',
    desc: 'Còn 5 phút nữa là hết giờ rồi, bạn mua thêm gói cuộc gọi nữa chứ?',
    cancel: 'Để sau',
    accept: 'Mua thêm gói',
    payment: 'Thanh toán',
    continue: 'Tiếp tục',
    confirmTxt: 'Xác nhận thanh toán',
    amountBefore: 'Amount',
    discount: 'Discount',
    total: 'Thành tiền',
    status: {
      processing: 'Pending confirmation',
      completed: 'Confirmed',
      canceled: 'Cancelled',
      declined: 'Declined',
    },
  },
  ProfileUser: {
    infoProfile: 'Thông tin tài khoản',
    message: 'Tin nhắn',
    fullName: 'Họ & Tên',
    userInformation: 'Thông tin người dùng',
    phoneNumber: 'Số điện thoại',
    email: 'Email',
    birthday: 'Ngày sinh',
    personalIdentification: 'Định danh cá nhân',
    typeOfIdentity: 'Loại giấy tờ',
    identifierNumber: 'Số định danh',
    required: 'Vui lòng nhập dữ liệu',
    success: 'Cập nhật thông tin thành công ',
    fail: 'Cập nhật thông tin thất bại',
    callHistory: 'Lịch sử hoạt động',
    transactionHistory: 'Giao dịch của tôi',
    detailTransaction: 'Chi tiết giao dịch',
    subCallHistory: 'Các cuộc gọi tư vấn bạn đã tham gia',
    emptyCallHistory: 'Chưa có cuộc gọi nào',
    listCard: 'Danh sách thẻ',
    setting: 'Cài đặt',
    subSetting: 'Thiết lập ngôn ngữ, mục yêu thích, đăng xuất',
    changePassword: 'Đổi mật khẩu',
    changeCategory: 'Thiết lập danh mục yêu thích',
    language: 'Ngôn ngữ',
    currentPasswordLabel: 'Mật khẩu hiện tại',
    oldPasswordLabel: 'Nhập mật khẩu cũ',
    newPasswordLabel: 'Nhập mật khẩu mới',
    confirmPasswordLabel: 'Xác nhận mật khẩu',
    passwordHelper: 'Mật khẩu dùng để bảo mật và đăng nhập tài khoản',
    passwordDescription1: 'Sử dụng tối thiểu 8 ký tự',
    passwordDescription2: 'Bao gồm số, chữ thường, chữ hoa',
    next: 'Tiếp theo',
    done: 'Hoàn tất',
    update: 'Cập nhật',
    voucherLabel: 'Đừng bỏ lỡ các ưu đãi hấp dẫn từ Unitz',
    share: 'Chia sẻ ứng dụng',
    subShare: 'Giới thiệu ứng dụng đến bạn bè xung quanh',
    inviteMessage: 'Hãy tải ứng dụng và trải nghiệm tư vấn cùng mình nhé!',
    subjectEmail: 'Lời mời tải ứng dụng Unitz',
    studySchedule: 'Lịch học',
    yourStudySchedule: 'Lịch học của bạn',
    cardDetail: 'Thông tin tài khoản',
    unlink: 'Huỷ liên kết',
    labelSelectUser: 'Chọn người đánh giá',
    changeAvatar: 'Thay đổi ảnh đại diện',
  },
  CallHistory: {
    detailTxt: 'Chi tiết cuộc gọi',
    timeCall: 'Thời gian',
    advisor: 'Chuyên gia',
    course: 'Khoá học',
    category: 'Danh mục',
    typeCall: 'Loại cuộc gọi',
    packageName: 'Gói cuộc gọi',
    totalAmount: 'Số tiền',
    timeDuration: 'Thời gian gọi',
    review: 'Đánh giá và cảm nhận',
    roomRegister: 'Số buổi đã đăng ký',
    status: {
      processing: 'Đang xử lý',
      completed: 'Đã hoàn thành',
      canceled: 'Thất bại',
      declined: 'Bị từ chối',
    },
  },
  UserWallet: {
    deposit: 'Nạp tiền',
    voucher: 'Nạp voucher',
    giftLabel: 'Tặng 10% khi nạp tiền từ 1.000.000 đ trở lên',
    recentTransaction: 'Giao dịch gần đây',
    payment: 'Thanh toán',
    amountBalance: 'Số dư ví',
    seeAll: 'Xem tất cả',
    walletHistory: 'Lịch sử ví',
    walletWithdrawalHistory: 'Lịch sử rút tiền',
    emptyWalletHistory: 'Không có lịch sử ví',
    status: {
      processing: 'Đang chờ xử lý',
      completed: 'Thành công',
      canceled: 'Đã huỷ',
      declined: 'Đã từ chối',
    },
    packageName: 'Gói %{time} phút',
    topUp: 'Nạp coin từ',
    accountCardInternational: 'Tài khoản thanh toán quốc tế',
    addNew: 'Thêm thẻ mới',
    linkBankNow: 'Liên kết ngay',
  },
  SettingLanguage: {
    vn: 'Tiếng Việt',
    en: 'Tiếng Anh',
    vi: 'Tiếng Việt',
    de: 'Tiếng Đức',
    restartMessage: 'Ứng dụng sẽ tự động khởi động lại để cập nhật ngôn ngữ.',
    restart: 'Khởi động lại',
    hintChangeLanguage: 'Bạn có muốn thay đổi ngôn ngữ mặc định không?',
    title: 'Xác nhận',
    yes: 'Có',
    no: 'Không',
  },
  FilterAdvanced: {
    category: 'Danh mục',
    price: 'Đơn giá phút',
    rate: 'Đánh giá tối thiểu',
    service: 'Loại cuộc gọi',
    favorite: 'Yêu thích',
    reset: 'Đặt lại',
    done: 'Xong',
    filter: 'Bộ lọc',
    liked: 'Đã thích',
    notLiked: 'Chưa thích',
    all: 'Tất cả',
    serviceType: {
      video: 'Cuộc gọi video',
      voice: 'Cuộc gọi thoại',
      phone: 'Cuộc gọi thoại',
      chat: 'Trò chuyện',
      all: 'Tất cả',
    },
    statusTxt: 'Trạng thái',
    status: {
      online: 'Trực tuyến',
      offline: 'Ngoại tuyến',
      all: 'Tất cả',
    },
    sessionDuration: 'Thời lượng buổi học',
    sessionDurationTxt: '%{time} phút',
    pricingType: {
      per_session: 'Theo buổi',
      per_course: 'Theo khóa',
    },
    sessionOccurence: {
      unlimited: 'Không giới hạn buổi học',
      limited: 'Có giới hạn buổi học',
    },
    numberOfSession: 'Số buổi học',
    tuition: 'Học phí',
  },
  HelpCenter: {
    titleTxt: 'Trung tâm trợ giúp',
    recentQuestion: 'Các vấn đề thường gặp',
    seeAll: 'Xem tất cả',
    problemWith: 'Tôi gặp vấn đề với',
    account: 'Tài khoản',
    paymentService: 'Thanh toán cuộc gọi',
    wallet: 'Ví Unitz',
    advisor: 'Tìm nhà tư vấn phù hợp',
    promotion: 'Promotion',
    otherProblem: 'Vấn đề khác',
    helpCustomer: 'Chăm sóc khách hàng',
    infoDesc: 'Thông tin này hữu ích với bạn chứ?',
    send: 'Gửi',
    request: 'Yêu cầu trợ giúp',
    sendRequest: 'Gửi yêu cầu',
    requestTitle: 'Yêu cầu về',
    requestDesc: 'Hãy viết câu hỏi của bạn',
    imageOption: 'Hình ảnh (tuỳ chọn)',
    suggest: 'Góp ý thêm cho Unitz nha',
    whatProblem: 'Bạn gặp vấn đề gì?',
    sendSuccess: 'Gửi đánh giá thành công',
    sendFail: 'Gửi đánh giá thất bại',
    sendRequestSuccess: 'Gửi yêu cầu trợ giúp thành công',
    sendRequestFail: 'Gửi yêu cầu trợ giúp thất bại',
    note: 'Hãy đọc cẩm nang của chúng tôi được biên soạn cẩn thận dành cho bạn!',
    learnMore: 'Tìm hiểu thêm',
    homePage: 'Trang chủ',
    search: 'Tìm kiếm',
    searchingResult: 'Kết quả tìm kiếm...',
  },
  CategoryFavorite: {
    title: 'Danh mục yêu thích',
    otherCategory: 'Danh mục khác',
    Save: 'Lưu',
    success: 'Thay đổi danh mục yêu thích thành công',
    fail: 'Thay đổi danh mục yêu thích thất bại',
  },
  New: {
    describe: 'Cập nhật các thông tin hữu ích, chương trình ưu đãi hấp dẫn, các tính năng mới của Unitz',
  },
  RatingSuggestion: {
    label1: 'Rất tệ!',
    label2: 'Tệ!',
    label3: 'Bình thường!',
    label4: 'Tốt!',
    label5: 'Tuyệt vời!',
    title1: 'Unitz rất tiếc về trải nghiệm mà bạn gặp phải. Bạn vui lòng cho chúng tối biết về vấn đề bạn gặp phải để chúng tôi khắc phục. Chân thành cảm ơn!',
    title2: 'Điều gì cần được cải thiện để nâng cao chất lượng trải nghiệm cuộc gọi của bạn?',
    title3: 'Điều gì cần được cải thiện để nâng cao chất lượng trải nghiệm cuộc gọi của bạn?',
    title4: 'Điều gì cần được cải thiện để nâng cao chất lượng trải nghiệm cuộc gọi của bạn?',
    title5: '',
    noise: 'Tiếng ồn môi trường',
    reverb: 'Âm thanh vang',
    lossOfSound: 'Mất âm thanh',
    echo: 'Âm bị lặp',
    distortion: 'Âm thanh bị bóp méo',
    interrupt: 'Âm thanh bị ngắt quãng',
    frozen: 'Video bị đứng',
    blur: 'Video mờ',
    poorColor: 'Màu sắc video kém',
    underexposed: 'Video bị tối',
    lag: 'Video bị giật lag',
    notShowing: 'Không hiển thị video',
  },
  Register: {
    Register: 'Đăng ký',
    emailPlaceholder: 'example@domain.com',
    Step1: {
      title: 'Nhập địa chỉ email',
      desc: 'Dùng làm “Tên tài khoản" để đăng nhập ứng dụng Unitz Advisor.',
    },
    Authentication: 'Xác thực',
    AuthenEmailInfo: 'Mở hộp thư để xác thực địa chỉ email',
    GuestMode: 'Khám phá',
    GuestMessage: 'Khám phá ứng dụng mà không cần đăng nhập, bạn sẽ bị hạn chế một số chức năng',
    GuestLimitMessage: 'Tính năng này bị giới hạn cho người dùng thử. Bạn cần đăng ký một tài khoản thật để sử dụng đầy đủ chức năng',
  },
  Favorite: {
    emptyList: 'Chưa có nhà tư vấn yêu thích nào',
    teacherEmptyList: 'Chưa có giáo viên yêu thích nào',
  },
  AdvisorCourse: {
    emptyList: 'Chưa có khóa học mới',
    roomActions: {
      startIn: 'Bắt đầu sau %{count}',
      inProgress: 'Đang diễn ra',
      endIn: 'Kết thúc sau %{count}',
      leave: 'Rời lớp',
      enter: 'Vào lớp',
      close: 'Đóng',
      autoJoin: 'Tự động vào lớp',
    },
    streamActions: {
      pin: 'Ghim',
      unpin: 'Xoá ghim',
      record: 'Thu hình',
    },
    roomStatus: {
      completed: 'Đã kết thúc',
    },
  },
  ReviewCall: {
    title: 'Đánh giá cuộc gọi',
    description: 'Trải nghiệm cuộc gọi của bạn như thế nào?',
    suggest: 'Góp ý cho nhà phát triển',
    advisorReview: 'Đánh giá chuyên gia',
    advisorReviewDesc: 'Đánh giá của bạn dành cho chuyên gia?',
    report: 'Báo cáo',
    textReview: 'Đôi dòng cảm nhận của bạn ...',
    reportTxt: 'Hãy cho chúng tôi biết về vấn đề bạn gặp phải với chuyên gia. Unitz rất tiếc về trải nghiệm không mong muốn này. Báo cáo của bạn sẽ được Unitz đảm bảo giữ bí mật để tránh ảnh hưởng. Mong bạn vẫn luôn ủng hộ Unitz!',
    send: 'Gửi',
    reivew: 'Đánh giá',
  },
  UserNotification: {
    All: 'Tất cả ',
    UnSeen: 'Chưa đọc',
    Seen: 'Đã đọc',
    Delete: 'Xóa',
    Types: {
      completed_call_user: 'Bạn đã hoàn thành một cuộc gọi %{packageName} với %{userLink}',
    },
    notificationEmpty: 'Không có thông báo',
    voice: 'cuộc gọi thoại',
    video: 'cuộc gọi video',
    call: 'cuộc gọi',
  },
  LinkCard: {
    title: 'Liên kết thẻ',
    continue: 'Tiếp tục',
    success: 'Thêm thẻ thành công',
  },
  Voucher: {
    title: 'Voucher quà tặng trị giá %{money}',
    expired: 'Hết hạn %{date}',
    use: 'Dùng ngay',
    voucherLabel: 'Nhập mã voucher',
    voucherContentLabel: 'Vui lòng kiểm tra và nhập mã voucher đã được gửi đến email mà bạn đăng ký với Unitz.',
    codePlaceholder: 'UZ-XXXXX',
    redeem: 'Đổi ngay',
    RedeemStatus: {
      titleSuccess: 'Đổi voucher thành công',
      titleFail: 'Đổi voucher thất bại',
      success: 'Voucher quà tặng trị giá %{money} đã được thêm vào ví thành công.',
      fail: 'Đổi voucher không thành công.',
    },
    goBack: 'Trở về',
    tryAgain: 'Thử lại',
    Status: {
      available: 'khả dụng',
      unavailable: 'không khả dụng',
    },
  },
  Message: {
    repliesCountLabel: {
      one: '%{count} trả lời',
      other: '%{count} trả lời',
      zero: 'Chưa có trả lời',
    },
    repliesCollapseLabel: 'Thu gọn',
    replyActionLabel: 'Trả lời',
    replyingIndicatorLabel: 'đang trả lời ...',
    messageStatus: {
      edited: '(đã sửa)',
      removed: '(đã xoá)',
    },
    tabPaneLabels: {
      rooms: 'Trò chuyện',
      threads: 'Threads',
      mentions: 'mentions',
    },
    messageActions: {
      edit: 'Chỉnh sửa tin nhắn',
      remove: 'Xoá tin nhắn',
      reply: 'Trả lời tin nhắn',
      pin: 'Pin message',
      setAsTopic: 'Set as topic',
      setAsQuestion: 'Set as question',
    },
    newMessageAlertLabel: {
      one: 'Bạn có 1 tin nhắn mới',
      other: 'Bạn có %{count} tin nhắn mới',
    },
    hints: {
      EnterToSend: 'Enter để gửi. Shift + Enter để xuống hàng',
    },
  },
  Conversation: {
    allConversation: 'Tất cả cuộc hội thoại',
    advice: 'Hãy tư vấn cho tôi về khóa học này.',
  },
  ReportIssue: {
    success: 'Đã gửi sự cố cuộc gọi cho Unitz',
    fail: 'Gửi sự cố cuộc gọi cho Unitz thất bại, vui lòng thử lại !',
  },
  ErrorMsg: {
    Default: 'Đã xảy ra lỗi, vui lòng thử lại !',
  },
  AppCalendar: {
    monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
    monthNamesShort: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
    dayNames: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
    dayNamesShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
    today: 'Hôm nay',
  },
  LearnSchedule: {
    goToClass: 'Vào lớp',
    offClass: 'Báo nghỉ',
    title: 'Lịch học',
    detail: 'Chi tiết buổi học',
    upcoming: 'Sắp diễn ra',
    doneCourse: 'Đã diễn ra',
    emptyList: 'Danh sách buổi học trống.',
  },
  RoomChat: {
    Title: 'Phòng chat',
    Actions: {
      Edit: 'Chỉnh sửa tin nhắn',
      Mark: 'Đánh dấu chưa đọc',
      Reply: 'Phản hồi',
      Copy: 'Sao chép nội dung',
      Delete: 'Xoá tin nhắn',
    },
    ReplyToUser: 'Đang trả lời %{userName}',
    SeeComment: 'Xem phản hồi',
    Rooms: 'Phòng chat',
    Threads: 'Threads',
    Mentions: 'Mentions',
    DayOf: 'Buổi %{day}',
    ListLesson: 'Danh sách buổi học',
    Leave: 'Rời lớp học',
    Participant: 'Người tham dự',
    Advisor: 'Giảng viên (%{num})',
    Student: 'Học viên (%{num})',
    Hosts: 'Hosts (%{num})',
    Participants: 'Participants (%{num})',
  },
  SearchCourse: {
    placeHolder: 'Tìm khóa học',
    result: '%{num} khóa học tìm được cho',
    emptyList: 'Tìm khóa học dành cho bạn',
    notResult: 'Không có khóa học nào phù hợp',
  },
  DateFormat: {
    Default: 'DD/MM/YYYY',
    FullDisplay: 'HH:mm:ss - DD/MM/YYYY',
  },
  CurrencyFormat: {
    vnd: 'đ',
  },
  AdvisorScheduleBooking: {
    type: 'Loại cuộc gọi',
    selectPackage: 'Chọn gói cuộc gọi',
    next: 'Tiếp theo',
    SetSchedule: 'Đặt lịch',
    timeLine: 'Thời gian',
    schedulePresent: 'Lịch hẹn sắp tới',
    list: 'Danh sách',
    detail: 'Chi tiết buổi hẹn',
    callWith: 'Cuộc gọi %{service} với',
    time: '%{time} phút',
    timeNote: 'Phòng tư vấn sẽ được mở trước khi đến giờ 5 phút. Bạn lưu ý để có mặt đúng giờ nhé!',
    startCall: 'Bắt đầu cuộc gọi',
    service: 'Dịch vụ cung cấp',
    serviceEmpty: 'Chuyên gia chưa cung cấp gói dịch vụ này, xin vui lòng chọn gói dịch vụ khác',
    scheduleEmpty: 'Chưa có lịch hẹn',
    timeEmpty: 'Chuyên gia chưa cung cấp thời gian để đặt lịch hẹn, vui lòng chọn ngày khác !',
    cancelSchedule: 'Huỷ lịch hẹn',
    cancelDescUser: 'Bạn chỉ có thể huỷ lịch hẹn trước thời gian bắt đầu 1 phút. Sau khi huỷ lịch hẹn tiền sẽ được hoàn lại vào ví Unitz của bạn',
    confirm: 'Xác nhận',
    back: 'Quay lại',
    cancelScheduleSuccess: 'Huỷ lịch hẹn thành công !',
    cancelScheduleFail: 'Huỷ lịch hẹn thất bại, vui lòng thử lại !',
    cancelDescAdvisor: 'Bạn có chắc chắn muốn huỷ lịch hẹn này không? Tiền đã thanh toán sẽ được hoàn vào ví Unitz của bạn.',
    status: {
      confirmed: 'Đã đặt lịch',
      completed: 'Đã hoàn thành',
      missed: 'Bị nhỡ',
      canceled: 'Đã huỷ hẹn',
    },
    upcoming: 'Sắp tới',
    booked: 'Lưu trữ',
    add: 'Thêm',
    reCall: 'Gọi nhà tư vấn',
    reCalling: 'Đang kết nối',
    cancelScheduleInvalid: 'Bạn không thể huỷ hẹn khi cuộc gọi đã bắt đầu.',
  },
  Whiteboard: {
    toolbar: {
      markerTooltip: 'Marker',
      shapeTooltip: 'Shape',
      stickyNoteTooltip: 'Sticky note',
      pictureTooltip: 'Image',
      moreTooltip: 'More',
      moveTooltip: 'Move tool',
      undoTooltip: 'Undo',
      redoTooltip: 'Redo',
      dragTooltip: 'Hand tool',
      hideTooltip: 'Ẩn bảng',
      showTooltip: 'Hiển thị bảng',
      eraseTooltip: 'Xoá',
      eraserTooltip: 'Xoá',
      zoomoutTooltip: 'Phóng to',
      zoominTooltip: 'Thu nhỏ',
      textTooltip: 'Text',
      authorTooltip: 'Show/hide author',
      textPlaceholder: 'Add text',
    },
  },
  CourseDetail: {
    summary: 'Tổng quan',
    detail: 'Thông tin',
    schedule: 'Lịch học',
    note: 'Ghi chú buổi học',
    file: 'Tập tin',
    support: 'Hỗ trợ',
    review: 'Đánh giá',
    timeNote: 'Lớp học sẽ được mở để vào trước khi đến giờ 5 phút. Bạn lưu ý có mặt đúng giờ nhé!',
    coursePresent: 'Khoá học đang diễn ra',
    download: 'Tải về',
    downloadSuccess: 'Tải về thành công %{file}',
    payment: 'Thanh toán',
    refund: 'Hoàn tiền',
    statusLesson: 'Trạng thái buổi học',
    viewcourse: 'Xem khoá học',
    benefits: 'Lợi ích từ khóa học',
    purchase: {
      limited: 'Bạn đã mua {{purchase_session}} buổi. Hiện đang còn {{no_learn_session}} buổi chưa học và sẽ kết thúc vào {{end_time}}.',
      unlimited: 'Bạn đang còn {{no_learn_session}} buổi chưa học và sẽ kết thúc vào {{end_time}}.',
    },
  },
  Audio: {
    connect: 'Đang kết nối âm thanh...',
  },
  AdvisorCalendar: {
    Tab: {
      booking: 'Lịch tư vấn',
      course: 'Lịch dạy học',
    },
    courseEmpty: 'Chưa có lịch dạy học',
    form: {
      fullTime: 'Cả ngày',
      beginTime: 'Bắt đầu',
      endTime: 'Kết thúc',
      delete: 'Xoá khung thời gian',
      message: 'Khung thời gian này đã bị trùng, vui lòng kiểm tra lại !',
      timeValid: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu, vui lòng kiểm tra lại !',
    },
  },
  SEO: {
    Actions: {
      add: 'Tạo SEO Link',
      submit: 'Gửi',
    },
    Form: {
      newSEOLink: 'Tạo mới SEO Link',
      originalPath: 'Đường dẫn gốc',
      SEOPath: 'Đường dẫn SEO',
      siteTitle: 'Tiêu đề trang',
      siteDescription: 'Mô tả trang',
      siteImage: 'Ảnh đại diện cho trang',
      SEOType: 'Loại SEO',
    },
    Table: {
      originPath: 'Link gốc',
      SEOPath: 'Link SEO',
      title: 'Tiêu đề',
      description: 'Mô tả',
      image: 'Ảnh',
      type: 'Loại',
    },
    manageLinks: 'Manage Links',
    linkDetail: 'SEO Link Detail',
    linkEdit: 'SEO Link Edit',
  },
  UserCalendar: {
    Tab: {
      booking: 'Lịch tư vấn',
      course: 'Lịch học',
    },
  },
  RemoteConfig: {
    Booking: {
      Package: 'Gói %{package} - %{time}',
      AdvisorBookingPaid: {
        title: 'Bạn đã nhận được %{amount} cho cuộc gọi %{kind} với %{user}.',
      },
      AdvisorBookingCancel: {
        title: 'Lịch hẹn với %{user} đã bị huỷ.',
      },
      AdvisorBookingCompleted: {
        title: 'Bạn đã hoàn tất lịch hẹn với %{user}.',
      },
      AdvisorBookingConfirmed: {
        title: 'Bạn có lịch hẹn mới với %{user}.',
      },
      AdvisorBookingReminder: {
        title: 'Lịch hẹn với %{user} sẽ bắt đầu sau %{diffMin} phút.',
      },
      UserBookingCancel: {
        title: 'Lịch hẹn với %{advisor} đã bị huỷ.',
      },
      UserBookingCompleted: {
        title: 'Bạn đã hoàn tất lịch hẹn với %{advisor}.',
      },
      UserBookingReminder: {
        title: 'Lịch hẹn với %{advisor} sẽ bắt đầu sau %{diffMin} phút.',
      },
    },
    Course: {
      AdvisorCoursePaid: {
        title: 'Khoá học %{course} đã hoàn tất.',
        body: 'Bạn nhận được %{amount}.',
      },
      AdvisorCoursePurchase: {
        title: '%{user} đã mua khoá học %{course}.',
      },
      AdvisorCourseRefund: {
        title: 'Thông báo huỷ khoá học',
        body: '%{user} huỷ đăng ký khoá học %{course}.',
      },
      Purchase: {
        session: 'Thanh toán %{amount} cho %{per_amount} buổi',
        full_session: 'Trọn gói: %{amount}',
        per_session: '%{session} buổi',
        full_session_txt: 'Trọn gói',
        sessionOccurence: {
          unlimited: 'Không giới hạn buổi học',
          limited: 'Có giới hạn buổi học',
        },
        sessionCount: {
          zero: '0 buổi',
          one: '1 buổi',
          other: '%{count} buổi',
        },
      },
      UserCoursePurchase: {
        title: 'Bạn đã mua khoá học %{course}.',
      },
      UserCourseRefund: {
        title: 'Tiền hoàn khoá học %{course}.',
        body: 'Unitz đã hoàn trả lại cho bạn %{amount} vào ví.',
      },
      AdvisorCoursePostpone: {
        title: 'Thông báo dời lịch khai giảng',
        body: 'Khoá học %{course} của bạn đã được dời lịch khai giảng qua ngày %{start_at}',
      },
      UserCoursePostpone: {
        title: 'Thông báo dời lịch khai giảng',
        body: 'Khoá học %{course} của bạn đã được dời lịch khai giảng qua ngày %{start_at}',
      },
    },
    Room: {
      UserRoomRefund: {
        title: 'Lớp học %{course} không diễn ra.',
        body: 'Unitz đã hoàn trả lại cho bạn %{amount} vào ví.',
      },
      UserRoomConnect: {
        title: 'Lớp học %{course}',
        body: 'Đã bắt đầu - Thời lượng %{duration}.',
      },
      UserRoomReminder: {
        title: 'Lớp học %{course}',
        body: 'Sẽ bắt đầu sau %{diffMin} phút.',
      },
      UserRoomReschedule: {
        title: 'Thay đổi giờ học lớp %{course}',
        body: 'Sẽ bắt đầu vào %{time}',
      },
      AdvisorRoomConnect: {
        title: 'Lớp học %{course}',
        body: 'Đã bắt đầu - Thời lượng %{duration}.',
      },
      AdvisorRoomPaid: {
        title: 'Lớp học %{course} đã hoàn tất',
        body: 'Bạn nhận được %{amount}',
      },
      AdvisorRoomRefund: {
        title: 'Lớp học %{course} không diễn ra.',
        body: 'Bạn không nhận được học phí từ lớp học.',
      },
      AdvisorRoomReminder: {
        title: 'Lớp học %{course}',
        body: 'Sẽ bắt đầu sau %{diffMin} phút.',
      },
      AdvisorRoomReschedule: {
        title: 'Thay đổi giờ học lớp %{course}',
        body: 'Sẽ bắt đầu vào %{time}',
      },
      student: {
        zero: 'Chưa có học viên',
        one: '1 học viên',
        other: '%{count} học viên',
      },
    },
    DemoCourse: {
      AdvisorDemoCourseRequest: {
        title: 'Yêu cầu buổi học thử',
        body: 'Bạn đã nhận được yêu cầu buổi học thử từ %{user}',
      },
      UserDemoCourseConfirm: {
        title: 'Yêu cầu buổi học thử được đồng ý',
        body: 'Yêu cầu học thử khoá học %{course} của bạn đã được đồng ý.',
      },
      UserDemoCourseReject: {
        title: 'Yêu cầu buổi học thử đã bị từ chối',
        body: 'Yêu cầu học thử khoá học %{course} của bạn đã bị từ chối.',
      },
    },
    TrialCourse: {
      AdvisorTrialCourseRequest: {
        title: 'Yêu cầu buổi học thử',
        body: 'Bạn đã nhận được yêu cầu buổi học thử từ %{user}',
      },
      UserTrialCourseConfirm: {
        title: 'Yêu cầu buổi học thử được đồng ý',
        body: 'Yêu cầu học thử khoá học %{course} của bạn đã được đồng ý.',
      },
      UserTrialCourseReject: {
        title: 'Yêu cầu buổi học thử đã bị từ chối',
        body: 'Yêu cầu học thử khoá học %{course} của bạn đã bị từ chối.',
      },
    },
    Purchase: {
      UserPurchaseRemind: {
        title: 'Nhắc nhở thanh toán khoá học',
        body: 'Đã sắp đến kỳ thanh toán mới cho khóa học %{course} của bạn. Hãy thanh toán kỳ mới để khóa học không bị ngắt quãng nhé!',
      },
    },
    Chat: {
      UserChatReminder: {
        title: 'Bạn có tin nhắn mới từ giáo viên %{advisor}',
        body: 'Xin vui lòng mở trình duyệt trên máy tính hoặc điện thoại để xem tin nhắn. Chúng tôi sẽ hỗ trợ sớm nhất thông qua app.',
      },
      AdvisorChatReminder: {
        title: 'Bạn có tin nhắn mới từ học viên %{user}',
        body: 'Xin vui lòng mở trình duyệt trên máy tính hoặc điện thoại để xem tin nhắn. Chúng tôi sẽ hỗ trợ sớm nhất thông qua app.',
      },
    },
    Email: {
      user_contacts: [
        {
          title: 'Cộng đồng dành cho chuyên gia',
          name: 'UNITZ COMMUNITY',
          url: 'https://www.facebook.com/groups/unitz.community',
        },
        {
          title: 'Trang Facebook',
          name: 'UNITZ APP',
          url: 'https://www.facebook.com/UnitzApp',
        },
        {
          title: 'Zalo',
          name: 'Công Ty TNHH UNITZ',
          url: 'https://zalo.me/2510053737522180594',
        },
        {
          title: 'Email',
          name: 'support@unitz.app',
          url: 'mailto:support@unitz.app',
        },
        {
          title: 'Website',
          name: 'https://unitz.app',
          url: 'https://unitz.app',
        },
        {
          title: 'Số điện thoại',
          name: '0856 507 095 (Ms. Trang)',
        },
      ],
      advisor_contacts: [
        {
          title: 'Cộng đồng dành cho chuyên gia',
          name: 'UNITZ COMMUNITY',
          url: 'https://www.facebook.com/groups/unitz.community',
        },
        {
          title: 'Trang Facebook',
          name: 'UNITZ APP',
          url: 'https://www.facebook.com/UnitzApp',
        },
        {
          title: 'Zalo',
          name: 'Công Ty TNHH UNITZ',
          url: 'https://zalo.me/2510053737522180594',
        },
        {
          title: 'Email',
          name: 'supplier@unitz.app',
          url: 'mailto:supplier@unitz.app',
        },
        {
          title: 'Website',
          name: 'https://advisor.unitz.app',
          url: 'https://advisor.unitz.app',
        },
        {
          title: 'Số điện thoại',
          name: '0856 507 095 (Ms. Trang)',
        },
      ],
      advertisementForm: '- %{courseName} được giảng dạy bởi %{teacher}. Khai giảng ngày %{startAt} vào lúc %{startTime} giờ Hà Nội ',
    },
    Call: {
      Package: 'Gói %{package}',
      AdvisorCallPaid: {
        title: 'Bạn đã nhận được %{amount} cho cuộc gọi %{kind} với %{user}.',
      },
      AdvisorCallReminder: {
        title: 'Bạn vừa nhận được thanh toán cho cuộc gọi với người dùng %{user}, hãy bật ứng dụng và sẵn sàng cho cuộc gọi.',
      },
    },
    RATCourse: {
      AdvisorRATCourseRequest: {
        title: 'Yêu cầu khung giờ học khác',
        body: 'Bạn đã nhận được yêu cầu khung giờ học khác từ %{user}',
      },
      UserRATCourseRequest: {
        title: 'Yêu cầu khung giờ học khác',
        body: 'Yêu cầu khung giờ học khác khoá học %{course} của bạn đã được gửi đi.',
      },
      UserRATCourseConfirm: {
        title: 'Yêu cầu khung giờ học khác được đồng ý',
        body: 'Yêu cầu khung giờ học khác khoá học %{course} của bạn đã được đồng ý.',
      },
      UserRATCourseReject: {
        title: 'Yêu cầu khung giờ học khác đã bị từ chối',
        body: 'Yêu cầu khung giờ học khác khoá học %{course} của bạn đã bị từ chối.',
      },
    },
  },
  DemoCourse: {
    Accept: 'Chấp nhận',
    Reject: 'Từ chối',
  },
  RequestFreeTrialLesson: {
    categoryListing: 'Chọn 1 lĩnh vực bạn muốn học thử',
    targetUser: 'Khóa học này dành cho ai',
    learningLevel: 'Cấp độ học tập',
    requestTime: 'Thời gian học mong muốn',
    nextBtn: 'Kế tiếp',
    registrationForm: 'Thông tin ghi danh',
    registration: {
      studentInfo: 'Thông tin học viên',
      studentFirstName: 'Họ',
      studentLastName: 'Tên',
      studentBirthday: 'Ngày sinh',
      studentNote: 'Ghi chú',
      accountInfo: 'Thông tin tài khoản',
      accountName: 'Họ tên',
      accountEmail: 'Email',
      accountPhone: 'Số điện thoại',
      accountPassword: 'Mật khẩu',
      accountRePassword: 'Nhập lại mật khẩu',
      agreeWith: 'Tôi đồng ý với ',
      termAndCondition: 'điều khoản và điều kiện',
      and: 'và',
      requestBtn: 'Đăng ký học thử miễn phí',
      privacy: 'chính sách bảo mật',
    },
    validation: {
      studentFirstName: 'Vui lòng nhập Họ',
      studentLastName: 'Vui lòng nhập Tên',
      studentBirthday: 'Vui lòng nhập Ngày sinh',
      studentNote: 'Vui lòng nhập Ghi chú',
      accountName: 'Vui lòng nhập Họ tên',
      accountEmail: 'Vui lòng nhập Email',
      accountPhone: 'Vui lòng nhập Số điện thoại',
      accountPassword: 'Vui lòng nhập Mật khẩu',
      accountRePassword: 'Vui lòng nhập lại mật khẩu',
    },
    verifyEmail: 'Xác minh địa chỉ email của bạn',
    verify: {
      title: 'The verification code has been sent to your\nemail address.',
      actionTxt: 'Verification code has been sent',
      description: "If you haven't received the e-mail, please check your spam folder.\n" + "      If you are sure you haven't received an e-mail, use the form below to get the e-mail sent again.",
    },
  },
  EducationTabbar: {
    Home: 'Trang chủ',
    Category: 'Khám phá',
    Course: 'Khoá hoc',
    Account: 'Tài khoản',
    Calendar: 'Lịch',
  },
  Explorer: {
    courseEmpty: 'Chưa có khóa học nào',
    filter: 'Bộ lọc khoá học',
    reset: 'Đặt lại',
    rating: 'Đánh giá tối thiểu',
    ratingDesc: 'Mức đánh giá',
    tuition: 'Học phí',
    applyFilter: 'Áp dụng',
    listCourse: 'Danh sách khóa học',
  },
  EducationCourse: {
    myCourse: 'Khoá học của tôi',
    featureCourse: 'Khoá học nổi bật',
    viewCourse: 'Khóa học đang được quan tâm',
    latestCourse: 'Khóa học mới nhất',
    favorite: 'Yêu thích',
    Sort: 'Sắp xếp',
    SortBy: {
      All: 'Tất cả',
      Upcomming: 'Sắp tới',
      OnProgress: 'Đang diễn ra',
      Finished: 'Đã kết thúc',
    },
  },
  TeacherProfile: {
    Category: 'Danh mục giảng dạy',
    Chat: 'Chat ngay',
  },
  CourseInfo: {
    All: 'Tất cả',
    studySchedule: 'Lịch học',
    registerTrialCourse: 'Đăng ký học thử',
    detail: 'Xem chi tiết',
    enrollNow: 'Đăng ký',
    retrialCourseSuccess: 'Yêu cầu học thử đã được gửi đi thành công',
    retrialCourseSuccessMessage: 'Yêu cầu học thử của bạn đã được \n' + '    gửi đi thành công. Giáo viên sẽ sớm phản hồi \n' + '    yêu cầu học thử từ bạn',
    retrialCourseError: 'Yêu cầu học thử không thành công',
    retrialCourseErrorMessage: 'Yêu cầu học thử của bạn thất bại, vui lòng thử lại',
    goHome: 'Màn hình chính',
    myCalendar: 'Lịch của tôi',
  },
  ProfileStudent: {
    calendar: 'Lịch học',
    subCalendar: 'Thời gian lớp học sắp diễn ra',
    message: 'Tin nhắn',
    subMessage: 'Tin nhắn với giáo viên',
    notes: 'Thông báo từ hệ thống',
  },
  MessageChat: {
    actions: {
      title: 'Tác vụ',
      Edit: 'Chỉnh sửa',
      Remove: 'Xoá',
    },
  },
  Subscriber: {
    Modal: {
      title: 'Bạn đã tìm thấy khóa học phù hợp với bạn chưa?',
      sub_title: 'Hãy để lại thông tin bên dưới. Unitz sẽ liên hệ với bạn để tư vấn cho bạn khóa học phù hợp nhất',
      your_message: 'Bạn đang tìm kiếm khóa học nào?',
    },
    Button: {
      title: 'Gửi thông tin',
    },
    Notification: {
      success: 'Bạn đã đăng ký thành công.',
    },
  },
  AboutUs: {
    message_success: 'Cảm ơn bạn đã liên lạc với chúng tôi! Chúng tôi sẽ liên hệ lại với bạn trong vòng 3 ngày làm việc.',
  },
  RoomCall: {
    endCall: 'Kết thúc buổi học',
    timeLeft: 'Thời gian buổi học của bạn vẫn còn lại',
    continueCall: 'Bạn vẫn muốn kết thúc buổi học?',
    chatDelay: 'Chức năng hoạt động tốt nhất trên trình duyệt, chúng tôi sẽ triển khai sớm tính năng Chat vào app.',
    okText: 'Đồng ý',
    chatWithTeacher: 'Chat với giáo viên ngay',
    waitingAdvisorNote: 'Bạn đang chờ kết nối với chuyên gia tư vấn %{advisor}. Đây là %{package}.\n' + '\n' + '    3 phút đầu tiên sẽ được miễn phí, sau 3 phút cuộc gọi sẽ bắt đầu tính phí. Nếu Chuyên gia không vào, bạn sẽ được hoàn lại tiền cho gói cuộc gọi này.',
    waitingUserNote: 'Bạn đang chờ kết nối với khách hàng của mình. Đây là %{package}.\n' + '\n' + '    Khách hàng của bạn sẽ được miễn phí trong ba phút đầu tiên, sau 3 phút cuộc gọi sẽ bắt đầu được tính phí. Bạn cần tham gia cuộc gọi nhiều hơn ba phút và ở lại lâu hơn người dùng nhận để được tiền cho cuộc gọi này.',
  },
  TimezoneSetting: {
    notfound: 'Không tìm thấy kết quả tìm kiếm.',
    textSearch: 'Nhập để tìm kiếm...',
  },
  RequestPermission: {
    Allow: 'Cho phép',
    Later: 'Để sau',
    Notification: {
      header: 'Unitz muốn gửi thông báo đến ban:',
      content: {
        require_1: 'Đảm bảo rằng bạn không bỏ lỡ bất kỳ cuộc gọi nào',
        require_2: 'Nhận thông báo từ các chuyên gia của bạn ngay lập tức',
        require_3: 'Nhanh chóng nhận thông tin thanh toán và hoàn tiền',
      },
      message: 'Thông báo có thể bao gồm cảnh báo, âm thanh và huy hiệu biểu tượng. Điều này có thể được định cấu hình trong Cài đặt',
    },
    Camera: {
      header: 'Vui lòng cho phép các ứng dụng truy cập Máy ảnh của bạn để:',
      content: {
        require_1: 'Gặp gỡ chuyên gia của bạn trong cuộc gọi điện video',
        require_2: 'Đảm bảo cuộc trò chuyện với trải nghiệm tốt hơn',
        require_3: 'Hãy chắc chắn rằng Chuyên gia của bạn có thể gặp bạn để đưa ra lời khuyên chính xác nhất nếu bạn đang kinh doanh dịch vụ tư vấn làm đẹp',
      },
    },
    Voice: {
      header: 'Vui lòng cho phép các ứng dụng truy cập vào Micrô của bạn để:',
      content: {
        require_1: 'Đảm bảo rằng Chuyên gia của bạn có thể nghe rõ bạn ',
        require_2: 'Giúp cuộc trò chuyện trôi chảy hơn',
        require_3: 'Đảm bảo bạn đang có trải nghiệm tốt nhất',
      },
    },
  },
  RequestApproval: {
    note: 'Vui lòng đợi giây lát, chúng tôi sẽ thiết lập tài khoản tư vấn cho bạn...',
    approvalSuccess: 'Đã thiết lập xong tài khoản tư vấn!',
  },
  AdvisorOnboarding: {
    category: 'Lĩnh vực',
    basicInfo: 'Điền thông tin cơ bản',
    basicInfo_2: 'Cập nhật thông tin cá nhân',
    infoDescription: 'Thông tin dưới đây mang tính chất công khai, sẽ hiển thị với khách hàng của bạn.',
    infoDescription_2: 'Hoàn thiện hồ sơ cá nhân bằng cách cập nhật thông tin liên quan đến kinh nghiệm, bằng cấp, và chứng nhận',
    intro_text_example: 'Ví dụ: Trên 10 năm kinh nghiệm trong lĩnh vực thiền định, truyền tải năng lượng cuộc sống.',
    detail_text_example: 'Ví dụ: Tôi có hơn 10 năm kinh nghiệm trong thiền và các phương pháp để có sức khỏe tinh thần tốt hơn. Nếu bạn mong muốn được chia sẻ những bí kíp ...',
    experience_example: 'Ví dụ: Trên 10 năm kinh nghiệm trong lĩnh vực tư vấn tâm lý.',
    certificates_example: 'Ví dụ: Tốt nghiệp thạc sĩ tâm lý tại đại học sư phạm TP. HCM.',
    education_example: 'Ví dụ: Tốt nghiệp thạc sĩ tâm lý tại đại học sư phạm TP. HCM.',
    setupPackage: 'Thiết lập cuộc gọi tư vấn',
    setupPackageDesc: 'Cài đặt thông tin giá cả,\nthời gian cuộc gọi theo từng gói.',
    setupPrice: 'Gói giá cho cuộc gọi %{package}',
    createAccount: 'Tạo tài khoản ngay và bắt đầu kết nối với hàng ngàn khách hàng đang chờ bạn tư vấn. ',
  },
  ZoomToolMenu: {
    Home: 'Trang chủ',
    ProgramsTemplates: 'Tạo chương trình',
    Programs: 'Chương trình',
    CourseTemplates: 'Tạo khoá học',
    Courses: 'Khoá học',
    Requests: 'Yêu cầu',
    Admins: 'Quản trị viên',
    Teachers: 'Giáo viên',
    Students: 'Sinh viên',
    Calendar: 'Lịch',
    MyAccount: 'Tài khoản của tôi',
    Notifications: 'Thông báo',
    Settings: 'Cài đặt',
  },

  ZoomToolOrganization: {
    organization: 'Organization',
    create: 'Create organization',
    settings: 'Organization settings and subscriptions',
  },

  ZoomToolDashboard: {
    ongoing: 'Ongoing',
    upcoming: 'Upcoming',
    finished: 'Finished',
    details: 'Details',
    number_of_students: 'Total number of students',
    number_of_teachers: 'Total number of teachers',
    number_of_courses: 'Total number of courses',
    alert: {
      message1: 'Welcome to Unitz',
      message2: 'This free demo of Unitz expires in 13 days. To continue, get your membership today!',
    },
    course: {
      zero: '0 courses',
      one: '1 course',
      other: '%{count} courses',
    },
    student: {
      zero: '0 students',
      one: '1 student',
      other: '%{count} students',
    },
    attendance: 'Attendance',
    student_performance: 'Student Performance',
    requests: 'Requests',
    empty_request: 'No requests yet',
    total: 'Total',
  },
  ZoomToolProgram: {
    title: 'PROGRAMS',
    empty_text1: 'Let’s begin here! A program is made up of multiple courses.',
    empty_text2: 'Start by adding a new program.',
    empty_text3: 'Don’t want to group multiple courses into programs? You can skip this concept and go to Settings to hide program.',
    add_form_title: 'CREATE YOUR PROGRAM',
    edit_form_title: 'EDIT PROGRAM',
    success_title: 'The program has been created successfully',
    success_sub_title: 'You can view, edit and use this program in your',
    course_templates: 'Programs',
    course: 'Course',
    Detail: {
      about_title: 'About this program',
      courses: 'Courses',
    },
    Form: {
      name: 'Program name',
      name_input_placeholder: 'Enter Programe name (1 - 100 chars)',
      courses: 'Courses',
      description: 'Description',
      description_input_placeholder: 'Enter the description',
      add_course: 'Add Course',
      course: {
        zero: '0 courses',
        one: '1 course',
        other: '%{count} courses',
      },
      selected_course_template: 'Selected course template',
      search_label1: 'Existing course templates',
      search_label2: 'Search',
      search_placeholder: 'Enter course template name',
    },
    Action: {
      add_program: 'Add program',
      add_program_from_template: 'Add program from template',
      add_course_template: 'Add a course',
      start: 'Start',
      use: 'Use',
      remove: 'Remove',
      edit: 'Edit',
      start_program_now: 'Start this program now',
    },
    selected_course_template: 'Selected course template',
    create_course_state_title: 'Start by adding a new course template',
    empty_course_state_title: 'No course templates yet.',
  },
  ZoomToolProgramTemplate: {
    title: 'PROGRAM TEMPLATES',
    empty_text1: 'Let’s begin here! A program is made up of multiple courses.',
    empty_text2: 'Start by adding a new program template.',
    empty_text3: 'Don’t want to group multiple courses into programs? You can skip this concept and go to Settings to hide program and program templates.',
    add_form_title: 'CREATE YOUR PROGRAM TEMPLATE',
    edit_form_title: 'EDIT PROGRAM TEMPLATE',
    success_title: 'The program template has been created successfully',
    success_sub_title: 'You can view, edit and use this template in your',
    course_templates: 'Program Templates',
    course: 'Course',
    Detail: {
      about_title: 'About this program',
      courses: 'Courses',
      course_detail: 'Course detail',
    },
    Form: {
      name: 'Program template name',
      courses: 'Courses',
      description: 'Description',
      add_course: 'Add Course',
      course: {
        zero: '0 courses',
        one: '1 course',
        other: '%{count} courses',
      },
      selected_course_template: 'Selected course template',
      search_label1: 'Existing course templates',
      search_label2: 'Search',
      search_placeholder: 'Enter course template name',
    },
    Action: {
      add_course_template: 'Add a course template',
      start: 'Start',
      use: 'Use',
      remove: 'Remove',
      edit: 'Edit',
      start_program_now: 'Start this program now',
    },
    selected_course_template: 'Selected course template',
    create_course_state_title: 'Start by adding a new course template',
    empty_course_state_title: 'No course templates yet.',
  },
  ZoomToolTeacher: {
    empty_text: 'Start by adding a teacher',
    report_title: 'Teacher report',
    send_mail_title: 'Send Teacher Report',
    edit_teacher_title: 'Edit teacher',
    Table: {
      title: 'TEACHERS',
      teacher: 'Teacher',
      assistant: 'Teaching assistant',
      type: 'Type',
      course_in_progress: 'Courses in progress',
      lesson_in_progress: 'Lesson in progress',
      attendance: 'Absences/Tardies/Early dismissal',
      status: 'Status',
      add_to_course: 'Add to course',
      active: 'Active',
      inactive: 'Inactive',
    },
    Attendance: {
      present: 'Present',
      absent: 'Absent',
      late: 'Late',
      early_dismissed: 'Early Dismissed',
    },
    Action: {
      view_report: 'View Report',
    },
  },
  ZoomToolStudent: {
    empty_text: 'Start by adding a student',
    add_student_title: 'Add Student',
    edit_student_title: 'Edit student',
    report_title: 'Student report',
    send_mail_title: 'Send Student Report',
    Table: {
      title: 'STUDENTS',
      student: 'Student',
    },
    Action: {
      add_student: 'Add a student',
    },
  },
  ZoomToolAccount: {
    title: 'ACCOUNTS',
    create_organization: 'CREATE NEW ORGANIZATION',
    edit_organization: 'ORGANIZATION INFORMATION',
    Form: {
      organization_id: 'Organization ID',
      organization_name: 'Organization name',
      logo: 'Logo',
      add_logo: 'Add logo here',
    },
    ListTable: {
      name: 'Name',
      actions: 'Actions',
    },
    Action: {
      save: 'Save',
      new_account: 'New account',
      create_organization: 'CREATE ORGANIZATION',
    },
  },
  ZoomToolCourse: {
    title: 'COURSES',
    add_form_title: 'CREATE YOUR COURSE',
    edit_form_title: 'EDIT COURSE',
    empty_text1: 'Start by adding a new course.',
    empty_text2: `You don't have a course yet. Please click the "Add course" to create more course.`,
    success_title: 'You have successfully started the course:',
    success_sub_title: '',
    course_type: 'Course type',
    minute_per_lesson: '%{minute} minutes / lesson',
    name: 'Name',
    UseTemplate: {
      title: 'START THE COURSE',
      choose_template_title: 'CHOOSE FROM A COURSE TEMPLATE',
    },
    teacher: 'TEACHERS',
    Form: {
      search_placeholder: 'Search the course name',
      name: 'Course name',
      teacher: 'Teachers',
      student: 'Students',
      duration: 'Duration',
      course_type: 'Course Type',
      start_date_placeholder: 'Please select',
      time: 'Time',
      online: 'Online',
      offline: 'Offline',
      minutes: 'mins',
      session_unit: '/ a session',
      select_time: 'Select time',
      add_lesson_time_manually: 'Add lesson time manually',
    },
    Table: {
      name: 'Course Name',
      teacher: 'Teacher',
      student: 'Student',
      student_number: 'Student number',
      email: 'Email',
      type: 'Type',
      startDate: 'Start date',
      lesson_progress: 'Lesson Progress',
      actions: 'Actions',
    },
    Action: {
      add_course_from_template: 'Add course from templates',
      add_course: 'Add course',
      add_more_schedule: 'Add more schedule',
      add_teacher: 'Add teacher',
      add_assistant: 'Add teaching assistant',
      add_student: 'Add student',
      add_a_teacher: 'Add a teacher',
      add_a_student: 'Add new student',
      next: 'Next',
      create_course_from_scratch: 'Create a new course from scratch',
      use_this_template: 'Use this template',
      back: 'Back',
      chat_room: 'Chat room',
      view_course: 'View course',
      edit_course: 'Edit course',
      view_report: 'View report',
      edit_student: 'Edit Students Result',
      edit_attendance: 'Edit Attendance',
      edit_teachers: 'Edit Teachers',
      done: 'Done',
      enroll_student: 'Enroll students to course',
      manage_all_courses: 'Manage all courses',
      join_room: 'Go to class',
    },
  },
  ZoomToolCourseTemplate: {
    title: 'COURSE TEMPLATES',
    add_form_title: 'CREATE YOUR COURSE TEMPLATE',
    edit_form_title: 'EDIT COURSE TEMPLATE',
    empty_text1: 'Start by adding a new course template.',
    empty_text2: `You don't have a course template yet. Please click the "Add template" to create more course templates.`,
    success_title: 'The course template has been created successfully',
    success_sub_title: 'You can view, edit and start this course in your',
    course_templates: 'Course Templates',
    lesson: 'Lesson',
    confirm_edit_title: 'You have unsaved changes',
    confirm_edit_description: 'Do you want to save this course template as a draft?',
    confirm_delete_title: 'Delete the template',
    confirm_delete_description: 'Do you want to delete this course template? Your changes will be discarded.',
    Detail: {
      about_title: 'About this course',
      materials: 'Marterials',
      lessons: 'Lessons',
      see_more: 'See more',
      see_less: 'See less',
    },
    Action: {
      add_template: 'Add template',
      start_course: 'Start course',
      view: 'View',
      save: 'Save',
      delete: 'Delete',
      edit: 'Edit',
      back: 'Back',
      yes: 'Yes',
      cancel: 'Cancel',
      save_as_draft: 'Save as Draft',
      manage_all_templates: 'Manage all templates',
      start_this_course_now: 'Start this course now',
    },
    Form: {
      name: 'Course template name',
      lessons: 'Lessons',
      cover: 'Cover',
      cover_placeholder: 'Add a photo (16x9)',
      cover_hint: 'Only JPG/ PNG/ GIF/ BMP is supported',
      description: 'Description',
      material: 'Materials',
      material_hint: 'Only the following types are supported: *.doc, *.docx, *.pdf, *.pptx, *.mp3, *.jpeg, *.png, *.jpg',
      url_placeholder: 'Add YouTube/URL Link',
      add_link: 'Add URL link',
      done: 'Done',
      cancel: 'Cancel',
      add_lesson: 'Add Lesson',
      add_multi_lesson: 'Add Multiple Lessons',
      lesson: {
        zero: '0 lessons',
        one: '1 lesson',
        other: '%{count} lessons',
      },
    },
  },
  ZoomToolMember: {
    title: 'USERS AND ACCESS',
    add_user_button: 'Add a new User',
    start_date: 'Start date',
    empty_course_state_title: 'No course yet',
    Form: {
      search_label: 'Search',
      search_placeholder: 'Name or Email address',
      role_label: 'Role',
      role_placeholder: 'Select role',
      status_label: 'Status',
      status_placeholder: 'Select status',
      clear_all_button: 'Clear all',
      send_to_label: 'Send to',
      send_to_me: 'Send to me',
    },
    Add_Form: {
      form_name: 'Add User',
      first_name_label: 'First name',
      first_name_placeholder: 'First name',
      last_name_label: 'Last name',
      last_name_placeholder: 'Last name',
      date_of_birth_label: 'Date of birth',
      date_of_birth_placeholder: 'Date of birth',
      email_label: 'Email',
      email_placeholder: 'Email',
      phone_label: 'Phone number',
      phone_placeholder: 'Phone number',
      photo_label: 'Photo',
      note_label: 'Note',
      note_placeholder: 'Note',
      roles_label: 'Roles',
      Action: {
        add_user: 'Add User',
        cancel: 'Cancel',
        see_permissions: 'See Permissions',
        hide_permissions: 'Hide Permissions',
      },
      Notification: {
        add_success: 'Add member successfully',
        add_error: 'Somethings went wrong. Please check again your information!',
      },
      place_of_birth_label: 'Place of birth',
      place_of_birth_placeholder: 'Place of birth',
    },
    Edit_Form: {
      form_name: 'Edit User',
      Action: {
        edit_user: 'Edit User',
        cancel: 'Cancel',
      },
      Notification: {
        edit_success: 'Edit member successfully',
      },
    },
    Table: {
      number_order: 'No. ',
      full_name: 'Full name',
      phone_number: 'Phone',
      email: 'Email',
      role: 'Role',
      status: {
        col_name: 'Status',
        type: {
          active: 'Active',
          disabled: 'Disabled',
        },
      },
      action: 'Action',
      Action: {
        edit: 'Edit',
      },
    },
    Action: {
      export_to_pdf: 'Export to pdf',
      send_email: 'Send email',
      back: 'Back',
      add_email: 'Add email',
      cancel: 'Cancel',
      send: 'Send',
    },
    Program: {
      ongoing: 'Program Ongoing',
      upcoming: 'Program Upcoming',
      finished: 'Program Finished',
      program: {
        zero: '0 programs',
        one: '1 program',
        other: '%{count} programs',
      },
    },
    Course: {
      ongoing: 'Course Ongoing',
      upcoming: 'Course Upcoming',
      finished: 'Course Finished',
      course: {
        zero: '0 courses',
        one: '1 course',
        other: '%{count} courses',
      },
    },
  },
  ZoomToolNotification: {
    title: 'NOTIFICATIONS',
    empty_text: 'There are no notifications to display yet',
  },
  ZoomToolCalendar: {
    empty_text: 'There are no notifications to display yet',
    Form: {
      viewBy: 'View by',
      search: 'Search',
      status: 'Status',
      program: 'Program',
    },
    roomActions: {
      modals: {
        cancelTitle: 'Leave the class',
        cancelText: 'Are you sure you want to leave?',
        form: {
          lessonName: 'Lesson name',
          courseName: 'Course name',
          dateTime: 'Datetime',
          currentTime: 'Current time',
          newTime: 'New time',
          applyFor: 'Apply for',
          checkOptions: {
            option1: 'This lesson',
            option2: 'This and following lessons',
            option3: 'All lessons',
          },
          manageTeachers: 'Manage teachers',
          reason: 'Reason',
        },
      },
      buttons: {
        confirmCancel: 'Confirm cancelling lesson',
        confirmReschedule: 'Confirm rescheduling lesson',
        confirmSubstitutes: 'Confirm substitutes',
      },
      cancel: 'Cancel lesson',
      reschedule: 'Reschedule lesson',
      manageSubstitutes: 'Manage substitutes',
    },
    Action: {
      reset: 'Reset',
      save: 'Save Presets',
    },
  },
  ZoomToolMyAccount: {
    title: 'My Account',
    edit_information: 'Edit your information',
    dob_label: 'Date of birth',
    first_name_label: 'First name',
    last_name_label: 'Last name',
    change_password: 'Change password',
    timezone: 'Timezone',
    region: 'Region',
    calendar: 'Calendar',
    font_size: 'Font size',
    calendar_label: 'Change calendar',
    timezone_label: 'Set your timezone',
    font_size_label: 'Change font size',
    language_note: 'Would you like to change the language of the text you see on Unitz Biz?',
    timezone_note: 'When you log in to Unitz, this will be the timezone you see. This will not affect the timezone other people sees on the platform.',
    region_note: 'This will be the region you see. This will not affect the region other people sees on the platform.',
    calendar_note: 'When you log in to Unitz, this will be the calendar you see. This will not affect the calendar other people sees on the platform.',
    font_size_note: 'When you log in to Unitz, this will be the font size you see. This will not affect the font size other people sees on the platform.',
    font_size_unit: 'pt (points)',
    region_hint: 'This will be the region you see. This will not affect the region other people sees on the platform.',
  },
  ZoomToolSettings: {
    title: 'SETTINGS',
    organization_name: 'Organization name',
    company_logo: 'Company logo',
    class_time_rules: {
      label: 'Class time rules',
      tardy: 'It will be counted as tardy when the following class time has passed:',
      early: 'It will be counted as early leave when leaving the class before',
      minute: 'minute',
      minutes: 'Minutes',
    },
    supported_materials: 'Supported materials',
    lessons_materials: {
      label: 'Allow access to lessons materials',
      option1: 'Up to current lesson only',
      option1_1: 'Allow teacher to manually publish lessons',
      option2: 'Only 1 lesson in advance',
      option2_2: 'Allow teacher to manually publish lessons',
      option3: 'All lessons',
    },
    program: {
      label: 'Skip program',
      enabled: 'Enabled',
      disabled: 'Disabled',
      note: 'Program is made up of multiple courses. I want to use Program in our system to wrap Courses into Program',
    },
    approval: {
      label: 'Allow automatic approval',
      option1: 'Automatically approve class cancellation lesson requests',
      option2: 'Automatically approve reschedule lesson requests',
    },
    evaluation: {
      label: 'Student Evaluation Per Single Lesson',
      option1: 'Student Classification',
      option2: 'Student Activeness during the Lesson',
      option3: 'Student Classification',
    },
    grading_system: {
      title: 'Student Evaluation Per Course',
      label: 'Evaluation Per Course',
      note: 'Would you like to change the grading system?',
    },
  },
};
