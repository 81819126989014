import React from 'react';
import { Dropdown, Menu } from 'antd';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const WorkBenchHeaderLayout = () => {
  return (
    <DIV className="WorkBenchHeaderLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="flex items-center justify-between bg-white500">
            <div className="text-base md:text-xl font-semibold text-main pr-5">{ctx.get('sectionData.shortText')}</div>
            <Dropdown
              trigger="click"
              autoAdjustOverflow={false}
              placement="bottomRight"
              destroyPopupOnHide
              overlayClassName="rounded-lg overflow-hidden shadow-xl"
              overlay={
                <Menu>
                  {ctx.get(`sectionState.sectionState.${ctx.get('parentSectionName')}`) === 'hide' && (
                    <Menu.Item
                      onClick={() => {
                        ctx.apply('sectionState.setState', ctx.get('parentSectionName'), 'show');
                      }}
                    >
                      <div className="flex items-center justify-start space-x-2">
                        <div>
                          {gstyles.icons({
                            name: 'eye-filled',
                            size: 24,
                            fill: gstyles.colors.main,
                          })}
                        </div>
                        <div className="text-base font-normal text-main">{ctx.apply('i18n.t', 'Show')}</div>
                      </div>
                    </Menu.Item>
                  )}

                  {ctx.get(`sectionState.sectionState.${ctx.get('parentSectionName')}`) !== 'hide' && (
                    <Menu.Item
                      onClick={() => {
                        ctx.apply('sectionState.setState', ctx.get('parentSectionName'), 'hide');
                      }}
                    >
                      <div className="flex items-center justify-start space-x-2">
                        <div>
                          {gstyles.icons({
                            name: 'eye-invisible-filled',
                            size: 24,
                            fill: gstyles.colors.main,
                          })}
                        </div>
                        <div className="text-base font-normal text-main">{ctx.apply('i18n.t', 'Hide')}</div>
                      </div>
                    </Menu.Item>
                  )}

                  {_.map(ctx.get('sectionData.sections'), (item) => {
                    const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });
                    return (
                      <Menu.Item key={_.get(item, 'id')}>
                        <div className="flex items-center justify-start space-x-2">
                          {!!_.get(itemData, 'className') && (
                            <div>
                              {gstyles.icons({
                                name: _.get(itemData, 'className', ''),
                                size: 24,
                                fill: gstyles.colors.main,
                              })}
                            </div>
                          )}
                          <div className="text-base font-normal text-main">{_.get(itemData, 'shortText', '')}</div>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              }
            >
              <div className="cursor-pointer">
                {gstyles.icons({ name: ctx.get('sectionData.className'), size: 24, fill: gstyles.colors.main })}
              </div>
            </Dropdown>
          </div>
        );
      })}
    </DIV>
  );
};

export default WorkBenchHeaderLayout;
