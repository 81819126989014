import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Col, Row } from 'antd';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }
`;

export const TextBlock = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <Row>
          <Col xs={2} md={0}>
            <div className="w-4 h-8 bg-brandb500"></div>
          </Col>
          <Col xs={22} md={24}>
            <div className="data flex space-x-0 md:space-x-4 items-start">
              <div className="hidden md:inline-block">
                <div className="w-4 h-8 bg-brandb500"></div>
              </div>
              <div
                className={`text-main text-xl md:text-3xl font-semibold text-base ${ctx.get('itemData.className')}`}
              >
                {ctx.get('itemData.shortText')}
              </div>
            </div>

            <CustomParagraph>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name', '') }, 'longText.longText', null)}
            </CustomParagraph>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default TextBlock;
