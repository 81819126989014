import React from 'react';
import { Image } from '@uz/unitz-components-web/Image';

import Nextjs from '../../assets/images/framework/next-js.svg';
import Html from '../../assets/images/framework/html-5.svg';

const ThemeOption = () => {
  return (
    <div id="framework-button">
      <a
        href="https://themeforest.net/item/busline-react-next-business-landing-page/25270135"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <Image src={Nextjs} alt="Nextjs" title="Nextjs" />
        </span>
      </a>
      <a
        href="https://themeforest.net/item/busline-business-landing-page-html-rtl/25581077"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <Image src={Html} alt="Html" title="Html" />
        </span>
      </a>
    </div>
  );
};

export default ThemeOption;
