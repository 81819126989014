import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
// const CustomParapraph = styled.div`
//   p > p {
//     color: ${gstyles.colors.main};
//   }
// `;
const CustomLongText = styled.div`
  p > p {
    color: ${gstyles.colors.main};
    @media only screen and (min-width: 320px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 20px;
    }
  }
`;

export const TextImageWithShadowItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <Row className="text-center items-center" gutter={[24, 16]}>
          <Col xs={24}>
            <div className="h-full w-full flex flex-col">
              <div className="mx-auto relative mb-8 md:mb-16">
                <div
                  style={{ zIndex: 0, borderRadius: 20 }}
                  className="absolute top-5 left-5 bg-white500 w-full h-full rounded-xl"
                ></div>
                <ResponsiveProps xs={{ size: 150 }} md={{ size: 200 }} lg={{ size: 250 }}>
                  {(resProps) => (
                    <Image
                      srcParams={{ fit: 'fill', fm: 'webp' }}
                      src={ctx.apply('ctf.findImage', ctx.get('itemData'), 'image', '')}
                      alt={ctx.get('itemData.name')}
                      style={{ border: `1px solid ${gstyles.colors.main}`, borderRadius: 20 }}
                      className="overflow-hidden"
                      {...resProps}
                    />
                  )}
                </ResponsiveProps>
              </div>
              <div className="font-semibold text-xl md:text-2xl my-2 text-main">
                {_.get(ctx.get('itemData'), 'shortText', '')}
              </div>
              <div>
                <CustomLongText>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                    className: 'text-base',
                    as: 'p',
                  })}
                </CustomLongText>
              </div>
            </div>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default TextImageWithShadowItem;
