import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import _ from 'lodash';
import moment from 'moment';
import { Button } from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';

const COUNT_DOWN_TIMER_END_SECOND = 5 * 60;

export const CountDownTimerAction = ({ room, interval = 1000, ...rest }) => {
  const $start_at = React.useMemo(() => moment(room.start_at), [room.start_at]);
  const $end_at = React.useMemo(() => moment(room.end_at), [room.end_at]);
  const [counter, $counter] = React.useState(0);
  const ref = React.useRef({});
  _.assign(ref.current, {
    $start_at,
    $counter,
    counter,
    interval,
  });
  const $start_duration = moment.duration($start_at.diff(moment()));

  React.useEffect(() => {
    ref.current.timer = setInterval(() => {
      ref.current.$counter(ref.current.counter + 1);
    }, ref.current.interval);
    return () => {
      if (ref.current.timer) {
        clearInterval(ref.current.timer);
      }
      ref.current = {};
    };
  }, []);

  const $now = moment();
  if ($start_duration.asSeconds() > COUNT_DOWN_TIMER_END_SECOND) {
    return (
      <DIV>
        {(() => {
          if (!!_.get(room, 'purchases.length') || !!_.get(room, 'purchase_info')) {
            return (
              <Link to={ctx.apply('routeStore.toUrl', 'room', room)} external className="block mx-auto w-40">
                <Button type="primary" name="primary" block size="small" {...rest}>
                  {/* {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.startIn', {
                    count: formatDuration($start_duration),
                  })} */}
                  {ctx.apply('i18n.t', 'Payment.Action.joinClass')}
                </Button>
              </Link>
            );
          } else {
            return (
              <Link
                to={ctx.apply('routeStore.toUrl', 'coursePurchase', _.get(room, 'course', {}))}
                external
                className="block mx-auto w-40"
              >
                <Button type="primary" name="primary" block size="small" {...rest}>
                  {ctx.apply('i18n.t', 'Payment.Action.payNow')}
                </Button>
              </Link>
            );
          }
        })()}
      </DIV>
    );
  }

  if ($start_duration.asSeconds() <= COUNT_DOWN_TIMER_END_SECOND && $start_at.isAfter($now)) {
    return (
      <DIV>
        {(() => {
          if (!!_.get(room, 'purchases.length') || !!_.get(room, 'purchase_info')) {
            return (
              // <Link to={ctx.apply('routeStore.toUrl', 'room', room)} external className="block mx-auto w-40">
              //   <Button type="primary" name="primary" block size="small" {...rest}>
              //     {/* {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.startIn', {
              //       count: formatDuration($start_duration),
              //     })} */}
              //     {ctx.apply('i18n.t', 'Payment.Action.joinClass')}
              //   </Button>
              // </Link>
              <Link to={ctx.apply('routeStore.toUrl', 'room', room)} external className="block mx-auto w-40">
                <Button className="bg-red" type="primary" block size="small" {...rest}>
                  {ctx.apply('i18n.t', 'Payment.Action.joinClass')}
                </Button>
              </Link>
            );
          } else {
            return (
              <Link
                to={ctx.apply('routeStore.toUrl', 'coursePurchase', _.get(room, 'course', {}))}
                external
                className="block mx-auto w-40"
              >
                <Button type="primary" name="primary" block size="small" {...rest}>
                  {ctx.apply('i18n.t', 'Payment.Action.payNow')}
                </Button>
              </Link>
            );
          }
        })()}
      </DIV>
    );
  }

  if ($now.isBetween($start_at, $end_at)) {
    return (
      <DIV>
        {(() => {
          if (!!_.get(room, 'purchases.length') || !!_.get(room, 'purchase_info')) {
            return (
              <Link to={ctx.apply('routeStore.toUrl', 'room', room)} external className="block mx-auto w-40">
                <Button className="bg-red" type="primary" block size="small" {...rest}>
                  {/* {ctx.apply('i18n.t', 'AdvisorCourse.roomActions.endIn', {
              count: formatDuration(moment.duration($end_at.diff(moment()))),
            })} */}
                  {ctx.apply('i18n.t', 'Payment.Action.joinClass')}
                </Button>
              </Link>
            );
          } else {
            return (
              <Link
                to={ctx.apply('routeStore.toUrl', 'coursePurchase', _.get(room, 'course', {}))}
                external
                className="block mx-auto w-40"
              >
                <Button type="primary" name="primary" block size="small" {...rest}>
                  {ctx.apply('i18n.t', 'Payment.Action.payNow')}
                </Button>
              </Link>
            );
          }
        })()}
      </DIV>
    );
  }

  if ($end_at.isBefore($now)) {
    return (
      <DIV>
        <Link to={ctx.apply('routeStore.toUrl', 'room', room)} external className="block mx-auto w-40">
          <Button className="" block type="primary" name="inkBg2" size="small" {...rest}>
            {ctx.apply('i18n.t', 'AdvisorCourse.roomStatus.completed')}
          </Button>
        </Link>
      </DIV>
    );
  }
  return null;
};

export const View8 = (props) => {
  return (
    <DIV>
      <CountDownTimerAction {...props} room={ctx.get('@item')} />
    </DIV>
  );
};

export default displayName(View8);
