import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';

const ForCenterProductListLayout = () => {
  return (
    <DIV className="ForCenterProductListLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="app-row wrapper">
            <div className="text-xl lg:text-3xl font-semibold text-center mb-6">
              {ctx.get('sectionData.shortText')}
            </div>
            <Row justify="center" gutter={[16, 16]}>
              {_.map(ctx.get('sectionData.sections'), (item, index) => (
                <Col key={index} xs={24} lg={12}>
                  {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') })}
                </Col>
              ))}
            </Row>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForCenterProductListLayout;
