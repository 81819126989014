import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Row, Col } from 'antd';
import { Description, Title } from '../../components/title/index';
// import './service.scss';

function Service() {
  return (
    <DIV>
      <section className="service-wrapper" id="service">
        <Row>
          <Col lg={5} md={4}>
            <div className="service-content-1 main-title-wrapper">
              {ctx.apply('ctf.renderSectionChild', 0, { component: 'RichText' })}
            </div>
          </Col>
          <Col lg={7} md={8} className="service-block-content">
            <Row>
              <Col sm={6} className="service-block1">
                {[2, 3].map((i) => {
                  const childIndex = i - 1;
                  return (
                    <div className={`service-${i - 2} service-content`} key={i}>
                      <div className="service-icon">
                        <span>
                          {ctx.apply('ctf.renderSectionChild', childIndex, {
                            args: ['', { width: 90 }],
                            component: 'Image',
                          })}
                        </span>
                      </div>
                      <div className="service-content-dec">
                        <Title
                          Class="service-title"
                          Name={ctx.apply('ctf.findSectionChild', childIndex, 'shortText', '')}
                        />
                        <Description
                          Class="service-dec-content"
                          Name={ctx.apply('ctf.findSectionChild', childIndex, 'longText.longText', '')}
                        />
                      </div>
                    </div>
                  );
                })}
              </Col>
              <Col sm={6} className="service-block2">
                {[4, 5].map((i) => {
                  const childIndex = i - 1;
                  return (
                    <div className={`service-${i - 4} service-content`} key={i}>
                      <div className="service-icon">
                        <span>
                          {ctx.apply('ctf.renderSectionChild', i - 1, {
                            args: ['', { width: 90 }],
                            component: 'Image',
                          })}
                        </span>
                      </div>
                      <div className="service-content-dec">
                        <Title
                          Class="service-title"
                          Name={ctx.apply('ctf.findSectionChild', childIndex, 'shortText', '')}
                        />
                        <Description
                          Class="service-dec-content"
                          Name={ctx.apply('ctf.findSectionChild', childIndex, 'longText.longText', '')}
                        />
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </DIV>
  );
}

export default Service;
