import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';

export const FlexColComponent = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <div className="flex flex-col h-auto justify-center items-center space-y-4">
          <div className="px-3 text-xl text-center font-semibold text-brandb500">
            {ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.shortText', 'shortText'), '')}
          </div>
          <div>
            <Image
              src={ctx.apply(
                'ctf.findImage',
                itemData,
                _.get(itemProps, 'fieldNames.image', 'image'),
                ...[].concat(_.get(itemProps, 'args') || [])
              )}
              size="cover"
              title={ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.shortText', 'shortText'), '')}
              alt={_.get(itemData, 'name', '')}
              preview={false}
            />
          </div>
        </div>
      );
    })}
  </DIV>
);

export default FlexColComponent;
