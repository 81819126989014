import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import RESABLE from '@vl/redata/RESABLE.macro';
import { resable } from '@vl/redata/RESABLE';
import { Link } from '@uz/unitz-components-web/Link';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

const View8 = () => {
  return (
    <DIV className="CourseSearchItem">
      <Link external to={ctx.apply('routeStore.toUrl', 'filterCourse', { search: ctx.get('@item.name') })}>
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center justify-between">
            <div>
              <RESABLE>
                {!!resable.for('avatarUrl')(CourseFormatter.avatarUrl(ctx)(ctx.get('@item'))) && (
                  <Avatar
                    alt={ctx.get('@item.profile.displayName')}
                    className="object-cover rounded-full"
                    shape="square"
                    size={{ xs: 24, md: 42 }}
                    src={resable.for('avatarUrl')(CourseFormatter.avatarUrl(ctx)(ctx.get('@item')))}
                  />
                )}
              </RESABLE>
            </div>
            <div className="pl-3 text-xs font-semibold text-left text-main text-ellipsis-2">
              {CourseFormatter.displayName(ctx)(ctx.get('@item'))}
            </div>
          </div>

          <div>{gstyles.icons({ name: 'arrow-right2', size: 20, fill: gstyles.colors.main })}</div>
        </div>
      </Link>
    </DIV>
  );
};

export default displayName(View8);
