import { bindings, hook } from '@vl/redata';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'HighlightAdvisor' });
              return {
                section,
              };
            }),
            paginationModel: hook((ctx) => {
              return ctx.get('paginationModel');
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
