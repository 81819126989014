import React from 'react';

export const withLoadingPromise = (name) => {
  const ref = React.useRef({
    check: () => {
      return ref.current.promise;
    }
  });
  ref.current.promise = React.useMemo(() => {
    return new Promise((resolve, reject) => {
      ref.current.resolve = resolve;
      ref.current.reject = reject;
    });
  }, []);
  return { [name]: ref.current };
};

export default withLoadingPromise;
