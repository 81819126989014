import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { TIME_HOUR_MINUTE_FORMAT } from '@vl/mod-utils/datetime';

export const formatter = {
  startAtTime: () => (itemData) => {
    const startAt = _.get(itemData, 'start_at');
    if (!startAt) return '';
    return moment(startAt).format(TIME_HOUR_MINUTE_FORMAT);
  },

  formatSessionTimeText: (ctx) => (itemData) => {
    const repeat_pattern = _.get(itemData, 'repeat_pattern') || '';
    if (repeat_pattern) {
      const processValue = `${repeat_pattern}`.replace('* * * * ', '').split(',');
      const arrayTransfering = _.castArray(processValue).sort();

      const dateOrdering = _.sortBy(arrayTransfering, (item) => {
        return item === '0' ? _.get(arrayTransfering, 'length', 1) : 0;
      });

      const repeatArray = _.map(dateOrdering, (item, index) => {
        return (
          <span key={index}>
            {moment()
              .day(item)
              .format('ddd')}
            {index < arrayTransfering.length - 1 ? '-' : ''}
          </span>
        );
      });
      return repeatArray;
    }
    return moment(_.get(itemData, 'start_at')).format('L');
  },
};

export default formatter;
