import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import { Spin } from 'antd';
import { InputText } from '@uz/unitz-components-web/InputText';
import styled from 'styled-components';
import { Form } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import CourseSearchItem from '@uz/unitz-components-web/CourseSearchItem';
import FlatList from '@uz/unitz-components-web/FlatList';

const CustomInput = styled.div`
  .ant-input-affix-wrapper {
    border: 1px solid ${gstyles.colors.black100};
    border-radius: 8px;
    background: ${gstyles.colors.white500};
    padding: 0;
    padding-left: 11px;
    .ant-input-suffix {
      margin-left: 10px;
      border-left: 1px solid ${gstyles.colors.secondary2};
    }
    .ant-input {
      padding: 8px 11px;
      background: transparent;
      height: auto;
      @media only screen and (min-width: 320px) {
        min-width: 220px;
      }
      @media only screen and (min-width: 768px) {
        min-width: 600px;
      }
      @media only screen and (min-width: 992px) {
        min-width: 100px;
      }
      @media only screen and (min-width: 1180px) {
        min-width: 200px;
      }
      &::-webkit-input-placeholder {
        color: ${gstyles.colors.sub};
      }

      &:-ms-input-placeholder {
        color: ${gstyles.colors.sub};
      }

      &::placeholder {
        color: ${gstyles.colors.sub};
      }
      &:hover {
        border-color: none;
      }
      &:focus {
        border-color: none;
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
`;
const CustomList = styled.div`
  .advisor-list {
    .advisor-item {
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
`;

const renderItem = ({ item, index }) => {
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('@item', item);
        ctx.set('@index', index);
      })}
      <div className="border-b rounded-lg course-item hover:bg-brandb-lightest border-divider">
        <CourseSearchItem />
      </div>
    </DIV>
  );
};

const View8 = () => {
  return (
    <DIV className="SearchButton">
      <div className="relative">
        <FormProvider form={ctx.get('searchForm')}>
          <Form>
            <div className="flex items-center justify-center">
              <CustomInput>
                <InputText
                  name="textSearch"
                  suffix={
                    <div
                      className="cursor-pointer"
                      style={{ padding: '8px' }}
                      onClick={() => {
                        ctx.apply(
                          'routeStore.navigate',
                          ctx.apply('routeStore.toUrl', 'filterCourse', {
                            search: ctx.get('searchForm.values.textSearch'),
                          })
                        );
                      }}
                    >
                      {gstyles.icons({ name: 'search', size: 20, fill: gstyles.colors.sub })}
                    </div>
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      ctx.apply(
                        'routeStore.navigate',
                        ctx.apply('routeStore.toUrl', 'filterCourse', {
                          search: ctx.get('searchForm.values.textSearch'),
                        })
                      );
                      ctx.apply('listSuggestion.hideSuggestion');
                    }
                  }}
                  autoComplete="off"
                  autoFocus
                  onChange={ctx.get('searchForm.handleChange')}
                  placeholder={ctx.apply('i18n.t', 'Selection.titleCourseSearchBar')}
                />
              </CustomInput>
            </div>
          </Form>
        </FormProvider>

        {!!(ctx.get('listSuggestion.hasData') && ctx.get('listSuggestion.show')) && (
          <CustomList>
            <div
              style={{ zIndex: 500 }}
              className="absolute w-full transform translate-y-1 rounded-lg shadow-lg course-list animation-opacity bg-white500 l-0"
            >
              <FlatList
                data={ctx.get('listSuggestion.items', [])}
                renderItem={renderItem}
                keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
                horizontal
              />
            </div>
          </CustomList>
        )}

        {!!(
          !ctx.get('listSuggestion.hasData') &&
          ctx.get('listSuggestion.show') &&
          ctx.get('searchForm.values.textSearch') !== ''
        ) && (
          <div
            style={{ zIndex: 500 }}
            className="absolute flex items-center justify-center w-full py-4 transform translate-y-1 rounded-lg shadow-lg animation-opacity bg-white500 l-0"
          >
            <Spin />
          </div>
        )}
      </div>
    </DIV>
  );
};

export default displayName(View8);
