import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Row, Col } from 'antd';
import { Image } from '@uz/unitz-components-web/Image';

const CustomParapraph = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;

export const BizFeatureItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return (
        <Row
          gutter={[
            { xs: 0, md: 16, lg: 60 },
            { xs: 24, xl: 32 },
          ]}
          className="mb-4 md:mb-6 lg:mb-16 justify-center items-center"
        >
          <Col className="order-2 min-md:order-1" lg={16} md={12} sm={24}>
            <div className="mb-3 sm:mb-0">
              <Image
                className="object-cover"
                size="cover"
                src={ctx.apply('ctf.findImage', itemData, 'image', '')}
                alt={_.get(itemData, 'shortText')}
                preview={false}
              />
            </div>
          </Col>
          <Col className="order-1 min-md:order-2 content" lg={8} md={12} sm={24}>
            <ResponsiveProps
              xs={{
                renderer: () => (
                  <div>
                    <h2 style={{ wordBreak: 'break-word' }} className="mb-1 text-left text-xl font-semibold text-main">
                      {_.get(itemData, 'shortText', '')}
                    </h2>
                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {
                        className: 'text-left text-base mb-4',
                        as: 'p',
                      })}
                    </CustomParapraph>
                  </div>
                ),
              }}
              md={{
                renderer: () => (
                  <div>
                    <h2 style={{ wordBreak: 'break-word' }} className="mb-4 text-2xl font-semibold text-main">
                      {_.get(itemData, 'shortText', '')}
                    </h2>
                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {
                        className: 'text-sm mb-4',
                        as: 'p',
                      })}
                    </CustomParapraph>
                  </div>
                ),
              }}
              lg={{
                renderer: () => (
                  <div>
                    <h2 style={{ wordBreak: 'break-word' }} className="mb-4 text-4xl font-semibold text-main">
                      {_.get(itemData, 'shortText', '')}
                    </h2>
                    <CustomParapraph>
                      {ctx.apply('ctf.renderHTMLText', { name: _.get(itemData, 'name', '') }, 'longText.longText', {
                        className: 'text-base mb-4',
                        as: 'p',
                      })}
                    </CustomParapraph>
                  </div>
                ),
              }}
            >
              {(resProps) => !!resProps && resProps.renderer()}
            </ResponsiveProps>
          </Col>
        </Row>
      );
    })}
  </DIV>
);

export default BizFeatureItem;
