import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const GreenDisc = styled.div`
  position: absolute;
  top: -60%;
  left: 20%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 1259px;
  height: 1259px;
  background-color: ${gstyles.colors.brandb500};
  overflow: hidden;
  opacity: 0.1;
`;

const ForSchoolHeroLayout = () => {
  return (
    <DIV className="ForSchoolHeroLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="relative overflow-hidden">
            <GreenDisc className="hidden lg:block" />
            <div className="wrapper app-row">
              <div className="block md:hidden text-center mb-3 text-brandb-darker text-3xl font-semibold">
                {_.get(
                  ctx.apply('ctf.findNode', { name: ctx.get('sectionData.sections.0.name') }),
                  'sections.0.shortText'
                )}
              </div>

              <ResponsiveProps
                xs={{ style: { flexDirection: 'column-reverse', textAlign: 'center' } }}
                md={{ style: { flexDirection: 'row', textAlign: 'left' } }}
              >
                {(resProps) => (
                  <Row gutter={[24, 24]} justify="center" align="middle" {...resProps}>
                    <Col xs={24} md={12}>
                      <Row>
                        <Col xs={24} lg={16}>
                          {_.map(
                            _.get(
                              ctx.apply('ctf.findNode', { name: ctx.get('sectionData.sections.0.name') }),
                              'sections'
                            ),
                            (item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {ctx.apply('ctf.renderItem', { name: item.name })}
                                </React.Fragment>
                              );
                            }
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={12}>
                      {ctx.apply('ctf.renderItem', { name: ctx.get('sectionData.sections.1.name') })}
                    </Col>
                  </Row>
                )}
              </ResponsiveProps>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForSchoolHeroLayout;
