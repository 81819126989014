import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

// import Img from "gatsby-image"
import { Subtitle, Description } from '../../components/title/index';
import Image from '../../components/image/index';
import Button from '../../components/button';
// import './contact.scss';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [, setErrors] = useState({});

  const handelSubmit = () => {
    const error = {};
    if (!name) {
      error.name = 'First Name field shouldn’t be empty';
    }
    if (!email) {
      error.email = 'Email field shouldn’t be empty';
    }
    if (!message) {
      error.message = 'Message field shouldn’t be empty';
    }

    if (error) {
      setErrors(error);
    } else {
      setName('');
      setEmail('');
      setMessage('');
    }
  };

  return (
    <section className="contact-wrapper" id="contact">
      <DIV>
        <Row>
          <Col sm={0} md={6}>
            <div className="contact-image">
              {/* <Img fluid={data.placeholderImage.childImageSharp.fluid} /> */}
              <Image
                Path={ctx.apply('ctf.findImage', { name: 'contactItem' }, 'image', '', { width: 437 })}
                Class="logo-img"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="contact-content-block main-title-wrapper">
              <Subtitle
                Class="sitemain-subtitle"
                Name={ctx.apply('ctf.findEntry', { name: 'contactItem' }, 'shortText', '')}
              />
              <Subtitle
                Class="site-subtitle2"
                Name={ctx.apply('ctf.findEntry', { name: 'contactItem' }, 'longText.longText', '')}
              />
              <Description
                Class="contact-dec"
                Name={ctx.apply('ctf.findEntry', { name: 'contactPhone' }, 'shortText', '')}
              />
              <Button
                Class="button1 btn button2 gradient-color"
                Name="SUBMIT"
                Clickble={handelSubmit}
                BtnIcon="btn-icon"
              />
            </div>
          </Col>
        </Row>
      </DIV>
    </section>
  );
}

export default Contact;
