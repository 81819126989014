import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const AdvisorSupportCenterContentLayout = () => {
  return (
    <DIV className="AdvisorSupportCenterContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="app-row wrapper md:px-4 md:mx-0 md:w-full xl:mx-auto xl:px-0">
            <div className="content">
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', [])
                .filter((item) => {
                  return _.get(item, 'slug', '') === ctx.get('tabData');
                })
                .map((itemFilled, index) => {
                  return (
                    <React.Fragment key={index}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(itemFilled, 'name', '') }) || null}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default AdvisorSupportCenterContentLayout;
