import React from 'react';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import CountUp from 'react-countup';
import { Title } from '../../components/title/index';
// import './project.scss';

function Project() {
  return (
    <section className="project-wrapper gradient-color" id="project">
      <DIV>
        <div className="project-content-wrapper">
          <Row>
            {[1, 2, 3, 4].map((i) => (
              <Col md={3} sm={6} key={i}>
                <div className={`counter-${i - 1} project-box-outer`}>
                  <div className="animated-bg">
                    <i></i>
                    <i></i>
                    <i></i>
                  </div>
                  <div className="counters">
                    <CountUp
                      delay={3}
                      end={+ctx.apply('ctf.findEntry', { name: `projectItem${i}` }, 'shortText', '')}
                      className="counters-number"
                    ></CountUp>
                    <Title
                      Class="counters-title"
                      Name={ctx.apply('ctf.findEntry', { name: `projectItem${i}` }, 'longText.longText', '')}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </DIV>
    </section>
  );
}

export default Project;
