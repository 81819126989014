import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useRoute from '@vl/hooks/useGbRoute';
import moment from 'moment';

import RenderError from './RenderError';

const bindData = bindings({
  courseRoomRescheduleModal: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const route = useRoute();
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const item = ctx.get('@item');
              const room = _.get(item, 'room');

              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  user_id: userId,
                  room_id: _.get(room, 'id'),
                  old_start_at: _.get(item, 'start_at'),
                  start_at: _.get(item, 'start_at'),
                  session_duration: moment(_.get(room, 'end_at')).diff(moment(_.get(room, 'start_at')), 'seconds'),
                  update_same_rooms: false,
                  sessions: Date.now(),
                },
                onSubmit: async (values, actions) => {
                  const errors = {};
                  const params = {
                    ..._.omit(values, ['old_start_at', 'sessions']),
                  };
                  try {
                    const data = await fbFnsClient.getClient().post('course-rescheduleRoom', params);

                    if (_.get(data, 'errors')) {
                      errors.sessions = RenderError(data.errors);
                      return form.setErrors({ sessions: RenderError(data.errors) });
                    }
                    if (_.get(data, 'error')) {
                      return actions.setStatus({
                        error: data,
                      });
                    }
                    form.handleCancel();
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid') && _.get(form, 'dirty'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
