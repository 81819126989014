import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';

export const Paragraph = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <p
          className={cx(
            ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
            _.get(itemProps, 'className')
          )}
        >
          {ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', '']))}
        </p>
      );
    })}
  </DIV>
);

export default Paragraph;
