import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            carouselModel: hook((ctx) => {
              const [dragging, $dragging] = React.useState(false);
              const NUMBER_OF_ITEMS = ctx.get('@items.length') || 0;
              const options = {
                dots: true,
                infinite: !!(NUMBER_OF_ITEMS > 4),
                centerMode: !(NUMBER_OF_ITEMS > 4),
                slidesToShow: 4,
                slidesToScroll: 4,
                swipeToSlide: true,
                speed: 500,
                // nextArrow: <NextArrow />,
                // prevArrow: <PrevArrow />,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      infinite: !!(NUMBER_OF_ITEMS > 3),
                      centerMode: !(NUMBER_OF_ITEMS > 3),
                      dots: true,
                      centerPadding: '0px',
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      speed: 500,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      dots: true,
                      centerPadding: '0px',
                      infinite: !!(NUMBER_OF_ITEMS > 2),
                      centerMode: !(NUMBER_OF_ITEMS > 2),
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      swipeToSlide: true,
                      speed: 500,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      infinite: !!(NUMBER_OF_ITEMS > 1),
                      centerMode: !(NUMBER_OF_ITEMS > 1),
                      dots: !!(NUMBER_OF_ITEMS < 8),
                      arrows: true,
                      centerPadding: '20px',
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipeToSlide: true,
                      speed: 500,
                    },
                  },
                ],
              };
              return {
                dragging,
                $dragging,
                options,
              };
            }),
            item: hook((ctx) => {}),
          },
        },
      ],
    ],
  },
});
export default bindData;
