import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const ForStudentMissionLayout = () => {
  return (
    <DIV className="ForStudentMissionLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <div className={`wrapper app-row flex flex-col`}>
            {_.map(ctx.get('sectionData.sections', []), (item, index) => {
              return <div key={index}>{ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}</div>;
            })}
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default ForStudentMissionLayout;
