import React from 'react';
import { Row, Col } from 'antd';
import { Subtitle, Description, Title, Titlespan2 } from '../../components/title/index';

// import './blog.scss';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

function Blog() {
  return (
    <section className="blog-wrapper" id="blog">
      <DIV>
        <div className="main-title-wrapper">
          <Subtitle Class="site-subtitle" Name={ctx.apply('ctf.findSectionChild', 0, 'shortText', '')} />
          <Titlespan2 Class="sitemain-subtitle" Name={ctx.apply('ctf.findSectionChild', 0, 'longText.longText', '')} />
          <Description Class="site-dec" Name={ctx.apply('ctf.renderSectionChild', 0, { component: 'RichText' })} />
        </div>
        <Row>
          {[1, 2, 3].map((i) => {
            const item = `missionItem${i}`;
            return (
              <Col key={item} lg={4} sm={6} className={'blog-{i-1}'}>
                <div className="blog-content">
                  <div className="blog-first-block">
                    {ctx.apply('ctf.renderSectionChild', i, {
                      args: ['', { width: 450 }],
                      component: 'Image',
                      fieldNames: { image: 'images.0' },
                    })}
                  </div>
                  <div className="blog-second-block">
                    <div className="blog-left-content">
                      <div className="blog-icon">
                        <span>
                          {ctx.apply('ctf.renderSectionChild', i, {
                            args: ['', { width: 90 }],
                            component: 'Image',
                            fieldNames: { image: 'images.1' },
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="blog-right-content">
                      <Title
                        Class="blog-title"
                        Name={ctx.apply('ctf.findSectionChild', { name: item }, 'longText.longText', '')}
                      />
                      <Description
                        Class="blog-dec"
                        Name={ctx.apply('ctf.renderSectionChild', { name: item }, { component: 'RichText' })}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </DIV>
    </section>
  );
}

export default Blog;
