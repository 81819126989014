import React from 'react';
import _ from 'lodash';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

const ModalProvider = ({ children }) => {
  const [renderModal, $renderModal] = React.useState();
  return (
    <DIV>
      {ctx.debug(() => {
        const modalModel = {
          show: (render) => {
            $renderModal(render);
          },
          hide: () => {
            $renderModal(false);
          },
        };
        ctx.set('modalModel', modalModel);
      })}
      {(() => {
        return _.isFunction(renderModal) ? renderModal() : renderModal;
      })()}
      {children}
    </DIV>
  );
};

export default ModalProvider;
