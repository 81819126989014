import React from 'react';
import createLayout from '@vl/mod-utils/createLayout';

export const Layout = createLayout(({ children }) => {
  return (
    <div className="bg-white rounded-lg p-4">
      <div id="layoutcontent">{children}</div>
      <div className="">
        <div className="">
          <Layout.RenderPOS name="widget-header">
            <div className="flex justify-between items-center">
              <Layout.RenderPOS name="widget-header-title">
                {(ele) => <div className="text-center">{ele}</div>}
              </Layout.RenderPOS>
              <div className="">
                <Layout.RenderPOS name="widget-header-action">
                  {(ele) => ele && <div className="space-x-2">{ele}</div>}
                </Layout.RenderPOS>
              </div>
            </div>
          </Layout.RenderPOS>
        </div>
        <div className="bg-transparent py-2">
          <Layout.RenderPOS name="widget-body" />
        </div>
        <div className="">
          <Layout.RenderPOS name="widget-footer" />
        </div>
      </div>
    </div>
  );
});

export default Layout;
