import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

const SercurityInformationLayout = () => {
  return (
    <DIV className="SercurityInformation">
      {ctx.apply('ctf.renderProps', () => (
        <div className="py-4 border-b sm:border-b-8">
          <div className="app-row wrapper md:px-4 md:mx-0 md:w-full xl:mx-auto xl:px-0">
            <div>
              <h2 className="mb-5 text-base md:text-2xl lg:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                className: 'py-4 mb-0',
                as: 'p',
              })}
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default SercurityInformationLayout;
