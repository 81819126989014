import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CourseRCMRequestModal from '@uz/unitz-components-web/CourseRCM/Components/CourseRCMRequestModal';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            rcmFormModel: hook((ctx) => {
              const [localItem, $localItem] = useLocalStorage('@UZ::RCMFormValues');
              const [reOpen, $reOpen] = useLocalStorage('@UZ::RCMReOpen', false);
              React.useEffect(() => {
                const modalModel = ctx.apply('REF.getRef', 'RCMModalModel');
                if (reOpen && ctx.apply('authModel.isAuthenticated') && !!modalModel) {
                  modalModel.show(() => <CourseRCMRequestModal />);
                  $reOpen(false);
                }
              }, [ctx.apply('authModel.isAuthenticated')]);
              return {
                show: () => {
                  const modalModel = ctx.apply('REF.getRef', 'RCMModalModel');
                  modalModel.show(() => <CourseRCMRequestModal />);
                },
              };
            }),
            subscriberState: hook((ctx) => {
              const [reOpen, $reOpen] = useLocalStorage('@UZ::RCMReOpen', false);
              const [localRCMFormStatus, $localRCMFormStatus] = useLocalStorage('@UZ::localRCMFormStatus', '');
              const handler = {
                openModal: () => {
                  _.debounce(() => {
                    ctx.apply('rcmFormModel.show');
                    $localRCMFormStatus('skip');
                  }, 20000)();
                },
                getRCMForm: () => {
                  if (localRCMFormStatus !== 'skip') handler.openModal();
                },
              };
              // Tạm thời off feature tự động mở form sau 20s vì conflict với yêu cầu mở form trên hero banner.
              // if (!reOpen) {
              //   handler.getRCMForm();
              // }

              return {
                localRCMFormStatus,
                $localRCMFormStatus,
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
