import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  NewsSliderSectionLayout: {
    rules: [
      [
        'data',
        {
          data: {
            carouselOptions: hook(() => {
              return {
                dots: true,
                centerMode: false,
                infinite: true,
                centerPadding: '30px',
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      infinite: true,
                      dots: true,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 1,
                      swipeToSlide: true,
                      arrows: true,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipeToSlide: true,
                      arrows: true,
                    },
                  },
                ],
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
