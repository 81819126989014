import React from 'react';

import _ from 'lodash';

export const useLoadingModel = (init) => () => {
  // eslint-disable-next-line
  const [loadings, $loadings] = React.useState(init);
  // eslint-disable-next-line
  const ref = React.useRef({ loadings: { ...loadings } });
  Object.assign(ref.current, { $loadings });
  // eslint-disable-next-line
  React.useEffect(() => {
    return () => {
      ref.current = null;
    };
  }, []);
  // eslint-disable-next-line
  const updateCb = React.useMemo(
    () =>
      _.debounce(() => {
        if (ref.current && !!_.get(ref, 'current.loadings', '')) {
          ref.current.$loadings({ ...ref.current.loadings });
        }
      }, 13),
    [ref, loadings]
  );

  const isLoading = () => {
    const keys = Object.keys(_.get(ref, 'current.loadings', ''));
    for (const key of keys) {
      if (loadings[key]) {
        return true;
      }
    }
    return false;
  };
  return {
    loadings,
    isLoading,
    set: (val) => {
      const newVal = {};
      if (_.isString(val)) {
        Object.assign(newVal, { [val]: true });
      } else if (_.isPlainObject(val)) {
        // value must be boolean
        _.map(val, (item, key) => (newVal[key] = !!item));
      }
      Object.assign(_.get(ref, 'current.loadings', ''), newVal);
      updateCb();
    },
    setLoading: (name) => {
      _.update(_.get(ref, 'current.loadings', ''), [name], (val) => {
        const newVal = true;
        if (newVal !== val) {
          updateCb();
        }
        return newVal;
      });
    },
    clearLoading: (name) => {
      _.update(_.get(ref, 'current.loadings', ''), [name], (val) => {
        const newVal = false;
        if (newVal !== val) {
          updateCb();
        }
        return newVal;
      });
    },
    hook: () => {},
  };
};

export default useLoadingModel;
