import React from 'react';
import _ from 'lodash';
import { getStreamable } from '@vl/mod-utils/getStreamable';

export const createLayout = (render) => {
  const container = {};
  const eventStream = getStreamable();
  const _Layout = (props) => {
    return render(props);
  };

  const emitChange = (name) => {
    const paths = _.toPath(name);
    if (paths.length === 2) {
      eventStream.emit(paths[0]);
      eventStream.emit(name);
    } else {
      eventStream.emit(name);
    }
  };

  _Layout.unset = (name) => {
    const removed = _.unset(container, name);
    emitChange(name);
    return removed;
  };

  _Layout.getContainer = () => {
    return container;
  };

  _Layout.POS = ({ name, children }) => {
    const ref = React.useRef({});
    React.useEffect(() => {
      ref.current.name_old = name;
      return () => {
        _Layout.unset(ref.current.name_old);
      };
    }, [name]);
    React.useEffect(() => {
      return () => {
        _Layout.unset(name);
      };
    }, []);
    if (!name) return null;
    _.set(container, name, children);
    emitChange(name);
    return null;
  };

  _Layout.RenderPOS = ({ name, children }) => {
    const ref = React.useRef({});
    const [val, $val] = React.useState(0);
    _.assign(ref.current, { val, $val });
    React.useEffect(() => {
      ref.current.disposer = eventStream.on(name, () => {
        setTimeout(() => {
          ref.current.$val && ref.current.$val(ref.current.val + 1);
        });
      });
      return () => {
        ref.current.disposer && ref.current.disposer();
        ref.current = {};
      };
    }, []);
    if (_.isFunction(children)) {
      return children(_.get(container, name)) || null;
    }
    if (_.has(container, name)) {
      return _.get(container, name);
    }
    if (children) return children;
    return null;
  };
  return _Layout;
};

export default createLayout;
