import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const BlogListLayout = () => {
  return (
    <DIV className="BlogListLayout">
      <section className="mb-0 wrapper app-row blog-list-section">
        <div className="flex flex-col items-center justify-center mb-6">
          <h5 className="text-sm font-semibold text-center uppercase text-sub">{ctx.get('sectionData.shortText')}</h5>
          <CustomParagraph className="text-center">
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
          </CustomParagraph>
        </div>
        <div className="flex mb-8 jusify-between"></div>
      </section>
    </DIV>
  );
};

export default BlogListLayout;
