import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import EducationTestimonialList from '@uz/unitz-components-web/EducationTestimonialList';
import EducationTestimonialVideoList from '@uz/unitz-components-web/EducationTestimonialVideoList';

const EducationTestimonialLayout = () => {
  return (
    <DIV className="EducationTestimonialLayout">
      <div className="bg-background1">
        <section className="wrapper app-row section">
          {ctx.apply('ctf.renderProps', () => {
            return (
              <div className="py-8 md:py-6 lg:py-14 mx-0 md:mx-4">
                <h2 className="mb-6 lg:mb-16 text-2xl lg:text-4xl font-semibold text-center text-main">
                  {ctx.get('sectionData.shortText')}
                </h2>
                <Row
                  gutter={[
                    { xs: 0, lg: 24 },
                    { xs: 0, lg: 24 },
                  ]}
                >
                  <Col xs={24} lg={12}>
                    <EducationTestimonialList />
                  </Col>
                  <Col xs={24} lg={12}>
                    <EducationTestimonialVideoList />
                  </Col>
                </Row>
              </div>
            );
          })}
        </section>
      </div>
    </DIV>
  );
};

export default EducationTestimonialLayout;
