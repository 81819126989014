import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

function CommonSectionLayout() {
  return (
    <DIV className="component">
      <div className="col-span-1">
        <div className="py-0 sm:wrapper app-row">
          {ctx.apply('ctf.renderProps', () => {
            return (
              <div className="rounded-lg shadow-md overflow-hidden bg-white500">
                <div className="section-header px-4 py-6">
                  {_.map(ctx.get('sectionData.nodes'), (section, index) => (
                    <div key={index}>{ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}</div>
                  ))}
                </div>

                {ctx.get(`sectionState.sectionState.${ctx.get('parentSectionName')}`) !== 'hide' && (
                  <div className="section-body">
                    <div className="px-4 pb-4 flex-col space-y-2">
                      {_.map(ctx.get('sectionData.sections'), (section, index) => (
                        <React.Fragment key={index}>
                          {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </DIV>
  );
}

export default CommonSectionLayout;
