import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    scrollbar-width: none;
    display: none;
    -ms-overflow-style: none;
  }
`;
const YourProblemLayout = () => {
  return (
    <DIV className="YourProblem">
      {ctx.apply('ctf.renderProps', () => (
        <div className="py-4 border-b sm:border-b-8">
          <div className="app-row wrapper md:px-4 md:mx-0 md:w-full xl:mx-auto xl:px-0">
            <div>
              <h2 className="text-base md:text-2xl lg:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                className: 'py-4 mb-0',
                as: 'p',
              })}
              <ScrollX className="relative w-full py-2 overflow-x-scroll">
                <Row gutter={16} wrap={false}>
                  {_.map(ctx.get('sectionData.sections'), (item, index) => (
                    <Col className="h-full" xs={16} lg={8} key={`item__${index}`}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                    </Col>
                  ))}
                </Row>
              </ScrollX>
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default YourProblemLayout;
