const _ = require('lodash');

const Streamable = require('./Streamable');
const utils = require('./utils');

const privateData = utils.privateDataWrapper({
  props: () => ({}),
  state: () => ({})
});

class StreamableAndQueriable extends Streamable {
  constructor(props) {
    super();
    if (props) {
      privateData.set(this, 'props', props);
    }
  }

  set(...args) {
    if (args.length === 1 && _.isPlainObject(args[0])) {
      for (const key of _.keys(args[0])) {
        const val = args[0][key];
        this.set(key, val);
      }
      return this;
    }

    if (args.length === 2) {
      const [key, val] = args;
      // trigger change event
      _.set(privateData.get(this, 'props'), key, val);
      // _.set(this, key, val);
      this.emit && this.emit('change', key, val);
      return this;
    }
  }

  has(key) {
    return (
      _.has(privateData.get(this, 'props'), key)
      || _.hasIn(privateData.get(this, 'props'), key)
      || _.has(privateData.get(this, 'nodes'), key)
    );
  }

  get(...args) {
    if (args.length === 0) {
      return privateData.get(this, 'props');
    }
    const [key, def] = args;
    return _.get(privateData.get(this, 'props'), key, def);
  }

  pick(props) {
    const rtn = {};
    _.castArray(props || []).map((prop) => {
      if (_.isArray(prop) && prop.length >= 2) {
        let [key, def, alias] = prop;
        alias = alias || key;
        if (this.has(key)) {
          rtn[alias] = this.get(key, def);
        }
      } else if (_.isString(prop)) {
        const key = prop;
        if (this.has(key)) {
          rtn[key] = this.get(key);
        }
      }
      return prop;
    });
    return rtn;
  }

  setNode(...args) {
    if (args.length === 1 && _.isPlainObject(args[0])) {
      for (const key of _.keys(args[0])) {
        const val = args[0][key];
        this.setNode(key, val);
      }
      return this;
    }

    if (args.length === 2) {
      const [key, val] = args;
      // trigger change event
      _.set(privateData.get(this, 'nodes'), key, val);
      this.emit && this.emit('change', key, val);
      return this;
    }
  }

  hasNode(key) {
    return _.has(privateData.get(this, 'nodes'), key);
  }

  getNode(...args) {
    if (args.length === 0) {
      return privateData.get(this, 'nodes');
    }
    const [key, def] = args;
    return _.get(privateData.get(this, 'nodes'), key, def);
  }

  setState(...args) {
    if (args.length === 1 && _.isPlainObject(args[0])) {
      for (const key of _.keys(args[0])) {
        const val = args[0][key];
        this.setState(key, val);
      }
      return this;
    }

    if (args.length === 2) {
      const [key, val] = args;
      // trigger change event
      _.set(privateData.get(this, 'state'), key, val);
      this.emit && this.emit('changeState', key, val);
      return this;
    }
  }

  hasState(key) {
    return _.has(privateData.get(this, 'state'), key);
  }

  getState(...args) {
    if (args.length === 0) {
      return privateData.get(this, 'state');
    }
    const [key, def] = args;
    return _.get(privateData.get(this, 'state'), key, def);
  }

  pickState(props) {
    const rtn = {};
    _.castArray(props || []).map((prop) => {
      if (_.isArray(prop) && prop.length >= 2) {
        let [key, def, alias] = prop;
        alias = alias || key;
        if (this.has(key)) {
          rtn[alias] = this.getState(key, def);
        }
      } else if (_.isString(prop)) {
        const key = prop;
        if (this.has(key)) {
          rtn[key] = this.getState(key);
        }
      }
      return prop;
    });
    return rtn;
  }

  getByPath(path, def) {
    return utils.getByPath(this, path, def);
  }

  setByPath(path, val) {
    return utils.setByPath(this, path, val);
  }

  applyByPath(path, ...args) {
    return utils.applyByPath(this, path, ...args);
  }
}

module.exports = StreamableAndQueriable;
