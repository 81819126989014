import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hook, bindings } from '@vl/redata';
import _ from 'lodash';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const form = useFormik({
                initialValues: {
                  username: '',
                  password: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    await ctx.apply('authModel.emailLogin', values.username, values.password);
                  } catch (error) {
                    // apply translation
                    const code = _.get(error, 'code');
                    if (code) {
                      error.message = ctx.apply('i18n.t', `FirebaseMessage.${code}.message`);
                    }
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  username: Yup.string()
                    .email('Invalid email')
                    .required(ctx.apply('i18n.t', 'Form.required')),
                  password: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });
              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid') && _.get(form, 'dirty'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
