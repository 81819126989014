import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import cx from 'classnames';

import SectionWrapper from '../../components/SectionWrapper';

// import './footer.scss';
import _ from 'lodash';

function Footer() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const isWhite = `${ctx.get('sectionData.className')}`.indexOf('bg-white500') >= 0;

        return (
          <SectionWrapper
            className={cx(`${ctx.get('sectionData.className')}`, 'lg:px-4', { 'bg-brandb500': !isWhite })}
            as="div"
            style={{ boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.15)' }}
          >
            <div
              className={cx(
                `${ctx.get(
                  'sectionData.className'
                )} border-b md:border-0 lg:border-b wrapper app-row md:px-4 md:pb-0 lg:pb-5 md:mx-0 md:w-full lg:mx-auto lg:px-0`
              )}
            >
              <div className="grid grid-cols-12 gap-0 md:gap-6 lg:gap-8">
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (col, index) => {
                    return (
                      <div className={cx(col.className)} key={`col_${index}`}>
                        {ctx.apply('ctf.findEntry', { name: col.name }, 'shortText', '') ? (
                          <div
                            className={cx('mb-3 text-sm font-semibold hidden sm:block', {
                              'text-white500': !isWhite,
                              'text-main': !!isWhite,
                            })}
                          >
                            {ctx.apply('ctf.findEntry', { name: col.name }, 'shortText', '')}
                          </div>
                        ) : (
                          <div className="m-0"></div>
                        )}

                        <React.Fragment key={`${col.name}_${index}`}>
                          {ctx.apply('ctf.renderEntry', { name: col.name }) || null}
                        </React.Fragment>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="hidden md:block lg:hidden border-b mx-12"></div>
            <div
              className={cx('py-3 text-sm font-normal text-center', {
                'text-sub': !!isWhite,
                'text-white500': !isWhite,
                // 'mb-20 min-lg:mb-0': !ctx.get('authModel.getUserId'),
              })}
            >
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'shortText', '')}
            </div>
          </SectionWrapper>
        );
      })}
    </DIV>
  );
}

export default Footer;
