// eslint-disable-next-line import/prefer-default-export
export const ACCOUNT_NAME = /^[A-Za-z ]{6,40}$/;

export const ACCOUNT_NUMBER = /^[0-9]+$/;

// export const FULL_NAME = /^[A-Za-z ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼẾỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏõốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựứýỳỵỷỹVv]{6,40}$/;
export const FULL_NAME = /^[\s\S]{6,40}$/;

export const PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]{4,20}$/;

export const IDENTIFY = /^[0-9]*$/;

export const PASSPORT = /^[A-Z0-9]*$/;

export const TAX_NUMBER = /^[0-9]*$/;

export const EMAIL = /^([a-zA-Z0-9_\-\.]+)@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const HTTP_LINK = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const normalizeHtmlString = (html) => {
  if (!html) return '';
  return html.replace(/(\r\n|\n|\r|'s)/gm, '');
};
