import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
// import CourseModel from '@uz/unitz-models/CourseModel';
import SuggestCourseModel from '@uz/unitz-models/SuggestCourseModel';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import useObservableSource from '@vl/hooks/useObservableSource';
import CourseRoomModelFormatter from '@uz/unitz-models/CourseRoomModel/formatter';
import { TIMESTAMPTZ_FORMAT } from '@vl/mod-utils/datetime';
import moment from 'moment';
const bindData = bindings({
  LandingCourseListLayout: {
    rules: [
      [
        'data',
        {
          data: {
            highlightCourses: hook((ctx) => {
              // 'where: {is_active: { _eq: true }} limit: 20'
              const toDate = moment()
                .utc()
                .format(TIMESTAMPTZ_FORMAT);
              const fromDate = moment()
                .subtract(2, 'weeks')
                .utc()
                .format(TIMESTAMPTZ_FORMAT);
              const items = useObservableSource(() =>
                SuggestCourseModel.find(
                  ` args: {
                    allow_all_category_vn: ${!ctx.apply('routeStore.isUnitzVN')},
                    allow_all_category_app: ${!ctx.apply('routeStore.isUnitzApp')},
                  },
                  ${CourseFormatter.queryCourseViewing()()}
                  limit: 18 `,
                  `
                  id
                  slug
                  name
                  advisor {
                    profile {
                      avatar_url
                      display_name
                    }
                  }
                  session_duration
                  start_at
                  sessions {
                    repeat_pattern
                    type
                    start_at
                  }
                  price_amount
                  price_currency
                  pricing_type
                  photo_url
                  video_url
                  status
                  rooms (order_by: { end_at: asc_nulls_last}, limit: 3, where: {${CourseRoomModelFormatter.getExactlyUpcomeRoomCondition()()}}){
                    id
                    start_at
                    end_at
                  }
                  views_aggregate(where: {_and: [{updated_at: {_lte: "${toDate}"}}, {updated_at: {_gte: "${fromDate}"}}]}) {
                    aggregate {
                      count
                    }
                  }
                `
                )
              );
              // const courses = useSubjectSource(ctx.get('highlightCourses'));
              return items;
            }),
            // '@itemsTwoRow': hook((ctx) => {
            //   const courses = ctx.get
            //   console.log({ courseTwoRow: courses });
            //   return _.chunk(courses, 2);
            // }),
          },
        },
      ],
    ],
  },
});

export default bindData;
