import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { caretRight } from 'react-icons-kit/fa/caretRight';
import { Subtitle, Description, Titlespan2 } from '../../components/title/index';
import PreviewIcon from '../../components/icon';
// import './video.scss';

function Video() {
  return (
    <section className="video-wrapper" id="video">
      <DIV>
        <div className="gradient-color overlay"></div>
        <div className="video-content-block main-title-wrapper">
          <Titlespan2
            Class="sitemain-subtitle"
            Name={ctx.apply('ctf.findEntry', { name: 'videoItem1' }, 'shortText', '')}
          />
          <Subtitle
            Class="site-subtitle2"
            Name={ctx.apply('ctf.findEntry', { name: 'videoItem1' }, 'longText.longText', '')}
          />

          <Description
            Class="video-dec"
            Name={ctx.apply('ctf.renderRichText', { name: 'videoItem1' }, 'richText', null)}
          />
          <div className="video-btn">
            <span>
              <PreviewIcon icon={caretRight} />
            </span>
          </div>
        </div>
      </DIV>
    </section>
  );
}

export default Video;
