import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  carouselContainer: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'EducationTestimonialSection' });
              const children = _.get(section, 'sections', []).map((section) => {
                return ctx.apply('ctf.findEntry', section);
              });
              return children;
            }),
            carouselOptions: hook(() => {
              return {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
