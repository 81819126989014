import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import moment from 'moment';

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'YYYY/MM/DD';

const handlers = {
  'validate/conflict_course_session': ({ params }) => {
    const course = _.get(params, 'course');
    return (
      <DIV>
        <span>{ctx.apply('i18n.t', 'Course.Info.Validation.conflictSessionCourse')}</span>
        <a
          className="mx-1"
          target="_blank"
          href={ctx.apply('routeStore.toUrl', 'courseDetail', course)}
          rel="noreferrer"
        >
          {_.get(course, 'name')}
        </a>
      </DIV>
    );
  },
  'validate/conflict_room': ({ params }) => {
    const course = _.get(params, 'course');
    const room = _.get(params, 'room');
    return (
      <DIV>
        <span>{ctx.apply('i18n.t', 'Course.Info.Validation.conflictSessionCourse')}</span>
        <a
          className="mx-1"
          target="_blank"
          href={ctx.apply('routeStore.toUrl', 'courseDetail', course)}
          rel="noreferrer"
        >
          {_.get(course, 'name')}
        </a>
        <div>{`${moment(_.get(room, 'start_at')).format(`${DATE_FORMAT} ${TIME_FORMAT}`)} - ${moment(
          _.get(room, 'end_at')
        ).format(TIME_FORMAT)}`}</div>
      </DIV>
    );
  },
  'validate/conflict_session': ({ params }) => {
    const sessionIndex = _.get(params, 'sessionIndex');
    return (
      <DIV>
        <span>{ctx.apply('i18n.t', 'Course.Info.Validation.conflictSession', { index: sessionIndex + 1 })}</span>
      </DIV>
    );
  },
  'validate/session_occurence_min': ({ params }) => {
    const session_count = _.get(params, 'session_count');
    const session_occurence = _.get(params, 'session_occurence');
    return (
      <DIV>
        <span>
          {ctx.apply('i18n.t', 'Course.Info.Validation.session_occurence_min', { session_count, session_occurence })}
        </span>
      </DIV>
    );
  },
  'validate/session_occurence_max': ({ params }) => {
    const session_count = _.get(params, 'session_count');
    const session_occurence = _.get(params, 'session_occurence');
    return (
      <DIV>
        <span>
          {ctx.apply('i18n.t', 'Course.Info.Validation.session_occurence_max', { session_count, session_occurence })}
        </span>
      </DIV>
    );
  },
};

const RenderError = (error) => {
  const code = _.get(error, 'code');
  const renderer = _.get(handlers, code);
  if (_.isFunction(renderer)) {
    return renderer(error);
  }
  return null;
};

export default RenderError;
