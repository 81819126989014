import graphme from '@unitz/graphme';
import hasuraClient from '@vl/mod-clients/hasuraApp';
import GetUserProfile from 'unitz-gql/user_profile/GetUserProfile.gql';
import HSDefinition from '../HSDefinition';

graphme.GqlBuilder.loadDocument({
  GetUserProfile,
});

class UserProfileModel extends graphme.BaseModel {
  static DEFINITION = HSDefinition.create({
    name: 'UserProfile',
    schema: {
      id: String,
      display_name: String,
      user_id: String,
    },
    foreignKeys: ['user_id'],
    key: 'id',

    baseQuery: 'GetUserProfile',
    GQL_ACTIONS: {
      GET: 'user_profile_by_pk',
      INSERT: 'insert_user_profile_one',
      UPDATE: 'update_user_profile_by_pk',
      DELETE: 'delete_user_profile_by_pk',
    },
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }

  static async updateProfile({ user_id, display_name, email, phone_number }) {
    const query = hasuraClient.gql`
      mutation updateUserProfile($user_id: String!, $display_name: String!, $email:  String!, $phone_number: String!) {
        profile: update_user_profile(
          where: {user_id: {_eq: $user_id}}, 
          _set: {display_name: $display_name,  email: $email, phone_number: $phone_number }
          ) {
          returning {
            user_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        display_name,
        email,
        phone_number,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ UserProfileModel });

export default UserProfileModel;
