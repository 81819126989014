import React from 'react';

export const FlatList = (props) => {
  const { data, renderItem, keyExtractor, as, ...rest } = props;
  if (as) {
    const AsComponent = as;
    return (
      <AsComponent className={props.className} {...rest}>
        {data.map((item, index) => {
          return <React.Fragment key={keyExtractor(item, index)}>{renderItem({ item, index })}</React.Fragment>;
        })}
      </AsComponent>
    );
  }

  return (
    <div className={props.className}>
      {data.map((item, index) => {
        return <React.Fragment key={keyExtractor(item, index)}>{renderItem({ item, index })}</React.Fragment>;
      })}
    </div>
  );
};

export default FlatList;
