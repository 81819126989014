import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import {
  formatDuration,
  TIME_HOUR_MINUTE_FORMAT,
  START_TIME_HOUR_MINUTE_FORMAT,
  END_TIME_HOUR_MINUTE_FORMAT,
  END_TIME_HOUR_MINUTE_FULL_FORMAT,
} from '@vl/mod-utils/datetime';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import moment from 'moment';

export const CourseRoomTimeSlot = () => {
  return (
    <DIV>
      <span>
        {(() => {
          const $start_at = moment(ctx.get('@item.start_at'));
          const $end_at = moment(ctx.get('@item.end_at'));
          const $now = moment();
          let start_at_format = $start_at.isSame($end_at, 'day')
            ? START_TIME_HOUR_MINUTE_FORMAT
            : TIME_HOUR_MINUTE_FORMAT;
          let end_at_format = $end_at.isSame($now, 'day')
            ? END_TIME_HOUR_MINUTE_FORMAT
            : END_TIME_HOUR_MINUTE_FULL_FORMAT;
          return [
            CourseFormatter.replaceSACH()(moment(ctx.get('@item.start_at')).format(start_at_format)),
            CourseFormatter.replaceSACH()(moment(ctx.get('@item.end_at')).format(end_at_format)),
          ].join(' - ');
        })()}
      </span>
    </DIV>
  );
};

export const CourseRoomTimeCountdown = ({ render }) => {
  return (
    <DIV>
      {(() => {
        const $now = moment();
        const $start_at = moment(ctx.get('@item.start_at'));
        const $end_at = moment(ctx.get('@item.end_at'));
        if (moment().isBetween($start_at, $end_at)) {
          const duration = formatDuration(moment.duration($end_at.diff($now)));
          return render ? render(duration) : duration;
        }
        return null;
      })()}
    </DIV>
  );
};

export default CourseRoomTimeSlot;
