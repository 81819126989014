import React from 'react';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

const resolveTargetVal = (target) => {
  if (target && _.isFunction(target.toObject)) {
    return target.toObject();
  }
  return target;
};

export const useObservableSource = (targetFn, ...params) => {
  const ref = React.useRef({});
  ref.current.targetSubject = React.useMemo(() => {
    const targetSubject = new BehaviorSubject([]);
    (async () => {
      let target = typeof targetFn === 'function' ? targetFn() : targetFn;
      target = await target;
      // seed init data before subscribe
      const nextVal = resolveTargetVal(target);
      targetSubject.next(nextVal);

      // subscribe if needed
      if (target && target.observe) {
        const sub = target.observe();
        ref.current.subscriptions = sub.subscribe((val) => {
          targetSubject.next(resolveTargetVal(val));
        });
      }
    })();
    return targetSubject;
    // eslint-disable-next-line
  }, [ref, ...params]);

  React.useEffect(() => {
    return () => {
      if (ref.current.subscriptions) {
        ref.current.subscriptions.unsubscribe();
      }
      ref.current = {};
    };
  }, []);
  return ref.current.targetSubject;
};

export default useObservableSource;
