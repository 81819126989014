import React from 'react';
import _ from 'lodash';

const xss = require('xss');

export const renderer = {
  renderHTML: () => (itemData) => {
    if (!itemData) return '';
    const cleanHTML = xss(itemData);
    return React.createElement('p', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
  longText: () => (itemData) => {
    const detail = _.get(itemData, 'longText.longText', '');
    if (!detail) return '';
    const cleanHTML = xss(detail);
    return React.createElement('p', {
      dangerouslySetInnerHTML: { __html: cleanHTML },
    });
  },
};

export default renderer;
