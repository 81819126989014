import _ from 'lodash';
import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import RESABLE from '@vl/redata/RESABLE.macro';
import { resable } from '@vl/redata/RESABLE';
import { Link } from '@uz/unitz-components-web/Link';
import { Avatar } from '@uz/unitz-components-web/Avatar';

const View8 = () => {
  return (
    <DIV className="AdvisorSearchItem">
      <Link external to={ctx.apply('routeStore.toUrl', 'advisor', ctx.get('@item'))}>
        <div className="p-2 flex flex-row justify-between items-center">
          <div className="flex flex-row justify-between items-center">
            <RESABLE>
              {!!resable.for('avatarUrl')(ctx.get('@item.profile.avatarUrl')) && (
                <Avatar
                  alt={ctx.get('@item.profile.displayName')}
                  className="object-cover rounded-full"
                  size={{ xs: 24, md: 42 }}
                  src={resable.for('avatarUrl')(ctx.get('@item.profile.avatarUrl'), '')}
                />
              )}
            </RESABLE>
            <div className="text-left text-main pl-3 font-semibold">{ctx.get('@item.profile.displayName')}</div>
          </div>

          <div>{gstyles.icons({ name: 'arrow-right2', size: 20, fill: gstyles.colors.main })}</div>
        </div>
      </Link>
    </DIV>
  );
};

export default displayName(View8);
