import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetSearchCourse from 'unitz-gql/course/GetSearchCourse.gql';

graphme.GqlBuilder.loadDocument({
  GetSearchCourse,
});

class SearchCourseModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'SearchCourse',
    schema: {
      id: String,
      is_active: Boolean,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetSearchCourse',

    GQL_ACTIONS: {
      FIND: 'search_course',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ SearchCourseModel });

export default SearchCourseModel;
