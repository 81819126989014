import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import { Avatar } from '@uz/unitz-components-web/Avatar';

const CustomParapraph = styled.div`
  p > p {
    color: ${gstyles.colors.main};
  }
`;
const CustomLongText = styled.div`
  p > p {
    color: ${gstyles.colors.sub};
  }
`;

export const AdvisorRegistratorTestimonialItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const item = ctx.get('item');
      return (
        <div>
          <Row className="text-center items-center" gutter={[24, 16]}>
            <Col xs={24} sm={12}>
              <div className="h-full w-full flex flex-col">
                <div>
                  <Avatar
                    srcParams={{ fit: 'fill', fm: 'webp' }}
                    size={168}
                    src={ctx.apply('ctf.findImage', item, 'image', '')}
                    alt={ctx.get('itemData.shortText')}
                  />
                </div>
                <div className="font-normal text-base min-lg:text-xl mt-6">
                  {_.get(ctx.get('itemData'), 'shortText', '')}
                </div>
                <div className="mt-1">
                  <CustomLongText>
                    {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                      className: 'text-sm',
                      as: 'p',
                    })}
                  </CustomLongText>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="px-4 min-md:px-0 min-lg:px-6">
                <CustomParapraph>
                  {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'detailText.detailText', {
                    className: 'text-main',
                    as: 'p',
                  })}
                </CustomParapraph>
              </div>
            </Col>
          </Row>
        </div>
      );
    })}
  </DIV>
);

export default AdvisorRegistratorTestimonialItem;
