import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Menu, Space } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

// const CustomDropDownContent = styled.div`
//   .ant-menu {
//     padding: 8px 0;
//   }
//   .ant-sub-menu {
//     border-radius: 8px;
//   }
// `;

function DesktopView() {
  return (
    <DIV className="component">
      <DIV>
        <Menu expandIcon={<span>{gstyles.icons({ name: 'arrow-right', size: 24, fill: gstyles.colors.sub })}</span>}>
          {ctx.get('categories', []).map((cat, index) => {
            if (_.get(cat, 'children') === null) {
              return (
                <Menu.Item key={`${_.get(cat, 'id', '')}_${index}`} className="bg-white500 hover:bg-brandb-lightest">
                  <Link
                    className="text-lg leading-10"
                    target="_blank"
                    to={ctx.apply('routeStore.toUrl', 'category', cat)}
                  >
                    <Space size="small">
                      {gstyles.icons({
                        name: _.get(cat, 'icon', 'replace'),
                        size: 24,
                        fill: gstyles.colors.brandb500,
                      })}
                      <span className="text-main text-base font-normal">{_.get(cat, 'displayName', '')}</span>
                    </Space>
                  </Link>
                </Menu.Item>
              );
            }
            return (
              <Menu.SubMenu
                onTitleMouseLeave={() => {}}
                popupOffset={[0, 0]}
                key={_.get(cat, 'id')}
                style={{ borderRadius: 8, overflow: 'hidden' }}
                popupClassName="rounded-xl hover:bg-brandb-lightest shadow-xl"
                title={
                  <Space size="small">
                    {gstyles.icons({ name: _.get(cat, 'icon', 'replace'), size: 24, fill: gstyles.colors.brandb500 })}
                    <span className="text-main text-base font-normal">{_.get(cat, 'displayName', '')}</span>
                  </Space>
                }
              >
                {_.map(_.get(cat, 'children', []), (item) => {
                  const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id', '') });
                  if (!itemData) return null;
                  return (
                    <Menu.Item
                      key={`${_.get(cat, 'id', '')}_${index}`}
                      className="bg-white500 hover:bg-brandb-lightest"
                    >
                      <Link
                        className="text-lg leading-10"
                        target="_blank"
                        to={ctx.apply('routeStore.toUrl', 'category', cat)}
                      >
                        <Space size="small">
                          {gstyles.icons({
                            name: _.get(itemData, 'icon', 'replace'),
                            size: 24,
                            fill: gstyles.colors.brandb500,
                          })}
                          <span className="text-main text-base font-normal">{_.get(itemData, 'displayName', '')}</span>
                        </Space>
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>
            );
          })}
        </Menu>
        {/* </CustomDropDownContent> */}
      </DIV>
    </DIV>
  );
}

export default DesktopView;
