const _ = require('lodash');
const fetch = require('node-fetch');
const firebase = require('firebase/app');
const modConfig = require('@vl/mod-config');

const options = {};

const getOptions = () => {
  const options = {
    jwtToken: modConfig.get('HASURA_GRAPHQL_JWT_TOKEN'),
  };
  return options;
};

const useEmulator = (baseUrl) => {
  console.log('[FB] Using functions emulator', baseUrl);
  _.set(options, 'baseUrl', baseUrl);
};

let instance = null;

const getFirebase = () => {
  if (typeof window !== 'undefined') {
    const config = {
      // apiKey: '',
      // authDomain: '',
      // databaseURL: '',
      // projectId: '',
      // storageBucket: '',
      // messagingSenderId: '',
      // appId: '',
      // measurementId: '',
    };

    if (instance) return instance;
    if (firebase.apps.length === 0) {
      instance = firebase.initializeApp(config);
      // firebase.initializeApp({});
    }
    return instance;
  }

  return null;
};
const getBaseUrl = _.memoize(() => {
  const fib = getFirebase();
  const projectId = _.get(fib, 'options.projectId', 'unitz-app');
  const zoneId = 'us-central1';
  if (process.env.FIB_FUNCTIONS_EMULATOR) {
    useEmulator(`${new URL(`${projectId}/${zoneId}/`, process.env.FIB_FUNCTIONS_EMULATOR)}`);
  }

  return _.get(options, 'baseUrl') || `https://${zoneId}-${projectId}.cloudfunctions.net`;
});

const resolveEndpoint = (endpoint, params) => {
  const url = new URL(endpoint, getBaseUrl());
  if (params) {
    if (_.isString(params)) {
      const searchParams = new URLSearchParams(params);
      searchParams.forEach((val, key) => {
        url.searchParams.set(key, val);
      });
    } else if (_.isPlainObject(params)) {
      for (const key of Object.keys(params)) {
        url.searchParams.set(key, params[key]);
      }
    }
  }
  return url.href;
};

const resolveHeaders = (headers) => {
  const options = getOptions();
  const extraHeaders = {};
  if (options.jwtToken) {
    _.assign(extraHeaders, {
      Authorization: `Bearer ${options.jwtToken}`,
    });
  }

  return {
    ...extraHeaders,
    ...headers,
    ...(process.env.GATSBY_APP_ENV ? { 'unitz-env': process.env.GATSBY_APP_ENV } : {}),
  };
};

exports.getClient = _.memoize(() => {
  return {
    get: (endpoint, params) => {
      return fetch(resolveEndpoint(endpoint, params), {
        headers: resolveHeaders(),
      }).then((response) => response.json());
    },

    post: (endpoint, params, headers) => {
      return fetch(resolveEndpoint(endpoint), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...resolveHeaders(headers),
        },
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    put: (endpoint, params, headers) => {
      return fetch(resolveEndpoint(endpoint), {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...resolveHeaders(headers),
        },
        body: JSON.stringify(params),
      }).then((response) => response.json());
    },

    call: (endpoint, data) => {
      return firebase.functions().httpsCallable(endpoint)(data);
    },
    url: (endpoint, params, headers) => {
      return resolveEndpoint(endpoint, params, headers);
    },
    useEmulator,
  };
});
