import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
// import CourseModel from '@uz/unitz-models/CourseModel';
import SuggestCourseModel from '@uz/unitz-models/SuggestCourseModel';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import useObservableSource from '@vl/hooks/useObservableSource';

const bindData = bindings({
  LandingCourseListLayout: {
    rules: [
      [
        'data',
        {
          data: {
            highlightCourses: hook((ctx) => {
              // 'where: {is_active: { _eq: true }} limit: 20'
              const items = useObservableSource(() =>
                SuggestCourseModel.find(
                  ` args: {
                    allow_all_category_vn: ${!ctx.apply('routeStore.isUnitzVN')},
                    allow_all_category_app: ${!ctx.apply('routeStore.isUnitzApp')},
                  },
                  ${CourseFormatter.queryCoursePublishedOnprogress()()}
                  order_by: { scoring_int: desc_nulls_last },
                  limit: 18 `,
                  `
                  id
                  slug
                  name
                  advisor {
                    profile {
                      avatar_url
                      display_name
                    }
                  }
                  session_duration
                  start_at
                  sessions {
                    repeat_pattern
                    type
                    start_at
                  }
                  price_amount
                  price_currency
                  pricing_type
                  photo_url
                  video_url
                  status
                `
                )
              );
              return items;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
