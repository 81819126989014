const _ = require('lodash');

const unitMap = [
  { value: 1024 * 1024 * 1024, unit: 'Gb' },
  { value: 1024 * 1024, unit: 'Mb' },
  { value: 1024, unit: 'Kb' },
];

exports.filesizeLabel = (size) => {
  const foundIndex = unitMap.findIndex(({ value }) => size > value);
  if (foundIndex >= 0) {
    const found = unitMap[foundIndex];
    return `${_.truncate(_.round(size / found.value, 2), 4)}${found.unit}`;
  }
};

const imageParams = {
  // w: 40,
  // h: 40,
  // center: null,
  // top: null,
  // right: null,
  // left: null,
  // bottom: null,
  // round
  // r: null,
  // quality
  // q: null,
};

const toParamsObject = (params) => {
  const rtn = {};
  let urlParams;
  if (!params) return {};
  if (_.isString(params)) {
    urlParams = new URLSearchParams(params);
  } else if (params instanceof URLSearchParams) {
    urlParams = params;
  } else if (_.isPlainObject(params)) {
    _.assign(rtn, params);
  }
  if (urlParams) {
    urlParams.forEach((value, key) => {
      rtn[key] = value;
    });
  }
  return rtn;
};

const reduceSizeProp = (size) => {
  let rtn = size;
  if (_.isPlainObject(size)) {
    rtn = 1;
    _.map(size, (val, key) => {
      rtn = Math.max(parseInt(val) || 0, rtn);
    });
  }
  return rtn;
};

const createCtfImageUrl = ({ src, size, width, height, quality, srcParams }) => {
  if (src.indexOf('//') === 0) {
    src = `https:${src}`;
  }
  if (!src) return {};
  size = reduceSizeProp(size);
  try {
    const url = new URL(src);
    const params = {
      ...imageParams,
      ...(_.isNumber(size) && { w: size, h: size }),
      ...(() => {
        let dim = [];
        if (_.isString(size) && (dim = size.split('x')) && _.every(dim, _.isNumber)) {
          return { w: dim[0], h: dim[1] };
        }
        return {};
      })(),
      ...(_.isUndefined(size) && _.isUndefined(width) && _.isUndefined(height) && { w: 64, h: 64 }),
      ...(_.isString(size) &&
        _.isUndefined(width) &&
        _.isUndefined(height) &&
        _.get(
          {
            large: { w: 144, h: 144 },
            small: { w: 40, h: 40 },
            default: { w: 64, h: 64 },
            cover: { w: 1100, h: undefined },
          },
          size
        )),
      ...(_.isPlainObject(size) && { w: Math.round(144 * 1.77), h: Math.round(144 * 1.77) }),
      ...(!_.isUndefined(width) && { w: width }),
      ...(!_.isUndefined(height) && { h: height }),
      ...(!_.isUndefined(quality) ? { q: quality } : {}),
      ...toParamsObject(srcParams),
    };

    _.map(params, (val, key) => {
      if (!_.isNull(val)) {
        url.searchParams.set(key, val);
      }
      if (_.isUndefined(val)) {
        url.searchParams.delete(key);
      }
    });
    // clean up some params
    url.searchParams.delete('token');
    url.searchParams.delete('alt');

    return {
      src: url.toString(),
    };
  } catch (err) {
    console.log('src error', src, err);
    return {};
  }
};

const storageUrlPath = 'https://firebasestorage.googleapis.com/v0/b/unitz-app.appspot.com/o';
const unitzAssetUrlPath = 'unitz-asset.web.app';

const createImageUrl = (props) => {
  if (`${props.src}`.indexOf('images.ctfassets.net') >= 0 || `${props.src}`.indexOf(unitzAssetUrlPath) >= 0) {
    return createCtfImageUrl({
      ...props,
    });
  }
  // storage url

  if (`${props.src}`.indexOf(storageUrlPath) >= 0) {
    return createCtfImageUrl({
      ...props,
      src: `https://${unitzAssetUrlPath}${decodeURIComponent(props.src.replace(storageUrlPath, ''))}`,
    });
  }
  return { ...props };
};

exports.createImageUrl = createImageUrl;

const resizeImageUrl = (src, options) => {
  const rtn = createImageUrl({ src, ...options });
  return _.get(rtn, 'src');
};

exports.resizeImageUrl = resizeImageUrl;

exports.ensureWindowRetryHandlers = () => {
  const retryCounters = new WeakMap();
  const retryTimers = new WeakMap();
  const MAX_RETRY = 10;
  if (window && !window.retryImageUrl) {
    window.retryImageUrl = function(that) {
      if (!retryCounters.has(that)) {
        retryCounters.set(that, 1);
      }
      const counter = retryCounters.get(that);
      if (counter < MAX_RETRY) {
        that.onerror = null;
        retryTimers.set(
          that,
          setTimeout(() => {
            const url = new URL(that.src);
            url.searchParams.set('ts', Date.now());
            that.src = url.toString();
            that.onerror = function() {
              window.retryImageUrl && window.retryImageUrl(this);
            };
          }, 5000 * counter * Math.sqrt(counter))
        );
      }
    };
  }
  if (window && !window.clearRetryImageUrl) {
    window.clearRetryImageUrl = function(that) {
      if (retryTimers.has(that)) {
        clearTimeout(retryTimers.get(that));
        retryTimers.delete(that);
      }
    };
  }

  if (window && !window.retryVideoUrl) {
    window.retryVideoUrl = function(that) {
      if (!retryCounters.has(that)) {
        retryCounters.set(that, 1);
      }
      const counter = retryCounters.get(that);
      if (counter < MAX_RETRY) {
        retryTimers.set(
          that,
          setTimeout(() => {
            const url = new URL(that.src);
            url.searchParams.set('ts', Date.now());
            that.src = url.toString();
            retryCounters.set(that, counter + 1);
          }, 10000 * counter * Math.sqrt(counter))
        );
      }
    };
  }

  if (window && !window.clearRetryVideoUrl) {
    window.clearRetryVideoUrl = function(that) {
      if (retryTimers.has(that)) {
        clearTimeout(retryTimers.get(that));
        retryTimers.delete(that);
      }
    };
  }
};
