/**
 * Set slide video
 *
 * @param {node} slide
 * @param {object} data
 * @param {int} index
 * @param {function} callback
 */
import {
  has,
  closest,
  injectAssets,
  addClass,
  removeClass,
  createHTML,
  isFunction,
  waitUntil,
} from '../utils/helpers.js';
import defaults from '../defaults';

export default function renderVideo(data, index, callback) {
  // const slide = create(defaults.slideHTML);
  const slide = createHTML(defaults.slideHTML);
  const settings = defaults;
  const slideContainer = slide.querySelector('.ginner-container');
  const videoID = `gvideo${index}`;
  const slideMedia = slide.querySelector('.gslide-media');
  // const videoPlayers = this.getAllPlayers();
  const videoPlayers = {};

  addClass(slideContainer, 'gvideo-container');

  slideMedia.insertBefore(createHTML('<div class="gvideo-wrapper"></div>'), slideMedia.firstChild);

  const videoWrapper = slide.querySelector('.gvideo-wrapper');

  injectAssets(settings.plyr.css, 'Plyr');

  let url = data.href;
  let protocol = location.protocol.replace(':', '');
  let videoSource = '';
  let embedID = '';
  let customPlaceholder = false;

  if (protocol === 'file') {
    protocol = 'http';
  }
  slideMedia.style.maxWidth = data.width;

  injectAssets(settings.plyr.js, 'Plyr', () => {
    // Set vimeo videos
    if (url.match(/vimeo\.com\/([0-9]*)/)) {
      const vimeoID = /vimeo.*\/(\d+)/i.exec(url);
      videoSource = 'vimeo';
      embedID = vimeoID[1];
    }

    // Set youtube videos
    if (
      url.match(/(youtube\.com|youtube-nocookie\.com)\/watch\?v=([a-zA-Z0-9\-_]+)/) ||
      url.match(/youtu\.be\/([a-zA-Z0-9\-_]+)/) ||
      url.match(/(youtube\.com|youtube-nocookie\.com)\/embed\/([a-zA-Z0-9\-_]+)/)
    ) {
      const youtubeID = getYoutubeID(url);
      videoSource = 'youtube';
      embedID = youtubeID;
    }

    // Set local videos
    if (url.match(/\.(mp4|ogg|webm|mov)$/) !== null) {
      videoSource = 'local';
      let html = `<video id="${videoID}" `;
      html += `style="background:#000; max-width: ${data.width};" `;
      html += 'preload="metadata" ';
      html += 'x-webkit-airplay="allow" ';
      html += 'playsinline ';
      html += 'controls ';
      html += 'class="gvideo-local">';

      let format = url
        .toLowerCase()
        .split('.')
        .pop();
      let sources = { mp4: '', ogg: '', webm: '' };
      format = format === 'mov' ? 'mp4' : format;
      sources[format] = url;

      for (let key in sources) {
        if (sources.hasOwnProperty(key)) {
          let videoFile = sources[key];
          if (data.hasOwnProperty(key)) {
            videoFile = data[key];
          }
          if (videoFile !== '') {
            html += `<source src="${videoFile}" type="video/${key}">`;
          }
        }
      }
      html += '</video>';
      customPlaceholder = createHTML(html);
    }

    // prettier-ignore
    const placeholder = customPlaceholder ? customPlaceholder : createHTML(`<div id="${videoID}" data-plyr-provider="${videoSource}" data-plyr-embed-id="${embedID}"></div>`);

    addClass(videoWrapper, `${videoSource}-video gvideo`);
    videoWrapper.appendChild(placeholder);
    videoWrapper.setAttribute('data-id', videoID);
    videoWrapper.setAttribute('data-index', index);

    const playerConfig = has(settings.plyr, 'config') ? settings.plyr.config : {};
    const player = new Plyr(`#${videoID}`, playerConfig);

    player.on('ready', (event) => {
      const instance = event.detail.plyr;
      videoPlayers[videoID] = instance;
      if (isFunction(callback)) {
        callback();
      }
    });
    waitUntil(
      () => {
        return slide.querySelector('iframe') && slide.querySelector('iframe').dataset.ready === 'true';
      },
      () => {
        // this.resize(slide);
      }
    );
    player.on('enterfullscreen', handleMediaFullScreen);
    player.on('exitfullscreen', handleMediaFullScreen);
  });

  return slide;
}

/**
 * Get youtube ID
 *
 * @param {string} url
 * @returns {string} video id
 */
function getYoutubeID(url) {
  let videoID = '';
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    videoID = url[2].split(/[^0-9a-z_\-]/i);
    videoID = videoID[0];
  } else {
    videoID = url;
  }
  return videoID;
}

/**
 * Handle fullscreen
 *
 * @param {object} event
 */
function handleMediaFullScreen(event) {
  const media = closest(event.target, '.gslide-media');

  if (event.type === 'enterfullscreen') {
    addClass(media, 'fullscreen');
  }
  if (event.type === 'exitfullscreen') {
    removeClass(media, 'fullscreen');
  }
}
