import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import cx from 'classnames';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';

const CustomParagraph = styled.div``;

const FieldFilterItem = () => {
  return (
    <DIV>
      <div
        onClick={() => {
          ctx.apply('form.setFieldValue', 'section', ctx.get('@item.ctfName'));
        }}
        className={cx(
          'flex-grow flex-1 cursor-pointer flex justify-center p-2 rounded-lg text-sm lg:text-base font-semibold',
          {
            'text-white500 duration-100 ease-in-out bg-brandb': ctx.get('@item.isSelected'),
            'text-main bg-white500 duration-100 ease-in-out border border-border': !ctx.get('@item.isSelected'),
          }
        )}
      >
        <div className="flex flex-col space-y-2 justify-center items-center">
          <span>
            {gstyles.icons({
              name: ctx.get('@item.icon'),
              size: 32,
              fill: ctx.get('@item.isSelected') ? gstyles.colors.white500 : gstyles.colors.brandb500,
            })}
          </span>
          <span className="text-base md:text-xl font-semibold">{ctx.get('@item.displayName')}</span>
        </div>
      </div>
    </DIV>
  );
};

const FieldSelectionLayout = () => {
  return (
    <DIV className="FieldSelectionLayout">
      <FormProvider form={ctx.get('form')}>
        <Form>
          <div className="rounded-lg shadow-md bg-white500">
            {ctx.apply('ctf.renderProps', () => {
              return (
                <div>
                  <div className="section-header px-4 py-6">
                    {_.map(ctx.get('sectionData.nodes'), (section, index) => (
                      <div key={index}>
                        {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                      </div>
                    ))}
                  </div>
                  {ctx.get(`sectionState.sectionState.${ctx.get('parentSectionName')}`) !== 'hide' && (
                    <div>
                      <div className="px-4">
                        <CustomParagraph>
                          {ctx.apply(
                            'ctf.renderHTMLText',
                            { name: ctx.get('sectionData.name', '') },
                            'longText.longText',
                            null
                          )}
                        </CustomParagraph>
                      </div>
                      <div className="flex mx-4 mb-8 space-x-4 jusify-between">
                        {_.map(ctx.get('sections'), (item, index) => {
                          return (
                            <React.Fragment key={`section_${index}`}>
                              <DIV forceCtx>
                                {ctx.debug(() => {
                                  ctx.set('@item', {
                                    ...item,
                                    isSelected: ctx.get('form.values.section') === _.get(item, 'ctfName'),
                                  });
                                  ctx.set('@index', index);
                                })}
                                <FieldFilterItem />
                              </DIV>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <DIV forceCtx>
                        {ctx.apply('ctf.renderEntry', { name: ctx.get('form.values.section') }) || null}
                      </DIV>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Form>
      </FormProvider>
    </DIV>
  );
};

export default FieldSelectionLayout;
