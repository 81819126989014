import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            menuData: hook((ctx) => {
              return _.compact([
                {
                  href: '/info',
                  id: 'info',
                  text: ctx.apply('i18n.t', 'ProfileUser.infoProfile'),
                  icon: 'account',
                  show: true,
                },
                {
                  href: '/message',
                  id: 'message',
                  text: ctx.apply('i18n.t', 'Profile.message'),
                  icon: 'message',
                  badge: true,
                  show: true,
                },
                {
                  href: '/notification',
                  id: 'notification',
                  text: ctx.apply('i18n.t', 'Profile.notification'),
                  icon: 'notification',
                  badge: true,
                  show: true,
                },
                !ctx.apply('routeStore.isUnitzVN')
                  ? {
                      href: '/wallet',
                      id: 'wallet',
                      text: ctx.apply('i18n.t', 'Profile.myWallet'),
                      icon: 'account_balance_wallet',
                      show: true,
                    }
                  : null,
                {
                  href: '/bank',
                  id: 'bank',
                  text: ctx.apply('i18n.t', 'ProfileUser.listCard'),
                  icon: 'bank-cards',
                  show: true,
                },
                {
                  href: '/transaction',
                  id: 'transaction',
                  text: ctx.apply('i18n.t', 'Profile.transactionHistory'),
                  icon: 'receipt',
                  show: true,
                },
                {
                  href: '/setting',
                  id: 'setting',
                  text: ctx.apply('i18n.t', 'ProfileUser.setting'),
                  icon: 'setting',
                  show: true,
                },
              ]);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
