import { BaseModel } from '@uz/unitz-models/BaseModel';
import _ from 'lodash';
import { GqlBuilder } from '@unitz/gqlbuilder';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export default class PaginationModel extends BaseModel {
  state = {
    items: [],
    page: 0,
    isLoading: false,
    isLoadMore: false,
    hasPreviousPage: false,
    hasNextPage: true,
    hasLoadMore: true,
    pageSize: 0,
    total: 0,
  };

  constructor(...args) {
    super(...args);
    this.constructor.initState(this);
    this.pageSize = 0;
    this.totalPage = 0;
    this.dataLoader = null;
    this.config = {};
    this.windows = {};
  }

  static fromDataLoader(dataLoader) {
    // Hàm để load thêm dữ liệu từ bên ngoài. Gọi query bên ngoài.
    try {
      const instance = new PaginationModel();
      instance.dataLoader = dataLoader;
      return instance;
    } catch (err) {
      return null;
    }
  }

  static fromConfig(config = {}) {
    let { dataQuery, aggQuery, Model, pageSize, varsList, getVars } = config;

    pageSize = pageSize || 12;
    const instance = new PaginationModel();
    const buildPageQuery = _.memoize(() => {
      const pageQuery = GqlBuilder.from(`
        query PaginationQuery($limit: Int, $offset: Int ${varsList ? `,${varsList}` : ''}) {
          ${dataQuery}
          items_agg: ${aggQuery}
        }
      `);
      pageQuery.update({
        alias: 'items',
        arguments: ({ node }) => node.merge('limit: $limit, offset: $offset'),
      });
      return pageQuery;
    });

    const buildAggQuery = _.memoize(() => {
      const pageQuery = GqlBuilder.from(`
        query AggPaginationQuery${varsList ? `(${varsList})` : ''} {
          items_agg: ${aggQuery}
        }
      `);
      return pageQuery;
    });

    const buildPageSubsQuery = _.memoize(() => {
      const pageQuery = GqlBuilder.from(`
        query PaginationQuery($limit: Int, $offset: Int ${varsList ? `,${varsList}` : ''}) {
          ${dataQuery}
        }
      `);
      pageQuery.update({
        alias: 'items',
        arguments: ({ node }) => node.merge('limit: $limit, offset: $offset'),
      });
      return pageQuery;
    });

    instance.dataLoader = async (opts) => {
      instance.config = config;
      instance.setState({ isLoading: true });
      const page = _.get(opts, 'page') || 0;

      const client = await Model.getClient();
      const offset = page * pageSize;
      const vars = _.isFunction(getVars) ? await getVars(opts) : {};

      const windowKey = page;
      const windowData = instance.ensureWindowKey(windowKey);
      const pageQuery = buildPageQuery();

      if (config.subscription) {
        instance.setState({ isLoading: true });
        const pageQuery = buildPageSubsQuery();
        const aggQuery = buildAggQuery();
        const client = await Model.getClient();
        const vars = _.isFunction(getVars) ? await getVars(opts) : {};
        try {
          windowData.subscription = await new Promise((res) => {
            const unsubscribe = _.get(windowData, 'subscriptionRef.subs.unsubscribe');
            _.isFunction(unsubscribe) && unsubscribe();
            const ref = {};
            windowData.subscriptionRef = ref;
            ref.subs = client
              .subscribe(pageQuery.setOperation('subscription').toString(), {
                ...vars,
                limit: pageSize,
                offset,
              })
              .subscribe({
                next({ data }) {
                  if (!ref.inited) {
                    res(ref.subs);
                    ref.inited = true;
                  }
                  if (data) {
                    windowData.items = _.get(data, 'items');
                    windowData.$last_fetch_at = moment();
                    const items = instance.getWindowItems();
                    instance.setState({
                      items,
                    });
                    instance.setState({ isLoading: false });
                  }
                },
              });
          });
          if (!instance.agg_subscription) {
            instance.agg_subscription = await new Promise((res) => {
              const unsubscribe = _.get(instance, 'agg_subscriptionRef.subs.unsubscribe');
              _.isFunction(unsubscribe) && unsubscribe();
              const ref = {};
              instance.agg_subscriptionRef = ref;
              ref.subs = client
                .subscribe(aggQuery.setOperation('subscription').toString(), {
                  ...vars,
                })
                .subscribe({
                  next({ data }) {
                    if (!ref.inited) {
                      res(ref.subs);
                      ref.inited = true;
                    }
                    if (data) {
                      const itemsCount = _.get(data, 'items_agg.aggregate.count') || 0;
                      instance.setState({
                        total: itemsCount,
                      });
                    }
                  },
                  error(err) {
                    console.log(`err: ${pageQuery.toString()}`, err);
                  },
                });
            });
          }
        } catch (err) {
          console.log(`err: ${pageQuery.toString()}`, err);
        }

        instance.setState({ isLoading: false });
      } else {
        try {
          const rtn = await client.request(pageQuery.toString(), {
            ...vars,
            limit: pageSize,
            offset,
          });

          const totalItem = _.get(rtn, 'items_agg.aggregate.count') || 0;
          const totalPage = _.ceil(totalItem / pageSize);
          instance.setState({ isLoading: false });

          windowData.items = _.get(rtn, 'items', []);
          windowData.itemsCount = _.get(rtn, 'items_agg.aggregate.count') || 0;
          windowData.$last_fetch_at = moment();
          return {
            items: _.get(rtn, 'items', []),
            total: totalItem,
            totalPage,
            pageSize,
            page,
            hasPreviousPage: page > 0,
            hasNextPage: page < totalPage - 1,
          };
        } catch (err) {
          instance.setState({ isLoading: false });
          console.log(`err: ${pageQuery.toString()}`, err);
        }
      }
      const items = instance.getWindowItems();
      const totalItem = instance.getState('total') || 0;
      const totalPage = _.ceil(totalItem / pageSize);

      return {
        items,
        total: totalItem,
        totalPage,
        pageSize,
        page,
        hasPreviousPage: page > 0,
        hasNextPage: page < totalPage - 1,
      };
    };
    return instance;
  }

  async onRefresh() {
    await this.onLoadPage(0);
  }

  async onLoadMore() {
    try {
      const hasNextPage = this.useState('hasNextPage');
      if (!hasNextPage) return;

      const items = this.getState('items');
      const page = this.getState('page');
      const newPage = page + 1;
      const res = await this.dataLoader({ page: newPage });

      this.setState({
        ...res,
        items: _.uniqBy([...items, ...res.items], (item) => _.get(item, 'id')),
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      this.setState({
        isLoadMore: false,
      });
    }
  }

  async onLoadPage(page) {
    try {
      const isLoading = this.useState('isLoading');
      if (isLoading) {
        return;
      }

      const res = await this.dataLoader({ page });
      this.setState({
        ...res,
      });
    } catch (error) {
      console.log('error', error);
    }
  }

  onPreviousPageHandler() {
    const hasPreviousPage = this.useState('hasPreviousPage');
    if (hasPreviousPage) {
      this.onLoadPage(this.getState('page') - 1);
    }
  }

  onNextPageHandler() {
    const hasNextPage = this.useState('hasNextPage');
    if (hasNextPage) {
      this.onLoadPage(this.getState('page') + 1);
    }
  }

  onLoadPageHandler(newPage) {
    this.onLoadPage(newPage);
  }

  ensureWindowKey(key) {
    if (!_.has(this.windows, [key])) {
      _.set(this.windows, [key], {
        items: [],
        $last_fetch_at: moment()
          .subtract(1, 'day')
          .utc(),
      });
    }
    return _.get(this.windows, [key]);
  }

  getWindowItems() {
    let rtn = [];
    for (let windowKey in this.windows) {
      rtn = rtn.concat(_.get(this.windows[windowKey], 'items', []));
    }
    return rtn;
  }
}
