import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Collapse } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const { Panel } = Collapse;

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
  }
`;

export const AdvisorFAQItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      // const itemProps = ctx.get('itemProps');
      return (
        <Panel header={_.get(itemData, 'shortText', '')} key={itemData.id}>
          <CustomParagraph>
            {ctx.apply('ctf.renderHTMLText', { name: itemData.name }, 'longText.longText', {
              className: 'text-ellipsis-3',
              as: 'p',
            })}
          </CustomParagraph>
        </Panel>
      );
    })}
  </DIV>
);

export default AdvisorFAQItem;
