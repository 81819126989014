import _ from 'lodash';
import React from 'react';
import { bindings, hook } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import i18n from 'i18n-js';
import { LANG_MAP_FULL } from '@uz/mod-translations/constants';

export const I18nProvider = bindings({
  i18nProvider: {
    rules: [
      [
        'data',
        {
          data: {
            i18n: hook((ctx) => {
              return {
                ...i18n,
                n: (...params) => {
                  if (params.length > 0 && typeof params[0] === 'string') {
                    return i18n.t(...params);
                  }

                  return _.get(params, '0.text');
                },
                rt: (...params) => {
                  const rtn = params[0];
                  return rtn;
                },
                suffixSelection: (value) => {
                  const activeLang = ctx.apply('REF.getRef', 'activeLanguage');
                  const locale = _.get(LANG_MAP_FULL, activeLang, '');
                  return value && locale ? `:${value}_${locale}` : '';
                },
              };
            }),
          },
        },
      ],
    ],
  },
})(({ children }) => <DIV className="i18nProvider">{children}</DIV>);

export default I18nProvider;
