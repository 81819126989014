import _ from 'lodash';
import graphme from '@unitz/graphme';

import GetUser from 'unitz-gql/user/GetUser.gql';
import hasuraClient from '@vl/mod-clients/hasuraApp';

import UserProfileModel from '@uz/unitz-models/UserProfileModel';

graphme.GqlBuilder.loadDocument({
  GetUser,
});

class UserModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'User',
    schema: {
      id: String,
      is_active: Boolean,
      user_id: String,
      display_name: String,
      avatar_url: String,
      phone_number: String,
    },
    nodes: [['profile', UserProfileModel, { column_mapping: { id: 'user_id' } }]],
    key: 'id',

    getForeignKeys: ['user_id'],

    foreignKeysMapping: {
      user_id: ['user', 'id'],
    },

    baseQuery: 'GetUser',

    GQL_ACTIONS: {
      FIND: 'user',
      GET: 'user_by_pk',
      INSERT: 'insert_user_one',
      INSERT_MANY: 'insert_user_profile',
      UPDATE: 'update_user_by_pk',
      DELETE: 'delete_user_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }

  async getDisplayName() {
    return this.get('profile.display_name') || 'Unknown';
  }

  async getAvatarUrl() {
    return this.get('profile.avatar_url', 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50');
  }

  static async updateProfile({ user_id, display_name, phone_number, avatar_url }) {
    const query = hasuraClient.gql`
      mutation updateProfile($user_id: String!, $display_name: String!, $phone_number: String!, $avatar_url: String!) {
        transaction: update_user_profile(where: {user_id: {_eq: $user_id}}, _set: {display_name: $display_name, phone_number: $phone_number, avatar_url: $avatar_url}) {
          returning {
            display_name
            avatar_url
            phone_number
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        display_name,
        phone_number,
        avatar_url,
      });

      const data = _.get(rtn, 'transaction');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async addCategoryFavorite({ created_by, objects }) {
    const query = hasuraClient.gql`
      mutation addCategoryFavorite($created_by: String!, $objects: [favorite_category_insert_input!]!) {
        delete_favorite_category(where: {created_by: {_eq: $created_by}}) {
          affected_rows
        }
        favorite: insert_favorite_category(objects: $objects, on_conflict: {constraint: favorite_category_target_id_created_by_key, update_columns: score}) {
          returning {
            id
            score
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        created_by,
        objects,
      });

      const data = _.get(rtn, 'favorite');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateAvatar({ user_id, avatar_url }) {
    const query = hasuraClient.gql`
      mutation updateAvatar($user_id: String!, $avatar_url: String!) {
        profile: update_user_profile(where: {user_id: {_eq: $user_id}}, _set: {avatar_url: $avatar_url}) {
          returning {
            user_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        user_id,
        avatar_url,
      });

      const data = _.get(rtn, 'profile.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}
graphme.model({ UserModel });

export default UserModel;
