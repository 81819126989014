import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col } from 'antd';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const Title = styled.div`
  p {
    font-weight: bold;
    color: ${gstyles.colors.main};
  }
`;

const Paragraph = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

export const UserGuideStep = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemProps = ctx.get('itemProps');
      return (
        <div className="h-full p-4 text-center border border-divider rounded-xl">
          <h4 className="mb-4 text-xl font-semibold text-center text-brandb500">
            {ctx.apply(
              'ctf.findEntry',
              ctx.get('itemData'),
              ...[].concat(_.get(itemProps, 'args') || ['shortText', ''])
            )}
          </h4>
          <div className="flex justify-center items-center mb-4">
            {gstyles.icons({
              name: ctx.get('itemData.className'),
              size: 93,
            })}
          </div>
          <Title>
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
              className: 'my-3 text-sm font-medium',
            })}
          </Title>

          <Paragraph>
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'detailText.detailText', {
              className: 'text-xs text-ellipsis-4',
            })}
          </Paragraph>
        </div>
      );
    })}
  </DIV>
);

export default UserGuideStep;
