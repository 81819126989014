import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'Categories' });
              return {
                section,
              };
            }),
            items: hook((ctx) => {
              const category = ctx.get('ctfCategories.0');
              const children = _.map(_.get(category, 'children', []), (item) => {
                const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });
                return itemData;
              });
              return {
                category,
                children,
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
