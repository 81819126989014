import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { Menu, Space } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
`;
function AuthMenuCounterPartyLayout() {
  return (
    <DIV className="component">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <CustomDropDownContent className="shadow-none xl:shadow-lg">
            <Menu>
              {_.map(ctx.get('sectionData.sections'), (item, index) => {
                const data = ctx.apply('ctf.findEntry', { name: _.get(item, 'name') });
                return (
                  <Menu.Item className="px-3 hover:bg-brandb-lightest" key={index} onItemHover={() => {}}>
                    <Link
                      className="text-lg leading-10"
                      target="_blank"
                      to={ctx.apply('routeStore.toLocale', _.get(data, 'linkHref', ''))}
                    >
                      <Space>
                        {gstyles.icons({ name: 'star', fill: gstyles.colors.yellow500, size: 24 })}
                        <span className="text-main text-base font-normal">{_.get(item, 'shortText', '')}</span>
                      </Space>
                    </Link>
                  </Menu.Item>
                );
              })}
            </Menu>
          </CustomDropDownContent>
        );
      })}
    </DIV>
  );
}

export default AuthMenuCounterPartyLayout;
