import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Alert } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem } from 'formik-antd';
import CustomInput from '../CustomInput';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function CourseRoomMessageModal() {
  return (
    <DIV className="courseRoomMessageModal">
      <Modal
        title={ctx.apply('i18n.t', 'CourseRoom.modals.message.title')}
        visible={ctx.get('form.isModalVisible')}
        onOk={ctx.get('form.handleSubmit')}
        okText={ctx.apply('i18n.t', 'CourseRoom.modals.message.okText')}
        onCancel={ctx.get('form.handleCancel')}
        cancelText={ctx.apply('i18n.t', 'CourseRoom.modals.message.cancelText')}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          id: 'submit',
          disabled: !ctx.get('form.canSubmit'),
          loading: ctx.get('form.isSubmitting'),
          className: 'px-8',
        }}
        destroyOnClose
        afterClose={() => ctx.apply('modalModel.hide')}
      >
        {ctx.get('form.isModalVisible') && (
          <FormProvider form={ctx.get('form')}>
            <Form {...layout}>
              {ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              <Alert message={ctx.apply('i18n.t', 'CourseRoom.modals.message.infoMessage')} type="info" />
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
}
