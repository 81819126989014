import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const Styling = styled.div`
  .ant-row {
    .column_1 {
      > div > div {
        margin-bottom: 70px;
        @media only screen and (max-width: 400px) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;

const AboutUsVisionAndMissionLayout = () => {
  return (
    <DIV className="AboutUsVisionAndMissionLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <Styling className="wrapper app-row py-0 md:py-16">
            <div>
              <Row gutter={[{ xs: 0, lg: 72 }, 24]}>
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col
                      key={index}
                      xs={24}
                      lg={12}
                      className={`${ctx.get('sectionData.className')} column_${index}`}
                      key={index}
                    >
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Styling>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsVisionAndMissionLayout;
