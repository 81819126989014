import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import cx from 'classnames';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';

export const ExternalLink = () => {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const itemData = ctx.get('itemData');
        const itemProps = ctx.get('itemProps');
        return (
          <Link
            className={cx(
              ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
              _.get(itemProps, 'className')
            )}
            aria-label={_.get(itemData, 'shortText')}
            to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref'))}
            target="_blank"
          >
            {gstyles.icons({
              name: ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', ''])),
              fill: _.isNull(_.get(itemData, 'className'))
                ? gstyles.colors.sub
                : _.get(gstyles, `colors.${_.get(itemData, 'className')}`),
              size: 24,
            })}
          </Link>
        );
      })}
    </DIV>
  );
};

export default ExternalLink;
