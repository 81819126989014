import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';

const CustomText = styled.div`
  h3 {
    margin-bottom: 0;
    p {
      font-size: 24px;
      font-weight: 600;
      color: ${gstyles.colors.main};
      margin-bottom: 0;
    }
  }
`;

const CustomTextSM = styled.div`
  h3 {
    margin-bottom: 0;
    p {
      font-size: 14px;
      font-weight: 600;
      color: ${gstyles.colors.main};
      margin-bottom: 0;
    }
  }
`;

const CustomParagraph = styled.div`
  h3 {
    margin-bottom: 0;
    p {
      font-size: 24px;
      font-weight: 600;
      color: ${gstyles.colors.white500};
      margin-bottom: 0;
    }
  }
`;

const CustomParagraphSM = styled.div`
  h3 {
    margin-bottom: 0;
    p {
      font-size: 14px;
      font-weight: 600;
      color: ${gstyles.colors.white500};
      margin-bottom: 0;
    }
  }
`;

function AdvisorSupportCenterMenuItem() {
  return (
    <DIV className="component">
      {ctx.apply('ctf.renderProps', () => {
        if (ctx.get('itemData.slug') === ctx.get('tabData')) {
          return (
            <Link to={ctx.apply('routeStore.toLocale', ctx.get('itemData.linkHref'))}>
              <div className="border-2 rounded-xl bg-brandb500 shadow-sm md:shadow-md xl:shadow-sm w-full">
                <Row className="flex-col justify-center items-center xl:text-left lg:py-8 md:px-2 xl:px-4 py-3 px-1">
                  <Col clas xs={24} md={3}>
                    <div className="flex justify-center items-center">
                      {gstyles.icons({
                        name: ctx.get('itemData.shortText'),
                        size: 32,
                        fill: gstyles.colors.white500,
                      })}
                    </div>
                  </Col>
                  <Col xs={24} md={21}>
                    <div className="flex justify-center text-center md:px-1 xl:px-0 md:justify-start items-center md:items-start">
                      <ResponsiveProps
                        xs={{
                          renderer: () => (
                            <CustomParagraphSM>
                              {ctx.apply(
                                'ctf.renderHTMLText',
                                { name: ctx.get('itemData.name') },
                                'longText.longText',
                                {
                                  as: 'h3',
                                }
                              )}
                            </CustomParagraphSM>
                          ),
                        }}
                        lg={{
                          renderer: () => (
                            <CustomParagraph>
                              {ctx.apply(
                                'ctf.renderHTMLText',
                                { name: ctx.get('itemData.name') },
                                'longText.longText',
                                {
                                  as: 'h3',
                                }
                              )}
                            </CustomParagraph>
                          ),
                        }}
                      >
                        {(resProps) => !!resProps && resProps.renderer()}
                      </ResponsiveProps>
                    </div>
                  </Col>
                </Row>
              </div>
            </Link>
          );
        }
        return (
          <Link to={ctx.apply('routeStore.toLocale', ctx.get('itemData.linkHref'))}>
            <div className="border-2 rounded-xl md:shadow-md xl:shadow-sm bg-white500 w-full">
              <Row className="flex-col justify-center items-center xl:text-left lg:py-8 md:px-2 xl:px-4 py-3 px-1">
                <Col xs={24} md={3}>
                  <div className="flex justify-center items-center">
                    {gstyles.icons({
                      name: ctx.get('itemData.shortText'),
                      size: 32,
                      fill: gstyles.colors.brandb500,
                    })}
                  </div>
                </Col>
                <Col xs={24} md={21}>
                  <div className="flex justify-center text-center text-ellipsis-1 md:px-1 xl:px-0 md:justify-start items-center md:items-start">
                    <ResponsiveProps
                      xs={{
                        renderer: () => (
                          <CustomTextSM>
                            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                              as: 'h3',
                            })}
                          </CustomTextSM>
                        ),
                      }}
                      lg={{
                        renderer: () => (
                          <CustomText>
                            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name') }, 'longText.longText', {
                              as: 'h3',
                            })}
                          </CustomText>
                        ),
                      }}
                    >
                      {(resProps) => !!resProps && resProps.renderer()}
                    </ResponsiveProps>
                  </div>
                </Col>
              </Row>
            </div>
          </Link>
        );
      })}
    </DIV>
  );
}

export default AdvisorSupportCenterMenuItem;
