const timezoneList = require('./timezones.json');
const _ = require('lodash');
const moment = require('moment');
const mergeRanges = require('@vl/mod-utils/mergeRanges');

const convertOffset = (gmt_offset) => {
  let time = gmt_offset.toString().split('.');
  let hour = parseInt(time[0]);
  let negative = hour < 0;
  hour = Math.abs(hour) < 10 ? `0${Math.abs(hour)}` : Math.abs(hour);
  hour = negative ? `-${hour}` : `+${hour}`;
  return time[1] ? `${hour}:${(time[1] * 6).toString()}` : `${hour}:00`;
};

const getListTimezone = () => {
  return _.map(_.sortBy(timezoneList, 'offset', 'asc'), (ele) => {
    return {
      ...ele,
      name: ele.text,
      fullValue: `UTC ${convertOffset(ele.offset)}`,
      value: convertOffset(ele.offset),
    };
  });
};

exports.getListTimezone = getListTimezone;

exports.getZoneInfo = (timeZoneName) => {
  return _.find(getListTimezone(), ({ utc }) => utc.includes(timeZoneName));
};

const splitRange = ($start, $end) => {
  const rtn = [];
  if (!$start.isSame($end, 'day')) {
    rtn.push([$start, $start.clone().endOf('day')]);
    rtn.push([$start.clone().add(1, 'day').startOf('day'), $end]);
  } else {
    rtn.push([$start, $end]);
  }
  return rtn;
};

const convertUTCToOtherTz = (time, newTz, dw) => {
  return moment.utc(time, 'HH:mm').day(_.toNumber(dw)).utcOffset(newTz);
};

const convertOtherTzToUTC = (time, newTz, dw) => {
  const day = moment().day(_.toNumber(dw)).format('YYYY-MM-DD');
  const newTime = moment(`${day}${time}${newTz}`, 'YYYY-MM-DDHH:mmZ');
  return newTime.utc();
};

const formatTime = (time) => {
  if (!time) return '00:00';
  return time.format('HH:mm');
};

const getDoW = (date, tz) => {
  return date.utcOffset(tz).day();
};

const transformEvents = (events, timezone) => {
  return _.flatten(
    _.map(events, (evt, index) => {
      const dw = _.get(evt, 'dayOfWeek');
      const start_at = convertUTCToOtherTz(evt.start_at, timezone, dw);
      const end_at = convertUTCToOtherTz(evt.end_at, timezone, dw);
      const range = splitRange(start_at, end_at);

      return _.map(range, (rtn, stt) => {
        const dayOfWeek = getDoW(_.get(rtn, '0'), timezone);
        const uid = `ev${index + stt}`;
        return {
          ...evt,
          start_at: formatTime(_.get(rtn, '0')),
          end_at: formatTime(_.get(rtn, '1')),
          dayOfWeek,
          id: `${dayOfWeek}-${uid}`,
        };
      });
    })
  );
};

const transformUTCEvents = (events, timezone) => {
  return _.flatten(
    _.map(events, (evt, index) => {
      const dw = _.get(evt, 'dayOfWeek');
      const start_at = convertOtherTzToUTC(evt.start_at, timezone, dw);
      const end_at = convertOtherTzToUTC(evt.end_at, timezone, dw);
      const range = splitRange(start_at, end_at);

      return _.map(range, (rtn, stt) => {
        const dayOfWeek = getDoW(_.get(rtn, '0'), '+00:00');
        const uid = `ev${index + stt}`;
        return {
          id: `${dayOfWeek}-${uid}`,
          dayOfWeek,
          start_at: formatTime(_.get(rtn, '0')),
          end_at: formatTime(_.get(rtn, '1')),
        };
      });
    })
  );
};

const mergeTimeIfValid = (itemData) => {
  return mergeRanges(itemData, (strA, strB) => moment(strA, 'HH:mm') - moment(strB, 'HH:mm'), -60000);
};

exports.mergeTimeIfValid = mergeTimeIfValid;

exports.transformUTCEvents = transformUTCEvents;

exports.transformEvents = transformEvents;
