import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import cx from 'classnames';

import SectionWrapper from '../../components/SectionWrapper';

// import './footer.scss';
import _ from 'lodash';

function AuthFooter() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <SectionWrapper className="bg-brandb500 lg:px-4" as="div">
            <div className="border-b md:border-0 lg:border-b wrapper app-row md:px-4 md:pb-0 lg:pb-5 md:mx-0 md:w-full lg:mx-auto lg:px-0 bg-brandb">
              <div className="grid grid-cols-12 gap-0 md:gap-6 lg:gap-8">
                {_.get(ctx.apply('ctf.findEntry', { name: 'authFooterSection' }), 'sections', []).map((col, index) => {
                  return (
                    <div className={cx(col.className)} key={`col_${index}`}>
                      <div className="footer-logo-wrapper">
                        {ctx.apply('ctf.findEntry', { name: col.name }, 'shortText', '') ? (
                          <h6 className="mb-3 text-sm font-semibold text-white500 hidden sm:block">
                            {ctx.apply('ctf.findEntry', { name: col.name }, 'shortText', '')}
                          </h6>
                        ) : (
                          <div className="m-0"></div>
                        )}

                        <React.Fragment key={`${col.name}_${index}`}>
                          {ctx.apply('ctf.renderEntry', { name: col.name }) || null}
                        </React.Fragment>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="hidden md:block lg:hidden border-b mx-12"></div>
            <div className="py-3 mb-0 text-sm font-normal text-center text-white500">
              {_.get(ctx.apply('ctf.findEntry', { name: 'FooterSection' }), 'shortText', '')}
            </div>
          </SectionWrapper>
        );
      })}
    </DIV>
  );
}

export default AuthFooter;
