import React from 'react';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Subtitle, Description, Titlespan2, Title } from '../../components/title/index';
import Image from '../../components/image/index';
import Progressbar from './progressbar';
// import './skills.scss';
// import '../../assets/scss/variable.scss';

function Skills() {
  return (
    <section className="skills-wrapper" id="skills">
      <DIV>
        <Row>
          <Col md={6} sm={0}>
            <div className="skills-image">
              <Image Path={ctx.apply('ctf.findImage', { name: 'skillItem1' }, 'image', '', { width: 620 })} />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="skills-content-block main-title-wrapper">
              <Titlespan2
                Class="sitemain-subtitle"
                Name={ctx.apply('ctf.findEntry', { name: 'skillItem1' }, 'shortText', '')}
              />
              <Subtitle
                Class="site-subtitle2"
                Name={ctx.apply('ctf.findEntry', { name: 'skillItem1' }, 'longText.longText', '')}
              />

              <Description
                Class="skills-dec"
                Name={ctx.apply('ctf.renderRichText', { name: 'skillItem1' }, 'richText', null)}
              />
              <div className="progress-outer-block">
                <Title Class="progress-title" Name="Our Best Skill" />
                <div className="progressbar-wrapper-block">
                  <div className="progressbar-block">
                    <Progressbar
                      Class="progressbar1"
                      Percenteg="96"
                      ProgressTitle="Strategies"
                      ProgressClass="progressbar-title"
                    />
                    <Progressbar
                      Class="progressbar2"
                      Percenteg="88"
                      ProgressTitle="Planning"
                      ProgressClass="progressbar-title"
                    />
                    <Progressbar
                      Class="progressbar3"
                      Percenteg="90"
                      ProgressTitle="Customer Support"
                      ProgressClass="progressbar-title"
                    />
                    <Progressbar
                      Class="progressbar4"
                      Percenteg="94"
                      ProgressTitle="Finance"
                      ProgressClass="progressbar-title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </DIV>
    </section>
  );
}

export default Skills;
