import React from 'react';
import _ from 'lodash';
import { ctx, bindings, hook } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import * as Layouts from '@uz/unitz-ctf-theme/layouts';
import * as Actions from '@uz/unitz-ctf-theme/actions';
import * as Enhancers from '@uz/unitz-ctf-theme/enhancers';
import * as Items from '@uz/unitz-ctf-theme/items';

import richTextRender from './richTextRender';
import htmlTextRender from './htmlTextRender';

const EntryContext = React.createContext({});

const useContent = () => {
  const container = React.useContext(EntryContext);
  return container;
};

const isSectionType = (data) => {
  return _.get(data, 'sys.contentType.sys.id') === 'section';
};

const isItemType = (data) => {
  return _.get(data, 'sys.contentType.sys.id') === 'item';
};

const normalizeCond = (cond) => {
  if (_.isPlainObject(cond)) {
    // only pick name and id
    return _.pick(cond, ['name', 'id']);
  }
  return cond;
};

const CtfContext = bindings({
  pageItem: {
    rules: [
      [
        'data',
        {
          data: {
            ctf: hook(() => {
              const container = useContent();
              const entries = React.useMemo(() => {
                const entries = [
                  ..._.get(container, 'allContentfulItem.nodes', []),
                  ..._.get(container, 'allContentfulSection.nodes', []),
                ];
                return entries;
              }, [container]);
              const nodes = React.useMemo(() => {
                const nodes = [
                  ..._.get(container, 'allContentfulItem.nodes', []),
                  ..._.get(container, 'allContentfulSection.nodes', []),
                  ..._.get(container, 'allContentfulCategory.nodes', []),
                  ..._.get(container, 'allContentfulPage.nodes', []),
                  ..._.get(container, 'allContentfulHelpCenter.nodes', []),
                  ..._.get(container, 'allContentfulAsset.nodes', []),
                ];
                return nodes;
              }, [container]);
              const ctf = React.useMemo(() => {
                return {
                  getNodes: (nodePath) => {
                    return _.get(container, nodePath);
                  },

                  findNode: (cond, selector, def) => {
                    if (_.isPlainObject(cond)) {
                      cond = _.pick(cond, ['name', 'id']);
                    }
                    const node = _.find(nodes, cond);
                    if (selector !== undefined) {
                      return _.get(node, selector, def);
                    }
                    return node || def;
                  },

                  findEntry: (cond, selector, def) => {
                    if (_.isPlainObject(cond)) {
                      cond = _.pick(cond, ['name', 'id']);
                    }
                    const entry = _.find(entries, cond);
                    if (selector !== undefined) {
                      return _.get(entry, selector, def);
                    }
                    return entry || def;
                  },

                  findImage: (cond, selector, def, options) => {
                    options = options || {};
                    const imgUrl = ctf.findEntry(cond, [..._.toPath(selector), 'fixed', 'src']);
                    return imgUrl || def;
                  },

                  renderRichText: (cond, selector, def, options) => {
                    const content = ctf.findEntry(cond, selector);
                    if (!content) return def;
                    return richTextRender(content, options);
                  },

                  renderHTMLText: (cond, selector, props) => {
                    const content = ctf.findEntry(cond, selector);
                    if (!content) return null;
                    return htmlTextRender({ content, ...props });
                  },

                  renderSectionChild: (index, options) => {
                    // find section entry
                    const sectionData = ctx.get('sectionData');
                    const sections = _.get(sectionData, 'sections');
                    let childContent;
                    if (_.isString(index) || _.isLength(index)) {
                      childContent = _.get(sections, `${index}`);
                    } else {
                      childContent = _.find(sections, normalizeCond(index));
                    }

                    if (!childContent) return null;
                    // check for content Type: item or section
                    if (isSectionType(childContent)) {
                      return ctf.renderSection(_.pick(childContent, ['name', 'id']), options);
                    }
                    if (isItemType(childContent)) {
                      return ctf.renderItem(_.pick(childContent, ['name', 'id']), options);
                    }

                    return null;
                  },
                  findSectionChild: (index, selector, def, options) => {
                    // find section entry
                    const sectionData = ctx.get('sectionData');
                    const sections = _.get(sectionData, 'sections');
                    let childContent;
                    if (_.isString(index) || _.isLength(index)) {
                      childContent = _.get(sections, `${index}`);
                    } else {
                      childContent = _.find(sections, normalizeCond(index));
                    }
                    if (!childContent) return null;
                    // check for content Type: item or section
                    if (isSectionType(childContent)) {
                      return ctf.findSection(_.pick(childContent, ['name', 'id']), selector, def, options);
                    }
                    if (isItemType(childContent)) {
                      return ctf.findEntry(_.pick(childContent, ['name', 'id']), selector, def, options);
                    }

                    return null;
                  },

                  findSection: (cond, selector, def) => {
                    const sections = _.get(container, 'allContentfulSection.nodes');
                    let section;
                    if (_.isString(cond) || _.isLength(cond)) {
                      section = _.get(sections, `${cond}`);
                    } else {
                      section = _.find(sections, normalizeCond(cond));
                    }
                    // const section =  _.find(sections, cond);
                    if (selector !== undefined) {
                      return _.get(section, selector, def);
                    }
                    return section || def;
                  },

                  findPage: (cond, def) => {
                    const pages = _.get(container, 'allContentfulPage.nodes');
                    const page = _.find(pages, cond);
                    return page || def;
                  },

                  renderSection: (cond, options) => {
                    const section = ctf.findSection(cond);
                    if (section) {
                      options = options || {};
                      // use section layout to render section
                      const render = _.get(options, 'render');
                      const LayoutComponent = Layouts.resolveLayout(section);
                      if (LayoutComponent || _.isFunction(render)) {
                        return (
                          <DIV forceCtx>
                            {ctx.set('sectionData', section)}
                            {ctx.set('sectionProps', Layouts.resolveProps(section, { ctx, options }))}
                            {ctf.renderEnhancers(
                              section,
                              LayoutComponent ? <LayoutComponent {...options} /> : render()
                            )}
                          </DIV>
                        );
                      }
                    }
                    return null;
                  },

                  renderItem: (cond, options) => {
                    const item = ctf.findEntry(cond);
                    if (item) {
                      options = options || {};
                      // use section layout to render section
                      const render = _.get(options, 'render');
                      const Component = Items.resolveComponent(item, { ctx, options }) || _.get(options, 'Component');
                      if (Component || _.isFunction(render)) {
                        return (
                          <DIV forceCtx>
                            {ctx.set('itemData', item)}
                            {ctx.set('itemProps', Items.resolveProps(item, { ctx, options }))}
                            {ctf.renderEnhancers(item, Component ? <Component {...options} /> : render())}
                          </DIV>
                        );
                      }
                    }
                    return null;
                  },

                  renderEntry: (cond, options) => {
                    const entry = ctf.findEntry(cond);
                    if (entry) {
                      const entryType = _.get(entry, 'sys.contentType.sys.id');
                      const render = _.get(ctf, `render${_.upperFirst(entryType)}`);
                      if (_.isFunction(render)) {
                        return render(cond, options);
                      }
                      return null;
                    }
                    return null;
                  },

                  renderEnhancers: (item, children) => {
                    const EnhancerComponent = Enhancers.resolveEnhancer(item);
                    if (!EnhancerComponent) return children;
                    return <EnhancerComponent item={item}>{children}</EnhancerComponent>;
                  },

                  renderProps: (renderer) => {
                    return renderer();
                  },

                  resolveAction: (item) => {
                    const action = Actions.resolveAction(item);
                    return action;
                  },

                  buildCategoryHierarchy: _.memoize(() => {
                    const categories = ctf.getNodes('allContentfulCategory.nodes') || [];

                    const categoryMapById = _.keyBy(categories, 'id');
                    // build direct parent
                    categories.map((item) => {
                      _.map(_.get(item, 'children', []), (childItem) => {
                        const childId = _.get(childItem, 'id');
                        _.set(categoryMapById, `${childId}.parent`, item);
                      });
                    });
                    // build parents path
                    categories.map((item) => {
                      const parents = [];
                      let curNode = item;
                      const maxLevel = 10;
                      do {
                        const parent = _.get(curNode, 'parent');
                        if (parent) {
                          parents.unshift(parent);
                          curNode = categoryMapById[_.get(parent, 'id')];
                        } else {
                          curNode = null;
                        }
                      } while (curNode && parents.length <= maxLevel);
                      _.set(item, 'parents', parents);
                    });
                    return categories;
                  }),
                };
              });
              return ctf;
            }),
          },
        },
      ],
    ],
  },
})(({ children }) => <DIV className="pageItem">{children}</DIV>);

const GbContentfulProvider = ({ data, children }) => {
  return (
    <EntryContext.Provider value={data}>
      <CtfContext>{children || null}</CtfContext>
    </EntryContext.Provider>
  );
};

export default GbContentfulProvider;
