import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import styled from 'styled-components';

const StyledWrapper = styled.section`
  * {
    &::selection {
      background: #ccc;
      color: #333;
    }
    &::-moz-selection {
      background: #ccc;
      color: #333;
    }
    &::-webkit-selection {
      background: #ccc;
      color: #333;
    }
  }
`;

function InformationFooterLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <StyledWrapper className="info-group py-0" id="info-group">
            <DIV>
              <div className="py-5 mb-3 sm:mb-0 sm:py-0">
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (col, index) => (
                    <React.Fragment key={`${_.get(col, 'name', '')}_${index}`}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                    </React.Fragment>
                  )
                )}
              </div>
            </DIV>
          </StyledWrapper>
        );
      })}
    </DIV>
  );
}

export default InformationFooterLayout;
