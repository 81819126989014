import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import Link from '@uz/unitz-components-web/Link';
import FlatList from '@uz/unitz-components-web/FlatList';
import gstyles from '@vl/gstyles';

import { getTodayDate } from '@vl/mod-utils/dateFormatter';

import renderCoureRoomItem from '@uz/unitz-components-web/CourseRoomItemRender';

const View8 = () => (
  <DIV className="advisorSchedule">
    <div className="flex justify-between">
      <div>
        <h3 className="text-base font-semibold flex">
          <span>{gstyles.icons({ name: 'today', size: 24, fill: gstyles.colors.sub })}</span>
          <span className="ml-2">
            {`${ctx.apply('i18n.t', 'AdvisorProfileGeneral.TodaySchedule')} ${getTodayDate()}`}
          </span>
        </h3>
      </div>
      <div>
        <Link to={ctx.apply('routeStore.toLocale', '/calendar')} className="block mx-auto text-base font-semibold">
          {ctx.apply('i18n.t', 'AdvisorProfileGeneral.Detail')}
        </Link>
      </div>
    </div>
    <FlatList
      data={ctx.get('courseRooms', [])}
      renderItem={renderCoureRoomItem}
      keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
    />
  </DIV>
);

export default displayName(View8);
