import { paddings, margins } from './styleguide/spacings';
import { Icons, icons, getFilterCss } from './styleguide/icons/index.web';
// eslint-disable-next-line
const cssPurge = [
  'lg:py-6 lg:w-16 w-4 lg:w-10 lg:flex-row lg:px-4 bg-brandb-lightest bg-yellow-lightest bg-blue-lightest bg-red-lightest sm:col-span-3 sm:col-span-4 md:col-span-2 md:col-span-4 md:col-span-3 lg:grid-cols-3 lg:col-span-4 grid-cols-1 xl:w-32 h-8 xl:h-10 lg:col-span-2 md:order-2 sm:col-span-4 md:col-span-12 md:w-1/2 md:mx-auto bg-red-lighter sm:rounded-xl xl:bg-white500 xl:text-brandb500 md:pl-4 md:my-3 md:opacity-100 md:p-12 md:border md:space-y-2 md:py-16 md:text-5xl md:w-24 md:h-36 md:mx-0 sm:mx-auto md:inline-block md:pl-0 sm:block md-mb-8 lg:p-5 lg:rounded-lg lg:shadow-lg lg:px-4 lg:bg-brandb-lighter lg:bg-brandb500 md:order-4 lg:bg-background1 lg:w-28',
];
const colors = require('./styleguide/colors.web');
// import { colors } from './styleguide/colors.web';
const gstyles = {
  colors,
  paddings,
  margins,
  Icons,
  Icon: Icons,
  icons,
  getFilterCss,
};

export default gstyles;
