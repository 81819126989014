import React from 'react';
import { bindings, ctx, hook } from '@vl/redata';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const bindData = bindings({
  LandingHowWeWorkLayout: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
});

export default bindData;
