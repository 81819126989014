import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Menu, Space } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const CustomDropDownContent = styled.div`
  .ant-menu {
    padding: 8px 8px;
  }
  .ant-sub-menu {
    border-radius: 8px;
  }
`;

function MobileView() {
  return (
    <DIV className="component">
      <DIV forceCtx>
        <CustomDropDownContent>
          <Menu
            mode="inline"
            inlineIndent={8}
            expandIcon={(props) => (
              <span className="flex items-center justify-end pl-2">
                {gstyles.icons({
                  name: !props.isOpen ? 'arrow-down' : 'arrow-up',
                  size: 24,
                  fill: gstyles.colors.sub,
                })}
              </span>
            )}
          >
            {_.map(ctx.get('categories.children', []), (cat, index) => {
              const itemData = ctx.apply('ctf.findNode', { id: _.get(cat, 'id', '') });
              let catSlug = { slug: _.get(itemData, 'slug', '') };
              return (
                <Menu.Item key={`${_.get(itemData, 'id', '')}_${index}`} className="bg-white500">
                  <Link
                    className="text-lg leading-10"
                    // target="_blank"
                    external
                    to={ctx.apply('routeStore.toUrl', 'category', catSlug)}
                  >
                    <Space size="large">
                      {gstyles.icons({
                        name: _.get(itemData, 'icon', 'replace'),
                        size: 24,
                        fill: gstyles.colors.brandb500,
                      })}
                      <span className="text-base font-normal text-main">{_.get(itemData, 'displayName', '')}</span>
                    </Space>
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </CustomDropDownContent>
        {/* <CustomDropDownContent>
          <Menu
            mode="inline"
            inlineIndent={8}
            expandIcon={(props) => (
              <span className="flex items-center justify-end pl-2">
                {gstyles.icons({
                  name: !props.isOpen ? 'arrow-down' : 'arrow-up',
                  size: 24,
                  fill: gstyles.colors.sub,
                })}
              </span>
            )}
          >
            {ctx.get('categories', []).map((cat, index) => {
              if (_.get(cat, 'children') === null) {
                return (
                  <Menu.Item key={`${_.get(cat, 'id', '')}_${index}`} className="bg-white500">
                    <Link
                      className="text-lg leading-10"
                      target="_blank"
                      to={ctx.apply('routeStore.toUrl', 'category', cat)}
                    >
                      <Space size="large">
                        {gstyles.icons({
                          name: _.get(cat, 'icon', 'replace'),
                          size: 24,
                          fill: gstyles.colors.brandb500,
                        })}
                        <span className="text-base font-normal text-main">{_.get(cat, 'displayName', '')}</span>
                      </Space>
                    </Link>
                  </Menu.Item>
                );
              }
              return (
                <Menu.SubMenu
                  onTitleMouseLeave={() => {}}
                  className="justify-between bg-white500"
                  key={_.get(cat, 'id')}
                  title={
                    <Space size="small">
                      {gstyles.icons({ name: _.get(cat, 'icon', 'replace'), size: 24, fill: gstyles.colors.brandb500 })}
                      <span className="text-base font-normal text-main">{_.get(cat, 'displayName', '')}</span>
                    </Space>
                  }
                >
                  {_.map(_.get(cat, 'children', []), (item) => {
                    const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id', '') });
                    if (!itemData) return null;
                    return (
                      <Menu.SubMenu
                        onTitleMouseLeave={() => {}}
                        className="px-4 bg-white500"
                        title={
                          <Space size="small">
                            {gstyles.icons({
                              name: _.get(itemData, 'icon', 'replace'),
                              size: 24,
                              fill: gstyles.colors.brandb500,
                            })}
                            <span className="text-base font-normal text-main">
                              {_.get(itemData, 'displayName', '')}
                            </span>
                          </Space>
                        }
                      >
                        {_.map(_.get(itemData, 'children', []), (subItem) => {
                          const subItemData = ctx.apply('ctf.findNode', { id: _.get(subItem, 'id', '') });
                          if (!subItemData) return null;
                          return (
                            <Menu.Item className="bg-white500" key={_.get(subItem, 'id')} onItemHover={() => {}}>
                              <Link
                                className="leading-10"
                                target="_blank"
                                to={ctx.apply('routeStore.toUrl', 'category', subItemData)}
                              >
                                <Space size="small">
                                  {gstyles.icons({
                                    name: 'replace',
                                    size: 24,
                                    fill: gstyles.colors.brandb500,
                                  })}
                                  <span className="text-base font-normal text-main">
                                    {_.get(subItemData, 'displayName', '')}
                                  </span>
                                </Space>
                              </Link>
                            </Menu.Item>
                          );
                        })}
                      </Menu.SubMenu>
                    );
                  })}
                </Menu.SubMenu>
              );
            })}
          </Menu>
        </CustomDropDownContent> */}
      </DIV>
    </DIV>
  );
}

export default MobileView;
