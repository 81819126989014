import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Row, Col } from 'antd';

export const Layout66 = () => {
  return (
    <DIV>
      <section className="about-wrapper gradient-color" id="about">
        <Row>
          <Col md={6}>
            <div className="text-white about-content-block main-title-wrapper">
              {ctx.apply('ctf.renderSectionChild', 0)}
            </div>
          </Col>
          <Col sm={6}>
            <div className="about-image">{ctx.apply('ctf.renderSectionChild', 1)}</div>
          </Col>
        </Row>
      </section>
    </DIV>
  );
};

export default Layout66;
