import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { useRenderProps } from '@vl/hooks/useRenderProps';

import CategoryNewItemX from '@uz/unitz-components-web/CategoryNewItemX';
import FlatList from '@uz/unitz-components-web/FlatList';
import { Row, Col, Button } from 'antd';
// import { Link } from '@uz/unitz-components-web/Link';
// import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const renderItem = useRenderProps(
  ({ item, index }) => {
    return (
      <Col lg={8} md={12} xs={12} className="mb-4 md:mb-0 xl:px-0">
        <DIV forceCtx>
          {ctx.debug(() => {
            ctx.set('@item', item);
            ctx.set('@index', index);
          })}
          <CategoryNewItemX />
        </DIV>
      </Col>
    );
  },
  ({ item, index }) => [index, _.get(item, 'id')]
);

const View8 = () => (
  <DIV className="component categoriesListX 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">
    <FlatList
      as={Row}
      gutter={[24, 24]}
      data={ctx.get('items.children', [])}
      renderItem={renderItem}
      keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
    />
    {/* {ctx.get('items.children.length') > 6 && (
      <Row className="justify-center mt-3 md:mt-9" gutter={24}>
        <Col xs={12} md={12} lg={8}>
          <Link to={ctx.apply('routeStore.toLocale', '/category')}>
            <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
              {(resProps) => (
                <Button block {...resProps} onClick={ctx.get('form.onSubmit')}>
                  <h5 className="mb-0 text-base font-semibold text-brandb500">
                    {ctx.apply('i18n.t', 'Category.allCategory')}
                  </h5>
                </Button>
              )}
            </ResponsiveProps>
          </Link>
        </Col>
      </Row>
    )} */}
  </DIV>
);

export default displayName(View8);
