import _ from 'lodash';
import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetAdvisor from 'unitz-gql/advisor/GetAdvisor.gql';

import '@uz/unitz-models/AdvisorProfileModel';
// import UserModel from '@uz/unitz-models/UserModel';
import '@uz/unitz-models/TransactionModel';
import '@uz/unitz-models/TransactionAggregateModel';

graphme.GqlBuilder.loadDocument({
  GetAdvisor,
});

class AdvisorModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Advisor',
    schema: {
      id: String,
      is_active: Boolean,
    },
    nodes: [
      ['profile', 'AdvisorProfileModel', { column_mapping: { id: 'advisor_id' } }],
      ['transactions', 'TransactionModel.Collection', { column_mapping: { id: 'advisor_id' } }],
      [
        'transactions_aggregate',
        'TransactionAggregateModel',
        { column_mapping: { id: 'advisor_id' }, usePlanSync: true },
      ],
    ],
    key: 'id',

    baseQuery: 'GetAdvisor',

    GQL_ACTIONS: {
      FIND: 'advisor',
      GET: 'advisor_by_pk',
      INSERT: 'insert_advisor_one',
      UPDATE: 'update_advisor_by_pk',
      DELETE: 'delete_advisor_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static async updateFavoriteAdvisor({ created_by, target_id }) {
    const query = hasuraClient.gql`
      mutation updateFavoriteAdvisor($created_by: String!, $target_id: String!) {
        advisor: insert_favorite_advisor_one(object: {created_by: $created_by, target_id: $target_id},
          on_conflict: {constraint: favorite_advisor_target_id_created_by_key, update_columns: score}) {
            id
            score
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        created_by,
        target_id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async removeFavoriteAdvisor({ created_by, target_id }) {
    const query = hasuraClient.gql`
      mutation removeFavoriteAdvisor($created_by: String!, $target_id: String!) {
        advisor: delete_favorite_advisor(where: {target_id: {_eq: $target_id}, created_by: {_eq: $created_by}}) {
          returning {
            id
            score
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        created_by,
        target_id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async activateAccount({ id }) {
    const query = hasuraClient.gql`
      mutation ($id: String!) {
        advisor: update_advisor_by_pk(pk_columns: {id: $id}, _set: {is_active: true}) {
          id
          is_active
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async deActivateAccount({ id }) {
    const query = hasuraClient.gql`
      mutation ($id: String!) {
        advisor: update_advisor_by_pk(pk_columns: {id: $id}, _set: {is_active: false}) {
          id
          is_active
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateStatusAdvisor({ advisor_id, is_accept_service }) {
    const query = hasuraClient.gql`
      mutation updateStatusAdvisor($advisor_id: String!, $is_accept_service: Boolean!) {
        transaction: insert_advisor_readiness(objects: {advisor_id: $advisor_id, is_accept_service: $is_accept_service},
          on_conflict: {constraint: advisor_readiness_advisor_id_key, update_columns: is_accept_service}) {
          returning {
            is_accept_service
            id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        advisor_id,
        is_accept_service,
      });

      const data = _.get(rtn, 'transaction.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async updateAdvisorState({
    advisor_id,
    update_category,
    signed,
    update_course,
    update_information,
    update_profile,
    update_service,
    update_working_time,
  }) {
    const query = hasuraClient.gql`
      mutation updateAdvisorState($advisor_id: String!, $update_category: Boolean!, $signed: String!, $update_course: Boolean!, $update_information: Boolean!, $update_profile: Boolean!, $update_service: Boolean!, $update_working_time: Boolean!) {
        advisorState: insert_advisor_state(objects: [{
            advisor_id: $advisor_id, 
            update_category: $update_category, 
            signed: $signed, 
            update_course: $update_course, 
            update_information: $update_information, 
            update_profile: $update_profile, 
            update_service: $update_service, 
            update_working_time: $update_working_time
          }],
          on_conflict: {
            constraint: advisor_state_advisor_id_key, 
            update_columns: [advisor_id, update_category, signed, update_course, update_information, update_profile, update_service, update_working_time]
          }) {
          returning {
            advisor_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        advisor_id,
        update_category,
        signed,
        update_course,
        update_information,
        update_profile,
        update_service,
        update_working_time,
      });

      const data = _.get(rtn, 'advisorState.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async createService({ service_id, advisor_id, price_amount, per_amount }) {
    const query = hasuraClient.gql`
      mutation createService($service_id: String!,$advisor_id: String!, $price_amount: bigint!, $per_amount: bigint!) {
        transaction: insert_advisor_service(objects: {service_id: $service_id, target_id: $advisor_id, price_amount: $price_amount, price_currency: vnd, per_unit: second, per_amount: $per_amount}, on_conflict: {constraint: advisor_service_target_id_service_id_key, update_columns: [per_amount,price_amount]}) {
          returning {
            id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        service_id,
        advisor_id,
        price_amount,
        per_amount,
      });

      const data = _.get(rtn, 'transaction.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateStatusService({ service_id, is_accept_service }) {
    const query = hasuraClient.gql`
      mutation updateStatusService($service_id: uuid!, $is_accept_service: Boolean!) {
        transaction: update_advisor_service_kind_by_pk(pk_columns: {id: $service_id}, _set: {is_active: $is_accept_service}) {
          id
          is_active
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        service_id,
        is_accept_service,
      });

      const data = _.get(rtn, 'transaction');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async deleteService({ service_id }) {
    const query = hasuraClient.gql`
      mutation deleteService($service_id: uuid!) {
        transaction: update_advisor_service_by_pk(pk_columns: {id: $service_id}, _set: {deleted: "1"}) {
          id
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        service_id,
      });

      const data = _.get(rtn, 'transaction');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async createAssistant({ advisor_id, assistant_id }) {
    const query = hasuraClient.gql`
      mutation createAssistant($assistant_id: String!,$advisor_id: String!) {
        transaction: insert_advisor_assistant(objects: [{
          advisor_id: $advisor_id,
          assistant_id: $assistant_id,
        }],
        on_conflict: {constraint: advisor_assistant_advisor_id_assistant_id_key, update_columns: []} 
        ) {
          returning {
            advisor_id, 
            assistant_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        assistant_id,
        advisor_id,
      });

      const data = _.get(rtn, 'transaction.returning');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static async removeAssistant({ advisor_id, assistant_id }) {
    const query = hasuraClient.gql`
      mutation removeAssistant($advisor_id: String!, $assistant_id: String!) {
        advisor: delete_advisor_assistant(where: {advisor_id: {_eq: $advisor_id}, assistant_id: {_eq: $assistant_id}}) {
          returning {
            advisor_id,
            assistant_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        advisor_id,
        assistant_id,
      });

      const data = _.get(rtn, 'advisor.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ AdvisorModel });

export default AdvisorModel;
