import React from 'react';
import _ from 'lodash';
import DataContext from '../DataContext';

function* reverseIt(items) {
  let index = items.length - 1;
  while (index >= 0) {
    yield { item: items[index], index };
    --index;
  }
}

function* mapIt(items) {
  let index = 0;
  while (index < items.length) {
    yield { item: items[index], index };
    index++;
  }
}

const rule = ({ children, options, className }) => {
  return (props, ctx) => {
    const dataIndex = _.get(options, 'dataIndex');
    const keyIndex = _.get(options, 'keyIndex');
    const reverse = _.get(options, 'reverse');
    let items = _.castArray(ctx.get(dataIndex) || []);
    const it = reverse ? reverseIt(items) : mapIt(items);
    if (reverse) {
      items = [...items];
      _.reverse(items);
    }
    const rtn = [];
    for (const { item, index } of it) {
      const key = keyIndex ? _.get(item, keyIndex) : index;
      rtn.push(
        <DataContext.Provider
          {...props}
          key={key}
          value={{
            ...item,
            '@index': index,
            '@first': index === 0,
            '@last': index === items.length - 1,
            '@item': item
          }}
          className={className}
        >
          {children}
        </DataContext.Provider>
      );
    }
    return rtn;
    // return _.map(items, (item, index) => {
    //   const key = keyIndex ? _.get(item, keyIndex) : index;
    //   return (
    //     <DataContext.Provider
    //       {...props}
    //       key={key}
    //       value={{
    //         ...item,
    //         '@index': index,
    //         '@first': index === 0,
    //         '@last': index === (items.length - 1),
    //         '@item': item,
    //       }}
    //       children={children}
    //       className={className}
    //     />
    //   );
    // });
  };
};

export const each = (dataIndex, options) => {
  return {
    type: 'each',
    options: _.defaults({ dataIndex }, options)
  };
};

export default rule;
