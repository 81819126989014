import { bindings, hook } from '@vl/redata';
import CFCategoryFormatter from '@uz/unitz-models/CFCategoryModel/formatter';
import CFCategoryRenderer from '@uz/unitz-models/CFCategoryModel/renderer';
import _ from 'lodash';

const bindData = bindings({
  categoryItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const itemData = ctx.apply('ctf.findNode', { id: _.get(ctx.get('@item'), 'id', '') });
              return {
                ...itemData,
                imageData: CFCategoryFormatter.findImage(ctx)(itemData),
                displayName: CFCategoryFormatter.displayName(ctx)(itemData),
                description: CFCategoryRenderer.longText(ctx)(itemData),
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
