import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Link } from '@uz/unitz-components-web/Link';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { ctx } from '@vl/redata';

const CustomText = styled.div`
  p > p {
    color: ${gstyles.colors.main};
    font-weight: 400;
  }
`;
function BusinessFAQ() {
  return (
    <DIV className="component">
      <div className="bg-background1">
        <div className="py-0 sm:wrapper app-row">
          {/* <div className="border-b-2" /> */}
          {ctx.apply('ctf.renderProps', () => (
            <div className="wrapper app-row sm:px-4 lg:mx-0 sm:w-full py-6 lg:py-16">
              <h2 className="mb-6 font-semibold text-main text-xl sm:text-2xl lg:text-4xl text-center">
                {ctx.get('sectionData.shortText')}
              </h2>
              <Row className="justify-center mb-6">
                <Col lg={18} sm={24} className="text-left sm:text-center">
                  <CustomText>
                    {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                      className: 'text-sm sm:text-base',
                      as: 'p',
                    })}
                  </CustomText>
                </Col>
              </Row>
              <div className="wrapper sm:px-4 sm:w-full xl:mx-6 py-0 mb-10">
                <Row gutter={[16, 15]}>
                  {ctx
                    .get('sectionData.sections', [])
                    .slice(0, 6)
                    .map((question) => {
                      const itemData = ctx.apply('ctf.findNode', { name: _.get(question, 'name', '') });
                      return (
                        <Col key={itemData.id} lg={12} md={12} sm={24}>
                          <Link
                            className="list-item list-disc px-2"
                            to={ctx.apply('routeStore.toLocale', `/faqs#${itemData.slug}`)}
                          >
                            <div className="mb-0 font-normal text-sm sm:text-base text-brandb500">
                              {itemData.shortText}
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <Row className="justify-center" gutter={24}>
                <Col xs={24} md={12} lg={12}>
                  <Link to={ctx.apply('routeStore.toLocale', '/consulting-faqs')}>
                    <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                      {(resProps) => (
                        <Button {...resProps} block>
                          <h5 className="mb-0 text-base font-semibold text-brandb500">
                            {ctx.apply('i18n.t', 'Question.more')}
                          </h5>
                        </Button>
                      )}
                    </ResponsiveProps>
                  </Link>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </DIV>
  );
}

export default BusinessFAQ;
