import data from './data';
import each from './each';
import as from './as';
import force from './force';
import display from './display';

const rules = {};

export const registerRule = (name, handler) => {
  rules[name] = handler;
};

export const resolveRule = (name) => {
  if (!rules[name]) {
    console.warn(`Data rule ${name} is not defined`);
  }
  const rule = rules[name];
  return rule;
};

// builtin rules
registerRule('data', data);
registerRule('each', each);
registerRule('as', as);
registerRule('display', display);
registerRule('force', force);
