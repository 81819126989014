import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

export const ShortText = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      return <div className={_.get(itemData, 'className', '')}>{_.get(itemData, 'shortText', '')}</div>;
    })}
  </DIV>
);

export default ShortText;
