import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'VideoSection' });
              const children = _.get(section, 'sections', []).map((section) => {
                return ctx.apply('ctf.findEntry', section);
              });
              return { section, children };
            }),

            elements: hook((ctx) => {
              const childrenData = _.map(ctx.get('sectionData.children', []), (item) => {
                return {
                  href: _.get(item, 'linkHref', ''),
                  type: 'video',
                  source: 'youtube',
                  width: 800,
                };
              });
              return childrenData;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
