import React from 'react';
import _ from 'lodash';

export const useSubjectState = (subject, def) => {
  const [val, $val] = React.useState(def);
  const ref = React.useRef({});
  Object.assign(ref.current, { val, $val, subject });

  const setVal = (newVal) => {
    if (!_.isEqual(newVal, ref.current.val)) {
      // update local val
      if (ref.current.$val) {
        ref.current.$val(newVal);
      }
      // update subject val
      if (ref.current.subject && ref.current.subject.next) {
        ref.current.subject.next(newVal);
      }
    }
  };

  const debounceSetter = React.useMemo(() => {
    return () => {
      if (!_.isEqual(ref.current.newVal, ref.current.val)) {
        // update local val
        if (ref.current.$val) {
          ref.current.$val(ref.current.newVal);
        }
      }
    };
  }, [ref]);
  React.useEffect(() => {
    if (ref.current.subject && ref.current.subject.subscribe) {
      ref.current.subs = subject.subscribe((item) => {
        ref.current.newVal = _.cloneDeep(item);
        debounceSetter();
      });
    }

    return () => {
      if (ref.current.subs) {
        ref.current.subs.unsubscribe();
      }
      ref.current = {};
    };
  }, [ref]);
  return [val, setVal];
};

export default useSubjectState;
