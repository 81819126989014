import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import Slider from 'react-slick';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomCarousel = styled.div`
  .slick-slider {
    .slick-list {
      margin-bottom: 24px;
    }
    .slick-dots {
      @media only screen and (min-width: 320px) {
        bottom: -10px;
      }
      @media only screen and (min-width: 768px) {
        bottom: 50px;
      }
      @media only screen and (min-width: 992px) {
        bottom: 20px;
      }
      @media only screen and (min-width: 1200px) {
        bottom: 20px;
      }
      li {
        width: 8px;
        height: 8px;
        margin: 0 5px;
        button {
          background: ${gstyles.colors.black200};
          border-radius: 50%;
          width: 8px;
          height: 8px;

          &:before {
            content: '';
          }
        }
        &.slick-active {
          button {
            background: ${gstyles.colors.brandb500};
          }
        }
      }
    }
  }
`;

const NextButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselNext">
      {/* eslint-disable-next-line */}
      <div
        className="absolute rounded-full cursor-pointer bg-black300 bottom-2/4 right-1 -translate-y-3/4"
        onClick={onClick}
      >
        {gstyles.icons({ name: 'arrow-right', size: 40, fill: gstyles.colors.white500 })}
      </div>
    </DIV>
  );
};
const PrevButton = (props) => {
  const { onClick } = props;
  return (
    <DIV className="carouselPrev">
      {/* eslint-disable-next-line */}
      <div
        className="absolute z-50 rounded-full cursor-pointer bg-black300 bottom-2/4 left-1 -translate-y-3/4 "
        onClick={onClick}
      >
        {gstyles.icons({ name: 'arrow-left', size: 40, fill: gstyles.colors.white500 })}
      </div>
    </DIV>
  );
};

export const HeroSliderLayout = () => {
  return (
    <DIV className="carouselContainer">
      {ctx.apply('ctf.renderProps', () => {
        const settings = ctx.get('carouselOptions');

        return (
          <CustomCarousel>
            <div className="h-full">
              <Slider
                {...settings}
                {...{
                  nextArrow: <NextButton onClick />,
                  prevArrow: <PrevButton onClick />,
                }}
              >
                {_.map(
                  _.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []),
                  (item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {ctx.apply('ctf.renderSection', { name: _.get(item, 'name', '') }) || null}
                      </React.Fragment>
                    );
                  }
                )}
              </Slider>
            </div>
          </CustomCarousel>
        );
      })}
    </DIV>
  );
};
export default HeroSliderLayout;
