import _ from 'lodash';
import { isHook } from './hook';

const visitHook = (visitor) => {
  const hookPaths = [];
  const canVisit = (val) => _.isObject(val) || _.isArray(val);
  const visit = (obj, path) => {
    if (canVisit(obj)) {
      _.map(obj, (value, key) => {
        const subPath = `${!!path || path === 0 ? `${path}.` : ''}${key}`;
        if (isHook(value)) {
          obj[key] = visitor({ hook: value, key, path: subPath });
          // track hook path
          hookPaths.push(subPath);
          return obj[key];
        }
        if (_.isObject(value) || _.isArray(value)) {
          visit(value, subPath);
        }
      });
    }
    return hookPaths;
  };
  return visit;
};

const shorthands = {
  onConflict: {
    append: ({ newVal, oldVal }) => {
      return [newVal, ..._.castArray(oldVal)];
    },
    overwrite: ({ newVal }) => newVal,
    ignore: ({ oldVal }) => oldVal,
    chaining: ({ oldVal, newVal }) => {
      if (_.isFunction('oldVal') && _.isFunction('newVal')) {
        return (...args) => {
          oldVal(...args);
          newVal(...args);
        };
      }
      return newVal;
    }
  },
  onPath: {
    reflect: ({ path }) => path
  }
};

const resolveShorthands = (val, type) => {
  if (_.isString(val)) {
    return _.get(shorthands, [type, val]);
  }
  return val;
};

const memoCache = {};

/**
 * Linked list context object
 */
class Context {
  cid = null;

  parent = null;

  className = undefined;

  data = {};

  path = null;

  resolveHookStack = [];

  tags = {};

  constructor({ parent, data, className }) {
    this.parent = parent;
    this.className = className;
    // init this current context scope data
    this.load(_.cloneDeep(data));
    // this.load(data);
    // init path to parent
    this.cid = _.uniqueId('ctx');
  }

  load(data) {
    this.data = data;
  }

  registerHooks = () => {
    // resolve data
    visitHook(({ path, hook }) => {
      // hook(this);
      const val = hook(this);
      this.taggingHook(hook, path);
      return val;
    })(this.data);
  };

  taggingHook = (hook, path) => {
    if (hook.options.tags) {
      _.map(hook.options.tags, ({ key, value }) => {
        const tagKey = `${key}::${value}`;
        if (!this.tags[tagKey]) {
          this.tags[tagKey] = new Set();
        }
        this.tags[tagKey].add(path);
      });
    }
  };

  getName() {
    return this.className;
  }

  getByTags(tags = [], options = {}, res = {}) {
    let { onConflict = 'append', onPath = 'reflect' } = options;

    onConflict = resolveShorthands(onConflict, 'onConflict');
    onPath = resolveShorthands(onPath, 'onPath');
    // finding tags from local tags
    _.map(_.castArray(tags || []), ({ key, value }) => {
      const tagKey = `${key}::${value}`;
      if (this.tags[tagKey]) {
        const paths = this.tags[tagKey].values();
        for (const path of paths) {
          const dataVal = _.get(this.data, path);
          const pathVal = onPath({ path });
          _.update(res, pathVal, (val) => {
            if (!_.has(res, pathVal)) {
              return dataVal;
            }
            if (_.isFunction(onConflict)) {
              return onConflict({ newVal: dataVal, oldVal: val, path: pathVal });
            }
            return dataVal;
          });
        }
      }
    });
    if (this.parent) {
      this.parent.getByTags(tags, options, res);
    }
    return res;
  }

  getPath() {
    if (!this.path) {
      const path = [];
      this.getName() && path.push(this.getName());
      if (this.parent) {
        path.push(...this.parent.getPath());
      }
      this.path = path;
    }
    return this.path;
  }

  get(...args) {
    // const [] = args;
    // special path:
    if (args.length === 0) return this.data;
    return this.getR(...args);
  }

  apply(path, ...args) {
    const paths = _.toPath(path);
    for (let pos = 0; pos < paths.length; pos++) {
      const targetPaths = paths.slice(0, pos + 1);
      const propPaths = paths.slice(pos + 1);
      const target = this.get(targetPaths.join('.'));
      const fn = _.get(target, propPaths);
      if (_.isFunction(fn)) {
        return fn.call(target, ...args);
      }
    }
  }

  set(path, value) {
    _.set(this.data, path, value);
  }

  resolveHook(path) {
    const hook = _.get(this.data, path);
    let val;
    if (isHook(hook)) {
      this.resolveHookStack.push(path);
      val = hook(this);
      this.resolveHookStack.pop();
      this.taggingHook(hook, path);
    }

    _.set(this.data, path, val);
    return val;
  }

  getR(...args) {
    const [path, def] = args;
    if (_.has(this.data, path)) {
      const val = _.get(this.data, path);
      // circular state, skip
      if (!isHook(val)) {
        return val;
      } if (this.resolveHookStack.includes(path)) {
        // access to a hook that has not been resolved, try to resolve it

        const resolveVal = this.resolveHook(path);
        return resolveVal;
      }
    }
    // check for the data from parent context
    if (this.parent && this.parent.getR) {
      return this.parent.getR(...args);
    }
    return def;
  }

  query() {}

  memo(fn, deps) {
    const key = JSON.stringify({ deps });
    if (!_.has(memoCache, [key])) {
      _.set(memoCache, [key], fn);
    }
    return _.get(memoCache, [key]);
  }

  debug(fn) {
    if (_.isFunction(fn)) {
      fn();
    }
    return null;
  }
}

export default Context;
