import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import cx from 'classnames';
import styled from 'styled-components';
import AboutUsSurveyForm from '@uz/unitz-components-web/AboutUsSurveyForm';
import gstyles from '@vl/gstyles';

const Styling = styled.div`
  p,
  span {
    color: ${gstyles.colors.main};
    font-size: 16px;
  }
`;

const AboutUsSurveyLayout = () => {
  return (
    <DIV className="AboutUsSurveyLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <Styling className="wrapper app-row py-0 md:py-16">
            <div className="py-8">
              <Row gutter={[{ xs: 0, md: 48 }, 24]}>
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col key={index} xs={24} lg={12} className={cx(ctx.get('sectionData.className'))}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                    </Col>
                  );
                })}
                <Col xs={24} lg={12}>
                  <AboutUsSurveyForm />
                </Col>
              </Row>
            </div>
          </Styling>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsSurveyLayout;
