import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Link } from '@uz/unitz-components-web/Link';
import { Button } from '@uz/unitz-components-web/Button';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import _ from 'lodash';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import { flattenGet } from '@vl/mod-utils/flattenGet';
import CourseStatusIndicator from '@uz/unitz-components-web/CourseStatusIndicator';
import gstyles from '@vl/gstyles';
import useRoute from '@vl/hooks/useGbRoute';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';
const CustomSkeleton = styled(Skeleton.Button)`
  display: flex !important;
  width: 100% !important;
  margin-bottom: 2px;
  .ant-skeleton-button {
    width: 100% !important;
  }
`;
export const View8 = ({ showPrice, showInfo = true, fullWidth = false }) => {
  return (
    <DIV>
      {!!ctx.apply('loadingCoursePurchaseModel.isLoading') && (
        <div className="flex items-center w-full">
          <CustomSkeleton active={true} size={'default'} shape={'default'} />
        </div>
      )}
      <DIV className="notLoadingCoursePurchase">
        {CourseFormatter.canPurchase(ctx)(ctx.get('@item')) ? (
          <div className="flex-1 mb-1">
            <Link
              className={cx('block w-40 mx-auto md:w-full sm:w-full xs:w-full', {
                'w-full': !!fullWidth,
              })}
            >
              <ResponsiveProps>
                {() => {
                  const item = ctx.get('@item');
                  return renderPropsComposer(
                    // paid course/room
                    {
                      matcher: (props) => {
                        if (
                          _.get(props, 'pricing_type') === 'per_course' &&
                          _.sumBy(_.get(item, 'purchases'), 'per_amount') > 0
                        ) {
                          return true;
                        }
                        if (
                          _.get(props, 'pricing_type') === 'per_session' &&
                          _.sumBy(_.get(item, 'purchases'), 'per_amount') === _.get(item, 'session_occurence')
                        ) {
                          return true;
                        }
                      },
                      render: () => (
                        <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'medium' }}>
                          {(resProps) => (
                            <Button type="primary" block disabled {...resProps}>
                              {ctx.apply('i18n.t', 'Course.paidAlready')}
                            </Button>
                          )}
                        </ResponsiveProps>
                      ),
                    },
                    {
                      matcher: (props) => _.get(props, 'pricing_type') === 'per_session',
                      render: () => (
                        <Link
                          {...ctx.apply('tracking.dataSet', {
                            action: 'click',
                            category: `${ctx.get('dataPage')}_button_course_purchase`,
                            value: ctx.get('@item.courseInfo.id'),
                            label: ctx.get('@item.courseInfo.displayName'),
                          })}
                          to={ctx.apply('routeStore.toUrl', 'coursePurchase', {
                            ...item,
                            referral_code: (() => {
                              const route = useRoute();
                              const routeParams = _.get(route, 'params', {});
                              return _.get(routeParams, 'referral_code');
                            })(),
                          })}
                        >
                          <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'medium' }}>
                            {(resProps) => (
                              <Button
                                loading={ctx.apply('@item.loadingCoursePurchase')}
                                focus="true"
                                type="primary"
                                block
                                {...resProps}
                              >
                                {showPrice ? (
                                  <>
                                    <span className="text-base font-semibold">
                                      {CourseFormatter.priceAmount(ctx)(item)}
                                    </span>
                                    <span className="mx-1">/</span>
                                    <span>{CourseFormatter.priceUnitText(ctx)(item)}</span>
                                  </>
                                ) : (
                                  <span>{ctx.apply('i18n.t', 'Course.enrollNow')}</span>
                                )}
                              </Button>
                            )}
                          </ResponsiveProps>
                        </Link>
                      ),
                    },
                    {
                      matcher: (props) => _.get(props, 'pricing_type') === 'per_course',
                      render: () => (
                        <Link
                          {...ctx.apply('tracking.dataSet', {
                            action: 'click',
                            category: `${ctx.get('dataPage')}_button_course_purchase`,
                            value: ctx.get('@item.courseInfo.id'),
                            label: ctx.get('@item.courseInfo.displayName'),
                          })}
                          to={ctx.apply('routeStore.toUrl', 'coursePurchase', {
                            ...item,
                            referral_code: (() => {
                              const route = useRoute();
                              const routeParams = _.get(route, 'params', {});
                              return _.get(routeParams, 'referral_code');
                            })(),
                          })}
                        >
                          <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'medium' }}>
                            {(resProps) => (
                              <Button focus="true" type="primary" block {...resProps}>
                                {showPrice ? (
                                  <>
                                    <span className="text-base font-semibold">
                                      {CourseFormatter.priceAmount(ctx)(item)}
                                    </span>
                                    <span className="mx-1">/</span>
                                    <span>{CourseFormatter.priceUnitText(ctx)(item)}</span>
                                  </>
                                ) : (
                                  <span>
                                    <span>
                                      {gstyles.icons({
                                        name: 'cart-add',
                                        size: 24,
                                        fill: gstyles.colors.white500,
                                        className: 'inline-block mr-2',
                                      })}
                                    </span>
                                    <span>{ctx.apply('i18n.t', 'Course.enrollNow')}</span>
                                  </span>
                                )}
                              </Button>
                            )}
                          </ResponsiveProps>
                        </Link>
                      ),
                    },
                    () => null
                  )(item);
                }}
              </ResponsiveProps>
            </Link>
          </div>
        ) : (
          <div className="flex-1 mb-1">
            <CourseStatusIndicator className="w-full" size="medium" />
          </div>
        )}
        {!!showInfo && (
          <ResponsiveProps>
            {() => {
              const item = ctx.get('@item');
              return renderPropsComposer(
                {
                  matcher: (props) =>
                    _.get(props, 'pricing_type') === 'per_session' && !!_.get(item, 'purchases.length'),
                  render: () => {
                    const remainCount = _.get(flattenGet(item, 'purchases.purchase.remain_rooms'));
                    return (
                      <>
                        {CourseFormatter.isUnlimitedSession(ctx)(item) ? (
                          !!remainCount && (
                            <div className="flex-1 mb-1">
                              <Link
                                {...ctx.apply('tracking.dataSet', {
                                  action: 'click',
                                  category: `${ctx.get('dataPage')}_button_course_purchase`,
                                  value: ctx.get('@item.courseInfo.id'),
                                  label: ctx.get('@item.courseInfo.displayName'),
                                })}
                                className="block w-40 mx-auto md:w-full sm:w-full xs:w-full"
                              >
                                <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'small' }}>
                                  {(resProps) => (
                                    <Button focus="true" type="primary" name="blue" block {...resProps}>
                                      <span>
                                        {ctx.apply('i18n.t', 'Course.Payment.paidSessionsRemainLabel', {
                                          count: _.get(flattenGet(item, 'purchases.purchase.remain_rooms'), 'length'),
                                          session_occurence: _.get(item, 'session_occurence'),
                                        })}
                                      </span>
                                    </Button>
                                  )}
                                </ResponsiveProps>
                              </Link>
                            </div>
                          )
                        ) : (
                          <div className="flex-1 mb-1">
                            <Link
                              {...ctx.apply('tracking.dataSet', {
                                action: 'click',
                                category: `${ctx.get('dataPage')}_button_course_purchase`,
                                value: ctx.get('@item.courseInfo.id'),
                                label: ctx.get('@item.courseInfo.displayName'),
                              })}
                              className="block w-40 mx-auto md:w-full sm:w-full xs:w-full"
                            >
                              <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'small' }}>
                                {(resProps) => (
                                  <Button focus="true" type="primary" name="blue" block {...resProps}>
                                    <span>
                                      {ctx.apply('i18n.t', 'Course.Payment.paidSessionsPerTotalLabel', {
                                        count: _.sumBy(_.get(item, 'purchases'), 'per_amount'),
                                        session_occurence: _.get(item, 'session_occurence'),
                                      })}
                                    </span>
                                  </Button>
                                )}
                              </ResponsiveProps>
                            </Link>
                          </div>
                        )}
                      </>
                    );
                  },
                },
                () => null
              )(item);
            }}
          </ResponsiveProps>
        )}
      </DIV>
    </DIV>
  );
};

export default displayName(View8);
