import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            priceRangeOptions: hook((ctx) => {
              const options = {
                min: 0,
                max: 5000000,
              };
              options.step = (options.max - options.min) / 20;
              // options.defaultValue = [options.min + options.step, options.max - options.step];
              options.defaultValue = [0, options.max - options.step];
              return options;
            }),

            advisorSearchForm: hook((ctx) => {
              const route = useRoute();
              const locale = _.get(route.getPageContext(), 'lang', '');
              const form = useFormik({
                initialValues: {
                  category: '',
                  service: 'video',
                  priceRange: ctx.get('priceRangeOptions.defaultValue'),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const { priceRange } = form.values;
                    const [minPrice, maxPrice] = priceRange;
                    route.navigate(
                      route.to(`/filter`, {
                        ..._.omit(form.values, ['priceRange']),
                        min: minPrice,
                        max: maxPrice,
                      })
                    );
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({}),
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
