import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import SupplierSuggestModel from '@uz/unitz-models/SupplierSuggestModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import useObservableSource from '@vl/hooks/useObservableSource';
import gstyles from '@vl/gstyles';
// const NextArrow = () => {
//   return (
//     <div className="bg-white500 drop-shadow">
//       {gstyles.icons({ name: 'arrow-right', size: 30, fill: gstyles.colors.sub })}
//     </div>
//   );
// };
// const PrevArrow = () => {
//   return (
//     <div className="bg-white500 drop-shadow">
//       {gstyles.icons({ name: 'arrow-left', size: 30, fill: gstyles.colors.sub })}
//     </div>
//   );
// };

const bindData = bindings({
  LandingCourseListLayout: {
    rules: [
      [
        'data',
        {
          data: {
            categories: hook((ctx) => {
              const arraySlug = ['education', 'consulting'];
              const categories = ctx.apply('ctf.buildCategoryHierarchy');
              const optimizeFilter = React.useMemo(
                () => ({
                  catId: _.get(
                    _.find(categories, (cat) => cat.slug === 'education'),
                    'id',
                    ''
                  ),
                  data: _.orderBy(
                    _.filter(categories, (item) => {
                      // return _.findIndex(arraySlug, (value) => value === _.get(item, 'slug')) !== -1;
                      if (
                        _.get(item, 'highlight') &&
                        _.findIndex(arraySlug, (slug) => slug === _.get(item, 'slug')) !== -1
                      ) {
                        item.index = _.findIndex(arraySlug, (slug) => slug === _.get(item, 'slug'));
                      }
                      return !!_.get(item, 'highlight');
                    }),
                    'index',
                    'asc'
                  ),
                }),
                [categories]
              );
              return optimizeFilter;
            }),

            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  category: ctx.get('categories.catId'),
                },
                validationSchema: Yup.object().shape({}),
              });
              _.assign(form, {
                getSelectedCategory: () => {
                  const category = _.get(form, 'values.category');
                  const selected = _.find(ctx.get('categories.data'), { id: category });
                  return selected;
                },
              });
              return form;
            }),

            getFilterArgs: hook((ctx) => {
              return () => ({
                category: ctx.get('form.values.category'),
              });
            }),

            advisorFilterModelMap: hook((ctx) => {
              const ref = React.useRef({});
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });
              const pagingModels = React.useMemo(() => {
                return _.map(ctx.get('categories.data'), (item) => {
                  const dataQuery = `
                  ${
                    ctx.get('categories.catId') === _.get(item, 'id', '')
                      ? `suggest_teacher (args: $args , order_by: {profile: {scoring_int: desc}}) {`
                      : `suggest_advisor (args: $args , order_by: {profile: {scoring_int: desc}}) {`
                  }
                  
                    id
                    profile {
                      id
                      display_name
                      avatar_url
                      slug
                      scoring_int
                      categories {
                        category {
                          display_name
                          id
                          icon
                        }
                      }
                      advisor_id
                      created_at
                      intro_text ${ctx.apply('i18n.suffixSelectionWeb', 'intro_text')}
                    }
                    readiness{
                      is_ready
                      on_transaction_id
                      is_accept_service
                    }
                    services {
                      price {
                        per_unit
                        per_amount
                        price_currency
                        price_amount
                      }
                      id
                      service {
                        id
                        name
                        kind
                        is_active
                      }
                    }
                    courses_aggregate (where: {status: {_in: [published, onprogress]}}) {
                      aggregate {
                        count
                      }
                    }
                  }
                `;
                  const pagingModel = PaginationModel.fromConfig({
                    Model: SupplierSuggestModel,
                    subscription: true,
                    dataQuery,
                    aggQuery: `
                    ${
                      ctx.get('categories.catId') === _.get(item, 'id', '')
                        ? `suggest_teacher_aggregate(args: $args) {`
                        : `suggest_advisor_aggregate(args: $args) {`
                    }
                        aggregate {
                          count
                        }
                      }
                    `,
                    pageSize: 24,
                    varsList: `$args: ${
                      ctx.get('categories.catId') === _.get(item, 'id', '')
                        ? `suggest_teacher_args!`
                        : `suggest_advisor_args!`
                    } `,
                    getVars: () => {
                      const args = { category: item.id };
                      if (ctx.get('categories.catId') === _.get(item, 'id', '')) {
                        args.user_id = '';
                      }
                      return {
                        args: {
                          category: item.id,
                        },
                      };
                    },
                  });
                  return pagingModel;
                });
              }, [ref]);

              _.map(pagingModels, (pagingModel) => pagingModel.hookState());

              React.useEffect(() => {
                _.map(pagingModels, (pagingModel) => pagingModel.onLoadPageHandler(0));
              }, [pagingModels]);
              return pagingModels;
            }),

            advisorFilterModel: hook((ctx) => {
              const catId = ctx.get('form.values.category');
              const categories = ctx.get('categories.data');
              const catIndex = _.findIndex(categories, { id: catId });
              const pagingModels = ctx.get('advisorFilterModelMap');

              const pagingModel = _.get(pagingModels, catIndex);

              const pagination = {
                items: (pagingModel && pagingModel.useState('items')) || [],
              };
              return pagination;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
