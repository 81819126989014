import _ from 'lodash';
import { normalizeNumber } from './currencyFormatter';

export const applyDiscount = (discount) => (total) => {
  const totalBefore = normalizeNumber(total);
  // not discount
  if (!discount) {
    return {
      totalBefore,
      totalAfter: totalBefore,
      discount: 0,
    };
  }

  let discountAmount = 0;
  const percentage = normalizeNumber(_.get(discount, 'percentage', 0));
  let amount = normalizeNumber(_.get(discount, 'amount', 0));

  if (percentage > 0) {
    discountAmount = _.round((totalBefore * percentage) / 100, 0);
    if (amount) {
      discountAmount = Math.min(amount, discountAmount);
    }
    return {
      totalBefore,
      totalAfter: totalBefore - discountAmount,
      discount: discountAmount,
    };
  }

  if (amount) {
    discountAmount = amount;
    return {
      totalBefore,
      totalAfter: totalBefore - discountAmount,
      discount: discountAmount,
    };
  }

  return {
    totalBefore,
    totalAfter: totalBefore,
    discount: 0,
  };
};
