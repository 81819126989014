import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const FormatText = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: initial;
    font-weight: bold;
  }
  ul {
    list-style: disc;
    li {
      margin: 8px 8px 0 20px;
      list-style-type: disc;
    }
  }
`;

export const LongText = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <React.Fragment>
          <FormatText>
            {ctx.apply(
              'ctf.renderHTMLText',
              itemData,
              ...[].concat(_.get(itemProps, 'args') || ['longText.longText', null])
            ) ||
              ctx.apply(
                'ctf.renderHTMLText',
                itemData,
                ...[].concat(_.get(itemProps, 'args') || ['detalText.detailText', null])
              )}
          </FormatText>
        </React.Fragment>
      );
    })}
  </DIV>
);

export default LongText;
