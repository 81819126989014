import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const bindData = bindings({
  FieldSelectionLayout: {
    rules: [
      [
        'data',
        {
          data: {
            sections: hook((ctx) => {
              const parentSection = ctx.apply('ctf.renderProps', () => {
                return ctx.get('sectionData.sections');
              });

              const sectionName = _.map(parentSection, (item) => {
                return {
                  ctfName: _.get(item, 'name', ''),
                  displayName: _.get(item, 'shortText', ''),
                  icon: _.get(item, 'className', ''),
                };
              });

              return sectionName;
            }),

            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  section: ctx.get('sections.0.ctfName'),
                },
                validationSchema: Yup.object().shape({}),
              });
              _.assign(form, {
                getSelectedCategory: () => {
                  const section = _.get(form, 'values.section');
                  const selected = _.find(ctx.get('sections'), { ctfName: section });
                  return selected;
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
