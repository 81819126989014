import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import cx from 'classnames';
import _ from 'lodash';

const UnitzBizVideoProductAdvantageLayout = () => {
  return (
    <DIV className="UnitzBizProductAdvantageLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className={cx('relative content bg-main', ctx.get('sectionData.className'))}>
            <div className="wrapper app-row lg:py-6">
              <h1 className="text-center text-2xl lg:text-4xl mb-6 lg:mb-20 text-branda500 font-semibold">
                {ctx.get('sectionData.shortText')}
              </h1>

              <Row gutter={[{ xs: 0, md: 24, lg: 48 }, 24]}>
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col key={`item_${index}`} xs={24} sm={12} lg={12}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default UnitzBizVideoProductAdvantageLayout;
