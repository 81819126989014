import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import cx from 'classnames';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;
const FlexColLayout = () => {
  return (
    <DIV className="FlexColLayout">
      {ctx.apply('ctf.renderProps', () => (
        <>
          {!!ctx.get('sectionData.shortText') && (
            <div className="flex flex-col justify-center mt-8 items-center">
              <div className="text-sm text-sub text-center font-semibold">{ctx.get('sectionData.shortText')}</div>
              <CustomParagraph className="text-center">
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
              </CustomParagraph>
            </div>
          )}
          <div className={`flex flex-col ${ctx.get('sectionData.className')}`}>
            {_.map(ctx.get('sectionData.sections', []), (item, index) => {
              return <div key={index}>{ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}</div>;
            })}
          </div>
        </>
      ))}
    </DIV>
  );
};

export default FlexColLayout;
