import React from 'react';
import _ from 'lodash';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Image } from '@uz/unitz-components-web/Image';
import Link from '@uz/unitz-components-web/Link';

const RichTextComponents = {
  Bold: ({ children }) => <span className="my-2 font-semibold">{children}</span>,
  Text: ({ children }) => <div className="text-base font-normal">{children}</div>,
  Para: ({ children }) => <span className="my-4 text-base block font-normal">{children}</span>,
  Italic: ({ children }) => <span className="italic">{children}</span>,
};

const richTextRender = (content, opts = {}) => {
  const options = _.merge({}, opts, {
    renderMark: {
      [MARKS.BOLD]: (text) => <RichTextComponents.Bold>{text}</RichTextComponents.Bold>,
      [MARKS.ITALIC]: (text) => <RichTextComponents.Italic>{text}</RichTextComponents.Italic>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <RichTextComponents.Para>{children}</RichTextComponents.Para>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        return (
          <Link external href={uri} className="underline">
            {children}
          </Link>
        );
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.LIST_ITEM]: (node, children) => <li className="list-decimal px-4">{children}</li>,
          },
        });
        return <div className="text-main px-4">{UnTaggedChildren}</div>;
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.LIST_ITEM]: (node, children) => <li className="list-disc px-4">{children}</li>,
          },
        });
        return <div className="px-4">{UnTaggedChildren}</div>;
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <RichTextComponents.Para>{children}</RichTextComponents.Para>,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
            [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
          },
        });

        return <li className="px-4">{UnTaggedChildren}</li>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const src = _.get(node, 'data.target.fixed.src');
        const title = _.get(node, 'data.target.title');
        return (
          <>
            <Image style={{ objectFit: 'cover', margin: '16px auto' }} src={src} size="cover" alt={title} />
          </>
        );
      },
    },
  });

  return !!content && renderRichText(content, options);
};

export default richTextRender;
