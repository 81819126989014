import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            categories: hook((ctx) => {
              const categories = ctx.apply('ctf.buildCategoryHierarchy');

              // const getLanguage = React.useMemo(
              //   () =>
              //     _.filter(categories, (item) => {
              //       return _.get(item, 'id') === '4bhJ4jhBJP3cilGFfNoekv';
              //     }),
              //   [categories]
              // );

              // const flag = ctx.apply('ctf.renderProps', () => {
              //   return ctx.get('sectionData.className');
              // });

              // const academics = React.useMemo(
              //   () =>
              //     _.filter(categories, (item) => {
              //       return (
              //         !_.get(item, 'parent') &&
              //         _.get(item, 'id') !== '2JDELht3aty4I18HvWctIC' &&
              //         _.get(item, 'id') === '6LsYTrdJ3VfYGcyglw8n5c'
              //       );
              //     }),
              //   [categories]
              // );

              const consultings = React.useMemo(
                () =>
                  _.filter(categories, (item) => {
                    return (
                      !_.get(item, 'parent') &&
                      _.get(item, 'id') !== '2JDELht3aty4I18HvWctIC' &&
                      _.get(item, 'id') === '1WamMEyXzLe6x1iI1v0bgJ'
                    );
                  }),
                [categories]
              );
              // const services = [
              //   {
              //     name: 'academic-list',
              //     data: academics,
              //   },
              //   {
              //     name: 'consulting-list',
              //     data: consultings,
              //   },
              // ];

              // const categoriesById = React.useMemo(
              //   () =>
              //     _.filter(services, (item) => {
              //       return _.get(item, 'name') === flag;
              //     }),
              //   [services]
              // );
              // return _.get(categoriesById, '0.data.0.children', []);
              return _.get(consultings, '0', []);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
