import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

// import GetSearchAdvisor from 'unitz-gql/advisor_search/GetSearchAdvisor.gql';

import GetAdvisor from 'unitz-gql/advisor/GetAdvisor.gql';

graphme.GqlBuilder.loadDocument({
  GetAdvisor,
});

class SupplierSuggestModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'SupplierSuggest',
    schema: {
      id: String,
    },
    nodes: [
      ['profile', 'AdvisorProfileModel', { column_mapping: { id: 'advisor_id' } }],
      ['transactions', 'TransactionModel.Collection', { column_mapping: { id: 'advisor_id' } }],
      [
        'transactions_aggregate',
        'TransactionAggregateModel',
        { column_mapping: { id: 'advisor_id' }, usePlanSync: true },
      ],
    ],
    key: 'id',

    baseQuery: 'GetAdvisor',

    GQL_ACTIONS: {
      FIND: 'suggest_supplier',
    },

    getClient: hasuraClient.getClient,
  });
  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ SupplierSuggestModel });

export default SupplierSuggestModel;
