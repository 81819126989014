import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const UnitzBizTermConditionsContentLayout = () => {
  return (
    <DIV className="TermConditionsContent">
      {ctx.apply('ctf.renderProps', () => (
        <div>
          <div className="wrapper app-row">
            <div>
              <h2 className="mb-6 text-xl sm:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              {/* {_.map(ctx.get('sectionData.sections'), (item, index) => {
                return (
                  <React.Fragment key={index}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                  </React.Fragment>
                );
              })} */}
              {ctx.apply('ctf.renderRichText', { name: ctx.get('sectionData.name') }, 'richText', null)}
            </div>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default UnitzBizTermConditionsContentLayout;
