import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: ${gstyles.colors.main};
  }
`;

export const BenefitItem = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      return (
        <div className="flex justify-center flex-col items-center">
          <div>
            {gstyles.icons({
              name: ctx.get('itemData.action') === null ? 'open-in-new' : `${ctx.get('itemData.action')}`,
              size: 70,
            })}
          </div>
          <div className="text-center mt-3">
            <h5 className="text-main font-semibold text-xl">{ctx.get('itemData.shortText')}</h5>
            <CustomParagraph>
              {ctx.apply('ctf.renderHTMLText', { name: ctx.get('itemData.name', '') }, 'longText.longText', null)}
            </CustomParagraph>
          </div>
        </div>
      );
    })}
  </DIV>
);

export default BenefitItem;
