import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';
import cx from 'classnames';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const Styling = styled.div`
  p,
  span {
    color: ${gstyles.colors.main};
    font-size: 20px;
    text-align: justify;
    @media only screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
`;

const AboutUsSharingLayout = () => {
  return (
    <DIV className="AboutUsSharingLayout">
      {ctx.apply('ctf.renderProps', () => (
        <Styling className="wrapper app-row py-0 md:py-16">
          <ResponsiveProps xs={{ style: { flexDirection: 'column-reverse' } }} md={{ style: { flexDirection: 'row' } }}>
            {(resProps) => (
              <Row className="py-8" gutter={[{ xs: 0, sm: 0, md: 48 }, 24]} {...resProps}>
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col key={index} xs={24} lg={12} className={cx(ctx.get('sectionData.className'))} key={index}>
                      {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}
                    </Col>
                  );
                })}
              </Row>
            )}
          </ResponsiveProps>
        </Styling>
      ))}
    </DIV>
  );
};

export default AboutUsSharingLayout;
