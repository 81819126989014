import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import Link from '@uz/unitz-components-web/Link';
import moment from 'moment';

export const View8 = () => {
  return (
    <DIV>
      {(() => {
        const room = ctx.get('@item');
        const isPassRoom = moment().isAfter(_.get(room, 'room.end_at'));
        if (_.has(room, 'purchase_info')) {
          if (_.get(room, 'purchase_info')) {
            return (
              <div className="px-2 rounded-lg bg-brandb-lightest font-semibold text-xs sm:text-sm text-brandb500">
                {ctx.apply('i18n.t', 'Course.Payment.paidLabel')}
              </div>
            );
          }
          return (
            <>
              {!!isPassRoom ? (
                <div className="px-2 rounded-lg bg-red-lightest font-semibold text-xs sm:text-sm text-red500">
                  {ctx.apply('i18n.t', 'Course.Payment.unPaidLabel')}
                </div>
              ) : (
                <Link to={ctx.apply('routeStore.toUrl', 'coursePurchase', _.get(room, 'course', {}))} external>
                  <div className="px-2 rounded-lg bg-red-lightest font-semibold text-xs sm:text-sm text-red500">
                    {ctx.apply('i18n.t', 'Course.Payment.unPaidLabel')}
                  </div>
                </Link>
              )}
            </>
          );
        }
        return null;
      })()}
    </DIV>
  );
};

export default displayName(View8);
