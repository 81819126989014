import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

import { useRenderProps } from '@vl/hooks/useRenderProps';
import { Button } from '@uz/unitz-components-web/Button';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import CourseRoomAttendees from '@uz/unitz-components-web/CourseRoomAttendees';
import CourseRoomTimeSlot from '@uz/unitz-components-web/CourseRoomTimeSlot';
import CourseRoomQuickActionAdvisor from '@uz/unitz-components-web/CourseRoomQuickActionAdvisor';
import CourseRoomActionMenu from '@uz/unitz-components-web/CourseRoomActionMenu';
import { Link } from '@uz/unitz-components-web/Link';

const renderCoureRoomItem = useRenderProps(
  ({ item, index }) => {
    return (
      <DIV forceCtx>
        {ctx.debug(() => {
          ctx.set('@item', item);
          ctx.set('@index', index);
        })}
        <Row gutter={[16, 8]} key={item.id} className="pl-2 py-3 border-b">
          <Col xs={{ span: 8, offset: 0 }} lg={{ span: 5, offset: 0 }}>
            <div className="flex justify-start items-center w-full h-full">
              <CourseRoomTimeSlot />
            </div>
          </Col>
          <Col xs={{ span: 16, offset: 0 }} lg={{ span: 9, offset: 0 }}>
            <div className="flex justify-start items-center w-full h-full">
              <Link to={ctx.apply('routeStore.toUrl', 'courseDetail', _.get(item, 'course'))}>
                {_.get(item, 'course.name', '')}
              </Link>
            </div>
          </Col>
          <Col xs={{ span: 8, offset: 0 }} lg={{ span: 4, offset: 0 }}>
            <div className="flex justify-start items-center w-full h-full">
              <CourseRoomAttendees />
            </div>
          </Col>
          <Col xs={{ span: 1, offset: 0 }} lg={{ span: 6, offset: 0 }}>
            <div className="flex justify-between items-center w-full h-full space-x-2">
              <CourseRoomQuickActionAdvisor />
              <CourseRoomActionMenu />
            </div>
          </Col>
        </Row>
      </DIV>
    );
  },
  ({ item, index }) => [index, _.get(item, 'id')]
);

export default renderCoureRoomItem;
