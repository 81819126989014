import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CFAdvisorProfileModel from '@uz/unitz-models/CFAdvisorProfileModel';
import CFAdvisorProfileFormatter from '@uz/unitz-models/CFAdvisorProfileModel/formatter';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';
import AdvisorProfileRenderer from '@uz/unitz-models/AdvisorProfileModel/renderer';
import usePromiseSource from '@vl/hooks/usePromiseSource';

const bindData = bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const itemData = ctx.get('@item');
              const profileId = _.get(itemData, 'profile.ref_ctf_eid');
              const services = _.get(itemData, 'services');
              const service = _.get(services, [0]);
              const reviewAggregate = _.get(itemData, 'reviews_aggregate');
              const advisorProfile = usePromiseSource(
                async () => {
                  if (!profileId) return null;
                  try {
                    const advisorProfile = await CFAdvisorProfileModel.getProfile(profileId);
                    return advisorProfile.toObject();
                  } catch (err) {}
                },
                null,
                [profileId]
              );
              const onlineValue = _.get(itemData, 'readiness.is_accept_service', false);
              const readiness = _.get(itemData, 'readiness.is_ready', false);
              const serviceList = CFAdvisorProfileFormatter.services(ctx)(service);
              const status = AdvisorProfileFormatter.status(ctx)(itemData);
              const transactionAggregate = _.get(itemData, 'profile.advisor.transactions_aggregate.aggregate');
              const profile = _.get(itemData, 'profile');
              const detail = _.get(profile, 'detail_html');
              return {
                ...itemData,
                profile: {
                  ..._.get(itemData, 'profile'),
                  id: CFAdvisorProfileFormatter.profileId(ctx)(advisorProfile),
                  displayName: AdvisorProfileFormatter.displayName(ctx)(profile),
                  avatarUrl: AdvisorProfileFormatter.avatarUrl(ctx)(profile),
                  yearsExperience: AdvisorProfileFormatter.yearsExperience(ctx)(profile),
                  createdAtTxt: CFAdvisorProfileFormatter.createAtByMonthSlashYear(ctx)(advisorProfile),
                  introText: AdvisorProfileFormatter.introText(ctx)(profile),
                  description: AdvisorProfileRenderer.renderHTML(ctx)(detail),
                  transactionAggregate: AdvisorProfileFormatter.reviewCount(ctx)(transactionAggregate),
                },

                reviewAggregate: AdvisorProfileFormatter.reviewCount(ctx)(reviewAggregate),

                categories: AdvisorProfileFormatter.categories(ctx)(advisorProfile),
                // services: AdvisorProfileFormatter.services(ctx)(services),
                price: {
                  pricePerMin: {
                    value: 0,
                    currency: 0,
                  },
                  pricePerMinTxt: AdvisorProfileFormatter.pricePerMin(ctx)(service),
                  pricePerMinTitle: AdvisorProfileFormatter.pricePerMinTitle(ctx)(service),
                },
                onlineTxt: CFAdvisorProfileFormatter.onlineTxt(ctx)(itemData),
                onlineValue,
                services: serviceList.map((item) => ({
                  ...item,
                  status: _.get(item, 'status_kind') && readiness && onlineValue,
                })),
                status,
                rating: {
                  ratingTxt: AdvisorProfileFormatter.reviewScore(ctx)(itemData),
                  ratingScore: AdvisorProfileFormatter.reviewScore(ctx)(itemData),
                },
              };
            }),
            status: hook((ctx) => {
              const statusData = ctx.get('@item.status');
              if (statusData.isReady) {
                return {
                  name: ctx.apply('i18n.t', 'Advisor.online'),
                  bgStyle: 'bg-brandb-lighter',
                  dotStyle: 'bg-brandb500',
                  textStyle: 'text-brandb500',
                };
              }
              if (statusData.isBusy) {
                return {
                  name: ctx.apply('i18n.t', 'Advisor.busy'),
                  bgStyle: 'bg-yellow-lighter',
                  dotStyle: 'bg-yellow500',
                  textStyle: 'text-yellow500',
                };
              }
              if (statusData.isOffline) {
                return {
                  name: ctx.apply('i18n.t', 'Advisor.offline'),
                  bgStyle: 'bg-background1',
                  dotStyle: 'bg-sub',
                  textStyle: 'text-sub',
                };
              }
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
