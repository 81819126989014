import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Row, Col } from 'antd';

const AboutUsStatisticsLayout = () => {
  return (
    <DIV className="component">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <div className="wrapper app-row py-0 md:py-16">
            <Row className="py-8" justify="center">
              <Col xs={24} md={18} lg={16}>
                <Row gutter={[32, 32]} wrap>
                  {_.map(ctx.get('statistics'), (item, index) => (
                    <Col key={index} xs={12} md={6} lg={6}>
                      <div className="flex flex-col space-y-0 md:space-y-2">
                        <div className="flex justify-center text-center items-center">
                          <div className="text-brandb-darker text-2xl md:text-5xl font-semibold">
                            {_.get(item, 'total', 0)}
                          </div>
                          <span className="text-2xl md:text-4xl text-brandb-darker font-semibold">+</span>
                        </div>

                        <div className="text-brandb-darker text-center text-base md:text-xl font-semibold">
                          {_.get(item, 'name', '')}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsStatisticsLayout;
