import { bindings, hook } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';

const bindData = bindings({
  AdvisorSupportCenterContent: {
    rules: [
      [
        'data',
        {
          data: {
            tabData: hook(() => {
              const route = useRoute();
              const idSection = _.get(route, 'location.hash', '').split('#')[1];
              return idSection;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
