const path = require('path');
const _ = require('lodash');

const SEP = '/';

const isExternalUrl = (val) => {
  return `${val}`.substring(0, 4) === 'http';
};

const GATSBY_TARGET_DOMAIN = process.env.GATSBY_TARGET_DOMAIN;
const updateTargetDomain = (url) => {
  if (GATSBY_TARGET_DOMAIN) {
    url = `${url}`.replace('unitz.app', `${GATSBY_TARGET_DOMAIN}`);
  }
  return url;
};

class LocaleConfig {
  constructor(config) {
    this.config = config;
  }

  get(att) {
    return _.get(this.config, att);
  }

  langSlug(slug) {
    const langPrefix = `${this.get('langSlug')}`;
    if (langPrefix && !_.startsWith(slug, langPrefix)) {
      return path.join(langPrefix, slug);
    }
    return slug;
  }

  withLangPrefix(url) {
    url = url || (typeof window !== 'undefined' && _.get(window, 'location.pathname'));
    url = updateTargetDomain(url);
    if (isExternalUrl(url)) return url;
    const paths = _.compact(_.split(url, SEP));
    const localeLang = _.first(paths);
    if (_.get(localeMap, localeLang)) {
      url = path.join(...paths.splice(1));
      return path.join(SEP, this.langSlug(url));
    }

    return path.join(SEP, this.langSlug(url));
  }

  matchRoute(url) {
    const paths = _.compact(_.split(url, SEP));
    const localeLang = _.first(paths);
    return localeLang === this.get('lang');
  }

  setCurrent() {
    document.cookie = `firebase-country-override=${_.toLower(this.get('country'))}`;
    document.cookie = `firebase-language-override=${_.toLower(this.get('lang'))}`;
  }
}

const configLocales = [
  {
    name: 'en',
    locale: 'en-US',
    lang: 'en',
    langSlug: `en${SEP}`,
    country: 'US',
  },
  {
    name: 'vi',
    locale: 'vi-VN',
    lang: 'vi',
    langSlug: `vi${SEP}`,
    country: 'VN',
  },
  {
    name: 'default',
    locale: 'vi-VN',
    lang: 'vi',
    langSlug: '',
    country: 'VN',
  },
].map((item) => new LocaleConfig(item));

const localeMap = _.keyBy(configLocales, (item) => item.get('lang'));

exports.localeMap = localeMap;

const localeMapByLang = _.keyBy(
  _.reject(configLocales, (item) => item.get('name') === 'default'),
  (item) => item.get('lang'),
);
exports.localeMapByLang = localeMapByLang;
exports.getLocaleConfigByLang = (lang) => _.get(localeMapByLang, lang);

const withLocale = (fn) => {
  return async (...args) => {
    const res = [];
    for (const localeConfig of configLocales) {
      res.push(await fn.apply(localeConfig, args));
    }
    return res;
  };
};

exports.withLocale = withLocale;

exports.getLocaleConfig = _.memoize((includeDefault = false) => {
  const res = [];
  for (const localeConfig of configLocales) {
    if (includeDefault) {
      res.push(localeConfig);
    } else if (localeConfig.get('name') !== 'default') {
      res.push(localeConfig);
    }
  }
  return res;
});

const getDefaultLocaleConfig = _.memoize(() => {
  for (const localeConfig of configLocales) {
    if (localeConfig.get('name') === 'default') {
      return localeConfig;
    }
  }
  return configLocales[0];
});

const getCurrentLocaleConfig = (route) => {
  const pathname = _.get(route, 'location.pathname');
  for (const localeConfig of configLocales) {
    if (localeConfig.matchRoute(pathname)) {
      return localeConfig;
    }
  }
  return getDefaultLocaleConfig();
};

exports.getCurrentLocaleConfig = getCurrentLocaleConfig;

const langPrefixUrl = (toUrl) => {
  return (...args) => {
    const url = toUrl(...args);
    // eslint-disable-next-line
    const route = typeof window === 'undefined' ? null : window;
    const currentLocale = getCurrentLocaleConfig(route);
    return currentLocale.withLangPrefix(url);
  };
};
exports.langPrefixUrl = langPrefixUrl;
