import React from 'react';
// import Scroll from '../../containers/scroll/index';
// import './copyright.scss';

function CopyRight() {
  return (
    <div className="footer-wrapper-copytext">
      <div className="footer-copyright-text">
        <div className="copyright-link">
          <span>© Copyright 2019 busines. All Rights Reserved</span>

          <span> Term & Condition / Privacy & Policy</span>
        </div>
        {/* <Scroll /> */}
      </div>
    </div>
  );
}

export default CopyRight;
