import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import Button from '@uz/unitz-components-web/Button';
import styled from 'styled-components';

import CourseRCMButton from '@uz/unitz-components-web/CourseRCM/Components/Button';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Row, Col } from 'antd';
import Link from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import useRoute from '@vl/hooks/useGbRoute';

const CustomText = styled.div`
  div {
    color: ${gstyles.colors.main};
    font-size: 20px;
    @media only screen and (min-width: 320px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  div > span {
    margin: 0;
  }
`;

const HeroSliderItem = () => {
  const route = useRoute().getPageContext();
  const lang = _.get(route, 'lang') === undefined ? '' : `/${_.get(route, 'lang')}`;
  return (
    <DIV className="HeroSliderItem">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div
            style={{
              background:
                'linear-gradient(180deg, #EFFBFA 67.99%, rgba(239, 251, 250, 0.4) 89.87%, rgba(239, 251, 250, 0) 100%)',
            }}
            className="flex items-center justify-center h-full md:py-10 min-lg:py-28"
          >
            <div className="relative mx-auto wrapper app-row">
              <div className="grid h-full grid-cols-1 min-md:grid-cols-2">
                <div className="flex items-center justify-center order-2 mt-4 min-md:mt-0 min-md:order-1">
                  <div className="flex flex-col justify-center space-y-4">
                    <h1 className="mb-0 text-3xl font-bold text-center min-md:text-left min-md:text-4xl xl:text-5xl lg:mb-2 text-main">
                      {ctx.get('sectionData.shortText')}
                    </h1>
                    <CustomText>
                      <div className="mb-0 text-base font-normal text-center min-md:text-left min-md:text-xl text-main">
                        {ctx.apply(
                          'ctf.renderHTMLText',
                          { name: ctx.get('sectionData.name') },
                          'longText.longText',
                          null
                        )}
                      </div>
                    </CustomText>
                    <Row>
                      {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                        const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });
                        if (_.get(itemData, 'name') === 'RCMButton') {
                          return (
                            <div className="flex">
                              <CourseRCMButton />
                            </div>
                          );
                        }
                        return (
                          <Col key={`item_${index}`} xs={24} sm={24} md={16} lg={16} xl={12}>
                            <Link
                              {...ctx.apply('tracking.dataSet', {
                                label: _.get(itemData, 'shortText', ''),
                                value: ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref', '')),
                                action: 'click',
                                category: 'home banner',
                              })}
                              target="_blank"
                              to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref', ''))}
                            >
                              <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                                {(resProps) => (
                                  <Button className="w-full text-main" type="primary" {...resProps}>
                                    {_.get(itemData, 'shortText', '')}
                                  </Button>
                                )}
                              </ResponsiveProps>
                            </Link>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
                <div className="static bottom-0 right-0 flex items-center justify-center hidden w-full h-full min-md:flex min-md:absolute min-md:-right-6 min-lg:-right-12 xl:-right-16 min-md:w-1/2">
                  <ResponsiveProps
                    xs={() => ({ ratio: 0.6, width: 420, height: 420 * 0.6 })}
                    md={() => ({ ratio: 0.5, width: 420, height: 620 * 0.5 })}
                    lg={() => ({ ratio: 0.8, width: 450, height: 550 * 0.8 })}
                    xl={() => ({ ratio: 0.8, width: 600, height: 600 * 0.8 })}
                  >
                    {(resProps) => {
                      return (
                        <Image
                          src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                          className="w-full overflow-hidden"
                          alt="banner"
                          srcParams={{ fit: 'pad' }}
                          {...resProps}
                        />
                      );
                    }}
                  </ResponsiveProps>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default HeroSliderItem;
