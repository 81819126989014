import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Row, Col } from 'antd';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import { Link } from '@uz/unitz-components-web/Link';
import { Image } from '@uz/unitz-components-web/Image';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import CourseSessions from '@uz/unitz-components-web/CourseSessions';
import gstyles from '@vl/gstyles';
import CourseRoomQuickActionAdvisor from '@uz/unitz-components-web/CourseRoomQuickActionAdvisor';
import CoursePurchaseButton from '@uz/unitz-components-web/CoursePurchaseButton';
import CourseStatusIndicator from '@uz/unitz-components-web/CourseStatusIndicator';

export const View8 = () => {
  return (
    <DIV className="listItem">
      <div
        style={{ boxShadow: '0px 2px 6px rgba(38, 51, 64, 0.18)' }}
        className="flex flex-col mx-2 mb-4 rounded-lg bg-white500"
      >
        <div className="relative rounded-tl-lg rounded-tr-lg">
          <Link to={ctx.apply('routeStore.toUrl', 'meCourseDetail', ctx.get('@item'))} external>
            <div className="mx-auto">
              <ResponsiveProps
                xs={{ style: { width: '100%', height: 154, objectFit: 'cover' }, height: 154 }}
                md={{ style: { width: '100%', height: 178, objectFit: 'cover' }, height: 178 }}
              >
                {(resProps) => (
                  <Image
                    src={ctx.get('@item.profile.avatarUrl', '')}
                    className="overflow-hidden rounded-tl-lg rounded-tr-lg"
                    alt={ctx.get('@item.profile.displayName')}
                    {...resProps}
                  />
                )}
              </ResponsiveProps>
            </div>
            <div className="p-4">
              <div className="flex flex-col space-y-1">
                <div className="mr-0 text-base font-semibold sm:mr-4 sm:text-sm text-ellipsis-1 text-main">
                  {ctx.get('@item.profile.displayName')}
                </div>
                <div className="flex items-center justify-start sm:mr-4">
                  <Avatar
                    size={20}
                    key={ctx.get('@item.advisorProfile.advisor_id')}
                    src={ctx.get('@item.advisorProfile.avatar_url')}
                    alt={ctx.get('@item.advisorProfile.display_name')}
                  />
                  <div className="ml-1 text-sm font-normal text-sub">{`${ctx.get(
                    '@item.advisorProfile.display_name'
                  )}`}</div>
                  <CourseStatusIndicator className="w-auto mx-2" type="label" />
                </div>
                <div className="flex items-center pt-2 space-x-4">
                  <div className="flex items-center justify-center space-x-1">
                    <div>{gstyles.icons({ name: 'clock', size: 16, fill: gstyles.colors.sub })}</div>
                    <h3 className="mb-0 text-xs font-normal text-main">
                      {`${ctx.get('@item.session_duration')} / ${ctx.get('@item.price.unitText')}`}
                    </h3>
                  </div>
                </div>
                <div className="flex items-center pt-2 space-x-4">
                  <div className="flex items-center justify-center space-x-1">
                    <div>{gstyles.icons({ name: 'calendar', size: 16, fill: gstyles.colors.sub })}</div>
                    <span className="text-xs text-main">
                      {ctx.apply('i18n.t', 'Course.openingDay', { startAt: ctx.get('@item.startAt') })}
                    </span>
                  </div>
                </div>
              </div>

              <div className="h-full px-4 my-3 py-2 bg-background2 rounded-xl">
                <CourseSessions />
              </div>

              <div className="flex flex-wrap items-center justify-between xs:space-x-2 sm:space-x-2 md:space-x-2 min-lg:space-x-1">
                <CoursePurchaseButton showPrice />
                {ctx.get('@item.nextCourseRoom') ? (
                  <div className="flex-1 my-1">
                    <DIV forceCtx>
                      {ctx.debug(() => {
                        ctx.set('@item', ctx.get('@item.nextCourseRoom'));
                        ctx.set('@index', ctx.get('@item.nextCourseRoom.id'));
                      })}
                      <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'small' }}>
                        {(resProps) => <CourseRoomQuickActionAdvisor {...resProps} />}
                      </ResponsiveProps>
                    </DIV>
                  </div>
                ) : null}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
