import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { getStreamable } from '@vl/mod-utils/getStreamable';

const eventStream = getStreamable();

class Dispatcher {
  listeners = new Map();

  static ensure(container, dataKey) {
    _.update(container, dataKey, (val) => (val instanceof Dispatcher ? val : new Dispatcher()));
    return _.get(container, dataKey);
  }

  subscribe(listener) {
    this.listeners.set(listener, () => {
      this.listeners.delete(listener);
    });
    return this.listeners.get(listener);
  }

  dispatch(data) {
    const it = this.listeners.entries();
    for (const item of it) {
      const [listener] = item;
      _.isFunction(listener) && listener(data);
    }
  }
}

const normalizeDataKey = (dataKey) => {
  if (_.isPlainObject(dataKey)) {
    const keys = _.keys(dataKey);
    return [dataKey[keys[0]], keys[0]];
  }
  if (_.isString(dataKey)) {
    return [dataKey, 'ref'];
  }
  if (_.isArray(dataKey)) {
    return dataKey;
  }
  return dataKey;
};

export const store = {};

const bindData = bindings({
  refProvider: {
    rules: [
      [
        'data',
        {
          data: {
            REF: hook((ctx) => {
              const ref = React.useRef({ data: {}, listeners: {} });

              const getData = (dataKey) => {
                return _.get(ref.current.data, dataKey);
              };

              const RendererComponent = React.useMemo(
                () => ({ dataKey, render, ctxKey }) => {
                  const [v, $v] = React.useState(0);
                  const cRef = React.useRef({});

                  Object.assign(cRef.current, { v, $v });

                  React.useEffect(() => {
                    const dis = Dispatcher.ensure(ref.current.listeners, dataKey).subscribe(() => {
                      cRef.current.$v(cRef.current.v + 1);
                    });
                    return () => {
                      dis();
                    };
                  }, [dataKey, cRef]);

                  if (_.isFunction(render)) {
                    const layout = getData(dataKey);
                    if (ctxKey) {
                      return render({ [ctxKey]: layout });
                    }
                    return render({ layout });
                  }
                  return null;
                },
                []
              );

              const onRef = (dataKey) => (event) => {
                _.set(ref.current.data, dataKey, _.cloneDeep(event.nativeEvent.layout));
                // emit all listener on the key if layout changed
                Dispatcher.ensure(ref.current.listeners, dataKey).dispatch();
              };

              const useRef = (key, render) => {
                const [dataKey, ctxKey] = normalizeDataKey(key);
                if (_.isFunction(render)) {
                  return <RendererComponent render={render} dataKey={dataKey} ctxKey={ctxKey} />;
                }
                return null;
              };

              const getRef = (key) => {
                const [dataKey] = normalizeDataKey(key);
                return getData(dataKey);
              };

              const setRef = (dataKey, data) => {
                _.set(ref.current.data, dataKey, data);
                // emit all listener on the key if layout changed
                Dispatcher.ensure(ref.current.listeners, dataKey).dispatch();
                eventStream.emit(dataKey, data);
              };

              const hookRef = (dataKey) => {
                const ref = React.useRef({});
                const [val, $val] = React.useState(() => getRef(dataKey));
                _.assign(ref.current, { val, $val });
                React.useEffect(() => {
                  ref.current.disposer = eventStream.on(dataKey, (newVal) => {
                    setTimeout(() => {
                      if (!_.isEqual(newVal, ref.current.val)) {
                        ref.current.$val && ref.current.$val(newVal);
                      }
                    });
                  });
                  return () => {
                    ref.current.disposer && ref.current.disposer();
                    ref.current = {};
                  };
                }, []);
                return ref.current.val;
              };

              store.REF = {
                onRef,
                useRef,
                getRef,
                setRef,
                hookRef,
              };
              return store.REF;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
