import { hook, bindings } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  formAdvisorSearch: {
    rules: [
      [
        'data',
        {
          data: {
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'SearchBar' });
              return {
                section,
              };
            }),
            priceRange: hook((ctx) => {
              return ctx.get('priceRangeOptions');
            }),
            form: hook((ctx) => {
              return ctx.get('advisorSearchForm');
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
            categoryOptions: hook((ctx) => {
              const categories = ctx.get('categories', []);
              return _.get(categories, '0.children', []);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
