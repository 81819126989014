import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomParagraph = styled.div`
  position: relative;
  overflow: hidden;

  div > p {
    font-weight: 400;
    font-size: 20px;
    @media only screen and (max-width: 576px) {
      font-size: 16px;
    }
    margin: 0;
    color: ${gstyles.colors.main};
  }
`;
const GreenDisc = styled.div`
  position: absolute;
  top: 100%;
  left: 5%;
  transform: translate(-60%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 762px;
  height: 762px;
  background-color: ${gstyles.colors.brandb.lightest};
  overflow: hidden;
  opacity: 0.4;
  @media only screen and (max-width: 991px) {
    opacity: 0;
  }
`;

const GreenCircle = styled.div`
  position: absolute;
  top: 20%;
  left: 35%;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  z-index: -20;
  width: 137px;
  height: 137px;
  background-color: ${gstyles.colors.brandb.lightest};
  overflow: hidden;
  opacity: 0.4;
  @media only screen and (max-width: 991px) {
    opacity: 0;
  }
`;

const PinkDisc = styled.div`
  position: absolute;
  top: 20%;
  right: 0;
  transform: translate(50%, -70%);
  border-radius: 50%;
  z-index: -20;
  width: 762px;
  height: 762px;
  background-color: ${gstyles.colors.branda.lightest};
  overflow: hidden;
  opacity: 0.4;
  @media only screen and (max-width: 991px) {
    opacity: 0;
  }
`;

const PinkCircle = styled.div`
  position: absolute;
  top: 70%;
  left: 60%;
  transform: translate(50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 137px;
  height: 137px;
  background-color: ${gstyles.colors.branda.lightest};
  overflow: hidden;
  opacity: 0.4;
  @media only screen and (max-width: 991px) {
    opacity: 0;
  }
`;

const AboutUsIntroductionLayout = () => {
  return (
    <DIV className="AboutUsIntroductionLayout">
      {ctx.apply('ctf.renderProps', () => (
        <div className={ctx.get('sectionData.className')}>
          <div className="wrapper app-row">
            <CustomParagraph className="rounded-lg p-0 md:p-12 md:border border-divider">
              <GreenDisc />
              <PinkDisc />
              <PinkCircle />
              <GreenCircle />
              <Row
                align="middle"
                justify="center"
                gutter={[
                  { xs: 16, lg: 24 },
                  { xs: 18, md: 24, lg: 32 },
                ]}
              >
                {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                  return (
                    <Col lg={24} key={index}>
                      <React.Fragment>{ctx.apply('ctf.renderEntry', { name: _.get(item, 'name') })}</React.Fragment>
                    </Col>
                  );
                })}
              </Row>
            </CustomParagraph>
          </div>
        </div>
      ))}
    </DIV>
  );
};

export default AboutUsIntroductionLayout;
