import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';

const AdvisorSectionGuideLayout = () => {
  return (
    <DIV className="AdvisorSectionGuideLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="col-span-1">
            <div className="flex px-4 flex-col md:flex-row rounded-lg bg-white500 md:space-x-2">
              {_.map(_.slice(_.get(ctx.get('sectionData'), 'sections', []), 0, 1), (section, index) => (
                <div className="flex-1 flex justify-center items-center h-auto w-full md:w-1/3 border-2 mb-3 rounded-lg" key={index}>
                  {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                </div>
              ))}

              <div className="w-full md:w-2/3">
                {_.map(
                  _.slice(
                    _.get(ctx.get('sectionData'), 'sections', []),
                    1,
                    _.get(ctx.get('sectionData'), 'sections.length', 0)
                  ),
                  (section, index) => {
                    return (
                      <div className="flex-1" key={index}>
                        {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default AdvisorSectionGuideLayout;
