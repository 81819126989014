import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

const bindData = bindings({
  CourseRoomComingSoonInfo: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const course = ctx.get('@item');

              const recentRoom = _.get(course, 'rooms.0');

              return {
                ...course,
                room: {
                  startAt: CourseFormatter.replaceSACH()(CourseFormatter.startTimeWithDate(ctx)(recentRoom)),
                  startAtTime: CourseFormatter.replaceSACH()(CourseFormatter.startAtTime(ctx)(recentRoom)),
                },
                startAt: CourseFormatter.startAt(ctx)(course),
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
