import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import LandingCourseList from '@uz/unitz-components-web/LandingCourseList';
import Link from '@uz/unitz-components-web/Link';
import { resable } from '@vl/redata/RESABLE';
import RESABLE from '@vl/redata/RESABLE.macro';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const HideScrollBar = styled.div`
  .tag-button-category:last-child {
    margin-right: 0;
  }
  cursor: grab;
  overflow: auto;
  color: #fff;
  transition: color 0.3s;

  &:hover {
    color: rgba(100, 100, 100, 0.1);
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
    height: 14px;

    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 7px;
    box-shadow: inset 0 0 0 10px;
    background-color: #ccc;
  }
`;
const CategoryFilterItem = () => {
  return (
    <DIV>
      <div
        onClick={() => {
          ctx.apply('form.setFieldValue', 'category', ctx.get('@item.id'));
        }}
        {...ctx.apply('tracking.dataSet', {
          value: ctx.get('@item.id'),
          label: ctx.get('@item.displayName'),
          action: 'click',
          category: 'category',
        })}
        className={cx(
          'tag-button-category py-3 select-none font-semibold cursor-pointer flex justify-center items-center mr-6 text-center p-2 text-base whitespace-nowrap',
          {
            'text-brandb500 border-b-2 border-brandb500': ctx.get('@item.isSelected'),
            'text-main bg-white500 border-b-2 border-white': !ctx.get('@item.isSelected'),
          }
        )}
      >
        {ctx.get('@item.displayName')}
      </div>
    </DIV>
  );
};
const LandingCourseListLayout = () => {
  const ref = React.useRef(null);
  _.assign(ref.current, { pos: { top: 0, left: 0, x: 0, y: 0 } });
  const mouseMoveHandler = (e) => {
    // How far the mouse has been moved
    const dx = e.clientX - ref.current.pos.x;
    const dy = e.clientY - ref.current.pos.y;

    // Scroll the element
    ref.current.scrollTop = ref.current.pos.top - dy;
    ref.current.scrollLeft = ref.current.pos.left - dx;
  };
  const mouseUpHandler = function() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ref.current.style.cursor = 'grab';
    ref.current.style.removeProperty('user-select');
  };
  const mouseDownHandler = (e) => {
    ref.current.pos = {
      // The current scroll
      left: ref.current.scrollLeft,
      top: ref.current.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  return (
    <DIV className="LandingCourseListLayout">
      <section className="wrapper app-row course-list-section">
        <div className="flex flex-col items-center justify-center">
          <div className="text-sm font-semibold text-center uppercase text-sub">{ctx.get('sectionData.shortText')}</div>
          <CustomParagraph className="text-center">
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
          </CustomParagraph>
        </div>
        <HideScrollBar ref={ref} className="flex w-full pt-4 pb-2 mb-4 overflow-auto" onMouseDown={mouseDownHandler}>
          {_.map(ctx.get('categories.data'), (item, index) => {
            return (
              <React.Fragment key={`cat_${index}`}>
                <DIV forceCtx>
                  {ctx.debug(() => {
                    ctx.set('@item', {
                      ...item,
                      isSelected: ctx.get('form.values.category') === _.get(item, 'id'),
                    });
                    ctx.set('@index', index);
                  })}
                  <CategoryFilterItem />
                </DIV>
              </React.Fragment>
            );
          })}
        </HideScrollBar>
        <RESABLE>
          <DIV forceCtx>
            {ctx.debug(() => {
              ctx.set('@items', ctx.get('courseFilterModel.items'));
              ctx.set(
                'linkHref',
                ctx.apply('routeStore.toUrl', 'filterCourse', { category: ctx.get('form.values.category') })
              );
            })}
            <LandingCourseList />
          </DIV>
        </RESABLE>
        <div className="flex items-center justify-center pt-0 min-md:pt-8">
          <Link
            className="flex flex-row space-x-2"
            to={ctx.apply('routeStore.toUrl', 'filterCourse', { category: ctx.get('form.values.category') })}
          >
            <span className="mb-0 text-base font-semibold text-brandb500">
              {ctx.apply('i18n.t', 'Advisor.discoverMore')}
            </span>
            <span>{gstyles.icons({ name: 'arrow-right2', size: 24, fill: gstyles.colors.brandb500 })}</span>
          </Link>
        </div>
      </section>
    </DIV>
  );
};

export default LandingCourseListLayout;
