import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Subtitle, Description, Titlespan2 } from '../../components/title/index';
import CarouselSlider from './testimonial';
// import './testimonial.scss';

function Testimonial() {
  return (
    <section className="testimonial-wrapper gradient-color" id="testimonial">
      <DIV>
        <div className="main-title-wrapper">
          <Subtitle
            Class="site-subtitle gradient-color"
            Name={ctx.apply('ctf.findEntry', { name: 'testimonialItem' }, 'shortText', '')}
          />
          <Titlespan2
            Class="sitemain-subtitle"
            Name={ctx.apply('ctf.findEntry', { name: 'testimonialItem' }, 'longText.longText', '')}
          />
          <Description
            Class="site-dec"
            Name={ctx.apply('ctf.renderRichText', { name: 'testimonialItem' }, 'richText', null)}
          />
        </div>
        <CarouselSlider />
      </DIV>
    </section>
  );
}

export default Testimonial;
