import React from 'react';
import { Link as GbLink } from 'gatsby';
import _ from 'lodash';

const isInternal = (to) => {
  return /^\/(?!\/)/.test(to);
};

export const Link = ({ nullish = true, ...props }) => {
  const { to, children, external, ...rest } = props;
  if (nullish && _.isNull(to)) return null;

  if (!external && isInternal(to)) {
    return <GbLink {...props} />;
  }
  if (to) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    );
  }

  return children;
};

export const LinkToWrapper = ({ to, children, nullish = true, ...rest }) => {
  if (nullish && _.isNull(to)) return null;
  if (to) {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }
  return children;
};

export default Link;
