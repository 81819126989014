import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';

const BeginnerGuideHeroLayout = () => {
  return (
    <DIV className="BeginnerGuideHero">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="py-4 border-b sm:border-b-8">
            <div className="app-row wrapper md:px-0 md:mx-0 md:w-full xl:mx-auto xl:px-0">
              <h2 className="mb-5 text-base md:text-2xl lg:text-4xl font-semibold text-center text-main">
                {ctx.get('sectionData.shortText')}
              </h2>
              <div className="flex flex-col space-y-4">
                {_.map(ctx.get('sectionData.sections'), (item, index) => (
                  <React.Fragment key={`item__${index}`}>
                    {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default BeginnerGuideHeroLayout;
