import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import AdvisorSearchModel from '@uz/unitz-models/AdvisorSearchModel';
import SupplierSearchModel from '@uz/unitz-models/SupplierSearchModel';
import React from 'react';

const bindData = bindings({
  SearchButton: {
    rules: [
      [
        'data',
        {
          data: {
            searchForm: hook((ctx) => {
              // const [canSearch, $canSearch] = React.useState(false);
              const form = useFormik({
                enableReinitialize: true,
                initialValues: {
                  textSearch: '',
                },

                onSubmit: async (values) => {
                  try {
                    // console.log(values);
                  } catch (error) {
                    console.log({ error });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });
              return form;
            }),

            listSuggestion: hook((ctx) => {
              const term = ctx.get('searchForm.values.textSearch');
              const [show, $show] = React.useState(false);
              const [items, $items] = React.useState([]);

              const searchTerm = React.useCallback(async (term) => {
                try {
                  const querySearch = [term ? `search: "${term}"` : `search: ""`];
                  const result = await SupplierSearchModel.find(
                    `args: {${_.compact(querySearch).join(',')}}, limit: 10, order_by: {profile: {scoring_int: desc}}`,
                    'id profile { id, display_name, avatar_url, ref_ctf_eid, advisor_id, created_at, slug, is_published }'
                  );

                  $items(result.toObject() || []);
                } catch (err) {
                  console.log(err);
                }
              }, []);

              React.useEffect(() => {
                var timer;
                if (term) {
                  searchTerm(term);
                  if (!show) {
                    $show(true);
                  }
                } else {
                  $items([]);
                }
                return () => clearTimeout(timer);
              }, [term]);
              return {
                hasData: items.length > 0,
                items,
                show,
                hideSuggestion: () => {
                  $show(false);
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
