import React from 'react';
import _ from 'lodash';
// import Stream from '@vl/mod-utils/Stream';
// import graphme from '@unitz/graphme';

import StreamableAndQueriable from './StreamableAndQueriable';
import BaseState from './BaseState';
// module.exports = StreamableAndQueriable;
import * as utils from './utils';

const privateData = utils.privateDataWrapper({
  effectState: () => []
});
export class BaseModel extends StreamableAndQueriable {
  data = {};

  state = BaseState.create();

  set(...args) {
    if (args.length === 1 && _.isPlainObject(args[0])) {
      _.assign(this.data, args[0]);
      return args[0];
    }

    if (args.length === 2) {
      const [key, val] = args;
      return _.set(this.data, key, val);
    }
  }

  get(key, def) {
    return _.get(this.data, key, def);
  }

  has(key) {
    return _.has(this.data, key);
  }

  /**
   * model query method
   *
   * @static
   * @param {*} ev
   * @memberof VideoCallModel
   */
  async query(queryString) {
    const paths = _.toPath(queryString);
    let curr = this;
    let rtn;
    for (const level of paths) {
      const resolver = (_.isFunction(_.get(curr, 'resolve')) && curr.resolve)
        || (_.isFunction(_.get(curr, 'get')) && curr.get)
        || function (key) {
          return _.get(this, key);
        };

      if (resolver) {
        curr = await resolver.call(curr, level);
      }

      rtn = curr;
    }
    return rtn;
  }

  resolve(key) {
    if (this.has(key)) {
      return this.get(key);
    }
    const resolvers = this.get('resolvers');
    if (_.get(resolvers, key)) {
      return resolvers[key].call(this);
    }
  }

  /**
   *
   *
   * @memberof BaseModel
   */
  _usedStates = new Set();

  // for state data
  setState(...args) {
    if (args.length === 1 && _.isPlainObject(args[0])) {
      _.assign(this.state, args[0]);
    } else if (args.length === 2) {
      const [key, val] = args;
      // _.set(this.state, key, val);
      utils.setByPath(this.state, key, val);
    }

    // emit onChange event
    this.state.emit('onChange');
  }

  getState(key, def) {
    // return _.get(this.state, key, def);
    const val = utils.getByPath(this.state, key, def);
    return val;
  }

  useState(key, def) {
    const rtn = this.getState(key, def);
    this._usedStates.add(key);
    return rtn;
  }

  withState(state) {
    React.useEffect(() => {
      this.setState(state);
      // eslint-disable-next-line
    }, []);
  }

  hookState() {
    this.constructor.hookState(this);
  }

  useEffect(cb, opts) {
    privateData.set(cb, 'effectState', opts());
    const disposer = this.state.on('onChange', () => {
      const newVal = opts();
      const oldVal = privateData.get(cb, 'effectState');

      if (!_.isEqual(newVal, oldVal)) {
        cb && cb();
      }
    });

    this.state.once('onDestroy', () => {
      console.log('onDestroy');
      disposer && disposer();
      privateData.set(cb, 'effectState', null);
    });
  }

  static hookState(instance) {
    const ref = React.useRef({});
    const [v, $v] = React.useState(0);
    _.assign(ref.current, {
      v,
      $v,
      instance,
      usedStates: {}
    });
    // add listen to the stream
    React.useEffect(() => {
      if (instance) {
        const disposers = [];
        disposers.push(
          instance.state.on('onChange', () => {
            const usedStates = {};
            const { instance } = ref.current;
            ref.current.instance
              && ref.current.instance._usedStates.forEach((key) => {
                const val = instance.getState(key);
                _.set(usedStates, key, val);
              });

            // compare state
            if (!_.isEqual(ref.current.usedStates, usedStates)) {
              ref.current.usedStates = usedStates;
              ref.current.$v && ref.current.$v(ref.current.v + 1);
            }
          })
        );
        return () => {
          ref.current = {};
          disposers.forEach((dis) => dis());
          instance.state.emit('onDestroy');
        };
      }
    }, [instance, ref]);
  }

  static initState(instance) {
    instance.state = BaseState.create(instance.state);
  }
}

export default BaseModel;
