import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import _ from 'lodash';

const StepTwoLayout = () => {
  return (
    <DIV className="StepTwoContent">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="py-4">
            <Row align="center">
              <Col className="order-2 lg:order-1" lg={24}>
                <h4 className="text-brandb500 mb-2 text-center lg:text-left text-xl font-semibold">{ctx.get('sectionData.shortText')}</h4>

                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name') }, 'longText.longText', {
                  className: 'mb-8 text-base font-semibold text-left',
                  as: 'div',
                })}
              </Col>
              <Col className="order-1 lg:order-2" lg={24}>
                <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 lg:space-x-20 p-5">
                  {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        {ctx.apply('ctf.renderItem', { name: _.get(item, 'name', '') }) || null}
                      </React.Fragment>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </DIV>
  );
};

export default StepTwoLayout;
