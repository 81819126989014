import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import CourseSessionFormatter from '@uz/unitz-models/CourseSessionModel/formatter';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import moment from 'moment';
import _ from 'lodash';

export const View8 = () => {
  return (
    <DIV>
      <div>
        <div className="text-xs font-normal text-sub">
          <span>{`${ctx.get('@item.sessionsTitle')}`}</span>
          {/* <span className="mx-1">
            (
            {ctx.apply('i18n.t', 'Course.Info.session', {
              count: ctx.get('@item.sessions.length'),
            })}
            )
          </span> */}
          {/* {ctx.apply('i18n.t', 'Course.teaching_schedule')}{' '} */}
          {/* <span>(${ctx.apply('i18n.t', 'Course.Info.session', { count: ctx.get('@item.sessions.length') })})</span> */}
        </div>
        <div className="flex flex-row items-center mt-1 space-x-3">
          <div>
            {_.map(_.take(ctx.get('@item.sessions'), 2), (item) => (
              <div className="mb-1 text-xs font-normal text-main" key={_.get(item, 'id')}>
                {CourseSessionFormatter.formatSessionTimeText(ctx)(item)}:{' '}
                {CourseFormatter.replaceSACH()(CourseSessionFormatter.startAtTime(ctx)(item))}
              </div>
            ))}
          </div>
          <div className="self-end mb-1">
            {ctx.get('@item.sessions.length') > 2 ? (
              <h4 className="mb-0 text-xs font-normal text-main">. +{ctx.get('@item.sessions.length') - 2}</h4>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
