import React from 'react';

export const useMountedState = (initState) => {
  const [state, $state] = React.useState(initState);
  const ref = React.useRef({});
  ref.current.$state = $state;
  React.useEffect(() => {
    ref.current.isMounted = true;
    return () => {
      ref.current = {};
    };
  }, []);
  return [state, (val) => {
    ref.current.isMounted && ref.current.$state(val);
  }];
};

export default useMountedState;
