const MAX_SAFE_INTEGER = 9007199254740991;
const TEN = 10;
const ONE_HUNDRED = 100;
const ONE_THOUSAND = 1000;
const ONE_MILLION = Math.pow(10, 6); // 1000000;
const ONE_BILLION = Math.pow(10, 9); //         1.000.000.000 (9)
const ONE_TRILLION = Math.pow(10, 12); //     1.000.000.000.000 (12)
const ONE_QUADRILLION = Math.pow(10, 15); // 1.000.000.000.000.000 (15)

const LESS_THAN_TWENTY = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
];

const TENTHS_LESS_THAN_HUNDRED = [
  'zero',
  'ten',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
];

function isFinite(value) {
  // eslint-disable-next-line no-self-compare
  return !(typeof value !== 'number' || value !== value || value === Infinity || value === -Infinity);
}

function isSafeNumber(value) {
  return typeof value === 'number' && Math.abs(value) <= MAX_SAFE_INTEGER;
}

export const numWords = (number) => {
  let num = parseInt(number, 10);

  if (!isFinite(num)) {
    throw new TypeError(`Not a finite number: ${number} (${typeof number})`);
  }
  if (!isSafeNumber(num)) {
    throw new RangeError('Input is not a safe number, it’s either too large or too small.');
  }
  return generateWords(num);
};

function generateWords(number, words) {
  let remainder;
  let word;

  // We’re done
  if (number === 0) {
    return !words ? 'zero' : words.join(' ').replace(/,$/, '');
  }
  // First run
  if (!words) {
    words = [];
  }
  // If negative, prepend “minus”
  if (number < 0) {
    words.push('minus');
    number = Math.abs(number);
  }

  if (number < 20) {
    remainder = 0;
    word = LESS_THAN_TWENTY[number];
  } else if (number < ONE_HUNDRED) {
    remainder = number % TEN;
    word = TENTHS_LESS_THAN_HUNDRED[Math.floor(number / TEN)];
    if (remainder) {
      word += `-${LESS_THAN_TWENTY[remainder]}`;
      remainder = 0;
    }
  } else if (number < ONE_THOUSAND) {
    remainder = number % ONE_HUNDRED;
    word = `${generateWords(Math.floor(number / ONE_HUNDRED))} hundred`;
  } else if (number < ONE_MILLION) {
    remainder = number % ONE_THOUSAND;
    word = `${generateWords(Math.floor(number / ONE_THOUSAND))} thousand,`;
  } else if (number < ONE_BILLION) {
    remainder = number % ONE_MILLION;
    word = `${generateWords(Math.floor(number / ONE_MILLION))} million,`;
  } else if (number < ONE_TRILLION) {
    remainder = number % ONE_BILLION;
    word = `${generateWords(Math.floor(number / ONE_BILLION))} billion,`;
  } else if (number < ONE_QUADRILLION) {
    remainder = number % ONE_TRILLION;
    word = `${generateWords(Math.floor(number / ONE_TRILLION))} trillion,`;
  } else if (number <= MAX_SAFE_INTEGER) {
    remainder = number % ONE_QUADRILLION;
    word = `${generateWords(Math.floor(number / ONE_QUADRILLION))} quadrillion,`;
  }

  words.push(word);
  return generateWords(remainder, words);
}
