import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import LandingCourseList from '@uz/unitz-components-web/LandingCourseList';
import Link from '@uz/unitz-components-web/Link';
import { resable } from '@vl/redata/RESABLE';
import RESABLE from '@vl/redata/RESABLE.macro';
import _ from 'lodash';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const LandingCourseListLayout = () => {
  return (
    <DIV className="LandingCourseListLayout">
      <section className="wrapper app-row course-list-section">
        <div className="flex flex-col items-center justify-center">
          <div className="text-sm font-semibold text-center uppercase text-sub">{ctx.get('sectionData.shortText')}</div>
          <CustomParagraph className="text-center">
            {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
          </CustomParagraph>
        </div>
        <RESABLE>
          <DIV forceCtx>
            {ctx.debug(() => {
              const coursesList2 = resable.for('highlightCourses')(ctx.get('highlightCourses'), []);
              ctx.set('@items', [...coursesList2]);
              ctx.set('linkHref', ctx.apply('routeStore.toLocale', '/education/course-filter?sortBy=viewing'));
            })}
            <LandingCourseList />
          </DIV>
        </RESABLE>
        <div className="flex items-center justify-center pt-0 min-md:pt-8">
          <Link
            className="flex flex-row space-x-2"
            to={ctx.apply('routeStore.toLocale', '/education/course-filter?sortBy=viewing')}
          >
            <span className="mb-0 text-base font-semibold text-brandb500">
              {ctx.apply('i18n.t', 'Advisor.discoverMore')}
            </span>
            <span>{gstyles.icons({ name: 'arrow-right2', size: 24, fill: gstyles.colors.brandb500 })}</span>
          </Link>
        </div>
      </section>
    </DIV>
  );
};

export default LandingCourseListLayout;
