import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import UserProfileFormatter from '@uz/unitz-models/UserProfileModel/formatter';

import { Avatar } from 'antd';
import _ from 'lodash';
import { resizeImageUrl } from '@vl/mod-utils/file';

export const View8 = () => {
  return (
    <DIV>
      {ctx.get('@item.attendees.length') ? (
        <Avatar.Group maxCount={3} size={18} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {_.map(ctx.get('@item.attendees'), (item) => (
            <Avatar
              key={_.get(item, 'id')}
              src={resizeImageUrl(UserProfileFormatter.avatarUrl(ctx)({ profile: item }), { size: 20 })}
              alt={_.get(item, 'id')}
            />
          ))}
        </Avatar.Group>
      ) : (
        <span>{ctx.apply('i18n.t', 'AdvisorProfileGeneral.NoStudentYet')}</span>
      )}
    </DIV>
  );
};

export default displayName(View8);
