import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Row, Col } from 'antd';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import UserGuideSection from '@uz/unitz-components-web/UserGuideSection';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const UserGuideLayout = () => {
  return (
    <DIV className="UserGuide">
      {ctx.apply('ctf.renderProps', () => (
        <div className="my-3 sm:my-0 border-b sm:border-b-8">
          <ResponsiveProps
            xs={{ renderer: () => <UserGuideSection /> }}
            lg={{
              renderer: () => (
                <div className="wrapper app-row pt-0 z-10">
                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 32,
                    }}
                  >
                    {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                      (col, index) => (
                        <Col
                          className="mb-3 gutter-row sm:mb-0"
                          lg={6}
                          xs={12}
                          sm={24}
                          md={10}
                          key={`${_.get(col, 'name', '')}_${index}`}
                        >
                          {ctx.apply('ctf.renderEntry', { name: _.get(col, 'name', '') }) || null}
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              ),
            }}
          >
            {(resProps) => !!resProps && resProps.renderer()}
          </ResponsiveProps>
        </div>
      ))}
    </DIV>
  );
};

export default UserGuideLayout;
