import React from 'react';
import _ from 'lodash';
import { hook, bindings } from '@vl/redata';
import moment from 'moment';
export default bindings({
  listItem: {
    rules: [
      [
        'data',
        {
          data: {
            courseRoomModel: hook((ctx) => {
              const item = ctx.get('@item');
              const userId = ctx.apply('authModel.getUserId');
              const model = {
                isPassedRoom: () => {
                  return moment().isAfter(_.get(item, 'room.end_at'));
                },
                isAttendee: () => {
                  return !!_.find(_.get(item, 'attendees', []), { user_id: userId });
                },
                isAdvisor: () => {
                  return _.get(item, 'course.advisor_id', []) === userId;
                },
                isPurchased: () => {
                  return !!_.find(_.get(item, 'purchases', []), { user_id: userId });
                },
                hasPurchase: () => {
                  return !!_.get(item, 'purchases.length', []);
                },
                can: (action) => {
                  if (action === 'dismiss') {
                    // only for future room and attendees
                    if (model.isPassedRoom() || !model.isAdvisor() || model.hasPurchase()) {
                      return false;
                    }
                  } else if (action === 'unfollow') {
                    // only for future room and attendees
                    if (model.isPassedRoom() || !model.isAttendee() || model.isPurchased()) {
                      return false;
                    }
                  } else if (action === 'refund') {
                    if (model.isPassedRoom() || model.isAdvisor() || !model.isPurchased()) {
                      return false;
                    }
                  } else if (action === 'message') {
                    if (!model.isAttendee() || model.isAdvisor()) {
                      return false;
                    }
                  } else if (action === 'reschedule') {
                    if (model.isPassedRoom() || !model.isAdvisor()) {
                      return false;
                    }
                  } else if (action === 'cancel') {
                    if (model.isPassedRoom() || !model.isAdvisor() || !model.hasPurchase()) {
                      return false;
                    }
                  }
                  return true;
                },
                linkTo: () => {
                  const advisor_id = _.get(item, 'room.course.advisor_id');
                  return ctx.apply('routeStore.toUrl', 'meMessageDetail', { id: advisor_id });
                },
              };
              return model;
            }),
          },
        },
      ],
    ],
  },
});
