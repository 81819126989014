// import React from 'react';
import _ from 'lodash';

const hook = (resolver, options = {}) => {
  const rtn = (...args) => resolver(...args);
  rtn.hook = true;
  rtn.options = options;
  return rtn;
};

export const isHook = (obj) => {
  return _.isFunction(obj) && obj.hook === true;
};

export const resolveOption = (option, ...args) => {
  const rtn = isHook(option) ? option(...args) : option;
  return rtn;
};

export const resolveObjectOption = (option, ...args) => {
  const rtn = resolveOption(option, ...args);
  if (_.isPlainObject(rtn)) {
    _.map(rtn, (val, key) => {
      rtn[key] = resolveOption(val, ...args);
    });
  }
  return rtn;
};

export default hook;
