import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import UnitzBizInformationForm from '@uz/unitz-components-web/UnitzBizInformationForm';
import { Row, Col } from 'antd';

const GreenDisc1 = styled.div`
  position: absolute;
  top: 100%;
  left: 15%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 755px;
  height: 787px;
  background-color: ${gstyles.colors.brandb.lighter};
  overflow: hidden;
  z-index: 0;
`;

const GreenDisc2 = styled.div`
  position: absolute;
  top: 35%;
  right: -40%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -20;
  width: 762px;
  height: 722px;
  background-color: ${gstyles.colors.brandb.lighter};
  overflow: hidden;
  z-index: 0;
`;

const CustomText = styled.div`
  div,
  span,
  p {
    margin: 0;
    text-align: center;
    color: ${gstyles.colors.main};
  }
`;

const ForCenterFormCollectionLayout = () => {
  return (
    <DIV className="ForCenterFormCollectionLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div id="form" className={`relative overflow-hidden ${_.get(ctx.get('sectionData'), 'className', '')}`}>
            <GreenDisc1 className="hidden lg:block" />
            <GreenDisc2 className="hidden lg:block" />
            <div className="wrapper app-row py-16">
              <div className="flex flex-col items-center mb-6 w-full md:w-1/2 mx-auto">
                <div className="text-brandb500 font-semibold text-xl lg:text-4xl mb-3 text-center">
                  {ctx.get('sectionData.shortText')}
                </div>
                <CustomText>
                  {ctx.apply(
                    'ctf.renderHTMLText',
                    { name: ctx.get('sectionData.name', '') },
                    'longText.longText',
                    null
                  )}
                </CustomText>
              </div>

              <Row justify="center">
                <Col xs={24} lg={12}>
                  <UnitzBizInformationForm />
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForCenterFormCollectionLayout;
