import React from 'react';
import _ from 'lodash';
import Context from './Context';

const DataContext = React.createContext();

const BaseProvider = DataContext.Provider;

const Provider = ({ value, className, ...rest }) => {
  const parentCtx = React.useContext(DataContext);
  const ctx = new Context({ parent: parentCtx, data: value, className });
  ctx.registerHooks();

  const { children, ...other } = rest;
  // renderProps children
  if (_.isFunction(children)) {
    return (
      <BaseProvider value={ctx} {...other}>
        {children(other, ctx)}
      </BaseProvider>
    );
  }
  return <BaseProvider value={ctx} {...rest} />;
};

// support hot reload?
Provider.getCurrent = () => Provider;

DataContext.Provider = Provider;

export default DataContext;
