import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Row, Col } from 'antd';
import Button from '../../components/button';
import AboutImg from '../../assets/images/about/about-img.png';
// import './about.scss';

function About() {
  return (
    <DIV>
      <section className="about-wrapper gradient-color" id="about">
        <Row>
          <Col md={6}>
            <div className="text-white about-content-block main-title-wrapper">
              {ctx.apply('ctf.renderSectionChild', 0, { component: 'RichText' })}
              <Button
                Class="button1 button4 btn"
                Name={ctx.apply('ctf.findEntry', { name: 'aboutItemAction' }, 'shortText', '')}
                Clickble={() =>
                  window.open(ctx.apply('ctf.findEntry', { name: 'aboutItemAction' }, 'action', ''), '_blank')
                }
                Title="gradient-color"
                BtnIcon="btn-icon gradient-color1"
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className="about-image">
              {ctx.apply('ctf.renderSectionChild', 0, {
                args: [AboutImg, { width: 690 }],
                component: 'Image',
                className: 'about-img',
              })}
            </div>
          </Col>
        </Row>
      </section>
    </DIV>
  );
}

export default About;
