import React from 'react';
import _ from 'lodash';

const ensureDepsArray = (deps, args) => {
  if (_.isFunction(deps)) {
    return deps(...args);
  }
  if (_.isArray(deps)) return deps;
  return [deps];
};

const RenderPropsCom = ({ renderer, args }) => {
  return renderer(...args);
};

const RenderPropsWrapper = ({ renderer, rendererArgs, deps }) => {
  const ref = React.useRef(() => ({}));
  const arrDeps = ensureDepsArray(deps, rendererArgs);
  Object.assign(ref.current, {
    renderer, rendererArgs, deps, arrDeps
  });

  ref.current.cache = React.useMemo(() => {
    if (ref.current.renderer) {
      // return ref.current.renderer(...ref.current.rendererArgs);
      return <RenderPropsCom renderer={ref.current.renderer} args={ref.current.rendererArgs} />;
    }
    // eslint-disable-next-line
  }, arrDeps);

  React.useEffect(() => {
    return () => {
      ref.current = {};
    };
    // eslint-disable-next-line
  }, arrDeps);

  return ref.current.cache || null;
};

export const useRenderProps = (renderer, deps) => {
  return (...args) => {
    return <RenderPropsWrapper renderer={renderer} rendererArgs={args} deps={deps} />;
  };
};

export default useRenderProps;
