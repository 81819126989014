import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import Button from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import _ from 'lodash';
import cx from 'classnames';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const CustomText = styled.div`
  p {
    color: ${gstyles.colors.main};
  }
`;

const LandingCategoriesLayout = () => {
  return (
    <DIV className="LandingCategoriesLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <section className="mb-0 wrapper app-row min-md:mb-8 benefit-section">
            <div className="flex flex-col items-center justify-center mb-6">
              <div className="text-sm font-semibold text-center text-sub">{ctx.get('sectionData.shortText')}</div>
              <CustomParagraph className="text-center text-md">
                {ctx.apply('ctf.renderHTMLText', { name: ctx.get('sectionData.name', '') }, 'longText.longText', null)}
              </CustomParagraph>
            </div>

            <div
              className={cx('grid max-md:grid-cols-2 auto-cols-fr gap-6 justify-center mb-10', {
                'min-lg:grid-flow-col': ctx.get('categories.length') <= 3,
                'min-lg:grid-cols-4': ctx.get('categories.length') > 3,
              })}
            >
              {_.map(_.take(ctx.get('categories', []), 8), (item, index) => (
                <Link
                  style={{ boxShadow: '0px 1px 6px rgba(38, 51, 64, 0.18)' }}
                  className="p-4 rounded-lg bg-white500 grid-span-1 min-md:p-6"
                  key={`item_${index}`}
                  target="_blank"
                  {...ctx.apply('tracking.dataSet', {
                    label: _.get(item, 'displayName', ''),
                    value: ctx.apply('routeStore.toUrl', 'category', item),
                    action: 'click',
                    category: 'home categories',
                  })}
                  to={ctx.apply('routeStore.toUrl', 'filterCourse', item)}
                >
                  <div className="flex flex-col space-y-6">
                    <Avatar
                      size={48}
                      style={{
                        backgroundColor: gstyles.colors.brandb.lighter,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      icon={gstyles.icons({
                        name: _.get(item, 'icon') ? _.get(item, 'icon') : 'replace',
                        size: 24,
                        fill: gstyles.colors.brandb500,
                      })}
                      alt={_.get(item, 'displayName', '')}
                    />
                    <h3 className="text-base font-semibold text-main min-md:text-xl">
                      {_.get(item, 'displayName', '')}
                    </h3>
                  </div>
                </Link>
              ))}
            </div>

            <CustomText className="mb-6 text-center">
              {ctx.apply(
                'ctf.renderHTMLText',
                { name: ctx.get('sectionData.name', '') },
                'detailText.detailText',
                null
              )}
            </CustomText>

            <Row align="center">
              {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });
                return (
                  <Col key={`item_${index}`} xs={24} sm={8} md={10} lg={6} xl={6}>
                    <Link target="_blank" to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref'))}>
                      <ResponsiveProps xs={{ size: 'large' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                        {(resProps) => (
                          <Button className="w-full" type="primary" {...resProps}>
                            {_.get(itemData, 'shortText', '')}
                          </Button>
                        )}
                      </ResponsiveProps>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </section>
        );
      })}
    </DIV>
  );
};

export default LandingCategoriesLayout;
