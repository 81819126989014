import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import styled from 'styled-components';

function VideoIntroductionLayout() {
  return (
    <DIV className="component">
      <div className="py-0 sm:wrapper app-row">
        {ctx.apply('ctf.renderProps', () => {
          return (
            <div className="overflow-hidden rounded-lg shadow-lg bg-white500">
              <div className="section-body">
                {_.map(ctx.get('sectionData.sections'), (section, index) => {
                  const sectionData = ctx.apply('ctf.findNode', { id: _.get(section, 'id') });
                  return (
                    <div className="grid grid-cols-3 rounded-lg">
                      <div className="col-span-1">
                        {_.map(_.slice(_.get(sectionData, 'sections', []), 0, 1), (item, index) => (
                          <React.Fragment key={`video_${index}`}>
                            {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="col-span-2">
                        <div className="px-6 py-3">
                          {_.map(ctx.get('sectionData.nodes'), (section, index) => (
                            <div key={index}>
                              {ctx.apply('ctf.renderEntry', { name: _.get(section, 'name', '') }) || null}
                            </div>
                          ))}
                        </div>
                        <div className="px-6 pb-3">
                          {_.map(
                            _.slice(_.get(sectionData, 'sections', []), 1, _.get(sectionData, 'sections.length', 0)),
                            (item, index) => {
                              const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });
                              return (
                                <React.Fragment key={`content_${index}`}>
                                  {ctx.apply('ctf.renderEntry', { name: _.get(item, 'name', '') }) || null}
                                </React.Fragment>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </DIV>
  );
}

export default VideoIntroductionLayout;
