import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  carouselContainer: {
    rules: [
      [
        'data',
        {
          data: {
            carouselModel: hook((ctx) => {
              const [dragging, $dragging] = React.useState(false);
              const NUMBER_OF_ITEMS = ctx.get('@items.length') || 0;
              const options = {
                dots: true,
                infinite: false,
                centerMode: !(NUMBER_OF_ITEMS > 2),
                speed: 500,
                lazyLoad: true,
                arrows: true,
                slidesToShow: 3.5,
                slidesToScroll: 2,
                responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      infinite: false,
                      centerMode: !(NUMBER_OF_ITEMS > 2),
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      dots: true,
                      arrows: false,
                      swipeToSlide: true,
                    },
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      infinite: !!(NUMBER_OF_ITEMS > 2),
                      centerMode: !(NUMBER_OF_ITEMS > 2),
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      dots: true,
                      arrows: false,
                      swipeToSlide: true,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      infinite: !!(NUMBER_OF_ITEMS > 1),
                      centerMode: !(NUMBER_OF_ITEMS > 1),
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      swipeToSlide: true,
                      arrows: false,
                    },
                  },
                ],
              };
              return {
                options,
                dragging,
                $dragging,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
