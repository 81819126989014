import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Menu, Dropdown, Space } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const { SubMenu } = Menu;

const CustomMenu = styled.div`
  .ant-menu {
    background: ${gstyles.colors.white500};
    border: none;
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        display: flex;
        padding-left: 0 !important;
      }
    }
  }
`;

const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
`;

function MenuNavLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        const menu = (
          <React.Fragment>
            {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
              (col, index) => {
                const item = ctx.apply('ctf.findEntry', { name: _.get(col, 'name', '') });
                return (
                  <React.Fragment key={_.get(item, 'id')}>
                    {ctx.apply(
                      'ctf.renderEntry',
                      { name: _.get(col, 'name', '') },
                      {
                        render: () => (
                          <CustomDropDownContent className="shadow-lg">
                            <Menu>
                              <Menu.Item className="px-3 hover:bg-brandb-lightest" onItemHover={() => {}} key={index}>
                                <Space>
                                  {gstyles.icons({ name: 'star', fill: gstyles.colors.yellow500, size: 24 })}
                                  <span className="text-main text-base font-normal">
                                    {_.get(item, 'shortText', '')}
                                  </span>
                                </Space>
                              </Menu.Item>
                            </Menu>
                          </CustomDropDownContent>
                        ),
                      }
                    )}
                  </React.Fragment>
                );
              }
            )}
          </React.Fragment>
        );
        const DesktopMenu = () => (
          <Dropdown
            overlayClassName="shadow-none xl:shadow-lg rounded-lg overflow-hidden"
            className="cursor-pointer flex"
            overlay={menu}
          >
            <div className="ant-dropdown-link flex justify-center items-center mb-0">
              <div className="text-base text-main mb-0 mx-2">
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'shortText', '')}
              </div>
              {gstyles.icons({ name: 'arrow-down', size: 24, fill: gstyles.colors.black500 })}
            </div>
          </Dropdown>
        );

        const MobileMenu = () => (
          <CustomMenu>
            <Menu
              expandIcon={(props) => (
                <span className="flex justify-center items-center">
                  {gstyles.icons({
                    name: !props.isOpen ? 'arrow-down' : 'arrow-up',
                    size: 24,
                    fill: gstyles.colors.black500,
                  })}
                </span>
              )}
              mode="inline"
              className="block cursor-pointer py-5"
            >
              <SubMenu
                title={
                  <div className="flex justify-center items-center">
                    <div className="text-base font-semibold text-main mb-0 mr-4">
                      {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'shortText', '')}
                    </div>
                  </div>
                }
              >
                {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                  (col, index) => {
                    const item = ctx.apply('ctf.findEntry', { name: _.get(col, 'name', '') });
                    return (
                      <React.Fragment key={index}>
                        {ctx.apply(
                          'ctf.renderEntry',
                          { name: _.get(col, 'name', '') },
                          {
                            render: () => (
                              <Menu.Item className="py-1 bg-white500 px-3" onClick={() => {}} key={index}>
                                <Link
                                  className="text-lg"
                                  target="_blank"
                                  to={ctx.apply('routeStore.toLocale', _.get(item, 'linkHref', ''))}
                                >
                                  <Space>
                                    {gstyles.icons({
                                      name: 'star',
                                      fill: gstyles.colors.yellow500,
                                      size: 20,
                                    })}
                                    <span className="text-main text-base font-normal">
                                      {_.get(item, 'shortText', '')}
                                    </span>
                                  </Space>
                                </Link>
                              </Menu.Item>
                            ),
                          }
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </SubMenu>
              <div className="border-t border-divider"></div>
            </Menu>
          </CustomMenu>
        );

        return (
          <div>
            <DIV>
              <ResponsiveProps
                lg={{
                  renderer: () => <MobileMenu />,
                }}
                xl={{
                  renderer: () => <DesktopMenu />,
                }}
              >
                {(resProps) => !!resProps && resProps.renderer()}
              </ResponsiveProps>
            </DIV>
          </div>
        );
      })}
    </DIV>
  );
}

export default MenuNavLayout;
