import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  AdvisorRegistratorSchedule: {
    rules: [
      [
        'data',
        {
          data: {},
        },
      ],
    ],
  },
});

export default bindData;
