import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';

import cx from 'classnames';

const StyledImage = styled.div``;
export const ImageWrapper = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <StyledImage
          className={cx(
            ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.className', 'className'), ''),
            _.get(itemProps, 'className')
          )}
        >
          <Image
            src={ctx.apply(
              'ctf.findImage',
              itemData,
              _.get(itemProps, 'fieldNames.image', 'image'),
              ...[].concat(_.get(itemProps, 'args') || [])
            )}
            size="cover"
            title={ctx.apply('ctf.findEntry', itemData, _.get(itemProps, 'fieldNames.shortText', 'shortText'), '')}
            alt={_.get(itemData, 'shortText', '')}
            preview={false}
          />
        </StyledImage>
      );
    })}
  </DIV>
);

export default ImageWrapper;
