import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import cx from 'classnames';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';

const CustomParagraph = styled.div`
  p {
    font-weight: 600;
    @media only screen and (min-width: 320px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 32px;
    }
    color: ${gstyles.colors.main};
  }
`;

const FieldFilterItem = () => {
  return (
    <DIV>
      <div
        onClick={() => {
          ctx.apply('form.setFieldValue', 'section', ctx.get('@item.ctfName'));
        }}
        className={cx(
          'flex-grow flex-1 cursor-pointer flex justify-center p-5 rounded-lg mx-2 text-sm lg:text-base font-semibold',
          {
            'text-white500 duration-100 ease-in-out bg-brandb': ctx.get('@item.isSelected'),
            'text-main bg-white500 duration-100 ease-in-out border border-border': !ctx.get('@item.isSelected'),
          }
        )}
      >
        {/* <div className="flex flex-col space-y-6 justify-center items-center">
          {gstyles.icons({
            name: 'star2',
            size: 24,
            fill: ctx.get('@item.isSelected') ? gstyles.colors.white500 : gstyles.colors.brandb500,
          })} */}

        {ctx.get('@item.displayName')}
        {/* </div> */}
      </div>
    </DIV>
  );
};

const LandingHowWeWorkLayout = () => {
  return (
    <DIV className="LandingHowWeWorkLayout">
      <FormProvider form={ctx.get('form')}>
        <Form>
          <section className="wrapper app-row section">
            {ctx.apply('ctf.renderProps', () => {
              return (
                <div>
                  <div className="flex flex-col justify-center items-center">
                    <div className="text-sm text-sub text-center font-semibold">{ctx.get('sectionData.shortText')}</div>
                    <CustomParagraph className="text-center">
                      {ctx.apply(
                        'ctf.renderHTMLText',
                        { name: ctx.get('sectionData.name', '') },
                        'longText.longText',
                        null
                      )}
                    </CustomParagraph>
                  </div>
                  {/* <div className="flex mb-8 jusify-between">
                    {_.map(ctx.get('sections'), (item, index) => {
                      return (
                        <React.Fragment key={`section_${index}`}>
                          <DIV forceCtx>
                            {ctx.debug(() => {
                              ctx.set('@item', {
                                ...item,
                                isSelected: ctx.get('form.values.section') === _.get(item, 'ctfName'),
                              });
                              ctx.set('@index', index);
                            })}
                            <FieldFilterItem />
                          </DIV>
                        </React.Fragment>
                      );
                    })}
                  </div> */}
                </div>
              );
            })}
            <DIV forceCtx>{ctx.apply('ctf.renderEntry', { name: ctx.get('form.values.section') }) || null}</DIV>
          </section>
        </Form>
      </FormProvider>
    </DIV>
  );
};

export default LandingHowWeWorkLayout;
