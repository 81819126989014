import _ from 'lodash';
import importAll from 'import-all.macro';

import DIContainer from '@vl/mod-utils/DIContainer';
// import '../assets/scss/style.scss';

const layoutContainer = new DIContainer();

layoutContainer.loadDir([importAll.sync('./**/index.js')]);

// eslint-disable-next-line
const ver = 36;

export const resolve = (itemData, info) => {
  const options = _.get(info, 'options', {});
  // component overrided from options
  const layoutNameFromOptions = _.get(options, 'component');
  if (layoutNameFromOptions) {
    if (layoutContainer.has(layoutNameFromOptions)) {
      return layoutContainer.get(layoutNameFromOptions);
    }
  }

  const layoutName = _.get(itemData, 'layout.name');
  if (layoutContainer.has(layoutName)) {
    return layoutContainer.get(layoutName);
  }
  return null;
};

export const resolveLayout = resolve;

export const resolveProps = (itemData, info) => {
  const options = _.get(info, 'options', {});
  return {
    ...options,
  };
};
