import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';

const bindData = bindings({
  BlogListLayout: {
    rules: [
      [
        'data',
        {
          data: {
            allMarkdownRemark: hook(() => {
              // const res = useStaticQuery(graphql`
              //   query BlogRollQuery {
              //     allMarkdownRemark(
              //       sort: { order: DESC, fields: [frontmatter___date] }
              //       filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
              //     ) {
              //       edges {
              //         node {
              //           excerpt(pruneLength: 400)
              //           id
              //           fields {
              //             slug
              //           }
              //           frontmatter {
              //             title
              //             templateKey
              //             date(formatString: "MMMM DD, YYYY")
              //             featuredpost
              //             featuredimage {
              //               childImageSharp {
              //                 gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
              //                 gatsbyImageData_md: gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              //                 gatsbyImageData_sm: gatsbyImageData(width: 350, quality: 100, layout: CONSTRAINED)
              //               }
              //             }
              //           }
              //         }
              //       }
              //     }
              //   }
              // `);
              // console.log('resresresres', res);
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
