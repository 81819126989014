import React from 'react';
import _ from 'lodash';
import { Field } from 'formik-antd';
import { Input } from 'antd';

export const InputText = React.forwardRef(
  (allProps, fRef) => {
    const { name, validate, fast, onChange: $onChange, onBlur: $onBlur, canCopy, ...restProps } = allProps;
    const ref = React.useRef({});
    const onChangeHandle = React.useMemo(
      () =>
        _.debounce((value) => {
          ref.current.form && ref.current.form.setFieldValue(name, value != null ? value.valueOf() : value);
        }, 500),
      []
    );
    const [copyValue, $copyValue] = React.useState();

    return (
      <Field name={name} validate={validate} fast={fast}>
        {({ field, form }) => {
          const { value, onChange, onBlur } = field;
          ref.current.form = form;
          ref.current.onChange = onChange;
          const initialValue = _.get(form, `initialValues.${field.name}`);
          if (!_.has(ref.current, 'initialValue') || !_.isEqual(ref.current.initialValue, initialValue)) {
            ref.current.key = `${_.uniqueId('key')}`;
            ref.current.initialValue = initialValue;
          }
          const ele = (<Input
            {...restProps}
            key={`${ref.current.key}`}
            ref={fRef}
            name={name}
            defaultValue={initialValue}
            onChange={(event) => {
              const value = _.get(event, 'target.value');
              onChangeHandle(value);
              $onChange && $onChange(event);
            }}
            onBlur={(event) => {
              onBlur(event);
              $onBlur && $onBlur(event);
            }}
          />);
          if (canCopy) {
            return (
              <>
                {ele}
                <div className='cursor-pointer' onClick={async () => {
                  try {
                    await window.navigator.clipboard.writeText(value);
                    $copyValue(value);
                  } catch (err) {
                  }
                }}>{copyValue === value ? 'Copied' : 'Click to copy'}</div>
              </>
            );
          }
          return ele;
        }}
      </Field>
    );
  }
);

export default InputText;
