import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import CFAdvisorProfileModel from '@uz/unitz-models/CFAdvisorProfileModel';
import CFAdvisorProfileFormatter from '@uz/unitz-models/CFAdvisorProfileModel/formatter';
import AdvisorProfileFormatter from '@uz/unitz-models/AdvisorProfileModel/formatter';
import usePromiseSource from '@vl/hooks/usePromiseSource';

const bindData = bindings({
  AdvisorSearchItem: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const itemData = ctx.get('@item');
              const profileId = _.get(itemData, 'profile.ref_ctf_eid');
              const advisorProfile = usePromiseSource(
                async () => {
                  if (!profileId) return null;
                  try {
                    const advisorProfile = await CFAdvisorProfileModel.getProfile(profileId);
                    return advisorProfile.toObject();
                  } catch (err) {}
                },
                null,
                [profileId]
              );
              const profile = _.get(itemData, 'profile');
              return {
                ...itemData,
                profile: {
                  ..._.get(itemData, 'profile'),
                  id: CFAdvisorProfileFormatter.profileId(ctx)(advisorProfile),
                  displayName: AdvisorProfileFormatter.displayName(ctx)(profile),
                  avatarUrl: AdvisorProfileFormatter.avatarUrl(ctx)(profile),
                  yearsExperience: AdvisorProfileFormatter.yearsExperience(ctx)(profile),
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
