import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col, Button } from 'antd';
import { Select, Form, FormItem, Radio, Slider } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import styled from 'styled-components';
import { formatVND } from '@vl/mod-utils/currencyFormatter';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import _ from 'lodash';

const CustomSelect = styled.div`
  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
    }
    .ant-select-selector {
      height: 52px;
      border-radius: 8px;
      .ant-select-selection-overflow {
        flex-wrap: nowrap;
        overflow: hidden;
        .ant-select-selection-overflow-item {
          .ant-select-selection-search {
            .ant-select-selection-search-input {
            }
          }
        }
      }
    }
  }
`;

const CustomRadioButton = styled.div`
  .ant-radio-group {
    background: ${gstyles.colors.white};
    height: 52px;
    border-radius: 6px;
    .ant-row {
      height: 50px;
      align-items: center;
    }
    .ant-radio-button-wrapper {
      width: 100%;
      padding-right: 2px;
      padding-left: 2px;
      color: ${gstyles.colors.sub};
      border: none;
      border-radius: 6px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span:last-child {
        font-size: 16px;
      }
      &:before {
        content: none;
      }
      &.ant-radio-button-wrapper-checked {
        border-radius: 6px;
        height: 48px;
        background: ${gstyles.colors.brandb500};
        color: ${gstyles.colors.white500};
        span:last-child {
          font-weight: 500;
        }
      }
    }
  }
`;

const CustomSlider = styled.div`
  .ant-form-item-control-input {
    height: 52px;
    .ant-form-item-control-input-content {
      .ant-slider {
        .ant-slider-rail {
          background-color: ${gstyles.colors.brandb.lighter};
        }
        .ant-slider-track {
          background-color: ${gstyles.colors.brandb500};
        }
        .ant-slider-handle {
          width: 20px;
          height: 20px;
          border-width: 4px;
          border-color: ${gstyles.colors.brandb500};
          margin-top: -8px;
        }
      }
    }
  }
`;

const View8 = () => {
  return (
    <DIV className="formAdvisorSearch">
      <div>
        <div className="p-4 sm:px-28 lg:px-44 xl:px-6 app-row bg-background1 xl:bg-white400 xl:rounded-xl">
          <h3 className="py-1 sm:py-4 text-xl sm:text-2xl lg:text-4xl xl:text-2xl font-semibold text-center text-brandb">
            {ctx.get('sectionData.section.shortText')}
          </h3>
          <FormProvider form={ctx.get('form')}>
            <Form className="px-4 advisorSearch-form" id="advisorSearch-form">
              <Row gutter={[16, { xs: 0 }]}>
                <Col xl={8} lg={24} xs={24} md={24} className="gutter-row">
                  <FormItem className="relative rounded-xl" name="category">
                    <span className="absolute z-10 right-2 top-4">
                      {gstyles.icons({ name: 'arrow-down', size: 20, fill: gstyles.colors.sub })}
                    </span>
                    <CustomSelect>
                      <ResponsiveProps xs={{ size: 'small' }} lg={{ size: 'large' }}>
                        {(resProps) => (
                          <Select
                            name="category"
                            style={{ width: '100%' }}
                            placeholder={ctx.apply('i18n.t', 'Selection.placeHolder')}
                            mode="multiple"
                            className="text-black shadow-sm w-full flex-nowrap"
                            {...resProps}
                          >
                            {ctx.get('categoryOptions', []).map((catOption) => {
                              const itemData = ctx.apply('ctf.findNode', { id: _.get(catOption, 'id', '') });
                              return (
                                <Select.Option value={catOption.id} key={catOption.id}>
                                  {itemData.displayName}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        )}
                      </ResponsiveProps>
                    </CustomSelect>
                  </FormItem>
                </Col>
                <Col className="items-center text-center gutter-row" xl={7} lg={24} xs={24} md={24}>
                  <FormItem name="service" className="rounded-xl">
                    <CustomRadioButton>
                      <Radio.Group
                        className="w-full border border-white"
                        defaultValue="phone"
                        name="service"
                        size="large"
                      >
                        <Row gutter={16}>
                          <Col span={12}>
                            <Radio.Button className="text-base" value="phone">
                              {ctx.apply('i18n.t', 'Selection.call')}
                            </Radio.Button>
                          </Col>
                          <Col span={12}>
                            <Radio.Button className="text-base" value="video">
                              {ctx.apply('i18n.t', 'Selection.video')}
                            </Radio.Button>
                          </Col>
                          {/* <Col span={8}>
                            <Radio.Button className="text-base" value="message">
                              {ctx.apply('i18n.t', 'Selection.message')}
                            </Radio.Button>
                          </Col> */}
                        </Row>
                      </Radio.Group>
                    </CustomRadioButton>
                  </FormItem>
                </Col>
                <Col className="gutter-row" xl={6} lg={24} md={24} xs={24}>
                  <CustomSlider>
                    <FormItem className="mb-0" name="priceRange">
                      <Slider name="priceRange" className="mt-0" range {...ctx.get('priceRangeOptions', {})} />
                      <div className="text-sm font-semibold text-main">Price per package</div>
                      <div className="flex items-center">
                        <div className="mb-0 text-sm font-normal text-main">
                          From {formatVND(ctx.get('form.values.priceRange.0'))}
                        </div>
                        <div className="ml-1 mb-0 text-sm font-normal text-main">
                          to {formatVND(ctx.get('form.values.priceRange.1'))}
                        </div>
                      </div>
                    </FormItem>
                  </CustomSlider>
                </Col>
                <Col className="text-center xl:text-right gutter-row" xl={3} lg={24} md={24} xs={24}>
                  <Row className="justify-center items-center xl:items-start">
                    <Col xs={24} md={8} lg={8} xl={24}>
                      <ResponsiveProps xs={{ size: 'small' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                        {(resProps) => (
                          <Button
                            className="w-full xl:w-28"
                            type="primary"
                            onClick={ctx.get('form.handleSubmit')}
                            disabled={!ctx.get('canSubmit')}
                            loading={ctx.get('form.isSubmitting')}
                            {...resProps}
                          >
                            <span className="mb-0 text-base font-semibold text-white500">
                              {ctx.apply('i18n.t', 'Selection.search')}
                            </span>
                          </Button>
                        )}
                      </ResponsiveProps>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
