import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import Button from '@uz/unitz-components-web/Button';
import styled from 'styled-components';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import { Row, Col } from 'antd';
import cx from 'classnames';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';

const CustomText = styled.div`
  div,
  span,
  p {
    margin: 0;
    text-align: center;
    color: ${gstyles.colors.sub};
    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
`;

const GreenDisc = styled.div`
  position: absolute;
  top: 50%;
  right: -40%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 1023px;
  height: 1023px;
  background-color: ${gstyles.colors.brandb.lightest};
  overflow: hidden;
`;

const ForCenterHeroLayout = () => {
  return (
    <DIV className="ForCenterHeroLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className="bg-white500 relative content overflow-hidden">
            <GreenDisc className="hidden lg:block" />
            <div className="absolute z-20 w-full left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className={cx('wrapper app-row', ctx.get('sectionData.className'))}>
                <div className="w-full min-lg:w-2/3 mx-auto">
                  <h1 className="text-center text-2xl lg:text-4xl mb-4 lg:mb-6 tracking-wider text-main font-semibold">
                    {ctx.get('sectionData.shortText')}
                  </h1>
                  <div className="mb-4 sm:mb-12">
                    <CustomText>
                      <div className="text-center text-2xl font-normal mb-0 tracking-wider text-brandb500">
                        {ctx.apply(
                          'ctf.renderHTMLText',
                          { name: ctx.get('sectionData.name') },
                          'longText.longText',
                          null
                        )}
                      </div>
                    </CustomText>
                  </div>
                  <Row justify="center" align="middle" className={ctx.get('sectionData.className')}>
                    {_.map(ctx.get('sectionData.sections', []), (item, index) => {
                      const itemData = ctx.apply('ctf.findNode', { id: _.get(item, 'id') });

                      return (
                        <Col key={`item_${index}`} xs={18} sm={12}>
                          <Link to={_.get(itemData, 'linkHref')}>
                            <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                              {(resProps) => (
                                <Button className="w-full text-center" type="primary" {...resProps}>
                                  {_.get(itemData, 'shortText', '')}
                                </Button>
                              )}
                            </ResponsiveProps>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </div>

            <ResponsiveProps
              md={{ style: { width: 768, height: 768 * 0.5 } }}
              xs={{ style: { height: 400 } }}
              lg={() => ({ style: { width: 1200, height: 1200 * 0.4 } })}
            >
              {(resProps) => <div className="bg-white500" {...resProps} />}
            </ResponsiveProps>
          </div>
        );
      })}
    </DIV>
  );
};

export default ForCenterHeroLayout;
