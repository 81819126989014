import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const ColorText = styled.div`
  div,
  span,
  p {
    color: ${(props) => (props.white === 'white' ? gstyles.colors.white500 : gstyles.colors.main)};
  }
`;

export const RichText = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <ColorText white={_.get(itemData, 'className')}>
          {ctx.apply('ctf.renderRichText', itemData, ...[].concat(_.get(itemProps, 'args') || ['richText', null]))}
        </ColorText>
      );
    })}
  </DIV>
);

export default RichText;
