import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import ButtonWrap from '@uz/unitz-components-web/Button';
import { Link } from '@uz/unitz-components-web/Link';

export const Button = () => (
  <DIV>
    {ctx.apply('ctf.renderProps', () => {
      const itemData = ctx.get('itemData');
      const itemProps = ctx.get('itemProps');
      return (
        <Link to={ctx.apply('routeStore.toLocale', _.get(itemData, 'linkHref'))}>
          <ButtonWrap type={_.get(itemData, 'buttonProps.type', '')} name={_.get(itemData, 'buttonProps.name', '')}>
            {ctx.apply('ctf.findEntry', itemData, ...[].concat(_.get(itemProps, 'args') || ['shortText', '']))}
          </ButtonWrap>
        </Link>
      );
    })}
  </DIV>
);

export default Button;
