import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import AdvisorSearchForm from '@uz/unitz-components-web/AdvisorSearchForm';
import { Overlay } from '@uz/unitz-components-web/Overlay';
import { Image } from '@uz/unitz-components-web/Image';

function BusinessHeroBanner() {
  return (
    <DIV className="component">
      <div className="relative none-container">
        {ctx.apply('ctf.renderProps', () => (
          <div className="relative content">
            <ResponsiveProps
              xs={{
                srcParams: { w: 420 },
                style: { height: 208, objectFit: 'cover' },
              }}
              md={{
                srcParams: { w: 800 },
                style: { height: 400, objectFit: 'cover' },
              }}
              lg={{
                srcParams: { w: 1200 },
                style: { height: 500, objectFit: 'cover' },
              }}
              xl={() => ({
                srcParams: { w: 1200 },
                style: { height: 640, objectFit: 'cover' },
              })}
            >
              {(resProps) => (
                <Image
                  size="cover"
                  src={ctx.apply('ctf.findImage', ctx.get('sectionData'), 'image', '')}
                  alt="banner"
                  className="w-full hero-image"
                  {...resProps}
                />
              )}
            </ResponsiveProps>
            <Overlay opacity={20} />
            <div className="absolute z-10 w-full top-6 sm:top-16">
              <div className="wrapper app-row">
                <div className="w-full">
                  <h1 className="text-xl md:text-4xl xl:text-5xl font-semibold tracking-wider text-white500">
                    {ctx.get('sectionData.shortText')}
                  </h1>
                  <div className="w-full">
                    <div className="text-xs sm:text-xl font-normal tracking-wider text-white500">
                      {ctx.apply('ctf.renderRichText', { name: ctx.get('sectionData.name') }, 'richText', null)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <ResponsiveProps
          xs={{ className: 'static' }}
          xl={{ className: 'absolute z-50 w-full bottom-12' }}
          xxl={{ className: 'absolute z-50 w-full bottom-20' }}
        >
          {(resProps) => (
            <div {...resProps}>
              <DIV>
                {ctx.debug(() => {
                  const categories = ctx.get('categories', []);
                  ctx.set('ctfCategories', categories);
                })}
                <AdvisorSearchForm />
              </DIV>
            </div>
          )}
        </ResponsiveProps>
      </div>
    </DIV>
  );
}

export default BusinessHeroBanner;
