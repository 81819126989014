import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            categories: hook((ctx) => {
              const categories = ctx.apply('ctf.buildCategoryHierarchy');
              const optimizeFilter = React.useMemo(
                () => _.filter(categories, (item) => {
                  return !_.get(item, 'parent') && _.get(item, 'id') !== '2JDELht3aty4I18HvWctIC';
                }),
                [categories]
              );
              return optimizeFilter;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
